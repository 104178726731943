import PlayType from "../datatype/PlayType";
import PlayBackground from "./PlayBackground";

class PlaySettingData {
    constructor() {
        this.playType = PlayType.AnimationReview
        this.playBackground = new PlayBackground()
        this.mapScale = 1.0
        this.speed = 2;
        this.textSpeed = 2;
        this.playOrderList = new Array()
        this.PlayOrderPeer = false
        this.onlyPlayText = false
    }

}

export default PlaySettingData