
import UiUtil from "../../../utils/UiUtil";
import Util from "../../../utils/Util";
import CGPoint from "../../../viewmodel/core/base/basedata/CGPoint";
import CGRect from "../../../viewmodel/core/base/basedata/Rect";
import HashMap from "../../../viewmodel/core/base/HashMap";
import HandwritingType from "../../../viewmodel/datatype/HandwritingType";
import MindType from "../../../viewmodel/datatype/MindType";
import DoubleBubbleMindNodeUnit from "../../../viewmodel/mindelementdata/DoubleBubbleMindNodeUnit";
import LineMindTypeNode from "../../../viewmodel/mindelementdata/LineMindTypeNode";
import LineMindTypeNodeUnit from "../../../viewmodel/mindelementdata/LineMindTypeNodeUnit";
import TimeMindTypeNodeUnit from "../../../viewmodel/mindelementdata/TimeMindTypeNodeUnit";


/**
 * ProjectName: MindMap
 * Created by tony on 2/19/21
 * Copyright(c) 2020 mindyushu.com
 */

class CalculSelectedNodesByRect {
    constructor() {
        this.mainMindNodeUnit = new LineMindTypeNodeUnit(MindType.LINE_MAP, 0, false);
        this.freeNodes = new Array();
        this.nodeConnectLineDataDict = new HashMap(); //节点连接线
        this.timeMindTypeNodeUnit = new TimeMindTypeNodeUnit();
        this.doubleBubbleMindNodeUnit = new DoubleBubbleMindNodeUnit(MindType.DOUBLE_BUBBLE_MAP, 0);


    }
    setData(mainMindNodeUnit, freeNodes, nodeConnectLineDataDict, timeMindTypeNodeUnit, doubleBubbleMindNodeUnit) {
        this.mainMindNodeUnit = mainMindNodeUnit;
        this.freeNodes = freeNodes;
        this.nodeConnectLineDataDict = nodeConnectLineDataDict;
        this.timeMindTypeNodeUnit = timeMindTypeNodeUnit;
        this.doubleBubbleMindNodeUnit = doubleBubbleMindNodeUnit;
    }
    hit(rect) {
        let list = new Array();
        let values = this.mainMindNodeUnit.mainMindElementDataDict.values();
        let length = values.length;
        for (let i = 0; i < length; i++) {
            const node = values[i];
            if (node != null && this.matrixHit(rect, node.value)) {
                list.push(node.value);
            }
        }


        for (let i = 0; i < this.doubleBubbleMindNodeUnit.mainMindElementDataDict.keys().length; i++) {
            const key = this.doubleBubbleMindNodeUnit.mainMindElementDataDict.keys()[i];
            let node = this.doubleBubbleMindNodeUnit.mainMindElementDataDict.get(key);
            if (node != null && this.matrixHit(rect, node.value)) {
                list.push(node.value);
            }
        }
        for (let i = 0; i < this.doubleBubbleMindNodeUnit.commonGroundMindElementDatas.length; i++) {
            let data = this.doubleBubbleMindNodeUnit.commonGroundMindElementDatas[i];
            if (data != null && this.matrixHit(rect, data)) {
                list.push(data);
            }
        }

        for (let i = 0; i < this.mainMindNodeUnit.generalizationMindElementDataDict.keys().length; i++) {
            const key = this.mainMindNodeUnit.generalizationMindElementDataDict.keys()[i];
            let node = this.mainMindNodeUnit.generalizationMindElementDataDict.get(key);
            if (node != null && this.matrixHit(rect, node.value)) {
                list.push(node.value);
            }
        }

        for (let i = 0; i < this.freeNodes.length; i++) {
            const unit = this.freeNodes[i];
            for (let i = 0; i < unit.mainMindElementDataDict.keys().length; i++) {
                const key = unit.mainMindElementDataDict.keys()[i];
                let node = unit.mainMindElementDataDict.get(key);
                if (node != null && this.matrixHit(rect, node.value)) {
                    list.push(node.value);
                }
            }

            for (let i = 0; i < unit.generalizationMindElementDataDict.keys().length; i++) {
                const key = unit.generalizationMindElementDataDict.keys()[i];
                let node = unit.generalizationMindElementDataDict.get(key);
                if (node != null && this.matrixHit(rect, node.value)) {
                    list.push(node.value);
                }
            }

            for (let i = 0; i < unit.encircleMindElementDataDict.keys().length; i++) {
                const key = unit.encircleMindElementDataDict.keys()[i];
                let line = unit.encircleMindElementDataDict.get(key);
                if (line != null && this.matrixHit(rect, line)) {
                    list.push(line);
                }
            }
        }

        for (let i = 0; i < this.nodeConnectLineDataDict.keys().length; i++) {
            const key = this.nodeConnectLineDataDict.keys()[i];
            let line = this.nodeConnectLineDataDict.get(key);
            if (this.matrixHit(rect, line)) {
                list.push(line);
            }
        }

        for (let i = 0; i < this.mainMindNodeUnit.encircleMindElementDataDict.keys().length; i++) {
            const key = this.mainMindNodeUnit.encircleMindElementDataDict.keys()[i];
            let line = this.mainMindNodeUnit.encircleMindElementDataDict.get(key);
            if (line != null && this.matrixHit(rect, line)) {
                list.push(line);
            }
        }

        for (let i = 0; i < this.timeMindTypeNodeUnit.mainMindElementDataDict.keys().length; i++) {
            const key = this.timeMindTypeNodeUnit.mainMindElementDataDict.keys()[i];
            let node = this.timeMindTypeNodeUnit.mainMindElementDataDict.get(key);
            if (this.matrixHit(rect, node.head)) {
                list.push(node.head);
            }
            if (this.matrixHit(rect, node.title)) {
                list.push(node.title);
            }
            if (this.matrixHit(rect, node.desc)) {
                list.push(node.desc);
            }
        }

        if (this.timeMindTypeNodeUnit.title != null && this.matrixHit(rect, this.timeMindTypeNodeUnit.title)) {
            list.push(this.timeMindTypeNodeUnit.title);
        }
        if (this.timeMindTypeNodeUnit.baseLine != null && this.timeMindTypeNodeUnit.baseLine.timeLineContent != null &&
            this.matrixHit(rect, this.timeMindTypeNodeUnit.baseLine)) {
            list.push(this.timeMindTypeNodeUnit.baseLine);
        }
        return list;
    }

    matrixHit(rect, data) {
        if (data.isHidden) {
            return false;
        }
        if (data.isHandwritingContent()) {
            return this.matrixHitHandwritingContent(rect, data);
        }
        let crossArea = Util.getCrossArea(rect, new CGRect(data.x, data.y, data.width,  data.height));
        return crossArea >= new UiUtil().dip2px(5);
    }

    matrixHitHandwritingContent(rect, data) {
        if (data.handwritingContent == null) {
            return false;
        }
        switch (data.handwritingContent.type) {
            case HandwritingType.FREE_WRITE:
                if (Util.containsInRectForPoint(rect, new CGPoint(data.handwritingContent.startPointX + data.x, data.handwritingContent.startPointY + data.y))) {
                    return true;
                }
                if (Util.containsInRectForPoint(rect, new CGPoint(data.handwritingContent.endPointX + data.x, data.handwritingContent.endPointY + data.y))) {
                    return true;
                }
                let count = data.handwritingContent.points.length;
                    for (let index = 0; index < count; index++) {
                        let cell = data.handwritingContent.points[index];
                    if (Util.containsInRectForPoint(rect, new CGPoint(cell.x + data.x, cell.y + data.y))) {
                        return true;
                    }
                }
                break;
            case HandwritingType.STRAIGHT_LINE:
            case HandwritingType.ARROW:
                if (Util.isLineIntersectRect(rect,
                        new CGPoint(data.handwritingContent.startPointX + data.x, data.handwritingContent.startPointY + data.y),
                        new CGPoint(data.handwritingContent.endPointX + data.x, data.handwritingContent.endPointY + data.y))) {
                    return true;
                }
                break;
            case HandwritingType.RECTANGLE:
                let point1 = data.handwritingContent.startPoint();
                let point2 = data.handwritingContent.endPoint();

                let hit = Util.isLineIntersectRect(rect,
                        new CGPoint(point1.x + data.x, point1.y + data.y),
                        new CGPoint(point2.x + data.x, point1.y + data.y));
                if (hit) {
                    return true;
                }
                hit = Util.isLineIntersectRect(rect,
                        new CGPoint(point2.x + data.x, point1.y + data.y),
                        new CGPoint(point2.x + data.x, point2.y + data.y));
                if (hit) {
                    return true;
                }
                hit = Util.isLineIntersectRect(rect,
                        new CGPoint(point2.x + data.x, point2.y + data.y),
                        new CGPoint(point1.x + data.x, point2.y + data.y));
                if (hit) {
                    return true;
                }
                hit = Util.isLineIntersectRect(rect,
                        new CGPoint(point1.x + data.x, point2.y + data.y),
                        new CGPoint(point1.x + data.x, point1.y + data.y));
                if (hit) {
                    return true;
                }
                break;
            case HandwritingType.CIRCULAR:
                let radiusX = Math.abs(data.handwritingContent.endPointX - data.handwritingContent.startPointX) / 2;
                let radiusY = Math.abs(data.handwritingContent.endPointY - data.handwritingContent.startPointY) / 2;
                let cx = data.handwritingContent.startPointX + (data.handwritingContent.endPointX - data.handwritingContent.startPointX) / 2;
                let cy = data.handwritingContent.startPointY + (data.handwritingContent.endPointY - data.handwritingContent.startPointY) / 2;
                let points = Util.getEllipsePoints(radiusX, radiusY, cx, cy);

                for (let index = 0; index < points.length; index++) {
                    if (index == 0) {
                        continue;
                    }
                    if (Util.isLineIntersectRect(rect,
                            new CGPoint(points[index - 1].x + data.x, points[index - 1].y + data.y),
                            new CGPoint(points[index].x + data.x, points[index].y + data.y))) {
                        return true;
                    }
                }
                break;
        }
        return false;
    }

    straightAndPointHit(point, line) {
        if (line.length != 2) {
            return false;
        }
        let space = new UiUtil().dip2px(10);
        let startPoint = line[0];
        let endPoint = line[1];

        let checkRect = new CGRect((startPoint.x < endPoint.x ? startPoint.x : endPoint.x) - space,
            (startPoint.y < endPoint.y ? startPoint.y : endPoint.y) - space,
            Math.abs(startPoint.x - endPoint.x) + space * 2,
            Math.abs(startPoint.y - endPoint.y) + space * 2);
        if (point.x < checkRect.x ||
            point.x > checkRect.x + checkRect.width() ||
            point.y < checkRect.y ||
            point.y > checkRect.y + checkRect.height()) {
            return false;
        }
        let horizontalSpaceing = Util.getPointAndLineHorizontalSpaceing(point, line[0], line[1]);
        let verticalSpaceing = Util.getPointAndLineVerticalSpaceing(point, line[0], line[1]);
        return horizontalSpaceing != -1 && horizontalSpaceing < space || verticalSpaceing != -1 && verticalSpaceing < space;
    }

    getNodeById(id) {
        let node = this.mainMindNodeUnit.getNodeById(id);
        if (!node.isEmpty()) {
            return node;
        }
        for (let i = 0; i < freeNodes.length; i++) {
            const unit = freeNodes[i];
            node = unit.getNodeById(id);
            if (!node.isEmpty()) {
                return node;
            }
        }
        node = this.doubleBubbleMindNodeUnit.getNodeById(id);
        if (!node.isEmpty()) {
            return node;
        }
        if (node.isEmpty() && this.nodeConnectLineDataDict.keys().containsKey(id)) {
            return new LineMindTypeNode(this.nodeConnectLineDataDict.get(id));
        }
        if (node.isEmpty()) {
            return new LineMindTypeNode(this.timeMindTypeNodeUnit.getNodeById(id));
        }
        return node;
    }
}

export default CalculSelectedNodesByRect