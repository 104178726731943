// package mind.yushu.com.mindmap.viewmodel.mindelementdata.mindcontent;

// import android.graphics.Point;

// import java.util.ArrayList;
// import java.util.List;

import MindElementContent from './MindElementContent.js';

import Point from '../../core/base/Point';

// import mind.yushu.com.mindmap.utils.Colors;
import Colors from '../../../utils/Colors';
import HandwritingType from '../../datatype/HandwritingType.js';
import Size from '../../core/base/Size.js';

/**
 * Created by tony on 2019/12/23
 */

class HandwritingContent extends MindElementContent {
    constructor(type = HandwritingType.FREE_WRITE, 
                startPoint = {x:0,y:0},
                endPoint = {x:0,y:0},
                color = Colors.clear, 
                strokeColor = Colors.black60) {
        super();
        this.color = color;
        this.type = type
        this.strokeColor = strokeColor;
        this.strokeColorAlpha = 1.0;
        this.colorAlpha = 1.0;
        this.lineWidth = 1.5;
        this.cornerRadius = 4;
        this.targetId = -1;

        this.startPointX = startPoint.x;
        this.startPointY = startPoint.y;
        this.endPointX = endPoint.x;
        this.endPointY = endPoint.y;
        this.points = []

        this.lineThicken = false;
        this.dottedLine = false;

        this.setStartPoint(startPoint)
        this.setEndPoint(endPoint)
    }

    //默认的钩子函数
    setLineElementContent(startPoint,endPoint,color) {        
        this.setStartPoint(startPoint);
        this.setEndPoint(endPoint);
        this.color = color;
        //某个判断条件，用来执行LineElementContentTwo的函数
    }

    setStartPoint(startPoint) {
        this.startPointX = startPoint.x;
        this.startPointY = startPoint.y;
    }

    setEndPoint(endPoint) {
        this.endPointX = endPoint.x;
        this.endPointY = endPoint.y;
    }

    startPoint() {
        return new Point(this.startPointX, this.startPointY);// .init(x: CGFloat(startPointX), y: CGFloat(startPointY))
    }

    endPoint() {
        return new Point(this.endPointX, this.endPointY);
    }

    copy() {
        let data = new HandwritingContent();
        data.x = this.x
        data.y = this.y
        data.width = this.width
        data.height = this.height
        
        data.type = this.type
        data.color = this.color
        data.colorAlpha = this.colorAlpha
        data.strokeColor = this.strokeColor
        data.strokeColorAlpha = this.strokeColorAlpha
        data.lineWidth = this.lineWidth
        data.cornerRadius = this.cornerRadius
        
        data.startPointX = this.startPointX
        data.startPointY = this.startPointY
        data.endPointX = this.endPointX
        data.endPointY = this.endPointY
        
        data.targetId = this.targetId
        data.lineThicken = this.lineThicken
        
        data.dottedLine = this.dottedLine

        let pointsCount = this.points.length
        data.points = new Array()
        if (pointsCount > 0) {
            for (let index = 0; index < pointsCount; index++) {
                data.points.push(this.points[index])
            }
        }
        return data;
    }

    stickStyle(data) {
        if (data == null) {
            return;
        }
        this.color = data.color
        this.colorAlpha = data.colorAlpha
        this.strokeColor = data.strokeColor
        this.strokeColorAlpha = data.strokeColorAlpha
        this.lineWidth = data.lineWidth
        this.cornerRadius = data.cornerRadius
        this.lineThicken = data.lineThicken
        this.dottedLine = data.dottedLine
    }

    stickColorStyle(data) {
        if (data == null) {
            return;
        }
        this.color = data.color
        this.colorAlpha = data.colorAlpha
        this.strokeColor = data.strokeColor
        this.strokeColorAlpha = data.strokeColorAlpha
        this.lineThicken = data.lineThicken
    }

    equal(data, ignorePoint = false, ignoreSize = false) {
        let result = false;
        if (!ignorePoint) {
            result = result || data.x != this.x
            result = result || data.y != this.y
        }
        if (!ignoreSize) {
            result = result || data.width != this.width
            result = result || data.height != this.height
        }
        
        result = result || data.color != this.color
        result = result || data.colorAlpha != this.colorAlpha
        result = result || data.strokeColor != this.strokeColor
        result = result || data.strokeColorAlpha != this.strokeColorAlpha
        result = result || data.lineWidth != this.lineWidth
        result = result || data.cornerRadius != this.cornerRadius
        result = result || data.type != this.type
        
        if (!ignorePoint) {
            result = result || data.startPointX != this.startPointX
            result = result || data.startPointY != this.startPointY
            result = result || data.endPointX != this.endPointX
            result = result || data.endPointY != this.endPointY
        }
        
        result = result || data.targetId != this.targetId
        result = result || data.lineThicken != this.lineThicken
        result = result || data.dottedLine != this.dottedLine
        result = result || data.points.count != this.points.count
        
        if (!result && !ignoreSize) {
            let pointsCount = this.points.length
            if (pointsCount > 0) {
                for (let index = 0; index < pointsCount; index++) {
                    result = result || data.points[index].x != this.points[index].x
                    result = result || data.points[index].y != this.points[index].y
                    if (result) {
                        return result;
                    }
                }
            }
        }
        return !result;
    }

    getSizeByPoint() {
        var left = Math.min(this.startPointX, this.endPointX);
        var right = Math.max(this.startPointX, this.endPointX);

        var top = Math.min(this.startPointY, this.endPointY);
        var bottom = Math.max(this.startPointY, this.endPointY);
        if (this.type == HandwritingType.FREE_WRITE) {
             let pointsCount = this.points.length
             if (pointsCount > 0) {
                 for (let index = 0; index < pointsCount; index++) {
                     let point = this.points[index]
                     left = Math.min(left, point.x);
                     right = Math.max(right, point.x);

                     top = Math.min(top, point.y);
                     bottom = Math.max(bottom, point.y);
                }
             }
          }
        return new Size(Math.max(Math.abs(right - left), 1.0), Math.max(Math.abs(bottom - top), 1.0))
    }
}

export default HandwritingContent