import Colors from "../../../utils/Colors"
import LinePath from "../../../viewmodel/core/base/LinePath"
import Point from "../../../viewmodel/core/base/Point"

import UiUtil from '../../../utils/UiUtil'
import LineLayout from "../../../viewmodel/datatype/LineLayout"
import LineOrientation from "../../../viewmodel/datatype/LineOrientation"

import NodeLayoutType from "../../../viewmodel/datatype/NodeLayoutType"

/**
 * Created by tony on 2019/12/23.
 */
import BaseElementView from "./BaseElementView";
import Config from "../../../core/core/calcule/Config"
import MindElementType from "../../../viewmodel/datatype/MindElementType"
import Util from "../../../utils/Util"
import CGPoint from "../../../viewmodel/core/base/basedata/CGPoint"
import { setTimeout } from "core-js"
import MindElementCalculation from "../../../core/core/calcule/elementCalculation/MindElementCalculation"
import EditMindmapVM from "../../../viewmodel/mindelementdata/vm/EditMindmapVM"

class SubjectLineView extends BaseElementView {
    constructor(viewCanvs, data) {
        
        super(viewCanvs, data);
        this.path;  //线条
        this.textPathPath;  //线条
        this.arrow; //箭头
        this.lineFullWidth = 4;
        this.startPoint;
        this.endPoint;
        this.lineWidth = 1;
        this.lineThicken = false;
        this.color;
        this.isAnimation = false;
        this.duration = 1;
        this.textPath = null;
        this.textObj = null;
        this.circularViewList = []
        this.isFormLeftRight = true
        this.onDraw();
    }

    setAnimation(animation, duration) {
        this.isAnimation = animation;
        this.duration = duration;
        this.onDraw();
        this.isAnimation = false;
    }

    refresh() {
        super.refresh();
        if (this.isRefresh) {
            this.onDraw();
        }
    }

    getPathId() {
        return "linepath" + this.mindElementData.id
    }

    getTextPathId() {
        return "textPath" + this.mindElementData.id
    }

    getLineWidth() {
        if (this.mindElementData == null || this.mindElementData.lineContent == null) {
            return 1;
        }
        if (this.isFreeLine()) {
            return Math.max(this.mindElementData.lineContent.cornerRadius, 0.3)
        }
        if (this.mindElementData.type == MindElementType.LAYOUT_FISH_RIGHT_LINE) {
            return this.UiUtil.dip2px(this.mindElementData.lineContent.lineWidth * 2);
        } else {
            return this.UiUtil.dip2px(this.mindElementData.lineContent.lineWidth);
        }
    }

    // @Override
    onDraw() {
        //在constructor定义的值返回undefined
        this.lineFullWidth = 4
        if (this.mindElementData == null || this.mindElementData.lineContent == null) {
            return;
        }
        this.drawLine();
    }

    drawLine() {
        if (this.mindElementData == null || this.mindElementData.lineContent == null) {
            return;
        }
        if (this.arrow != null) {    //删除箭头
            this.arrow.parentNode.removeChild(this.arrow);
            this.arrow = null
        }
        if (this.textObj != null) {
            this.textObj.parentNode.removeChild(this.textObj);
            this.textObj = null
        }
        if (this.circularViewList.length > 0) {
            for (let i = 0; i < this.circularViewList.length; i++) {
                if (this.circularViewList[i] != null && this.circularViewList[i].parentNode != null) {
                    this.circularViewList[i].parentNode.removeChild(this.circularViewList[i]);
                }
            }
            this.circularViewList = []
        }
        let lineMindElementContent = this.mindElementData.lineContent;
        this.startPoint = lineMindElementContent.startPoint();
        this.endPoint = lineMindElementContent.endPoint();

        this.lineThicken = this.mindElementData.type != MindElementType.LAYOUT_FISH_RIGHT_LINE && 
                            this.mindElementData.type != MindElementType.LAYOUT_CUSTOM_LINE &&
                            lineMindElementContent.lineThicken;
        this.lineWidth = this.getLineWidth();

        this.color = lineMindElementContent.color
        if (this.path == null) {
            this.path = document.createElementNS('http://www.w3.org/2000/svg', "path")
            this.context.appendChild(this.path)
            this.path.setAttribute("id", this.getPathId())
        }
        
        if (lineMindElementContent.lineWidth == 0) {
            this.path.style.display = "none"
            return;
        } else {
            this.path.style.display = "block"
        }
        this.lineFullWidth = lineMindElementContent.lineWidth * 3;
        if (lineMindElementContent.lineLayout == LineLayout.CURVE_LINE ||
            lineMindElementContent.lineLayout == LineLayout.CURVE_LINE_CIRCULAR) {
            this.lineFullWidth = Math.min(lineMindElementContent.lineWidth * 1.5, 3)
        } else if (lineMindElementContent.lineLayout == LineLayout.STRAIGHT_CURVE_LINE) {
            this.lineFullWidth = Math.min(lineMindElementContent.lineWidth * 2.2, 5.5)
        }
        if ((this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_LEFT ||
            this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_RIGHT ||
            this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT ||
            this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT)) {
                if ((lineMindElementContent.lineLayout == LineLayout.CURVE_LINE || 
                    lineMindElementContent.lineLayout == LineLayout.STRAIGHT_CURVE_LINE ||
                    lineMindElementContent.lineLayout == LineLayout.CURVE_LINE_CIRCULAR ||
                    lineMindElementContent.lineLayout == LineLayout.CURVE_LINE_2)) {
                    this.lineFullWidth = lineMindElementContent.lineWidth;
                } else {
                    this.lineFullWidth = lineMindElementContent.lineWidth * 1.5;
                }
        }
        if (this.lineFullWidth < 2) {
            this.lineFullWidth = 2;
        }
        if (!this.isFreeLine() && lineMindElementContent.lineLayout == LineLayout.CURVE_LINE_CIRCULAR && this.lineWidth > 0) {
            let space = this.UiUtil.dip2px(2);
            let radius = Math.max(this.lineWidth/2*3, this.UiUtil.dip2px(1));
            if (lineMindElementContent.orientation == LineOrientation.RIGHT) {
                this.endPoint.x = this.endPoint.x - radius - space;
            } else if (lineMindElementContent.orientation == LineOrientation.LEFT) {
                this.endPoint.x = this.endPoint.x + radius + space;
            } else if (lineMindElementContent.orientation == LineOrientation.BOTTOM) {
                this.endPoint.y = this.endPoint.y - radius - space;
            } else if (lineMindElementContent.orientation == LineOrientation.TOP) {
                this.endPoint.y = this.endPoint.y + radius + space;
            }
            this.drawCircular( this.endPoint, radius, lineMindElementContent.color, 0, 0);
        }
        if (this.mindElementData.isShadow && Math.abs(this.startPoint.x - this.endPoint.x) > 0 && Math.abs(this.startPoint.y - this.endPoint.y) > 0) {
            this.context.setAttribute('filter', 'url(#linemapLineShadow)');
        } else {
            this.context.setAttribute('filter', '');
        }

        //这里的LineLayout是引入的枚举对象
        if (this.isFreeLine()) {
            this.drawFreeCurve();
        } else if (this.mindElementData.layout == NodeLayoutType.LAYOUT_CIRCLE ||
            this.mindElementData.layout == NodeLayoutType.LAYOUT_RADIATE) {
            this.drawCircle();
        } else if (lineMindElementContent.lineLayout == LineLayout.CURVE_LINE||
            lineMindElementContent.lineLayout == LineLayout.CURVE_LINE_CIRCULAR) {
            this.drawCurve();
        } else if (lineMindElementContent.lineLayout == LineLayout.STRAIGHT_CURVE_LINE) {
            this.drawStraightCurve();
        } else if (lineMindElementContent.lineLayout == LineLayout.CURVE_LINE_2 ||
            lineMindElementContent.lineLayout == LineLayout.CURVE_LINE_AVERAGE) {
            this.drawCurve2();
        } else if (lineMindElementContent.lineLayout == LineLayout.RIGHT_ANGLE_LINE) {
            this.drawStraightLine(0);
        } else if (lineMindElementContent.lineLayout == LineLayout.STRAIGHT_LINE) {
            if (this.mindElementData.layout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT ||
                this.mindElementData.layout == NodeLayoutType.LAYOUT_BRACKETS_LEFT) {
                this.drawStraightLine(6);
            } else {
                this.drawStraightLine();
            }
        } else if (lineMindElementContent.lineLayout == LineLayout.STRAIGHT_LINE_2) {
            if (this.mindElementData.layout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT ||
                this.mindElementData.layout == NodeLayoutType.LAYOUT_BRACKETS_LEFT) {
                this.drawStraightLine(6);
            } else {
                this.drawStraightLine2();
            }
        } else if (lineMindElementContent.lineLayout == LineLayout.FULL_RIGHT_ANGLE_CORNER_LINE ||
            lineMindElementContent.lineLayout == LineLayout.FULL_RIGHT_ANGLE_CORNER_ARROW_LINE) {
            if (this.mindElementData.layout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT ||
                this.mindElementData.layout == NodeLayoutType.LAYOUT_BRACKETS_LEFT) {
                this.drawStraightLine(6);
            } else {
                this.drawFullStraightLine(6);
            }
        } else if (lineMindElementContent.lineLayout == LineLayout.RIGHT_ANGLE_CORNER_LINE ||
            lineMindElementContent.lineLayout == LineLayout.RIGHT_ANGLE_CORNER_ARROW_LINE) {
            this.drawStraightLine(6);
        } else {
            this.drawStraightLine(6);
        }
    }


    drawFullStraightLine(radius) {
        let linePath = new LinePath()
        let lineMindElementContent = this.mindElementData.lineContent;

        if (lineMindElementContent.lineLayout == LineLayout.RIGHT_ANGLE_CORNER_ARROW_LINE ||
            lineMindElementContent.lineLayout == LineLayout.FULL_RIGHT_ANGLE_CORNER_ARROW_LINE) {
            this.drawArrow();
        }


        linePath.setEffectRadius(radius)
        radius = 0;
        //尝试用js的圆角函数好像不行 

        if (this.lineThicken) {
            if ((this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_RIGHT ||
                this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT) &&
                lineMindElementContent.orientation == LineOrientation.RIGHT) {
                this.startPoint.x += this.lineFullWidth / 2;
            } else if ((this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_LEFT ||
                this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT) &&
                lineMindElementContent.orientation == LineOrientation.LEFT) {
                this.startPoint.x -= this.lineFullWidth / 2;
            }
        }

        linePath.moveTo(this.startPoint.x, this.startPoint.y);

        if (lineMindElementContent.orientation == LineOrientation.RIGHT) {
            linePath.lineTo(this.startPoint.x, this.endPoint.y);
        } else if (lineMindElementContent.orientation == LineOrientation.BOTTOM || lineMindElementContent.orientation == LineOrientation.TOP) {
            linePath.lineTo(this.endPoint.x, this.startPoint.y);
        } else if (lineMindElementContent.orientation == LineOrientation.LEFT) {
            linePath.lineTo(this.startPoint.x, this.endPoint.y);
        }
        
        linePath.lineTo(this.endPoint.x, this.endPoint.y);


        if (this.lineThicken) {
            if (lineMindElementContent.orientation == LineOrientation.RIGHT) {
                linePath.lineTo(this.startPoint.x - this.lineFullWidth, this.endPoint.y);
                linePath.lineTo(this.startPoint.x - this.lineFullWidth, this.startPoint.y);
            } else if (lineMindElementContent.orientation == LineOrientation.BOTTOM || lineMindElementContent.orientation == LineOrientation.TOP) {
                linePath.lineTo(this.endPoint.x, this.startPoint.y - this.lineFullWidth);
                linePath.lineTo(this.startPoint.x, this.startPoint.y - this.lineFullWidth);
            } else if (lineMindElementContent.orientation == LineOrientation.LEFT) {
                linePath.lineTo(this.startPoint.x + this.lineFullWidth, this.endPoint.y);
                linePath.lineTo(this.startPoint.x + this.lineFullWidth, this.startPoint.y);
            }
        }
        // canvas.drawPath(linePath, paint);
        this.renderCanvas(linePath)

    }

    drawStraightLine(radius) {
        //判断传进来几个参数
        if (arguments.length == 0) {
            // final Path linePath = new Path();
            let linePath = new LinePath();
            let tightenWidth = 0;
            let lineMindElementContent = this.mindElementData.lineContent;
            let moveToPoint = new Point();
            let treeSpace = this.UiUtil.dip2px(30)
            if (this.lineThicken) {
                if (this.mindElementData.type == MindElementType.LAYOUT_FISH_RIGHT_LINE) {
                    let fishLineWidth = this.getLineWidth();
                    linePath.moveTo(this.startPoint.x, this.startPoint.y);
                    linePath.lineTo(this.endPoint.x, this.endPoint.y);

                    let fishTailHeight = this.UiUtil.dip2px(40);
                    let fishTailWidth = this.UiUtil.dip2px(50);
                    
                    linePath.cubicTo(
                        this.endPoint.x + fishTailWidth * 0.4,
                        this.endPoint.y - fishTailHeight / 2,
                        this.endPoint.x + fishTailWidth * 0.4,
                        this.endPoint.y - fishTailHeight / 2,
                        this.endPoint.x + fishTailWidth,
                        this.endPoint.y - fishTailHeight);

                    linePath.cubicTo(
                        this.endPoint.x + fishTailWidth * 0.4,
                        this.endPoint.y + fishLineWidth / 2,
                        this.endPoint.x + fishTailWidth * 0.4,
                        this.endPoint.y + fishLineWidth / 2,
                        this.endPoint.x + fishTailWidth,
                        this.endPoint.y + fishTailHeight + fishLineWidth);

                    linePath.cubicTo(
                            this.endPoint.x + fishTailWidth * 0.4,
                            this.endPoint.y + fishTailHeight / 2 + fishLineWidth,
                            this.endPoint.x + fishTailWidth * 0.4,
                            this.endPoint.y + fishTailHeight / 2 + fishLineWidth,
                            this.endPoint.x,
                            this.endPoint.y + fishLineWidth);                            
                    linePath.lineTo(this.endPoint.x, this.endPoint.y + fishLineWidth);
                    linePath.lineTo(this.startPoint.x, this.endPoint.y + fishLineWidth);
                    linePath.lineTo(this.startPoint.x, this.startPoint.y);
                } else if (this.mindElementData.type == MindElementType.LAYOUT_CUSTOM_LINE) {
                    linePath.moveTo(this.startPoint.x, this.startPoint.y);
                    linePath.lineTo(this.endPoint.x, this.endPoint.y);
                } else if ((this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_RIGHT ||
                    this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT) &&
                    lineMindElementContent.orientation == LineOrientation.RIGHT) {
                    linePath.moveTo(this.startPoint.x - this.lineFullWidth / 2, this.startPoint.y);
                    if (this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_RIGHT) {
                        linePath.lineTo(this.startPoint.x - this.lineFullWidth / 2, this.endPoint.y - treeSpace);
                    } else {
                        linePath.lineTo(this.startPoint.x - this.lineFullWidth / 2, this.endPoint.y + treeSpace);
                    }
                    linePath.lineTo(this.endPoint.x, this.endPoint.y);
                    if (this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_RIGHT) {
                        linePath.lineTo(this.startPoint.x + this.lineFullWidth / 2, this.endPoint.y - treeSpace);
                    } else {
                        linePath.lineTo(this.startPoint.x + this.lineFullWidth / 2, this.endPoint.y + treeSpace);
                    }
                    linePath.lineTo(this.startPoint.x + this.lineFullWidth / 2, this.startPoint.y);
                    linePath.lineTo(this.startPoint.x, this.startPoint.y);
                } else if ((this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_LEFT  ||
                    this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT) &&
                    lineMindElementContent.orientation == LineOrientation.LEFT) {
                    linePath.moveTo(this.startPoint.x - this.lineFullWidth / 2, this.startPoint.y);
                    if (this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_LEFT) {
                        linePath.lineTo(this.startPoint.x + this.lineFullWidth / 2, this.endPoint.y - treeSpace);
                    } else {
                        linePath.lineTo(this.startPoint.x + this.lineFullWidth / 2, this.endPoint.y + treeSpace);
                    }
                    linePath.lineTo(this.endPoint.x, this.endPoint.y);
                    if (this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_LEFT) {
                        linePath.lineTo(this.startPoint.x - this.lineFullWidth / 2, this.endPoint.y - treeSpace);
                    } else {
                        linePath.lineTo(this.startPoint.x - this.lineFullWidth / 2, this.endPoint.y + treeSpace);
                    }
                    linePath.lineTo(this.startPoint.x - this.lineFullWidth / 2, this.startPoint.y);
                    linePath.lineTo(this.startPoint.x, this.startPoint.y);
                } else if (this.mindElementData.layout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT ||
                            this.mindElementData.layout == NodeLayoutType.LAYOUT_BRACKETS_LEFT) {
                    // linePath.moveTo(this.startPoint.x, this.startPoint.y - this.lineFullWidth / 2);
                    // linePath.lineTo(this.endPoint.x, this.startPoint.y - this.lineFullWidth / 2);
                    // linePath.lineTo(this.endPoint.x, this.endPoint.y);
                    // linePath.lineTo(this.endPoint.x, this.startPoint.y + this.lineFullWidth / 2);
                    // linePath.lineTo(this.startPoint.x, this.startPoint.y + this.lineFullWidth / 2);
                    // linePath.lineTo(this.startPoint.x, this.startPoint.y - this.lineFullWidth / 2);
                    // linePath.lineTo(this.endPoint.x, this.startPoint.y - this.lineFullWidth / 2);
                    linePath.moveTo(this.startPoint.x, this.startPoint.y);
                    linePath.lineTo(this.endPoint.x, this.startPoint.y);
                    linePath.lineTo(this.endPoint.x, this.endPoint.y);
                    this.lineThicken = false
                } else if (this.mindElementData.layout == NodeLayoutType.LAYOUT_BOTTOM ||
                    this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP) {
                    let space = this.mindElementData.layout == NodeLayoutType.LAYOUT_BOTTOM ? 8 : -8;
                    let middlePoint = new CGPoint(this.startPoint.x, this.startPoint.y + space);
                    if (this.startPoint.y > this.endPoint.y) {
                        middlePoint = new CGPoint(this.startPoint.x, this.startPoint.y - space);
                    }

                    linePath.moveTo(this.startPoint.x - this.lineFullWidth / 2, this.startPoint.y);
                    linePath.lineTo(middlePoint.x - this.lineFullWidth / 2, middlePoint.y);
                    linePath.lineTo(this.endPoint.x, this.endPoint.y);
                    linePath.lineTo(middlePoint.x + this.lineFullWidth / 2, middlePoint.y);
                    linePath.lineTo(this.startPoint.x + this.lineFullWidth / 2, this.startPoint.y);
                } else {
                    let lineLenght = Util.getPointSpacing(this.startPoint, this.endPoint);
                    let angle = Util.getDegreesByTwoPoints(this.endPoint, this.startPoint);
                    let includedAngle = Util.getTanDegrees((this.lineFullWidth)/2.0/lineLenght);
                    let point1 = Util.getCirclePoint(this.endPoint, angle + includedAngle, lineLenght + this.lineFullWidth/2);
                    let point2 = Util.getCirclePoint(this.endPoint, angle - includedAngle, lineLenght + this.lineFullWidth/2);
                    linePath.moveTo(this.endPoint.x, this.endPoint.y);
                    linePath.lineTo(point1.x, point1.y);
                    linePath.lineTo(point2.x, point2.y);
                    linePath.lineTo(this.endPoint.x, this.endPoint.y);
                }
                this.renderCanvas(linePath)
            } else {
                if (this.mindElementData.type == MindElementType.LAYOUT_FISH_RIGHT_LINE) {
                    if (this.mindElementData.layout == NodeLayoutType.LAYOUT_LEFT) {
                        let fishLineWidth = this.getLineWidth();
                        let headPoint = lineMindElementContent.curveStartPoint()
                        let headHeight = Math.abs(headPoint.x - this.mindElementData.width) * 1.45 * 0.5
                        let topPoint = new CGPoint(this.mindElementData.width - headHeight * 0.2, headPoint.y - headHeight)
                        let bottomPoint = new CGPoint(this.mindElementData.width - headHeight * 0.2, headPoint.y + headHeight + fishLineWidth)
                        let headTopSpace = Util.getPointSpacing(headPoint, topPoint)
                        let headBottomSpace = Util.getPointSpacing(headPoint, bottomPoint)
                        let topDegrees = Util.getCircleDegreesInPoint(headPoint, topPoint)
                        let bottomDegrees = Util.getCircleDegreesInPoint(bottomPoint, headPoint)
    
                        let topStartControlPoint = Util.getCirclePoint(headPoint, topDegrees - 10, headTopSpace * 0.25)
                        let topEndControlPoint = Util.getCirclePoint(headPoint, topDegrees + 15, headTopSpace * 0.5)
    
                        let bottomStartControlPoint = Util.getCirclePoint(bottomPoint, bottomDegrees + 10, headBottomSpace * 0.3)
                        let bottomEndControlPoint = Util.getCirclePoint(bottomPoint, bottomDegrees + 5, headBottomSpace * 0.75)
    
                        let eyePoint = Util.getCirclePoint(headPoint, topDegrees - 10, headTopSpace * 0.55)
                        this.drawCircular(eyePoint, 7, EditMindmapVM.mindBGColor, 1, Colors.clear)
    
                        linePath.moveTo(headPoint.x, headPoint.y);
                        
                        linePath.cubicTo(
                            topStartControlPoint.x,
                            topStartControlPoint.y,
                            topEndControlPoint.x,
                            topEndControlPoint.y,
                            topPoint.x,
                            topPoint.y);
                        linePath.cubicTo(
                                this.endPoint.x,
                                this.endPoint.y - headHeight/2,
                                this.endPoint.x,
                                this.endPoint.y,
                                this.endPoint.x,
                                this.endPoint.y);
    
                        linePath.lineTo(this.endPoint.x, this.endPoint.y);
                        linePath.lineTo(this.startPoint.x, this.startPoint.y);
    
                        let fishTailHeight = this.UiUtil.dip2px(40);
                        let fishTailWidth = this.UiUtil.dip2px(50);
    
                        linePath.cubicTo(
                            this.startPoint.x - fishTailWidth * 0.4,
                            this.startPoint.y - fishTailHeight / 2,
                            this.startPoint.x - fishTailWidth * 0.4,
                            this.startPoint.y - fishTailHeight / 2,
                            this.startPoint.x - fishTailWidth,
                            this.startPoint.y - fishTailHeight);
    
                        linePath.cubicTo(
                            this.startPoint.x - fishTailWidth * 0.4,
                            this.startPoint.y + fishLineWidth / 2,
                            this.startPoint.x - fishTailWidth * 0.4,
                            this.startPoint.y + fishLineWidth / 2,
                            this.startPoint.x - fishTailWidth,
                            this.startPoint.y + fishTailHeight + fishLineWidth);
    
                        linePath.cubicTo(
                                this.startPoint.x - fishTailWidth * 0.4,
                                this.startPoint.y + fishTailHeight / 2 + fishLineWidth,
                                this.startPoint.x - fishTailWidth * 0.4,
                                this.startPoint.y + fishTailHeight / 2 + fishLineWidth,
                                this.startPoint.x,
                                this.startPoint.y + fishLineWidth);                            
                        linePath.lineTo(this.startPoint.x, this.startPoint.y + fishLineWidth);
                        linePath.lineTo(this.endPoint.x, this.endPoint.y + fishLineWidth);
    
                        linePath.cubicTo(
                            this.endPoint.x,
                            this.endPoint.y + fishLineWidth,
                            this.endPoint.x,
                            this.endPoint.y + fishLineWidth + headHeight/2,
                            bottomPoint.x,
                            bottomPoint.y);
    
                        linePath.cubicTo(
                            bottomStartControlPoint.x,
                            bottomStartControlPoint.y,
                            bottomEndControlPoint.x,
                            bottomEndControlPoint.y,
                            headPoint.x,
                            headPoint.y);
                    } else {
                        let fishLineWidth = this.getLineWidth();
                        let headPoint = lineMindElementContent.curveStartPoint()
                        let headHeight = Math.abs(headPoint.x) * 1.45 * 0.5
                        let topPoint = new CGPoint(headHeight * 0.2, headPoint.y - headHeight)
                        let bottomPoint = new CGPoint(headHeight * 0.2, headPoint.y + headHeight + fishLineWidth)
                        let headTopSpace = Util.getPointSpacing(headPoint, topPoint)
                        let headBottomSpace = Util.getPointSpacing(headPoint, bottomPoint)
                        let topDegrees = Util.getCircleDegreesInPoint(headPoint, topPoint)
                        let bottomDegrees = Util.getCircleDegreesInPoint(bottomPoint, headPoint)

                        let topStartControlPoint = Util.getCirclePoint(headPoint, topDegrees + 10, headTopSpace * 0.25)
                        let topEndControlPoint = Util.getCirclePoint(headPoint, topDegrees - 15, headTopSpace * 0.5)

                        let bottomStartControlPoint = Util.getCirclePoint(bottomPoint, bottomDegrees - 10, headBottomSpace * 0.3)
                        let bottomEndControlPoint = Util.getCirclePoint(bottomPoint, bottomDegrees - 5, headBottomSpace * 0.75)

                        let eyePoint = Util.getCirclePoint(headPoint, topDegrees + 10, headTopSpace * 0.55)
                        this.drawCircular(eyePoint, 7, EditMindmapVM.mindBGColor, 1, Colors.clear)

                        linePath.moveTo(headPoint.x, headPoint.y);
                        
                        linePath.cubicTo(
                            topStartControlPoint.x,
                            topStartControlPoint.y,
                            topEndControlPoint.x,
                            topEndControlPoint.y,
                            topPoint.x,
                            topPoint.y);
                        linePath.cubicTo(
                                this.startPoint.x,
                                this.startPoint.y - headHeight/2,
                                this.startPoint.x,
                                this.startPoint.y,
                                this.startPoint.x,
                                this.startPoint.y);

                        linePath.lineTo(this.startPoint.x, this.startPoint.y);
                        linePath.lineTo(this.endPoint.x, this.endPoint.y);

                        let fishTailHeight = this.UiUtil.dip2px(40);
                        let fishTailWidth = this.UiUtil.dip2px(50);

                        linePath.cubicTo(
                            this.endPoint.x + fishTailWidth * 0.4,
                            this.endPoint.y - fishTailHeight / 2,
                            this.endPoint.x + fishTailWidth * 0.4,
                            this.endPoint.y - fishTailHeight / 2,
                            this.endPoint.x + fishTailWidth,
                            this.endPoint.y - fishTailHeight);

                        linePath.cubicTo(
                            this.endPoint.x + fishTailWidth * 0.4,
                            this.endPoint.y + fishLineWidth / 2,
                            this.endPoint.x + fishTailWidth * 0.4,
                            this.endPoint.y + fishLineWidth / 2,
                            this.endPoint.x + fishTailWidth,
                            this.endPoint.y + fishTailHeight + fishLineWidth);

                        linePath.cubicTo(
                                this.endPoint.x + fishTailWidth * 0.4,
                                this.endPoint.y + fishTailHeight / 2 + fishLineWidth,
                                this.endPoint.x + fishTailWidth * 0.4,
                                this.endPoint.y + fishTailHeight / 2 + fishLineWidth,
                                this.endPoint.x,
                                this.endPoint.y + fishLineWidth);                            
                        linePath.lineTo(this.endPoint.x, this.endPoint.y + fishLineWidth);
                        linePath.lineTo(this.startPoint.x, this.endPoint.y + fishLineWidth);

                        linePath.cubicTo(
                            this.startPoint.x,
                            this.endPoint.y + fishLineWidth,
                            this.startPoint.x,
                            this.endPoint.y + fishLineWidth + headHeight/2,
                            bottomPoint.x,
                            bottomPoint.y);

                        linePath.cubicTo(
                            bottomStartControlPoint.x,
                            bottomStartControlPoint.y,
                            bottomEndControlPoint.x,
                            bottomEndControlPoint.y,
                            headPoint.x,
                            headPoint.y);
                    }                    

                    // linePath.lineTo(this.startPoint.x, this.startPoint.y);
                } else if (this.mindElementData.type == MindElementType.LAYOUT_CUSTOM_LINE) {
                    linePath.moveTo(this.startPoint.x, this.startPoint.y);
                    linePath.lineTo(this.endPoint.x, this.endPoint.y);
                } else if ((this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_RIGHT ||
                    this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT) &&
                    lineMindElementContent.orientation == LineOrientation.RIGHT) {
                    linePath.moveTo(this.startPoint.x, this.startPoint.y);
                    if (this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_RIGHT) {
                        linePath.lineTo(this.startPoint.x, this.endPoint.y - treeSpace);
                    } else {
                        linePath.lineTo(this.startPoint.x, this.endPoint.y + treeSpace);
                    }
                    linePath.lineTo(this.endPoint.x, this.endPoint.y);
                } else if ((this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_LEFT  ||
                    this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT) &&
                    lineMindElementContent.orientation == LineOrientation.LEFT) {
                    linePath.moveTo(this.startPoint.x, this.startPoint.y);
                    if (this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_LEFT) {
                        linePath.lineTo(this.startPoint.x, this.endPoint.y - treeSpace);
                    } else {
                        linePath.lineTo(this.startPoint.x, this.endPoint.y + treeSpace);
                    }
                    linePath.lineTo(this.endPoint.x, this.endPoint.y);
                } else if (this.mindElementData.layout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT ||
                            this.mindElementData.layout == NodeLayoutType.LAYOUT_BRACKETS_LEFT) {
                    linePath.moveTo(this.startPoint.x, this.startPoint.y);
                    linePath.lineTo(this.endPoint.x, this.startPoint.y);
                    linePath.lineTo(this.endPoint.x, this.endPoint.y);
                } else if (this.mindElementData.layout == NodeLayoutType.LAYOUT_BOTTOM ||
                    this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP) {
                    let space = this.mindElementData.layout == NodeLayoutType.LAYOUT_BOTTOM ? 8 : -8;
                    let middlePoint = new CGPoint(this.startPoint.x, this.startPoint.y + space);
                    if (this.startPoint.y > this.endPoint.y) {
                        middlePoint = new CGPoint(this.startPoint.x, this.startPoint.y - space);
                    }
                    
                    linePath.moveTo(this.startPoint.x, this.startPoint.y);
                    linePath.lineTo(middlePoint.x, middlePoint.y);
                    linePath.lineTo(this.endPoint.x, this.endPoint.y);
                } else {
                    linePath.moveTo(this.startPoint.x, this.startPoint.y);
                    linePath.lineTo(this.endPoint.x, this.endPoint.y);
                }
                this.renderCanvas(linePath)
            }
        } else {

            let linePath = new LinePath()
            let lineMindElementContent = this.mindElementData.lineContent;
            linePath.setEffectRadius(radius)

            let arrowH = 0;
            if (lineMindElementContent.lineLayout == LineLayout.RIGHT_ANGLE_CORNER_ARROW_LINE) {
                arrowH = (new UiUtil).getArrowWidth(this.startPoint, this.endPoint, this.lineWidth);
                this.drawArrow();
            }

            if (this.mindElementData.type == MindElementType.LAYOUT_CUSTOM_LINE) {
                linePath.moveTo(this.startPoint.x, this.startPoint.y);
                linePath.lineTo(this.endPoint.x, this.endPoint.y);
            } else if (this.mindElementData.layout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT && radius > 0) {
                if (this.lineThicken) {
                    linePath.moveTo(this.startPoint.x - this.lineFullWidth / 2, this.startPoint.y);
                } else {
                    linePath.moveTo(this.startPoint.x, this.startPoint.y);
                }
                

                let radiusBackups = radius;
                let moveX = (this.endPoint.x - this.startPoint.x) - arrowH / 2;
                if (this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_RIGHT) {
                    moveX = 0;
                }
                if (radiusBackups > 0 && this.mindElementData.layout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT) {
                    linePath.setEffectRadius(moveX / 2)
                    radius = moveX / 2
                    radiusBackups = radius;
                }
                let pointOne = new Point(moveX / 2, this.startPoint.y)
                linePath.lineTo(pointOne.x, pointOne.y);

                if (this.startPoint.y > this.endPoint.y) {
                    let pointTwo = new Point(moveX / 2, this.endPoint.y)
                    linePath.lineTo(pointTwo.x, pointTwo.y);
                } else if (this.startPoint.y < this.endPoint.y) {
                    let pointTwo = new Point(moveX / 2, this.endPoint.y)
                    linePath.lineTo(pointTwo.x, pointTwo.y);
                } else {
                    let pointTwo = new Point(moveX / 2, this.endPoint.y)
                    linePath.lineTo(pointTwo.x, pointTwo.y);
                }

                linePath.lineTo(this.endPoint.x, this.endPoint.y);
            } else if (this.mindElementData.layout == NodeLayoutType.LAYOUT_BRACKETS_LEFT && radius > 0) {
                if (this.lineThicken) {
                    linePath.moveTo(this.startPoint.x + this.lineFullWidth / 2, this.startPoint.y);
                } else {
                    linePath.moveTo(this.startPoint.x,this.startPoint.y);
                }
                
                if (lineMindElementContent.orientation == LineOrientation.LEFT) {
                    let moveX = Math.abs(this.endPoint.x - this.startPoint.x) - arrowH / 2;
                    radius = moveX / 2;
                    if (Math.abs(this.startPoint.y - this.endPoint.y) < radius) {
                        radius = Math.abs(this.startPoint.y - this.endPoint.y);
                    }
                    linePath.setEffectRadius(radius)
                    linePath.lineTo(this.endPoint.x + radius, this.startPoint.y);
                    if (this.startPoint.y > this.endPoint.y) {
                        linePath.lineTo(this.endPoint.x + radius, this.endPoint.y);
                    } else if (this.startPoint.y < this.endPoint.y) {
                        linePath.lineTo(this.endPoint.x + radius, this.endPoint.y);
                    } else {
                        linePath.lineTo(this.startPoint.x, this.endPoint.y);
                    }
                }
                linePath.lineTo(this.endPoint.x, this.endPoint.y);
            } else {
                let radiusBackups = radius;
                this.lineFullWidth = Config.getLineFullWidth(lineMindElementContent.lineWidth);
                if (this.lineThicken) {
                    if ((this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_RIGHT ||
                        this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT) &&
                        lineMindElementContent.orientation == LineOrientation.RIGHT) {
                        this.startPoint.x += this.lineFullWidth / 2;
                    } else if ((this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_LEFT ||
                        this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT) &&
                        lineMindElementContent.orientation == LineOrientation.LEFT) {
                        this.startPoint.x -= this.lineFullWidth / 2;
                    }
                }

                radius = 0;
                linePath.moveTo(this.startPoint.x, this.startPoint.y);

                if (lineMindElementContent.orientation == LineOrientation.RIGHT) {
                    let moveX = (this.endPoint.x - this.startPoint.x) - arrowH / 2;
                    let middleX = Math.min(moveX / 2, 24)
                    if (this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_RIGHT ||
                        this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT) {
                        if (this.startPoint.y > this.endPoint.y) {
                            linePath.lineTo(this.startPoint.x, this.endPoint.y + (radius));
                        } else if (this.startPoint.y < this.endPoint.y) {
                            linePath.lineTo(this.startPoint.x, this.endPoint.y - (radius));
                        } else {
                            linePath.lineTo(this.startPoint.x, this.endPoint.y);
                        }
                    } else {
                        linePath.lineTo(middleX, this.startPoint.y);
                        linePath.lineTo(middleX, this.startPoint.y); //消除圆角
                        if (this.startPoint.y > this.endPoint.y) {
                            linePath.lineTo(middleX, this.endPoint.y + (radius));
                        } else if (this.startPoint.y < this.endPoint.y) {
                            linePath.lineTo(middleX, this.endPoint.y - (radius));
                        } else {
                            linePath.lineTo(middleX, this.endPoint.y);
                        }
                    }
                } else if (lineMindElementContent.orientation == LineOrientation.BOTTOM) {
                    let moveY = Math.min((this.endPoint.y - this.startPoint.y) - arrowH / 2, 50);
                    linePath.lineTo(this.startPoint.x, moveY / 2);
                    linePath.lineTo(this.startPoint.x, moveY / 2);
                    if (this.startPoint.x > this.endPoint.x) {
                        linePath.lineTo(this.endPoint.x + radius, moveY / 2);
                    } else if (this.startPoint.x < this.endPoint.x) {
                        linePath.lineTo(this.endPoint.x - radius, moveY / 2);
                    } else {
                        linePath.lineTo(this.endPoint.x, moveY / 2);
                    }
                } else if (lineMindElementContent.orientation == LineOrientation.TOP) {
                    let moveY = Math.min((this.startPoint.y - this.endPoint.y) + arrowH / 2, 50)
                    linePath.lineTo(this.startPoint.x, this.startPoint.y - moveY / 2);
                    linePath.lineTo(this.startPoint.x, this.startPoint.y - moveY / 2);
                    if (this.startPoint.x > this.endPoint.x) {
                        linePath.lineTo(this.endPoint.x + radius, this.startPoint.y - moveY / 2);
                    } else if (this.startPoint.x < this.endPoint.x) {
                        linePath.lineTo(this.endPoint.x - radius, this.startPoint.y - moveY / 2);
                    } else {
                        linePath.lineTo(this.endPoint.x, this.startPoint.y - moveY / 2);
                    }
                } else if (lineMindElementContent.orientation == LineOrientation.LEFT) {
                    let moveX = (this.startPoint.x - this.endPoint.x) - arrowH / 2;
                    let middleX = Math.min(moveX / 2, 24)
                    if (this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_LEFT ||
                        this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT) {
                        linePath.lineTo(this.startPoint.x, this.startPoint.y);

                        if (this.startPoint.y > this.endPoint.y) {
                            linePath.lineTo(this.startPoint.x, this.endPoint.y + (radius));
                        } else if (this.startPoint.y < this.endPoint.y) {
                            linePath.lineTo(this.startPoint.x, this.endPoint.y - (radius));
                        } else {
                            linePath.lineTo(this.startPoint.x, this.endPoint.y);
                        }
                    } else {
                        moveX = (this.startPoint.x - moveX / 2) * 2;
                       
                        middleX = this.startPoint.x - Math.min(moveX / 2, 24)
                        linePath.lineTo(middleX, this.startPoint.y);
                        linePath.lineTo(middleX, this.startPoint.y);
                        if (this.startPoint.y > this.endPoint.y) {
                            linePath.lineTo(middleX, this.endPoint.y + (radius));
                        } else if (this.startPoint.y < this.endPoint.y) {
                            linePath.lineTo(middleX, this.endPoint.y - (radius));
                        } else {
                            linePath.lineTo(middleX, this.endPoint.y);
                        }
                    }
                }

            }


            let pointEnd = new Point(this.endPoint.x, this.endPoint.y)
            linePath.lineTo(pointEnd.x, pointEnd.y);


            if (this.lineThicken) {
                if (this.mindElementData.layout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT && radius > 0) {
                    if (lineMindElementContent.orientation == LineOrientation.RIGHT) {
                        let moveX = (this.endPoint.x - this.startPoint.x) - arrowH / 2;
                        let middleX = moveX / 2
                        if (this.startPoint.y > this.endPoint.y) {
                            linePath.lineTo(middleX - this.lineFullWidth / 2, this.endPoint.y);
                            linePath.lineTo(middleX - this.lineFullWidth / 2, this.startPoint.y - this.lineFullWidth / 2);
                            linePath.lineTo(this.startPoint.x - this.lineFullWidth / 2, this.startPoint.y - this.lineFullWidth / 2);
                        } else if (this.startPoint.y < this.endPoint.y) {
                            linePath.lineTo(middleX - this.lineFullWidth / 2, this.endPoint.y);
                            linePath.lineTo(middleX - this.lineFullWidth / 2, this.startPoint.y + this.lineFullWidth / 2);
                            linePath.lineTo(this.startPoint.x - this.lineFullWidth / 2, this.startPoint.y + this.lineFullWidth / 2);
                        } else {
                            linePath.lineTo(middleX - this.lineFullWidth / 2, this.endPoint.y);
                            linePath.lineTo(middleX - this.lineFullWidth / 2, this.startPoint.y);
                        }
                    }
                } else if (this.mindElementData.layout == NodeLayoutType.LAYOUT_BRACKETS_LEFT && radius > 0) {
                    if (lineMindElementContent.orientation == LineOrientation.LEFT) {
                        if (this.startPoint.y > this.endPoint.y) {
                            linePath.lineTo(this.endPoint.x + radius + this.lineFullWidth / 2, this.endPoint.y);
                            linePath.lineTo(this.endPoint.x + radius + this.lineFullWidth / 2, this.startPoint.y - this.lineFullWidth / 2);
                            linePath.lineTo(this.startPoint.x + this.lineFullWidth / 2, this.startPoint.y - this.lineFullWidth / 2);
                        } else if (this.startPoint.y < this.endPoint.y) {
                            linePath.lineTo(this.endPoint.x + radius + this.lineFullWidth / 2, this.endPoint.y);
                            linePath.lineTo(this.endPoint.x + radius + this.lineFullWidth / 2, this.startPoint.y + this.lineFullWidth / 2);
                            linePath.lineTo(this.startPoint.x + this.lineFullWidth / 2, this.startPoint.y + this.lineFullWidth / 2);
                        }
                    }
                } else if (lineMindElementContent.orientation == LineOrientation.RIGHT) {
                    let moveX = (this.endPoint.x - this.startPoint.x) - arrowH / 2;
                    let middleX = Math.min(moveX / 2, 24)
                    if (this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_RIGHT ||
                        this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT) {
                        moveX = 0;
                        if (this.startPoint.y > this.endPoint.y) {
                            linePath.lineTo(this.startPoint.x - this.lineFullWidth, this.endPoint.y);
                            linePath.lineTo(this.startPoint.x - this.lineFullWidth, this.startPoint.y);
                        } else if (this.startPoint.y < this.endPoint.y) {
                            linePath.lineTo(this.startPoint.x - this.lineFullWidth, this.endPoint.y);
                            linePath.lineTo(this.startPoint.x - this.lineFullWidth, this.startPoint.y);
                        } else {
                            linePath.lineTo(this.startPoint.x - this.lineFullWidth, this.endPoint.y);
                            linePath.lineTo(this.startPoint.x - this.lineFullWidth, this.startPoint.y);
                        }
                    } else {
                        if (this.startPoint.y > this.endPoint.y) {
                            linePath.lineTo(middleX - this.lineFullWidth, this.endPoint.y);
                            linePath.lineTo(middleX - this.lineFullWidth, this.startPoint.y - this.lineFullWidth / 2);
                            linePath.lineTo(middleX - this.lineFullWidth, this.startPoint.y - this.lineFullWidth / 2);
                            linePath.lineTo(this.startPoint.x, this.startPoint.y - this.lineFullWidth / 2);
                        } else if (this.startPoint.y < this.endPoint.y) {
                            linePath.lineTo(middleX - this.lineFullWidth, this.endPoint.y);
                            linePath.lineTo(middleX - this.lineFullWidth, this.startPoint.y + this.lineFullWidth / 2);
                            linePath.lineTo(middleX - this.lineFullWidth, this.startPoint.y + this.lineFullWidth / 2);
                            linePath.lineTo(this.startPoint.x, this.startPoint.y + this.lineFullWidth / 2);
                        } else {
                            linePath.lineTo(middleX - this.lineFullWidth, this.endPoint.y);
                            linePath.lineTo(middleX - this.lineFullWidth, this.startPoint.y);
                        }
                    }
                } else if (lineMindElementContent.orientation == LineOrientation.BOTTOM) {
                    let moveY = Math.min((this.endPoint.y - this.startPoint.y) - arrowH / 2, 50);
                    
                    if (this.startPoint.x > this.endPoint.x) {
                        linePath.lineTo(this.endPoint.x, moveY / 2 - this.lineFullWidth)
                        linePath.lineTo(this.startPoint.x - this.lineFullWidth / 2, moveY / 2 - this.lineFullWidth)
                        linePath.lineTo(this.startPoint.x - this.lineFullWidth / 2, moveY / 2 - this.lineFullWidth)
                        linePath.lineTo(this.startPoint.x - this.lineFullWidth / 2, this.startPoint.y)
                    } else if (this.startPoint.x < this.endPoint.x) {
                        linePath.lineTo(this.endPoint.x, moveY / 2 - this.lineFullWidth)
                        linePath.lineTo(this.startPoint.x + this.lineFullWidth / 2, moveY / 2 - this.lineFullWidth)
                        linePath.lineTo(this.startPoint.x + this.lineFullWidth / 2, moveY / 2 - this.lineFullWidth)
                        linePath.lineTo(this.startPoint.x + this.lineFullWidth / 2, this.startPoint.y)
                    } else {
                        linePath.lineTo(this.endPoint.x, moveY / 2 - this.lineFullWidth)
                        linePath.lineTo(this.startPoint.x, moveY / 2 - this.lineFullWidth)
                    }
                } else if (lineMindElementContent.orientation == LineOrientation.TOP) {
                    let moveY =  Math.min((this.startPoint.y - this.endPoint.y) + arrowH / 2, 50);
                    if (this.startPoint.x > this.endPoint.x) {
                        linePath.lineToByPoint(new Point(this.endPoint.x, (this.startPoint.y - moveY / 2) + this.lineFullWidth));
                        linePath.lineToByPoint(new Point(this.startPoint.x - this.lineFullWidth / 2, (this.startPoint.y - moveY / 2) + this.lineFullWidth));
                        linePath.lineToByPoint(new Point(this.startPoint.x - this.lineFullWidth / 2, (this.startPoint.y - moveY / 2) + this.lineFullWidth));
                        linePath.lineToByPoint(new Point(this.startPoint.x - this.lineFullWidth / 2, this.startPoint.y));
                    } else if (this.startPoint.x < this.endPoint.x) {
                        linePath.lineToByPoint(new Point(this.endPoint.x, (this.startPoint.y - moveY / 2) + this.lineFullWidth));
                        linePath.lineToByPoint(new Point(this.startPoint.x + this.lineFullWidth / 2, (this.startPoint.y - moveY / 2) + this.lineFullWidth));
                        linePath.lineToByPoint(new Point(this.startPoint.x + this.lineFullWidth / 2, (this.startPoint.y - moveY / 2) + this.lineFullWidth));
                        linePath.lineToByPoint(new Point(this.startPoint.x + this.lineFullWidth / 2, this.startPoint.y));
                    } else {
                        linePath.lineToByPoint(new Point(this.endPoint.x, (this.startPoint.y - moveY / 2) + this.lineFullWidth));
                        linePath.lineToByPoint(new Point(this.startPoint.x, (this.startPoint.y - moveY / 2) + this.lineFullWidth));
                    }
                } else if (lineMindElementContent.orientation == LineOrientation.LEFT) {
                    let moveX = (this.startPoint.x - this.endPoint.x) - arrowH / 2;
                    let middleX = Math.max(moveX / 2, -24)
                    if (this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_LEFT ||
                        this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT) {
                        moveX = (this.startPoint.x - this.endPoint.x) * 2;
                        if (this.startPoint.y > this.endPoint.y) {
                            linePath.lineTo(this.startPoint.x + this.lineFullWidth, this.endPoint.y + (radius));
                            linePath.lineTo(this.startPoint.x + this.lineFullWidth, this.startPoint.y);
                        } else if (this.startPoint.y < this.endPoint.y) {
                            linePath.lineTo(this.startPoint.x + this.lineFullWidth, this.endPoint.y - (radius));
                            linePath.lineTo(this.startPoint.x + this.lineFullWidth, this.startPoint.y);
                        } else {
                            linePath.lineTo(this.startPoint.x, this.endPoint.y);
                            linePath.lineTo(this.startPoint.x + this.lineFullWidth, this.startPoint.y);
                        }
                    } else {
                        moveX = (this.startPoint.x - moveX / 2) * 2;
                        middleX = this.startPoint.x - Math.min(moveX / 2, 24)
                        
                        if (this.startPoint.y > this.endPoint.y) {
                            linePath.lineTo(middleX + this.lineFullWidth, this.endPoint.y)
                            linePath.lineTo(middleX + this.lineFullWidth, this.startPoint.y - this.lineFullWidth / 2)
                            linePath.lineTo(middleX + this.lineFullWidth, this.startPoint.y - this.lineFullWidth / 2)
                            linePath.lineTo(this.startPoint.x, this.startPoint.y - this.lineFullWidth / 2)
                        } else if (this.startPoint.y < this.endPoint.y) {
                            linePath.lineTo(middleX + this.lineFullWidth, this.endPoint.y)
                            linePath.lineTo(middleX + this.lineFullWidth, this.startPoint.y + this.lineFullWidth / 2)
                            linePath.lineTo(middleX + this.lineFullWidth, this.startPoint.y + this.lineFullWidth / 2)
                            linePath.lineTo(this.startPoint.x, this.startPoint.y + this.lineFullWidth / 2)
                        } else {
                            linePath.lineTo(middleX, this.endPoint.y)
                            linePath.lineTo(middleX + this.lineFullWidth, this.startPoint.y)
                        }
                    }
                }
            }
            this.renderCanvas(linePath)
        }

    }

    drawStraightLine2() {
        let linePath = new LinePath();
        let tightenWidth = 0;
        let lineMindElementContent = this.mindElementData.lineContent;
        let moveToPoint = new Point();
        let space = (lineMindElementContent.orientation == LineOrientation.RIGHT || 
                lineMindElementContent.orientation == LineOrientation.LEFT) ? new UiUtil().dip2px(30) : new UiUtil().dip2px(22);
        let radius = new UiUtil().dip2px(10)

        if (this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_RIGHT ||
            this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_LEFT ||
            this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT ||
            this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT ||
            this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_LEFT_RIGHT ||
            this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT_RIGHT ||
            this.mindElementData.layout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT ||
            this.mindElementData.layout == NodeLayoutType.LAYOUT_BRACKETS_LEFT) {
            space = new UiUtil().dip2px(10);
        }

        let width = this.mindElementData.width;
        let height = this.mindElementData.height;
        let maxSpace = Math.abs((width) * 0.6);
        if (this.mindElementData.layout == NodeLayoutType.LAYOUT_RIGHT ||
            this.mindElementData.layout == NodeLayoutType.LAYOUT_LEFT) {

            let maxHeight = 1200;
            space = space + (Math.abs(height) / maxHeight) * (maxSpace);
            if (space > maxSpace) {
                space = maxSpace
            }
        }

        if (this.lineThicken) {
            if (lineMindElementContent.orientation == LineOrientation.RIGHT) {
                if ((this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_LEFT ||
                    this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_RIGHT ||
                    this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT ||
                    this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT)) {
                        this.startPoint.x = this.startPoint.x - this.lineFullWidth/2;
                } else {
                    this.startPoint.y = this.startPoint.y - this.lineFullWidth/2;
                }
                
                let middlePoint = new Point(this.endPoint.x - space, this.endPoint.y)
                let treeSpace = this.UiUtil.dip2px(30)

                let point0 = this.startPoint;
                let point1 = new Point(middlePoint.x, middlePoint.y);
                let point2 = new Point(this.endPoint.x, this.endPoint.y);
                let startThickenPoint = new Point(point0.x, point0.y + this.lineFullWidth)
                
                if ((this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_LEFT ||
                    this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_RIGHT ||
                    this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT ||
                    this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT) &&
                    Math.abs(middlePoint.y - this.startPoint.y) > treeSpace) {
                    if (middlePoint.y > this.startPoint.y) {
                        point0 = new Point(this.startPoint.x, middlePoint.y - treeSpace)
                        startThickenPoint = new Point(point0.x + this.lineFullWidth, middlePoint.y - treeSpace)
                    } else {
                        point0 = new Point(this.startPoint.x, middlePoint.y + treeSpace)
                        startThickenPoint = new Point(point0.x + this.lineFullWidth, middlePoint.y + treeSpace)
                    }
                }
                
                
                let insertPoints = Util.getInsertPointBetweenCircleAndLine(point0.x, point0.y, point1.x, point1.y,
                                                        point1.x, point1.y, radius)
                let startPointRadiusPoint = null
                let endPointRadiusPoint = null
                let startThickenPointRadiusPoint = null

                let spacing = 90000000
                
                for (let i = 0; i < insertPoints.length; i++) {
                    let dd = Util.getPointSpacing(point0, insertPoints[i]);
                    if (spacing > dd) {
                        startPointRadiusPoint = insertPoints[i]
                        spacing = dd
                    }
                }
                insertPoints = Util.getInsertPointBetweenCircleAndLine(point2.x, point2.y, point1.x, point1.y,
                                                        point1.x, point1.y, radius)
                spacing = 90000000
                
                for (let i = 0; i < insertPoints.length; i++) {
                    let dd = Util.getPointSpacing(point2, insertPoints[i]);
                    if (spacing > dd) {
                        endPointRadiusPoint = insertPoints[i]
                        spacing = dd
                    }
                }

                insertPoints = Util.getInsertPointBetweenCircleAndLine(startThickenPoint.x, startThickenPoint.y, 
                                                        point1.x, point1.y,
                                                        point1.x, point1.y, radius)
                spacing = 90000000
                
                for (let i = 0; i < insertPoints.length; i++) {
                    let dd = Util.getPointSpacing(startThickenPoint, insertPoints[i]);
                    if (spacing > dd) {
                        startThickenPointRadiusPoint = insertPoints[i]
                        spacing = dd
                    }
                }
                linePath.moveTo(this.startPoint.x, this.startPoint.y);
                linePath.lineTo(point0.x, point0.y);
                linePath.lineTo(startPointRadiusPoint.x, startPointRadiusPoint.y);

                linePath.cubicTo(
                    point1.x,
                    point1.y,
                    point1.x,
                    point1.y,
                    endPointRadiusPoint.x,
                    endPointRadiusPoint.y);

                linePath.lineTo(point2.x, point2.y);
                linePath.lineTo(endPointRadiusPoint.x, endPointRadiusPoint.y);

                linePath.cubicTo(
                    point1.x,
                    point1.y,
                    point1.x,
                    point1.y,
                    startThickenPointRadiusPoint.x,
                    startThickenPointRadiusPoint.y);
                

                linePath.lineTo(startThickenPoint.x, startThickenPoint.y);
                linePath.lineTo(startThickenPoint.x, this.startPoint.y);
                linePath.lineTo(this.startPoint.x, this.startPoint.y);
            } else if (lineMindElementContent.orientation == LineOrientation.LEFT) {
                if ((this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_LEFT ||
                    this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_RIGHT ||
                    this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT ||
                    this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT)) {
                        this.startPoint.x = this.startPoint.x - this.lineFullWidth/2;
                } else {
                    this.startPoint.y = this.startPoint.y - this.lineFullWidth/2;
                }

                let middlePoint = new Point(this.endPoint.x + space, this.endPoint.y)
                let treeSpace = this.UiUtil.dip2px(30)

                let point0 = this.startPoint;
                let point1 = new Point(middlePoint.x, middlePoint.y);
                let point2 = new Point(this.endPoint.x, this.endPoint.y);

                let startThickenPoint = new Point(point0.x, point0.y + this.lineFullWidth)
                
                if ((this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_LEFT ||
                    this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_RIGHT ||
                    this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT ||
                    this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT) &&
                    Math.abs(middlePoint.y - this.startPoint.y) > treeSpace) {
                    if (middlePoint.y > this.startPoint.y) {
                        point0 = new Point(this.startPoint.x, middlePoint.y - treeSpace)
                        startThickenPoint = new Point(point0.x + this.lineFullWidth, middlePoint.y - treeSpace)
                    } else {
                        point0 = new Point(this.startPoint.x, middlePoint.y + treeSpace)
                        startThickenPoint = new Point(point0.x + this.lineFullWidth, middlePoint.y + treeSpace)
                    }
                }

                let insertPoints = Util.getInsertPointBetweenCircleAndLine(point0.x, point0.y, point1.x, point1.y,
                                                        point1.x, point1.y, radius)
                let startPointRadiusPoint = null
                let endPointRadiusPoint = null
                let startThickenPointRadiusPoint = null

                let spacing = 90000000
                
                for (let i = 0; i < insertPoints.length; i++) {
                    let dd = Util.getPointSpacing(point0, insertPoints[i]);
                    if (spacing > dd) {
                        startPointRadiusPoint = insertPoints[i]
                        spacing = dd
                    }
                }
                insertPoints = Util.getInsertPointBetweenCircleAndLine(point2.x, point2.y, point1.x, point1.y,
                                                        point1.x, point1.y, radius)
                spacing = 90000000
                
                for (let i = 0; i < insertPoints.length; i++) {
                    let dd = Util.getPointSpacing(point2, insertPoints[i]);
                    if (spacing > dd) {
                        endPointRadiusPoint = insertPoints[i]
                        spacing = dd
                    }
                }

                insertPoints = Util.getInsertPointBetweenCircleAndLine(startThickenPoint.x, startThickenPoint.y, 
                                                        point1.x, point1.y,
                                                        point1.x, point1.y, radius)
                spacing = 90000000
                
                for (let i = 0; i < insertPoints.length; i++) {
                    let dd = Util.getPointSpacing(startThickenPoint, insertPoints[i]);
                    if (spacing > dd) {
                        startThickenPointRadiusPoint = insertPoints[i]
                        spacing = dd
                    }
                }

                linePath.moveTo(this.startPoint.x, this.startPoint.y);
                linePath.moveTo(point0.x, point0.y);
                linePath.lineTo(startPointRadiusPoint.x, startPointRadiusPoint.y);

                linePath.cubicTo(
                    point1.x,
                    point1.y,
                    point1.x,
                    point1.y,
                    endPointRadiusPoint.x,
                    endPointRadiusPoint.y);

                linePath.lineTo(point2.x, point2.y);
                linePath.lineTo(endPointRadiusPoint.x, endPointRadiusPoint.y);

                linePath.cubicTo(
                    point1.x,
                    point1.y,
                    point1.x,
                    point1.y,
                    startThickenPointRadiusPoint.x,
                    startThickenPointRadiusPoint.y);
                
                linePath.lineTo(startThickenPoint.x, startThickenPoint.y);
                linePath.lineTo(startThickenPoint.x, this.startPoint.y);
                linePath.lineTo(this.startPoint.x, this.startPoint.y);

            } else if (lineMindElementContent.orientation == LineOrientation.BOTTOM) {
                let startSpace = 8;
                space = 12;

                this.startPoint.x = this.startPoint.x - this.lineFullWidth/2;
                let middlePoint = new CGPoint(this.startPoint.x, this.startPoint.y + startSpace);
                if (this.startPoint.y > this.endPoint.y) {
                    middlePoint = new CGPoint(this.startPoint.x, this.startPoint.y - startSpace);
                }

                
                linePath.moveTo(this.startPoint.x, this.startPoint.y);
                linePath.lineTo(middlePoint.x, middlePoint.y);
                linePath.lineTo(this.endPoint.x, this.endPoint.y - space);
                linePath.lineTo(this.endPoint.x, this.endPoint.y);
                linePath.lineTo(this.endPoint.x, this.endPoint.y - space);
                linePath.lineTo(middlePoint.x + this.lineFullWidth, middlePoint.y);
                linePath.lineTo(this.startPoint.x + this.lineFullWidth, this.startPoint.y);
                linePath.lineTo(this.startPoint.x, this.startPoint.y);
            } else if (lineMindElementContent.orientation == LineOrientation.TOP) {
                let startSpace = 8;
                space = 12;

                this.startPoint.x = this.startPoint.x - this.lineFullWidth/2;
                let middlePoint = new CGPoint(this.startPoint.x, this.startPoint.y + startSpace);
                if (this.startPoint.y > this.endPoint.y) {
                    middlePoint = new CGPoint(this.startPoint.x, this.startPoint.y - startSpace);
                }
                linePath.moveTo(this.startPoint.x, this.startPoint.y);
                linePath.lineTo(middlePoint.x, middlePoint.y);
                linePath.lineTo(this.endPoint.x, this.endPoint.y + space);
                linePath.lineTo(this.endPoint.x, this.endPoint.y);
                linePath.lineTo(this.endPoint.x, this.endPoint.y + space);
                linePath.lineTo(middlePoint.x + this.lineFullWidth, middlePoint.y);
                linePath.lineTo(this.startPoint.x + this.lineFullWidth, this.startPoint.y);
                linePath.lineTo(this.startPoint.x, this.startPoint.y);
            }
            this.renderCanvas(linePath)
        } else {
            if (lineMindElementContent.orientation == LineOrientation.RIGHT) {
                let middlePoint = new Point(this.endPoint.x - space, this.endPoint.y)
                let treeSpace = this.UiUtil.dip2px(30)

                let point0 = this.startPoint;
                let point1 = new Point(middlePoint.x, middlePoint.y);
                let point2 = new Point(this.endPoint.x, this.endPoint.y);

                if ((this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_LEFT ||
                    this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_RIGHT ||
                    this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT ||
                    this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT) &&
                    Math.abs(middlePoint.y - this.startPoint.y) > treeSpace) {
                    if (middlePoint.y > this.startPoint.y) {
                        point0 = new Point(this.startPoint.x, middlePoint.y - treeSpace)
                    } else {
                        point0 = new Point(this.startPoint.x, middlePoint.y + treeSpace)
                    }
                }

                let insertPoints = Util.getInsertPointBetweenCircleAndLine(point0.x, point0.y, point1.x, point1.y,
                                                        point1.x, point1.y, radius)
                let endPointRadiusPoint = null
                let startPointRadiusPoint = null

                let spacing = 90000000
                
                for (let i = 0; i < insertPoints.length; i++) {
                    let dd = Util.getPointSpacing(point0, insertPoints[i]);
                    if (spacing > dd) {
                        startPointRadiusPoint = insertPoints[i]
                        spacing = dd
                    }
                }
                insertPoints = Util.getInsertPointBetweenCircleAndLine(point2.x, point2.y, point1.x, point1.y,
                                                        point1.x, point1.y, radius)
                spacing = 90000000
                for (let i = 0; i < insertPoints.length; i++) {
                    let dd = Util.getPointSpacing(point2, insertPoints[i]);
                    if (spacing > dd) {
                        endPointRadiusPoint = insertPoints[i]
                        spacing = dd
                    }
                }
                linePath.moveTo(this.startPoint.x, this.startPoint.y);
                linePath.lineTo(point0.x, point0.y);
                linePath.lineTo(startPointRadiusPoint.x, startPointRadiusPoint.y);
                linePath.cubicTo(
                    point1.x,
                    point1.y,
                    point1.x,
                    point1.y,
                    endPointRadiusPoint.x,
                    endPointRadiusPoint.y);

                linePath.lineTo(point2.x, point2.y);
            } else if (lineMindElementContent.orientation == LineOrientation.LEFT) {
                let treeSpace = this.UiUtil.dip2px(30)
                let middlePoint = new Point(this.endPoint.x + space, this.endPoint.y)

                let point0 = this.startPoint;
                let point1 = new Point(middlePoint.x, middlePoint.y);
                let point2 = new Point(this.endPoint.x, this.endPoint.y);

                if ((this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_LEFT ||
                    this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_RIGHT ||
                    this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT ||
                    this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT) &&
                    Math.abs(middlePoint.y - this.startPoint.y) > treeSpace) {
                    if (middlePoint.y > this.startPoint.y) {
                        point0 = new Point(this.startPoint.x, middlePoint.y - treeSpace)
                    } else {
                        point0 = new Point(this.startPoint.x, middlePoint.y + treeSpace)
                    }
                }

                let insertPoints = Util.getInsertPointBetweenCircleAndLine(point0.x, point0.y, point1.x, point1.y,
                                                        point1.x, point1.y, radius)
                let endPointRadiusPoint = null
                let startPointRadiusPoint = null

                let spacing = 90000000
                
                for (let i = 0; i < insertPoints.length; i++) {
                    let dd = Util.getPointSpacing(point0, insertPoints[i]);
                    if (spacing > dd) {
                        startPointRadiusPoint = insertPoints[i]
                        spacing = dd
                    }
                }
                insertPoints = Util.getInsertPointBetweenCircleAndLine(point2.x, point2.y, point1.x, point1.y,
                                                        point1.x, point1.y, radius)
                spacing = 90000000
                for (let i = 0; i < insertPoints.length; i++) {
                    let dd = Util.getPointSpacing(point2, insertPoints[i]);
                    if (spacing > dd) {
                        endPointRadiusPoint = insertPoints[i]
                        spacing = dd
                    }
                }
                linePath.moveTo(this.startPoint.x, this.startPoint.y);
                linePath.lineTo(point0.x, point0.y);
                linePath.lineTo(startPointRadiusPoint.x, startPointRadiusPoint.y);
                linePath.cubicTo(
                    point1.x,
                    point1.y,
                    point1.x,
                    point1.y,
                    endPointRadiusPoint.x,
                    endPointRadiusPoint.y);

                linePath.lineTo(point2.x, point2.y);
            } else if (lineMindElementContent.orientation == LineOrientation.BOTTOM) {
                let startSpace = 8;
                space = 12;
                let middlePoint = new CGPoint(this.startPoint.x, this.startPoint.y + startSpace);
                if (this.startPoint.y > this.endPoint.y) {
                    middlePoint = new CGPoint(this.startPoint.x, this.startPoint.y - startSpace);
                }

                linePath.moveTo(this.startPoint.x, this.startPoint.y);
                linePath.lineTo(middlePoint.x, middlePoint.y);
                linePath.lineTo(this.endPoint.x, this.endPoint.y - space);
                linePath.lineTo(this.endPoint.x, this.endPoint.y);
            } else if (lineMindElementContent.orientation == LineOrientation.TOP) {
                let startSpace = 8;
                space = 12;
                let middlePoint = new CGPoint(this.startPoint.x, this.startPoint.y + startSpace);
                if (this.startPoint.y > this.endPoint.y) {
                    middlePoint = new CGPoint(this.startPoint.x, this.startPoint.y - startSpace);
                }
                linePath.moveTo(this.startPoint.x, this.startPoint.y);
                linePath.lineTo(middlePoint.x, middlePoint.y);
                linePath.lineTo(this.endPoint.x, this.endPoint.y + space);
                linePath.lineTo(this.endPoint.x, this.endPoint.y);
            }
        }
        this.renderCanvas(linePath)
    }

    drawCircle() {
        let path = new LinePath();
        let tightenWidth = 0;
        let lineMindElementContent = this.mindElementData.lineContent;
        let cornerRadius = lineMindElementContent.cornerRadius;

        path.setEffectRadius(0)
        path.moveTo(0, cornerRadius);
        path.circle(cornerRadius, cornerRadius, 0, 0, 1, cornerRadius * 2, cornerRadius);
        path.circle(cornerRadius, cornerRadius, 0, 0, 1, 0, cornerRadius);

        this.renderCanvas(path)
    }

    drawCurve() {
        if (this.mindElementData.layout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT || 
            this.mindElementData.layout == NodeLayoutType.LAYOUT_BRACKETS_LEFT) {
            this.drawCurve2();
            return;
        }
        let path = new LinePath()
        let lineMindElementContent = this.mindElementData.lineContent;
        let treeSpace = this.UiUtil.dip2px(30)
        let moveToPoint = new Point();
        if (lineMindElementContent.orientation == LineOrientation.RIGHT ||
            lineMindElementContent.orientation == LineOrientation.LEFT) {
            if (this.lineThicken) {
                if (Math.abs(this.mindElementData.height) < this.lineFullWidth) {
                    this.startPoint.y = this.startPoint.y - this.lineFullWidth;
                } else {
                    this.startPoint.x = this.startPoint.x - this.lineFullWidth;
                }
            }
            moveToPoint.x = this.startPoint.x;
            moveToPoint.y = this.startPoint.y;

            path.moveTo(moveToPoint.x, moveToPoint.y);
            path.lineTo(this.startPoint.x, this.startPoint.y);

            if (this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_LEFT || 
                this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_RIGHT || 
                this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT || 
                this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT) {
                if (Math.abs(this.startPoint.y - this.endPoint.y) < treeSpace) {
                    path.lineTo(this.startPoint.x, this.endPoint.y);
                } else if (this.startPoint.y < this.endPoint.y) {
                    path.lineTo(this.startPoint.x, this.endPoint.y - treeSpace);
                } else {
                    path.lineTo(this.startPoint.x, this.endPoint.y + treeSpace);
                }
                
                path.cubicTo(
                    this.startPoint.x,
                    this.endPoint.y,
                    this.startPoint.x + ((this.endPoint.x - this.startPoint.x) * 0.2),
                    this.endPoint.y,
                    this.endPoint.x,
                    this.endPoint.y);
            } else {    
                let space = (this.endPoint.x - this.startPoint.x) * 0.2;
                if (space >= 0) {
                    space = Math.min(space, this.UiUtil.dip2px(20));
                } else {
                    space = Math.max(space, -this.UiUtil.dip2px(20));
                }
                path.cubicTo(
                    this.startPoint.x,
                    this.startPoint.y - ((this.startPoint.y - this.endPoint.y) * 0.22),
                    this.startPoint.x + space,
                    this.endPoint.y,
                    this.endPoint.x,
                    this.endPoint.y);
            }
            path.lineTo(this.endPoint.x, this.endPoint.y);

        } else if (lineMindElementContent.orientation == LineOrientation.BOTTOM || 
            lineMindElementContent.orientation == LineOrientation.TOP) {
            if (this.lineThicken) {
                if (this.startPoint.x > this.endPoint.x) {
                    this.startPoint.x = this.startPoint.x - this.lineFullWidth/2;
                } else {
                    this.startPoint.x = this.startPoint.x + this.lineFullWidth/2;
                }
                
            }
            let space = 6;

            moveToPoint.x = this.startPoint.x;
            moveToPoint.y = this.startPoint.y;
            
            let middlePoint = new CGPoint(this.startPoint.x, this.startPoint.y + space);
            path.moveTo(this.startPoint.x, this.startPoint.y);
            if (this.startPoint.y > this.endPoint.y) {
                middlePoint = new CGPoint(this.startPoint.x, this.startPoint.y - space);
            }
            path.lineTo(middlePoint.x, middlePoint.y);
            // let circlePoint = new CGPoint(middlePoint.x + (middlePoint.x - this.endPoint.x), this.endPoint.y)

            // let circleCenterPoint = Util.getCircleCenter(this.endPoint, middlePoint, circlePoint)
            // let r = Util.getPointSpacing(circleCenterPoint, this.endPoint);
            // if (middlePoint.x > this.endPoint.x) {
            //     path.circle(r, r, 0, 0, 0, this.endPoint.x, this.endPoint.y)
            // } else {
            //     path.circle(r, r, 0, 0, 1, this.endPoint.x, this.endPoint.y)
            // }

            path.cubicTo(
                middlePoint.x,
                middlePoint.y + (this.endPoint.y - middlePoint.y) * 0.42,
                this.endPoint.x,
                middlePoint.y + (this.endPoint.y - middlePoint.y) * 0.20,
                this.endPoint.x,
                this.endPoint.y);

            // path.cubicTo(
            //     this.startPoint.x,
            //     this.startPoint.y + (this.endPoint.y - this.startPoint.y) * 0.42,
            //     this.endPoint.x,
            //     this.startPoint.y + (this.endPoint.y - this.startPoint.y) * 0.60,
            //     this.endPoint.x,
            //     this.endPoint.y);
        }

        if (this.lineThicken) {
            if (lineMindElementContent.orientation == LineOrientation.RIGHT ||
                lineMindElementContent.orientation == LineOrientation.LEFT) {
                path.lineTo(this.endPoint.x, this.endPoint.y);
                if (Math.abs(this.mindElementData.height) < this.lineFullWidth) {
                    this.startPoint.y = this.startPoint.y + this.lineFullWidth * 2;
                } else {
                    this.startPoint.x = this.startPoint.x + this.lineFullWidth * 2;
                }
                if (this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_LEFT || 
                    this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_RIGHT || 
                    this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT || 
                    this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT) {
                    if (Math.abs(this.startPoint.y - this.endPoint.y) < treeSpace) {
                        path.cubicTo(
                            this.startPoint.x + (this.endPoint.x - this.startPoint.x) * 0.2,
                            this.endPoint.y,
                            this.startPoint.x,
                            this.startPoint.y - (this.startPoint.y - this.endPoint.y) * 0.22,
                            this.startPoint.x,
                            this.startPoint.y);
                    } else if (this.startPoint.y < this.endPoint.y) {
                        path.cubicTo(
                            this.startPoint.x + (this.endPoint.x - this.startPoint.x) * 0.2,
                            this.endPoint.y,
                            this.startPoint.x,
                            this.endPoint.y,
                            this.startPoint.x,
                            this.endPoint.y - treeSpace);
                    } else {
                        path.cubicTo(
                            this.startPoint.x + (this.endPoint.x - this.startPoint.x) * 0.2,
                            this.endPoint.y,
                            this.startPoint.x,
                            this.endPoint.y,
                            this.startPoint.x,
                            this.endPoint.y + treeSpace);
                    }
                } else {    
                    let space = (this.endPoint.x - this.startPoint.x) * 0.2;
                    if (space >= 0) {
                        space = Math.min(space, this.UiUtil.dip2px(20));
                    } else {
                        space = Math.max(space, -this.UiUtil.dip2px(20));
                    }
                    path.cubicTo(
                        this.startPoint.x + space,
                        this.endPoint.y,
                        this.startPoint.x,
                        this.startPoint.y - (this.startPoint.y - this.endPoint.y) * 0.22,
                        this.startPoint.x,
                        this.startPoint.y);
                }
                path.lineTo(this.startPoint.x, this.startPoint.y);
                path.lineTo(moveToPoint.x, moveToPoint.y);
            } else if (lineMindElementContent.orientation == LineOrientation.BOTTOM || lineMindElementContent.orientation == LineOrientation.TOP) {
                if (this.startPoint.x > this.endPoint.x) {
                    this.startPoint.x = this.startPoint.x + this.lineFullWidth;
                } else {
                    this.startPoint.x = this.startPoint.x - this.lineFullWidth;
                }
                

                let space = 6 + this.lineFullWidth;

                let middlePoint = new CGPoint(this.startPoint.x, this.startPoint.y + space);
                if (this.startPoint.y > this.endPoint.y) {
                    middlePoint = new CGPoint(this.startPoint.x, this.startPoint.y - space);
                }
                
                // let circlePoint = new CGPoint(middlePoint.x + (middlePoint.x - this.endPoint.x), this.endPoint.y)

                // let circleCenterPoint = Util.getCircleCenter(this.endPoint, middlePoint, circlePoint)
                // let r = Util.getPointSpacing(circleCenterPoint, this.endPoint);

                // if (middlePoint.x > this.endPoint.x) {
                //     path.circle(r, r, 0, 0, 1, middlePoint.x, middlePoint.y)
                // } else {
                //     path.circle(r, r, 0, 0, 0, middlePoint.x, middlePoint.y)
                // }
                
                path.cubicTo(
                    this.endPoint.x,
                    middlePoint.y + (this.endPoint.y - middlePoint.y) * 0.20,
                    middlePoint.x,
                    middlePoint.y + (this.endPoint.y - middlePoint.y) * 0.42,
                    middlePoint.x,
                    middlePoint.y);

                path.lineTo(this.startPoint.x, this.startPoint.y);
                

                // path.cubicTo(
                //     this.endPoint.x,
                //     this.startPoint.y + (this.endPoint.y - this.startPoint.y) * 0.60,
                //     this.startPoint.x,
                //     this.startPoint.y + (this.endPoint.y - this.startPoint.y) * 0.42,
                //     this.startPoint.x,
                //     this.startPoint.y);
                path.lineTo(moveToPoint.x, moveToPoint.y);
            }
            this.renderCanvas(path)
        }
        this.renderCanvas(path)
    }

    drawStraightCurve() { // ---
        if (this.mindElementData.layout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT || 
            this.mindElementData.layout == NodeLayoutType.LAYOUT_BRACKETS_LEFT) {
            this.drawCurve2();
            return;
        }
        let path = new LinePath()
        let lineMindElementContent = this.mindElementData.lineContent;
        let treeSpace = this.UiUtil.dip2px(30)
        let moveToPoint = new Point();
        if (lineMindElementContent.orientation == LineOrientation.RIGHT ||
            lineMindElementContent.orientation == LineOrientation.LEFT) {
            if (this.lineThicken) {
                if (Math.abs(this.mindElementData.height) < this.lineFullWidth) {
                    this.startPoint.y = this.startPoint.y - this.lineFullWidth;
                } else {
                    this.startPoint.x = this.startPoint.x - this.lineFullWidth;
                }
            }
            moveToPoint.x = this.startPoint.x;
            moveToPoint.y = this.startPoint.y;

            path.moveTo(moveToPoint.x, moveToPoint.y);
            path.lineTo(this.startPoint.x, this.startPoint.y);

            if (this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_LEFT || 
                this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_RIGHT || 
                this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT || 
                this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT) {
                if (Math.abs(this.startPoint.y - this.endPoint.y) < treeSpace) {
                    path.lineTo(this.startPoint.x, this.endPoint.y);
                } else if (this.startPoint.y < this.endPoint.y) {
                    path.lineTo(this.startPoint.x, this.endPoint.y - treeSpace);
                } else {
                    path.lineTo(this.startPoint.x, this.endPoint.y + treeSpace);
                }
                
                path.cubicTo(
                    this.startPoint.x,
                    this.endPoint.y,
                    this.startPoint.x + ((this.endPoint.x - this.startPoint.x) * 0.2),
                    this.endPoint.y,
                    this.endPoint.x,
                    this.endPoint.y);
            } else {    
                let spaceH = this.UiUtil.dip2px(40);                
                if (this.startPoint.x < this.endPoint.x) { // 向右
                    spaceH = Math.min((this.endPoint.x - this.startPoint.x) * 0.4, spaceH)
                } else {
                    spaceH = -Math.min((this.startPoint.x - this.endPoint.x) * 0.4, spaceH)
                }
                // if (this.isFormLeftRight) {

                // }
                let cubicSpace = spaceH;
                let point2 = new Point(this.endPoint.x - spaceH, this.endPoint.y)
                let point3 = new Point(this.endPoint.x - spaceH - cubicSpace, this.endPoint.y)
                let startPointAndPointDegrees = 180 - Util.getCircleDegreesInPoint(point2, this.startPoint)
                let point3Height = Util.tan(startPointAndPointDegrees) * cubicSpace;
                if (point3Height > 0) {
                    point3Height = Math.min(point3Height, Math.abs(cubicSpace * 3))
                } else {
                    point3Height = Math.max(point3Height, -Math.abs(cubicSpace * 3))
                }
                let point1 = new Point(this.endPoint.x - spaceH - cubicSpace, this.endPoint.y + point3Height * 0.5)                
                let controlPoint = Util.getStaticgetLineIntersectionPoint(point1, this.startPoint, 
                                                                                point2, this.endPoint)
                path.lineToByPoint(point1);
                path.addQuadCurve(point2, controlPoint)

                // this.drawCircularFree(point1, 2, Colors.black, 0, 0)
                // this.drawCircularFree(controlPoint, 2, Colors.black, 0, 0)
                // this.drawCircularFree(point2, 2, Colors.black, 0, 0)  
            }
            path.lineTo(this.endPoint.x, this.endPoint.y);

        } else if (lineMindElementContent.orientation == LineOrientation.BOTTOM || 
            lineMindElementContent.orientation == LineOrientation.TOP) {
            if (this.lineThicken) {
                if (this.startPoint.x > this.endPoint.x) {
                    this.startPoint.x = this.startPoint.x - this.lineFullWidth/2;
                } else {
                    this.startPoint.x = this.startPoint.x + this.lineFullWidth/2;
                }
                
            }
            let space = this.UiUtil.dip2px(8)

            moveToPoint.x = this.startPoint.x;
            moveToPoint.y = this.startPoint.y;
            
            let middlePoint = new CGPoint(this.startPoint.x, this.startPoint.y + space);
            path.moveTo(this.startPoint.x, this.startPoint.y);
            if (this.startPoint.y > this.endPoint.y) {
                middlePoint = new CGPoint(this.startPoint.x, this.startPoint.y - space);
            }
            path.lineTo(middlePoint.x, middlePoint.y);

            let spaceH = this.UiUtil.dip2px(12);                
            if (middlePoint.y < this.endPoint.y) { // 向下
                spaceH = Math.min((this.endPoint.y - middlePoint.y) * 0.4, spaceH)
            } else {
                spaceH = -Math.min((middlePoint.y - this.endPoint.y) * 0.4, spaceH)
            }
            let cubicSpace = spaceH * 1.3;
            let point2 = new Point(this.endPoint.x, this.endPoint.y - spaceH)
            let point3 = new Point(this.endPoint.x, this.endPoint.y - spaceH - cubicSpace)
            let startPointAndPointDegrees = Util.getCircleDegreesInPoint(point2, middlePoint) - 270
            let point3Height = Util.tan(startPointAndPointDegrees) * cubicSpace;
            if (point3Height > 0) {
                point3Height = Math.min(point3Height, Math.abs(cubicSpace * 3))
            } else {
                point3Height = Math.max(point3Height, -Math.abs(cubicSpace * 3))
            }
            let point1 = new Point(this.endPoint.x + point3Height * 0.5, this.endPoint.y - spaceH - cubicSpace)                
            let controlPoint = Util.getStaticgetLineIntersectionPoint(point1, middlePoint, 
                                                                            point2, this.endPoint)
            path.lineToByPoint(point1);
            path.addQuadCurve(point2, controlPoint)
            path.lineTo(this.endPoint.x, this.endPoint.y);

            // this.drawCircularFree(point1, 2, Colors.black, 0, 0)
            // this.drawCircularFree(controlPoint, 2, Colors.black, 0, 0)
            // this.drawCircularFree(point2, 2, Colors.black, 0, 0)  
        }

        if (this.lineThicken) {
            let originalStartPoint = new Point(this.startPoint.x, this.startPoint.y)
            if (lineMindElementContent.orientation == LineOrientation.RIGHT ||
                lineMindElementContent.orientation == LineOrientation.LEFT) {
                path.lineTo(this.endPoint.x, this.endPoint.y);
                if (Math.abs(this.mindElementData.height) < this.lineFullWidth) {
                    this.startPoint.y = this.startPoint.y + this.lineFullWidth * 2;
                } else {
                    this.startPoint.x = this.startPoint.x + this.lineFullWidth * 2;
                }
                if (this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_LEFT || 
                    this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_RIGHT || 
                    this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT || 
                    this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT) {
                    if (Math.abs(this.startPoint.y - this.endPoint.y) < treeSpace) {
                        path.cubicTo(
                            this.startPoint.x + (this.endPoint.x - this.startPoint.x) * 0.2,
                            this.endPoint.y,
                            this.startPoint.x,
                            this.startPoint.y - (this.startPoint.y - this.endPoint.y) * 0.22,
                            this.startPoint.x,
                            this.startPoint.y);
                    } else if (this.startPoint.y < this.endPoint.y) {
                        path.cubicTo(
                            this.startPoint.x + (this.endPoint.x - this.startPoint.x) * 0.2,
                            this.endPoint.y,
                            this.startPoint.x,
                            this.endPoint.y,
                            this.startPoint.x,
                            this.endPoint.y - treeSpace);
                    } else {
                        path.cubicTo(
                            this.startPoint.x + (this.endPoint.x - this.startPoint.x) * 0.2,
                            this.endPoint.y,
                            this.startPoint.x,
                            this.endPoint.y,
                            this.startPoint.x,
                            this.endPoint.y + treeSpace);
                    }
                } else {  
                    let spaceH = this.UiUtil.dip2px(40);                
                    if (this.startPoint.x < this.endPoint.x) { // 向右
                        spaceH = Math.min((this.endPoint.x - this.startPoint.x) * 0.4, spaceH)
                    } else {
                        spaceH = -Math.min((this.startPoint.x - this.endPoint.x) * 0.4, spaceH)
                    }
                    
                    let cubicSpace = spaceH;
                    let point2 = new Point(this.endPoint.x - spaceH, this.endPoint.y)
                    let point3 = new Point(this.endPoint.x - spaceH - cubicSpace, this.endPoint.y)
                    let startPointAndPointDegrees = 180 - Util.getCircleDegreesInPoint(point2, this.startPoint)
                    let point3Height = Util.tan(startPointAndPointDegrees) * cubicSpace;
                    if (point3Height > 0) {
                        point3Height = Math.min(point3Height, Math.abs(cubicSpace * 3))
                    } else {
                        point3Height = Math.max(point3Height, -Math.abs(cubicSpace * 3))
                    }
                    let point1 = new Point(this.endPoint.x - spaceH - cubicSpace, this.endPoint.y + point3Height * 0.5)                    
                    let controlPoint = Util.getStaticgetLineIntersectionPoint(point1, this.startPoint, 
                                                                                    point2, this.endPoint)
                    path.lineToByPoint(point2);
                    path.addQuadCurve(point1, controlPoint)
                }
                path.lineTo(this.startPoint.x, this.startPoint.y);
                path.lineTo(moveToPoint.x, moveToPoint.y);
            } else if (lineMindElementContent.orientation == LineOrientation.BOTTOM || lineMindElementContent.orientation == LineOrientation.TOP) {
                path.lineTo(this.endPoint.x, this.endPoint.y);
                if (this.startPoint.x > this.endPoint.x) {
                    this.startPoint.x = this.startPoint.x + this.lineFullWidth;
                } else {
                    this.startPoint.x = this.startPoint.x - this.lineFullWidth;
                }

                let space = this.UiUtil.dip2px(8) + this.lineFullWidth;

                let middlePoint = new CGPoint(this.startPoint.x, this.startPoint.y + space);
                if (this.startPoint.y > this.endPoint.y) {
                    middlePoint = new CGPoint(this.startPoint.x, this.startPoint.y - space);
                }
                let spaceH = this.UiUtil.dip2px(12);                
                if (middlePoint.y < this.endPoint.y) { // 向下
                    spaceH = Math.min((this.endPoint.y - middlePoint.y) * 0.4, spaceH)
                } else {
                    spaceH = -Math.min((middlePoint.y - this.endPoint.y) * 0.4, spaceH)
                }
                let cubicSpace = spaceH * 1.3;
                let point2 = new Point(this.endPoint.x, this.endPoint.y - spaceH)
                let point3 = new Point(this.endPoint.x, this.endPoint.y - spaceH - cubicSpace)
                let startPointAndPointDegrees = Util.getCircleDegreesInPoint(point2, middlePoint) - 270
                let point3Height = Util.tan(startPointAndPointDegrees) * cubicSpace;
                if (point3Height > 0) {
                    point3Height = Math.min(point3Height, Math.abs(cubicSpace * 3))
                } else {
                    point3Height = Math.max(point3Height, -Math.abs(cubicSpace * 3))
                }
                let point1 = new Point(this.endPoint.x + point3Height * 0.5, this.endPoint.y - spaceH - cubicSpace)                
                let controlPoint = Util.getStaticgetLineIntersectionPoint(point1, middlePoint, 
                                                                                point2, this.endPoint)
                path.lineToByPoint(point2);
                path.addQuadCurve(point1, controlPoint)

                path.lineTo(middlePoint.x, middlePoint.y);
                path.lineTo(this.startPoint.x, this.startPoint.y);
                path.lineTo(moveToPoint.x, moveToPoint.y);
            }
            this.renderCanvas(path)
        }
        this.renderCanvas(path)
    }
    //自己添加的一个函数
    renderCanvas(path) {
        if (this.textPathPath != null) {
            this.textPathPath.setAttribute("d", path.getLineRadius())
            this.textPathPath.setAttribute("stroke", Colors.getUiColor(this.color))
            this.textPathPath.setAttribute("stroke-width", this.lineWidth)
        }
        

        let lineMindElementContent = this.mindElementData.lineContent
        this.path.setAttribute("d", path.getLineRadius())
        if (this.mindElementData.layout == NodeLayoutType.LAYOUT_RADIATE) {
            this.path.setAttribute("stroke", Colors.getUiColor(lineMindElementContent.strokeColor))
        } else {
            this.path.setAttribute("stroke", Colors.getUiColor(this.color))
        }
        
        if (this.isFreeLine() || this.mindElementData.type == MindElementType.LAYOUT_FISH_RIGHT_LINE) {
            this.path.setAttribute("stroke-width", 0.5)
        } else {
            this.path.setAttribute("stroke-width", this.lineWidth)
        }
        
        if (this.isAnimation) {
            this.path.style.strokeDashoffset = this.path.getTotalLength()
            this.path.style.strokeDasharray = this.path.getTotalLength()
            this.path.style.animation = 'path-line-animation-dash ' + (this.duration / 1000) + 's linear forwards'
        } else {
            if (lineMindElementContent.dottedLine && !lineMindElementContent.lineThicken) {
                var dottedLineWidth = 15
                if (this.lineWidth >= 5) {
                    dottedLineWidth = 20
                } else if (this.lineWidth >= 4) {
                    dottedLineWidth = 18
                } else if (this.lineWidth >= 3) {
                    dottedLineWidth = 16
                }
                if (this.path.getTotalLength != undefined && this.path.getTotalLength != null) {
                    let lineLenght = this.path.getTotalLength()                
                    let dotCount = Math.floor(lineLenght / dottedLineWidth)
                    if (dotCount % 2 == 1) { //空白开始实线结束
                        dotCount += 1
                    }
                    dottedLineWidth = lineLenght / dotCount
                }
                let strokeDashScale = dottedLineWidth * 0.25 < (this.lineWidth + 1) ? 0.35 : 0.25
                this.path.style.strokeDashoffset = dottedLineWidth
                this.path.style.strokeDasharray = [dottedLineWidth * (1 - strokeDashScale), dottedLineWidth * strokeDashScale]
            } else {
                this.path.style.strokeDashoffset = 0
                this.path.style.strokeDasharray = 0
            }
            this.path.style.animation = ""
        }
        // console.log(this.lineThicken);
        if (this.isFreeLine() || this.lineThicken || 
            this.mindElementData.type == MindElementType.LAYOUT_FISH_RIGHT_LINE ||
            this.mindElementData.type == MindElementType.LAYOUT_CUSTOM_LINE) {
            this.path.setAttribute("fill", Colors.getUiColor(this.color))
        } else {
            this.path.setAttribute("fill", "none")
        }
        // this.path.setAttribute("fill", "none")
        if(this.mindElementData.layout == NodeLayoutType.LAYOUT_CIRCLE){
            this.path.setAttribute("fill", "none")
        }
        if (this.mindElementData.layout == NodeLayoutType.LAYOUT_RADIATE) {
            this.path.setAttribute("fill", Colors.getUiColor(lineMindElementContent.color))
        }

    }

    getDrawCurve2EndXRatio(startPoint, endPoint) {
        let ratio = 0.5;
        if (this.mindElementData == null ||
            this.mindElementData.isEmpty() ||
            this.mindElementData.lineContent == null) {
            return ratio;
        }
        if (this.mindElementData.lineContent.lineLayout == LineLayout.CURVE_LINE_AVERAGE) {
            return 0.35;
        }
        let maxHeight = Math.abs(endPoint.x - startPoint.x) * 0.5;
        let moveHeight = Math.abs(endPoint.y - startPoint.y);
        if (this.mindElementData.lineContent.orientation == LineOrientation.BOTTOM) {
            maxHeight = Math.abs(endPoint.y - startPoint.y);
            moveHeight = Math.abs(endPoint.x - startPoint.x);
        }
        if (moveHeight == 0) {
            return 0.5;
        }
        ratio = maxHeight / moveHeight;
        if (ratio > 0.5) {
            ratio = 0.5;
        } else if (ratio < 0) {
            ratio = 0.0;
        }
        return ratio;
    }

    drawFreeCurve() {
        let path = new LinePath();
        let treeCurveSpace = this.UiUtil.dip2px(6);
        let lineMindElementContent = this.mindElementData.lineContent;
        let moveToPoint = new Point();
        //测试各种线
        if (lineMindElementContent.orientation == LineOrientation.RIGHT ||
            lineMindElementContent.orientation == LineOrientation.LEFT) {
            
            let targetPoint = new Point(lineMindElementContent.endControlPointX, lineMindElementContent.endControlPointY)
            let startEndDegrees = Util.getCircleDegreesInPoint(this.startPoint, this.endPoint)
            let startTargetDegrees = Util.getCircleDegreesInPoint(this.startPoint, targetPoint)
            let radius = Util.getPointSpacing(this.startPoint, targetPoint)
            let startEndSpace = Util.getPointSpacing(this.startPoint, this.endPoint)
            let targetEndSpace = Util.getPointSpacing(targetPoint, this.endPoint)
            // this.drawCircularFree(this.endPoint, 2, Colors.red, 0, 0);
            

            let noDirectionDiffAngle = 20 + (lineMindElementContent.startControlPointX / 10 * 20); 
            let noDirectionEndDiffAngle = 5 + (lineMindElementContent.startControlPointX / 10 * 10); 
            let startControlRadius = radius * 0.5;
            let endControlRadius = radius * 0.65;

            if (startEndDegrees > 60 && startEndDegrees < 120 || startEndDegrees > 240 && startEndDegrees < 300) {
                noDirectionDiffAngle = (lineMindElementContent.startControlPointX / 10 * 10); 
                noDirectionEndDiffAngle = (lineMindElementContent.startControlPointX / 10 * 10);
                startTargetDegrees = startEndDegrees
                if (startEndDegrees > 60 && startEndDegrees < 120) {
                    if (startEndSpace > 200 && Math.abs(startEndDegrees - 90) > 15) {
                        endControlRadius = targetEndSpace + Math.max(Math.abs(startEndDegrees - 90) * 6, 100);
                        noDirectionDiffAngle = 10 + (lineMindElementContent.startControlPointX / 10 * 10); 
                        noDirectionEndDiffAngle = 10 + (lineMindElementContent.startControlPointX / 10 * 10); 
                    } else {
                        endControlRadius = targetEndSpace + Math.max(Math.abs(startEndDegrees - 90) * 3, 60);
                    }
                } else {
                    
                    if (startEndSpace > 200 && Math.abs(startEndDegrees - 27) > 15) {
                        endControlRadius = targetEndSpace + Math.max(Math.abs(startEndDegrees - 270) * 3, 50);
                        noDirectionEndDiffAngle = 10 + (lineMindElementContent.startControlPointX / 10 * 10); 
                    } else {
                        endControlRadius = targetEndSpace + Math.max(Math.abs(startEndDegrees - 270) * 1.5, 20);
                    }
                }
            } else if ((startEndDegrees > 165 && startEndDegrees < 195) || 
                        (startEndDegrees > 0 && startEndDegrees < 15) || 
                        (startEndDegrees > 345 && startEndDegrees < 361)) {
                noDirectionDiffAngle = 5 + (lineMindElementContent.startControlPointX / 10 * 20); 
            }

            let diffAngle = lineMindElementContent.orientation == LineOrientation.RIGHT ? noDirectionDiffAngle : 0 - noDirectionDiffAngle;
            let diffEndAngle = lineMindElementContent.orientation == LineOrientation.RIGHT ? noDirectionEndDiffAngle : 0 - noDirectionEndDiffAngle;
            
            let startControlPoint;
            let endControlPoint;
            
            let verticalDegrees = 55
            // console.log("startEndDegrees-", startEndDegrees, diffEndAngle);
            if (startEndDegrees > 60 && startEndDegrees < 120 || startEndDegrees > 240 && startEndDegrees < 300) {
                startControlRadius = targetEndSpace * 0.6;
                diffAngle = 0
                diffEndAngle = 0;
                // if (startEndDegrees > 60 && startEndDegrees < 90) {
                //     endControlRadius = endControlRadius * 1.0
                // } else if (startEndDegrees > 90 && startEndDegrees < 120) {
                //     endControlRadius = endControlRadius * 1.5
                // }
                // if (lineMindElementContent.orientation == LineOrientation.RIGHT) {
                //     if (startEndDegrees > 60 && startEndDegrees < 120) {
                //         // diffAngle = startEndDegrees * 0.25;
                //         // endControlRadius = endControlRadius * 1.2;
                //         diffEndAngle = 0//-diffEndAngle * 0.5
                //     } else {
                //         // diffAngle = (360 - startEndDegrees) * 0.25;
                //         // endControlRadius = endControlRadius * 1.6;
                //         diffEndAngle = diffEndAngle * 0.5
                //     }
                // } else {
                //     // diffAngle = startEndDegrees > 60 && startEndDegrees < 120 ?
                //     if (startEndDegrees > 60 && startEndDegrees < 120) {
                //         // diffAngle = startEndDegrees * 0.25;
                //         // endControlRadius = endControlRadius * 1.6;
                //         diffEndAngle = diffEndAngle * 0.5
                //     } else {
                //         diffEndAngle = 0//-diffEndAngle * 0.5
                //         // diffAngle = (360 - startEndDegrees) * 0.25;
                //         // endControlRadius = endControlRadius * 1.2;
                //     }
                // }
                 
            } else if ((startTargetDegrees > 270 && startTargetDegrees < 270 + verticalDegrees) || 
                (startTargetDegrees < 270 && startTargetDegrees < 270 - verticalDegrees) ||
                (startTargetDegrees < 90 && startTargetDegrees < 90 + verticalDegrees) ||
                (startTargetDegrees < 90 && startTargetDegrees < 90 - verticalDegrees)) {
                startControlRadius = radius / 2 + lineMindElementContent.curveStartPointY * 0.8;
                diffAngle = 0
            } else {
                startControlRadius = radius / 2 + lineMindElementContent.curveStartPointX * 0.8;
                diffAngle = 0
            }
            if (this.startPoint.y > this.endPoint.y) {// 向上方
                startControlPoint = Util.getCirclePoint(this.startPoint, startTargetDegrees + diffAngle, startControlRadius)
                if (lineMindElementContent.orientation == LineOrientation.RIGHT) {
                    endControlPoint = Util.getCirclePoint(targetPoint, 180 + diffEndAngle, endControlRadius)
                } else {
                    // endControlPoint = Util.getCirclePoint(targetPoint, 0 + diffEndAngle, endControlRadius)
                    endControlPoint = Util.getCirclePoint(targetPoint, 0 + 0, endControlRadius)
                }
            } else {                          
                startControlPoint = Util.getCirclePoint(this.startPoint, startTargetDegrees - diffAngle, startControlRadius)
                if (lineMindElementContent.orientation == LineOrientation.RIGHT) {
                    endControlPoint = Util.getCirclePoint(targetPoint, 180 - diffEndAngle, endControlRadius)
                } else {
                    // endControlPoint = Util.getCirclePoint(targetPoint, 0 - diffEndAngle, endControlRadius)
                    endControlPoint = Util.getCirclePoint(targetPoint, 0 + 0, endControlRadius)
                }
            }
            // this.drawCircularFree(startControlPoint, 4, Colors.black, 0, 0);
            // this.drawCircularFree(endControlPoint, 6, Colors.deep_yellow, 0, 0);
            let fullWidth = this.lineWidth
            if (startEndDegrees > 60 && startEndDegrees < 120 || startEndDegrees > 240 && startEndDegrees < 300) {
                path.moveTo(this.startPoint.x - fullWidth * 1.5, this.startPoint.y);
                if (this.mindElementData.borderThicken) {
                    path.cubicTo(
                        startControlPoint.x,
                        startControlPoint.y,
                        endControlPoint.x,
                        endControlPoint.y,
                        targetPoint.x,
                        targetPoint.y);
                } else {
                    if ((lineMindElementContent.orientation == LineOrientation.RIGHT && this.startPoint.y > this.endPoint.y) || 
                        (lineMindElementContent.orientation == LineOrientation.LEFT && this.startPoint.y < this.endPoint.y)) { //右上、左下
                            path.cubicTo(
                                startControlPoint.x,
                                startControlPoint.y,
                                endControlPoint.x,
                                endControlPoint.y,
                                targetPoint.x,
                                targetPoint.y - fullWidth / 2);
                            path.lineTo(targetPoint.x, targetPoint.y + fullWidth/2);            
                    } else {
                        path.cubicTo(
                            startControlPoint.x,
                            startControlPoint.y,
                            endControlPoint.x,
                            endControlPoint.y,
                            targetPoint.x,
                            targetPoint.y + fullWidth / 2);
                        path.lineTo(targetPoint.x, targetPoint.y - fullWidth/2);            
                    }
                        
                }
                if (lineMindElementContent.orientation == LineOrientation.RIGHT) {
                    if (this.startPoint.y > this.endPoint.y) { //向上
                        path.cubicTo(
                            endControlPoint.x + fullWidth,
                            endControlPoint.y + fullWidth,
                            startControlPoint.x + fullWidth,
                            startControlPoint.y + fullWidth,
                            this.startPoint.x + fullWidth * 1.5, 
                            this.startPoint.y);
                    } else {
                        path.cubicTo(
                            endControlPoint.x + fullWidth,
                            endControlPoint.y - fullWidth,
                            startControlPoint.x + fullWidth,
                            startControlPoint.y,
                            this.startPoint.x + fullWidth * 1.5, 
                            this.startPoint.y);
                    }
                } else {
                    if (this.startPoint.y > this.endPoint.y) { //向上                        
                        path.cubicTo(
                            endControlPoint.x + fullWidth,
                            endControlPoint.y - fullWidth,
                            startControlPoint.x + fullWidth,
                            startControlPoint.y,
                            this.startPoint.x + fullWidth * 1.5, 
                            this.startPoint.y);
                    } else {
                        path.cubicTo(
                            endControlPoint.x + fullWidth,
                            endControlPoint.y + fullWidth,
                            startControlPoint.x + fullWidth,
                            startControlPoint.y,
                            this.startPoint.x + fullWidth * 1.5, 
                            this.startPoint.y);
                    }
                }
            } else {
                path.moveTo(this.startPoint.x, this.startPoint.y - fullWidth * 1.5);
                if (this.mindElementData.borderThicken) {
                    path.cubicTo(
                        startControlPoint.x,
                        startControlPoint.y,
                        endControlPoint.x,
                        endControlPoint.y,
                        targetPoint.x,
                        targetPoint.y);
                } else {
                    path.cubicTo(
                        startControlPoint.x,
                        startControlPoint.y,
                        endControlPoint.x,
                        endControlPoint.y,
                        targetPoint.x,
                        targetPoint.y - fullWidth/2);
                    path.lineTo(targetPoint.x, targetPoint.y + fullWidth/2);                
                }
                if (lineMindElementContent.orientation == LineOrientation.RIGHT) {
                    if (this.startPoint.y > this.endPoint.y) { //向上
                        path.cubicTo(
                            endControlPoint.x + fullWidth,
                            endControlPoint.y + fullWidth,
                            startControlPoint.x + fullWidth,
                            startControlPoint.y + fullWidth,
                            this.startPoint.x, 
                            this.startPoint.y + fullWidth * 1.5);
                    } else {
                        path.cubicTo(
                            endControlPoint.x - fullWidth,
                            endControlPoint.y + fullWidth,
                            startControlPoint.x - fullWidth,
                            startControlPoint.y + fullWidth,
                            this.startPoint.x, 
                            this.startPoint.y + fullWidth * 1.5);
                    }
                } else {
                    if (this.startPoint.y > this.endPoint.y) { //向上                        
                        path.cubicTo(
                            endControlPoint.x - fullWidth,
                            endControlPoint.y + fullWidth,
                            startControlPoint.x - fullWidth,
                            startControlPoint.y + fullWidth,
                            this.startPoint.x, 
                            this.startPoint.y + fullWidth * 1.5);
                    } else {
                        path.cubicTo(
                            endControlPoint.x + fullWidth,
                            endControlPoint.y + fullWidth,
                            startControlPoint.x + fullWidth,
                            startControlPoint.y + fullWidth,
                            this.startPoint.x, 
                            this.startPoint.y + fullWidth * 1.5);
                    }
                }
            }
            path.lineTo(this.startPoint.x, this.startPoint.y + fullWidth * 1.5);
        }

        // if (this.textObj == null) {
        //     let text = "<text "; //"<text ><textpath xlink:href='#linepath11300'>asdasd</textpath></text>";
            
        //     text = text + " id='" + this.getTextPathId() + "' font-size='24' ><textpath ";
        //     text = text + " xlink:href='#" + this.getPathId() + "'>" + lineMindElementContent.text + "</textpath></text>"
        //     this.context.innerHTML = text   
            
        //     if (this.path != null) {
        //         this.context.appendChild(this.path)
        //     }
        // }
        this.renderCanvas(path)
        // let textHtml = document.getElementById(this.getTextPathId())
        // if (textHtml != null) {
        //     let lineLenght = this.path.getTotalLength()
        //     let size = MindElementCalculation.caluleText(lineMindElementContent.text, 40, false); 
        //     // console.log("this.path.getTotalLength - ", this.path.getTotalLength());

        //     textHtml.setAttribute("x", this.path.getTotalLength() / 2 - size.getWidth())
        //     textHtml.setAttribute("y", this.lineWidth)
        // }
    }

    drawCurve2() {
        let path = new LinePath();
        let treeCurveSpace = this.UiUtil.dip2px(6);
        let lineMindElementContent = this.mindElementData.lineContent;
        let moveToPoint = new Point();
        //测试各种线
        if (lineMindElementContent.orientation == LineOrientation.RIGHT ||
            lineMindElementContent.orientation == LineOrientation.LEFT) {
            if (this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_RIGHT ||
                this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT) {
                if (this.lineThicken) {
                    this.startPoint.x = this.startPoint.x - this.lineFullWidth/2;
                }
                path.moveTo(this.startPoint.x, this.startPoint.y);
                path.lineTo(this.startPoint.x, this.endPoint.y);
                if (this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_RIGHT) {
                    path.cubicTo(
                        this.startPoint.x + (this.endPoint.x - this.startPoint.x)/2,
                        this.endPoint.y + treeCurveSpace,
                        this.startPoint.x + (this.endPoint.x - this.startPoint.x)/2,
                        this.endPoint.y - treeCurveSpace/3,
                        this.endPoint.x,
                        this.endPoint.y);
                } else {
                    path.cubicTo(
                        this.startPoint.x + (this.endPoint.x - this.startPoint.x)/2,
                        this.endPoint.y - treeCurveSpace,
                        this.startPoint.x + (this.endPoint.x - this.startPoint.x)/2,
                        this.endPoint.y + treeCurveSpace/3,
                        this.endPoint.x,
                        this.endPoint.y);
                }
                path.lineTo(this.endPoint.x, this.endPoint.y);

            } else if (this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_LEFT ||
                this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT) {
                if (this.lineThicken) {
                    this.startPoint.x = this.startPoint.x + this.lineFullWidth/2;
                }
                path.moveTo(this.startPoint.x, this.startPoint.y);
                path.lineTo(this.startPoint.x, this.endPoint.y);
                if (this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_LEFT) {
                    path.cubicTo(
                        this.startPoint.x - Math.abs(this.endPoint.x - this.startPoint.x)/2,
                        this.endPoint.y + treeCurveSpace,
                        this.startPoint.x - Math.abs(this.endPoint.x - this.startPoint.x)/2,
                        this.endPoint.y - treeCurveSpace/3,
                        this.endPoint.x,
                        this.endPoint.y);
                } else {
                    path.cubicTo(
                        this.startPoint.x - Math.abs(this.endPoint.x - this.startPoint.x)/2,
                        this.endPoint.y - treeCurveSpace,
                        this.startPoint.x - Math.abs(this.endPoint.x - this.startPoint.x)/2,
                        this.endPoint.y + treeCurveSpace/3,
                        this.endPoint.x,
                        this.endPoint.y);
                }
                path.lineTo(this.endPoint.x, this.endPoint.y);

            } else {
                if (this.lineThicken && 
                    (this.mindElementData.layout != NodeLayoutType.LAYOUT_BRACKETS_RIGHT ||
                    this.mindElementData.layout != NodeLayoutType.LAYOUT_BRACKETS_LEFT)) {
                    this.startPoint.y = this.startPoint.y - this.lineFullWidth/2;
                }
                if (lineMindElementContent.orientation == LineOrientation.RIGHT) {
                    moveToPoint.x = this.startPoint.x;
                    moveToPoint.y = this.startPoint.y;
                } else {
                    moveToPoint.x = this.startPoint.x;
                    moveToPoint.y = this.startPoint.y;
                }
                path.moveTo(moveToPoint.x, moveToPoint.y);
                path.lineTo(this.startPoint.x, this.startPoint.y);
    
                if (this.mindElementData.layout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT) {
                    path.cubicTo(
                        this.startPoint.x + ((this.endPoint.x - this.startPoint.x) * 1.2),
                        this.startPoint.y,
                        this.startPoint.x + ((this.endPoint.x - this.startPoint.x) * -0.2),
                        this.endPoint.y,
                        this.endPoint.x,
                        this.endPoint.y);
    
                    path.lineTo(this.endPoint.x, this.endPoint.y);
                } else if (this.mindElementData.layout == NodeLayoutType.LAYOUT_BRACKETS_LEFT) {
                    path.cubicTo(
                        this.startPoint.x + ((this.endPoint.x - this.startPoint.x) * 1.2),
                        this.startPoint.y,
                        this.startPoint.x + ((this.endPoint.x - this.startPoint.x) * -0.2),
                        this.endPoint.y,
                        this.endPoint.x,
                        this.endPoint.y);
                    path.lineTo(this.endPoint.x, this.endPoint.y);
    
                } else {
                    // path.cubicTo(
                    //     this.endPoint.x - ((this.endPoint.x - this.startPoint.x) * this.getDrawCurve2EndXRatio(this.startPoint, this.endPoint)),
                    //     this.startPoint.y,
                    //     this.startPoint.x + ((this.endPoint.x - this.startPoint.x) * this.getDrawCurve2EndXRatio(this.startPoint, this.endPoint)),
                    //     this.endPoint.y,
                    //     this.endPoint.x,
                    //     this.endPoint.y);
    
                    path.cubicTo(
                        this.endPoint.x - (this.endPoint.x - this.startPoint.x) * 0.5,
                        this.startPoint.y,
                        this.startPoint.x + (this.endPoint.x - this.startPoint.x) * 0.5,
                        this.endPoint.y,
                        this.endPoint.x,
                        this.endPoint.y);

                    path.lineTo(this.endPoint.x, this.endPoint.y);
                }
            }
        } else if (lineMindElementContent.orientation == LineOrientation.BOTTOM || lineMindElementContent.orientation == LineOrientation.TOP) {
            if (this.lineThicken) {
                this.startPoint.x = this.startPoint.x - this.lineFullWidth;
            }
            moveToPoint.x = this.startPoint.x;
            moveToPoint.y = this.startPoint.y;

            path.moveTo(this.startPoint.x, this.startPoint.y);
            path.cubicTo(
                this.startPoint.x + ((this.endPoint.x - this.startPoint.x)) * 0.1,
                this.startPoint.y + (this.endPoint.y - this.startPoint.y) * 0.52,
                this.endPoint.x,
                this.startPoint.y + (this.endPoint.y - this.startPoint.y) * this.getDrawCurve2EndXRatio(this.startPoint, this.endPoint),
                this.endPoint.x,
                this.endPoint.y);
        }

        if (this.lineThicken) {
            if (lineMindElementContent.orientation == LineOrientation.RIGHT ||
                lineMindElementContent.orientation == LineOrientation.LEFT) {
                if (this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_RIGHT ||
                    this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT) {
                    if (this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_RIGHT) {
                        path.cubicTo(
                            this.startPoint.x + (this.endPoint.x - this.startPoint.x)/2,
                            this.endPoint.y - treeCurveSpace/3,
                            this.startPoint.x + (this.endPoint.x - this.startPoint.x)/2,
                            this.endPoint.y + treeCurveSpace,
                            this.startPoint.x + this.lineFullWidth,
                            this.endPoint.y - this.lineFullWidth);
                    } else {
                        path.cubicTo(
                            this.startPoint.x + (this.endPoint.x - this.startPoint.x)/2,
                            this.endPoint.y + treeCurveSpace/3,
                            this.startPoint.x + (this.endPoint.x - this.startPoint.x)/2,
                            this.endPoint.y - treeCurveSpace,
                            this.startPoint.x + this.lineFullWidth,
                            this.endPoint.y + this.lineFullWidth);
                    }
                    path.lineTo(this.startPoint.x + this.lineFullWidth, this.startPoint.y);
                    path.lineTo(this.startPoint.x, this.startPoint.y);
                } else if (this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_LEFT ||
                    this.mindElementData.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT) {
                        if (this.mindElementData.layout == NodeLayoutType.LAYOUT_TREE_LEFT) {
                            path.cubicTo(
                                this.startPoint.x - Math.abs(this.endPoint.x - this.startPoint.x)/2,
                                this.endPoint.y - treeCurveSpace/3,
                                this.startPoint.x - Math.abs(this.endPoint.x - this.startPoint.x)/2,
                                this.endPoint.y + treeCurveSpace,
                                this.startPoint.x - this.lineFullWidth,
                                this.endPoint.y - this.lineFullWidth);
                        } else {
                            path.cubicTo(
                                this.startPoint.x - Math.abs(this.endPoint.x - this.startPoint.x)/2,
                                this.endPoint.y + treeCurveSpace/3,
                                this.startPoint.x - Math.abs(this.endPoint.x - this.startPoint.x)/2,
                                this.endPoint.y - treeCurveSpace,
                                this.startPoint.x - this.lineFullWidth,
                                this.endPoint.y + this.lineFullWidth);
                        }
                        path.lineTo(this.startPoint.x - this.lineFullWidth, this.startPoint.y);
                        path.lineTo(this.startPoint.x, this.startPoint.y);
                } else {
                    path.lineTo(this.endPoint.x, this.endPoint.y);
                    if (this.mindElementData.layout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT ||
                        this.mindElementData.layout == NodeLayoutType.LAYOUT_BRACKETS_LEFT) {
                        path.cubicTo(
                            this.startPoint.x + (this.endPoint.x - this.startPoint.x) * -0.3,
                            this.endPoint.y,
                            this.startPoint.x + (this.endPoint.x - this.startPoint.x) * 1.1,
                            this.startPoint.y,
                            this.startPoint.x,
                            this.startPoint.y);

                        if (lineMindElementContent.orientation == LineOrientation.RIGHT) {
                            path.lineTo(this.startPoint.x, this.startPoint.y);
                            path.lineTo(this.startPoint.x, this.startPoint.y);
                        } else {
                            path.lineTo(this.startPoint.x, this.startPoint.y);
                            path.lineTo(this.startPoint.x, this.startPoint.y);
                        }
                        path.lineTo(this.startPoint.x, this.startPoint.y);
                    } else {
                        this.startPoint.y = this.startPoint.y + this.lineFullWidth;
                        // path.cubicTo(
                        //     this.startPoint.x + (this.endPoint.x - this.startPoint.x) * this.getDrawCurve2EndXRatio(this.startPoint, this.endPoint),
                        //     this.endPoint.y,
                        //     this.endPoint.x - (this.endPoint.x - this.startPoint.x) * this.getDrawCurve2EndXRatio(this.startPoint, this.endPoint),
                        //     this.startPoint.y,
                        //     this.startPoint.x,
                        //     this.startPoint.y);
                        path.cubicTo(
                            this.startPoint.x + (this.endPoint.x - this.startPoint.x) * 0.5,
                            this.endPoint.y,
                            this.endPoint.x - (this.endPoint.x - this.startPoint.x) * 0.5,
                            this.startPoint.y,
                            this.startPoint.x,
                            this.startPoint.y);
                        path.lineTo(moveToPoint.x, moveToPoint.y);
                    }
                }
            } else if (lineMindElementContent.orientation == LineOrientation.BOTTOM || lineMindElementContent.orientation == LineOrientation.TOP) {
                this.startPoint.x = this.startPoint.x + this.lineFullWidth * 2;
                path.cubicTo(
                    this.endPoint.x,
                    this.startPoint.y + (this.endPoint.y - this.startPoint.y) * this.getDrawCurve2EndXRatio(this.startPoint, this.endPoint),
                    this.startPoint.x + (this.endPoint.x - this.startPoint.x) * 0.1,
                    this.startPoint.y + (this.endPoint.y - this.startPoint.y) * 0.52,
                    this.startPoint.x,
                    this.startPoint.y);
                path.lineTo(moveToPoint.x, moveToPoint.y);
            }
            this.renderCanvas(path)
        }
        this.renderCanvas(path)
    }

    drawArrow() {
        if (this.mindElementData == null || this.mindElementData.lineContent == null) {
            return;
        }
        let lineMindElementContent = this.mindElementData.lineContent;
        let linePath = new LinePath();
        let arrowStart = new Point();
        let arrowEnd = new Point();
        let arrowH = (new UiUtil).getArrowWidth(this.startPoint, this.endPoint, this.lineWidth);
        let moveSpace = arrowH / 2;
        arrowH = arrowH - moveSpace;
        if (lineMindElementContent.orientation == LineOrientation.RIGHT) {
            this.endPoint.x = this.endPoint.x - moveSpace - 3;
            arrowStart = new Point(this.endPoint.x - arrowH - 100, this.endPoint.y);
            arrowEnd = new Point(this.endPoint.x - arrowH, this.endPoint.y);
        } else if (lineMindElementContent.orientation == LineOrientation.BOTTOM) {
            this.endPoint.y = this.endPoint.y - moveSpace - 3;
            arrowStart = new Point(this.endPoint.x, this.endPoint.y - arrowH - 100);
            arrowEnd = new Point(this.endPoint.x, this.endPoint.y - arrowH);
        } else if (lineMindElementContent.orientation == LineOrientation.TOP) {
            this.endPoint.y = this.endPoint.y + moveSpace + 3;
            arrowStart = new Point(this.endPoint.x, this.endPoint.y + arrowH + 100);
            arrowEnd = new Point(this.endPoint.x, this.endPoint.y + arrowH);
        } else if (lineMindElementContent.orientation == LineOrientation.LEFT) {
            this.endPoint.x = this.endPoint.x + moveSpace + 3;
            arrowStart = new Point(this.endPoint.x + arrowH + 100, this.endPoint.y);
            arrowEnd = new Point(this.endPoint.x + arrowH, this.endPoint.y);
        }

        let p = (new UiUtil).getArrowPoint(arrowStart, arrowEnd, this.lineWidth);
        if (p.length != 3) {
            return;
        }
        linePath.moveTo(p[0].x, p[0].y);
        linePath.lineTo(p[1].x, p[1].y);
        linePath.lineTo(p[2].x, p[2].y);
        linePath.lineTo(p[0].x, p[0].y);
        //为了解决封闭图形不规则，起始点有缺口的问题。
        linePath.lineTo(p[1].x, p[1].y);

        if (this.arrow == null) {
            this.arrow = document.createElementNS('http://www.w3.org/2000/svg', "path")
            this.context.appendChild(this.arrow)
        }
        this.arrow.setAttribute("d", linePath.getLineRadius())
        this.arrow.setAttribute("stroke", Colors.getUiColor(this.color))
        this.arrow.setAttribute("stroke-width", this.lineWidth)
        this.arrow.setAttribute("fill", Colors.getUiColor(this.color))

    }


    createBackgroundView() {
    }

    drawCircular( point, radius, fillColor, lineWidth, lineColor) {
        if (this.arrow == null) {
            this.arrow = document.createElementNS('http://www.w3.org/2000/svg', "circle");
            this.context.appendChild(this.arrow)
        }
        let fcolor = 'rgba(0,0,0,0)';
        let lcolor = 'rgba(0,0,0,0)';
        let lWidth = 0;
        if (Colors.isClear(fillColor)) {
            fcolor = Colors.getUiColor(this.mindTextSizeChangedDelegate.DrawingBoardBackgroundColor);
            lWidth = 0.1;
        } else {
            fcolor = Colors.getUiColor(fillColor);
            lWidth = 0.1;
        }
        if (!Colors.isClear(lineColor) && lineWidth > 0) {
            lcolor = Colors.getUiColor(lineColor);
            lWidth = lineWidth;

        }
        this.arrow.setAttribute("cx", point.x)
        this.arrow.setAttribute("cy", point.y)
        this.arrow.setAttribute("r", radius)
        this.arrow.setAttribute("fill", fcolor)
        this.arrow.setAttribute("stroke-width", lWidth)
        this.arrow.setAttribute("stroke", lcolor)
    }

    drawCircularFree( point, radius, fillColor, lineWidth, lineColor) {
        let view = document.createElementNS('http://www.w3.org/2000/svg', "circle");
        this.context.appendChild(view)

        let fcolor = 'rgba(0,0,0,0)';
        let lcolor = 'rgba(0,0,0,0)';
        let lWidth = 0;
        if (Colors.isClear(fillColor)) {
            fcolor = Colors.getUiColor(this.mindTextSizeChangedDelegate.DrawingBoardBackgroundColor);
            lWidth = 0.1;
        } else {
            fcolor = Colors.getUiColor(fillColor);
            lWidth = 0.1;
        }
        if (!Colors.isClear(lineColor) && lineWidth > 0) {
            lcolor = Colors.getUiColor(lineColor);
            lWidth = lineWidth;

        }
        view.setAttribute("cx", point.x)
        view.setAttribute("cy", point.y)
        view.setAttribute("r", radius)
        view.setAttribute("fill", fcolor)
        view.setAttribute("stroke-width", lWidth)
        view.setAttribute("stroke", lcolor)
        this.circularViewList.push(view)
    }

    setViewRect(width, height, x, y) {
        if (width <= 0) {
            this.context.style.width = this.getLineWidth() + 'px';
        } else {
            this.context.style.width = width + 'px';
        }
        if (height <= 0) {
            this.context.style.height = this.getLineWidth() + 'px';
        } else {
            this.context.style.height = height + 'px';
        }
        this.setPoint(x, y);
    }

    isFreeLine() {
        return this.mindElementData.isFreeTreeNode && 
            (this.mindElementData.layout == NodeLayoutType.LAYOUT_RIGHT || this.mindElementData.layout == NodeLayoutType.LAYOUT_LEFT);
    }
}
export default SubjectLineView







