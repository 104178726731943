/**
 * ProjectName: MindMap
 * Created by tony on 2024/01/04
 * Copyright(c) 2024 mindyushu.com
 */

let FlowChartDirection = {
    UNKNOWN: -1,
    LEFT: 0,
    TOP: 1,
    RIGHT: 2,
    BOTTOM: 3,

}
export default FlowChartDirection