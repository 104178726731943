import Colors from "../../../utils/Colors";
import UiUtil from "../../../utils/UiUtil";
import Size from "../../../viewmodel/core/base/Size";
import MindElementType from "../../../viewmodel/datatype/MindElementType";
import NodeLayoutType from "../../../viewmodel/datatype/NodeLayoutType";

class ControllMindSize {
    constructor(fatherDom,delegate) {
        this.fatherDom = fatherDom;
        this.delegate = delegate;
        this.leftControllSizeBox;
        this.rightControllSizeBox;
        this.controllSizeBoxWidth = 8;      //可以拖动区域的宽度
        this.leftControllSizeBoxObj = {     //可以拖动的区域
            width: 0,
            height: 0,
            x: 0,
            y: 0,
        };
        this.rightControllSizeBoxObj = {    //可以拖动的区域
            width: 0,
            height: 0,
            x: 0,
            y: 0,
        }
        this.mindElementData; //存储节点信息
        this.size; //拖动产生的区域的大小。
    }

    //在选中节点的时候移动放大缩小节点按钮的位置。
    setPosition(mindElementData, newX, newY) {
        if (mindElementData == undefined) {
            return;
        }
        this.mindElementData = mindElementData;
        this.setPositionXY(newX, newY);
    }

    //设置点击节点之后拖动框的位置。
    setPositionXY(newX, newY) {
        if (this.mindElementData == null || 
            this.mindElementData.type == MindElementType.NODE_CONNECT_LINE ||
            this.mindElementData.type == MindElementType.BAOWEI_VIEW) {
            this.leftControllSizeBoxObj = {
                x: 0,
                y: 0,
                width: 0,
                height: 0,
            }
    
            this.rightControllSizeBoxObj = {
                x: 0,
                y: 0,
                width: 0,
                height: 0,  
            }
            return
        }
        let space = this.getSelectedSpace();
        let setPointX;
        let setPointY;
        let setRightPointX;
        let setRightPointY;
        if (newX == undefined || newY == undefined) {
            setPointX = this.mindElementData.x - space
            setPointY = this.mindElementData.y
            setRightPointX = this.mindElementData.x + this.mindElementData.width
            setRightPointY = this.mindElementData.y
        } else {
            setPointX = newX - space;
            setPointY = newY;
            setRightPointX = newX + this.mindElementData.width
            setRightPointY = newY
        }

        this.leftControllSizeBoxObj = {
            x: setPointX,
            y: setPointY,
            width: this.controllSizeBoxWidth,
            height: this.mindElementData.height,
        }

        this.rightControllSizeBoxObj = {
            x: setRightPointX ,
            y: setRightPointY,
            width: this.controllSizeBoxWidth,
            height: this.mindElementData.height,  
        }
    }

    //拖动区域，改变选中框大小。
    changeBoxSize(newX, newY, buttonType ) {
        let space = this.getSelectedSpace();
        let setPointX;
        let setPointY;
        let setRightPointX;
        let setRightPointY;
        setPointX = newX - space;
        setPointY = newY;
        setRightPointX = newX + this.mindElementData.width
        setRightPointY = newY

        if ( buttonType == 'left') {
            setPointX = newX - space + this.mindElementData.x;
            setPointY = this.mindElementData.y;

            this.leftControllSizeBoxObj = {
                x: setPointX,
                y: setPointY,
                width: this.controllSizeBoxWidth,
                height: this.mindElementData.height,
            }
            this.changeBoxBorderSize(newX, newY, this.mindElementData.width - (newX), this.mindElementData.height);

        } else if (buttonType == 'right') {
            setRightPointX = newX + this.mindElementData.width + this.mindElementData.x;
            setRightPointY = this.mindElementData.y;

            this.rightControllSizeBoxObj = {
                x: setRightPointX,
                y: setRightPointY,
                width: this.controllSizeBoxWidth,
                height: this.mindElementData.height,
            }

            this.changeBoxBorderSize(-space, -space, this.mindElementData.width + (newX), this.mindElementData.height);
        }

    }

    //当元素刷新之后再次调用一下改变拖动按钮位置的方法。
    refreshButtonPosition(data) {
        if (data != null && this.mindElementData != null && data.id == this.mindElementData.id) {
            this.setPositionXY();
        }
    }

    //调用改变节点大小的方法。
    changeBoxSizeEnd() {
        if( this.size == null  ) {
            return;
        }
        let size = new Size(this.size.width,this.size.height);
        this.delegate.editMindmapContent.onDataSizeChanged(size,this.mindElementData.id);
    }

    //修改选中框大小。
    changeBoxBorderSize(x, y, width, height) {
        if(width < 0) {
            width = 0;
        }
        if (height < 0) {
            height = 0;
        }
        if (this.mindElementData == null || this.mindElementData == undefined) {
            return
        }

        let node = this.delegate.viewElementList.get(this.mindElementData.id);
        if (node == null || node == undefined) {
            return
        }
        node.setSelectedBorder(width,height,x)
        this.size = {
            width: width,
            height: height
        }
    }

    //选中框与节点之间的间距
    getSelectedSpace() {
        if (this.mindElementData == null) {
            return 6;
        }
        if (this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM || this.mindElementData.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
            return 1;
        }
        if (this.mindElementData.backgroundColor == Colors.clear &&
            (this.mindElementData.borderWidth <= 0 || this.mindElementData.borderColor == Colors.clear)) {
            return 0;
        }
        return 6;
    }

    //隐藏可以拖动节点区域。
    hiddenBottom() {
        this.leftControllSizeBoxObj = {
            width: 0,
            height: 0,
            x: 0,
            y: 0,
        };
        this.rightControllSizeBoxObj = {
            width: 0,
            height: 0,
            x: 0,
            y: 0,
        }
    }

}

export default ControllMindSize