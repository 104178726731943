// package mind.yushu.com.mindmap.viewmodel.mindelementdata;

import MindType from "../datatype/MindType"
import TimeMindTypeNodeUnit from "./TimeMindTypeNodeUnit";
import MindElementData from "./MindElementData"
import NodeLayoutType from "../datatype/NodeLayoutType";
import LineLayout from "../datatype/LineLayout";
import MindDisplayType from "../datatype/MindDisplayType"
import Colors from "../../utils/Colors";
import DoubleBubbleMindNodeUnit from "./DoubleBubbleMindNodeUnit"
import HashMap from "../core/base/HashMap";
import MindElementType from "../datatype/MindElementType";
import UiUtil from "../../utils/UiUtil";
import MindElementCalculation from "../../core/core/calcule/elementCalculation/MindElementCalculation";
import TimeLineContent from "./mindcontent/TimeLineContent";
import CGRect from "../core/base/basedata/Rect";
import CalculSaveImageRect from "../../core/core/calcule/CalculSaveImageRect";
import Config from "../../core/core/calcule/Config";
import Util from "../../utils/Util";
import StyleData from "../../assets/img/mapContent/assets/style/style.json"
import StyleModelCellData from "../core/minddata/StyleModelCellData";
import { length } from "file-loader";
import LineElementContent from "./mindcontent/LineElementContent";
import TextContent from "./mindcontent/TextContent";
import GeneralizationContent from "./mindcontent/GeneralizationContent";
import IconElementContent from "./mindcontent/IconElementContent";
import LinkElementContent from "./mindcontent/LinkElementContent";
import RemarksElementContent from "./mindcontent/RemarksElementContent";
import ConnectMapElementContent from "./mindcontent/ConnectMapElementContent";
import TimeDotElementContent from "./mindcontent/TimeDotElementContent";
import ImageContent from "./mindcontent/ImageContent";
import StyleModelTyle from "../datatype/StyleModelTyle";
import Size from "../core/base/Size";
import ViewStatus from "../datatype/ViewStatus"
import StyleManager from "../../core/core/storage/StyleManager";
import MindElementShapeType from "../datatype/MindElementShapeType";
import CGPoint from "../core/base/basedata/CGPoint";
import LineMindTypeNode from "./LineMindTypeNode";

/**
 * ProjectName: MindMap
 * Created by tony on 2020/8/10
 * Copyright(c) 2020 mindyushu.com
 */

class MindMapStyleHandle {
    constructor(vm,
        mindType,
        globalLayout,
        globalLineLayout,
        mindDisplayType,
        mindBGColor) {
        this.vm = vm;
        this.mainMindNodeUnit;
        this.freeNodes = new Array();
        this.timeMindTypeNodeUnit = new TimeMindTypeNodeUnit();
        this.doubleBubbleMindNodeUnit = new DoubleBubbleMindNodeUnit(MindType.DOUBLE_BUBBLE_MAP, 0);
        this.nodeConnectLineDataDict = new HashMap(); //节点连接线
        this.mindType = mindType;
        this.globalLayout = globalLayout;
        this.globalLineLayout = globalLineLayout;
        this.mindDisplayType = mindDisplayType;
        this.mindBGColor = mindBGColor;
        this.styleData;
        this.styleModelPanel;
        this.mindCurrentBGColor = -1;
    }

    setData(mainMindNodeUnit,
        freeNodes,
        timeMindTypeNodeUnit,
        doubleBubbleMindNodeUnit,
        nodeConnectLineDataDict) {
        this.mainMindNodeUnit = mainMindNodeUnit;
        this.freeNodes = freeNodes;
        this.timeMindTypeNodeUnit = timeMindTypeNodeUnit;
        this.doubleBubbleMindNodeUnit = doubleBubbleMindNodeUnit;
        this.nodeConnectLineDataDict = nodeConnectLineDataDict;
        return this;
    }

    creatCustomStyle() {
        let styleData = new StyleModelCellData().empty();
        styleData.id = 0;
        styleData.styleModelTyle = StyleModelTyle.Custom;
        styleData.mindType = this.mindType;
        styleData.globalLayout = this.globalLayout;
        styleData.globalLineLayout = this.globalLineLayout;
        styleData.mindBGColor = this.mindBGColor;
        let image = this.getCustomStylePreviewImage();
        
        StyleManager.get().setStyleData(styleData);
        if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.FLOW_CHART || this.mindType == MindType.BUBBLE_MAP) {
            StyleManager.get().setDataStyle(styleData.id, this.mainMindNodeUnit.rootTreeNode.value);
            let itemCount = this.mainMindNodeUnit.rootTreeNode.children.length;
            for (let index = 0; index< itemCount; index++) {
                let item =  this.mainMindNodeUnit.rootTreeNode.children[index];
                StyleManager.get().setDataStyle(styleData.id, item.value);
                let line = this.vm.getNodeLine(item.value.id);
                if (line.length > 0 && !line[0].isEmpty()) {
                    StyleManager.get().setDataStyle(styleData.id, line[0]);
                }
            }
            let mainMindElementDataDictValues = this.mainMindNodeUnit.mainMindElementDataDict.values();
            let nodeCont = mainMindElementDataDictValues.length
            for (let index = 0; index< nodeCont; index++) {
                let node = mainMindElementDataDictValues[index];
                    if (node.value.type != MindElementType.SUBJECT) {
                        StyleManager.get().setDataStyle(styleData.id, node.value);
                    }
            }


            let lineCont = this.mainMindNodeUnit.generalizationLineMindElementDataDict.values().length
            for (let index = 0; index< lineCont; index++) {
                let line = this.mainMindNodeUnit.generalizationLineMindElementDataDict.values()[index];
                    StyleManager.get().setDataStyle(styleData.id, line);
                    break;
            }

            let gnodeCont = this.mainMindNodeUnit.generalizationMindElementDataDict.values().length
            for (let index = 0; index< gnodeCont; index++) {
                let node = this.mainMindNodeUnit.generalizationMindElementDataDict.values()[index];
                StyleManager.get().setDataStyle(styleData.id, node.value);
                    break;
            }
            let encircleMindElementDataValues = this.mainMindNodeUnit.encircleMindElementDataDict.values()
            let mindCount = encircleMindElementDataValues.length;
            let encircleMindElement = null
            for (let index = 0; index < mindCount; index++) {
                let mind = encircleMindElementDataValues[index]
                if (mind.lineContent != null && (encircleMindElement == null || mind.lineContent.textContent != null)) {
                    encircleMindElement = mind
                }
            }
            
            if (encircleMindElement != null) {
                StyleManager.get().setDataStyle(styleData.id, encircleMindElement);
            }

            let nodeConnectLineDataValues = this.nodeConnectLineDataDict.values()
            let dictLineCont = nodeConnectLineDataValues.length
            let nodeConnectElement = null
            for (let index = 0; index< dictLineCont; index++) {
                let line = nodeConnectLineDataValues[index];
                if (line.lineContent != null && (nodeConnectElement == null || line.lineContent.textContent != null)) {
                    nodeConnectElement = line
                }
            }
            if (nodeConnectElement != null) {
                StyleManager.get().setDataStyle(styleData.id, nodeConnectElement);
            }
        } else if (this.mindType == MindType.TIME_MAP) {
                StyleManager.get().setDataStyle(styleData.id, this.timeMindTypeNodeUnit.title);

                let mainMindElementDataDictValues = this.timeMindTypeNodeUnit.mainMindElementDataDict.values()
                let nodeCount = mainMindElementDataDictValues.length;
                
                for (let index = 0; index< nodeCount; index++) {
                    let node  = mainMindElementDataDictValues[index];
                    StyleManager.get().setDataStyle(styleData.id, node.head);
                    StyleManager.get().setDataStyle(styleData.id, node.desc);
                    let line = new LineElementContent(new CGPoint(), new CGPoint(), this.timeMindTypeNodeUnit.baseLine.timeLineContent.color, 0);
                    let lineDate = new LineMindTypeNode().emptyMindNode(); 
                    lineDate.value.id = 1;
                    lineDate.value.backgroundColor = Colors.clear;
                    lineDate.value.borderColor = Colors.clear;
                    lineDate.value.type = MindElementType.LINE;
                    lineDate.value.lineContent = line;
                    StyleManager.get().setDataStyle(styleData.id, lineDate.value);
                }

        } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {

            StyleManager.get().setDataStyle(styleData.id, this.doubleBubbleMindNodeUnit.rootTreeNode.value);
            let itemCount = this.doubleBubbleMindNodeUnit.rootTreeNode.children.length
            for (let index = 0; index< itemCount; index++) {
                let item = this.doubleBubbleMindNodeUnit.rootTreeNode.children[index]
                StyleManager.get().setDataStyle(styleData.id, item.value);
                let line = this.vm.getNodeLine(item.value.id);
                if (line.length > 0 && !line[0].isEmpty()) {
                    StyleManager.get().setDataStyle(styleData.id, line[0]);
                }
            }

            let nodeCont = this.doubleBubbleMindNodeUnit.mainMindElementDataDict.values().length
            for (let index = 0; index< nodeCont; index++) {
                let node = this.doubleBubbleMindNodeUnit.mainMindElementDataDict.values()[index];
                if (node.value.type != MindElementType.SUBJECT) {
                    StyleManager.get().setDataStyle(styleData.id, node.value);
                }
            }
        }

        let freeNodesCount = this.freeNodes.length
        for (let index = 0; index< freeNodesCount; index++) {
            let unit = this.freeNodes[index]
            let unitLineCount = unit.lineMindElementDataDict.values().length;
            for (let x = 0; x< unitLineCount; x++) {
                let line = unit.lineMindElementDataDict.values()[x];
                StyleManager.get().setFreeNodesLineStyle(styleData.id, line);
                break;
            }

            if (unit.rootTreeNode != null && !unit.rootTreeNode.isEmpty()) {
                StyleManager.get().setFreeNodesStyle(styleData.id, unit.rootTreeNode.value);
            }

            let unitNodeCount = unit.mainMindElementDataDict.values().length;
            for (let x = 0; x< unitNodeCount; x++) {
                let node = unit.mainMindElementDataDict.values()[x]
                if (node.value.type == MindElementType.SON_SUBJECT) {
                    StyleManager.get().setFreeSonSubjectNodesStyle(styleData.id, node.value);
                    break;
                }
            }
        }
        let nodes = styleData.toJSONString();
        // long dbId = StorageManager.get().createCustomStyle(previewUrl, nodes);
        let obj = {content:nodes,rect:image}
        return obj;
    }

    findSubjectNode(data) {
        if (data.type == MindElementType.SUBJECT || data.type == MindElementType.MAIN_SUBJECT) {
            return data;
        }
        if (data.type == MindElementType.SON_SUBJECT) {
            let node = this.vm.getNodeById(data.parentNodeId);
            if (node.isEmpty()) {
                return new MindElementData().emptyMindNode();
            }
            return this.findSubjectNode(node.value);
        }
        return new MindElementData().emptyMindNode();
    }

    setStyleToMap(data, isCoverCustomStyle) {
        if (isCoverCustomStyle) {
            this.vm.clearChangeDataStyleId()
        }
        this.mindCurrentBGColor = this.mindBGColor;
        let isUserCustom = data.styleModelTyle == StyleModelTyle.Custom
        let styleData = this.getStyleModelPreview(data.content)
        this.vm.collectionStyleLineColor(styleData);
        if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.FLOW_CHART) {
            this.setLineMapStyleToMap(data, styleData, isCoverCustomStyle);
        } else if(this.mindType == MindType.BUBBLE_MAP){
            this.setBubbleStyleToMap(data, styleData, isCoverCustomStyle);
        } else if (this.mindType == MindType.TIME_MAP) {
            this.setItemStyleToMap(data, styleData, isCoverCustomStyle);
        } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            this.setDoubleBubbleStyleToMap(data, styleData, isCoverCustomStyle);
        }
        let freeNodes = this.freeNodes
        let freeNodesLength = freeNodes.length
        for (let index = 0; index < freeNodesLength; index++) {
            let unit = freeNodes[index]

            if (this.mindType == MindType.DOUBLE_BUBBLE_MAP ||
                this.mindType == MindType.BUBBLE_MAP) {
                let lineData = unit.lineMindElementDataDict.values()
                let lineLength = lineData.length
                for (let index = 0; index < lineLength; index++) {
                    let line = lineData[index];
                    if (isUserCustom) {
                        line.stickStyle(styleData.freeNodesLine);
                    } else {
                        line.stickColorStyle(styleData.freeNodesLine);
                    }
                }

                let nodeData = unit.mainMindElementDataDict.values()
                let nodeLength = nodeData.length
                for (let index = 0; index < nodeLength; index++) {
                    let node = nodeData[index];
                    let mindElementShape = node.value.mindElementShape;
                    if (this.isCoverCustomStyleNode(node.value, isCoverCustomStyle)) {
                        if (isUserCustom) {
                            if (node.value.type == MindElementType.SUBJECT) {
                                node.stickStyle(styleData.freeNodes);
                            } else if (node.value.type == MindElementType.SON_SUBJECT) {
                                node.stickStyle(styleData.freeSonSubjectNodes);
                            }
                        } else {
                            if (node.value.type == MindElementType.SUBJECT) {
                                node.stickColorStyle(styleData.freeNodes);
                            } else if (node.value.type == MindElementType.SON_SUBJECT) {
                                node.stickColorStyle(styleData.freeSonSubjectNodes);
                            }
                        }
                        
                        if (this.mindType == MindType.BUBBLE_MAP || this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
                            node.value.mindElementShape = mindElementShape;
                        }
                        if (node.value.layout == NodeLayoutType.LAYOUT_CIRCLE &&
                            mindElementShape == MindElementShapeType.Circular) {
                            node.value.mindElementShape = MindElementShapeType.Circular;
                        }
                        node.value.checkShapeCard(styleData.mindBGColor)
                    }
                    
                    let lines = this.vm.getNodeLine(node.value.id);
                    if (!lines.isEmpty() &&
                            (node.value.mindElementShape == MindElementShapeType.Underline ||
                                node.value.mindElementShape == MindElementShapeType.Two_Underline) &&
                            lines[0].lineContent != null) {
                        node.value.borderColor = lines[0].lineContent.color;
                    }
                }
            } else {
                let lineData = unit.lineMindElementDataDict.values()
                let lineLength = lineData.length
                for (let index = 0; index < lineLength; index++) {
                    let line = lineData[index];
                    if (line.lineContent != null && line.lineContent.targetId > -1) {
                        let node = this.vm.getNodeById(line.lineContent.targetId);
                        if (!node.isEmpty()) {
                            let subject = node.value;
                            if (subject.layout == NodeLayoutType.LAYOUT_BOTTOM ||
                                    subject.layout == NodeLayoutType.LAYOUT_TOP ||
                                    subject.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT_RIGHT ||
                                    subject.layout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT ||
                                    subject.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT ||
                                    subject.layout == NodeLayoutType.LAYOUT_TREE_RIGHT ||
                                    subject.layout == NodeLayoutType.LAYOUT_TREE_LEFT ||
                                    subject.layout == NodeLayoutType.LAYOUT_TREE_LEFT_RIGHT) {
                                line.stickColorStyle(styleData.freeNodesLine);
                            } else {
                                line.stickStyle(styleData.freeNodesLine);
                            }
                        }
                    }
                }
                let nodeData = unit.mainMindElementDataDict.values()
                let nodeLength = nodeData.length
                for (let index = 0; index < nodeLength; index++) {
                    let node = nodeData[index];
                    let dataShape = node.value.mindElementShape;
                    if (this.isCoverCustomStyleNode(node.value, isCoverCustomStyle)) {
                        if (node.value.type == MindElementType.SUBJECT) {
                            node.stickStyle(styleData.freeNodes);
    
                        } else if (node.value.type == MindElementType.SON_SUBJECT) {
                            node.stickStyle(styleData.freeSonSubjectNodes);
                        }
                        if (node.value.layout == NodeLayoutType.LAYOUT_CIRCLE &&
                            dataShape == MindElementShapeType.Circular) {
                            node.value.mindElementShape = MindElementShapeType.Circular;
                        }
                    }
                    let lines = this.vm.getNodeLine(node.value.id);
                    if (!lines.isEmpty() &&
                            (node.value.mindElementShape == MindElementShapeType.Underline ||
                                node.value.mindElementShape == MindElementShapeType.Two_Underline) &&
                            lines[0].lineContent != null) {
                        node.value.borderColor = lines[0].lineContent.color;
                    }
                    node.value.checkShapeCard(styleData.mindBGColor)
                }
            }
            MindElementCalculation.set(unit.rootTreeNode.value).caluleTextForData();
        }
        this.vm.refresh(true);
        this.vm.willSave();
        this.vm.addHostry();
    }

    getStyleModelPreview(fileName) {
        // let value = require("../../assets/img/mapContent/assets/style/" + fileName + ".json")
        let value = fileName
        // console.log(value);
        let data = JSON.parse(value)
        let styleData = new StyleModelCellData()
        for (var key in data) {
            let keyValue = data[key]
            styleData[key] = keyValue;
            if (key == "rootData" || 
                key == "sonSubjectData" ||
                key == "generalizationData" ||
                key == "generalizationLineData" ||
                key == "nodeConnectLineData" ||
                key == "freeNodes" ||
                key == "freeSonSubjectNodes" ||
                key == "freeNodesLine" ||
                key == "encircleData" ) {
                styleData[key] = this.deserializationLineMindElementData(keyValue);
            } else if (key == "subjectDatas" || key == "subjectLineData") {
                let list = new Array();
                let length = keyValue.length;
                for (let index = 0; index < length; index++) {
                    list.push(this.deserializationLineMindElementData(keyValue[index]))
                }
                styleData[key] = list;
            }
        }
        return styleData
    }

    deserializationLineMindElementData(dataValue) {
        if (dataValue == null) {
            return
        }
        let mindElementData = new MindElementData()
        for (var mindElementDataKey in dataValue) {
            let mindElementDataValue = dataValue[mindElementDataKey]
            mindElementData[mindElementDataKey] = mindElementDataValue
            if (mindElementDataKey == "lineContent" && mindElementDataValue != null) {
                let lineContent = new LineElementContent()
                for (var lineContentKey in mindElementDataValue) {
                    let lineContentValue = mindElementDataValue[lineContentKey]
                    lineContent[lineContentKey] = lineContentValue
                    if ((lineContentKey == "lineLayout" ||
                        lineContentKey == "orientation" ||
                        lineContentKey == "connectLineType") &&
                        lineContentValue != null &&
                        typeof lineContentValue == "string") {
                        lineContent[lineContentKey] = parseInt(lineContentValue)
                    }
                }
                mindElementData[mindElementDataKey] = lineContent
            } else if ((mindElementDataKey == "textContent" || mindElementDataKey == "titleContent") && mindElementDataValue != null) {
                let textContent = new TextContent()
                for (var textContentKey in mindElementDataValue) {
                    let textContentValue = mindElementDataValue[textContentKey]
                    textContent[textContentKey] = textContentValue
                }
                mindElementData[mindElementDataKey] = textContent
            } else if (mindElementDataKey == "generalizationContent" && mindElementDataValue != null) {
                let generalizationContent = new GeneralizationContent();
                for (var generalizationContentKey in mindElementDataValue) {
                    let generalizationContentValue = mindElementDataValue[generalizationContentKey]
                    generalizationContent[generalizationContentKey] = generalizationContentValue
                }
                mindElementData[mindElementDataKey] = generalizationContent
            } else if (mindElementDataKey == "iconElementContents" && mindElementDataValue != null) {
                let iconElementContents = new Array();
                let iconElementContentSize = mindElementDataValue.length;
                for (let index = 0; index < iconElementContentSize; index++) {
                    let iconElementContent = new IconElementContent();
                    let iconElementData = mindElementDataValue[index];
                    for (var iconElementContentKey in iconElementData) {
                        let iconElementContentValue = iconElementData[iconElementContentKey]
                        iconElementContent[iconElementContentKey] = iconElementContentValue
                        if ((iconElementContentKey == "layoutType") &&
                            iconElementContentValue != null && typeof iconElementContentValue == "string") {
                            iconElementContent[iconElementContentKey] = parseInt(iconElementContentValue)
                        }
                    }
                    iconElementContents.push(iconElementContent);
                }
                mindElementData[mindElementDataKey] = iconElementContents
            } else if (mindElementDataKey == "linkElementContent" && mindElementDataValue != null) {
                let linkElementContent = new LinkElementContent();
                for (var linkElementContentKey in mindElementDataValue) {
                    let linkElementContentValue = mindElementDataValue[linkElementContentKey]
                    linkElementContent[linkElementContentKey] = linkElementContentValue
                    if ((linkElementContentKey == "layoutType") &&
                        linkElementContentValue != null && typeof linkElementContentValue == "string") {
                        linkElementContent[linkElementContentKey] = parseInt(linkElementContentValue)
                    }
                }
                mindElementData[mindElementDataKey] = linkElementContent
            } else if (mindElementDataKey == "remarksElementContent" && mindElementDataValue != null) {
                let remarksElementContent = new RemarksElementContent();
                for (var remarksElementContentKey in mindElementDataValue) {
                    let remarksElementContentValue = mindElementDataValue[remarksElementContentKey]
                    remarksElementContent[remarksElementContentKey] = remarksElementContentValue
                    if ((remarksElementContentKey == "layoutType") &&
                        remarksElementContentValue != null && typeof remarksElementContentValue == "string") {
                        remarksElementContent[remarksElementContentKey] = parseInt(remarksElementContentValue)
                    }
                }
                mindElementData[mindElementDataKey] = remarksElementContent
            } else if (mindElementDataKey == "connectMapElementContent" && mindElementDataValue != null) {
                let connectMapElementContent = new ConnectMapElementContent();
                for (var connectMapElementContentKet in mindElementDataValue) {
                    let connectMapElementContentValue = mindElementDataValue[connectMapElementContentKet]
                    remarksElementContent[connectMapElementContentKet] = connectMapElementContentValue

                    if ((connectMapElementContentKet == "layoutType") &&
                        connectMapElementContentValue != null && typeof connectMapElementContentValue == "string") {
                        remarksElementContent[connectMapElementContentKet] = parseInt(connectMapElementContentValue)
                    }
                }
                mindElementData[mindElementDataKey] = connectMapElementContent
            } else if (mindElementDataKey == "timeLineContent" && mindElementDataValue != null) {
                let timeLineContent = new TimeLineContent();
                for (var timeLineContentKey in mindElementDataValue) {
                    let timeLineContentValue = mindElementDataValue[timeLineContentKey]
                    timeLineContent[timeLineContentKey] = timeLineContentValue
                    if ((timeLineContentKey == "nodeType" ||
                        timeLineContentKey == "orientationType") &&
                        timeLineContentValue != null &&
                        typeof timeLineContentValue == "string") {
                        timeLineContent[timeLineContentKey] = parseInt(timeLineContentValue)
                    }
                    if (timeLineContentKey == "dots") {
                        let dots = new Array();
                        let dotsSize = timeLineContentValue.length;
                        for (var index = 0; index < dotsSize; index++) {
                            let timeDotElementContent = new TimeDotElementContent();
                            let timeDotElementData = mindElementDataValue[index];
                            for (var timeDotElementContentKey in timeDotElementData) {
                                let timeDotElementDataValue = timeDotElementData[timeDotElementContentKey]
                                timeDotElementContent[timeDotElementContentKey] = timeDotElementDataValue
                                if ((timeDotElementContentKey == "color") && timeDotElementDataValue != null && typeof timeDotElementDataValue == "string") {
                                    timeDotElementContent[timeDotElementContentKey] = parseInt(timeDotElementDataValue)
                                }
                            }
                            dots.push(timeDotElementContent);
                        }
                        timeLineContent[timeLineContentKey] = dots
                    }
                }
                mindElementData[mindElementDataKey] = timeLineContent
            } else if (mindElementDataKey == "imageContent" && mindElementDataValue != null) {
                let imageContent = new ImageContent();
                for (var imageContentKet in mindElementDataValue) {
                    let imageContentContentValue = mindElementDataValue[imageContentKet]
                    imageContent[imageContentKet] = imageContentContentValue

                    if ((imageContentKet == "layoutType" || imageContentKet == "shapeType") &&
                        imageContentContentValue != null && typeof imageContentContentValue == "string") {
                        imageContent[imageContentKet] = parseInt(imageContentContentValue)
                    }
                }
                mindElementData[mindElementDataKey] = imageContent
            } else if ((mindElementDataKey == "type" ||
                mindElementDataKey == "layout" ||
                mindElementDataKey == "mindElementShape") &&
                mindElementDataValue != null && typeof mindElementDataValue == "string") {
                mindElementData[mindElementDataKey] = parseInt(mindElementDataValue)
            }
        }
        return mindElementData
    }

    setDoubleBubbleStyleToMap(data, styleData, isCoverCustomStyle) {
        if (this.mindType != MindType.DOUBLE_BUBBLE_MAP ||
            this.doubleBubbleMindNodeUnit.rootTreeNode == null ||
            this.doubleBubbleMindNodeUnit.rootTreeBrotherNode == null) {
            return;
        }
        let subjectLineData = this.getStyleLineColors(styleData);
        let subjectDatas = this.getStyleSubjectDatas(styleData);
        if (this.isCoverCustomStyleNode(this.doubleBubbleMindNodeUnit.rootTreeNode.value, isCoverCustomStyle)) {
            this.setDataColorForStyleModel(this.doubleBubbleMindNodeUnit.rootTreeNode.value, styleData.rootData, styleData, false);
            MindElementCalculation.set(this.doubleBubbleMindNodeUnit.rootTreeNode.value).caluleTextForData();
        }
        
        if (this.isCoverCustomStyleNode(this.doubleBubbleMindNodeUnit.rootTreeBrotherNode.value, isCoverCustomStyle)) {
            this.setDataColorForStyleModel(this.doubleBubbleMindNodeUnit.rootTreeBrotherNode.value, styleData.rootData, styleData, false);
            MindElementCalculation.set(this.doubleBubbleMindNodeUnit.rootTreeBrotherNode.value).caluleTextForData();
        }
        this.mindBGColor = styleData.mindBGColor;
        this.vm.changMindBackgroundColor(styleData.mindBGColor, false);
        this.vm.delegate.changMindBackgroundColor(styleData.mindBGColor);

        let count = this.doubleBubbleMindNodeUnit.rootTreeNode.children.length;
        if (count > 0 && subjectDatas.length > 0) {
            for (let index = 0; index < count; index++) {
                let subject = this.doubleBubbleMindNodeUnit.rootTreeNode.children[index];
                if (this.isCoverCustomStyleNode(subject.value, isCoverCustomStyle)) {
                    if (subjectDatas.length > index) {
                        this.setDataColorForStyleModel(subject.value, subjectDatas[index], styleData, false);
                    } else {
                        this.setDataColorForStyleModel(subject.value, subjectDatas[index % subjectDatas.length], styleData, false);
                    }
                }
                let lines = this.vm.getNodeLine(subject.value.id);
                if (lines.length > 0 && !lines[0].isEmpty()) {
                    if (subjectLineData.length > index) {
                        lines[0].stickColorStyle(subjectLineData[index]);
                    } else {
                        lines[0].stickColorStyle(subjectLineData[index % subjectLineData.length]);
                    }
                }
            }
        }

        let brotherCount = this.doubleBubbleMindNodeUnit.rootTreeBrotherNode.children.length;
        if (brotherCount > 0 && subjectDatas.length > 0) {
            for (let index = 0; index < brotherCount; index++) {
                let subject = this.doubleBubbleMindNodeUnit.rootTreeBrotherNode.children[index];
                if (this.isCoverCustomStyleNode(subject.value, isCoverCustomStyle)) {
                    if (subjectDatas.length > index) {
                        this.setDataColorForStyleModel(subject.value, subjectDatas[index], styleData, false);
                    } else {
                        this.setDataColorForStyleModel(subject.value, subjectDatas[index % subjectDatas.length], styleData, false);
                    }
                }

                let lines = this.vm.getNodeLine(subject.value.id);
                if (lines.length > 0 && !lines[0].isEmpty()) {
                    if (subjectLineData.length > index) {
                        lines[0].stickColorStyle(subjectLineData[index]);
                    } else {
                        lines[0].stickColorStyle(subjectLineData[index % subjectLineData.length]);
                    }
                }
            }
        }
        if (this.doubleBubbleMindNodeUnit.commonGroundMindElementDatas.length > 0 && subjectDatas.length > 0) {
            let mindData1 = this.doubleBubbleMindNodeUnit.commonGroundMindElementDatas
            let mindDataLength = mindData1.length
            for (let index = 0; index < mindDataLength; index++) {
                const mindData = mindData1[index];
                if (this.isCoverCustomStyleNode(mindData, isCoverCustomStyle)) {
                    this.setDataColorForStyleModel(mindData, subjectDatas[0], styleData, false);
                    MindElementCalculation.set(mindData).caluleTextForData();
                }
                let lines = this.doubleBubbleMindNodeUnit.getNodeLines(mindData.id);
                if ((lines.length > 1)) {
                    let linesData = lines
                    let linesLength = linesData.length
                    for (let index = 0; index < linesLength; index++) {
                        const line = linesData[index];
                        line.stickColorStyle(subjectLineData[0]);
                    }
                }
            }
        }

        let lineData = this.doubleBubbleMindNodeUnit.lineMindElementDataDict.values()
        let lineDataLength = lineData.length
        for (let index = 0; index < lineDataLength; index++) {
            let line = lineData[index];
            if ((line.lineContent != null && line.lineContent.targetId > -1)) {
                let node = this.vm.getNodeById(line.lineContent.targetId);
                if (!node.isEmpty() && node.value.type == MindElementType.SON_SUBJECT) {
                    let subject = this.findSubjectNode(node.value);
                    if (subject.isEmpty()) {
                        continue;
                    }
                    let subjectLines = this.vm.getNodeLine(subject.id);
                    if (subjectLines.length == 0 || subjectLines[0].isEmpty()) {
                        continue;
                    }
                    line.stickColorStyle(subjectLines[0]);
                }
            }
        }

        let nodeData = this.doubleBubbleMindNodeUnit.mainMindElementDataDict.values()
        let nodeDataLenght = nodeData.length
        for (let index = 0; index < nodeDataLenght; index++) {
            let node = nodeData[index];
            if (node.value.type == MindElementType.SON_SUBJECT && 
                this.isCoverCustomStyleNode(node.value, isCoverCustomStyle)) {
                this.setDataColorForStyleModel(node.value, styleData.sonSubjectData, styleData, false);
            }
        }

        let MindElementDataLine = this.nodeConnectLineDataDict.values()
        let MindElementDataLineLength = MindElementDataLine.length
        for (let index = 0; index < MindElementDataLineLength; index++) {
            let line = MindElementDataLine[index];
            line.stickColorStyle(styleData.nodeConnectLineData);
        }
    }
    setItemStyleToMap(data, styleData, isCoverCustomStyle) {
        if (this.mindType != MindType.TIME_MAP || this.timeMindTypeNodeUnit.title == null) {
            return;
        }
        let subjectLineData = this.getStyleLineColors(styleData);
        let subjectDatas = this.getStyleSubjectDatas(styleData);
        if (this.isCoverCustomStyleNode(this.timeMindTypeNodeUnit.title, isCoverCustomStyle)) {
            this.setDataColorForStyleModel(this.timeMindTypeNodeUnit.title, styleData.rootData, styleData, false);
            MindElementCalculation.set(this.timeMindTypeNodeUnit.title).caluleTextForData();
        }
        this.mindBGColor = styleData.mindBGColor;
        this.vm.changMindBackgroundColor(styleData.mindBGColor, false);
        this.vm.delegate.changMindBackgroundColor(styleData.mindBGColor);
        if (subjectDatas.length > 0) {

            let nodeData = this.timeMindTypeNodeUnit.mainMindElementDataDict.values()
            let nodeLength = nodeData.length
            for (let index = 0; index < nodeLength; index++) {
                const node = nodeData[index];
                
                if (this.isCoverCustomStyleNode(node.head, isCoverCustomStyle)) {
                    this.setDataColorForStyleModel(node.head, subjectDatas[0], styleData, false);
                    MindElementCalculation.set(node.head).caluleTextForData();
                }
                if (this.isCoverCustomStyleNode(node.title, isCoverCustomStyle)) {
                    this.setDataColorForStyleModel(node.title, subjectDatas[0], styleData, false);
                    MindElementCalculation.set(node.title).caluleTextForData();
                }
                if (!styleData.sonSubjectData.isEmpty() && 
                    this.isCoverCustomStyleNode(node.desc, isCoverCustomStyle)) {
                    this.setDataColorForStyleModel(node.desc, styleData.sonSubjectData, styleData, false);
                    MindElementCalculation.set(node.desc).caluleTextForData();
                }
            }
            if (subjectLineData.length > 0) {
                if (subjectLineData[0].timeLineContent == null) {
                    subjectLineData[0].timeLineContent = new TimeLineContent();
                    subjectLineData[0].timeLineContent.color = subjectLineData[0].lineContent.color;
                    subjectLineData[0].timeLineContent.nodeType = this.timeMindTypeNodeUnit.baseLine.timeLineContent.nodeType;
                    subjectLineData[0].timeLineContent.orientationType = this.timeMindTypeNodeUnit.baseLine.timeLineContent.orientationType;
                }
                this.timeMindTypeNodeUnit.baseLine.stickColorStyle(subjectLineData[0]);
            }
        }
    }

    isClearBackgorund(data) {
        return Colors.isClear(data.backgroundColor);
    }

    isBackgorundSameMindMapBG(data) {
        return data.backgroundColor == this.mindCurrentBGColor;
    }

    isBorderSameMindMapBG(data) {
        return data.borderColor == this.mindCurrentBGColor;
    }

    isClearBorder(data) {
        return Colors.isClear(data.borderColor);
    }

    setDataColorForStyleModel(data, refer, styleData, isUserCustom) {
        if (data.isEmpty() || refer.isEmpty()) {
            return;
        }
        let isClearBackgorund = this.isClearBackgorund(data);
        let isClearBorder = this.isClearBorder(data);
        let isBackgorundSameMindMapBG = this.isBackgorundSameMindMapBG(data);
        let isBorderSameMindMapBG = this.isBorderSameMindMapBG(data);
        if (isUserCustom) {
            data.stickStyle(refer);
        } else {
            data.stickColorStyle(refer);
        }
        if (isClearBackgorund) {
            data.backgroundColor = Colors.clear;
        } else if (isBackgorundSameMindMapBG) {
            data.backgroundColor = styleData.mindBGColor;
        }
        if (isClearBorder) {
            data.borderColor = Colors.clear;
        } else if (isBorderSameMindMapBG) {
            data.borderColor = styleData.mindBGColor;
        }

        if (!isClearBackgorund && !isBackgorundSameMindMapBG) {
            if (Colors.isClear(data.backgroundColor) || data.backgroundColor == styleData.mindBGColor) {
                if (!Colors.isClear(refer.borderColor) && refer.borderColor != styleData.mindBGColor) {
                    data.backgroundColor = refer.borderColor;
                }
            }
        }
        if (!isClearBorder && !isBorderSameMindMapBG) {
            if (Colors.isClear(data.borderColor) || data.borderColor == styleData.mindBGColor) {
                if (!Colors.isClear(refer.backgroundColor) && refer.backgroundColor != styleData.mindBGColor) {
                    data.borderColor = refer.backgroundColor;
                }
            }
        }
        data.checkSetTextColor(styleData.mindBGColor);
    }

    getStyleLineColors(styleData) {
        var lines = [];
        let count = styleData.subjectLineData.length;
        if (count == 0) {
            return lines;
        } else if (count == 1) {
            return styleData.subjectLineData;
        } else {

            if (styleData.subjectLineData[0].lineContent != null && null != styleData.subjectLineData[1].lineContent &&
                    styleData.subjectLineData[0].lineContent.color == styleData.subjectLineData[1].lineContent.color) {
                return styleData.subjectLineData;
            }

            for (let index = 0; index < count; index++) {
                let cell = styleData.subjectLineData[index];
                if (cell.lineContent == null) {
                    continue;
                }
                if (lines.isEmpty()) {
                    lines.push(cell);
                } else {
                    var added = false;
                    for (let j = 0; j < lines.length; j++) {
                        if (cell.lineContent.color == lines[j].lineContent.color) {
                            added = true;
                            break;
                        }
                    }
                    if (!added) {
                        lines.push(cell);
                    }
                }
            }
        }
        return lines;
    }

    getStyleSubjectDatas(styleData) {
        let lines = this.getStyleLineColors(styleData);
        if (lines.isEmpty() || lines.length == 1 || styleData.subjectDatas.isEmpty() || styleData.subjectDatas.length == 1) {
            return styleData.subjectDatas;
        }
        var datas = [];
        let count = lines.length;
        for (let index = 0; index < count; index++) {
            let lineCell = lines[index];
            var added = false;
            for (let j = 0; j < styleData.subjectDatas.length; j++) {
                let dataCell = styleData.subjectDatas[j];
                if (dataCell.borderWidth > 0 && !Colors.isClear(dataCell.borderColor) && lineCell.lineContent.color == dataCell.borderColor) {
                    datas.push(dataCell);
                    added = true;
                    break;
                } else if (!Colors.isClear(dataCell.backgroundColor) && lineCell.lineContent.color == dataCell.backgroundColor) {
                    datas.push(dataCell);
                    added = true;
                    break;
                }
            }
            if (!added) {
                if (index < styleData.subjectDatas.length) {
                    datas.push(styleData.subjectDatas[index]);
                } else {
                    datas.push(styleData.subjectDatas[index % styleData.subjectDatas.length]);
                }
            }
        }
        return datas;
    }

    setLineMapStyleToMap(data, styleData, isCoverCustomStyle) {
        if (this.mindType != MindType.LINE_MAP && this.mindType != MindType.FLOW_CHART) {
            return;
        }
        let sonNodeBackgroundAndLineSame = new HashMap();
        let nodeData = this.mainMindNodeUnit.mainMindElementDataDict.values()
        let nodeLength = nodeData.length
        this.mindBGColor = styleData.mindBGColor;
        for (let index = 0; index < nodeLength; index++) {
            const node = nodeData[index];
            if (node.value.type == MindElementType.SON_SUBJECT) {
                let lines = this.vm.getNodeLine(node.value.id);
                if (lines.length == 0 || lines[0].isEmpty() || lines[0].lineContent == null) {
                    continue;
                }
                sonNodeBackgroundAndLineSame.put(node.value.id, lines[0].lineContent.color == node.value.backgroundColor);
            }
        }
        let subjectLineData = this.getStyleLineColors(styleData);
        let isUserCustom = data.styleModelTyle == StyleModelTyle.Custom
        let rootShape = this.mainMindNodeUnit.rootTreeNode.value.mindElementShape;
        if (this.isCoverCustomStyleNode(this.mainMindNodeUnit.rootTreeNode.value, isCoverCustomStyle)) {
            this.setDataColorForStyleModel(this.mainMindNodeUnit.rootTreeNode.value, styleData.rootData, styleData, isUserCustom);
        }
        if (this.mainMindNodeUnit.rootTreeNode.value.layout == NodeLayoutType.LAYOUT_CIRCLE &&
            rootShape == MindElementShapeType.Circular && this.isCoverCustomStyleNode(this.mainMindNodeUnit.rootTreeNode.value, isCoverCustomStyle)) {
                this.mainMindNodeUnit.rootTreeNode.value.mindElementShape = MindElementShapeType.Circular;
        }
        MindElementCalculation.set(this.mainMindNodeUnit.rootTreeNode.value).caluleTextForData();

        this.vm.changMindBackgroundColor(styleData.mindBGColor, false);
        this.vm.delegate.changMindBackgroundColor(styleData.mindBGColor);
        if (isUserCustom) {
            this.vm.changeLineLayout(styleData.globalLineLayout);
        }
        
        let subjectDatas = this.getStyleSubjectDatas(styleData);
        let count = this.mainMindNodeUnit.rootTreeNode.children.length;
        if (count > 0 && subjectDatas.length > 0) {
            for (let index = 0; index < count; index++) {
                let subject = this.mainMindNodeUnit.rootTreeNode.children[index];
                let isBackgorundColor = !Colors.isClear(subject.value.backgroundColor);
                let isBorderColor = !Colors.isClear(subject.value.borderColor);
                let dataShape = subject.value.mindElementShape;
                if (this.isCoverCustomStyleNode(subject.value, isCoverCustomStyle)) {
                    let ref = subjectDatas.length > index ? subjectDatas[index] : subjectDatas[index % subjectDatas.length];
                    this.setDataColorForStyleModel(subject.value, ref, styleData, isUserCustom);
                    if (subject.value.layout == NodeLayoutType.LAYOUT_CIRCLE &&
                            dataShape == MindElementShapeType.Circular) {
                        subject.value.mindElementShape = MindElementShapeType.Circular;
                    }
                }
                
                MindElementCalculation.set(subject.value).caluleTextForData();
                let lines = this.vm.getNodeLine(subject.value.id);
                if (lines.length > 0 && !lines[0].isEmpty()) {
                    if (isUserCustom) {
                        if (subjectLineData.length > index) {
                            lines[0].stickStyle(subjectLineData[index]);
                        } else {
                            lines[0].stickStyle(subjectLineData[index % subjectLineData.length]);
                        }
                    } else {
                        if (subjectLineData.length > index) {
                            lines[0].stickColorStyle(subjectLineData[index]);
                        } else {
                            lines[0].stickColorStyle(subjectLineData[index % subjectLineData.length]);
                        }
                    }
                    if ((subject.value.mindElementShape == MindElementShapeType.Underline || 
                        subject.value.mindElementShape == MindElementShapeType.Two_Underline) &&
                        Colors.isClear(subject.value.borderColor) && lines[0].lineContent != null) {
                        subject.value.borderColor = lines[0].lineContent.color;
                        if (subject.value.mindElementShape == MindElementShapeType.Card) {
                            subject.value.checkSetTextColor(this.mindBGColor);
                        }
                    }
                }                
                if (subject.value.isCardContent() && (Colors.isClear(subject.value.borderColor) || subject.value.borderColor == subject.value.backgroundColor)) {
                    subject.value.borderColor = Colors.getColorIntValue(Colors.getNumberToRgbDark(subject.value.backgroundColor, 0.5));
                }
                subject.value.checkSetTextColor(this.mindBGColor)
            }
        }

        let lineData = this.mainMindNodeUnit.lineMindElementDataDict.values()
        let lineLength = lineData.length
        for (let index = 0; index < lineLength; index++) {
            const line = lineData[index];
            if (line.lineContent != null && line.lineContent.targetId > -1) {
                let node = this.vm.getNodeById(line.lineContent.targetId);
                if (!node.isEmpty() && node.value.type == MindElementType.SON_SUBJECT) {
                    let subject = this.findSubjectNode(node.value);
                    if (subject.isEmpty()) {
                        continue;
                    }
                    let subjectLines = this.vm.getNodeLine(subject.id);
                    if (subjectLines.length == 0 || subjectLines[0].isEmpty()) {
                        continue;
                    }
                    if (isUserCustom) {
                        line.stickStyle(subjectLines[0]);
                    } else {
                        line.stickColorStyle(subjectLines[0]);
                    }
                }
            }
        }

        for (let index = 0; index < nodeLength; index++) {
            const node = nodeData[index];
            if (node.value.type == MindElementType.SON_SUBJECT) {
                let isBackgorundColor = !Colors.isClear(node.value.backgroundColor);
                let isBorderColor = !Colors.isClear(node.value.borderColor);

                if (this.isCoverCustomStyleNode(node.value, isCoverCustomStyle)) {
                    let dataShape = node.value.mindElementShape;
                    this.setDataColorForStyleModel(node.value, styleData.sonSubjectData, styleData, isUserCustom);
                    if (node.value.layout == NodeLayoutType.LAYOUT_CIRCLE &&
                        dataShape == MindElementShapeType.Circular) {
                        node.value.mindElementShape = MindElementShapeType.Circular;
                    } 
                    node.value.checkSetTextColor(this.mindBGColor);
                    MindElementCalculation.set(node.value).caluleTextForData();
                }
                let lines = this.vm.getNodeLine(node.value.id);
                if (lines.length == 0 || lines[0].isEmpty()) {
                    continue;
                }
                if (sonNodeBackgroundAndLineSame.containsKey(node.value.id) && sonNodeBackgroundAndLineSame.get(node.value.id)) {
                    node.value.backgroundColor = lines[0].lineContent.color;
                    if (node.value.type == MindElementType.SON_SUBJECT && ! Colors.isClear(node.value.backgroundColor)) {
                        if (Colors.isDarkColor(this.mindBGColor)) {
                            if (Colors.isDarkColor(node.value.backgroundColor)) {
                                node.value.backgroundColorAlpha = 0.2;
                            } else {
                                node.value.backgroundColorAlpha = 0.15;
                            }
                        } else {
                            if (Colors.isDarkColor(node.value.backgroundColor)) {
                                node.value.backgroundColorAlpha = 0.15;
                            } else {
                                node.value.backgroundColorAlpha = 0.20;
                            }
                        }
                    }
                }
                node.value.checkSetTextColor(this.mindBGColor);
                if ((node.value.mindElementShape == MindElementShapeType.Underline ||
                    node.value.mindElementShape == MindElementShapeType.Two_Underline || 
                    (node.value.type == MindElementType.SON_SUBJECT && node.value.isDisplayBorder() && node.value.backgroundColor != node.value.borderColor)) &&
                        lines[0].lineContent != null) {
                    node.value.borderColor = lines[0].lineContent.color;
                }
                if (node.value.isCardContent() && node.value.borderColor == node.value.backgroundColor) {
                    node.value.borderColor = Colors.getColorIntValue(Colors.getNumberToRgbDark(node.value.backgroundColor, 0.5));
                }
            }
        }

        let MindElementDataLine = this.mainMindNodeUnit.generalizationLineMindElementDataDict.values()
        let MindElementDataLineLength = MindElementDataLine.length

        for (let index = 0; index < MindElementDataLineLength; index++) {
            let line = MindElementDataLine[index];
            if (isUserCustom) {
                line.stickStyle(styleData.generalizationLineData);
            } else {
                line.stickColorStyle(styleData.generalizationLineData);
            }
        }

        let MindElementDataNode = this.mainMindNodeUnit.generalizationMindElementDataDict.values()
        let MindElementDataNodeLength = MindElementDataNode.length

        for (let index = 0; index < MindElementDataNodeLength; index++) {
            let node = MindElementDataNode[index];
            if (this.isCoverCustomStyleNode(node.value, isCoverCustomStyle)) {
                if (isUserCustom) {
                    node.stickStyle(styleData.generalizationData);
                } else {
                    node.stickColorStyle(styleData.generalizationData);
                }
                MindElementCalculation.set(node.value).caluleTextForData();
            }
        }

        let MindElementDataMind = this.mainMindNodeUnit.encircleMindElementDataDict.values()
        let MindElementDataMindLength = MindElementDataMind.length
        for (let index = 0; index < MindElementDataMindLength; index++) {
            let mind = MindElementDataMind[index];
            if (isUserCustom) {
                mind.stickStyle(styleData.encircleData);
            } else {
                mind.stickColorStyle(styleData.encircleData);
            }            
        }

        let lineDataDict = this.nodeConnectLineDataDict.values()
        let lineDataDictLength = lineDataDict.length
        for (let index = 0; index < lineDataDictLength; index++) {
            let line = lineDataDict[index];
            if (isUserCustom) {
                line.stickStyle(styleData.nodeConnectLineData);
            } else {
                line.stickColorStyle(styleData.nodeConnectLineData);
            }
        }
    }

    setBubbleStyleToMap(data, styleData, isCoverCustomStyle) {
        if (this.mindType != MindType.BUBBLE_MAP) {
            return;
        }

        if (this.isCoverCustomStyleNode(this.mainMindNodeUnit.rootTreeNode.value, isCoverCustomStyle)) {
            this.setDataColorForStyleModel(this.mainMindNodeUnit.rootTreeNode.value, styleData.rootData, styleData, false);
        }
        this.mindBGColor = styleData.mindBGColor;
        this.vm.changMindBackgroundColor(styleData.mindBGColor, false);
        this.vm.delegate.changMindBackgroundColor(styleData.mindBGColor);
        let subjectLineData = this.getStyleLineColors(styleData);
        let subjectDatas = this.getStyleSubjectDatas(styleData);

        let count = this.mainMindNodeUnit.rootTreeNode.children.length;
        if (count > 0 && subjectDatas.length > 0) {
            for (let index = 0; index < count; index++) {
                let subject = this.mainMindNodeUnit.rootTreeNode.children[index];
                if (this.isCoverCustomStyleNode(subject.value, isCoverCustomStyle)) {
                    if (subjectDatas.length > index) {
                        this.setDataColorForStyleModel(subject.value, subjectDatas[index], styleData, false);
                    } else {
                        this.setDataColorForStyleModel(subject.value, subjectDatas[index % subjectDatas.length], styleData, false);
                    }
                    MindElementCalculation.set(subject.value).caluleTextForData();
                }
                let lines = this.vm.getNodeLine(subject.value.id);
                if (lines.length > 0 && !lines[0].isEmpty()) {
                    if (subjectLineData.length > index) {
                        lines[0].stickColorStyle(subjectLineData[index]);
                    } else {
                        lines[0].stickColorStyle(subjectLineData[index % subjectLineData.length]);
                    }
                }
            }
        }

        let lineData = this.mainMindNodeUnit.lineMindElementDataDict.values()
        let lineLength = lineData.length
        for (let index = 0; index < lineLength; index++) {
            const line = lineData[index];
            if (line.lineContent != null && line.lineContent.targetId > -1) {
                let node = this.vm.getNodeById(line.lineContent.targetId);
                if (!node.isEmpty() && node.value.type == MindElementType.SON_SUBJECT) {
                    let subject = this.findSubjectNode(node.value);
                    if (subject.isEmpty()) {
                        continue;
                    }
                    let subjectLines = this.vm.getNodeLine(subject.id);
                    if (subjectLines.length == 0 || subjectLines[0].isEmpty()) {
                        continue;
                    }
                    line.stickColorStyle(subjectLines[0]);
                }
            }
        }

        let nodeData = this.mainMindNodeUnit.mainMindElementDataDict.values()
        let nodeLength = nodeData.length

        for (let index = 0; index < nodeLength; index++) {
            const node = nodeData[index];
            if (node.value.type == MindElementType.SON_SUBJECT &&
                this.isCoverCustomStyleNode(node.value, isCoverCustomStyle)) {
                this.setDataColorForStyleModel(node.value, styleData.sonSubjectData, styleData, false);
            }
        }

        let MindElementDataLine = this.mainMindNodeUnit.generalizationLineMindElementDataDict.values()
        let MindElementDataLineLength = MindElementDataLine.length

        for (let index = 0; index < MindElementDataLineLength; index++) {
            let line = MindElementDataLine[index];
            line.stickStyle(styleData.generalizationLineData);
        }

        let MindElementDataNode = this.mainMindNodeUnit.generalizationMindElementDataDict.values()
        let MindElementDataNodeLength = MindElementDataNode.length
        for (let index = 0; index < MindElementDataNodeLength; index++) {
            let node = MindElementDataNode[index];
            if (this.isCoverCustomStyleNode(node.value, isCoverCustomStyle)) {
                this.setDataColorForStyleModel(node.value, styleData.generalizationData, styleData, false);
                MindElementCalculation.set(node.value).caluleTextForData();
            }
        }

        let MindElementDataMind = this.mainMindNodeUnit.encircleMindElementDataDict.values()
        let MindElementDataMindLength = MindElementDataMind.length
        for (let index = 0; index < MindElementDataMindLength; index++) {
            let mind = MindElementDataMind[index];
            mind.stickStyle(styleData.encircleData);
        }

        let lineDataDict = this.nodeConnectLineDataDict.values()
        let lineDataDictLength = lineDataDict.length
        for (let index = 0; index < lineDataDictLength; index++) {
            let line = lineDataDict[index];
            line.stickStyle(styleData.nodeConnectLineData);
        }
    }

    getCustomStylePreviewImage() {
        return this.getCustomStylePreviewImage1(new Size(new UiUtil().dip2px(350), new UiUtil().dip2px(350)));
    }

    getCustomStylePreviewImage1(sizeLimit) {

        let rect = new CGRect();

        if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.FLOW_CHART || this.mindType == MindType.BUBBLE_MAP) {
            let calculSaveImageRect = new CalculSaveImageRect(this.mainMindNodeUnit.mainMindElementDataDict,
                this.mainMindNodeUnit.generalizationMindElementDataDict, 
                this.mainMindNodeUnit.encircleMindElementDataDict,
                this.mainMindNodeUnit.rootTreeNode.value, 
                this.mainMindNodeUnit.lineMindElementDataDict, 
                this.mainMindNodeUnit.explainMindElementDataDict);
            rect = calculSaveImageRect.get(new UiUtil().dip2px(5), new UiUtil().dip2px(5));
            if (rect.width() > rect.height()) {
                rect.y = rect.y - (rect.width() - rect.height()) / 2;
                rect.height(rect.width());
            } else {
                rect.x = rect.x - (rect.height() - rect.width()) / 2;
                rect.width(rect.height());
            }
        } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            let calculSaveImageRect = new CalculSaveImageRect(
                this.doubleBubbleMindNodeUnit.mainMindElementDataDict,
                new HashMap(), new HashMap(), this.doubleBubbleMindNodeUnit.rootTreeNode.value,new HashMap(), new HashMap());
            rect = calculSaveImageRect.get(new UiUtil().dip2px(5), new UiUtil().dip2px(5));
            if (rect.width() > rect.height()) {
                rect.y = rect.y - (rect.width() - rect.height()) / 2;
                rect.height(rect.width());
            } else {
                rect.x = rect.x - (rect.height() - rect.width()) / 2;
                rect.width(rect.height());
            }
        } else if (this.mindType == MindType.TIME_MAP) {
            rect = this.timeMindTypeNodeUnit.getRect();
        }

        if (this.mindDisplayType == MindDisplayType.MindMap) {
            if (rect.width() > sizeLimit.getWidth()) {
                rect.x = rect.x + (rect.width() - sizeLimit.getWidth()) / 2;
                rect.width(sizeLimit.getWidth());
            }
            if (rect.height() > sizeLimit.getHeight()) {
                rect.y = rect.y + (rect.height() - sizeLimit.getHeight()) / 2;
                rect.height(sizeLimit.getHeight());
            }
            let scale = rect.width() / rect.height();
            if (scale < Config.Style_Preview_Image_W_W_Scale) {
                let targetW = (rect.height() * Config.Style_Preview_Image_W_W_Scale);
                rect.x = rect.x + (rect.width() - targetW) / 2;
                rect.width(targetW);
            } else {
                let targetH = (rect.width() / Config.Style_Preview_Image_W_W_Scale);
                rect.y = rect.y + (rect.height() - targetH) / 2;
                rect.height(targetH);
            }
        }
        return rect;
    }

    isCoverCustomStyleNode(data, isCoverCustomStyle) {
        if (isCoverCustomStyle) {
            return true;
        }
        return !this.vm.isCoverCustomStyle(data)
    }
}

export default MindMapStyleHandle
