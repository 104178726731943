import { postGroupUploadGmap, postGroupViewGmap } from "../../../common/netWork/group_api";
import Util from "../../../utils/Util";
import MindType from "../../datatype/MindType";
import GroupMindMapData from "./GroupMindMapData";

class GroupMindSyncAndMerge {
    constructor() {
        this.delegate = null;
        this.lastLocalMapData = null;
        this.currentLocalMapData = null;
        this.serverMapData = null;
     
        this.mindType = MindType.LINE_MAP;
        this.gmapId = "";
        this.sheetId = "";
        this.title = "";
        this.subtitle = "";
        this.imageCover = "";
        this.mindGlobalAudio = "";
        this.isMergeing = false;
        this.stopMerge = false;
    }

    get() {
        return this;
    }

    setBaseInfo(mindType, gmapId, sheetId, title, subtitle, imageCover, mindGlobalAudio) {
        this.mindType = mindType;
        this.gmapId = gmapId;
        this.sheetId = sheetId;
        this.title = title;
        this.subtitle = subtitle;
        if (imageCover != null) {
            this.imageCover = imageCover;
        }
        if (mindGlobalAudio != null) {
            this.mindGlobalAudio = mindGlobalAudio;
        }
        return this;
    }

    clearData(isClearLastData) {
        this.gmapId = "";
        this.sheetId = "";
        this.title = "";
        this.subtitle = "";
        if (isClearLastData) {
            this.lastLocalMapData = null;
        }
        this.currentLocalMapData = null;
        this.serverMapData = null;
        this.isMergeing = false;
        this.delegate = null;
        this.time = 0;
    }

    setLastData(data) {
        this.lastLocalMapData = data;
    }

    stopCurrentMerge() {
        this.stopMerge = true;
    }

    merge(callback, currentLocalMapData) {
        // console.log("merge-time-", Util.getCurrentTime() - this.time);
        this.time = Util.getCurrentTime()
        if (this.isMergeing) {
            return;
        }
        this.stopMerge = false;
        this.delegate = callback;
        this.currentLocalMapData = currentLocalMapData;
        this.isMergeing = true;
        this.loadServerData();
    }
    
    setCurrentLocalMapDataContent() {
        if (this.delegate != null && this.currentLocalMapData != null) {
            let content = this.delegate.getCurrentMindContent()
            if (content != null && content.length > 50) {
                this.currentLocalMapData.setContent(content)
            }
        }
        return null
    }

    loadServerData() {
        postGroupViewGmap({ gmapId: this.gmapId, sheetId: this.sheetId }, (res) => {
            if (res.content != null && res.content.length > 0) {
                this.parseServerData(res.content);
            } else {
                if (this.delegate != null && this.currentLocalMapData != null) {
                    this.setCurrentLocalMapDataContent()
                    this.delegate.groupMapMergeCallback(true, 2, null, this.gmapId, this.sheetId, this.currentLocalMapData.generalSave(), this.currentLocalMapData);
                }
                this.isMergeing = false;
            }
        }, (e) => {
            if (this.delegate != null) {
                this.delegate.groupMapMergeCallback(true, 1, null, this.gmapId, this.sheetId, this.currentLocalMapData.generalSave(), this.currentLocalMapData);
            }
            this.isMergeing = false;
        })
    }
 
    parseServerData(data) {
        if (this.stopMerge) {
            this.isMergeing = false;
            return;
        }
        if (data != null && data.length > 0) {
            this.serverMapData = new GroupMindMapData(this.mindType, this.gmapId, this.sheetId);
            this.serverMapData.setContent(data);
            this.startMerge();
        } else {
            if (this.delegate != null) {
                this.delegate.groupMapMergeCallback(false, 1);
            }
            this.isMergeing = false;
        }
    }
 
    startMerge() {
        if (this.serverMapData == null || this.currentLocalMapData == null || this.lastLocalMapData == null) {
            if (this.delegate != null) {
                this.delegate.groupMapMergeCallback(false, 1);
            }
            this.isMergeing = false;
            return;
        }
        this.setCurrentLocalMapDataContent();
        if (!this.currentLocalMapData.isAvailable()) {
            if (this.delegate != null) {
                this.delegate.groupMapMergeCallback(false, 1);
            }
            this.isMergeing = false;
            return;
        }
        this.currentLocalMapData.merge(this.serverMapData, this.lastLocalMapData);
        if (this.delegate != null && !this.stopMerge) {
            this.delegate.groupMapMergeCallback(true, 0, null, this.gmapId, this.sheetId, this.currentLocalMapData.generalSave(), this.currentLocalMapData);
        }
        this.isMergeing = false;
    }
 
    save(mindNodeJson, callback) {
        let modifyTime = Math.round(new Date().getTime() / 1000)
        let canvansData = { gmapId: this.gmapId, 
                            sheetId: this.sheetId, 
                            name:this.title, 
                            content: mindNodeJson, 
                            cover: this.imageCover, 
                            subtitle: this.subtitle, 
                            audio: this.mindGlobalAudio, modifyTime }
        postGroupUploadGmap(canvansData, res => {
            if (callback != null) {
                callback.groupMapSaveCallback(true, null);
            }
            this.isMergeing = false;
            let lastLocalMapData = new GroupMindMapData(this.mindType, this.gmapId, this.sheetId);
            lastLocalMapData.setContent(mindNodeJson);
            this.setLastData(lastLocalMapData);
        }, error => {
            callback.groupMapSaveCallback(false, error);
            this.isMergeing = false;
        })
    }
}

let groupMindSyncAndMerge = new GroupMindSyncAndMerge();
export default groupMindSyncAndMerge
