<template>
  <div
    id="free-node-menu"
    ref="freeNodeMenu"
    v-show="isShowFreeNodeMenu"
    v-clickoutside="outsideCloseIconCompoenntAccout"
    :style="{
      left: newMenuX + 'px',
      top: newMenuY - domsCenterNumber + 'px',
      border: '1px solid var(--rightClickMenuBorderColor)',
    }"

  >
    <div
      class="free-node"
      v-for="(item, index) in menuList"
      :key="index"
      @click="clickFreeNodeMenuOption(item.type, false)"
    >
      <span>{{ item.name }}</span>
      <img
        v-if="item.needMember && !isMember()"
        src="../../../assets/img/user/icon/vip.svg"
        class="need-member"
        alt="vip"
      />
    </div>
  </div>
</template>

<script>
import getString from "../../../common/lang/language";
import strings from "../../../common/lang/strings";
import Colors from "../../../utils/Colors";
import MindElementType from "../../../viewmodel/datatype/MindElementType";
import MindCopyManager from "../../../core/core/common/MindCopyManager";
import { postBaseGlobalConfig } from "../../../common/netWork/networkcache";

export default {
  data() {
    return {
      menuList: [],
      domsCenterNumber: 40, //鼠标距菜单位置
    };
  },
  props: ["newMenuY", "newMenuX", "isShowFreeNodeMenu"],
  created() {
    this.setMenuList();
  },
  mounted() {
    let doms = document.getElementsByClassName("free-node");
    this.domsCenterNumber = (doms.length / 2) * 40;
    this.setDardarkMode();
  },
  methods: {
    outsideCloseIconCompoenntAccout() {
      this.$emit("clickFreeNodeMenuOption", "colse", false);
    },
    setMenuList() {
      this.menuList = [
        {
          name: getString(strings.Mind_Edit_Free_Node),
          type: MindElementType.FREE_NODE,
        },
        {
          name: getString(strings.Mind_Edit_Insert_Summary_Graph),
          type: "Add_Echart",
        },
        {
          name: getString(strings.Mind_Usinghelp_9), //插入图片
          type: "Insert_Picture",
          needMember: false,
        },
      ];
      this.insertPicturesIsNeedMembers();
    },
    clickFreeNodeMenuOption(type, accout) {
      this.$emit("clickFreeNodeMenuOption", type, accout);
    },
    //插入图片是否需要会员
    insertPicturesIsNeedMembers() {
      postBaseGlobalConfig({}, (res) => {
        if (res) {
          let obj = this.menuList.find(
            (sitem) => sitem.type == "Insert_Picture"
          );
          if (obj) {
            obj.needMember = !res.uploadImageFree;
          }
          // this.menuList[2].needMember = !res.uploadImageFree
        }
      });
    },
    setDardarkMode() {
      // 设置深色模式
      this.$nextTick(() => {
        const white = "#fff";
        let freeNodeMenuDom = this.$refs.freeNodeMenu;
        let dardarkMode = this.$store.state.mapEditIsDardarkMode;
        if (freeNodeMenuDom) {
          if (!dardarkMode.darkMode) {
            freeNodeMenuDom.style.color = "#fff";//dardarkMode.fontColor;
            freeNodeMenuDom.style.background = "#3E3E3E"//dardarkMode.bgColor;
            document.documentElement.style.setProperty(
              "--rightClickMenuColor",
              white
            );
            document.documentElement.style.setProperty(
              "--rightClickMenuBg",
              "rgba(255, 255, 255, 0.3)"
            );
          } else {
            freeNodeMenuDom.style.color = "#000";
            freeNodeMenuDom.style.background = white;
             document.documentElement.style.setProperty(
              "--rightClickMenuColor",
              "#000"
            );
            document.documentElement.style.setProperty(
              "--rightClickMenuBg",
              "#e0e0e0"
            );
          }
        }
      });
    },
    // checkAddIconColor(btnColor) {
    //   let isDarkColor = Colors.isLightColor(btnColor);
    //   let color = "";
    //   let boxShadow = ""
    //   let Textcolor = "";

    //   if (isDarkColor) {
    //     color = "rgba(255,255,255,0.5)";
    //     boxShadow = "0px 3px 6px rgba(255,255,255,0.5)"
    //     Textcolor = "#666"
    //   } else {
    //     Textcolor = "#fff"
    //     color = "rgba(0,0,0)";
    //     boxShadow = "0px 3px 6px rgba(0,0,0,0.5)"
    //   }

    //   let bgColor = document.getElementById("free-node-menu");
    //   let fontColor = document.getElementsByClassName("free-node");
    //   bgColor.style.background = color;
    //   bgColor.style.boxShadow = boxShadow;
    //   fontColor[0].style.color = Textcolor;
    // },
  },
  watch: {
    // "$store.state.saveImageBgcolor"(newBg) {
    //   this.checkAddIconColor(newBg);
    // },
    isShowFreeNodeMenu(newVal) {
      if (newVal) {
        this.setMenuList();
        let isHasCopyNodeFlag = MindCopyManager.isHasCopyNode();
        if (isHasCopyNodeFlag) {
          //判断是否有复制的节点
          this.menuList.push({
            name: getString(strings.Mind_Edit_Node_Menu_Paste),
            type: "Menu_Paste",
          });
        } else {
          let tath = this
          tath.menuList.push({
            name: getString(strings.Mind_Edit_Node_Menu_Paste),
            type: "Menu_Paste",
          });

          // navigator.clipboard.read().then((data) => {
          //   for (let i = 0; i < data.length; i++) {
          //     if (data[i].types.includes("image/png")) {
          //       tath.menuList.push({
          //           name: getString(strings.Mind_Edit_Node_Menu_Paste),
          //           type: "Menu_Paste",
          //         });
          //     }
          //   }
          // });
                
            // console.log("clipboardItems navigator.clipboard.read() ");
            // navigator.clipboard.read().then( function(clipboardItems) {
            //   if (clipboardItems != null && clipboardItems.length > 0) {
            //     console.log("clipboardItems ", clipboardItems);

            //     for (const clipboardItem of clipboardItems) {
            //       for (const type of clipboardItem.types) {
            //         const blob = clipboardItem.getType(type);
            //         console.log(URL.createObjectURL(blob));
            //       }
            //     }
            //   }
            // });
        }
      }
    },
    "$store.state.mapEditIsDardarkMode": {
      //导图编辑页面是否为深色模式
      handler(newVal, oldVal) {
        this.setDardarkMode();
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
#free-node-menu {
  max-width: 160px;
  width: 100%;
  min-height: 40px;
  position: fixed;
  z-index: 1200;
  border-radius: 8px;
  color: #000;
  background-color: #ffffff;
  box-shadow: 0px 0px 0px 1px var(--rightClickMenuInsetBorderColor) inset;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  .free-node {
    width: 160px;
    min-height: 40px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    cursor: pointer;
    border-radius: 6px;
    font-size: 16px;
    position: relative;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    .need-member {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
    }
  }
  .free-node:hover {
    background-color: var(--rightClickMenuBgHover);
    // color: var(--rightClickMenuColor);
    // background-color: #212121;
  }
}
</style>