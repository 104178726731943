
import HashMap from "../../../viewmodel/core/base/HashMap"
import MindElementCalculation from "../../../core/core/calcule/elementCalculation/MindElementCalculation"
import UiUtil from "../../../utils/UiUtil"
import CGPoint from "../../../viewmodel/core/base/basedata/CGPoint";
import DeviceType from "../../../viewmodel/datatype/DeviceType";
import MindElementType from "../../../viewmodel/datatype/MindElementType";
import MindElementData from "../../../viewmodel/mindelementdata/MindElementData";
import IconElementContent from "../../../viewmodel/mindelementdata/mindcontent/IconElementContent"
import Rect from "../../../viewmodel/core/base/basedata/Rect"
import Point from "../../../viewmodel/core/base/Point";
import Colors from "../../../utils/Colors";
import NodeConnectLineControlPoint from "./NodeConnectLineControlPoint"
import MindOriginDataRelativeRootInfo from "./MindOriginDataRelativeRootInfo";
import NodeLayoutType from "../../../viewmodel/datatype/NodeLayoutType";
import MindElementShapeType from "../../../viewmodel/datatype/MindElementShapeType";
import CGRect from "../../../viewmodel/core/base/basedata/Rect";
import NodesRectCalculation from "../calcule/NodesRectCalculation";
import LineMindTypeNode from "../../../viewmodel/mindelementdata/LineMindTypeNode";
import MindElementAlignmentType from "../../../viewmodel/datatype/MindElementAlignmentType";
import { getMachineId } from "../../../common/netWork/base";
import LineLayout from "../../../viewmodel/datatype/LineLayout";
import LinePointShape from "../../../viewmodel/datatype/LinePointShape";
import ConnectLineType from "../../../viewmodel/datatype/ConnectLineType";
import Config from "../calcule/Config";

/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/25
 * Copyright(c) 2020 mindyushu.com
 */

class MindFormatCheck {
    constructor(deviceType, deviceDensity, rootData, nodeConnectLinet, dataVersion, freeNodes, uuid, settingData, originalDataMap) {
        this.originContentRect = new CGRect(100000, 100000, -50000, -50000);
        this.nodeConnectLineControlPointMap = new HashMap();
        this.mindOriginDataRelativeRootInfo = new HashMap();
        this.deviceType = deviceType;
        this.deviceDensity = deviceDensity;
        this.nodeConnectLinet = nodeConnectLinet; //节点连接线
        this.isOriginCutValue = true;
        this.rootData = new LineMindTypeNode();
        this.rootOriginData = new MindElementData()
        this.centre = new CGPoint(-1, -1);
        this.leftTopPoint = new CGPoint(-1, -1);
        this.rightTopPoint = new CGPoint(-1, -1);
        this.leftBottomPoint = new CGPoint(-1, -1);
        this.rightBottomPoint = new CGPoint(-1, -1);
        this.rootLine = [];
        this.dataVersion = dataVersion;
        this.freeNodes = freeNodes;
        this.rootOriginRect = new CGRect();
        this.uuid = uuid;
        this.settingData = settingData
        this.originalDataMap = originalDataMap;
        Config.setTightModeEdgeInsets(this.settingData.tightMode);
        this.selfUuid = getMachineId();
        this.removeRepeat(rootData);
        this.resetConnnectLineData();
        this.setCentre(rootData);
        this.checkSettingData();
    }

    setIsOriginCutValue(isOriginCutValue) {
        this.isOriginCutValue = isOriginCutValue;
    }

    removeRepeat(node) {
        if (node.children.isEmpty()) {
            return;
        }
        let children = new Array();
        let map = new HashMap();
        node.children.forEach(child => {
            if (!map.containsKey(child.value.id)) {
                children.push(child);
                map.put(child.value.id, child);
            }
        })
        node.children = children;
    }

    setRootLine(lines) {
        this.rootLine = lines;
        this.checkLineThicken();
    }

    setCentre(rootData) {
        if (rootData.isEmpty()) {
            return;
        }
        this.rootData = rootData;
        this.rootOriginData = rootData.value.copy()
        this.rootOriginRect = new NodesRectCalculation().calcule(rootData);
        this.centre = new CGPoint(this.rootOriginRect.x + this.rootOriginRect.width() / 2, this.rootOriginRect.y + this.rootOriginRect.height() / 2);
        this.leftTopPoint = new CGPoint(this.rootOriginRect.x, this.rootOriginRect.y);
        this.rightTopPoint = new CGPoint(this.rootOriginRect.x + this.rootOriginRect.width(), this.rootOriginRect.y);
        this.leftBottomPoint = new CGPoint(this.rootOriginRect.x, this.rootOriginRect.y + this.rootOriginRect.height());
        this.rightBottomPoint = new CGPoint(this.rootOriginRect.x + this.rootOriginRect.width(), this.rootOriginRect.y + this.rootOriginRect.height());

        for (let index = 0; index < this.freeNodes.length; index++) {
            let cell = this.freeNodes[index];
            if (cell.rootNode != null && !cell.rootNode.isEmpty()) {
                this.calculOriginFreeDataRelativeRootInfo(cell.rootNode);
            }
        }
        if (this.rootData != null && !this.rootData.children.isEmpty()) {
            for (let index = 0; index < this.rootData.children.length; index++) {
                let cell = this.rootData.children[index];
                if (!cell.isEmpty() && cell.value.isFreeLayoutNode) {
                    this.calculOriginFreeDataRelativeRootInfo(cell);
                }
            }
        }
        if (this.nodeConnectLinet != null) {
            for (let index = 0; index < this.nodeConnectLinet.length; index++) {
                let cell = this.nodeConnectLinet[index]
                if (cell != null && !cell.isEmpty()) {
                    this.calculOriginFreeDataRelativeRootInfo(new LineMindTypeNode(cell));
                }
            }
        }
    }

    checkData(data) {
        this.resetWidth(data);
        this.resetSonSubject(data);
        this.resetAlignmentType(data);
        this.checkEncircle(data);
    }

    checkEncircle(data) {
        if (this.dataVersion > 7 || data.type != MindElementType.BAOWEI_VIEW) {
            return;
        }
        data.backgroundColorAlpha = 0.19
    }

    checkSettingData() {
        if ((this.uuid != null && this.uuid != "" && this.uuid == this.selfUuid) && 
            this.deviceDensity == new UiUtil().dip2px(1)) {
                // console.log("checkSettingData ");
            return;
        }
        // console.log("checkSettingData ", this.settingData.subjectSpcaeHorizontal);
        if (this.settingData.subjectSpcaeHorizontal > 0) {
            this.settingData.subjectSpcaeHorizontal = this.getDeviceDensityForIconAndImageSize(this.settingData.subjectSpcaeHorizontal);
        }
        if (this.settingData.subjectSpcaeVertical > 0) {
            this.settingData.subjectSpcaeVertical = this.getDeviceDensityForIconAndImageSize(this.settingData.subjectSpcaeVertical);
        }
        if (this.settingData.sonSubjectSpcaeHorizontal > 0) {
            this.settingData.sonSubjectSpcaeHorizontal = this.getDeviceDensityForIconAndImageSize(this.settingData.sonSubjectSpcaeHorizontal);
        }
        if (this.settingData.sonSubjectSpcaeVertical > 0) {
            this.settingData.sonSubjectSpcaeVertical = this.getDeviceDensityForIconAndImageSize(this.settingData.sonSubjectSpcaeVertical);
        }
        // console.log("checkSettingData end ", this.settingData.subjectSpcaeHorizontal);
    }

    checkLineThicken() {
        if (this.dataVersion >= 6) {
            return;
        }
        let rootLineLength = this.rootLine.length;
        for(let index = 0; index < rootLineLength; index++){
            let line = this.rootLine[index];
            if (line.lineContent == null) {
                continue;
            }
            if (line.type == MindElementType.LINE && line.lineContent.lineThicken) {
                this.settingData.lineThicken = true;
                break;
            }
        }
    }

    resetSonSubject(data) {
        if (data == null || data.type  != MindElementType.SON_SUBJECT) {
            return;
        }
        if (this.dataVersion == 1 &&
                data.layout != NodeLayoutType.LAYOUT_BOTTOM &&
                data.layout != NodeLayoutType.LAYOUT_TOP &&
                data.mindElementShape == MindElementShapeType.Corner &&
                (data.borderWidth == 0 || Colors.isClear(data.borderColor))) {
            let lineData = this.getLineData(data);
            if (lineData.isEmpty() || lineData.lineContent == null) {
                return;
            }
            data.borderColor = lineData.lineContent.color;
            data.borderWidth = lineData.lineContent.lineWidth;
            data.mindElementShape = MindElementShapeType.Underline;
        }
    }

    resetConnnectLineData() {
        if (this.dataVersion < 5) {
            let nodeConnectLinetCount = this.nodeConnectLinet.length;
            for (let index = 0; index < nodeConnectLinetCount; index++) {
                let line = this.nodeConnectLinet[index];
                if (line.lineContent == null) {
                    continue;
                }
                switch (line.lineContent.connectLineType) {
                    case ConnectLineType.CURVE_LINE:
                    case ConnectLineType.RIGHT_ANGLE_LINE:
                    case ConnectLineType.STRAIGHT_ARROW_LINE:
                        line.lineContent.startShape = LinePointShape.NORMAL;
                        line.lineContent.endShape = LinePointShape.ARROW;
                        break;
                    case ConnectLineType.STRAIGHT_CIRCULAR_LINE:
                        line.lineContent.startShape = LinePointShape.CIRCULAR_RADIATION;
                        line.lineContent.endShape = LinePointShape.CIRCULAR;
                        break;
                    default:
                }
            }
        }
    }

    resetAlignmentType(data) {
        if (this.dataVersion < 3 && 
            data.alignmentType == MindElementAlignmentType.MIDDLE &&
            data.customWidth == 0) {
            data.alignmentType = MindElementAlignmentType.LEFT;
        }
    }

    resetWidth(data) {
        if ((this.uuid != null && this.uuid != "" && this.uuid == this.selfUuid) && 
            this.deviceDensity == new UiUtil().dip2px(1) && this.dataVersion >= 7) {
            return;
        }
        this.changeDataValueSizeToSelf(data);
    }

    changeDataValueSizeToSelf(data) {
        if (this.isOriginCutValue) {
            this.calculOriginConnectLineDataRelativeInfo(data);
        } else {
            if (this.originalDataMap != null && !this.originalDataMap.containsKey(data.id)) {
                this.originalDataMap.put(data.id, data.copy());
            }
            if (data.lineContent != null) {
                data.lineContent.lineWidth = this.getDeviceDensityForLine(data.lineContent.lineWidth);
                data.lineContent.padding = this.getDeviceDensityForIconAndImageSize(data.lineContent.padding)
                if (data.lineContent.textContent != null) {
                    data.lineContent.textContent.textFontSize = data.lineContent.textContent.textFontSize * this.getDeviceDensityForFontSize();
                }
                if (data.type == MindElementType.NODE_CONNECT_LINE) {
                    data.width = this.getDeviceDensityForIconAndImageSize(data.width);
                    data.height = this.getDeviceDensityForIconAndImageSize(data.height);
                }
            }
            if (data.timeLineContent != null) {
                data.timeLineContent.lineWidth = this.getDeviceDensityForLine(data.timeLineContent.lineWidth);
            }
            if (data.textContent != null) {
                data.textContent.textFontSize = data.textContent.textFontSize * this.getDeviceDensityForFontSize();
                if (data.textContent.styleCells != null && data.textContent.styleCells.length > 0) {
                    let count = data.textContent.styleCells.length;
                    if (count > 0) {
                        for (let index = 0; index < count; index++) {
                            let cell = data.textContent.styleCells[index]
                            cell.textFontSize = cell.textFontSize * this.getDeviceDensityForFontSize();
                        }
                    }
                }
            }
            if (data.generalizationContent != null) {
                data.generalizationContent.textFontSize = data.generalizationContent.textFontSize * this.getDeviceDensityForFontSize();

                if (data.generalizationContent.styleCells != null && data.generalizationContent.styleCells.length > 0) {
                    let count = data.generalizationContent.styleCells.length;
                    if (count > 0) {
                        for (let index = 0; index < count; index++) {
                            let cell = data.generalizationContent.styleCells[index]
                            cell.textFontSize = cell.textFontSize * this.getDeviceDensityForFontSize();
                        }
                    }
                }
            }
            if (data.imageContent != null) {
                data.imageContent.width = this.getDeviceDensityForIconAndImageSize(data.imageContent.width);
                data.imageContent.height = this.getDeviceDensityForIconAndImageSize(data.imageContent.height);
            }
            if (data.statisticsContent != null) {
                data.statisticsContent.radius = this.getDeviceDensityForIconAndImageSize(data.statisticsContent.radius);
            }
            data.customWidth = this.getDeviceDensityForIconAndImageSize(data.customWidth);
            data.customHeight = this.getDeviceDensityForIconAndImageSize(data.customHeight);
            if (data.type == MindElementType.BAOWEI_VIEW) {
                data.width = this.getDeviceDensityForIconAndImageSize(data.width);
                data.height = this.getDeviceDensityForIconAndImageSize(data.height);
            }
            data.borderWidth = this.getDeviceDensityForLine(data.borderWidth);
            if (data.iconElementContents != null && !data.iconElementContents.isEmpty()) {
                data.iconElementContents.forEach(cell => {
                    cell.width = this.getDeviceDensityForIconAndImageSize(cell.width);
                    cell.height = this.getDeviceDensityForIconAndImageSize(cell.height);
                });
            }
            MindElementCalculation.set(data).caluleTextForData().calcule();
            if (this.originContentRect.x > data.x) {
                this.originContentRect.x = data.x;
            }
            if (this.originContentRect.y > data.y) {
                this.originContentRect.y = data.y;
            }
            if (this.originContentRect.x + this.originContentRect.width() < data.x + data.width) {
                this.originContentRect.width(data.x + data.width - this.originContentRect.x);
            }
            if (this.originContentRect.y + this.originContentRect.height() < data.y + data.height) {
                this.originContentRect.height(data.y + data.height - this.originContentRect.y);
            }
            if (data.isHandwritingContent()) {
                let scaleX = (this.rightTopPoint.x - this.leftTopPoint.x) / this.rootData.value.width;
                let scaleY = (this.rightBottomPoint.y - this.leftTopPoint.y) / this.rootData.value.height;
                if (this.rootOriginData.textContent != null) {
                    scaleX = this.rootOriginData.textContent.width / this.rootData.value.textContent.width;
                    scaleY = this.rootOriginData.textContent.height / this.rootData.value.textContent.height;
                }
                let scale = Math.min(scaleX, scaleY);
                data.handwritingContent.startPointX = data.handwritingContent.startPointX / scale;
                data.handwritingContent.startPointY = data.handwritingContent.startPointY / scale;

                data.handwritingContent.endPointX = data.handwritingContent.endPointX / scale;
                data.handwritingContent.endPointY = data.handwritingContent.endPointY / scale;
                
                for (let index = 0; index < data.handwritingContent.points.length; index++) {
                    let cell = data.handwritingContent.points[index]
                    cell.x = cell.x / scale
                    cell.y = cell.y / scale
                }
            }
        }
    }

    getDeviceDensityForIconAndImageSize(size) {
        if (this.deviceType == DeviceType.WEB ||
            this.deviceType == DeviceType.WINDOWS ||
            this.deviceType == DeviceType.MAC) {
            return Math.ceil(size);
        } else if (this.deviceType == DeviceType.ANDROID || this.deviceType == DeviceType.ANDROID_PAD) {
            return Math.ceil(size / this.deviceDensity);
        } else if (this.deviceType == DeviceType.IPHONE || this.deviceType == DeviceType.IPAD) {
            return Math.ceil(size);
        } else {
            return Math.ceil(size);
        }
    }

    getDeviceDensityForFontSize() {
        if (this.deviceDensity >= 2) {
            //return this.deviceDensity / 2
        }
        return 1;//this.deviceDensity;
    }

    getDeviceDensityForLine(lineWidth) {
        return lineWidth;//this.deviceDensity;
    }

    getDeviceDensity() {
        return this.deviceDensity;
    }

    formatValue(value) {
        if (value == 0) {
            return 0.0;
        } else if (value > 0 && value <= 0.5) {
            return 0.5;
        } else if (value > 0.5 && value <= 1.0) {
            return 1.0;
        }
        //let decimalFormat = new DecimalFormat("#.#");
        //保留2位小数，.后面的#代表小数点后面的位数,保留3位小数就是#.###
        let valueStr = this.roundFun(value, "#.#");
        let formatValue = parseFloat(valueStr);
        if (formatValue > 0 && formatValue < 0.5) {
            return 0.5;
        }
        let decimalPlacesIndex = ("" + valueStr).lastIndexOf(".");
        if (decimalPlacesIndex > -1) {
            let integerPlaces = valueStr.substring(0, decimalPlacesIndex);
            let decimalPlaces = valueStr.substring(decimalPlacesIndex + 1);
            let integer = parseFloat(integerPlaces);
            let decimal = parseFloat(decimalPlaces);
            if (decimal == 0) {
                return integer;
            } else if (decimal > 0 && decimal <= 5) {
                return integer + 0.5;
            } else if (decimal > 5 && decimal <= 10.0) {
                return integer + 1.0;
            }
        }
        return formatValue;
    }

    roundFun(value, n) {
        if (n == "#.#") { 
            return JSON.stringify(Math.round(value * Math.pow(10, 1)) / Math.pow(10, 1))
        } else if (n == "#.##") {
            return JSON.stringify(Math.round(value * Math.pow(10, 2)) / Math.pow(10, 2))
        } else if (n == "#.###") {
            return JSON.stringify(Math.round(value * Math.pow(10, 3)) / Math.pow(10, 3))
        }
    }

    calculOriginFreeDataRelativeRootInfo(node) {
        if (this.isFreeData(node.value) || node.value.isFreeLayoutNode || node.value.type == MindElementType.NODE_CONNECT_LINE) {
            let rect = new NodesRectCalculation().calcule(node);
            let dataCentre = new CGPoint(rect.x + rect.width() / 2, rect.y + rect.height() / 2);
            let dataLeftTopPoint = new CGPoint(rect.x, rect.y);
            let dataRightBottomPoint = new CGPoint(rect.x + rect.width(), rect.y + rect.height());

            let left = (this.leftTopPoint.x - dataLeftTopPoint.x);
            let top = (this.leftTopPoint.y - dataLeftTopPoint.y);
            let right = (this.rightBottomPoint.x - dataRightBottomPoint.x);
            let bottom = (this.rightBottomPoint.y - dataRightBottomPoint.y);
            let centreX = (this.centre.x - dataCentre.x);
            let centreY = (this.centre.y - dataCentre.y);
            let scaleX = (rect.x - this.rootOriginRect.x) / this.rootOriginRect.width();
            let scaleY =  (rect.y - this.rootOriginRect.y) /  this.rootOriginRect.height();
            let info = new MindOriginDataRelativeRootInfo(node.value.copy(), this.rootData.value.copy(), this.rootOriginRect, left, top, right, bottom, centreX, centreY, scaleX, scaleY);
            this.mindOriginDataRelativeRootInfo.put(node.value.id, info);
        }
    }

    calculOriginConnectLineDataRelativeInfo(data) {
        let length = this.nodeConnectLinet.length
        for (let index = 0; index < length; index++) {
            let line = this.nodeConnectLinet[index]
            if (line.lineContent == null) {
                continue;
            }
            if (!this.nodeConnectLineControlPointMap.containsKey(line.id)) {
                this.nodeConnectLineControlPointMap.put(line.id, new NodeConnectLineControlPoint());
            }

            let controlPoint = this.nodeConnectLineControlPointMap.get(line.id);
            if (controlPoint == null) {
                continue;
            }
            if (line.lineContent.targetId == data.id) {
                let end = line.lineContent.getEndControlPoint();
                if (this.deviceType == DeviceType.IPHONE || this.deviceType == DeviceType.IPAD) {
                    controlPoint.endPoint = new Point((data.x - end.x), (data.y - end.y));
                } else if (this.deviceType == DeviceType.WEB || this.deviceType == DeviceType.MAC || this.deviceType == DeviceType.WINDOWS) {
                    controlPoint.endPoint = new Point((data.x - end.x), (data.y - end.y));
                } else if (this.deviceType == DeviceType.ANDROID || this.deviceType == DeviceType.ANDROID_PAD) {
                    controlPoint.endPoint = new Point((data.x - end.x) / this.getDeviceDensity(), (data.y - end.y) / this.getDeviceDensity());
                } else {
                    controlPoint.endPoint = new Point((data.x - end.x), (data.y - end.y));
                }
                controlPoint.originalEndPoint = new Point(end.x, end.y);
                controlPoint.endData = data.copy();

            } else if (line.parentNodeId == data.id) {
                let start = line.lineContent.getStartControlPoint();
                if (this.deviceType == DeviceType.IPHONE || this.deviceType == DeviceType.IPAD) {
                    controlPoint.startPoint = new Point((data.x - start.x), (data.y - start.y));
                } else if (this.deviceType == DeviceType.WEB || this.deviceType == DeviceType.MAC || this.deviceType == DeviceType.WINDOWS) {
                    controlPoint.startPoint = new Point((data.x - start.x), (data.y - start.y));
                } else if (this.deviceType == DeviceType.ANDROID || this.deviceType == DeviceType.ANDROID_PAD) {
                    controlPoint.startPoint = new Point((data.x - start.x) / this.getDeviceDensity(), (data.y - start.y) / this.getDeviceDensity());
                } else {
                    controlPoint.startPoint = new Point((data.x - start.x), (data.y - start.y));
                }
                controlPoint.originalStartPoint = new Point(start.x, start.y);
                controlPoint.startData = data.copy();
            }
        }
    }

    isFreeData(data) {
        return !this.rootData.isEmpty() &&
            data.type == MindElementType.SUBJECT &&
            data.textContent != null &&
            data.parentNodeId < 0 &&
            this.rootData.value.id != data.id &&
            this.centre.x > 0 &&
            this.centre.y > 0;
    }

    getLineData(value)  {
        if (this.rootLine == null || this.rootLine.length == 0) {
            return new MindElementData().emptyMindNode();
        }
        for (let index = 0; index <  this.rootLine.length; index++) {
            let data = this.rootLine[index];
            if (data.type == MindElementType.LINE && data.parentNodeId == value.parentNodeId &&
                    data.lineContent.targetId == value.id) {
                return data;
            } else if (data.type == MindElementType.SON_LINE && data.parentNodeId == value.parentNodeId &&
                    data.lineContent.targetId == value.id) {
                return data;
            }
        }
        return new MindElementData().emptyMindNode();
    }
}
export default MindFormatCheck
