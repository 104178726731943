<template>
  <div class="play-modal">
    <!-- 导图播放modal -->
    <a-modal
      class="mindmap-paly-model"
      :centered="true"
      v-model="isShowMindmapPlaySetUpModal"
      :title="getString(strings.Mindmap_Review)"
      :cancelText="getString(strings.Global_Cancel)"
      :okText="getString(strings.Mindmap_Review_Play)"
      @cancel="mindmapPlaySetUpCancelHandleOk"
      @ok="mindmapPlaySetUpDeleteHandleOk"
    >
      <div class="modal-style-box">
        <div
          class="modal-style"
          style="font-size: 14px; font-family: Noto Sans SC; display: block"
        >
          {{ getString(strings.Mindmap_Review_Content) }}
        </div>
      </div>

      <section class="style-header-box">
        <div
          class="style-head-item"
          :class="{ 'style-head-active': item.type == headTableActive }"
          v-for="(item, index) in headTableMenu"
          :key="index"
          @click="switchHeadTableMenu(item.type)"
        >
          {{ item.title }}
        </div>
      </section>

      <div id="modal-setup-content">
        <div class="setup-box" v-show="isShowMindmapPlayBackground"
        @click="isShowMindmapPlayBackgroundFun()" 
        style="cursor: pointer;">
          <span>{{
            getString(strings.Mind_Add_Background_Image)
          }}</span>
          <span style="min-width: 20px; margin-right: 0px"
            ><a-icon type="right"
          /></span>
        </div>
        <div class="setup-box">
          <span>{{ getString(strings.Mindmap_Review_Speed) }}</span>
          <a-slider
            id="test"
            :default-value="0.5"
            :min="0.2"
            :max="2"
            :step="0.1"
            :tooltipVisible="false"
            :disabled="disabled"
            @change="mindmapPlaySpeedSetup"
          />
          <span>{{ mindmapPlaySpeed }}/2S</span>
        </div>
        <div class="setup-box">
          <span>{{ getString(strings.Mindmap_Review_Word_Speed) }}</span>
          <a-slider
            id="test"
            :default-value="0.2"
            :min="0"
            :max="2"
            :step="0.1"
            :value="mindmapPlayText"
            :tooltipVisible="false"
            :disabled="disabled"
            @change="mindmapPlayTextSetup"
          />
          <span>{{ mindmapPlayText }}/2S</span>
        </div>
        <div class="setup-box" v-show="isShowMindmapPlayBackgroundRatio">
          <span>{{ getString(strings.Mindmap_Review_Background_Ratio) }}</span>
          <a-slider
            id="test"
            :default-value="100"
            :min="40"
            :max="200"
            :tooltipVisible="false"
            :disabled="disabled"
            @change="mindmapPlayBgSetup"
          />
          <span>{{ mindmapPlayBg }}/200%</span>
        </div>
        <!-- 优先播放同级内容' -->
        <div class="setup-box-checkbox">
          <span>{{ getString(strings.Mindmap_Review_Play_Order_Peer) }}</span>
          <a-checkbox :checked="PlayOrderPeer" @change="mindmapPlayOrderPeerSetup">
          </a-checkbox>
        </div>
        <div class="setup-box-checkbox"  v-show="isShowMindmapPlayBackgroundRatio">
          <span>{{ getString(strings.Mindmap_Only_Play_Text) }}</span>
          <a-checkbox :checked="OnlyPlayText" @change="mindmapOnlyPlayTextSetup">
          </a-checkbox>
        </div>
        <!-- <div class="setup-node-box">
                      <p>
                        {{
                          getString(
                            strings.Mindmap_Review_Current_Playback_Node_Magnification
                          )
                        }}
                      </p>
                      <div class="setup-slider-box">
                        <a-slider
                          id="test"
                          :default-value="0"
                          :min="0"
                          :max="0.5"
                          :step="0.1"
                          :tooltipVisible="false"
                          :disabled="disabled"
                          @change="mindmapPlayNodeEnlargeSetup"
                        />
                        <span>{{ mindmapPlayNodeEnlarge }}/0.5s</span>
                      </div>
                    </div> -->

        <div class="setup-box" @click="isShowMindmapPlayOrder()" style="cursor: pointer;">
          <span>{{
            getString(strings.Mindmap_Review_Node_Playback_Order)
          }}</span>
          <span style="min-width: 20px; margin-right: 0px"
            ><a-icon type="right"
          /></span>
        </div>

        <div v-if="playTotal != 0" class="warning-hint">
          <img
            class="warning-hint-icon"
            src="../../../assets/img/mindmap_play/warningHint.svg"
            alt=""
          />
          <span>{{
            getString(strings.Mind_Play_Number_Of_Playable_Times_Remaining)
          }}</span><span>{{playRemains}}/{{playTotal}}</span>
        </div>

      </div>
    </a-modal>

    <!-- 设置背景 -->
    <a-modal
      class="mindmap-paly-order-model"
      :centered="true"
      v-model="isShowMindmapPlayBackgroundModal"
      :title="getString(strings.Mind_Add_Background_Image)"
      :cancelText="getString(strings.Global_Cancel)"
      :okText="getString(strings.Global_Ok)"
      @cancel="isShowMindmapPlayBackgroundCancelHandleOk"
      :footer="null"
    >
      <div class="modal-style-box">
        <div
          class="modal-style"
          style="font-size: 14px; font-family: Noto Sans SC; display: block"
          v-if="nodeDatas.length > 0"
        >
          <section
              class="menu-use-content-inside"
              id="menu-content-border-box"
            >
              <ul>
                <li
                  class="menu-theme-border"
                  :class="{ 'menu-active-border': item.key == currentBackgroundImage.key }"
                  v-for="(item, index) in backgroundImageDatas"
                  :key="index"
                >
                  <img
                    class="style-img"
                    :src="
                      require(`../../../assets/img/playbackground/${item.key}_th.png`)
                    "
                    @click="setBackgorundImage(item)"
                    alt
                  />
                </li>
              </ul>
            </section>
            
            <div
              class="style-mbox-item"
              @click="materialMainContentHandleOk()">
              {{ getString(strings.Mind_Edit_Choose_From) }}
            </div>
        </div>
      </div>
    </a-modal>
    <!-- 播放顺序 -->
    <a-modal
      class="mindmap-paly-order-model"
      :centered="true"
      v-model="isShowMindmapPlayOrderModal"
      :title="getString(strings.Mindmap_Review_Node_Playback_Order)"
      :cancelText="getString(strings.Global_Cancel)"
      :okText="getString(strings.Mind_Edit_Upgrade)"
      @cancel="isShowMindmapPlayOrderCancelHandleOk"
      :footer="null"
    >
      <div class="modal-style-box">
        <div
          class="modal-style"
          style="font-size: 14px; font-family: Noto Sans SC; display: block"
          v-if="nodeDatas.length > 0"
        >
          <div class="node-box" v-for="(item, index) in nodeDatas" :key="index">
            <div class="node-text">
              {{ item.value.textContent.text }}
            </div>
            <div class="node-edit-sort-box">
              <div class="node-edit-sort-content">
                <img
                  src="../../../assets/img/create_chart_icon/chart_sort.svg"
                  alt=""
                  @click="editContentSort('down', index)"
                />
                <img
                  src="../../../assets/img/create_chart_icon/chart_sort_top.svg"
                  alt=""
                  @click="editContentSort('up', index)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import mindmapPlaySetupModal from "../../../assets/css/mindmapPlaySetupModal/mindmapPlaySetupModal.less";
import getString from "../../../common/lang/language";
import strings from "../../../common/lang/strings";
// import { postCrispsPlay } from "../../../common/netWork/crisps_api";
import {
  postMindmapPlay,
} from "../../../common/netWork/mind_map_api";
// import { postTemplatePlay } from "../../../common/netWork/template_api";
// import { postGroupPlayGmap } from "../../../common/netWork/group_api";
import EditMindmapVM from "../../../viewmodel/mindelementdata/vm/EditMindmapVM";
import Strings from '../../../utils/Strings';
import LoadLocalXJMind from "../../../viewmodel/core/tools/mapfile/LoadLocalXJMind";
import PlayBackground from "../../../viewmodel/minddata/PlayBackground"
import Colors from '../../../utils/Colors';
import PlaySettingData from '../../../viewmodel/minddata/PlaySettingData';
import PlayType from '../../../viewmodel/datatype/PlayType';

const headTableType = {
  ReviewTable: "ReviewTypeTable",
  SpeechTable: "SpeechTypeTable",
};

export default {
  data() {
    return {
      isShowMindmapPlaySetUpModal: false, //显示播放导图模态框
      disabled: false, //导图播放是否禁用
      mindmapPlaySpeed: 0.5, //导图播放速度 0.2~2
      mindmapPlayText: 0.2, //导图播放文字速度 0~1
      changeMindmapPlayText: false, //手动设置导图播放文字速度 0~1
      mindmapPlayBg: 100, //导图播放背景比例 40~200
      mindmapPlayNodeEnlarge: 0, //当前播放节点放大倍数
      isShowMindmapPlayOrderModal: false, //显示播放顺序模态框
      isShowMindmapPlayBackgroundModal: false, //显示播放背景
      PlayOrderPeer: false, //同级内容播放优先级
      OnlyPlayText: false, //只播放文字
      clickPlayOrderPeer: false, //主动点击同级内容播放优先级
      nodeDatas: [], //播放节点
      strings: strings, //语言包
      playRemains: 0, //剩余播放次数
      playTotal: 0, //总播放次数
      backgroundImageDatas: [], //背景图列表
      isShowMindmapPlayBackground: true, 
      isShowMindmapPlayBackgroundRatio: true, 
      currentBackgroundImage: new PlayBackground("mind_background_1000", "center", Colors.white, Colors.black80, Colors.black50, 0x0C0C0C, 0xF79833, 1000, 1000),
      headTableActive: headTableType.ReviewTable,
      headTableMenu: [
        {
          title: getString(strings.Mindmap_Review_Tab_Lable),
          type: headTableType.ReviewTable,
        },
        {
          title: getString(strings.Mindmap_Speech_Tab_Lable), 
          type: headTableType.SpeechTable,
        },
      ],
    };
  },

  methods: {
    ...mapMutations([
      "mindmapPlayPauseShowSetup",
      "isShowShoppingModel",
      "showLoginModel",
      "isMindmapPlay",
      "setmaterialLibraryModulesModel",
    ]),

    //语言包
    getString(i) {
      return getString(i);
    },
    //导图播放取消
    mindmapPlaySetUpCancelHandleOk() {
      this.isShowMindmapPlaySetUpModal = false;
      this.mindmapPlayPauseShowSetup({
        showSetup: false,
        playAccout: "HIDE_PLAY_SETUP",
      });
    },
    setBackgorundImage(item) {
      // 设置骨架
      this.currentBackgroundImage = item
    },
    //导图播放确定
    mindmapPlaySetUpDeleteHandleOk() {
      let userData = localStorage.getItem("meMessage");
      let router = this.$router.history.current.name; //路由状态

      let setData = new PlaySettingData();
      if (this.headTableActive == headTableType.ReviewTable) {
          setData.playType = PlayType.AnimationReview;
          setData.mapScale = this.mindmapPlayBg;
      } else {
          setData.playType = PlayType.SpeechMode;
          setData.mapScale = 100.0;
      }
      setData.speed = this.mindmapPlaySpeed;
      setData.textSpeed = this.mindmapPlayText
      setData.playOrderList = this.nodeDatas;
      setData.playOrderPeer = this.PlayOrderPeer;
      if (this.currentBackgroundImage.key == "mind_background_1000") {
        if (Colors.isDarkColor(EditMindmapVM.mindBGColor)) {
          this.currentBackgroundImage.textColor = Colors.white;
          this.currentBackgroundImage.darknessTextColor = Colors.black40;
          this.currentBackgroundImage.lineColor = Colors.white;
          this.currentBackgroundImage.backgroundColor = EditMindmapVM.mindBGColor;   
          this.currentBackgroundImage.borderColor = Colors.white;
        } else {
          this.currentBackgroundImage.textColor = Colors.black;
          this.currentBackgroundImage.darknessTextColor = Colors.black35;
          this.currentBackgroundImage.lineColor = Colors.black;
          this.currentBackgroundImage.backgroundColor = EditMindmapVM.mindBGColor;   
          this.currentBackgroundImage.borderColor = Colors.black;
        }
      }
      setData.playBackground = this.currentBackgroundImage;
      setData.onlyPlayText = this.OnlyPlayText

      // this.mindmapGetPlayPower(true).then((playPower) => {
      //   if (playPower) {
          if (EditMindmapVM.isExistResumeData()) {
            let that = this;
            this.$confirm({
              title: that.getString(that.strings.Mind_Tips),
              content: that.getString(that.strings.Mind_Play_Resume_Tips_Do_You_Want_To_Go_Back_To_Where_It_Was_Last_Played),
              cancelText:that.getString(that.strings.Global_Cancel),
              okText:that.getString(that.strings.Global_Ok),
              centered: true,
              style: {
                borderRadius: '20px',
              },
              onOk() {
                that.isShowMindmapPlaySetUpModal = false;
                that.mindmapPlayPauseShowSetup({
                  showSetup: false,
                  playAccout: "OnePlay",
                });
                EditMindmapVM.resumePlay(setData);
              },
              onCancel() {
                that.isShowMindmapPlaySetUpModal = false;
                that.mindmapPlayPauseShowSetup({
                  showSetup: false,
                  playAccout: "OnePlay",
                });
                EditMindmapVM.playMap(setData);
              },
            });
            
          } else {
            this.isShowMindmapPlaySetUpModal = false;
            this.mindmapPlayPauseShowSetup({
              showSetup: false,
              playAccout: "OnePlay",
            });

            EditMindmapVM.playMap(setData);
          }
          this.isMindmapPlay(true);//将要导图播放，isMindmapPlay设为true
        // }
      // });


    },

    materialMainContentHandleOk() {
      this.setmaterialLibraryModulesModel({ accout: true, type: "pbb" });
    },

    //播放调用接口
    mindmapGetPlayPower(isPlay) {
      let router = this.$router.history.current.name; //路由状态
      let obj = {
        mindMapId: '',
        shareId: '',
        crispsId: '',
        templateId: '',
        gmapId: ''
      }
      if (isPlay) {
          if (router == "Complie") {
            if ((LoadLocalXJMind.isLoaclMind() && !LoadLocalXJMind.isCloudStorage()) || this.$route.query.id == null) {
              obj.mindMapId = "123456"
            } else {
              obj.mindMapId = this.$route.query.id;
            }
          } else if (router == "template") {
            obj.templateId = this.$route.query.id;
          } else if (router == "s") {
            let index = this.$route.path.indexOf("/s/") + 3;
            let id = this.$route.path.substring(index);
            obj.shareId = id
          } else if (router == "c") {
            let index = this.$route.path.indexOf("/c/") + 3;
            let id = this.$route.path.substring(index);
            obj.crispsId = id;
          } else if (router == "gmap") {
            obj.gmapId = this.$route.query.id
          }
      }
      return new Promise((resolve,reject) => {
        postMindmapPlay(
          obj,
          (res) => {
            resolve(res);
          },
          (error) => {
            if (error.code == 155) {
              this.isShowShoppingModel(true);
            } else if (error.code == 4) {
              this.showLoginModel(true);
            } else {
              this.$message.error(error.desc);
            }
            reject(error);
          }
        );
      });

    },
    switchHeadTableMenu(type) {
      this.headTableActive = type;
      this.isShowMindmapPlayBackground = true //this.headTableActive == headTableType.SpeechTable
      this.isShowMindmapPlayBackgroundRatio = this.headTableActive == headTableType.ReviewTable
      if (this.headTableActive == headTableType.SpeechTable && !this.PlayOrderPeer && !this.clickPlayOrderPeer) {
        this.PlayOrderPeer = true
      } else if (this.headTableActive == headTableType.ReviewTable && this.PlayOrderPeer && !this.clickPlayOrderPeer) {
        this.PlayOrderPeer = false
      }
      if (this.headTableActive == headTableType.SpeechTable && !this.changeMindmapPlayText) {
        this.mindmapPlayText = 0.1
      } else if (this.headTableActive == headTableType.ReviewTable && !this.changeMindmapPlayText) {
        this.mindmapPlayText = 0.2
      }
      
    },
    //思维导图播放速度设置
    mindmapPlaySpeedSetup(value) {
      this.mindmapPlaySpeed = value;
    },
    //思维导图播放文字设置
    mindmapPlayTextSetup(value) {
      this.mindmapPlayText = value;
      this.changeMindmapPlayText = true;
    },
    //思维导图播放背景设置
    mindmapPlayBgSetup(value) {
      this.mindmapPlayBg = value;
    },
    //思维导图播放同级内容优先级
    mindmapPlayOrderPeerSetup(value) {
      this.PlayOrderPeer = !this.PlayOrderPeer;
      this.clickPlayOrderPeer = true
    },
    //思维导图播放同级内容优先级
    mindmapOnlyPlayTextSetup(value) {
      this.OnlyPlayText = !this.OnlyPlayText;
    },
    //思维导图播放节点放大设置
    mindmapPlayNodeEnlargeSetup(value) {
      this.mindmapPlayNodeEnlarge = value;
    },
    //显示播放排序模态框
    isShowMindmapPlayOrder() {
      this.isShowMindmapPlayOrderModal = true;
    },
    //调整排序回调
    isShowMindmapPlayOrderCancelHandleOk() {
      this.isShowMindmapPlayOrderModal = false;
    },

    //显示播放背景
    isShowMindmapPlayBackgroundFun() {
      if (this.backgroundImageDatas == null || this.backgroundImageDatas.length == 0) {
          this.backgroundImageDatas = [
                  new PlayBackground("mind_background_1000", "center", Colors.white, Colors.black80, Colors.black50, 0x0C0C0C, 0xF79833, 1000, 1000),
                  new PlayBackground("mind_background_11", "center", Colors.white, Colors.black80, Colors.black50, 0x0C0C0C, 0xF79833, 1000, 1000),
                  new PlayBackground("mind_background_12", "left", Colors.black, Colors.black30, Colors.black, 0x7FEAE8, 0x70CDCB, 3656, 2000),
                  new PlayBackground("mind_background_8", "center", Colors.black, 0xE6E3D3, 0xC07D5D, 0xFBDDBD, 0xFFA09A, 3494, 2000),

                  new PlayBackground("mind_background_9", "center", Colors.white, 0x353947, 0xF19319, 0x2F333F, 0xF29319, 899, 437),
                  new PlayBackground("mind_background_10", "left", Colors.white, 0xF33843, 0xF7A546, 0x860E07, 0xFFE400, 3150, 1500),
                  new PlayBackground("mind_background_4", "center", Colors.white, Colors.black80, Colors.black50, 0x151515, 0xF79833, 1456, 816),

                  // new PlayBackground("mind_background_5", "center", Colors.black, Colors.black60, 0xDA9797, 0xDE9A99),
                  new PlayBackground("mind_background_7", "center", 0x213C2D, 0x99CBC5, 0x278274, 0xCFF0ED, 0xDCC13D, 2520, 1200),
                  new PlayBackground("mind_background_1", "center", Colors.black, 0xFBC5D0, 0x8632D5, 0xF0D6ED, 0x8C40FF, 1456, 816),

                  new PlayBackground("mind_background_6", "right", 0x701F26, 0xE69A74, 0xF54D5D, 0xF6F9F6, 0xF78758, 1456, 816),
                  new PlayBackground("mind_background_3", "center", Colors.white, Colors.black50, Colors.black50, 0x232222, 0xFEB643, 1456, 816),
                  new PlayBackground("mind_background_2", "center", Colors.black, Colors.black40, Colors.black, 0xF0BED0, 0xFCFF03, 1456, 816)];
      }
      this.isShowMindmapPlayBackgroundModal = true;

    },
    isShowMindmapPlayBackgroundCancelHandleOk() {
      this.isShowMindmapPlayBackgroundModal = false;
    },

    
    //上下排序
    editContentSort(type, index) {
      if (type == "up") {
        if (index === 0) {
          this.nodeDatas.push(this.nodeDatas[index]);
          this.nodeDatas.splice(index, 1);
          return;
        }
        this.nodeDatas.splice(index - 1, 0, this.nodeDatas[index]);
        this.nodeDatas.splice(index + 1, 1);
      } else if (type == "down") {
        if (index === this.nodeDatas.length - 1) {
          this.nodeDatas.unshift(this.nodeDatas[index]);
          this.nodeDatas.splice(index + 1, 1);
          return;
        }
        this.nodeDatas.splice(index + 2, 0, this.nodeDatas[index]);
        this.nodeDatas.splice(index, 1);
      }
    },
  },
  watch: {
    "$store.state.mindmapPlayPauseShowSetup.playAccout"(newPlayAccout) {
      switch (newPlayAccout) {
        case "SHOW_PLAY_SETUP":
            this.isShowMindmapPlaySetUpModal = true;
            this.nodeDatas = EditMindmapVM.getPlayRootNodes();
            this.mindmapGetPlayPower(false).then(res => {
            if (res != null){
              this.playRemains = res.playRemains; //剩余播放次数
              this.playTotal = res.playTotal; //总播放次数
            }

          })
          break;
        case "HIDE_PLAY_SETUP":
          this.isShowMindmapPlaySetUpModal = false;
          break;
        case "OnePlay":
          this.isShowMindmapPlaySetUpModal = false;
          break;
        default:
          break;
      }
    },

    "$store.state.materialImgObj": {
      handler(newval, oldval) {
        if (newval == null) {
        } else {
          let type = this.$store.state.showMaterialLibraryModulesModel.type;          
          if (type == "pbb") {
            this.currentBackgroundImage = new PlayBackground(newval.mageBase64, "center", Colors.black, Colors.black30, Colors.black, 0x7FEAE8, 0x70CDCB, newval.width, newval.height)
            this.isShowMindmapPlayBackgroundModal = false;
          }
        }
      },
      // immediate: true,    //在一开是绑定的时候执行
      deep: true, //深度监听
    },
  },
};
</script>

<style lang="less" scoped>
  .warning-hint {
    color: #ec705a;
    font-size:14px;
    display: flex;
    align-items: center;
    .warning-hint-icon {
      margin-right: 10px;

    }
  }
  .style-header-box {
    display: flex;
    background: #f2f2f2;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 4px;
    .style-head-item {
      width: 50%;
      height: 32px;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &:hover {
        background-color: #d7d7d7;
        border-radius: 4px;
      }
    }
    .style-head-active {
      background: #fff;
      border-radius: 4px;
      font-weight: 525;
    }    
  }
  .style-mbox-item {
      color: #333;
      font-size: 13px;
      font-weight: 600;
      margin-left: 10px;
      cursor: pointer;
    }
</style>