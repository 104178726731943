
import Colors from "../../../utils/Colors";
import imageToStyle from "../../../utils/ImageToolkit";
import UiUtil from "../../../utils/UiUtil";
import CGRect from "../../../viewmodel/core/base/basedata/Rect";

/**
 * ProjectName: MindMap
 * Created by tony on 2/13/21
 * Copyright(c) 2020 mindyushu.com
 */

class MindNodeUpView {
    constructor(fatherDom, size, delegate, isChangeAngle = false) {
        this.lineHeight = new UiUtil().dip2px(2);
        this.lineView;
        this.line;
        this.size = size;
        this.fatherDom = fatherDom;
        this.delegate = delegate;
        this.mousdownFunction;
        this.selectId;
        this.isMoveDom = false;
        this.timeInterval;
        this.left = 0;
        this.top = 0;
        this.isChangeAngle = isChangeAngle;
        this.data = null;
        this.initView();
    }

    initView() {
        this.lineView = document.createElement("div");
        this.lineView.style.position = 'absolute';
        this.lineView.style.zIndex = 31;
        this.fatherDom.appendChild(this.lineView);
        this.lineView.style.width = this.size.width + 'px';
        this.lineView.style.height = this.size.height + 'px';
        this.lineView.style.border = '2px solid ' + Colors.getUiColor(Colors.black50); 
        this.lineView.style.backgroundColor = Colors.getUiColor(Colors.white);
        this.lineView.style.borderRadius = '50%';
        this.lineView.style.cursor = 'pointer';
        this.lineView.style.userSelect = "none"
        this.lineView.style.overflow = "hidden"

        this.line = document.createElement("div");
        this.lineView.appendChild(this.line);
        this.line.style.position = 'absolute';
        this.line.style.left = "50%";
        this.line.style.top = "50%";
        this.line.style.transform = "translate(-50%,-50%)";
        this.line.style.width = '100%';
        this.line.style.height = '100%';
        this.line.style.lineHeight = this.size.height + 'px';
        this.line.style.textAlign = 'center';
        this.line.style.color = Colors.getUiColor(Colors.black50); 
        this.line.innerHTML = "&#xe651;";
        this.line.classList.add("hidden-child-node");
        this.lineView.style.display = 'none';
        if (this.isChangeAngle) {
            this.lineView.style.border = '0 px solid ' + Colors.getUiColor(Colors.black50); 
            let url = require("../../../assets/img/mapIcon/res/mipmap-xxhdpi/xuanzhuan_icon.svg")
            this.line.style.backgroundImage = 'url(' + url + ')';           
            this.line.style.backgroundPosition = 'center center';
            this.line.style.backgroundRepeat = 'no-repeat';
            this.line.style.backgroundSize = '100% 100%';
            this.line.style.marginLeft = this.size.width + 'px';
            this.line.style.filter = 'drop-shadow(' + Colors.getUiColor(Colors.black50) + ' -' + this.size.width + 'px 0)';
            this.line.style.fontSize = 10 + 'px';
            this.line.innerHTML = "";
        } else {
            this.line.innerHTML = "&#xe651;";
        }
        this.bundling();
    }

    bundling() {
        if (!this.isChangeAngle) {
            this.mousdownFunction = (ev) => {
                window.event ? window.event.cancelBubble = true : ev.stopPropagation();
                this.delegate.onMousedownHiddenChildren(this.selectId);
                this.hideDom()
            }
            this.lineView.addEventListener('mousedown', this.mousdownFunction)
        }
    }

    updateAngleText(value) {
        if (value <= -1000) {
            this.lineView.style.overflow = "hidden"
            this.line.style.marginLeft = this.size.width + 'px';
            this.line.innerHTML = "";
            return
        }
        this.lineView.style.overflow = ""
        this.line.style.backgroundImage = ""
        this.line.style.filter = ""
        this.line.style.marginLeft = '0px';
        this.line.style.marginTop = '0px';
        this.line.innerHTML = Math.trunc(value) + "°";
    }

    setColor(bg, line) {
        if (Colors.getUiColor(bg) == Colors.getUiColor(line)) {
            if (Colors.isDarkColor(bg)) {
                line = Colors.white;
            } else {
                line = Colors.black80;
            }
        }        
        if (this.isChangeAngle) {
            this.lineView.style.overflow = "hidden"
            this.lineView.style.border = '0px solid ' + Colors.getUiColor(line); 
            let url = require("../../../assets/img/mapIcon/res/mipmap-xxhdpi/xuanzhuan_icon.svg")
            this.line.style.backgroundImage = 'url(' + url + ')';           
            this.lineView.style.backgroundColor = Colors.getUiColor(bg);
            this.line.style.filter = 'drop-shadow(' + Colors.getUiColor(line) + ' -' + this.size.width + 'px 0)';
            this.line.style.color = Colors.getUiColor(line);
            this.line.style.marginLeft = this.size.width + 'px';
            this.line.style.marginTop = '0px';
        } else {
            this.lineView.style.border = '2px solid ' + Colors.getUiColor(line); 
            this.lineView.style.backgroundColor = Colors.getUiColor(bg);
            this.line.style.color = Colors.getUiColor(line);
        }            
    }

    setPosition(obj) {
        if(obj==undefined) {
            return;
        }
        clearInterval(this.timeInterval);
        this.lineView.style.display = 'block';
        this.lineView.style.left = obj.left + 'px';
        this.lineView.style.top = obj.top + 'px';
        this.left = obj.left;
        this.top = obj.top;
    }

    getRect() {
        if (this.lineView == null || this.lineView.style.display != 'block') {
            return new CGRect(0, 0, 0, 0)
        }
        return new CGRect(this.left, this.top, this.size.width, this.size.height)
    }

    checkId(id, data) {
        this.selectId = id;
        this.data = data;
    }

    hideDom() {
        this.lineView.style.display = 'none';
    }

    movePoint(data, isDelay = true) {
        let that = this;
        clearInterval(this.timeInterval);
        if (isDelay) {
            this.timeInterval = setInterval(() => {
                that.hideDom()
                clearInterval(this.timeInterval);
            },3000)
        } else {
            that.hideDom()
        }
    }
}

export default MindNodeUpView