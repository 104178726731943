<template>
  <div class="complie">
    <div class="complie-box">
      <div class="comlie-content">
        <div
          class="content-header"
          :class="
            routerData == 'template' || routerData == 'Complie'
              ? 'content-header-keep-handsome'
              : ''
          "
        >
          <!-- 分享logo路由 -->
          <a
            v-if="routerData == 's' && !nowElectron"
            href="http://mindyushu.com"
            target="_black"
          >
            <div class="content-header-log">
              <img
                src="../../assets/img/code/logo02.svg"
                alt=""
              />
              <!-- <div class="content-header-text">
                {{ getString(strings.About_App_Name) }}
              </div> -->
            </div>
          </a>
          <!-- 功能界面路由 -->
          <template v-else>
            <template v-if="nowElectron">
              <div @click="jumpOfficialWebsite()" class="content-header-log">
                <img
                  class="content-header-log-icon"
                  src="../../assets/img/code/logo02.svg"
                  alt=""
                />
                <!-- <div class="content-header-text">
                  {{ getString(strings.About_App_Name) }}
                </div> -->
              </div>
            </template>
            <template v-else>
              <a :href="'/Home/Mymind'" target="_black">
                <div class="content-header-log">
                  <img
                    src="../../assets/img/code/logo02.svg"
                    alt=""
                  />
                  <!-- <div class="content-header-text">
                    {{ getString(strings.About_App_Name) }}
                  </div> -->
                </div>
              </a>
            </template>
          </template>

          <!-- 导图&大纲 -->
          <div
            class="content-header-titel"
            v-for="(item, index) in accoutList"
            :key="index"
          >
            <div
              v-if="isShowMindStyle"
              :class="
                titelAccout == index ? 'befor-title-mind' : 'header-title-mind'
              "
              @click="setAccoutTitel(index)"
            >
              <div class="title-mind-text">{{ item.styleName }}</div>
            </div>
          </div>

          <!-- 下载入口 v-if="routerData != 'c'"-->
          <div class="content-header-right" v-if="true">
            <!-- 编辑操作状态 -->
            <div class="edit-accout" v-if="routerData == 'Complie'">
              <div v-if="editAccout.url != ''">
                <a-icon :type="editAccout.url" />
              </div>
              <div style="margin-left: 5px; margin-top: -2px">
                {{ editAccout.text }}
              </div>
              <div
                class="save-button"
                v-if="editAccout.url != 'check-circle' && editAccout.url != ''"
              >
                <a-button type="primary" @click="saveMindData">{{
                  getString(strings.Public_Header_Save)
                }}</a-button>
              </div>
            </div>
            <div class="content-header-dowload">
              <template v-if="nowElectron">
                <span
                  class="content-header-dowload-page"
                  @click="
                    jumpOfficialWebsite(
                      'https://www.mindyushu.com/downloads.html'
                    )
                  "
                >
                  <img
                    src="../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/dowload_icon.png"
                    alt=""
                  />
                  <span>
                    {{ getString(strings.Public_Header_Download_Apps) }}
                  </span>
                </span>
                <span>/</span>
                <span
                  class="content-header-dowload-page"
                  @click="
                    jumpOfficialWebsite(
                      'https://www.mindyushu.com/downloads.html'
                    )
                  "
                >
                  <img
                    src="../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/dowload_mobile.png"
                    alt=""
                  />
                  <span>
                    {{ getString(strings.Public_Header_Mobile_Version) }}
                  </span>
                </span>
              </template>
              <template v-else>
                <a href="https://www.mindyushu.com/" target="_black">
                  <img
                    src="../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/dowload_icon.png"
                    alt=""
                  />
                  <span>
                    {{ getString(strings.Public_Header_Download_Apps) }}
                  </span>
                </a>
                <span>/</span>
                <a href="https://www.mindyushu.com/" target="_black">
                  <img
                    src="../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/dowload_mobile.png"
                    alt=""
                  />
                  <span>
                    {{ getString(strings.Public_Header_Mobile_Version) }}
                  </span>
                </a>
              </template>
            </div>

            <!-- 风暴查看页面 -->

            <div class="content-header-user-message" v-if="routerData == 'c'">
              <div class="content-header-user-message" v-if="isComplieUserData">
                <span class="user-head" @click="showUserData">
                  <div v-if="this.crispsUserData.isMember" class="user-avatar">
                    <img
                      :src="httpImageToPrefix(this.crispsUserData.avatar)"
                      alt=""
                    />
                  </div>
                  <div v-else>
                    <img
                      :src="httpImageToPrefix(this.crispsUserData.avatar)"
                      alt=""
                    />
                  </div>
                </span>

                <div style="width: 70px; height: 22px; overflow: hidden">
                  {{
                    this.newUserIfLogin == true
                      ? this.userLoginData.username
                      : this.userLoginData.username
                  }}
                </div>
              </div>
              <div class="content-header-user-message" v-else>
                <div class="account-no-login">
                  <a-button @click="hideLoginModels">{{
                    getString(strings.Global_Login)
                  }}</a-button>
                </div>
              </div>
            </div>

            <!-- 分享界面 -->
            <!-- <div
              class="content-header-user-message"
              v-else-if="routerData == 's'"
            ></div> -->

            <!-- 编辑界面 -->
            <div
              class="content-header-user-message"
              v-if="routerData == 'Complie'"
            >
              <div class="content-header-user-message" v-if="isComplieUserData">
                <div class="head_avatar" @click="showUserData">
                  <img
                    v-show="userLoginData.isMember"
                    class="crown"
                    src="../../assets/img/user/crown.png"
                    alt
                  />
                  <div
                    class="head_avatar_icon"
                    :style="
                      'width: 30px;height: 30px;border-radius: 50%; background-image:url(' +
                      userLoginData.avatar +
                      ') ; background-repeat: no-repeat; background-size: cover; background-position:center; overflow:hidden;'
                    "
                  ></div>
                </div>

                <span style="width: 70px; height: 22px; overflow: hidden">{{
                  this.newUserIfLogin == true
                    ? this.userLoginData.username
                    : this.userLoginData.username
                }}</span>
              </div>
              <div class="content-header-user-message" v-else>
                <div class="account-no-login">
                  <a-button @click="hideLoginModels">{{
                    getString(strings.Global_Login)
                  }}</a-button>
                </div>
              </div>
            </div>
          </div>
          <div class="content-header-storm-right" v-else>
            <div class="content-header-dowload">
              <template v-if="nowElectron">
                <span
                  class="content-header-dowload-page"
                  @click="
                    jumpOfficialWebsite(
                      'https://www.mindyushu.com/downloads.html'
                    )
                  "
                >
                  <img
                    src="../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/dowload_icon.png"
                    alt=""
                  />
                </span>
              </template>
              <template v-else>
                <a href="https://www.mindyushu.com/" target="_black">
                  <img
                    src="../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/dowload_icon.png"
                    alt=""
                  />
                </a>
              </template>
            </div>
          </div>
        </div>

        <!-- 分享链接创作者信息 -->
        <div class="userData" v-if="routerData == 's'">
          <div class="userData-box">
            <div class="content-header-user-message">
              <div class="complie-header-left">
                <div class="mind-logo" @click="toDownload">
                  <img
                    src="../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/share_mind_logo.png"
                    alt=""
                  />
                  <div>{{ getString(strings.About_App_Name) }}</div>
                </div>

                <div class="creator-message cursor-auto">
                  <span class="creator-logo">
                    <img
                      src="../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/creator.svg"
                      alt=""
                    />
                  </span>
                  <span class="creator-name" style="margin: 0px 10px">{{
                    getString(strings.Public_Header_Creator)
                  }}</span>
                  <div
                    class="user-head-message cursor-pointer"
                    @click="showUserData"
                  >
                    <span class="user-head" v-if="!whetherMobile">
                      <div
                        class="user-head-border-member"
                        v-if="shareUserData.userIsMember"
                      >
                        <img :src="shareUserData.userAvatar" alt="" />
                      </div>

                      <div class="user-head-border" v-else>
                        <img :src="shareUserData.userAvatar" alt="" />
                      </div>
                    </span>

                    <span v-else class="user-head">
                      <div
                        class="user-head-border-member"
                        v-if="shareUserData.userIsMember"
                      >
                        <img :src="shareUserData.userAvatar" alt="" />
                      </div>

                      <div class="user-head-border" v-else>
                        <img :src="shareUserData.userAvatar" alt="" />
                      </div>
                    </span>

                    <span class="user-head-name">{{
                      shareUserData.username
                    }}</span>
                  </div>
                </div>
              </div>

              <div class="complie-header-right">
                <!-- 导图播放 -->
                <div
                  class="mindmap-play"
                  v-if="getMindmapPattern()"
                  @click="mindmapPlay()"
                >
                  <div class="mp-play">
                    <img style="width:24px; height:24px;"
                      src="../../assets/img/mindNewEditMenuIocn/playMind_share_icon.svg"
                      alt=""
                    />
                  </div>
                  <span>{{ getString(strings.Mindmap_Review) }}</span>
                </div>
                <!-- 查看次数 -->
                <div class="look-mindcount cursor-auto">
                  <span class="number-views">
                    <img
                      src="../../assets/img/mindNewEditMenuIocn/numberViews.svg"
                      alt=""
                    />
                  </span>
                  <span class="look-count-text" style="margin: 0px 10px">
                    {{ getString(strings.Public_Header_View_Frequency) }}
                    {{ shareUserData.viewCount + 1 }}
                  </span>
                  <span class="look-count-number">{{
                    shareUserData.viewCount + 1
                  }}</span>
                </div>
                <!-- 收藏 -->
                <div class="collection-box">
                  <div
                    class="collection"
                    @click="colectionLogoType(shareUserData.shareId)"
                  >
                    <span class="collect">
                      <img
                        v-if="!colectionLogo"
                        src="../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/collect.svg"
                        alt=""
                      />
                      <img
                        v-else
                        src="../../assets/img/mindNewEditMenuIocn/collectNot.svg"
                        alt=""
                      />
                    </span>
                    <span class="mobile-display-none">{{
                      getString(strings.User_Collection)
                    }}</span>
                  </div>
                </div>

                <div class="mindmap-or-outline">
                  <span
                    v-if="titelAccout"
                    @click="setAccoutTitel(0)"
                    class="mindicon"
                  >
                    &#58884;
                  </span>
                  <span v-else @click="setAccoutTitel(1)" class="Outline">
                    <img style="width:24px; height:24px;margin-top:-2px;"
                      src="../../assets/img/mindNewEditMenuIocn/share_outline.svg"
                      alt=""
                    />
                  </span>
                </div>
                <!-- 保存 -->
                <div class="save-box" v-if="canBeSaved">
                  <div
                    class="save"
                    v-if="saveMindAccout === true"
                    @click="saveMind(shareUserData.shareId)"
                  >
                    <span class="save-icon">
                      <img
                        src="../../assets/img/mindNewEditMenuIocn/save.svg"
                        alt=""
                      />
                    </span>
                    <!-- <span>{{ getString(strings.Public_Header_Save) }}</span> -->
                  </div>
                  <!-- 保存中 -->
                  <div class="save" v-else-if="saveMindAccout === 'Saving'">
                    <span class="save-icon">
                      <img
                        src="../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/saved.svg"
                        alt=""
                      />
                    </span>
                    <span>{{ getString(strings.Header_Mind_Tips_Saving) }}...</span>
                  </div>
                  <div class="save" v-else>
                    <span class="save-icon">
                      <img
                        src="../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/saved.svg"
                        alt=""
                      />
                    </span>
                    <span>{{ getString(strings.Header_Mind_Tips_Saved) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 移动端输入密码 -->
        <div
          class="mobile-mind-logo"
          v-if="routerLogo == true"
          ref="moblieMindLogo"
          @click="toDownload"
        >
          <img
            src="../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/share_mind_logo.png"
            alt=""
          />
          <span> {{ getString(strings.About_App_Name) }} </span>
        </div>
      </div>
    </div>
    <!-- 导图播放 -->
    <ComplieMindmapPlayModal />
  </div>
</template>
<script>
if (
  typeof navigator === "object" &&
  typeof navigator.userAgent === "string" &&
  navigator.userAgent.indexOf("Electron") >= 0
) {
  var { ipcRenderer } = window.require("electron");
  // var ipcRenderer = window.ipcRenderer
}
import { mapMutations } from "vuex";
import getString from "../../common/lang/language";
import strings from "../../common/lang/strings";
import { doLogin, doLogout, getJwt } from "../../common/netWork/base";
import { postUserMe } from "../../common/netWork/base_api";
import {
  postMindmapCollectShare,
  postMindmapPlayShare,
  postMindmapSaveShare,
  postMindmapUncollectShare,
} from "../../common/netWork/mind_map_api";

import Colors from "../../utils/Colors";
import Logger from "../../utils/Logger";
import UiUtil from "../../utils/UiUtil";
import httpImageToPrefix from "../../utils/httpImageToPrefix";

import MindType from "../../viewmodel/datatype/MindType";
import MindDisplayType from "../../viewmodel/datatype/MindDisplayType";
import MindElementType from "../../viewmodel/datatype/MindElementType";
import EditMindmapVM from "../../viewmodel/mindelementdata/vm/EditMindmapVM";
import MindOperateUIControllerView from "./tools/MindOperateUIControllerView";

import ComplieMindmapPlayModal from "./ComplieHeader/ComplieMindmapPlayModal";
import Util from '../../utils/Util';

export default {
  data() {
    return {
      ProFileName: "未命名文件",
      titelAccout: 0,
      accoutList: [
        {
          styleName: getString(strings.Public_Header_Mind),
        },
        {
          styleName: getString(strings.Public_Header_Outline),
        },
      ],
      routerData: this.$router.history.current.name,
      routerLogo: false,
      isShowMindStyle: true,
      isComplieUserData: true,
      shareDataCount: 8,
      userLoginData: {}, //页面内登陆用户数据
      newUserIfLogin: false,
      editAccout: {
        text: "",
        url: "",
      },
      colectionLogo: false, //收藏状态
      canBeSaved: true, //是否显示保存
      saveMindAccout: true, //保存导图状态
      whetherMobile: true, //是否为pc
      nowElectron: false, //当前是否在electron环境中
      strings,
      viewModel: null,
      token: "", //导图的编号
      isClickSaveButton: false, //是否点击保存
    };
  },
  components: {
    ComplieMindmapPlayModal,
  },
  props: {
    templateUserData: {
      type: Object,
      defualt() {
        return {};
      },
    },
    shareUserData: {
      type: Object,
      defualt() {
        return {};
      },
    },
    crispsUserData: {
      type: Object,
      defualt() {
        return {};
      },
    },
  },
  created() {
    if (this.routerData == "mindmap") {
      localStorage.removeItem("meMessage");
    }
    if (this.routerData == "s") {
      this.isShowMindStyle = false;
      this.routerData = false;
      this.isShowLookCount();
      let shareData = localStorage.getItem("share-password-");
      if (!shareData) {
        this.routerLogo = true;
      }
    }
    if (
      window.navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i // midp|iphone os|ipad|ucweb|windows mobile|
      )
    ) {
      this.whetherMobile = true;
    } else {
      this.whetherMobile = false;
    }
    this.nowElectron = this.isElectron();

    if (document.hidden !== undefined) {
      var self = this;
      document.addEventListener("visibilitychange", () => {
        if (document.hidden) {
          return;
        }
        self.doLoadUserInfo();
      });
    }
  },
  mounted() {
    this.doLoadUserInfo();
    this.mobileIsOutline();
    //气泡图不显示大纲
    if (this.viewModel == null) {
      this.viewModel = this.$store.state.editMindmap;
    }
    this.bus.$on("saveToMyMap", (val) => {
      //点击保存按钮，未登录登录之后保存到我的导图
      if (this.isClickSaveButton) {
        this.saveMind(this.shareUserData.shareId);
      }
    });
    //阻止safari自身滑动事件
    // window.addEventListener("touchstart", function (e) {
    //   e.preventDefault();
    // });
    // window.addEventListener("touchmove", function (e) {
    //   e.preventDefault();
    // });
  },
  methods: {
    ...mapMutations([
      "showLoginModel",
      "showLoginModel",
      "showDaGangModel",
      "showUserDataModel",
      "isShowShoppingModel",
      "mindmapPlayPauseShowSetup",
    ]),
    getString(i) {
      return getString(i);
    },
    httpImageToPrefix(img) {
      return httpImageToPrefix(img);
    },
    isElectron() {
      if (
        typeof navigator === "object" &&
        typeof navigator.userAgent === "string" &&
        navigator.userAgent.indexOf("Electron") >= 0
      ) {
        return true;
      }
      return false;
    },
    jumpOfficialWebsite(val) {
      if (arguments.length == 1) {
        ipcRenderer.send("open-url", val);
      } else {
        ipcRenderer.send("open-url", "https://www.mindyushu.com/");
      }
    },
    //气泡时间线处理
    getHeaderMenuAccout(borderAccoutData) {
      if (
        borderAccoutData.mindType == MindType.DOUBLE_BUBBLE_MAP ||
        borderAccoutData.mindType == MindType.BUBBLE_MAP ||
        borderAccoutData.mindType == MindType.TIME_MAP
      ) {
        this.accoutList = [
          {
            styleName: getString(strings.Public_Header_Mind),
          },
        ];
      } else {
        this.accoutList = [
          {
            styleName: getString(strings.Public_Header_Mind),
          },
          {
            styleName: getString(strings.Public_Header_Outline),
          },
        ];
      }
      // console.log(this.accoutList);
    },
    // 大纲&导图
    setAccoutTitel(index) {
      if (this.accoutList.length == 1) {
        return;
      }
      //大纲
      this.titelAccout = index;
      this.MindOperateUIControllerView = new MindOperateUIControllerView(
        this.viewModel,
        this.viewModel
      );
      this.MindOperateUIControllerView.onSelected(MindElementType.OUTLINE);
      this.mobileIsOutline();
    },
    doLoadUserInfo() {
      //获取localstorage中的用户信息
      if (!getJwt()) {
        this.isComplieUserData = false;
        return;
      }
      let meMessage = localStorage.getItem("meMessage");
      if (!meMessage) {
        var self = this;
        postUserMe(
          {},
          (res) => {
            doLogin(res);
          },
          (e) => {},
          () => {
            self.doLoadUserInfo();
          }
        );
        this.userLoginData = JSON.parse(meMessage);
        this.userLoginData.avatar = httpImageToPrefix(this.userLoginData.avatar);
      }

      this.isMember = meMessage.isMember;
      this.isComplieUserData = true;
    },
    //查看次数
    isShowLookCount() {
      var count = this.shareDataCount;
      if (count <= 999) {
        return;
      } else if (count >= 1000 && count < 10000) {
        const countK = count * 0.001;
        this.shareDataCount = Math.floor(countK * 100) / 100 + "k";
      } else if (count >= 10000) {
        const countK = count * 0.0001;
        this.shareDataCount = Math.floor(countK * 100) / 100 + "w";
      }
    },
    //隐藏登录模态框
    hideLoginModels() {
      this.showLoginModel(true);
    },
    //跳转下载页
    toDownload() {
      this.$router.push("/download");
    },
    //保存导图数据
    saveMindData() {
      EditMindmapVM.saveDataForClosePage();
    },
    //保存导图
    saveMind(id) {
      this.token = this.$router.history.current.params.id;
      let userData = localStorage.getItem("meMessage");
      if (userData == null || userData == "") {
        this.isClickSaveButton = true;
        this.showLoginModel(true);
        return;
      }
      this.saveMindAccout = "Saving";
      let pwd = window.localStorage.getItem("share-password-" + this.token);
      if (pwd == null) {
        pwd = "";
      }
      postMindmapSaveShare(
        { shareId: id, password: pwd },
        (res) => {
          this.$message.success(getString(strings.Message_Tips_Save_Success));
          this.saveMindAccout = false;
        },
        (error) => {
          this.$message.error(error.desc);
          this.saveMindAccout = true;
        },
        () => {}
      );
    },
    //收藏
    colectionLogoType(id) {
      let userData = localStorage.getItem("meMessage");
      if (userData == null || userData == "") {
        this.showLoginModel(true);
        return;
      }
      this.colectionLogo = !this.colectionLogo;
      if (!this.colectionLogo) {
        //收藏
        postMindmapCollectShare({
          collectionId: "",
          shareId: id,
        });
      } else {
        //取消收藏
        postMindmapUncollectShare({
          shareId: id,
        });
      }
    },
    //显示个人中心
    showUserData() {
      if (new UiUtil().isPhoneAndPad()) {
        return;
      }
      var userId;
      var xfrom;
      var fromId;
      if (this.routerData == "Complie") {
        userId = this.userLoginData.userId;
        xfrom = "template";
        fromId = this.userLoginData.userId;
      } else if (this.routerData == "s") {
        userId = this.shareUserData.userId;
        xfrom = "sharelink";
        fromId = this.shareUserData.userId;
      } else if (this.routerData == "c") {
        userId = this.crispsUserData.userId;
        xfrom = "crisps";
        fromId = this.crispsUserData.userId;
      }
      this.showUserDataModel({ accout: true, userId, xfrom, fromId });
    },
    //功能按钮颜色
    checkAddIconColor(btnColor) {
      let isDarkColor = Colors.isLightColor(btnColor);
      let color = "";
      if (isDarkColor) {
        color = "#666";
      } else {
        color = "#fff";
      }
      // let ele = document.querySelectorAll(".iconfont");
      let mindTitle = document.getElementsByClassName("complie");
      let mobileMindLogo = document.getElementsByClassName("mobile-mind-logo");
      var LookCountAndCollctionLogo =
        document.getElementsByClassName("iconfont");

      mindTitle[0].style.color = color;
      mobileMindLogo[0].style.color = color;
      for (let i = 0; i < LookCountAndCollctionLogo.length; i++) {
        LookCountAndCollctionLogo[i].style.color = color;
      }
    },
    //移动端大纲
    mobileIsOutline() {
      this.$nextTick(function () {
        let headerDom = document.getElementsByClassName(
          "content-header-user-message"
        );
        if (headerDom[0] == undefined) {
          return;
        }
        let mobileTerminal = navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        );
        if (mobileTerminal) {
          if (EditMindmapVM.mindDisplayType == MindDisplayType.Outline) {
            headerDom[0].style.backgroundColor = "#FFF2F2";
          } else {
            headerDom[0].style.backgroundColor = "#F9F9F9";
          }
        } else {
          if (isSharePageView()) {
            headerDom[0].style.backgroundColor = "#F0F0F0";
          } else {
            headerDom[0].style.backgroundColor = "transparent";
          }
          
        }
      });
    },
    isSharePageView() { //分享页面
        return window.location.href.match(/\/s\//i) == "/s/";
    },
    //导图播放
    mindmapPlay() {
      this.mindmapPlayPauseShowSetup({
        showSetup: false,
        playAccout: "SHOW_PLAY_SETUP",
      });
    },
    //当前导图模式
    getMindmapPattern() {
      return EditMindmapVM.mindDisplayType == MindDisplayType.MindMap
        ? true
        : false;
    },
  },
  watch: {
    "$store.state.showDaGangModels"(newHideModel, olHideModel) {
      if (newHideModel == false) {
        //this.titelAccout = 0;
      }
    },
    "$store.state.userIfLogin"(newUserIfLogin, olUserIfLogin) {
      this.newUserIfLogin = newUserIfLogin;
      this.doLoadUserInfo();
    },
    "$store.state.saveAndEditAccout"(newSaveAndEdit, olSaveAndEdit) {
      if (newSaveAndEdit == "saveSuccess") {
        this.editAccout.url = "check-circle";
        this.editAccout.text = getString(strings.Header_Mind_Tips_Update_Saved);
      } else if (newSaveAndEdit == "alreadyEdit") {
        this.editAccout.url = "exclamation-circle";
        this.editAccout.text = getString(strings.Header_Mind_Tips_Edit);
      }
    },
    shareUserData: function (newShareData, olShareData) {
      if (newShareData != null) {
        this.isShowMindStyle = true;
        this.routerData = "s";
        this.routerLogo = false;
        if (
          window.navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i // midp|iphone os|ipad|ucweb|windows mobile|
          )
        ) {
          this.checkAddIconColor(JSON.parse(newShareData.content).mindBGColor);
        }
        if (newShareData.collected) {
          this.colectionLogo = false;
        } else {
          this.colectionLogo = true;
        }
        this.canBeSaved = newShareData.canBeSaved;
      }
    },
    "$store.state.editMindmap"(newval, olval) {
      this.viewModel = newval;
    },
    "$store.state.initData"(newInitData, olInitData) {
      this.getHeaderMenuAccout(newInitData);
      //移动端如果为大纲
      if (this.routerData == "s") {
        this.mobileIsOutline();
      }
    },
  },
};
</script>
<style lang="less" scoped>
@font-face {
  font-family: "Outline";
  src: url("../../assets/font-icon/outline_Icon/iconfont.eot");
  src: url("../../assets/font-icon/outline_Icon/iconfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/font-icon/outline_Icon/iconfont.woff2") format("woff2"),
    url("../../assets/font-icon/outline_Icon/iconfont.woff") format("woff"),
    url("../../assets/font-icon/outline_Icon/iconfont.ttf") format("truetype"),
    url("../../assets/font-icon/outline_Icon/iconfont.svg#iconfont")
      format("svg");
}
.Outline {
  font-family: "Outline" !important;
  font-size: 24px;
  font-style: normal;
  color: #666666;
  margin-right: 10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "mindicon";
  src: url("../../assets/font-icon/mind_icon/iconfont.eot");
  src: url("../../assets/font-icon/mind_icon/iconfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/font-icon/mind_icon/iconfont.woff2") format("woff2"),
    url("../../assets/font-icon/mind_icon/iconfont.woff") format("woff"),
    url("../../assets/font-icon/mind_icon/iconfont.ttf") format("truetype"),
    url("../../assets/font-icon/mind_icon/iconfont.svg#iconfont") format("svg");
}
.mindicon {
  font-family: "mindicon" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #666666;
}

@font-face {
  font-family: "iconfont";
  src: url("../../assets/font-icon/font_share_save_ok/iconfont.eot"); /* IE9 */
  src: url("../../assets/font-icon/font_share_save_ok/iconfont.eot")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../../assets/font-icon/font_share_save_ok/iconfont.woff2")
      format("woff2"),
    url("../../assets/font-icon/font_share_save_ok/iconfont.woff2")
      format("woff"),
    url("../../assets/font-icon/font_share_save_ok/iconfont.ttf")
      format("truetype"),
    /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
      url("../../assets/font-icon/font_share_save_ok/iconfont.ttf#iconfont")
      format("svg"); /* iOS 4.1- */
}
@font-face {
  font-family: "iconfont";
  src: url("../../assets/font-icon/font_share_save_Icon/iconfont.eot"); /* IE9 */
  src: url("../../assets/font-icon/font_share_save_Icon/iconfont.eot")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../../assets/font-icon/font_share_save_Icon/iconfont.woff2")
      format("woff2"),
    url("../../assets/font-icon/font_share_save_Icon/iconfont.woff2")
      format("woff"),
    url("../../assets/font-icon/font_share_save_Icon/iconfont.ttf")
      format("truetype"),
    /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
      url("../../assets/font-icon/font_share_save_Icon/iconfont.ttf#iconfont")
      format("svg"); /* iOS 4.1- */
}
@font-face {
  font-family: "iconfont";
  src: url("../../assets/font-icon/crisps/iconfont.eot"); /* IE9 */
  src: url("../../assets/font-icon/crisps/iconfont.eot")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../../assets/font-icon/crisps/iconfont.woff2")
      format("woff2"),
    url("../../assets/font-icon/crisps/iconfont.woff2") format("woff"),
    url("../../assets/font-icon/crisps/iconfont.ttf") format("truetype"),
    /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
      url("../../assets/font-icon/crisps/iconfont.ttf#iconfont") format("svg"); /* iOS 4.1- */
}
@font-face {
  font-family: "iconfont";
  src: url("../../assets/font-icon/lookCountLogo/iconfont.eot"); /* IE9 */
  src: url("../../assets/font-icon/lookCountLogo/iconfont.eot")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../../assets/font-icon/lookCountLogo/iconfont.woff2")
      format("woff2"),
    url("../../assets/font-icon/lookCountLogo/iconfont.woff2") format("woff"),
    url("../../assets/font-icon/lookCountLogo/iconfont.ttf") format("truetype"),
    /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
      url("../../assets/font-icon/lookCountLogo/iconfont.ttf#iconfont")
      format("svg"); /* iOS 4.1- */
}
.iconfont {
  font-family: "iconfont" !important;
  font-size: 24px;
  font-style: normal;
  margin-right: 4px;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}
.icon-shoucang-befor {
  color: #f78e7b !important;
}
/** */
.content-header-keep-handsome {
  min-width: 1300px;
}
/**头部 */
.comlie-content {
  .content-header {
    margin-top: 0px;
    transition: 0.5s;
    height: 80px;
    display: flex;
    position: relative;
    background-color: #ffffff;
    justify-content: center;
    align-items: center;

    .content-header-log {
      position: absolute;
      left: 36px;
      top: 24px;
      bottom: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      .content-header-log-icon {
        cursor: pointer;
      }
      .content-header-text {
        width: auto;
        height: 22px;
        font-size: 16px;
        font-family: Noto Sans SC;
        font-weight: 400;
        line-height: 22px;
        color: #fd492b;
        opacity: 1;
        margin-left: 12px;
      }
    }
    .content-header-titel {
      display: flex;
      justify-content: center;
      .header-title-mind {
        width: 100px;
        height: 46px;
        opacity: 1;
        border-radius: 61px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        color: #000000;
        cursor: pointer;
      }
      .befor-title-mind {
        cursor: pointer;
        width: 100px;
        height: 46px;
        opacity: 1;
        border-radius: 61px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        background: #fd492b;
        color: #ffffff;
        transition: 0.5s;
      }
    }
    .content-header-right {
      /* border: 1px solid red; */
      display: flex;
      position: absolute;
      right: 36px;
      align-items: center;
      justify-content: center;
      .edit-accout {
        min-width: 150px;
        margin-right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        .save-button {
          padding: 4px;
          border-radius: 10px;
          cursor: pointer;
          margin-left: 10px;
          button {
            height: 28px;
            border-radius: 10px;
            padding: 2px 6px;
            background: transparent;
            color: #666666;
            border: none;
          }
        }
      }
      .content-header-dowload {
        min-width: 195px;
        margin-right: 20px;
        color: #fd492b;
        .content-header-dowload-page {
          cursor: pointer;
        }
      }
    }
    .content-header-storm-right {
      /* border: 1px solid red; */
      display: flex;
      position: absolute;
      right: 36px;
      align-items: center;
      justify-content: center;
      .content-header-dowload {
        color: #fd492b;
        .content-header-dowload-page {
          cursor: pointer;
        }
      }
    }
  }

  .content-header-user-message {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 100%;
    .user-head {
      .user-avatar {
        display: flex;
        justify-content: center;
        background-image: url(../../assets/img/user/crown.png);
        background-repeat: no-repeat;
        width: 32px;
        height: 42px;
        margin: 0 auto;
        position: relative;
        top: -4px;
        right: 5px;
        img {
          position: absolute;
          top: 12px;
          left: 1px;
        }
      }
    }
    .user-head div img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fd492b;
      margin-right: 10px;
      background-color: #dedede;
    }

    .head_avatar {
      position: relative;
      margin-right: 6px;
      margin-bottom: 12px;
      width: 32px;
      height: 42px;
    }

    .head_avatar_icon {
      position: absolute;
      left: 1px;
      top: 11px;
      z-index: 1;
    }
    .account-no-login {
      .ant-btn {
        /* background-color: #333 !important; */
        border: none;
        font-size: 16px;
        margin-top: 2px;
        width: 82px;
        font-family: Noto Sans SC;
        font-weight: 400;
        border-radius: 19px;
        color: #fd492b;
      }
      .ant-btn:hover {
        color: #ff7354;
        border: 2px solid #ff7354;
      }
      .ant-btn:focus {
        outline: none;
        color: #ffffff;
        background-color: #fd492b !important;
        border: none;
      }
    }
  }
  .userData {
    width: 100%;
    min-width: 1300px;
    height: 60px;
    background: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 400;
    .userData-box {
      margin: 0 auto;
      min-width: 840px;
      .content-header-user-message {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .complie-header-left {
          height: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          .mind-logo {
            display: none;
            font-size: 13px;
          }
          .creator-message {
            display: flex;
            justify-content: center;
            align-items: center;
            // position: absolute;
            // left: 0;
            .user-head-message {
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .user-head-border-member {
              display: flex;
              justify-content: center;
              background-image: url(../../assets/img/user/crown.png);
              background-repeat: no-repeat;
              width: 34px;
              height: 43px;
              margin: 0 auto;
              margin-top: -8px;
              margin-right: 10px;
              img {
                border: 1px solid #ffffff;
                border-radius: 50%;
                margin-top: 11px;
                margin-right: 0px;
                width: 34px;
                height: 34px;
                border-radius: 50%;
              }
            }
            .user-head-border {
              img {
                border: 1px solid #ffffff;
                border-radius: 50%;
                margin-top: 0px;
                margin-right: 10px;
                width: 34px;
                height: 34px;
                border-radius: 50%;
              }
            }
          }
        }
        .complie-header-right {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          min-width: 380px;
          .mindmap-or-outline {
            display: none;
          }
          .mindmap-play {
            display: flex;
            align-items: center;
            // margin-right: 10px;
            margin: 0px 10px;
            .mp-play {
              display: block;
              margin-right: 10px;
            }
          }
          .look-mindcount {
            display: flex;
            justify-content: center;
            align-items: center;
            // position: absolute;
            // right: 100px;
            img {
              width: 24px;
            }
            .share-mind-download {
              display: none;
            }
            .look-count-number {
              display: none;
            }
          }
          .collection-box {
            display: flex;
            justify-content: center;
            align-items: center;
            // position: absolute;
            // right: 0px;
            .collection {
              display: flex;
              align-items: center;
              img {
                width: 24px;
                margin-right: 10px;
              }
            }
          }
          .save-box {
            margin-right: 10px;
            .save {
              display: flex;
              align-items: center;
              .save-icon {
                margin: 0 10px 0 10px;
              }
            }
          }
        }
      }
    }
  }
  .mobile-mind-logo {
    display: none;
  }
  @media screen and(min-width:250px) and(max-width:1400px) {
    .iconfont {
      font-family: "iconfont" !important;
      font-size: 16px;
      font-style: normal;
      margin-right: 4px;
      -webkit-font-smoothing: antialiased;
      -webkit-text-stroke-width: 0.2px;
      -moz-osx-font-smoothing: grayscale;
    }
    .comlie-content {
      .userData {
        width: 100%;
        min-width: 0px;
        width: 100%;
        height: 60px;
        background: none;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        font-weight: 400;
        position: relative;
        .userData-box {
          margin: 0 auto;
          min-width: auto;
          width: 100%;
          .content-header-user-message {
            width: 100%;
            min-width: 250px;
            max-width: 1400px;
            display: flex;
            justify-content: space-between;
            .user-head div img {
              width: 26px;
              height: 26px;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #fd492b;
              margin-right: 8px;
              background-color: #dedede;
            }
            .user-head-name {
              width: 52px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 13px;
            }
            .complie-header-left {
              .mind-logo {
                display: none;
                justify-content: center;
                align-items: center;
                margin: 0;
                img {
                  width: 28px;
                  height: 28px;
                  margin-right: 9px;
                }
                span {
                  color: #666666;
                  font-weight: 400;
                  font-size: auto;
                  line-height: 28px;
                  margin-left: 6px;
                }
              }
              .creator-message {
                .creator-logo {
                  display: none;
                }
                .user-head-message {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-left: 10px;
                }
                .creator-name {
                  display: none;
                }
                display: flex;
                justify-content: center;
                align-items: center;
                .user-head-border {
                  img {
                    border: 1px solid #ffffff;
                    border-radius: 50%;
                  }
                }
              }
            }
            .complie-header-right {
              min-width: 100px;
              .mindmap-or-outline {
                display: block;
                // width: 60px;
                height: 22px;
                // background-color: #fd492b;
                color: #666666;
                border-radius: 19px;
                // margin-right: 10px;
                font-size: 14px;
                color: #ffffff;
                text-align: center;
                line-height: 22px;
              }
              //导图播放
              .mindmap-play {
                font-size: 13px;
                display: flex;
                justify-content: center;
                align-items: center;
                // margin-right: 0;
                margin: 0px 5px;
                span {
                  display: none;
                }
              }
              .look-mindcount {
                margin-right: 10px;
                display: none;
                .look-count-text {
                  display: none;
                }
                .look-count-number {
                  font-size: 13px;
                  display: block;
                }
                // display: flex;
                justify-content: center;
                align-items: center;
                img {
                  width: 24px;
                  margin-right: 8px;
                }
                .share-mind-download {
                  margin-top: -5px;
                  margin-left: 14px;
                  display: block;
                }
              }
              .collection-box {
                margin-right: 10px;
                display: none;
                .collection {
                  img {
                    width: 24px;
                    margin-right: 8px;
                  }
                }
              }
            }
          }
        }
      }
    }
    /**移动端输入密码 */
    .mobile-mind-logo {
      display: block;
      display: flex;
      justify-content: center;
      margin: 10px;
      img {
        width: 28px;
        height: 28px;
      }
      span {
        color: #666666;
        font-weight: 400;
        font-size: auto;
        line-height: 28px;
        margin-left: 6px;
      }
    }
    .mobile-display-none {
      display: none;
    }
  }
}
.complie-setMindData {
  height: 10px;
  width: 100%;
  position: absolute;
  z-index: 100;
}
</style>