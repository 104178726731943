<template>
  <fullscreen ref="fullscreen" @change="fullscreenChange">
    <!-- <div id="latex_katex"  style="position: absolute; z-index: -999;" ></div> -->
    <!-- tips提示 -->
    <div
      @click="allClick()"
      ondragstart="return false"
      class="left-contetn-box"
      @contextmenu.prevent="openMenu($event)"
      v-if="showTipsMessageAccout"
      :class="isMacComputed == false ? 'noMacComputed' : ''"
    >
      {{ tipsMessage }}
    </div>
    <!-- 导图 -->
    <div
      ref="canvasFather"
      class="canvas-father"
      ondragstart="return false"
      id="canvas-father"
      :class="canvasFullscreen ? 'canvas-father-top' : ''"
      :style="{
        width: fullWidth + 'px',
        height: fullHeight + 'px',
        top: canvasTop + 'px',
        position: 'absolute',
        overflow: 'auto',
      }"
    >
      <!-- icon弹框 -->
      <MenuPerSon
        v-if="showIconMenu"
        :showIconType="showIconType"
        :showIconX="showIconX"
        :showIconY="showIconY"
        :tintColor="tintColor"
        :mind="true"
        :rightMenuAccout="rightMenuAccout"
        @continuousShow="continuousShow"
        @hideIconMenu="hideIconMenu"
        @checkMenuIconElement="removeIconMenu"
      ></MenuPerSon>
      <!-- 字体样式设置 -->
      <MenuFontStyle
        v-if="showFontStyleMenu"
        :showFontStyleX="showFontStyleX"
        :showFontStyleY="showFontStyleY"
        :showFontStyleHeight="showFontStyleHeight"
        :showScale="showScale"
        :screenRect="screenRect"
        :mind="true"
        :rightMenuAccout="rightMenuAccout"
        @hideFontStyle="hideFontStyle"
        @continuousFontStyleShow="continuousFontStyleShow"
        @hideFontStyleShow="hideFontStyleShow"
      ></MenuFontStyle>
      <!-- 保存状态 -->
      <transition name="slide-fade">
        <div
          class="edit-accout"
          ondragstart="return false"
          v-show="
            (routerData == 'Complie' ||
              (routerData == 'gmap' && MindPageType == 'groupMapEdit') ||
              (routerData == 'arvhiveMap' &&
                MindPageType == 'arvhiveMapEdit')) &&
            editAccout.url != ''
          "
        >
          <div v-show="editAccout.url != ''">
            <a-icon :type="editAccout.url" />
          </div>
          <div style="margin-left: 5px; margin-top: -2px">
            {{ editAccout.text }}
          </div>
          <div
            class="save-button"
            v-show="editAccout.url != 'check-circle' && editAccout.url != ''"
            :title="
              $getStringObj.getString($Strings.Public_Header_Save) +
              saveMapShortcut
            "
          >
            <a-button type="primary" @click="saveMindData">{{
              getString(strings.Public_Header_Save)
            }}</a-button>
          </div>
        </div>
      </transition>
      <!-- 左边列表子导图模式 -->
      <div
        :class="isDarkColor ? 'menu-left-menu-color' : 'menu-left-menu-scale'"
        :style="{}"
        ondragstart="return false"
      >
        <div
          class="menu-left-menu"
          id="menuLeftMenu"
          :class="getComlieLeftMenuClass()"
          :style="{
            width: 216 + 'px',
            height: sheetLeftFullHeight + 'px',
            backgroundColor: sheetBarColor,
          }"
        >
        <!-- <div id="leftShapeMenu"
          v-if="this.showLeftShapeView" >
            <ComplieChildMenuForm 
            :flowChartLeftMenu="true"
            />
        </div> -->
        <div id="leftSheetMenu"
        v-if="this.showLeftSheetView">
          <div class="left-search">
            <a-button
              class="search-button"
              shape="circle"
              icon="search"
              size="small"
              @click="searchButton()"
            />
            <input
              class="search-box"
              type="text"
              v-model="searchText"
              :placeholder="$getStringObj.getString($Strings.Search_Pagination)"
              v-on:keyup.enter="searchButton()"
              @focus="useShortcut = false"
              @blur="useShortcut = true"
            />
            <!-- <span
            class="cleariconfont clear-search-text-icon"
            v-if="searchText != ''"
            @click="clearSearchText()"
            >&#xe605;</span
            > -->
          </div>
          <div
            class="left-sheet-box-overflow-father"
            :style="{
              width: 216 + 'px',
              height: sheetLeftFullHeight - 50 + 'px',
            }"
          >
            <div
              v-if="
                $route.name == 'Complie' ||
                (routerData == 'gmap' && MindPageType == 'groupMapEdit') ||
                (routerData == 'arvhiveMap' && MindPageType == 'arvhiveMapEdit')
              "
              class="left-sheet-box-overflow"
              :style="{
                width: 216 + 'px',
                height: sheetLeftFullHeight - 50 + 'px',
              }"
            >
              <div
                class="left-sheet-box"
                v-for="(item, index) in initSearchMindSheetDatas"
                :key="index"
                v-dragging="{
                  list: initSearchMindSheetDatas,
                  item: item,
                  group: 'sheetListLeftGroup',
                }"
                :class="
                  showIndex == index
                    ? 'left-sheet-box-click-select'
                    : 'left-sheet-box-click'
                "
              >
                <div class="left-sheet-box-spinning" v-show="spinningSmall">
                  <a-spin
                    class="loading-child"
                    tip="Loading..."
                    size="small"
                    :spinning="showIndex == index ? spinningSmall : false"
                    :delay="500"
                  >
                  </a-spin>
                </div>
                <div class="left-sheet-box-index">
                  <span>{{ item.index + 1 }}</span>
                </div>
                <div
                  class="left-sheet-box-cover-text"
                  @click="clickSwitchSheet(item, index)"
                >
                  <div
                    class="left-sheet-box-cover"
                    :style="
                      'width: 167px;height: 94px;background:url(' +
                      httpImageToPrefix(item.cover) +
                      ') no-repeat center center;background-size: 180px 180px;background-position:center;overflow:hidden;'
                    "
                  ></div>
                  <div class="left-sheet-box-text">
                    {{ item.name }}
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              class="left-sheet-box-overflow"
              :style="{
                width: 266 + 'px',
                height: sheetLeftFullHeight - 50 + 'px',
              }"
            >
              <div
                class="left-sheet-box"
                v-for="(item, index) in initSearchMindSheetDatas"
                :key="index"
                :class="
                  showIndex == index
                    ? 'left-sheet-box-click-select'
                    : 'left-sheet-box-click'
                "
              >
                <div class="left-sheet-box-spinning" v-show="spinningSmall">
                  <a-spin
                    class="loading-child"
                    tip="Loading..."
                    size="small"
                    :spinning="showIndex == index ? spinningSmall : false"
                    :delay="500"
                  >
                  </a-spin>
                </div>
                <div class="left-sheet-box-index">
                  <span>{{ item.index + 1 }}</span>
                </div>
                <div
                  class="left-sheet-box-cover-text"
                  @click="clickSwitchSheet(item, index)"
                >
                  <div
                    class="left-sheet-box-cover"
                    :style="
                      'width: 167px;height: 94px;background:url(' +
                      httpImageToPrefix(item.cover) +
                      ') no-repeat;background-size: 180px 180px;background-position:center;overflow:hidden;'
                    "
                  ></div>
                  <div class="left-sheet-box-text">
                    {{ item.name }}
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
     <!-- 底部拦开始 -->
      <div
        id="checkMenuColor-scale-menu"
        :class="isDarkColor ? 'checkMenuColor' : 'scale-menu'"
        v-show="isShowBottomMenu"
        :style="{ backgroundColor: sheetBarColor }"
      >
        <div
          v-show="topSheetListShow"
          class="sheet-top-box"
          :style="{ position: 'absolute', width: sheetTopFullWidth + 'px' }"
        >
          <div
            class="sheet-top-box-look"
            @click.stop="clickLookSheet(false)"
            v-show="showLookSheetOne"
          >
            <!-- <div class="wrapping-path-div">&#xe63c;</div> -->
            <img
              v-if="isDarkColor"
              style="height: 16px; width: 16px"
              src="./../../assets/img/mindmap_play/lookSheet.png"
            />
            <img
              v-else
              style="height: 16px; width: 16px"
              src="./../../assets/img/mindmap_play/lookSheet_1.png"
            />
          </div>
          <div
            class="sheet-top-box-look"
            @click.stop="clickLookSheet(true)"
            v-show="showLookSheetTow"
          >
            <img
              style="height: 16px; width: 16px"
              src="./../../assets/img/mindmap_play/lookSheetTow.png"
            />
          </div>
          <div class="sheet-top-box-total"
            @click.stop="clickLookSheet(false)"
            >
            {{ sheetTotal }}
          </div>
          <div
            v-if="isLeftScrollButton"
            class="sheet-top-box-leftslip"
            @click.stop="scrollLeftSheet()"
            style=""
          >
            <img
              v-if="isDarkColor"
              style="height: 12px; width: 12px"
              src="./../../assets/img/mindmap_play/leftSlipTow.png"
            />
            <img
              v-else
              style="height: 12px; width: 12px"
              src="./../../assets/img/mindmap_play/leftSlip_1.png"
            />
          </div>
          <div v-else class="sheet-top-box-leftslip" style="">
            <img
              v-if="isDarkColor"
              style="height: 12px; width: 12px"
              src="./../../assets/img/mindmap_play/leftSlip.png"
            />
            <img
              v-else
              style="height: 12px; width: 12px"
              src="./../../assets/img/mindmap_play/leftSlipTow_1.png"
            />
          </div>
          <div
            v-if="
              $route.name == 'Complie' ||
              (routerData == 'gmap' && MindPageType == 'groupMapEdit') ||
              (routerData == 'arvhiveMap' && MindPageType == 'arvhiveMapEdit')
            "
            v-clickoutside="outsideCloseSheetTopMenu"
            class="sheet-top-box-list"
            ref="sheetScrollRef"
            id="sheetScroll"
            :style="{ maxWidth: sheetTopFullWidth - 150 + 'px' }"
          >
            <div
              v-for="(item, index) in initMindSheetDatas"
              :key="index"
              @click.stop="clickSwitchSheet(item, index)"
              @contextmenu.prevent="reightMenuSheetClick(item, index, $event)"
              v-dragging="{
                list: initMindSheetDatas,
                item: item,
                group: 'sheetListGroup',
              }"
              class="sheet-top-box-name"
              :class="
                showIndex == index
                  ? 'class-sheet-click-select'
                  : 'class-sheet-click'
              "
              style=""
            >
              {{ item.name }}
            </div>
          </div>
          <div
            v-else
            class="sheet-top-box-list"
            ref="sheetScrollRef"
            id="sheetScroll"
            :style="{ maxWidth: sheetTopFullWidth - 150 + 'px' }"
          >
            <div
              v-for="(item, index) in initMindSheetDatas"
              :key="index"
              @click.stop="clickSwitchSheet(item, index)"
              :class="
                showIndex == index
                  ? 'class-sheet-click-select'
                  : 'class-sheet-click'
              "
              class="sheet-top-box-name"
              style=""
            >
              <span>{{ item.name }}</span>
            </div>
          </div>
          <!-- 右键菜单 -->
          <CompileMySheetContent
            :reightMenuX="reightMenuX"
            :moveSheetMenu="moveSheetMenu"
            :item="sheetNameObj"
            :index="reightMenuIndex"
            @hiddenSheetTopReightMenu="hiddenSheetTopReightMenu"
          />
          <div
            v-if="isReightScrollButton"
            class="sheet-top-box-right-spli"
            @click.stop="scrollReightSheet()"
          >
            <img
              v-if="isDarkColor"
              style="height: 12px; width: 12px"
              src="./../../assets/img/mindmap_play/rightSlipTow.png"
            />
            <img
              v-else
              style="height: 12px; width: 12px"
              src="./../../assets/img/mindmap_play/rightSlipTow_1.png"
            />
          </div>
          <div v-else class="sheet-top-box-right-spli">
            <img
              v-if="isDarkColor"
              style="height: 12px; width: 12px; opacity: 0.2"
              src="./../../assets/img/mindmap_play/rightSlipTow.png"
            />
            <img
              v-else
              style="height: 12px; width: 12px"
              src="./../../assets/img/mindmap_play/rightSlip_1.png"
            />
          </div>
          <div
            class="sheet-top-box-created"
            @click="clickCreated(-1)"
            v-if="
              $route.name == 'Complie' ||
              (routerData == 'gmap' && MindPageType == 'groupMapEdit') ||
              (routerData == 'arvhiveMap' && MindPageType == 'arvhiveMapEdit')
            "
          >
            <img
              v-if="isDarkColor"
              style="height: 12px; width: 12px"
              src="./../../assets/img/mindmap_play/establish.png"
            />
            <img
              v-else
              style="height: 12px; width: 12px"
              src="./../../assets/img/mindmap_play/establishS.png"
            />
          </div>
        </div>
        <section
          class="zoom-function"
          :style="{ right: isNowPhone ? 0 : '10px' }"
        >
          <div
            class="each-scale-box add-scale-box"
            :class="isNowPhone ? 'each-scale-box-Phone' : ''"
            @click="pantograph(true)"
            @mouseenter="getKeyboard(KeyboardValueType.ENLARGE_MAP)"
          >
            <div class="active-show-background"></div>
            <span class="iconfont-scale center-icon-box">&#xe606;</span>
            <!-- 放大 -->
            <div class="bottom-gray-text-content">
              {{ $getStringObj.getString($Strings.Mind_Enlarge) }}
              <template v-if="!isNowPhone">
                [{{ keyboard.ENLARGE_MAP.replace("Command", "⌘") }}]
              </template>
            </div>
          </div>

          <div
            v-if="editmapScrollView != null"
            class="scale-number-show-box"
            :class="
              isNowPhone ? 'each-scale-box-Phone' : 'each-scale-box-no-Phone'
            "
          >
            {{ parseInt(editmapScrollView.scale * 100) }}%
          </div>

          <div
            class="each-scale-box lost-scale-box"
            :class="
              isNowPhone ? 'each-scale-box-Phone' : 'each-scale-box-no-Phone'
            "
            @click="pantograph(false)"
            @mouseenter="getKeyboard(KeyboardValueType.SHRINK_MAP)"
          >
            <div class="active-show-background"></div>
            <span class="iconfont-scale center-icon-box">&#xe605;</span>
            <!-- 缩小 -->
            <div class="bottom-gray-text-content">
              {{ $getStringObj.getString($Strings.Mind_Narrow) }}
              <template v-if="!isNowPhone">
                [{{ keyboard.SHRINK_MAP.replace("Command", "⌘") }}]
              </template>
            </div>
          </div>

          <div
            class="each-scale-box center-canvas-box"
            :class="
              isNowPhone ? 'each-scale-box-Phone' : 'each-scale-box-no-Phone'
            "
            @click="centeredViewModal()"
          >
            <div class="active-show-background"></div>
            <span class="iconfont-scale center-icon-box">&#xe603;</span>
            <!-- 居中 -->
            <div class="bottom-gray-text-content">
              {{ $getStringObj.getString($Strings.Mind_Center) }}
            </div>
          </div>

          <div
            class="each-scale-box full-screen-box"
            :class="
              isNowPhone ? 'each-scale-box-Phone' : 'each-scale-box-no-Phone'
            "
            @click="fullScreen"
          >
            <template v-if="canvasFullscreen">
              <div class="active-show-background"></div>
              <span class="iconfont-fullSucreen center-icon-box">&#xe60b;</span>
              <!-- 恢复 -->
              <div class="bottom-gray-text-content">
                {{ $getStringObj.getString($Strings.Mind_Edit_Restore) }}
                <template v-if="!isNowPhone"> [Esc] </template>
              </div>
            </template>
            <template v-else>
              <div class="active-show-background"></div>
              <span class="iconfont-fullSucreen center-icon-box">&#xe60a;</span>
              <!-- 全屏 -->
              <div class="bottom-gray-text-content">
                {{ $getStringObj.getString($Strings.Mind_Full_Screen) }}
                <template v-if="!isNowPhone"> [F11] </template>
              </div>
            </template>
          </div>
        </section>
      </div>
      <!-- 底部拦end -->

      <div
        class="title-float-popup"
        :class="
          showSheetLeft ? 'title-float-popup-after' : 'title-float-popup-befor'
        "
        v-show="showTitleFloatPopup"
      >
        <div class="title-float-colse-img">
          <img
            class="close-img"
            src="./../../assets/img/popupsImg/upgrade-colse.svg"
            @click.stop="colseTitleFloatPopup"
          />
        </div>
        <div @click.stop="getTitleFloatPopup" class="title-float-upgrade-img">
          <img
            class="upgrade-img"
            style="width: 232px"
            src="./../../assets/img/popupsImg/upgrade.png"
          />
        </div>
        <div class="title-float-upgrade-name">
          <span style="font-weight: bold; color: #ffffff; font-size: 21px">{{
            getString(strings.Upgrade_To_Professional)
          }}</span>
        </div>
      </div>
      <ComplieMoveInModel
        @hideMoveInSheet="hideMoveInSheet"
        v-if="showMoveInSheet"
      />
      <!-- 菜单 -->
      <ComplieLeftContentMindMenu
        :newMenuX="newMenuX"
        :newMenuY="newMenuY"
        :fullHeight="fullHeight"
        :fullWidth="fullWidth"
        :isExplain="isExplain"
        :showElementMenuList="showElementMenuList"
        :hiddenMindButtonArray="hiddenMindButtonArray"
        :MindMenuType="MindMenuType"
        @checkMenuElement="checkMenuElement"
      />
      <ComplieChildMenuForm
        ref='newNodeChangeShape'
        id="newNodeChangeShape"
        style="position: absolute; z-index: 999;"
        :newNodeChangeShape="true"
        v-if="showLeftShapeView"
      />
      <!-- 任务进度相关菜单 -->
      <ComplieLeftContentMindTaskContentMenu
        :newMenuX="newMenuX"
        :newMenuY="newMenuY"
        :fullHeight="fullHeight"
        :fullWidth="fullWidth"
        :showTaskMenuList="showTaskMenuList"
        :MindTaskMenuType="MindTaskMenuType"
        @checkMenuTask="checkMenuTask"
      />

      <!-- LaTeX公式右键菜单 -->
      <ComplieLeftContentMindLaTeXContentMenu
        :newMenuX="newMenuX"
        :newMenuY="newMenuY"
        :fullHeight="fullHeight"
        :fullWidth="fullWidth"
        :showLaTeXMenuList="showLaTeXMenuList"
        :MindLaTeXMenuType="MindLaTeXMenuType"
        @checkMenuLaTeX="checkMenuLaTeX"
      />

      <ComplieLeftContentIconMenu
        :showElementIconMenuList="showElementIconMenuList"
        :newMenuX="newMenuX"
        :newMenuY="newMenuY"
        :showLinkMenu="showLinkMenu"
        :abstractMenu="abstractMenu"
        :moreMenu="moreMenu"
        :moreNodeId="moreNodeId"
        :showAssMapLink="showAssMapLink"
        :showResourceModel="showResourceModel"
        :MindPageType="MindPageType"
        @checkMenuIconElement="checkMenuIconElement"
      />
      <ComplieLeftContentLineMenu
        :newMenuX="newMenuX"
        :newMenuY="newMenuY"
        :fullHeight="fullHeight"
        :fullWidth="fullWidth"
        :showRelevanceLineMenu="showRelevanceLineMenu"
        :showEncircleMenu="showEncircleMenu"
        :hiddenBottonArray="hiddenBottonArray"
        @checkMenuLineElement="checkMenuLineElement"
      >
      </ComplieLeftContentLineMenu>
      <div v-if="lineColorColorAccout" class="menu-right-bg" :style="{'left' : newMenuX + 'px', 'top': newMenuY + 'px'}">
            <CommonBgColor style="position: absolute;"/>
      </div>
      <ComplieFreeNodeMenu
        :newMenuX="newMenuX"
        :newMenuY="newMenuY"
        :isShowFreeNodeMenu="isShowFreeNodeMenu"
        @clickFreeNodeMenuOption="clickFreeNodeMenuOption"
      />

      <ComplieCreatedSheetMind
        :moveSheetFile="showCreatedSheetMind"
        :rootId="$route.query.id"
        :afterIndex="afterIndex"
        :sheetName="sheetName"
        :groupGmapId="groupGmapId"
        @hideMoveFile="hideCreatedBox"
      />
      <EditTaskMenu
        :dateNode="dataNode"
        :fullHeight="fullHeight"
        :fullWidth="fullWidth"
        :showEditTask="showEditTask"
      />
      <FindTextView
        :dateNode="dataNode"
        :fullHeight="fullHeight"
        :fullWidth="fullWidth"
        :showFindText="showFindTextView"
      />
      <!-- latex输入页面 -->
      <EditLatexMenu
        v-if="showEditLatex"
        :fullHeight="fullHeight"
        :fullWidth="fullWidth"
        :showEditLatex="showEditLatex"
        @hideLatex="hideLatex"
      />
      <!-- 输入分页子导图name -->
      <a-modal
        v-model="sheetCreatedModal"
        :ok-text="getString(strings.Global_Ok)"
        :cancel-text="getString(strings.Global_Cancel)"
        :centered="true"
        @ok="hideSheetCreatedModal"
        @cancel="hideSheetNameCancel"
      >
        <div class="file-color-class"></div>
        <a-input
          ref="sheet_name"
          v-model="sheetName"
          @focus="useShortcut = false"
          @blur="useShortcut = true"
          v-on:keyup.enter="hideSheetCreatedModal()"
          :placeholder="getString(strings.Please_Enter_A_Paging_Name)"
        />
      </a-modal>
      <!-- 输入密码-->
      <a-modal
        v-model="mindPasswordPanelModal"
        :ok-text="getString(strings.Global_Ok)"
        :cancel-text="getString(strings.Global_Cancel)"
        :centered="true"
        @ok="hideMindPasswordPanelModal"
        @cancel="hideMindPasswordPanelModalCancel"
      >
        <div class="file-color-class"></div>
        <a-input
          ref="mind_password_input"
          v-model="mindPassword"
          type="password" 
          @focus="useShortcut = false"
          @blur="useShortcut = true"
          v-on:keyup.enter="hideMindPasswordPanelModal()"
          :placeholder="mindPasswordPlaceholder"
        />
      </a-modal>
      <SpinningAnimation :spinning="spinning" />

      <SetStorageSpaceUpgradeFile
        v-if="spaceUpgradeModalShow"
        :spaceUpgradeModalShow="spaceUpgradeModalShow"
        :groupGmapId="groupGmapId"
        @spaceUpgradeCklickAlter="spaceUpgradeCklickAlter"
      />

      <!-- 付费模态框 -->
      <a-modal
        class="report-member-model"
        :centered="true"
        v-model="storageIsMeberModelAccout"
        :title="getString(strings.Mind_Edit_Join_Membership)"
        :cancelText="getString(strings.Global_Cancel)"
        :okText="getString(strings.Mind_Edit_Upgrade)"
        @cancel="storageIsMeberCancelHandleOk"
        @ok="storageIsMeberDeleteHandleOk"
      >
        <div class="report-crisps-style-box">
          <div
            class="report-crisps-style"
            style="
              font-size: 18px;
              font-family: Noto Sans SC;
              text-align: center;
              display: block;
              margin-top: -25px;
            "
          >
            {{ getString(strings.Insufficient_Storage_Space) }}
            <span
              v-if="routerData == 'gmap'"
              style="display: block; margin-top: -20px; font-size: 16px"
            >
              提示:您的组云存储空间已满，无法保存创作内容
            </span>
            <span
              v-else
              style="display: block; margin-top: -20px; font-size: 16px"
            >
              提示:您的云存储空间不足1M，继续操作会导致无法保存创作内容
            </span>
          </div>
        </div>
      </a-modal>
      <transition name="slide-fade" mode="out-in">
        <MindmapPlaySetupModal v-if="isShowMindmapPlaySetupModal" />
      </transition>
      <transition>
        <ComplieShowVideo />
      </transition>
    </div>
    <!-- <地步> -->
    
    <!-- v-katex="latexKatex" -->
  </fullscreen>
</template>
<script>
import httpImageToPrefix from "../../utils/httpImageToPrefix";
import ComplieCreatedSheetMind from "./ComplieCreatedSheetFile/ComplieCreatedSheetMind";
import CompileMySheetContent from "./ComplieCreatedSheetFile/CompileMySheetContent";

//渲染的子组件
// import SvgChildElement from './SvgChildElement'
import HashMap from "../../viewmodel/core/base/HashMap";
import MindElementType from "../../viewmodel/datatype/MindElementType";

import MainSubjectInputView from "../../views/editmap/elementviews/MainSubjectInputView";
import SubjectInputView from "../../views/editmap/elementviews/SubjectInputView";
import SonSubjectInputView from "../../views/editmap/elementviews/SonSubjectInputView";
import FloatExplainView from "../../views/editmap/elementviews/FloatExplainView";
import SubjectLineView from "../../views/editmap/elementviews/SubjectLineView";
import SonSubjectLineView from "../../views/editmap/elementviews/SonSubjectLineView";
import StatisticsElementView from "../../views/editmap/mindwidget/StatisticsElementView";
import HandwritingView from "../../views/editmap/elementviews/HandwritingView";
import ComplieChildMenuForm from "./ComplieRightMenuChild/ComplieChildMenuForm";

import NodeConnectLineView from "../../views/editmap/elementviews/NodeConnectLineView";
import LeftBraceLineView from "../../views/editmap/elementviews/LeftBraceLineView";
import EncircleLineView from "../../views/editmap/elementviews/EncircleLineView";
import TimeLineView from "../../views/editmap/elementviews/TimeLineView";
import GeneralizationView from "../../views/editmap/elementviews/GeneralizationView";
import FormLineView from "../../views/editmap/elementviews/FormLineView";
import ScrollView from "./tools/ScrollView";

import VueDataEvent from "../../core/core/basemode/VueDataEvent";

import NodeLayoutType from "../../viewmodel/datatype/NodeLayoutType";
import ResetMindType from "./tools/ResetMindType";
import MindDisplayType from "../../viewmodel/datatype/MindDisplayType";
import EditMindmapVM from "../../viewmodel/mindelementdata/vm/EditMindmapVM";

import LineLayout from "../../viewmodel/datatype/LineLayout";
import Colors from "../../utils/Colors";
import { mapMutations, mapState } from "vuex";
//测试图标需要引入

//测试图片需要引入
import Config from "../../core/core/calcule/Config";
import UiUtil from "../../utils/UiUtil";
//背景
import MindBGStripesType from "../../viewmodel/datatype/MindBGStripesType";
import StylePanelData from "../../components/complieComponents/edit/StylePanelData";
import MindmapPlaySetupModal from "./ComplieHeader/MindmapPlaySetupModal";

import {
  postBaseGlobalConfig,
  postMindmapListSheets,
} from "../../common/netWork/networkcache";

import fullscreen from "vue-fullscreen";

import ToImage from "../../utils/ToImage";
import imageToStyle from "../../utils/ImageToolkit";
import {
  postMindmapUploadImage,
  postMindmapDeleteImage,
  postMindmapSearchSheet,
  postMindmapDeleteSheet,
  postMindmapModifySheet,
  postMindmapCopySheet,
  postMindmapMoveSheet,
  postMindmapDeleteAttachments,
  postMindmapExportSheet,
  postMindmapMigrateSheet,
} from "../../common/netWork/mind_map_api";

import {
  postGroupEditGmap,
  postGroupGmapEditPing,
  postGroupGmapEditFinish,
} from "../../common/netWork/group_api";
import {
  postCrispsListCsheets,
  postCrispsSearchCsheet,
} from "../../common/netWork/crisps_api";

import publicModalStyle from "../../assets/css/publicModalStyle/publicModalStyle.less";

import MindOperateUIControllerView from "./tools/MindOperateUIControllerView";
import ComplieLeftContentMindMenu from "./ComplieLeftContentChild/ComplieLeftContentMindMenu";
import ComplieLeftContentIconMenu from "./ComplieLeftContentChild/ComplieLeftContentIconMenu";
import ComplieLeftContentLineMenu from "./ComplieLeftContentChild/ComplieLeftContentLineMenu";
import CommonBgColor from "../common/CommonBgColor/CommonBgColor";
import ComplieLeftContentMindTaskContentMenu from "./ComplieLeftContentChild/ComplieLeftContentMindTaskContentMenu";
import ComplieLeftContentMindLaTeXContentMenu from "./ComplieLeftContentChild/ComplieLeftContentMindLaTeXContentMenu";
import EditTaskMenu from "./ComplieLeftContentChild/EditTaskMenu";
import FindTextView from "./ComplieLeftContentChild/FindTextView";
import EditLatexMenu from "./ComplieLeftContentChild/EditLatexMenu";
import ComplieFreeNodeMenu from "./ComplieLeftContentChild/ComplieFreeNodeMenu";
import MenuPerSon from "./iconMenu/MenuPerSon";
import Vue from "vue";
import VueDND from "awe-dnd";
import Point from "../../viewmodel/core/base/Point";
import {
  postTemplateGet,
  postTemplateListTsheets,
  postTemplateSearchTsheet,
} from "../../common/netWork/template_api";
import JsExtend from "../../utils/JsExtend";
import colorChangeClass from "../../utils/ColorToSystem";
import strings from "../../common/lang/strings";
import getString from "../../common/lang/language";
import CGPoint from "../../viewmodel/core/base/basedata/CGPoint";
import CGRect from "../../viewmodel/core/base/basedata/Rect";
import Size from "../../viewmodel/core/base/Size";
import MindCopyManager from "../../core/core/common/MindCopyManager";
import MindMapPlayStatus from "../../viewmodel/datatype/MindMapPlayStatus";
import IdGenerator from "../../viewmodel/core/base/IdGenerator";
import MenuFontStyle from "./FontStyle/MenuFontStyle";
import { postUserStorageInfo } from "../../common/netWork/networkcache";
import {
  postGroupGstorageInfo,
  postGroupListGsheets,
  postGroupSearchGsheet,
  postGroupCopyGsheet,
  postGroupModifyGsheet,
  postGroupMoveGsheet,
  postGroupDeleteGsheet,
} from "../../common/netWork/group_api";
import SpinningAnimation from "./../spinningAnimation/SpinningAnimation";
import MindSettingManager from "../../viewmodel/core/tools/setting/MindSettingManager";
import KeyboardValueType from "../../viewmodel/core/tools/setting/KeyboardValueType";
import DragImgFileAppendCanvas from "./tools/DragImgFileAppendCanvas";
import ImageFileUpload from "./tools/ImageFileUpload";

import SetStorageSpaceUpgradeFile from "../groupManagementChildren/groupFile/SetStorageSpaceUpgradeFile";

import Util from "../../utils/Util";
import ConnectLineType from "../../viewmodel/datatype/ConnectLineType";
import ConfigurationItem from "../../assets/js/ConfigurationItem.js";
const { numberOfMaps } = ConfigurationItem;
import ComplieShowVideo from "./ComplieShowVideo/ComplieShowVideo";
import VideoStatusData from "../../viewmodel/core/minddata/VideoStatusData";
import "../../../node_modules/katex/dist/katex.min.css";

import katex from "katex";
import { getJwt, openSystemUrl } from "../../common/netWork/base";
import html2canvas from "html2canvas";
// import domtoimage from 'dom-to-image';
import {
  isClickSaveMapButton,
  ocrScanExtractTypes,
  sessionStorageKey,
  saveImgModes,
} from "../../assets/js/GlobalVariable.js";
import ComplieMoveInModel from "./ComplieHeader/ComplieAddModel/ComplieMoveInModel";
import MindmapDataRouter from '../../viewmodel/facade/MindmapDataRouter';
import LoadLocalXJMind from '../../viewmodel/core/tools/mapfile/LoadLocalXJMind';
import NewXJMindMap from '../../core/core/dataformat/fileImport/NewXJMindMap';
import MindMe from '../../viewmodel/facade/MindMe';
import MindType from '../../viewmodel/datatype/MindType';
import MoveMindElementDataInfo from '../../core/core/basemode/MoveMindElementDataInfo';
import NodeConnectLineCalcul from '../../core/core/calcule/NodeConnectLineCalcul';
import FlowChartDirection from '../../viewmodel/datatype/FlowChartDirection';
import HandwritingType from '../../viewmodel/datatype/HandwritingType';

if (
  typeof navigator === "object" &&
  typeof navigator.userAgent === "string" &&
  navigator.userAgent.indexOf("Electron") >= 0
) {
  var { ipcRenderer, remote } = window.require("electron");
}

Vue.use(fullscreen);
Vue.use(VueDND);
let keyName = "";

export default {
  inject: ["reload"],
  components: {
    ComplieLeftContentMindMenu,
    ComplieLeftContentIconMenu,
    ComplieLeftContentLineMenu,
    ComplieLeftContentMindTaskContentMenu,
    ComplieLeftContentMindLaTeXContentMenu,
    ComplieFreeNodeMenu,
    MenuPerSon,
    MindmapPlaySetupModal,
    MenuFontStyle,
    ComplieCreatedSheetMind,
    CompileMySheetContent,
    SpinningAnimation,
    SetStorageSpaceUpgradeFile,
    ComplieShowVideo,
    EditTaskMenu,
    FindTextView,
    EditLatexMenu,
    ComplieMoveInModel,
    ComplieChildMenuForm,
    CommonBgColor,
    // domtoimage,
  },
  data() {
    return {
      latexKatex: "",
      editmapScrollView: null,
      editMindmapContent: null,
      mouseY: 0, //鼠标的x坐标
      mouseX: 0, //鼠标的y坐标
      scale: 0,
      viewElementList: new HashMap(),
      lineColorColorAccout: false,
      styleIndex: 1,
      mindDetailsID: -1,
      mindPreviewID: -1,
      mindType: 1,
      globalLayout: NodeLayoutType.LAYOUT_VERTICAL,
      MindDisplayType: MindDisplayType.MindMap,
      LineLayout: LineLayout.STRAIGHT_LINE,
      fullHeight: document.documentElement.clientHeight - 88, //中间内容部分的高度
      fullWidth: document.documentElement.clientWidth - 291, //中间内容部分的高度
      showLeftShapeView: false,
      showLeftSheetView: true,
      rightShowMenu: true, //右侧菜单是否展开
      rightMenuAccout: true, //用于判断右侧菜单展开状态
      //背景
      DrawingBoardBackgroundColor: null,
      StylePanelData: new StylePanelData(),
      textContent: {
        textColor: "51, 51, 51",
        textFontSize: null,
        textBold: null,
        textItalics: null,
        textStrikethrough: null,
      },
      textStyleAccoutId: null,
      tipsMessage: null,
      showTipsMessageAccout: false,

      showElementIconMenuLastTime:0,
      showElementMenuList: false, //是否展示菜单
      hiddenMindButtonArray: [], //隐藏的按钮数组
      MindMenuType: "mind", //菜单的类型

      showTaskMenuList: false, //是否展示任务进度相关菜单
      MindTaskMenuType: "completion", //任务进度相关菜单的类型

      showLaTeXMenuList: false, //latex右键菜单展示
      MindLaTeXMenuType: "edit",

      newMenuX: 0, //新的x坐标
      newMenuY: 0, //新的y坐标
      saveTimeUilt: null,
      entityId: null,
      cover: null,
      lastDblclickMindData: null, //上一次双击保存的数据
      lastClickMindData: null, //上一次单击保存的数据
      showElementIconMenuList: false, //展示图标菜单
      showLinkMenu: false, // 是否展示链接菜单
      iconNode: null, //选中的图标的数据
      canvasFullscreen: false, //全屏
      showRelevanceLineMenu: false, //是否展示连接线的菜单。
      showEncircleMenu: false, //是否展示包裹线
      isShowFreeNodeMenu: false, //展示添加自由节点菜单
      hiddenBottonArray: [], //隐藏的按钮类型数组
      abstractMenu: false, //是否展示备注
      showAssMapLink: false, //是否展示关联导图链接菜单
      mapEvent: null, // 点击画布获取的event
      selectMindView: null,
      coverTimeOut: null, //截图
      initMindDatas: null, //导图创建导图数据
      initMindGroupViewDatas: null, //组导图查看数据
      initTemplateDatas: null, //tempalte
      initShareMindDatas: null, //share
      initCrispsMindData: null, //crisps
      isTemplatePage: false, //是否是模板
      isSharePage: false, //是否是分享
      isNowPhone: false, // 当前是否是手机
      isDarkColor: false, //当前是否是深色图标。 是否是深色辅助线
      baoweiMessage: null, //包围状态
      baoweiMessageToFreeNode: null, //包裹状态To自由节点
      defaultTop: 68, //默认距离顶部的距离
      canvasTop: 66, //距离顶部的距离。
      canvasTopPattern1: 66, //用于存储距离顶部的距离。避免退出全屏的时候距离顶部距离消失了，这个值在获取到导图数据的时候赋值。
      isMacComputed: false, // 是否在mac电脑上
      outlineData: [], //大纲数据
      outlineSiblingDom: null, //大纲回车兄弟节点
      outlineDataIndex: [], //大纲节点index
      outlineNodeId: null, //大纲节点id
      drawingBoardScrollView: null, //选框
      downKey: null, //按键key
      downKeyTimeout: -1,
      editAccout: {
        text: "",
        url: "",
      }, //保存状态
      routerData: this.$router.history.current.name, //路由状态
      strings: strings, //防止dom初始拿不到语言包
      rightClickMenuOption: null, //右击菜单选项
      storageIsMeberModelAccout: false, //存储空间不足提示框
      showIconMenu: false, //显示icon弹框
      showIconType: 0, //icon弹框类型
      iconHideTime: null,
      showIconX: 0,
      showIconY: 0,
      iconNode: {}, //icon对象
      tintColor: "", //icon颜色
      isShowMindmapPlaySetupModal: false, //导图播放设置面板
      // pingIntervalFunc: null, //组导图编辑页面的ping定时器。
      MindPageType: null, //当前所处的导图页面类型
      rightMenuShowTimeout: null, //右侧展示隐藏避免出现灰色背景所做的延时。

      //字体样式弹窗
      showFontStyleX: 0, //当前光标的x的轴值
      showFontStyleY: 0, //当前节点距画布的y的轴值
      showFontStyleHeight: 0, //当前节点的高度
      showScale: 1, //系数值
      screenRect: null, //画布距当前浏览器的距离
      showFontStyleMenu: false, //是否弹出字体样式窗
      showFontStyleMenuTime: null, //定时函数5秒
      fontStyleName: "", //当前点击的字体样式弹窗的那个style
      refreshInputMindLayoutTimeout: -1,

      //loding加载
      spinning: false,
      spinningSmall: false,
      //sheet
      initMindSheetDatas: [], //分页sheet数据
      sheetTotal: "", //总页数
      showCreatedSheetMind: false, //创建导图弹窗
      afterIndex: -1, //添加到某个导图之前，-1默认添加到最后
      sheetCreatedModal: false, //添加分页name的输入框是否显示
      sheetName: "", //分页输入框内容
      sheetLeftFullHeight: document.documentElement.clientHeight, //左边菜单列表div的高度
      showSheetLeft: false, //左边sheet分页列表是否展示
      showLookSheetOne: true, //左边列表没展示时显示灰色眼睛图标
      showLookSheetTow: false, //左边列表展示时显示红色眼睛图标
      searchText: "", //左边列表搜索框内容
      initSearchMindSheetDatas: [], //左边导航栏列表
      showIndex: -1, //用于判断当前选中的分页导图下标
      oldIndex: -1, //用于判断当前选中的分页导图下标

      mindPasswordPanelModal:false,
      mindPassword: "", //分页输入框内容
      mindPasswordPlaceholder: "", //分页输入框内容

      // isSheetMenu:false,
      reightMenuIndex: -1, //右键菜单时获取当前右击所属的下标
      sheetNameObj: null, //重命名时保存的item所用
      createdSheetName: "", //判断是创建导图还是重命名
      sheetTopFullWidth: document.documentElement.clientWidth / 2, //底部菜单列表div的宽度
      moveSheetMenu: false, //右键菜单显示隐藏
      reightMenuX: 0, //右键菜单的x轴
      drageBeforeIndex: -1, //更改导图位置排序的index
      drage: [], //保存sheet导图更改排序之前的数据
      // groupEditGmap:false,
      groupGmapId: "", //共享组id
      showSwitchSheet: false, //判断是否是正在切换分页导图
      sheetSwitchItem: null, //保存切换导图之前的数据
      isReightScrollButton: true, //判断向右是否还有导图数据，能否继续点击
      isLeftScrollButton: false, //判断向左是否还有导图数据，能否继续点击
      useShortcut: true, //是否可以使用快捷键,默认true
      sheetBarColor: null, //保存当前分页的背景颜色
      topSheetListShow: true, //用于显示隐藏底部按钮
      isShowBottomMenu: true, //用于显示隐藏底部
      shareRootId: "", //分享链接导图的rootid
      rightMenuClickPoint: new CGPoint(-1, -1),
      dragImgFileAppendCanvas: null, // 图片拖动到画布的方法。
      spaceUpgradeModalShow: false,
      isElectronSaveMind: 0, //保存electron的导图
      calendarWindowIndex: null, //当前窗口的index
      previewSelectedNodeId: -1,
      paidPopUps: 0, //付费弹窗次数
      timePaidPopUp: null,
      generalConfigurationParameters: null, //通用配置参数
      showTitleFloatPopup: false, //是否付费提示浮动弹窗
      showTitleNodeLimit: false, //超出节点限制时，判断提示框是否弹出
      isImageSeleced: false, //判断图片是否选中
      KeyboardValueType,
      dataNode: null, //task数据
      showEditTask: false,
      showFindTextView: false,
      showEditLatex: false,
      keyboard: {
        //快捷键提示
        ENLARGE_MAP: "",
        SHRINK_MAP: "",
        ADD_CHILD_NODE: "",
        ADD_TOP_CHILD_NODE: "",
        ADD_BOTTOM_CHILD_NODE: "",
      },
      moreMenu: false, //备注链接更多图标展示
      moreNodeId: null,
      showResourceModel: false, //附件菜单的显示隐藏
      shareToken: "", //分享链接里面点击关联导图的token
      isExplain: false, //当前点击是不是注释
      saveMapShortcut: "[Ctrl+s]", //保存导图快捷键按键
      showMoveInSheet: false, //是否显示可移入的导图列表
      bottomMenuHeight: 32,
      downControlKey: false,
    };
  },
  props: {
    height: Number,
    initMindData: {
      type: Object,
      defualt() {
        return {};
      },
    },
    initMindGroupViewData: {
      type: Object,
      defualt() {
        return {};
      },
    },
    mindMapingType: {
      type: VueDataEvent,
      defualt() {
        return new VueDataEvent(false);
      },
    },
    initTemplateData: {
      type: Object,
      defualt() {
        return {};
      },
    },
    shareMindData: {
      type: Object,
      defualt() {
        return {};
      },
    },
    initCrispsData: {
      type: Object,
      defualt() {
        return {};
      },
    },
    isRightShowMenu: {
      type: Boolean,
      defualt() {
        return true;
      },
    },
  },
  created: function () {
    // let span = document.createElement("div")
    // span.setAttribute('id','latex_katex')
    // document.body.appendChild(span)

    // span.style.position = 'absolute';
    // span.style.whiteSpace = "pre-wrap"
    // span.style.wordBreak = "break-word"
    // span.style.opacity = 0
    // span.style.maxWidth = Config.contentMaxWidth + 'px'
    // span.style.width = "auto"
    // span.style.top = "0"
    // span.style.lineHeight = Config.textLineHeight

    // //换行必须设置为行内块
    // span.style.display = "block"
    // span.innerHTML = '';
    // span.style.fontSize = 16 + 'px'
    // let html = katex.renderToString('', {
    //     throwOnError: false
    // });
    // span.innerHTML = html;

    this.fullSccreenkeydown();
    //判断是否是手机
    this.judgePhone();
    this.isMacComputed = this.$tools.isMacComputed();
    MindSettingManager.getMacComputed(this.isMacComputed);
    if (this.isMacComputed) {
      this.saveMapShortcut = "[Command+s]";
    }

    this.setWindowWidth(document.documentElement.clientWidth);
    if (this.routerData == "Complie") {
      this.useShortcut = true;
    }
    // window.onunload = ()=>{
    //   if ( this.MindPageType == 'groupMapEdit' ) {
    //     let gmapId = this.$route.params.id
    //     this.endGroupEditGmap({gmapId: gmapId});
    //   }
    // }
    setTimeout(() => {
      this.scrollInitTopSheet();
      
      if (this.$route.name == "Complie" || this.$route.name == "gmap") {
        this.sheetLeftFullHeight = this.sheetLeftFullHeight - 65 - 33;
      } else if (this.$route.name == "template") {
        this.sheetLeftFullHeight = this.sheetLeftFullHeight - 79 - 33;
      } else if (this.$route.name == "c") {
        this.sheetLeftFullHeight = this.sheetLeftFullHeight - 79 - 33;
      } else if (this.$route.name == "s") {
        var checkMenuColorScaleMenuObj = document.getElementById("checkMenuColor-scale-menu");
        if (this.isSharePageView() && new UiUtil().isPhoneAndPad()) {
          checkMenuColorScaleMenuObj.style.display = "none";
        }
        if (document.documentElement.clientWidth > 1400) {
          this.sheetLeftFullHeight = this.sheetLeftFullHeight - 88 - 57 - 33;
          var leftObj = document.getElementById("menuLeftMenu");
          leftObj.style.marginTop = 59 + "px";
        } else {
          this.sheetLeftFullHeight = this.sheetLeftFullHeight - 88 - 33;
        }
        if (this.isNowPhone) {
          this.sheetTopFullWidth = document.documentElement.clientWidth;
          this.sheetLeftFullHeight = this.sheetLeftFullHeight + 30;
          var leftObj = document.getElementById("menuLeftMenu");
          leftObj.style.marginTop = 59 + "px";
        }
      }
    }, 200);
    // console.log("LeftContent created end-> ", Util.getCurrentTime() - Config.startTime);
    // if(this.$route.query.sheetCrispsId != "" && this.$route.query.sheetCrispsId != null ){
    //       this.$router.replace({
    //         query: {
    //           sheetCrispsId:this.$route.query.sheetCrispsId
    //         }
    //       });
    // }else if(this.$route.query.sheetId != "" && this.$route.query.sheetId != null){
    //       this.$router.replace({
    //         // path: "mindmap",
    //         query: {
    //           id: this.$route.query.id,
    //           sheetId:this.$route.query.sheetId
    //         },
    //       });
    // }
  },

  updated() {
    // if(!this.editmapScrollView.isShowInput()){
    //   this.bunidingShortcutKey();
    // }

    this.topListScroll();
    // this.getProgressBar();
  },
  mounted: function () {
    // console.log("LeftContent mounted -> ", Util.getCurrentTime() - Config.startTime);
    this.bunidingShortcutKey();
    this.bus.$emit("setMindMapDelegae", this);
    this.bus.$on("setAddLink", (data) => {
      //插入链接
      this.addLink(data, this.$store.state.nodeContentMessage.id);
    });
    // this.bus.$on("latexTurnImg", (data) => {
    //   this.spinning = true;
    //   if(data){
    //     this.latexTurnImgs().then((res)=>{
    //       if(res){
    //         this.setIsLatexTurnImgSuccess(true);
    //         this.spinning = false;
    //       }
    //     });
    //   }else{
    //     this.spinning = false;
    //   }
    // });

    //获取盒子的宽度与高度。
    this.getContentWidthHeight();
    this.monitorRight();
    setTimeout(() => {
      this.bus.$emit("scrollHeight", this.fullHeight);
    }, 100);
    let editmapScrollViewParent = document.getElementById("canvas-father");
    this.editmapScrollView = new ScrollView(
      editmapScrollViewParent,
      Config.Mind_Width,
      Config.Mind_Height,
      this
    );
    this.editmapScrollView.vueThis = this;

    this.editMindmapContent = EditMindmapVM;

    this.editMindmapContent.delegate = this;

    this.editMindmapAccout(this.editMindmapContent);
    window.windowEditMindmapVM = EditMindmapVM; //全局变量

    this.editmapScrollView.setBackgroundColor("0xFFFFFF");
    this.editmapScrollView.inputView.style.display = "none";
    this.bus.$on("hideEditInput", (flag) => {
      this.editmapScrollView.hideInput();
    });

    //截屏
    this.coverTimeOut;
    if (this.coverTimeOut) {
      clearTimeout(this.coverTimeOut);
    }
    //保存
    //是否为electron
    if (!this.$tools.isElectron()) {
      let that = this;
      //关闭窗口提示
      window.onbeforeunload = function (e) {
        if (
          that.$route.name == "Complie" &&
          that.$store.state.saveAndEditAccout == "alreadyEdit"
        ) {
          e = e || window.event;
          // 兼容IE8和Firefox 4之前的版本
          if (e) {
            e.returnValue = "关闭提示";
            EditMindmapVM.saveDataForClosePage();
          }
          // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
          EditMindmapVM.saveDataForClosePage();
          return "关闭提示";
        } else {
          EditMindmapVM.saveDataForClosePage();
          window.onbeforeunload = null;
        }
      };
    }

    this.watchAddSubscript();
    // this.getSheetPaginationList();
    // this.spatialCuesFile();
    let that = this;
    this.$dragging.$on("dragged", (value) => {
      that.exitEditStatusSaveMap();
      //因为我这在排序的时候赋值
      // let obj = {rootId:value.draged.rootId,sheetId:value.draged.id,beforeIndex:value.to.index}
      that.drage = value.draged;
      // that.drageId = value.draged.id;
      that.drageBeforeIndex = value.to.index;
    });
    this.$dragging.$on("dragend", (value) => {
      let timer = setInterval(() => {
        const finishSavingMapVal = this.$store.state.finishSavingMap;
        if (finishSavingMapVal.fulfill) {
          this.setFinishSavingMap({ fulfill: false, result: false });
          clearTimeout(timer);
          if (!finishSavingMapVal.result) {
            return;
          }
        } else {
          return;
        }
        //因为我这在排序的时候需要发送数据到服务器
        if (this.initMindSheetDatas.length <= 1) {
          return;
        }
        let obj = {
          rootId: that.drage.rootId,
          sheetId: that.drage.id,
          beforeIndex: that.drageBeforeIndex,
        };

        if (that.$route.name == "Complie" || that.$route.name == "arvhiveMap") {
          return new Promise(function (resolve, reject) {
            postMindmapMoveSheet(
              obj,
              (res) => {
                resolve(res);
                if (that.drage.id == that.$route.query.sheetId) {
                  if (that.drageBeforeIndex > that.drage.index) {
                    that.showIndex = that.drageBeforeIndex - 1;
                  } else {
                    that.showIndex = that.drageBeforeIndex;
                  }
                } else {
                  if (
                    that.showIndex >= that.drageBeforeIndex &&
                    that.showIndex < that.drage.index
                  ) {
                    that.showIndex += 1;
                  }
                  if (
                    that.showIndex < that.drageBeforeIndex &&
                    that.showIndex > that.drage.index
                  ) {
                    that.showIndex -= 1;
                  }
                }
                that.getSheetPaginationList(0);
                const query = {};
                query["id"] = res.rootId;
                query["refresh"] = parseInt(new Date().getTime() / 1000);
                if (res.rootId != res.id) {
                  query["sheetId"] = res.id;
                }
                if (
                  that.routerData == "arvhiveMap" &&
                  that.mindMapingType &&
                  that.mindMapingType.value
                ) {
                  //归档判断是否是编辑状态
                  query["editState"] = "arvhiveMapEdit";
                }
                that.$router.replace({
                  query,
                });
              },
              (error) => {
                that.$message.error(error.desc);
                reject(error);
              }
            );
          });
        } else if (that.$route.name == "gmap") {
          let gmapObj = {
            groupId: that.groupGmapId,
            rootId: that.drage.rootId,
            sheetId: that.drage.id,
            beforeIndex: that.drageBeforeIndex,
          };
          return new Promise(function (resolve, reject) {
            postGroupMoveGsheet(
              gmapObj,
              (res) => {
                resolve(res);
                if (that.drage.id == that.$route.query.sheetId) {
                  if (that.drageBeforeIndex > that.drage.index) {
                    that.showIndex = that.drageBeforeIndex - 1;
                  } else {
                    that.showIndex = that.drageBeforeIndex;
                  }
                } else {
                  if (
                    that.showIndex >= that.drageBeforeIndex &&
                    that.showIndex < that.drage.index
                  ) {
                    that.showIndex += 1;
                  }
                  if (
                    that.showIndex < that.drageBeforeIndex &&
                    that.showIndex > that.drage.index
                  ) {
                    that.showIndex -= 1;
                  }
                }
                that.getSheetPaginationList(0);
                const query = {};
                query["id"] = res.rootId;
                query["refresh"] = parseInt(new Date().getTime() / 1000);
                if (res.rootId != res.id) {
                  query["sheetId"] = res.id;
                }
                if (that.mindMapingType && that.mindMapingType.value) {
                  //共享组判断是否是编辑状态
                  query["editState"] = "groupMapEdit";
                }
                that.$router.replace({
                  query,
                });
              },
              (error) => {
                that.$message.error(error.desc);
                reject(error);
              }
            );
          });
        }
      }, 200);
    });

    this.dragImgFileAppendCanvas = new DragImgFileAppendCanvas(
      editmapScrollViewParent,
      this
    );

    this.electronMessageData();

    this.floatPopupLoop();

    this.baseGlobalConfigAPI();

    this.dblcickLineOrWrapping();
    // console.log("LeftContent mounted end-> ", Util.getCurrentTime() - Config.startTime);
  },
  destroyed() {
    //如果时导图编辑页面
    // if ( this.MindPageType == 'groupMapEdit' ) {
    //   let gmapId = this.$route.params.id
    //   this.endGroupEditGmap({gmapId: gmapId});
    // }
  },

  methods: {
    ...mapMutations([
      "showLoginModel",
      "setEditMindmap",
      "setNodeContentMessage",
      "getTextStlye",
      "getImgUrl",
      "setNodeType",
      "getLineTypeLayout",
      "setShowAddRemark",
      "setAddRemarkContent",
      "setShowAddLink",
      "setShowAddAi",
      "setAddLinkContent",
      "getInitData",
      "setAddIconPage",
      "setDomImg",
      "showTipsMessageAccouts",
      "getCanvanDatas",
      "getCanvanImageDatas",
      "saveImageBgcolors",
      "initMindDataToMenu",
      "saveEditAccoutToHeader",
      "saveMindPhoto",
      "revocationMap",
      "recoverMap",
      "chartContent",
      "materialLibraryModel",
      "setOcrScanModel",
      "setResourceModel",
      "materiaVideoModal",
      "setmaterialLibraryModulesModel",
      "setEncircleLineType",
      "showDaGangModel",
      "outlineView",
      "removeElementViews",
      "isShowAlignButton",
      "isShowSameWidth",
      "showEchartModal",
      "isShowShoppingModel",
      "mindmapPlayPauseShowSetup",
      "isMindmapPlay",
      "imgTypeModelAccout",
      "topToolDardarkMode",
      "mindmapExportMenu",
      "setNodeFontAlign",
      "setWindowWidth",
      "createdEditMindmapVM",
      "showLoading",
      "getTextSize",
      "setBorderlLine",
      "setNodeWidth",
      "setLineMinorWitdh",
      "setIconSize",
      "setbaoWeiTextSize",
      "setPaddingSize",
      "setIsAssociationModel",
      "setTsakSelectedNodeId",
      "setIsShowEditTask",
      "setIsShowFindTextView",
      "setIsLaTeXModel",
      "setLatexNodesId",
      "setFinishSavingMap",
      "setIsLatexTurnImgSuccess",
      "showChunkColor",
      "markColorAccout",
    ]),
    getString(i) {
      return getString(i);
    },
    storageIsMeberCancelHandleOk() {
      this.storageIsMeberModelAccout = false;
    },
    storageIsMeberDeleteHandleOk() {
      this.storageIsMeberModelAccout = false;
      this.isShowShoppingModel(true);
    },
    electronMessageData() {
      if (this.$tools.isElectron()) {
        ipcRenderer.on("getPageData", (event, arg) => {
          // 接收异步消息
          this.calendarWindowIndex = arg;
          let isEdit =
            !this.isTemplatePage &&
            !this.isSharePage &&
            this.editMindmapContent.hasWithoutSaveData();
          let obj = {
            isEdit: isEdit,
            window: arg,
          };
          ipcRenderer.send("emitPageData", obj); // 发送异步消息
        });

        ipcRenderer.on("seveMindOn", (event, arg) => {
          // 接收到保存导图
          if (this.editMindmapContent.hasWithoutSaveData()) {
            //如果还有没保存的。
            this.calendarWindowIndex = arg;
            this.isElectronSaveMind = 1;
            EditMindmapVM.saveDataForClosePage();
          } else {
            this.isElectronSaveMind = 0;
            ipcRenderer.send("saveMindOver", arg); // 发送异步消息
          }
        });

        ipcRenderer.on("downloadError", (event, err) => {
          this.$message.error("下载失败");
        });

        ipcRenderer.on("downloadSuccess", (event, msg) => {
          openSystemUrl(msg);
        });
      }
    },
    changeFullWidth(val) {
      //设置画布宽度
      this.fullWidth = val;
    },
    changeFullHeight(val) {
      //设置画布高度。
      if (val < 0) {
        return
      }
      this.fullHeight = val - this.bottomMenuHeight;
    },
    //保存已更改状态var
    saveEditAccout(accout) {
      if (this.$tools.isElectron()) {
        if (this.isElectronSaveMind == 1) {
          if (accout == "saveSuccess") {
            ipcRenderer.send("saveMindOver", this.calendarWindowIndex); // 发送异步消息
            this.isElectronSaveMind = 0
          } else if (accout == "saveError") {
            ipcRenderer.send("saveMindError", this.calendarWindowIndex); // 发送异步消息
            this.isElectronSaveMind = 0
          }
        }
      }

      var time = setTimeout(() => {
        this.saveEditAccoutToHeader(null);
      }, 100);
      if (accout == "saveSuccess") {
        this.saveEditAccoutToHeader("saveSuccess");
        time;
      } else if (accout == "alreadyEdit") {
        this.saveEditAccoutToHeader("alreadyEdit");
        time;
        this.checkAddIconColor(this.DrawingBoardBackgroundColor);
      }
    },
    //空间不足提示
    spatialCuesFile() {
      if (this.routerData == "gmap") {
        postGroupGstorageInfo(
          { groupId: this.initMindGroupViewDatas.groupId },
          (res) => {
            let percentKB = (res.totalBytes - res.useBytes) / 1024;

            if (percentKB <= 1024) {
              this.spaceUpgradeModalShow = true;
            }
          }
        );
      } else {
        postUserStorageInfo({}, (res) => {
          if (res == undefined || res == null) {
            return;
          }

          let percentKB = (res.totalBytes - res.usedBytes) / 1024;
          if (percentKB <= 1024) {
            this.storageIsMeberModelAccout = res.showAlert;
          }
        });
      }
    },
    //保存导图数据
    saveMindData() {
      this.spatialCuesFile();
      isClickSaveMapButton.value = true;
      EditMindmapVM.saveDataForClosePage();
      setTimeout(() => {
        this.editAccout.url = "";
      }, 2000);
    },
    judgePhone() {
      var ua = navigator.userAgent.toLocaleLowerCase();
      if (new UiUtil().isPhoneAndPad() && ua.match(/QQ/i) != "qq") {
        this.isNowPhone = true;
        this.fullWidth = document.documentElement.clientWidth;
        this.fullHeight = document.documentElement.clientHeight;
        this.canvasTop = 0;
      } else {
        this.isNowPhone = false;
      }
      
      if (this.isSharePageView() && new UiUtil().isPhoneAndPad()) {
        this.canvasTop = 56;
        this.canvasTopPattern1 = 56;
      }
    },
    isSharePageView() { //分享页面
        return window.location.href.match(/\/s\//i) == "/s/";
    },
    //自定义快捷键
    shortcutFun(type) {
      const MindOperateUIControllerVie = new MindOperateUIControllerView(
        this.editMindmapContent,
        this.editMindmapContent
      );
      if (this.selectMindView == null && this.editMindmapContent.selectedNodeId > -1) {
        this.selectMindView = this.viewElementList.get(this.editMindmapContent.selectedNodeId)
      }
      let selectedStatus = this.selectMindView != null; //选中状态
      let editStatus = this.editmapScrollView.isShowInput(); //编辑状态
      switch (type) {
        case KeyboardValueType.ADD_CHILD_NODE: //添加子节点
          if (selectedStatus) {
            this.editmapScrollView.hideInput();
            this.editMindmapContent.addChildMindElement(true);
          }
          break;
        case KeyboardValueType.ADD_TOP_CHILD_NODE: //添加上子节点
          if (selectedStatus) {
            this.editmapScrollView.hideInput();
            MindOperateUIControllerVie.onSelected(
              MindElementType.ADD_BROTHER_TOP
            );
          }
          break;
        case KeyboardValueType.ADD_BOTTOM_CHILD_NODE: //添加下子节点
          if (selectedStatus) {
            this.editmapScrollView.hideInput();
            MindOperateUIControllerVie.onSelected(
              MindElementType.ADD_BROTHER_BOTTOM
            );
          }
          break;
        case KeyboardValueType.REVOKE: //撤销
          this.revocationMap();
          break;
        case KeyboardValueType.RECOVERY: //恢复
          this.recoverMap();
          break;
        case KeyboardValueType.EXIT_EDIT_STATUS: //退出编辑
          if (editStatus) {
            this.editmapScrollView.cancelCheckNode();
          }
          break;
        case KeyboardValueType.MODIFY_CURRENT_NODE: //修改当前节点
          if (selectedStatus) {
            if (!editStatus) {
              this.editMindmapContent.editSelectedNode();
            }
          }
          break;
        case KeyboardValueType.DELETE:
        case KeyboardValueType.BACKSPACE: //删除当前节点（当前处于非编辑状态）
          if (this.isImageSeleced) {
            this.editMindmapContent.removeImage();
            this.isImageSeleced = false;
            break;
          }
          if (selectedStatus) {
            if (!editStatus) {
              this.editMindmapContent.removeNode();
              //隐藏关联线菜单还有关联线的控制点。
              if (this.showRelevanceLineMenu == true) {
                this.editmapScrollView.hideCircle();
                this.showRelevanceLineMenu = false;
              }
            }
          }
          break;
        case KeyboardValueType.ENLARGE_MAP: //放大导图
          this.pantograph(true);
          break;
        case KeyboardValueType.SHRINK_MAP: //缩小导图
          this.pantograph(false);
          break;
        case KeyboardValueType.SELECT_MASTER: //选择主节点
          if (!editStatus) {
            this.editMindmapContent.selecteMainNode();
          }
          break;
        case KeyboardValueType.COPY: //复制节点
          if (selectedStatus) {
            if (!editStatus) {
              this.editMindmapContent.setCopyNodeId(this.lastClickMindData.id);
              this.copyPicture2Clipboard();
            }
          }
          break;
        case KeyboardValueType.PASTE: //粘贴节点
          if (!editStatus) {
            if (MindCopyManager.isHasCopyNode()) {
              //判断是否有复制的节点
              // this.editMindmapContent.stickNode();
              let that = this;
              if (that.point == null || that.point.x <= 0 ||
                  that.editMindmapContent.selectedNodeId < 0) {
                  that.editMindmapContent.stickNode(
                      that.editMindmapContent.selectedNodeId,
                      null,
                      false
                    );
              } else {
                this.$confirm({
                  title: getString(strings.Mind_Tips),
                  content: getString(strings.Keep_Original_Style_Request),
                  centered: true,
                  okText: getString(strings.Keep_Original_Style),
                  cancelText: getString(strings.Keep_FollowCurrent),
                  onOk() {
                    that.editMindmapContent.stickNode(
                      that.editMindmapContent.selectedNodeId,
                      null,
                      true
                    );
                  },
                  onCancel() {
                    that.editMindmapContent.stickNode(
                      that.editMindmapContent.selectedNodeId,
                      null,
                      false
                    );
                  },
                });
              }
            } else {
              let that = this;
              try {
                navigator.clipboard.readText().then( function(clipText) {
                  that.editMindmapContent.stickString(that.editMindmapContent.selectedNodeId, null, clipText)
                });
                navigator.clipboard.read().then((data) => {
                  for (let i = 0; i < data.length; i++) {
                    if (data[i].types.includes("image/png")) {
                      data[i].getType("image/png").then((blob) => {
                        let node = that.editMindmapContent.getSelectedNode() 
                        if (node == null || node.isEmpty()) {
                          new ImageFileUpload(that, that.getMindMapCentralPoint()).upload(blob);
                        } else {
                          new ImageFileUpload(that, null).upload(blob);
                        }
                      });
                    }
                  }
                });
              } catch (error) {
                // console.log("error ", error);
              }
            }
          }
          break;
        default:
          // if (selectedStatus) {
          //   // this.onDoubleClickSelected(this.lastClickMindData);
          //    if (!editStatus) {
          //      this.editMindmapContent.editSelectedNode();
          //    }

          // }
          break;
      }
    },
    keydownFun(e) {
      const keyCode = window.event ? e.keyCode : e.which; //获取被按下的键值
      let controlKey = e.ctrlKey;
      let metaKey = e.metaKey;
      let shiftKey = e.shiftKey;
      let controlOrCommandKey = false;
      this.downControlKey = controlKey;
      if (this.isMacComputed) {
        controlOrCommandKey = metaKey;
      } else {
        controlOrCommandKey = controlKey;
      }
      if (keyCode == 27 ||
        keyCode == 32 ||
        keyCode == 37 ||
        keyCode == 38 ||
        keyCode == 39 ||
        keyCode == 40) {
        //按esc：27，空格：32，[←]:37[↑]:38[→]:39[↓]:40退出监听事件
        return;
      }

      if (
        (keyCode == 68 && controlOrCommandKey == true) ||
        (keyCode == 69 && controlOrCommandKey == true) ||
        (keyCode == 81 && controlOrCommandKey == true) ||
        (keyCode == 107 && controlOrCommandKey == true) ||
        (keyCode == 109 && controlOrCommandKey == true) ||
        (keyCode == 189 && controlOrCommandKey == true) ||
        (keyCode == 187 && controlOrCommandKey == true) ||
        (keyCode == 83 && controlOrCommandKey == true) ||
        keyCode == 9
      ) {
        e.preventDefault();
        e.returnValue = false;
        // [Command]+[e] Ctrl+68:去除浏览器默认打开输入框
        // [Command]+[d] Ctrl+69:去除浏览器默认事件
        // [Command]+[q] Ctrl+81:去除浏览器默认关闭页面
        // [Command]+[+] Ctrl+107:去除浏览器放大
        // [Command]+[-] Ctrl+109:去除浏览器缩小
        // [Command]+[s] Ctrl+83:去除浏览器保存事件
        // [Command]+[=] 91+187:去除Mac浏览器放大
        // [Command]+[-] 91+189:去除Mac浏览器缩小
        // [Tab] 9:去除浏览器Tab默认事件
      }
      if (this.editMindmapContent.mindDisplayType != MindDisplayType.MindMap || !this.useShortcut) {
        return;
      }

      // if (!this.editmapScrollView.isShowInput()) {
      //   //可以使用快捷键 非编辑状态 触发
      //   if (keyCode == 65 && controlOrCommandKey == true) {
      //     e.preventDefault();
      //     e.returnValue = false;
      //     // [Command]+[a] Ctrl+65:去除浏览器全选
      //   }
      // }
      if (controlKey) {
        keyName += "Control+";
      }
      if (metaKey) {
        keyName += "Meta+";
      }
      if (shiftKey) {
        keyName += "Shift+";
      }

      keyName += (e.key === "+" ? "Plus" : e.key) + "+";
      this.keyupFun(e);
      //  //选中可以直接输入,只有数字和字母可以进入编辑状态
      //   let keyName = e.key.toUpperCase();
      //   if (keyName.length < 2) {
      //     let type = 0;
      //     const reg = /^[0-9A-Z]{1,}/gi;
      //     if (reg.test(keyName)) {
      //       type = KeyboardValueType.MODIFY_CURRENT_NODE;
      //       this.shortcutFun(type);
      //     }
      //   }
    },
    keyupFun() {
      if (keyName == "") {
        return;
      }
      keyName = keyName.slice(0, keyName.length - 1);
      keyName = keyName.split("+");
      keyName = Array.from(new Set(keyName));
      keyName = keyName.map((item) =>
        item.replace(item[0], item[0].toUpperCase())
      );
      keyName = keyName.join("+");
      keyName = keyName
        .replace("Control", "Ctrl")
        .replace("Meta", "Command")
        .replace(" ", "Space")
        .replace("Plus", "+");
      // 拼接字符串和Setting.vue中对应，修改一个两个都要改
      this.getKeyboardValueType(keyName);
      keyName = "";
    },
    getKeyboardValueType(key) {
      let type = 0;
      MindSettingManager.loadData(); //加载自定义的快捷键
      const mindKeyboards = MindSettingManager.mindSettingData.mindKeyboards;
      const mindKeyboardsItem = mindKeyboards.find(
        (item) => item.shortcutKey === key
      );
      if (mindKeyboardsItem) {
        type = mindKeyboardsItem.type;
      } else {
        if (key.length < 2) {
          const reg = /^[0-9A-Z]{1,}/gi;
          if (reg.test(key)) {
            type = KeyboardValueType.MODIFY_CURRENT_NODE; //选中可以直接输入,只有数字和字母可以进入编辑状态
          }
        }
      }
      if (type == 0) {
        return;
      }
      this.shortcutFun(type);
    },
    bunidingShortcutKey: function () {
      //绑定快捷键
      document.addEventListener("keydown", this.keydownFun);
      // document.addEventListener("keyup", this.keyupFun);
    },
    removeShortcutKey: function () {
      //移除快捷键
      document.removeEventListener("keydown", this.keydownFun, false);
      // document.removeEventListener("keyup", this.keyupFun,false);
    },
    // 获取快捷键提示
    getKeyboard(type) {
      MindSettingManager.loadData(); //加载自定义的快捷键
      const mindKeyboards = MindSettingManager.mindSettingData.mindKeyboards;
      const keyboardsItem = mindKeyboards.find(
        (item) => item.type === type
      ).shortcutKey;
      switch (type) {
        case KeyboardValueType.ENLARGE_MAP: //放大
          this.keyboard.ENLARGE_MAP = keyboardsItem;
          break;
        case KeyboardValueType.SHRINK_MAP: //缩小
          this.keyboard.SHRINK_MAP = keyboardsItem;
          break;
        case KeyboardValueType.ADD_BOTTOM_CHILD_NODE: //添加下子节点
          this.keyboard.ADD_BOTTOM_CHILD_NODE = keyboardsItem;
          break;
        case KeyboardValueType.REVOKE: //撤销
          this.keyboard.REVOKE = keyboardsItem;
          break;
        case KeyboardValueType.RECOVERY: //恢复
          this.keyboard.RECOVERY = keyboardsItem;
          break;
      }
    },
    //编辑导图
    getInitMindData: function () {
      return new Promise((resolve, reject) => {
        let res = this.initMindDatas;
        this.editMindmapContent.init(
          -1,
          -1,
          res.content,
          res.type,
          res.nodeLayoutType,
          res.lineLayout,
          res.styleIndex,
          -1,
          res.mindDisplayType,
          res.isEmpty
        );
        this.editMindmapContent.viewDidLoad();
        //初始化数据
        this.initData(
          res.type,
          res.nodeLayoutType,
          res.mindDisplayType,
          res.lineLayout
        );
        if (res.content != "") {
          const content = JSON.parse(res.content);
          this.DrawingBoardBackgroundColor = content.mindBGColor;
          this.topToolDardarkMode(this.DrawingBoardBackgroundColor); //判断是否是深色模式
          const audio = content.mindGlobalAudio;
          this.getCanvanData({
            entityId: res.entityId,
            cover: res.cover,
            audio: audio,
          });
        } else {
          this.getCanvanData({ entityId: res.entityId, cover: res.cover });
        }
        this.initMindEditData(res)
        this.cover = res.cover;
        this.initBackgroundImage()
        if (1) {
          resolve(res);
        } else {
          reject(error);
        }
      }).then((data) => {
        this.setMapTextColor();
        this.setNodeUpNumberViewColor();
        this.setCheckBorderColor();
      });
      // var setMindData = document.getElementsByClassName(
      //   "complie-setMindData"
      // )[0];
      // setMindData.onmouseout = function () {
      //   EditMindmapVM.saveDataForClosePage();
      // };
    },

    initBackgroundImage() {
      if (this.editmapScrollView == null) {
        return
      }

      let that = this
      setTimeout(() => {
        if ((that.$router.history.current.name == 'gmap' &&
                that.MindPageType != 'groupMapEdit') ||
            (that.$router.history.current.name == 'arvhiveMap' &&
                that.MindPageType != 'arvhiveMapEdit') ||
            that.$router.history.current.name == 'c' ||
            that.$router.history.current.name == 's' ||
            that.$router.history.current.name == 'template') {
            return
        } 
        that.editmapScrollView.initBackgroundImage()
      }, 1500);
    },

    setInitMindGroupViewData: function () {
      return new Promise((resolve, reject) => {
        let res = this.initMindGroupViewDatas;
        this.editMindmapContent.init(
          -1,
          -1,
          res.content,
          res.type,
          res.nodeLayoutType,
          res.lineLayout,
          res.styleIndex,
          -1,
          res.mindDisplayType,
          res.isEmpty
        );
        this.editMindmapContent.mindOwnerId = res.userId
        this.editMindmapContent.requestorType = res.requestorType;
        this.editMindmapContent.viewDidLoad();
        //初始化数据
        this.initData(
          res.type,
          res.nodeLayoutType,
          res.mindDisplayType,
          res.lineLayout
        );
        if (res.content != "") {
          const content = JSON.parse(res.content);
          this.DrawingBoardBackgroundColor = content.mindBGColor;
          this.topToolDardarkMode(this.DrawingBoardBackgroundColor); //判断是否是深色模式
          const audio = content.mindGlobalAudio;
          this.getCanvanData({
            entityId: res.entityId,
            cover: res.cover,
            audio: audio,
          });
        } else {
          this.getCanvanData({ entityId: res.entityId, cover: res.cover });
        }
        this.initMindEditData(res)
        this.cover = res.cover;
        this.initBackgroundImage()
        if (1) {
          resolve(res);
        } else {
          reject(error);
        }
      }).then((data) => {
        //开始编辑组导图
        this.setMapTextColor();
        this.setNodeUpNumberViewColor();
        this.setCheckBorderColor();
        if (data.content.length == 0) {
          this.mindMapingType.setValue(true);
        }
      });
    },
    //使用模板
    getTemplateInitMindData: function () {
      return new Promise((resolve, reject) => {
        let res = this.initTemplateDatas;
        this.editMindmapContent.init(
          -1,
          -1,
          res.content,
          res.mindMapType,
          res.nodeLayoutType,
          res.lineLayout,
          res.styleIndex,
          -1,
          res.mindDisplayType,
          res.isEmpty
        );
        this.editMindmapContent.viewDidLoad();
        this.initData(
          res.mindMapType,
          res.nodeLayoutType,
          res.mindDisplayType,
          res.lineLayout
        );
        /**
         * 截图参数
         * 1、模板ID
         * 2、模板封面
         * 3、音频
         */
        if (res.content != "") {
          const content = JSON.parse(res.content);
          const audio = content.mindGlobalAudio;
          this.getCanvanData({
            entityId: res.entityId,
            cover: res.cover,
            audio: audio,
          });
        } else {
          this.getCanvanData({ entityId: res.entityId, cover: res.cover });
        }
        this.cover = res.Cover;
        this.initBackgroundImage()
        if (1) {
          resolve(res);
        } else {
          reject(error);
        }
      }).then((data) => {
        // this.centeredViewModal();
      });
    },
    //分享模板
    getShareMindData() {
      return new Promise((resolve, reject) => {
        let share = this.initShareMindDatas;
        this.editMindmapContent.init(
          -1,
          -1,
          share.content,
          share.mindMapType,
          share.nodeLayoutType,
          share.lineLayout,
          share.styleIndex,
          -1,
          share.mindDisplayType,
          share.isEmpty
        );
        this.initData(
          share.mindMapType,
          share.nodeLayoutType,
          share.mindDisplayType,
          share.lineLayout
        );
        this.editMindmapContent.viewDidLoad();
        /**
         * 截图参数
         * 1、模板ID
         * 2、模板封面
         * 3、音频
         */
        if (share.content != "") {
          const content = JSON.parse(share.content);
          const audio = content.mindGlobalAudio;
          this.getCanvanData({
            entityId: share.entityId,
            cover: share.cover,
            audio: audio,
          });
        } else {
          this.getCanvanData({ entityId: share.entityId, cover: share.cover });
        }
        this.cover = share.cover;
        this.initBackgroundImage()
        if (1) {
          resolve(share);
        } else {
          reject(error);
        }
      }).then((data) => {
        // this.centeredViewModal();
      });
    },
    //风暴
    getCrispsData() {
      return new Promise((resolve, reject) => {
        let res = this.initCrispsData;
        this.editMindmapContent.init(
          -1,
          -1,
          res.content,
          res.mindMapType,
          res.nodeLayoutType,
          res.lineLayout,
          res.styleIndex,
          -1,
          res.mindDisplayType,
          res.isEmpty
        );
        this.editMindmapContent.viewDidLoad();
        this.initData(
          res.mindMapType,
          res.nodeLayoutType,
          res.mindDisplayType,
          res.lineLayout
        );
        /**
         * 截图参数
         * 1、模板ID
         * 2、模板封面
         * 3、音频
         */
        if (res.content != "") {
          const content = JSON.parse(res.content);
          const audio = content.mindGlobalAudio;
          this.getCanvanData({
            entityId: res.entityId,
            cover: res.cover,
            audio: audio,
          });
        } else {
          this.getCanvanData({ entityId: res.entityId, cover: res.cover });
        }
        this.initBackgroundImage()
        if (1) {
          resolve(res);
        } else {
          reject(error);
        }
      }).then((data) => {
        // this.centeredViewModal();
      });
    },
    deletMindImageRequest: function (obj) {
      //获取所有模板信息
      postMindmapDeleteImage(obj);
    },
    deletMindVideoRequest: function (obj) {
      //获取所有模板信息
      let that = this;
      return new Promise(function (resolve, reject) {
        postMindmapDeleteAttachments(
          { urls: obj },
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          }
        );
      });
    },

    monitorRight: function () {
      this.bus.$on("rightMenuAccout", (msg) => {
        if (msg == true) {
          //避免展开出现空白背景。
          setTimeout(() => {
            this.fullWidth = this.document.documentElement.clientWidth - 291;
          }, 500);

          this.rightShowMenu = true;
        }
        if (msg == false) {
          this.fullWidth = this.document.documentElement.clientWidth + 291;
          this.rightShowMenu = false;
        }
      });
    },

    watchAddSubscript: function () {
      this.bus.$on("ADD_Subscript", (msg) => {
        this.addSubscript(this.lastClickMindData);
      });
    },

    addSubscript(data) {
      //当前未选中节点
      if (this.editMindmapContent.selectedNodeId == IdGenerator.INVALID_ID) {
        this.editMindmapContent.selectAddTargetTips();
        return;
      }
      //统计图
      if (EditMindmapVM.getMindMapPlayStatus() != MindMapPlayStatus.NORMAL) {
        if (EditMindmapVM.getMindMapPlayStatus() == MindMapPlayStatus.PLAY) {
          this.pausePlayMap();
        }
        return;
      }
      //当前在下标编辑框
      this.editmapScrollView.isEditSubscript = true;
      if (this.lastDblclickMindData != null) {
        if (this.editmapScrollView.isShowInput()) {
          //将上一次双击的输入框隐藏
          this.editmapScrollView.hideInput();
        }
      }
      this.lastDblclickMindData = data;
      this.editMindmapContent.onSelected(data);
      this.editDataExplain(data);
      this.showElementMenuList = false;
      this.lineColorColorAccout = false;
    },

    editDataExplain(data) {
      if (data == null || data.isEmpty()) {
        return;
      }
      this.editmapScrollView.setInput(data, "setSubscript");
    },

    isShowRightAccout(msg) {
      clearInterval(this.rightMenuShowTimeout);
      if (msg == true) {
        //避免展开出现空白背景。
        this.rightMenuShowTimeout = setTimeout(() => {
          this.fullWidth = document.documentElement.clientWidth - 291;
        }, 500);
        this.rightShowMenu = true;
      }
      if (msg == false) {
        this.fullWidth = document.documentElement.clientWidth;
        this.rightShowMenu = false;
      }
    },
    watchShowMenu: function () {
      this.bus.$on("isShowMindMenu", (msg) => {
        if (msg === false) {
          this.this.showElementMenuList = false;
          this.lineColorColorAccout = false;
        }
      });
    },
    //编辑导图初始化数据
    initMindEditData(mindData) {
      this.initMindDataToMenu(mindData);
    },
    isNodeSelectedNotEdited() {
      let node = this.editMindmapContent.getSelectedNodeById()
      if (node == null || node.isEmpty()) {
        return false
      }
      return !this.editmapScrollView.isShowInput()
    },
    //监听按键 默认快捷键
    fullSccreenkeydown: function () {
      document.onkeyup = (e) => {
        //事件对象兼容
        let keydown = e || window.event || arguments.callee.caller.arguments[0];
        let keyNum = keydown.keyCode;
        let controlKey = false;
        if (this.isMacComputed) {
          controlKey = e.metaKey;
        } else {
          controlKey = e.ctrlKey;
        }
        this.downControlKey = false
      };
      document.onkeydown = (e) => {
        //事件对象兼容
        let keydown = e || window.event || arguments.callee.caller.arguments[0];
        let keyNum = keydown.keyCode;
        let controlKey = false;
        if (this.isMacComputed) {
          controlKey = e.metaKey;
        } else {
          controlKey = e.ctrlKey;
        }
        this.downControlKey = controlKey
        if (!this.useShortcut) {
          return;
        }
        if (keyNum == 122) {
          keydown.preventDefault();
          keydown.returnValue = false;
          this.switchFullScreen(); //F11全屏
        }

        if (keyNum == 27) {
          if (this.canvasFullscreen) {
            this.switchFullScreen();
          } else {
            if (!!this.selectMindView) {
              // 编辑状态下ESC键退出编辑状态
              this.exitEditStatus();
            }
          }
        } else if (keyNum == 37 && this.isNodeSelectedNotEdited() && !controlKey && this.editMindmapContent.mindDisplayType != MindDisplayType.Outline) {// [←]   
          keydown.preventDefault();
          keydown.returnValue = false; 
          let data = this.editMindmapContent.getSelectedRightData();
          if (!data.isEmpty()) {
              this.onSelected(data, new Point(-1, -1));
              this.checkNodeViewToScreen(data)
          }
        } else if (keyNum == 38 && this.isNodeSelectedNotEdited() && !controlKey && this.editMindmapContent.mindDisplayType != MindDisplayType.Outline) {// && controlKey == true
          // [Command]+[↑]
          keydown.preventDefault();
          keydown.returnValue = false;
          let data = this.editMindmapContent.getSelectedTopData();
          if (!data.isEmpty()) {
            this.onSelected(data, new Point(-1, -1));
            this.checkNodeViewToScreen(data)
          }
        } else if (keyNum == 39 && this.isNodeSelectedNotEdited() && !controlKey && this.editMindmapContent.mindDisplayType != MindDisplayType.Outline) {
          // [Command]+[→]          
          keydown.preventDefault();
          keydown.returnValue = false;
          let data = this.editMindmapContent.getSelectedLeftData();
          if (!data.isEmpty()) {
            this.onSelected(data, new Point(-1, -1));
            this.checkNodeViewToScreen(data)
          }
        } else if (keyNum == 40 && this.isNodeSelectedNotEdited() && !controlKey && this.editMindmapContent.mindDisplayType != MindDisplayType.Outline) {
          // [Command]+[↓]
          keydown.preventDefault();
          keydown.returnValue = false;
          let data = this.editMindmapContent.getSelectedBottomData();
          if (!data.isEmpty()) {
            this.onSelected(data, new Point(-1, -1));
            this.checkNodeViewToScreen(data)
          }
        } else if (
          keyNum == 38 &&
          this.editmapScrollView.inputView.style.display != "block" && 
          controlKey) {
          // Node 向上移动
          keydown.preventDefault();
          keydown.returnValue = false; 
          if (this.downKeyTimeout <= -1) {
            this.downKeyTimeout = setTimeout(() => {
              this.downKeyTimeout = -1;
              EditMindmapVM.moveSlectedNodeByVerticalKey(5);
            }, 50);
          }
        } else if (
          keyNum == 40 &&
          this.editmapScrollView.inputView.style.display != "block" && 
          controlKey) {
          // Node 向下移动
          keydown.preventDefault();
          keydown.returnValue = false; 
          if (this.downKeyTimeout <= -1) {
            this.downKeyTimeout = setTimeout(() => {
              this.downKeyTimeout = -1;
              EditMindmapVM.moveSlectedNodeByVerticalKey(-5);
            }, 50);
          }
        } else if (
          keyNum == 37 &&
          this.editmapScrollView.inputView.style.display != "block" && 
          controlKey) {
          // Node 向左移动
          keydown.preventDefault();
          keydown.returnValue = false; 
          if (this.downKeyTimeout <= -1) {
            this.downKeyTimeout = setTimeout(() => {
              this.downKeyTimeout = -1;
              EditMindmapVM.moveSlectedNodeByHorizontal(5);
            }, 50);
          }
        } else if (
          keyNum == 39 &&
          this.editmapScrollView.inputView.style.display != "block" && 
          controlKey) {
          // Node 向右移动
          keydown.preventDefault();
          keydown.returnValue = false; 
          if (this.downKeyTimeout <= -1) {
            this.downKeyTimeout = setTimeout(() => {
              this.downKeyTimeout = -1;
              EditMindmapVM.moveSlectedNodeByHorizontal(-5);
            }, 50);
          }
        } else if (keyNum == 32) {
          //空格 暂停导图回顾
          if (!this.editmapScrollView.isShowInput()) {
            keydown.preventDefault();
            keydown.returnValue = false;
          }
          this.pausePlayMap();
        } else if (keyNum == 9) {
          //Tab 添加子节点
          let type = KeyboardValueType.ADD_CHILD_NODE;
          this.shortcutFun(type);
        } else if (keyNum == 13) {
          //Enter 修改当前节点
          if (!this.editmapScrollView.isShowInput()) {
            let type = KeyboardValueType.MODIFY_CURRENT_NODE;
            this.shortcutFun(type);
          }
        } else if (keyNum == 83 && controlKey == true) {
          // [Ctrl]+[s] || [Command]+[s] 保存导图
          this.exitEditStatusSaveMapByShortcutKeys();
        } else if (keyNum == 70 && controlKey == true) {
          // [Ctrl]+[s] || [Command]+[s] 保存导图
         this.setIsShowFindTextView(true);
         keydown.preventDefault();
         keydown.returnValue = false;
        }
      };
    },

    //计算左边sheet列表的height
    CalculationHeight() {
      if (this.$route.name == "s") {
        if (document.documentElement.clientWidth > 1400) {
          this.sheetLeftFullHeight =
            document.documentElement.clientHeight - 88 - 57 - 33;
          var leftObj = document.getElementById("menuLeftMenu");
          leftObj.style.marginTop = 59 + "px";
        } else {
          this.sheetLeftFullHeight =
            document.documentElement.clientHeight - 88 - 33;
          var leftObj = document.getElementById("menuLeftMenu");
          leftObj.style.marginTop = 0 + "px";
        }
      } else {
        this.sheetLeftFullHeight =
          document.documentElement.clientHeight - this.canvasTop - 32;
      }
    },
    //全屏时计算左边sheet列表的高度
    fullscreenCalculationHeight() {
      this.sheetLeftFullHeight = document.documentElement.clientHeight;
    },
    fullscreenChange: function (fullscreen) {
      this.canvasFullscreen = fullscreen;
      if (!fullscreen) {
        //退出全屏
        this.isShowBottomMenu = true;
        this.topSheetListShow = true;
        var checkMenuColorScaleMenuObj = document.getElementById(
          "checkMenuColor-scale-menu"
        );
        checkMenuColorScaleMenuObj.style.border = "1px solid #EEEEEE";
        checkMenuColorScaleMenuObj.style.backgroundColor = this.sheetBarColor;
        // checkMenuColorScaleMenuObj.style.opacity = 1;
        document.removeEventListener("mousemove", this.removeMousemove);
        this.stopPlayMap();
        // this.rightShowMenu = true;
        this.$emit("menuIsShow", true);
        if (this.isTemplatePage) {
          this.canvasTop = this.canvasTopPattern1;
        } else if (this.isSharePage) {
          if (this.isNowPhone) {
            //如果是手机
            this.canvasTop = 0;
          } else {
            this.canvasTop = this.canvasTopPattern1;
          }
        } else {
          this.canvasTop = this.canvasTopPattern1;
        }
        if (this.isSharePageView() && new UiUtil().isPhoneAndPad()) {
          checkMenuColorScaleMenuObj.style.display = "none";
        }
        if (this.rightMenuAccout == true) {
          this.rightShowMenu = true;
        }
        this.CalculationHeight();
        this.computedFullSize();
      } else {
        //全屏
        var element = document.documentElement;
        this.canvasTop = 0;
        this.fullscreenCalculationHeight();
        this.computedFullSize();
        this.topSheetListShow = false;
        this.isShowBottomMenu = !this.isPlayStatus()
        var checkMenuColorScaleMenuObj = document.getElementById(
          "checkMenuColor-scale-menu"
        );
        if (window.navigator.userAgent.toUpperCase().indexOf("CHROME") >= 0) {
          element.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
        }
        checkMenuColorScaleMenuObj.style.border = "0px";
        checkMenuColorScaleMenuObj.style.backgroundColor = "";
        if (this.isSharePageView() && new UiUtil().isPhoneAndPad()) {
          checkMenuColorScaleMenuObj.style.display = "none";
        }
        // checkMenuColorScaleMenuObj.style.opacity = 0;
        //var height =  window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        // let that = this
        document.addEventListener("mousemove", this.removeMousemove);
      }

      //打开关闭全屏的时候需要延迟才可以居中或精确居中。50毫秒有时有问题，用100
      this.editMindmapContent.moveRootNodeCenterDisplay()
    },
    fullscreenSheet() {
      if (this.canvasFullscreen) {
        //全屏
        this.canvasTop = 0;
        this.fullscreenCalculationHeight();
        this.computedFullSize();
        this.topSheetListShow = false;
        this.isShowBottomMenu = !this.isPlayStatus()
        var checkMenuColorScaleMenuObj = document.getElementById("checkMenuColor-scale-menu");
        checkMenuColorScaleMenuObj.style.border = "0px";
        checkMenuColorScaleMenuObj.style.backgroundColor = "";
        if (this.isSharePageView() && new UiUtil().isPhoneAndPad()) {
          checkMenuColorScaleMenuObj.style.display = "none";
        }
        // checkMenuColorScaleMenuObj.style.opacity = 0;
        //var height =  window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        // let that = this
        document.addEventListener("mousemove", this.removeMousemove);
        //
      }
    },
    removeMousemove: function (event) {
      var width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (event.clientX / width <= 0.05) {
        this.clickLookSheet(false);
      } else if (event.clientX >= 225) {
        this.clickLookSheet(true);
      }
    },
    fullScreen: function () {
      this.switchFullScreen();
    },
    switchFullScreen: function () {
      this.$refs["fullscreen"].toggle(); // recommended
      let getStateAccout = this.$refs["fullscreen"].getState();
      if (!this.isTemplatePage && !this.isSharePage) {
        if (!getStateAccout) {
          this.rightShowMenu = false;
          this.computedFullSize();
        } else {
          if (this.rightMenuAccout == true) {
            this.rightShowMenu = true;
          }
          // this.rightShowMenu = true;
          this.computedFullSize();
        }
      }
      if (this.isTemplatePage || this.isSharePage) {
        this.rightShowMenu = false;
        if (!getStateAccout) {
          this.computedFullSize();
        } else {
          this.computedFullSize();
        }
      }
    },

    getCanvanData: function (canvanData) {
      this.getCanvanDatas(canvanData);
    },
    editMindmapAccout: function (editMindmap) {
      this.setEditMindmap(editMindmap);
    },
    initData: function (mindType, globalLayout, MindDisplayType, LineLayout) {
      let perMindType = this.mindType;
      this.mindType = mindType;
      this.getInitData({ mindType, globalLayout, MindDisplayType, LineLayout });
      // if (mindType == MindType.FLOW_CHART) {
      //   this.showLeftShapeView = true
      //   this.showLeftSheetView = false
      //   this.showSheetLeft = true
      // } else {
      //   if (perMindType == MindType.FLOW_CHART) {
      //     this.showSheetLeft = false
      //   }
      //   this.showLeftShapeView = false
      //   this.showLeftSheetView = true
      // }
    },

    getContentWidthHeight: function () {
      //动态获取浏览器高度
      const that = this;

      window.addEventListener("resize", () => {
        that.setWindowWidth(document.documentElement.clientWidth);
        that.computedFullSize1();
        this.CalculationHeight();
        if (this.isNowPhone) {
          this.sheetTopFullWidth = document.documentElement.clientWidth;
        } else {
          this.sheetTopFullWidth = document.documentElement.clientWidth / 2;
        }
      });
    },
    computedFullSize1: function () {
      //浏览器视口是否变化触发的函数。
      let windowWidth = document.documentElement.clientWidth;
      let windowHeight = document.documentElement.clientHeight;

      if (!this.isTemplatePage && !this.isSharePage) {
        //判断是宽度变了
        if (windowWidth - 291 != this.fullWidth) {
          if (this.rightShowMenu) {
            //如果展示
            this.fullWidth = document.documentElement.clientWidth - 291;
          } else {
            //如果隐藏
            this.fullWidth = document.documentElement.clientWidth;
          }
        }
        //判断是高度变了
        if (windowHeight - this.canvasTopPattern1 != this.fullHeight) {
          // this.fullHeight = document.documentElement.clientHeight - this.canvasTopPattern1;
          this.bus.$emit("scrollHeight", this.fullHeight);
          // if (this.rightShowMenu) {
          if (!this.canvasFullscreen) {
            if (this.isTemplatePage) {
              this.fullHeight =
                document.documentElement.clientHeight - this.canvasTopPattern1 - this.bottomMenuHeight;
            } else {
              this.fullHeight =
                document.documentElement.clientHeight - this.canvasTopPattern1 - this.bottomMenuHeight;
            }
          } else {
            //之所以这里减去77是因为浏览器不知为什么会减少23像素左右的高度
            this.fullHeight = document.documentElement.clientHeight;
          }
        }
        //  if(this.rightShowMenu) {
        //     this.fullHeight = document.documentElement.clientHeight - 100;
        //  }else if (this.rightShowMenu == false && windowHeight - 100 < this.fullHeight){    //之所以这里减去77是因为浏览器不知为什么会减少23像素左右的高度
        //     this.fullHeight = document.documentElement.clientHeight - 77;
        //  }
      } else if (this.isTemplatePage) {
        if (this.canvasFullscreen) {
          this.fullWidth = document.documentElement.clientWidth;
          this.fullHeight = document.documentElement.clientHeight;
        } else {
          this.fullWidth = document.documentElement.clientWidth;
          this.fullHeight =
            document.documentElement.clientHeight - this.canvasTopPattern1 - this.bottomMenuHeight;
        }
      } else if (this.isSharePage) {
        if (this.isNowPhone) {
          //如果是手机
          this.fullWidth = document.documentElement.clientWidth;
          this.fullHeight = document.documentElement.clientHeight;
        } else {
          //如果不是手机
          if (this.canvasFullscreen) {
            this.fullWidth = document.documentElement.clientWidth;
            this.fullHeight = document.documentElement.clientHeight;
          } else {
            this.fullWidth = document.documentElement.clientWidth;
            this.fullHeight =
              document.documentElement.clientHeight - this.canvasTopPattern1 - this.bottomMenuHeight;
          }
        }
      }
    },
    computedFullSize: function () {
      //是否全屏触发的函数。
      let windowWidth = document.documentElement.clientWidth;
      let windowHeight = document.documentElement.clientHeight;
      if (!this.isTemplatePage && !this.isSharePage) {
        if (this.canvasFullscreen) {
          this.fullWidth = document.documentElement.clientWidth;
          this.fullHeight = document.documentElement.clientHeight;
        } else {
          if (this.rightShowMenu) {
            this.fullWidth = document.documentElement.clientWidth - 291;
          } else {
            this.fullWidth = document.documentElement.clientWidth;
          }

          this.fullHeight =
            document.documentElement.clientHeight - this.canvasTopPattern1;
        }
      } else if (this.isTemplatePage) {
        if (this.canvasFullscreen) {
          this.fullWidth = document.documentElement.clientWidth;
          this.fullHeight = document.documentElement.clientHeight;
        } else {
          this.fullWidth = document.documentElement.clientWidth;
          this.fullHeight =
            document.documentElement.clientHeight - this.canvasTopPattern1 - this.bottomMenuHeight;
        }
      } else if (this.isSharePage) {
        if (this.isNowPhone) {
          //如果是手机
          this.fullWidth = document.documentElement.clientWidth;
          this.fullHeight = document.documentElement.clientHeight;
        } else {
          //如果不是手机
          if (this.canvasFullscreen) {
            this.fullWidth = document.documentElement.clientWidth;
            this.fullHeight = document.documentElement.clientHeight;
          } else {
            this.fullWidth = document.documentElement.clientWidth;
            this.fullHeight =
              document.documentElement.clientHeight - this.canvasTopPattern1 - this.bottomMenuHeight;
          }
        }
      }
    },

    changeMindContentView: function (data, index, checkNodeViewOffScreen = true) {
      //改变数据
      //计算框的长宽
      if (data == null || data.isEmpty()) {
        return;
      }
      if (this.editMindmapContent.mindDisplayType == MindDisplayType.Outline) {
        //TODO outline changeMindContentView
        //大纲
        this.bus.$emit("changeMindContentView", data, index);
      } else {
        let view = this.viewElementList.get(data.id);
        if (view == null || view == undefined) {
          this.addMindContentView(data, index, checkNodeViewOffScreen);
          return;
        }
        // if (data.textContent != null) {
        //   console.log("data-> ", data.x, data.y, data.textContent.text);
        // }
        view.setData(data);
        if (!view.isRefresh) {
          if (data.type == MindElementType.LAYOUT_FISH_RIGHT_LINE ||
            data.type == MindElementType.LAYOUT_CUSTOM_LINE ||
            data.type == MindElementType.NODE_CONNECT_LINE ||
            data.type == MindElementType.LAYOUT_RADIATE ||
            data.layout == NodeLayoutType.LAYOUT_RADIATE) {
            view.bringToFront();
          }
          if (data.isHandwritingContent()) {
              if ((data.handwritingContent.type == HandwritingType.CIRCULAR ||
                  data.handwritingContent.type == HandwritingType.RECTANGLE) &&
                      !Colors.isClear(data.handwritingContent.color)) {
                  view.bringToBottom();
              } else {
                  view.bringToFront();
              }
          }
          return;
        }
        view.bringToFront();
        if (data.isHandwritingContent() && (data.handwritingContent.type == HandwritingType.CIRCULAR ||
                data.handwritingContent.type == HandwritingType.RECTANGLE) &&
                !Colors.isClear(data.handwritingContent.color)) {
            view.bringToBottom();
        } else {
            view.bringToFront();
        }
        
        view.setViewRect(data.width, data.height, data.x, data.y);
        view.refresh();
        this.checkNodeViewOffScreen(data);

        this.editmapScrollView.controllMindSize.refreshButtonPosition(data); //TODO
      }
    },
    elementMindViewSeparator: function (data) {
      let editmap = document.getElementById("editmap");
      let linemap = document.getElementById("linemap");

      let basemap = document.getElementById("basemap");

      if (data.isStatisticsContent()) {
        // return new StatisticsElementView(editmap, data);
        return new StatisticsElementView(
          editmap,
          data,
          this,
          this.DrawingBoardBackgroundColor
        );
      } else if (data.isHandwritingContent()) {
        return new HandwritingView(editmap, data);
      }
      switch (MindElementType.from(data.type)) {
        case MindElementType.MAIN_SUBJECT:
          return new MainSubjectInputView(editmap, data, this);
        case MindElementType.SUBJECT:
        case MindElementType.FREE_NODE:
          return new SubjectInputView(editmap, data, this);
        case MindElementType.SON_SUBJECT:
          return new SonSubjectInputView(editmap, data, this);
        case MindElementType.EXPLAIN:
          return new FloatExplainView(editmap, data, this);
        case MindElementType.LINE:
        case MindElementType.LAYOUT_FISH_RIGHT_LINE:
        case MindElementType.LAYOUT_CUSTOM_LINE:
          return new SubjectLineView(linemap, data);
        // return new SubjectLineView(editmap, data);
        case MindElementType.SON_LINE:
          return new SonSubjectLineView(linemap, data);
        // return new SonSubjectLineView(editmap, data);
        case MindElementType.NODE_CONNECT_LINE:
          return new NodeConnectLineView(editmap, data, this);
        // return new NodeConnectLineView(editmap, data);
        case MindElementType.LEFTBRACELINE:
          return new LeftBraceLineView(linemap, data);
        // return new LeftBraceLineView(editmap, data);
        case MindElementType.BAOWEI_VIEW:
          return new EncircleLineView(basemap, data);
        // return new EncircleLineView(editmap, data);
        case MindElementType.TIME_LINE_BASE_ELEMENT:
          return new TimeLineView(linemap, data, this);
        // return new TimeLineView(editmap, data);

        case MindElementType.CONTENT_GENERALIZATION:
          return new GeneralizationView(editmap, data, this);
        case MindElementType.FORM_LINE:
          return new FormLineView(linemap, data);
        // return new FormLineView(editmap, data);
        default:
      }
      return null;
    },

    close: function () {},
    removeElementView: function (data) {
      if (data == null) {
        return;
      }
      this.bus.$emit("onRemoveGeneralization", data);
      if (!this.viewElementList.containsKey(data.id)) {
        return;
      }
      let view = this.viewElementList.get(data.id);
      view.removeView();
      this.viewElementList.remove(data.id);
      if (
        this.editmapScrollView.resetMindView != null &&
        this.editmapScrollView.resetMindView.getData() != null &&
        this.editmapScrollView.resetMindView.getData().id == data.id
      ) {
        this.hideResetMindView();
      }
      this.editmapScrollView.hideCircle();
      this.hideOperateFlowChartView();
      this.editmapScrollView.hideAddChildNodeButton();
    },
    saveImagePng: function (rect, watermark, customWatermarkData) {},
    saveImagePdf: function (rect, name) {},
    getPreviewImage: function (rect, isHD) {},

    showTips: function (value, isDelayHidden) {
      this.showTipsMessageAccout = true;
      // eee.ss = 1
      setTimeout(() => {
        this.showTipsMessageAccout = false;
      }, 2000);
      this.tipsMessage = value;
    },

    showTipsMenuData: function (value, accout) {
      this.showTipsMessageAccout = true;
      if (accout == false) {
        setTimeout(() => {
          this.showTipsMessageAccout = false;
        }, 2000);
      }
      this.tipsMessage = value;
    },

    hideTips: function () {},

    changMindBackgroundColor: function (value = -1) {
      this.editMindmapContent.changMindBackgroundColor(parseInt(value));
      this.editmapScrollView.setBackgroundColor(parseInt(value));
      this.checkAddIconColor(parseInt(value));
      this.checkScaleIconBackground(parseInt(value));
      this.saveImageBgcolor(value);

      this.DrawingBoardBackgroundColor = parseInt(value);
      this.topToolDardarkMode(this.DrawingBoardBackgroundColor); //判断是否是深色模式

      // this.checkScaleIconBackground(parseInt(value))
      // this.saveImageBgcolor(value)

      this.setMapTextColor();
      this.setNodeUpNumberViewColor();
      this.setCheckBorderColor();
    },
    setMapTextColor() {
      //设置饼状图、柱状图、折线图的文字颜色
      let color = Colors.getUiColor(this.DrawingBoardBackgroundColor);
      let keys = this.viewElementList.keys();
      // let isDarkColor = Colors.isLightColor(this.DrawingBoardBackgroundColor);
      // let newcolor = "";
      // if (isDarkColor) {
      //   // newcolor = Colors.getUiColor(Colors.black50);
      //   newcolor = Colors.black50;
      // } else {
      //   // newcolor = Colors.getUiColor(Colors.white15);
      //   newcolor = Colors.white15;
      // }
      for (let index = 0; index < keys.length; index++) {
        let view = this.viewElementList.get(keys[index]);
        if (view.mindElementData.isStatisticsContent()) {
          view.setBackgroundColor(this.DrawingBoardBackgroundColor);
        }

        //  case MindElementType.MAIN_SUBJECT:
        //   return new MainSubjectInputView(editmap, data);
        // case MindElementType.SUBJECT:
        // case MindElementType.FREE_NODE:
        //   return new SubjectInputView(editmap, data);
        // case MindElementType.SON_SUBJECT:
      }
    },
    setNodeUpNumberViewColor() {
      //设置隐藏节点按钮的背景颜色。
      let keys = this.viewElementList.keys();
      for (let index = 0; index < keys.length; index++) {
        let view = this.viewElementList.get(keys[index]);
        if (
          view.mindElementData.hiddenNumber != 0 &&
          view.mindElementData.textContent != null
        ) {
          let lineColor = view.mindElementData.borderColor;
          if (
            Colors.isClear(lineColor) ||
            view.mindElementData.borderWidth == 0
          ) {
            lineColor = view.mindElementData.textContent.textColor;
          }
          let bgColor = this.DrawingBoardBackgroundColor;
          // let bgColor;
          view.mindNodeUpNumberView.setColor(bgColor, lineColor);
        }
      }
    },

    hideAddNodeIcon(currentId) {
      let keys = this.viewElementList.keys();
      for (let index = 0; index < keys.length; index++) {
        let view = this.viewElementList.get(keys[index]);
        if (view.mindElementData != null && 
          view.mindElementData.id != currentId &&
          view.hideAddNodeIcon != null) {
          view.hideAddNodeIcon(false)
        }
      }
    },

    showMindKeyInputView() {
      this.mindPasswordPanelModal = true
      this.mindPasswordPlaceholder = getString(strings.Please_Enter_Mind_Password_For_Unlock)
      this.mindPassword = "";
    },

    setCheckBorderColor() {
      this.editmapScrollView.resetMindView.checkAllColor(
        this.DrawingBoardBackgroundColor
      );
    },

    checkAddIconColor: function (newColor) {
      let isDarkColor = Colors.isLightColor(newColor);
      let color = "";
      let fontColor = "";
      if (isDarkColor) {
        fontColor = "#666";
      } else {
        fontColor = "#fff";
      }

      if (!isDarkColor) {
        color = "#666";
      } else {
        color = "#fff";
      }

      let editSavebg = document.querySelectorAll(".edit-accout")[0];
      let saveButton = document.querySelectorAll(".save-button")[0];
      //let playButton = document.querySelectorAll(".iconfont-mindmap-play");

      //编辑颜色
      if (editSavebg == undefined) {
        return;
      }
      editSavebg.style.background = fontColor;
      editSavebg.style.color = color;

      //保存按钮颜色
      if (saveButton == undefined) {
        return;
      }
      saveButton.children[0].style.color = color;

      // 播放按钮颜色
      // for (let i = 0; i < playButton.length; i++) {
      //   playButton[i].style.color = fontColor;
      // }
    },

    mindContentDisplay: function (rect, isScale = true) {
      var left = rect.x;
      var top = rect.y;
      var widthScale = 1.0;
      var heightScale = 1.0;
      var scale = 1.0;
      var spaceH = 10.0;
      var spaceV = 60.0;
      if (isScale) {
        if (rect.width() > new UiUtil().getScreenWidth() - spaceH) {
          widthScale =
            1.0 -
            (rect.width() - new UiUtil().getScreenWidth() + spaceH) /
              rect.width();
        } else {
          widthScale =
            1.0 +
            (new UiUtil().getScreenWidth() + spaceH - rect.width()) /
              rect.width();
        }
        if (rect.height() > new UiUtil().getScreenHeight() - spaceV) {
          heightScale =
            1.0 -
            (rect.height() - new UiUtil().getScreenHeight() + spaceV) /
              rect.height();
        } else {
          heightScale =
            1.0 +
            (new UiUtil().getScreenHeight() + spaceV - rect.height()) /
              rect.height();
        }
        scale = Math.min(widthScale, heightScale);
        if (scale > 1.0) {
          scale = 1.0;
        } else if (scale < 0.8) {
          scale = 0.8;
        }

        this.editmapScrollView.setScale(scale);
      } else {
        scale = this.editmapScrollView.getScale();
      }
      
      left = (left + rect.width() / 2) * scale - new UiUtil().getScreenWidth() / 2;
      top = (top + rect.height() / 2) * scale - new UiUtil().getScreenHeight() / 2; 
      this.editmapScrollView.scrollTo(left, top);
    },
    sharedImage: function (rect) {},
    saveFile: function (id, mindType, mindPreviewID, images) {},
    sharedDarkdownFile: function (id, mindType, mindPreviewID) {},
    saveIcloudFile: function (id, mindType, mindPreviewID, images) {},
    setBackwardRetreatEnable: function (enable) {},
    setForwardEnable: function (enable) {},
    showAddLinkPanel: function (targetNode) {
      this.setShowAddLink(true);
      let linkText = targetNode.value.linkElementContent;
      if (linkText != null) {
        this.setAddLinkContent(
          linkText.links.filter((arr) => {
            return arr != 0;
          })
        );
      } else {
        this.setAddLinkContent([]);
      }
    },
    showAddAiPanel: function (targetNode) {
      this.setShowAddAi(true);
    },
    showRemarksPanel: function (targetNode) {
      this.setShowAddRemark(true);
      let remarksText = targetNode.value.remarksElementContent;
      if (remarksText != null) {
        this.setAddRemarkContent(remarksText.text);
      } else {
        this.setAddRemarkContent();
      }
    },
    openSelecteConnectMapPanel: function (targetNode) {},
    openOutlineDisplayView: function () {
      this.showDaGangModel(true);
    }, //显示大纲
    mindmapDisplayView: function () {
      this.showDaGangModel(false);
      this.editMindmapContent.moveRootNodeCenterDisplay()
    }, //显示导图
    onInputBecomeFirstResponder: function (id) {
      if (this.editMindmapContent.mindDisplayType == MindDisplayType.Outline) {
        this.outlineNodeId = id;
        setTimeout(() => {
          this.bus.$emit("onInputBecomeFirstResponder", id);
        }, 50);
      }
    },
    setMindDisplayType: function (type) {},

    setNodeToEdit: function (id) {
      if (this.isTemplatePage || this.isSharePage) {
        return;
      }
      let node = this.editMindmapContent.getNodeById(id);
      if (!this.viewElementList.containsKey(id) || node.isEmpty()) {
        return;
      }
      this.onDoubleClickSelected(node.value);
    },

    clearViewSelected: function () {},
    openBlackboardView: function (
      nodeId,
      nodeText,
      mindmapId,
      mindPreviewID,
      mindType
    ) {},

    getDisplayContentWindowSize() {
      return new Size(this.fullWidth, this.fullHeight);
    },

    hideThemePanel: function () {},

    moveDrawingBoard: function (moveX, moveY, mindData) {
      let scale = this.editmapScrollView.getScale();
      if (mindData == null) {
        this.editmapScrollView.constrainScrollBy(scale * moveX, scale * moveY); 
      } else {
        let left = this.editmapScrollView.getContentScrollX();
        let top = this.editmapScrollView.getContentScrollY();

        let contentX = left / scale;
        let contentY = top / scale;

        let rect = new CGRect(
          contentX,
          contentY,
          this.fullWidth / scale,
          this.fullHeight / scale
        );
        let space = 40 / scale;
        if (
          mindData.x + mindData.width + space > rect.x + rect.width() ||
          mindData.x - space < rect.x ||
          mindData.y + mindData.height + space > rect.y + rect.height() ||
          mindData.y - space < rect.y
        ) {
          this.editmapScrollView.constrainScrollBy(scale * moveX, scale * moveY);
        }
      }
    },

    moveXYDrawingBoardToCenter: function (x, y) {
      let scale = this.editmapScrollView.getScale();

      let left = x * scale - new UiUtil().getScreenWidth() / 2;
      let top = y * scale - new UiUtil().getScreenHeight() / 2; 
      this.editmapScrollView.scrollTo(left, top);
    },

    getMindMapCentralPoint() {
      return this.editmapScrollView.getMindMapCentralPointInScreen();
    },

    checkNodeViewToScreen(data) {
      if (data == null || data.isEmpty()) {
        return;
      }
      this.checkNodeViewOffScreen(data, false);
    },

    checkNodeViewOffScreen(data, isCheckPlayStatus = true) {
      if (isCheckPlayStatus && EditMindmapVM.getMindMapPlayStatus() != MindMapPlayStatus.PLAY) {
        return;
      }
      this.editmapScrollView.checkRectOffScreen(
        new CGRect(data.x, data.y, data.width, data.height),
        false,
        false
      );
    },

    resetDrawingBoardSize: function (size) {
      this.editmapScrollView.setSize(size.getWidth(), size.getHeight());
    },
    clearNodeSelectedStatus: function (nodeId) {
      //取消选中节点
      if (this.editMindmapContent != null) {
        this.editMindmapContent.clearSelected(false);
      }

      let targetView = this.getMindElementView(nodeId);

      if (targetView != null) {
        if (targetView.mindElementData.type == MindElementType.NODE_CONNECT_LINE) {
          targetView.setSelectedHoverLayer(false);
        }
        targetView.setSelected(false);
        targetView.mindElementData.isEdit = false;
      }
      let keys = this.viewElementList.keys();
      for (let index = 0; index < keys.length; index++) {
        let view = this.viewElementList.get(keys[index]);
        if (view != null && view.isSelected) {          
          if (view.mindElementData.type == MindElementType.NODE_CONNECT_LINE) {
            view.setSelectedHoverLayer(false);
          }
          view.setSelected(false);
        }
      }
    },
    removeConnectLineShapeView: function () {},
    hiddenMindMenu: function () {
      // if (mindMenuController != null) {
      //   mindMenuController.removeMenu();
      // }
    },
    createMindMenuController() {
      // if (mindMenuController == null) {
      //   mindMenuController = new MindMenuController(EditMindmapVM, this);
      // }
    },
    selectedRectInDrawingBoard(rect) {
      // let widthChange =
      //   this.drawingBoardScrollView.getContentWidth() *
      //   (this.drawingBoardScrollView.getScale() - 1);
      // let heightChange =
      //   this.drawingBoardScrollView.getContentHeight() *
      //   (this.drawingBoardScrollView.getScale() - 1);
      // let contentX =
      //   Math.abs(this.drawingBoardScrollView.getContentX() - widthChange / 2) /
      //   this.drawingBoardScrollView.getScale();
      // let contentY =
      //   Math.abs(this.drawingBoardScrollView.getContentY() - heightChange / 2) /
      //   this.drawingBoardScrollView.getScale();
      // let left = rect.left / this.drawingBoardScrollView.getScale() + contentX;
      // let top = rect.top / this.drawingBoardScrollView.getScale() + contentY;
      // let right =
      //   rect.right / this.drawingBoardScrollView.getScale() + contentX;
      // let bottom =
      //   rect.bottom / this.drawingBoardScrollView.getScale() + contentY;
      // let globalRect = new CGRect(left, top, right, bottom);
      // this.createMindMenuController();
    },
    outlineNewLineDOM(doms) {
      this.outlineSiblingDom = doms;
    },

    getMindElementView(nodeId) {
      if (this.viewElementList.containsKey(nodeId)) {
        return this.viewElementList.get(nodeId);
      }
      return null;
    },

    addMindContentViewIncludeAnimation(data, isAnimation, speed) {
      this.addMindContentView(data, -1, true);
      let view = this.getMindElementView(data.id);
      if (
        view == null ||
        view.setAnimation == null ||
        view.setAnimation == undefined ||
        speed == undefined ||
        speed <= 0
      ) {
        return;
      }
      view.setAnimation(isAnimation, speed);
    },

    changeMindContentViewPointAnimation(data, point, toPoint, speed) {
      let view = this.getMindElementView(data.id);
      if (view == null || speed <= 0) {
        return;
      }
      view.setPointByAnimation(point, toPoint, speed);
    },

    addMindContentView: function (data, index, checkNodeViewOffScreen = true) {
      // console.log("addMindContentView-> ", Util.getCurrentTime() - Config.startTime); 

      if (this.editMindmapContent.mindDisplayType == MindDisplayType.Outline) {
        //将大纲数据传递给 leftOutline
        this.bus.$emit("addMindContentView", data, index);
      } else {
        if (this.viewElementList.containsKey(data.id)) {
          this.changeMindContentView(data);
        } else {
          let viewCell = this.elementMindViewSeparator(data);

          if (viewCell != null) {
            viewCell.isTemplatePage = this.isTemplatePage;
            viewCell.isSharePage = this.isSharePage;

            if ((data.type == MindElementType.MAIN_SUBJECT ||
                data.type == MindElementType.SUBJECT ||
                data.type == MindElementType.SON_SUBJECT ||
                data.type == MindElementType.EXPLAIN ||
                data.type == MindElementType.CONTENT_GENERALIZATION) && !data.isHandwritingContent()) {
              viewCell.mindTextSizeChangedDelegate = this;
              viewCell.setClickListener();
              viewCell.refresh();
            }
            this.viewElementList.put(data.id, viewCell);
            if (checkNodeViewOffScreen) { 
              this.checkNodeViewOffScreen(data);
            }
          }
        }
      }
    },

    onTextSizeChanged: function (text, width, height, id, type) {
      if (this.isTemplatePage || this.isSharePage) {
        return;
      }
      this.refreshNodeText(text, id);
      this.editMindmapContent.onTextSizeChanged(text, width, height, id, type);
    },

    refreshNodeText(text, id) {
      if (this.isTemplatePage || this.isSharePage || id == null || text == null) {
        return;
      }
      let targetView = this.viewElementList.get(id)      
      if (targetView != null && targetView.isClearText && targetView != null && targetView.mindElementData != null && 
          targetView.mindElementData.textContent != null && text == targetView.mindElementData.textContent.text) {
        targetView.refresh()
      } 
    },

    onMousedownHiddenChildren(id) {
      this.editMindmapContent.hiddenNodeChilds(id);
    },
    onMousedownShowChildren(id) {
      this.editMindmapContent.showNodeChildsTrue(id);
    },

    onSelectedImg: function (object) {
      // {data, point:new Point(-1, -1), mindElementData: this.mindElementData,event,clickType}
      // console.log(object,this.isTemplatePage,this.isSharePage);
      this.isImageSeleced = true;
      if (this.isTemplatePage || this.isSharePage) {
        if (object.clickType == 0) {
          if (!object.data.isVideo()) {
            //只有模板和分享
            // let obj = {};
            // obj.src = object.data.key;
            let imgList = this.editMindmapContent.getAllImageUrlList(-1);
            let obj = {};
            obj.src = object.data.key;
            obj.imgUrlList = imgList;
            this.bus.$emit("examineImg", obj);
          } else {
            let objVideo = {};
            objVideo.src = object.data.key;
            objVideo.videoSrc = object.data.videoUrl;
            this.bus.$emit("examineVideoImg", objVideo);
          }
        }
      } else {
        // if (object.clickType == 2) { //如果时右击
        // object.mindThis.mindElementData.imageContent
        let x = object.mindThis.mindElementData.x;
        let y = object.mindThis.mindElementData.y;
        let width = object.mindThis.mindElementData.imageContent.width;
        let height = object.mindThis.mindElementData.imageContent.height;
        let rect = new CGRect(x, y, width, height);
        this.editmapScrollView.resetMindView.setRect(
          object.mindThis.mindElementData,
          "image",
          rect,
          object.mindThis
        );
        // }
      }
    },
    concatJumpUrl(url) {
      if (url.startsWith("http")) {
        return url;
      }
      return "http://" + url;
    },
    onSelectedIcon: function (nodeId, iconData, icon, event, clickType) {
      this.moreNodeId = null;
      if (clickType === 0) {
        //如果是单击链接或者单击备注图标。
        let node = this.editMindmapContent.getNodeById(nodeId);
        if (node.isEmpty()) {
          return;
        }
        this.onSelected(node.value, new Point(-1, -1));
        if (iconData.key == "mind_link_icon") {
          let url = this.concatJumpUrl(node.value.linkElementContent.links[0]);
          if (this.$tools.isElectron()) {
            ipcRenderer.send("open-url", url);
          } else {
            this.createATagJump(url);
          }
        } else if (iconData.key == "remarks_icon") {
          if (this.isTemplatePage || this.isSharePage) {
            this.showRemarksPanel(node);
          } else {
            this.editMindmapContent.openRemarksPanel();
          }
        } else if (iconData.key == "lianxian_map_icon") {
          let token = "";
          if (this.$route.name == "s") {
            if (this.shareToken == "") {
              token = this.$route.params.id;
            }
          }
          // this.editMindmapContent.openConnectMapByNode(node.value.id,token);
          //查看关联导图
          this.viewAssociatedMap(node.value.id, token);
        } else if (iconData.key == "mind_element_more") {
          this.moreMenu = true;
          this.moreNodeId = nodeId;
        } else if (
          iconData.key == "mind_element_xcl" ||
          iconData.key == "mind_element_pdf" ||
          iconData.key == "mind_element_word" ||
          iconData.key == "mind_element_txt" ||
          iconData.key == "mind_element_voide" ||
          iconData.key == "mind_element_audio" ||
          iconData.key == "mind_element_image" ||
          iconData.key == "mind_element_attchment"
        ) {
          if (this.$route.name == "s") {
            if (!this.initShareMindDatas.viewAttachment) {
              this.$message.error("用户关闭了查看附件功能");
              return;
            }
          }
          this.openOrDownResource(node);
        }
        let obj = {};
        obj.nodeId = nodeId;
        obj.iconData = iconData;
        this.iconNode = obj;

        //TODO 菜单
        let menuElement = document.getElementById("elementIconMenuList");
        menuElement.style.opacity = 0; //由于菜单是隐藏的时候获取不到宽高，所以要先显示一下。但是这会让菜单在上一个位置闪一下。所以先设置成透明的。

        setTimeout(() => {
          //不延时无法获取菜单的宽高
          this.computedMenuPoint(event, menuElement);
          menuElement.style.opacity = 1;
        }, 5);
      } else {
        if (this.isTemplatePage || this.isSharePage) {
          return;
        }
        let node = this.editMindmapContent.getNodeById(nodeId);
        if (node.isEmpty()) {
          return;
        }
        this.onSelected(node.value, new Point(-1, -1));
        if (iconData.key == "mind_link_icon") {
          this.showLinkMenu = true; //显示连接菜单
        } else if (iconData.key == "remarks_icon") {
          this.abstractMenu = true;
        } else if (iconData.key == "lianxian_map_icon") {
          this.showAssMapLink = true;
        } else if (iconData.key == "mind_element_more") {
          this.moreMenu = true;
          this.moreNodeId = nodeId;
        } else if (
          iconData.key == "mind_element_xcl" ||
          iconData.key == "mind_element_pdf" ||
          iconData.key == "mind_element_word" ||
          iconData.key == "mind_element_txt" ||
          iconData.key == "mind_element_voide" ||
          iconData.key == "mind_element_audio" ||
          iconData.key == "mind_element_image" ||
          iconData.key == "mind_element_attchment"
        ) {
          if (this.$route.name == "s") {
            if (!this.initShareMindDatas.viewAttachment) {
              this.$message.error("用户关闭了查看附件功能");
              return;
            }
          }
          this.showResourceModel = true;
        } else {
          this.showElementIconMenuList = true; //显示图标菜单
        }
        let obj = {};
        obj.nodeId = nodeId;
        obj.iconData = iconData;
        this.iconNode = obj;
        //TODO 菜单
        let menuElement = document.getElementById("elementIconMenuList");
        menuElement.style.opacity = 0; //由于菜单是隐藏的时候获取不到宽高，所以要先显示一下。但是这会让菜单在上一个位置闪一下。所以先设置成透明的。

        setTimeout(() => {
          //不延时无法获取菜单的宽高
          this.computedMenuPoint(event, menuElement);
          menuElement.style.opacity = 1;
        }, 5);
      }
    },
    // getMindmapView(){
    //   postMindmapView(
    //     { urls: obj },
    //     (res) => {
    //       resolve(res);
    //     },
    //     (error) => {
    //       reject(error);
    //     }
    //   );
    // },
    //打开或者下载附件
    openOrDownResource(node) {
      let downLink = this.httpImageToPrefix(node.value.resourceContent.url);
      if (this.$tools.isElectron()) {
        //打开浏览器啊下载
        var fs = window.require("fs");
        var path = window.require("path");
        let desdirpath = node.value.resourceContent.url.substring(0,
          node.value.resourceContent.url.lastIndexOf("/"));

        let desfilename = node.value.resourceContent.url.substring(
                node.value.resourceContent.url.lastIndexOf("/") + 1);
        desdirpath =
          desdirpath.indexOf("http") > -1
            ? desdirpath.replaceAll("://", "/")
            : desdirpath;
        desdirpath =
          desdirpath.indexOf(":") > -1
            ? desdirpath.replaceAll(":", "/")
            : desdirpath;
        let cacheDir = localStorage.getItem("cacheDir");
        let newDesdirpath;
        if (this.$tools.isWindows()) {
          newDesdirpath = desdirpath.replaceAll("/", "\\") + "\\" + desfilename;
          desdirpath = desdirpath.replaceAll("/", "\\");
          cacheDir = cacheDir.replaceAll("/", "\\");
          newDesdirpath = cacheDir + "\\" + newDesdirpath;
        } else {
          newDesdirpath = desdirpath.replaceAll("\\", "/") + "/" + desfilename;
          desdirpath = desdirpath.replaceAll("\\", "/");
          cacheDir = cacheDir.replaceAll("\\", "/");
          newDesdirpath = cacheDir + "/" + newDesdirpath;
        }
        if (LoadLocalXJMind.existsLocalFile(downLink)) {
            openSystemUrl(LoadLocalXJMind.getLocalFilePath(downLink, false));
        } else if (fs.existsSync(newDesdirpath)) {
          openSystemUrl(newDesdirpath);
        } else {
          let obj = {};
          obj.url = downLink;
          obj.desc = newDesdirpath;
          ipcRenderer.send("http-download-and-open", obj);
        }
      } else {
        window.open(downLink);
      }
    },
    viewAssociatedMap(id, token) {
      //查看关联导图
      this.exitEditStatusSaveMap();
      let timer = setInterval(() => {
        const finishSavingMapVal = this.$store.state.finishSavingMap;
        if (finishSavingMapVal.fulfill) {
          this.editMindmapContent.openConnectMapByNode(id, token);
          this.setFinishSavingMap({ fulfill: false, result: false });
          clearTimeout(timer);
        }
      }, 200);
    },
    //悬浮图标展示菜单
    isShowIconMenu(type, data, iconNode) {
      // setTimeout(() => {
      //   let menuElement =  document.querySelector(".MenuIcon-priority-bigbox");
      //   //不延时无法获取菜单的宽高
      //   this.computedMenuPoint(event, menuElement);
      //   menuElement.style.opacity = 1;
      // }, 5);
      // let data={
      //    x:this.newMenuX+30,
      //    y:this.newMenuY+60
      //  }
      if (this.$store.state.isMindmapPlay) {
        //判断是否要导图播放
        return;
      }
      this.showIconType = type;
      this.showIconX = data.x + this.editmapScrollView.getContentScrollX();
      this.showIconY = data.y + this.editmapScrollView.getContentScrollY();
      this.iconNode = iconNode;
      this.tintColor = iconNode.iconData.tintColor;
      this.showIconMenu = true;
    },
    continuousShow() {
      clearTimeout(this.iconHideTime);
    },
    hideIconMenu() {
      this.showIconMenu = false;
    },
    //移除图标
    removeIconMenu: function (obj) {
      this.hideIconMenu();
      EditMindmapVM.removeIcon(this.iconNode.nodeId, this.iconNode.iconData);
    },
    //悬浮字体样式
    isShowFontStyleMenu(obj, showFontStyleMenu) {
      if (this.$store.state.isMindmapPlay) {
        //判断是否要导图播放
        return;
      }
      if (obj.x == null || obj.y == null || obj.x == "" || obj.y == "") {
        this.showFontStyleMenu = showFontStyleMenu;
      } else {
        this.showFontStyleX = obj.x;
        this.showFontStyleY = obj.y;
        this.showFontStyleHeight = obj.inputHeight;
        this.showScale = obj.scale;
        this.showFontStyleMenu = showFontStyleMenu;
        this.screenRect = obj.screenRect;
      }
      // console.log("---showFontStyleMenu---", showFontStyleMenu);
    },

    isClickFontStyleMenu(x, y) {
      let elements = document.getElementsByClassName("ComplieRightMenuIconDialog");
      if (!this.showFontStyleMenu || elements.length == 0 ||
          elements[0].style.left == null || elements[0].style.top == null) {
        return false
      }
      let point = (new UiUtil()).getViewPoint(elements[0])
      let size = (new UiUtil()).getViewSize(elements[0])
      if (size == null || size.width <= 0 || size.height <= 0) {
        size = new Size(340, 50)
      } 
      return Util.containsInRectForXY(new CGRect(point.x, point.y + this.canvasTopPattern1, size.width, size.height), x, y)
    },

    //字体样式修改传参
    hideFontStyle(val) {
      if (val.styleName != null && val.styleName != "") {
        this.fontStyleName = val.styleName;
      }
      // this.showFontStyleMenu = false;
      this.updateStyle(val);
    },

    continuousFontStyleShow() {
      if (this.showFontStyleMenuTime != null && this.showFontStyleMenuTime > -1) {
        clearTimeout(this.showFontStyleMenuTime);
      }
      // this.showFontStyleMenu = true;
    },
    hideFontStyleShow() {
      this.showFontStyleMenu = false;
    },
    //字体样式修改
    updateStyle(obj) {
      this.editmapScrollView.updateStyle(obj);
    },

    onSelectedForMoreSelect(data) {
      let keys = this.viewElementList.keys();
      for (let index = 0; index < keys.length; index++) {
        let view = this.viewElementList.get(keys[index]);
        if (view.mindElementData.id == data.id) {
          view.setSelected(true, false);
          break;
        }
      }
    },

    onElementViewClickUp(data, point) {
      if (EditMindmapVM.getMindMapPlayStatus() != MindMapPlayStatus.NORMAL) {
        if (EditMindmapVM.getMindMapPlayStatus() == MindMapPlayStatus.PLAY) {
          this.pausePlayMap();
        }
        return;
      }

      if (this.isTemplatePage || this.isSharePage) {
        return;
      }
      if (this.editMindmapContent.mindDisplayType == MindDisplayType.Outline) {
        this.editMindmapContent.onSelected(data);
        this.setNodeContentMessage(data);
        return;
      }
      if (data.id == EditMindmapVM.selectedNodeId) {
        let clentPoint = this.editmapScrollView.getScrollPointByClentPoint(
          point.x,
          point.y
        );
        if (data.hitText(clentPoint) && this.previewSelectedNodeId == data.id) {
          setTimeout(() => {
            this.setNodeToEdit(data.id);
          }, 50);
          return;
        } else if (data.hitExplain(clentPoint)) {
          this.addSubscript(data);
        }
      }
    },

    onElementViewClickDown: function (data, point, add, event) {
      if (data != null && this.editMindmapContent.isDataSelected(data.id)) {
        return
      }
      if (EditMindmapVM.selectedNodeId != IdGenerator.INVALID_ID) {
        this.previewSelectedNodeId = EditMindmapVM.selectedNodeId;
      } else {
        this.previewSelectedNodeId = IdGenerator.INVALID_ID;
      }
      this.onSelected(data, point, add, event);
    },

    showChangeNodeShape(data, direction) {
      this.showLeftShapeView = true
      let baseX = this.editmapScrollView.getContentScrollX();
      let baseY = this.editmapScrollView.getContentScrollY();
      let screenRect = this.editmapScrollView.getScreenRect();
      let scale = this.editmapScrollView.getScale()
      let space = 24 * scale
      let left = ((data.x + data.width / 2) * scale - 100);
      let top = ((data.y + data.height) * scale + space);
      if (direction != null) {
        if (direction == FlowChartDirection.LEFT || 
            direction == FlowChartDirection.RIGHT) {
          left = ((data.x + data.width / 2) * scale - 100);
          top = ((data.y + data.height) * scale + space);
        } else {
          left = ((data.x + data.width) * scale + space);
          top = data.y * scale;
        }
      }
      if (left + 200 > baseX + screenRect.width() * scale) {
        left = data.x * scale - (200 + space);
      }
      if (top < baseY) {
        top = data.y * scale;
      } else if (top + 242 > baseY + screenRect.height() * scale) {
        top = ((data.y) * scale - (242 + space));//baseY + screenRect.height() * scale - 242;
      }
      let newNodeChangeShape = document.getElementById("newNodeChangeShape")
      if (newNodeChangeShape != null && newNodeChangeShape.style != null) {        
        newNodeChangeShape.style.left = left + "px";
        newNodeChangeShape.style.top =  top + "px";
      } else {
        setTimeout(() => {
          let newNodeChangeShape = document.getElementById("newNodeChangeShape")
          if (newNodeChangeShape != null && newNodeChangeShape.style != null) {
            newNodeChangeShape.style.left = left + "px";
            newNodeChangeShape.style.top =  top + "px";
          } 
        }, 500);
      }
    },

    mouseDownAddNodeNode: function (data, point) {
      this.onElementViewClickDown(data, point, { type: "mousedown" });
      EditMindmapVM.selectedNodeConnectLineStatus(ConnectLineType.RIGHT_ANGLE_LINE, false);
    },

    onSelected: function (data, point, add, event) {
      this.isImageSeleced = false;
      //this.viewElementList.get(data.id).context.
      // alignNode((e) => {
      //   //注册键盘事件
      //   let keyDown =
      //     e || event || window.event || arguments.callee.caller.arguments[0];
      // });
      //这个函数在选中的时候会调用，无是节点还是包裹线还是连接线。
      //这里不需要限制选中，否则备注展示不了。
      if (EditMindmapVM.getMindMapPlayStatus() != MindMapPlayStatus.NORMAL) {
        if (EditMindmapVM.getMindMapPlayStatus() == MindMapPlayStatus.PLAY) {
          this.pausePlayMap();
        }
        return;
      }
      if (this.isTemplatePage || this.isSharePage) {
        return;
      }
      if (this.editMindmapContent.mindDisplayType == MindDisplayType.Outline) {
        this.editMindmapContent.onSelected(data);
        this.setNodeContentMessage(data);
        return;
      }
      if (this.lastDblclickMindData != null) {
        if (this.editmapScrollView.isShowInput()) {
          //将上一次双击的输入框隐藏
          this.editmapScrollView.hideInput();
        }
      }
      // this.editmapScrollView.hideCircle() //隐藏控制圆形
      this.showRelevanceLineMenu = false; //隐藏关联线菜单
      this.lineColorColorAccout = false
      this.isShowFreeNodeMenu = false; //隐藏添加自由节点菜单
      let showRelevance; //是否展示关联线菜
      this.showElementMenuList = false; //隐藏菜单
      this.showTaskMenuList = false; //任务进度紧急相关菜单
      this.showLaTeXMenuList = false; //latex右键菜单
      this.lastClickMindData = data; //保存当前点击的节点,用于菜单
      this.showEncircleMenu = false; //隐藏包裹线菜单
      this.showLinkMenu = false; //隐藏链接菜单
      this.abstractMenu = false; //隐藏备注菜单
      this.moreMenu = false; //隐藏更多菜单
      this.moreNodeId = null; //清空更多菜单里面的数据
      this.showAssMapLink = false; //隐藏关联菜单
      this.showResourceModel = false; //隐藏附件菜单
      this.showElementIconMenuList = false; //隐藏图标菜单
      this.showIconMenu = false; //隐藏icon弹框
      this.hiddenBottonArray = []; //隐藏按钮数组清空
      this.hiddenMindButtonArray = []; //隐藏按钮数组清空
      //由于有些时候鼠标会拖动出画布，所以造成无法监听松开拖动节点事件（这里有一个隐藏辅助线的方法），所以这里添加一个隐藏辅助线。
      this.editmapScrollView.hidenGuideLine(); //隐藏辅助线

      if (
        this.$store.state.setAddIconPage == null ||
        this.$store.state.setAddIconPage.type == "ADD_Canvas"
      ) {
        this.setAddIconPage("ADD_STYLE");
      }

      if (data.generalizationContent != null) {
        this.setNodeContentMessage(data);
      }
      this.baoweiMessage = data.type;
      this.baoweiMessageToFreeNode = data.type;
      //連接線 NODE_CONNECT_LINE 包圍//BAOWEI_VIEW
      this.setNodeContentMessage(data);
      if (add == undefined || add.type != "smallIcon") {
        this.editMindmapContent.onSelected(data); //选中的节点。
        this.editmapScrollView.setAddFlowChartView(data)
      }
      let keys = this.viewElementList.keys();
      for (let index = 0; index < keys.length; index++) {
        let view = this.viewElementList.get(keys[index]);
        let isSelected = view.mindElementData.id == data.id;
        view.setSelected(isSelected);
        if (isSelected) {
          this.selectMindView = view;
        }
      }
      if (this.isTemplatePage || this.isSharePage) {
        return;
      } else {
        showRelevance = this.editmapScrollView.showCircle(data);
        if (showRelevance) {
          //是否展示线条
          if (event != undefined) { // && event.button == 2) {
            setTimeout(() => {
              this.lineColorColorAccout = false
              this.showRelevanceLineMenu = true;
            }, 100);
          }
          let menuElement = document.getElementById(
            "complie-left-content-line-menu"
          );
          this.setNodeContentMessage(data);
          menuElement.style.opacity = 0; //由于菜单是隐藏的时候获取不到宽高，所以要先显示一下。但是这会让菜单在上一个位置闪一下。所以先设置成透明的。
          setTimeout(() => {
            //不延时无法获取菜单的宽高
            this.computedMenuPoint(this.mapEvent, menuElement);
            menuElement.style.opacity = 1;
          }, 5);
        } else {
          this.editmapScrollView.hideCircle();
        }
      }
      this.setResetMindView(data);      
      if (add != undefined && add.type == "smallIcon") {
        //单击添加图标触发
        //如果有第三个参数，就添加子元素
        let MindOperateUIControllerVie = new MindOperateUIControllerView(
          this.editMindmapContent,
          this.editMindmapContent
        );
        if (add.direction != null) {
          this.editmapScrollView.hideInput();
          this.editMindmapContent.addFlowChartNode(data.id, null, add.direction)
        } else {
          this.editMindmapContent.onSelected(data); //选中的节点。
          MindOperateUIControllerVie.onSelected(MindElementType.ADD);
        }
      } else if (add != undefined && add.type == "rightClick") {
        this.setDataMenu(data, event, point)
      } else if (add != undefined && add.type == "completionClick") {
        if (event.button == 2) {
          this.MindTaskMenuType = "completion";
          this.showTaskMenuList = true;
          let menuTask = document.getElementById("taskList");
          menuTask.style.opacity = 0; //由于菜单是隐藏的时候获取不到宽高，所以要先显示一下。但是这会让菜单在上一个位置闪一下。所以先设置成透明的。
          setTimeout(() => {
            //不延时无法获取菜单的宽高
            this.computedMenuPoint(event, menuTask);
            menuTask.style.opacity = 1;
          }, 5);
        }
      } else if (add != undefined && add.type == "weightClick") {
        if (event.button == 2) {
          this.MindTaskMenuType = "weight";
          this.showTaskMenuList = true;
          let menuTask = document.getElementById("taskList");
          menuTask.style.opacity = 0; //由于菜单是隐藏的时候获取不到宽高，所以要先显示一下。但是这会让菜单在上一个位置闪一下。所以先设置成透明的。
          setTimeout(() => {
            //不延时无法获取菜单的宽高
            this.computedMenuPoint(event, menuTask);
            menuTask.style.opacity = 1;
          }, 5);
        }
      } else if (add != undefined && add.type == "editClick") {
        if (event.button == 2) {
          this.MindTaskMenuType = "edit";
          this.showTaskMenuList = true;

          let menuTask = document.getElementById("taskList");
          menuTask.style.opacity = 0; //由于菜单是隐藏的时候获取不到宽高，所以要先显示一下。但是这会让菜单在上一个位置闪一下。所以先设置成透明的。
          setTimeout(() => {
            //不延时无法获取菜单的宽高
            this.computedMenuPoint(event, menuTask);
            menuTask.style.opacity = 1;
          }, 5);
        }
      } else if (add != undefined && add.type == "latexEditClick") {
        if (event.button == 2) {
          this.MindLaTeXMenuType = "edit";
          this.showLaTeXMenuList = true;

          let menuTask = document.getElementById("LatexList");
          menuTask.style.opacity = 0; //由于菜单是隐藏的时候获取不到宽高，所以要先显示一下。但是这会让菜单在上一个位置闪一下。所以先设置成透明的。
          setTimeout(() => {
            //不延时无法获取菜单的宽高
            this.computedMenuPoint(event, menuTask);
            menuTask.style.opacity = 1;
          }, 5);
        }
      }

      //判断是否为包裹线
      if (data != null &&
          data.lineContent != null &&
          data.type == MindElementType.BAOWEI_VIEW &&
          event != undefined &&
          event.button == 2) {
        this.showEncircleMenu = true;
        let menuElement = document.getElementById("complie-left-content-line-menu");

        //隐藏的菜单类型
        if (data.lineContent.backgroundUrl == null ||
          data.lineContent.backgroundUrl == "") {
          this.hiddenBottonArray.push(
            strings.Mind_Edit_Node_Menu_Delete_Encircle_Background
          );
        }

        this.setAddIconPage("ADD_STYLE");
        this.editMindmapContent.getNodeById(data.id);
        this.setNodeContentMessage(data);
        menuElement.style.opacity = 0; //由于菜单是隐藏的时候获取不到宽高，所以要先显示一下。但是这会让菜单在上一个位置闪一下。所以先设置成透明的。
        setTimeout(() => {
          //不延时无法获取菜单的宽高
          this.computedMenuPoint(this.mapEvent, menuElement);
          menuElement.style.opacity = 1;
        }, 5);
      }
        // let divHeight = this.editmapScrollView.getViewPxValue(data.height);
        // // const pos  = {x:window.event.x,y:window.event.target.getBoundingClientRect().y,inputHeight:divHeight};
        // let dataPoint = this.editmapScrollView.getEventAndDataPointByRoot(window.event, data)
        // const pos = {x: dataPoint.x, y: dataPoint.y, 
        //           inputHeight:divHeight,
        //           screenRect:this.editmapScrollView.getScreenRect(),
        //           scale:this.editmapScrollView.scale};
        // this.isShowFontStyleMenu(pos,true);

      // this.dataNode = this.editMindmapContent.selectedNodeId;
      this.setTsakSelectedNodeId(this.editMindmapContent.selectedNodeId);
      // abstractMenu
    },

    setDataMenu(data, event, point) {
      let time = Util.getCurrentTime()
      if (this.showElementMenuList &&
          this.showElementIconMenuLastTime != null &&
          (time - this.showElementIconMenuLastTime < 100)) {
            return
      }
      
      this.showElementIconMenuList = false; //隐藏图标菜单
      this.showIconMenu = false; //隐藏icon弹框
      this.showLinkMenu = false; //隐藏链接菜单
      this.abstractMenu = false; //隐藏备注菜单
      this.moreMenu = false; //隐藏更多菜单
      this.moreNodeId = null; //清空更多菜单里面的数据
      this.showAssMapLink = false; //隐藏关联菜单
      this.showResourceModel = false; //隐藏附件菜单
      this.showElementIconMenuLastTime = time
      if (!this.editMindmapContent.isHasCopyStyle()) {
        this.hiddenMindButtonArray.push(
          strings.Mind_Edit_Node_Menu_Sticking_Style
        );
      }
      if (!this.editMindmapContent.isHasCopyNode()) {
        let that = this
        that.hiddenMindButtonArray.push(strings.Mind_Edit_Node_Menu_Paste);
        that.hiddenMindButtonArray.push(strings.Mind_Node_Overlay_Paste);
        Util.arrayDeleteValue(that.hiddenMindButtonArray, strings.Mind_Edit_Node_Menu_Paste)
      }
      if (data.imageContent == null) {
        //显示删除图片和查看图片、删除视频
        this.hiddenMindButtonArray.push(
          strings.Mind_Edit_Node_Delete_View_Video
        );
        this.hiddenMindButtonArray.push(
          strings.Mind_Edit_Node_Menu_Save_Picture
        );
        this.hiddenMindButtonArray.push(
          strings.Mind_Edit_Node_Menu_Delete_Picture
        );
        this.hiddenMindButtonArray.push(
          strings.Mind_Edit_Node_Menu_View_Picture
        );
      } else {
        if (data.imageContent.isVideo()) {
          this.hiddenMindButtonArray.push(
            strings.Mind_Edit_Node_Menu_Delete_Picture
          );
          this.hiddenMindButtonArray.push(
            strings.Mind_Edit_Node_Menu_View_Picture
          );
        } else {
          this.hiddenMindButtonArray.push(
            strings.Mind_Edit_Node_Menu_View_Video
          );
          this.hiddenMindButtonArray.push(
            strings.Mind_Edit_Node_Delete_View_Video
          );
        }
      }
      if (data.type == MindElementType.MAIN_SUBJECT) {
        //主节点不显示删除
        this.hiddenMindButtonArray.push(strings.Mind_Mindmap_Deleted_Forever);
      }

      if (data.backgroundUrl == null || data.backgroundUrl == "") {
        this.hiddenMindButtonArray.push(strings.Mind_Delete_Background_Image);
      }

      if (data.isStatisticsContent()) {
        //如果是图表
        this.MindMenuType = "chart";
      } else {
        this.MindMenuType = "mind";
      }

      let node = EditMindmapVM.getSelectedNode();
      if (node.value.type == MindElementType.EXPLAIN) {
        this.isExplain = true;
      } else {
        this.isExplain = false;
      }
      //控制菜单的逻辑
      this.showElementMenuList = true;
      this.lineColorColorAccout = false;
      let menuElement = document.getElementById("elementMenuList");
      menuElement.style.opacity = 0; //由于菜单是隐藏的时候获取不到宽高，所以要先显示一下。但是这会让菜单在上一个位置闪一下。所以先设置成透明的。
      setTimeout(() => {
        //不延时无法获取菜单的宽高
        this.computedMenuPoint(event, menuElement);
        menuElement.style.opacity = 1;
      }, 5);
    },

    mindMapPlayComplete() {
      this.mindmapPlayPauseShowSetup({
        showSetup: true,
        playAccout: "",
      });
    },
    /**
     * 重新播放和暂停后继续播放
     */
    playMap() {
      this.mindmapPlayPauseShowSetup({
        showSetup: false,
        playAccout: "",
      });
      EditMindmapVM.replayMap();
    },

    /**
     * 播放过程中点击屏幕或者按空格键
     */
    pausePlayMap() {
      if (EditMindmapVM.getMindMapPlayStatus() == MindMapPlayStatus.PLAY) {
        EditMindmapVM.pausePlayMap();
        this.mindmapPlayPauseShowSetup({
          showSetup: true,
          playAccout: "",
        });
      } else if (
        EditMindmapVM.getMindMapPlayStatus() == MindMapPlayStatus.PAUSE
      ) {
        this.playMap();
      }
    },

    //结束播放
    stopPlayMap() {
      this.drawingBoardScrollEnabled(true);
      EditMindmapVM.stopPlayMap();
      this.mindmapPlayPauseShowSetup({
        showSetup: false,
        playAccout: "OVER",
      });
      this.isMindmapPlay(false);
      this.clearPlayBackgroundImage();
    },
    //获取光标开始位置
    getSelectionStart(id) {
      if (
        this.editmapScrollView == null ||
        this.lastDblclickMindData == null ||
        this.lastDblclickMindData.id != id
      ) {
        return -1;
      }
      return this.editmapScrollView.getSelectionStart(id);
    },

    //获取光标结束位置
    getSelectionEnd(id) {
      if (
        this.editmapScrollView == null ||
        this.lastDblclickMindData == null ||
        this.lastDblclickMindData.id != id
      ) {
        return -1;
      }
      return this.editmapScrollView.getSelectionEnd(id);
    },

    refreshMindLayout() {
      if (this.editmapScrollView.resetMindView != null &&
        this.editmapScrollView.resetMindView.isShow()) {
        this.setResetMindView(this.editmapScrollView.resetMindView.getData());
      }
      if (this.editmapScrollView != null &&
        this.editmapScrollView.isShowInput() &&
        this.lastDblclickMindData != null &&
        this.lastDblclickMindData.id == this.editMindmapContent.selectedNodeId) {
        if (this.refreshInputMindLayoutTimeout != -1) {
          clearTimeout(this.refreshInputMindLayoutTimeout);
        }

        this.refreshInputMindLayoutTimeout = -1;
        if (this.editmapScrollView.isShowInput()) {
          if (this.lastDblclickMindData.textContent != null && 
            (this.editmapScrollView.typeNode == null || this.editmapScrollView.typeNode == "" || this.editmapScrollView.typeNode == "textContent") ) {
            this.editmapScrollView.setInput(
              this.lastDblclickMindData,
              "textContent",
              "refresh"
            );
          } else if (this.lastDblclickMindData.generalizationContent != null) {
            this.editmapScrollView.setInput(this.lastDblclickMindData, "generalizationContent");
          } else if (this.lastDblclickMindData.lineContent != null &&
            this.lastDblclickMindData.lineContent.textContent != null) {
            this.editmapScrollView.setInput(this.lastDblclickMindData,"lineContent");
          }
        }
      }
      if (this.viewElementList != null) {
            let removeList = [];
            let keys = this.viewElementList.keys();
            for (let index = 0; index < keys.length; index++) {
              let view = this.viewElementList.get(keys[index]);
                if (view.mindElementData == null) {
                    continue;
                }
                if (view.mindElementData.type == MindElementType.SUBJECT ||
                        view.mindElementData.type == MindElementType.SON_SUBJECT ||
                        view.mindElementData.type == MindElementType.BAOWEI_VIEW ||
                        view.mindElementData.type == MindElementType.CONTENT_GENERALIZATION) {
                    let node = this.editMindmapContent.getNodeById(view.mindElementData.id);
                    if (node.isEmpty()) {
                        removeList.push(view.mindElementData);
                    }
                } else if (view.mindElementData.type == MindElementType.LINE ||
                        view.mindElementData.type == MindElementType.FORM_LINE ||
                        view.mindElementData.type == MindElementType.LAYOUT_FISH_RIGHT_LINE ||
                        view.mindElementData.type == MindElementType.LAYOUT_CUSTOM_LINE ||
                        view.mindElementData.type == MindElementType.LEFTBRACELINE ||
                        view.mindElementData.type == MindElementType.SON_LINE) {
                    let line = this.editMindmapContent.getLineDataById(view.mindElementData.id);
                    if (line.isEmpty()) {
                        removeList.push(view.mindElementData);
                    }
                }
            }
            removeList.forEach((data) => {
                this.removeElementView(data);
            })
        }
    },

    hideResetMindView() {
      if (
        this.editmapScrollView.resetMindView != null &&
        this.editmapScrollView.resetMindView.isShow()
      ) {
        this.editmapScrollView.resetMindView.hidden();
      }
    },

    setResetMindView(data) {
      if (this.mindType == MindType.FLOW_CHART &&
          (data.type == MindElementType.MAIN_SUBJECT || 
            data.type == MindElementType.SUBJECT ||
            data.type == MindElementType.CONTENT_GENERALIZATION)) {
          this.editmapScrollView.resetMindView.setRect(
            data,
            'free',
            new CGRect(data.x, data.y, data.width, data.height)
          );
          return;
      }
      if (
        data.type != MindElementType.BAOWEI_VIEW &&
        data.type != MindElementType.CONTENT_GENERALIZATION
      ) {
        this.hideResetMindView();
        return;
      }
      let rect = this.editMindmapContent.getSelectedGRect(data.id);
      if (data.type == MindElementType.BAOWEI_VIEW) {
        rect = new CGRect(data.x, data.y, data.width, data.height);
      }
      if (this.editMindmapContent.isResetViewFree(data)) {
          this.editmapScrollView.resetMindView.setRect(
            data,
            'free',
            rect
          );
      } else if (this.editMindmapContent.isResetViewHorizontal(data)) {
        this.editmapScrollView.resetMindView.setRect(
          data,
          ResetMindType.LEFT_RIGHT,
          rect
        );
      } else {
        this.editmapScrollView.resetMindView.setRect(
          data,
          ResetMindType.TOP_BOTTOM,
          rect
        );
      }
    },

    setResizeNode(rect) {
      this.editMindmapContent.resizeNode(rect);
    },

    onMoveLineControllPoint: function (point, isStart) {
      if (this.isTemplatePage || this.isSharePage) {
        return;
      }
      this.editMindmapContent.lineControlPointMove(point.x, point.y, isStart);
    },

    onMoveLinePoint: function (point, isStart) {
      if (this.isTemplatePage || this.isSharePage) {
        return;
      }
      this.editMindmapContent.linePointMove(point.x, point.y, isStart);
    },

    onMoveRightAngleLinePoint: function (point, index) {
      if (this.isTemplatePage || this.isSharePage) {
        return;
      }
      this.editMindmapContent.rightAngleLinePointMove(point.x, point.y, index);
    },

    changeDataAngle: function (value, id) {
      if (this.isTemplatePage || this.isSharePage) {
        return;
      }
      this.editMindmapContent.changeDataAngle(value, id);
    },

    computedMenuPoint: function (event, menuElement, timeOut = false) {
      //计算当前菜单的位置
      if (event == undefined || event == null) {
        return;
      }
      let top = 0;
      if (!this.canvasFullscreen) {
        //判断如果不是全屏。然后加上顶部距离
        top = this.canvasTopPattern1;
      }
      let baseX = this.editmapScrollView.getContentScrollX()
      let baseY = this.editmapScrollView.getContentScrollY()

      let x = event.pageX + baseX;
      let y = event.pageY - top + baseY; //因为整个画布向下移动了this.canvasTopPattern1像素。
      let elementWidth = menuElement.offsetWidth; //菜单的宽度
      let elementHeight = menuElement.offsetHeight; //菜单的高度
      if (elementWidth == 0) {
        if (!timeOut) {
          setTimeout(() => {
            this.computedMenuPoint(event, menuElement, true)
          }, 200);
          this.newMenuX = 0;
          this.newMenuY = 0;
          return;
        } else {
          elementWidth = 160
        }
      }
      let menuExcursionX = 40; //菜单水平偏移
      let windowHeight = document.documentElement.clientHeight;
      let boxWidth = this.fullWidth;
      let line = EditMindmapVM.getSelectedNode()
      if (line != null && line.value.type == MindElementType.NODE_CONNECT_LINE) {
        let rect = new NodeConnectLineCalcul().getConnectLineRect(line.value)
        let scale = this.editmapScrollView.getScale()
        if ((rect.x + rect.width())*scale + menuExcursionX + elementWidth > boxWidth + baseX) {
          if (rect.x * scale - menuExcursionX - elementWidth < baseX) {
            x = baseX + 10
          } else {
            x = rect.x * scale - menuExcursionX - elementWidth
          }
        } else {
          x = ((rect.x + rect.width()) * scale) + menuExcursionX
        }
        this.newMenuX = x;
        this.newMenuY = y;
        return
      }
      
      if (x + elementWidth + menuExcursionX > boxWidth + baseX) {
        x -= elementWidth + menuExcursionX;
      } else {
        x += menuExcursionX;
      }

      if (elementHeight + top + baseY > windowHeight + baseY) {
        y = baseY;
      } else {
        if (windowHeight - y - top > elementHeight + baseY) {
          y = y;
        } else if (y > elementHeight + baseY) {
          y = y - elementHeight;
        } else {
          if (
            y >= elementHeight / 2 + baseY&&
            windowHeight + baseY - y - top >= elementHeight / 2
          ) {
            y = y - elementHeight / 2;
          } else if (
            y >= elementHeight / 2 + baseY &&
            windowHeight + baseY - y - top < elementHeight / 2
          ) {
            y = baseY;
          } else if (
            y < elementHeight / 2 &&
            windowHeight - y - top >= elementHeight / 2
          ) {
            y = baseY;
          } else if (
            y < elementHeight / 2 + baseY &&
            windowHeight + baseY - y - top < elementHeight / 2
          ) {
            y = baseY;
          }
        }
      }
      
      this.newMenuX = x;
      this.newMenuY = y;
    },
    onDoubleClickSelected: function (data, point, objType) {
      //统计图
      if (EditMindmapVM.getMindMapPlayStatus() != MindMapPlayStatus.NORMAL) {
        if (EditMindmapVM.getMindMapPlayStatus() == MindMapPlayStatus.PLAY) {
          this.pausePlayMap();
        }
        return;
      }
      
      if (data.statisticsContent != null) {
        //如果是统计图
        if (this.checkIsPreviewStatusAndToEdit()) {//进入编辑状态
          return;
        }
        this.useShortcut = false;
        this.chartContent({ accout: true, data: data });
      }
      if (this.lastDblclickMindData != null) {
        if (this.editmapScrollView.isShowInput()) {
          //将上一次双击的输入框隐藏
          this.editmapScrollView.hideInput();
        }
      }
      this.lastDblclickMindData = data;
      this.editMindmapContent.onSelected(data);
      if (data.statisticsContent == null) {
        //如果是编辑框，不是统计图
        if (objType != null && objType.button == "subScript") {
          if (this.checkIsPreviewStatusAndToEdit()) {//进入编辑状态
            return;
          }
          this.editmapScrollView.isEditSubscript = true;
          this.editmapScrollView.setInput(data, "setSubscript");
        } else if (objType != null && objType.button == "titleDoubleClick") {
          if (this.checkIsPreviewStatusAndToEdit()) {//进入编辑状态
            return;
          }
          this.editmapScrollView.isEditCardTitle = true;
          this.editmapScrollView.setInput(data, "setCardTitleEdit");
        } else {
          if (this.checkIsPreviewStatusAndToEdit()) {//进入编辑状态
            return;
          }
          this.editmapScrollView.setInput(data);
          
          if (data != null && (data.type == MindElementType.SUBJECT || data.type == MindElementType.SON_SUBJECT) &&
              data.textContent != null && data.backgroundColorAlpha < 1.0) {
            let targetView = this.viewElementList.get(data.id);            
            if (targetView != null && targetView.clearText != null) {
              targetView.clearText()
            }
          }
        }
      }
      this.showElementMenuList = false;
      this.lineColorColorAccout = false
    },
    hideTips: function () {},

    openMenu(e, data, index) {
      this.mouseY = e.pageY;
      this.mouseX = e.pageX;
    },
    allClick: function () {
      // this.showBox = false;
    },

    getTextColor: function (textColor) {
      this.editMindmapContent.changeTextColor(textColor);
    },
    getTextFontSize: function (textFontSize) {
      this.editMindmapContent.changeTextSize(this.textContent.textFontSize);
    },
    getTextStyle: function (textStyle) {
      this.editMindmapContent.changeTextStyle(textStyle);
    },
    getBorderColor: function (borderColor) {
      this.editMindmapContent.changeBorderColor(borderColor);
    },
    getFillColorColor: function (fillColor) {
      this.editMindmapContent.changeFillColorColor(fillColor);
    },
    getLineMinorColor: function (LineMinorColor) {
      this.editMindmapContent.changeLineColor(LineMinorColor);
    },
    getLineMinorWidth: function (LineMinorWidth) {
      this.editMindmapContent.changeLineWidth(LineMinorWidth);
    },
    getBorderlLine: function (borderline) {
      this.editMindmapContent.changeBorderlLine(borderline);
    },
    getNodeWidth: function (nodeWidth) {
      this.editMindmapContent.changeNodeWidth(nodeWidth);
    },
    getAddImgData: function (url, id, width, height) {
      this.editMindmapContent.addImage(
        url,
        id.id,
        width,
        height,
        this.rightMenuClickPoint
      );
      this.rightMenuClickPoint = new CGPoint(-1, -1);
    },
    getAddVideoData: function (videoUrl, url, id, width, height) {
      this.editMindmapContent.addVideo(
        videoUrl,
        url,
        id,
        width,
        height,
        this.rightMenuClickPoint
      );
      this.rightMenuClickPoint = new CGPoint(-1, -1);
    },
    getImgSize: function (width, height) {
      this.editMindmapContent.changeImageSize(width, height);
    },
    getImgPlace: function (imgPlace) {
      this.editMindmapContent.changeImageShape(imgPlace);
    },
    getImgLayout: function (imgLayout) {
      this.editMindmapContent.changeImageLayout(imgLayout);
    },
    getNodeStyle: function (nodestyle) {
      this.editMindmapContent.changeNodeType(nodestyle);
      this.editmapScrollView.setAddFlowChartView(this.editMindmapContent.getSelectedNode());
    },
    setEctrilineStyle: function (nodestyle) {
      this.editMindmapContent.changeEncircleLineType(nodestyle);
    },
    setEncircleLinePadding: function (value) {
      this.editMindmapContent.changeEncircleLinePadding(value);
    },
    setEncircleLineTextSizeNumber: function (value) {
      this.editMindmapContent.changeEncircleLineTextSize(value);
    },
    getIconSize: function (iconSize) {
      this.editMindmapContent.changeIconSize(iconSize);
    },
    getIconPlace: function (iconPlace) {
      this.editMindmapContent.changeIconLayout(iconPlace);
    },
    getLineColor: function (LineColor) {
      this.editMindmapContent.changeLineColor(LineColor);
    },
    getLineWidth: function (LineWitdh) {
      this.editMindmapContent.changeLineWidth(LineWitdh);
    },
    getLineType: function (linetype) {
      this.editMindmapContent.selectedNodeConnectLineStatus(linetype);
    },
    getBaoWeiLineColor: function (baoWeiLineColor) {
      this.editMindmapContent.changeBaoweiLineColor(baoWeiLineColor);
    },
    getBaoWeiFillColor: function (baoWeiFillColor) {
      this.editMindmapContent.changeBaoweiFullAndConnectLineColor(
        baoWeiFillColor
      );
    },
    getTextShadowColor: function (color) {
      this.editMindmapContent.changeTextShadowColor(color);
    },
    getDottedLineAccout: function (DottedLineAccout) {
      this.editMindmapContent.changeLineToNodeColor(DottedLineAccout);
    },
    getKeepAccout: function () {
      this.getTextStlye("keep");
    },
    getImgAccout: function () {
      this.setDomImg("");
    },
    getNodeStyleAccout: function () {
      this.setNodeType(null);
    },
    getLineTypeAccout: function () {
      this.getLineTypeLayout(null);
    },

    getMindElementDataInDrawingBoardByPoint: function (point) {
      let data = this.editMindmapContent.getMindElementDataByPoint(point);

      // if (data.isEmpty()) {
      //   return data;
      // }
      return data;
    },

    getElemeViewInDrawingBoardByPoint: function (point) {
      let data = this.getMindElementDataInDrawingBoardByPoint(point);
      if (data.isEmpty()) {
        return null;
      }
      if (data.type == MindElementType.BAOWEI_VIEW && this.mindType == MindType.FLOW_CHART) {
        let elementView = this.viewElementList.get(data.id);
        return elementView;   
      }
      switch (data.type) {
        case MindElementType.MAIN_SUBJECT:
        case MindElementType.SUBJECT:
        case MindElementType.SON_SUBJECT:
        case MindElementType.EXPLAIN:
        case MindElementType.CONTENT_GENERALIZATION:
        case MindElementType.NODE_CONNECT_LINE:
          break;
        default:
          return null;
      }
      let elementView = this.viewElementList.get(data.id);
      return elementView;
    },

    changeNodePoint: function (elementView, point) {
      if (this.isTemplatePage || this.isSharePage) {
        return;
      }
      elementView.referenceData = null;
      this.editMindmapContent.changeNodePoint(
        elementView.mindElementData.id,
        point
      );

      // this.editmapScrollView.mindChildshowButton.hideDom();
    },

    getHitMindElementDataByPoint: function (elementView, point) {
      if (this.isTemplatePage || this.isSharePage || this.mindType == MindType.FLOW_CHART) {
        return new MoveMindElementDataInfo();
      }
      elementView.referenceData = null;
      let info = this.editMindmapContent.getHitMindElementDataByPoint(
        elementView.mindElementData.id,
        point
      );
      return info;
    },
    exitEditStatus() {
      // 退出编辑状态
      this.editmapScrollView.hideInput(); // 退出编辑状态，保存导图数据
      this.hideResetMindView();
      this.editmapScrollView.hideAddChildNodeButton();
      this.hideOperateFlowChartView();
      // this.clearAllSelectedView();//取消选中样式
      // this.editMindmapContent.clearSelected();//取消选中
    },

    hideOperateFlowChartView() {
      if (this.editmapScrollView == null) {
        return;
      }
      this.editmapScrollView.hideAddFlowChartDataLeftView();
      this.editmapScrollView.hideDataAngleButton();
    },

    exitEditStatusSaveMap() {
      // 退出编辑状态，保存导图
      this.exitEditStatus();
      EditMindmapVM.saveDataForClosePage();
    },

    exitEditStatusSaveMapByShortcutKeys() {
      // 退出编辑状态，保存导图
      this.exitEditStatus();
      EditMindmapVM.saveContentByShortcutKeys();
    },

    hideAllMenu() {
      this.isShowFreeNodeMenu = false;
      this.showElementMenuList = false;
      this.showTaskMenuList = false;
      this.showLaTeXMenuList = false; 
      this.showEncircleMenu = false;
      this.showLinkMenu = false;
      this.showRelevanceLineMenu = false;
      this.lineColorColorAccout = false
    },

    onClickInDrawingBoard: function (event, point, eventButtonKey) {
      if (EditMindmapVM.getMindMapPlayStatus() != MindMapPlayStatus.NORMAL) {
        if (EditMindmapVM.getMindMapPlayStatus() == MindMapPlayStatus.PLAY) {
          this.pausePlayMap();
        }
        return;
      }
      this.showLeftShapeView = false;
      this.isShowFreeNodeMenu = false;
      this.lineColorColorAccout = false
      this.isShowAlignButton(false);
      this.isShowSameWidth(false);
      this.mapEvent = event;
      let node = this.editMindmapContent.getSelectedNode();
      this.clearAllSelectedView();
      if (!node.isEmpty()) {
        let targetView = this.viewElementList.get(node.value.id);
        if (
          targetView != null &&
          targetView.mindElementData != null &&
          (targetView.mindElementData.type == MindElementType.MAIN_SUBJECT ||
            targetView.mindElementData.type == MindElementType.SUBJECT ||
            targetView.mindElementData.type == MindElementType.SON_SUBJECT ||
            targetView.mindElementData.type == MindElementType.EXPLAIN ||
            targetView.mindElementData.type ==
              MindElementType.CONTENT_GENERALIZATION)
        ) {
          if (
            point.x > targetView.mindElementData.x &&
            point.x <
              targetView.mindElementData.x + targetView.mindElementData.width &&
            point.y > targetView.mindElementData.y &&
            point.y <
              targetView.mindElementData.y + targetView.mindElementData.height
          ) {
            if (eventButtonKey != null && eventButtonKey == 2) {
              this.setDataMenu(node.value, event, point)
            }
            return;
          }
        }
      } else {
        this.editmapScrollView.hideAddChildNodeButton(); //隐藏，隐藏子节点按钮
        this.hideOperateFlowChartView();
      }
      
      this.hideResetMindView();
      let data = this.editMindmapContent.clickPoints(point, event);
      if (data.isEmpty()) {
        this.clearNodeSelectedStatus(this.editMindmapContent.selectedNodeId);
      } else if (eventButtonKey != null && eventButtonKey == 2 &&
                (data.type == MindElementType.MAIN_SUBJECT ||
                data.type == MindElementType.SUBJECT ||
                data.type == MindElementType.SON_SUBJECT ||
                data.type == MindElementType.EXPLAIN ||
                data.type == MindElementType.CONTENT_GENERALIZATION)) {
        this.setDataMenu(data, event, point)
      }
      if (data.isEmpty()) {
        this.editMindmapContent.clearSelected(false);
      } else if (node != null && !node.isEmpty() && node.value.id == data.id) {
        if (data.isHandwritingContent()) {
            this.onSelected(data, point);
        }
        if (data.hitConnectTitle(point)) {
          this.editmapScrollView.setInput(data);
        } else if (data.hitExplain(point)) {
          this.addSubscript(data);
        } else if (data.hitEncircleTitle(point)) {
          this.editmapScrollView.setInput(data);
        }
      }
      if (
        this.baoweiMessage == MindElementType.BAOWEI_VIEW ||
        this.baoweiMessage == MindElementType.NODE_CONNECT_LINE ||
        this.baoweiMessage == MindElementType.LAYOUT_CUSTOM_LINE
      ) {
        this.setAddIconPage("ADD_STYLE");
        this.baoweiMessage = null;
      } else {
        this.setAddIconPage("ADD_Canvas");
        this.setNodeContentMessage(null);
      }
    },
    onAddFreeNodeMenu(event, point) {
      if (this.baoweiMessageToFreeNode == MindElementType.BAOWEI_VIEW) {
        this.baoweiMessageToFreeNode = null;
        return;
      }
      this.point = point;
      if (event.button == 2) {
        this.isShowFreeNodeMenu = true; //显示菜单
        this.newMenuX = event.clientX;
        this.newMenuY = event.clientY;
      }
    },

    isArvhiveMapPrevieStatus() { //是否在归档查看状态
      return (this.routerData == "arvhiveMap" &&
          this.mindMapingType &&
          !this.mindMapingType.value);
    },

    isGroupMapPrevieStatus() { //是否在共享组导图查看状态
      return (this.routerData == "gmap" &&
          this.MindPageType != "groupMapEdit");
    },

    onDoubleClickDrawingBoard: function (event, point) {
      if (this.checkIsPreviewStatusAndToEdit()) {//进入编辑状态
        return;
      }
      if (this.isTemplatePage || this.isSharePage) {
        return;
      }
      // console.log("color ", new UiUtil().isShowColorPanel());
      let node = this.editMindmapContent.getSelectedNode();
      if (!node.isEmpty()) {
        let targetView = this.viewElementList.get(node.value.id);
        if (
          targetView != null &&
          targetView.mindElementData != null &&
          (targetView.mindElementData.type == MindElementType.MAIN_SUBJECT ||
            targetView.mindElementData.type == MindElementType.SUBJECT ||
            targetView.mindElementData.type == MindElementType.SON_SUBJECT ||
            targetView.mindElementData.type == MindElementType.EXPLAIN ||
            targetView.mindElementData.type == MindElementType.CONTENT_GENERALIZATION)) {
          if (point.x > targetView.mindElementData.x &&
            point.x < targetView.mindElementData.x + targetView.mindElementData.width &&
            point.y > targetView.mindElementData.y &&
            point.y < targetView.mindElementData.y + targetView.mindElementData.height) {
            if (node.value.hitImage(point)) {
              this.showMindImage();
            }
            return;
          }
        }

        if (
          targetView != null &&
          targetView.mindElementData != null &&
          targetView.mindElementData.type == MindElementType.BAOWEI_VIEW
        ) {
          if (
            point.x > targetView.mindElementData.x &&
            point.x <
              targetView.mindElementData.x + targetView.mindElementData.width &&
            point.y > targetView.mindElementData.y &&
            point.y <
              targetView.mindElementData.y + targetView.mindElementData.height
          ) {
            this.editmapScrollView.setInput(node.value);
            return;
          }
        }
      }
      // this.clearAllSelectedView();
      // this.editMindmapContent.clearSelected();
      this.editMindmapContent.onDoubleClick(point);
    },
    onClickDrawingBoard(event, point) {
      if (this.isTemplatePage || this.isSharePage) {
        return;
      }

      let node = this.editMindmapContent.getSelectedNode();
      if (!node.isEmpty()) {
        let targetView = this.viewElementList.get(node.value.id);
        if (
          targetView != null &&
          targetView.mindElementData != null &&
          (targetView.mindElementData.type == MindElementType.MAIN_SUBJECT ||
            targetView.mindElementData.type == MindElementType.SUBJECT ||
            targetView.mindElementData.type == MindElementType.SON_SUBJECT ||
            targetView.mindElementData.type == MindElementType.EXPLAIN)
        ) {
          if (
            point.x > targetView.mindElementData.x &&
            point.x <
              targetView.mindElementData.x + targetView.mindElementData.width &&
            point.y > targetView.mindElementData.y &&
            point.y <
              targetView.mindElementData.y + targetView.mindElementData.height
          ) {
            return;
          }
        }

        if (
          targetView != null &&
          targetView.mindElementData != null &&
          targetView.mindElementData.type == MindElementType.BAOWEI_VIEW
        ) {
          if (
            point.x > targetView.mindElementData.x &&
            point.x <
              targetView.mindElementData.x + targetView.mindElementData.width &&
            point.y > targetView.mindElementData.y &&
            point.y <
              targetView.mindElementData.y + targetView.mindElementData.height
          ) {
            this.editmapScrollView.setInput(node.value);
            return;
          }
        }
      }
      this.clearAllSelectedView();
      this.editMindmapContent.clearSelected();
      this.editMindmapContent.addFreeNodeByPoint(point);
    },
    getRemarkContent: function (remarkContent) {
      this.editMindmapContent.changeRemarks(remarkContent);
    },
    addLink: function (linkContent, id) {
      this.editMindmapContent.addLink(linkContent, id);
    },
    removeLink: function (removeLinkContent, id) {
      this.editMindmapContent.removeLink(removeLinkContent, id);
    },
    saveImageBgcolor: function (bgcolor) {
      this.saveImageBgcolors(bgcolor);
    },
    setlineThicken: function (lineThicken) {
      this.editMindmapContent.setlineThicken(lineThicken);
    },
    clearAllSelectedView: function () {
      //取消所有选中节点
      this.editmapScrollView.hideCircle();
      this.selectMindView = null;
      this.isImageSeleced = false;
      let keys = this.viewElementList.keys();
      for (let index = 0; index < keys.length; index++) {
        let view = this.viewElementList.get(keys[index]);
        if (view.mindElementData.type == MindElementType.NODE_CONNECT_LINE) {
          view.setSelectedHoverLayer(false);
        }
        view.setSelected(false);
      }
    },

    clearSelectedView: function (id) {
      //取消所有选中节点
      let view = this.getMindElementView(id)
      if (view != null && view.setSelected != null) {
        view.setSelected(false);
      }
      this.editmapScrollView.hideCircle();
    },

    lineViewHoverView: function (data) {
      if (this.isTemplatePage || this.isSharePage) {
        return;
      }
      if (EditMindmapVM.getMindMapPlayStatus() != MindMapPlayStatus.NORMAL) {
        return
      }
      let keys = this.viewElementList.keys();
      for (let index = 0; index < keys.length; index++) {

        let view = this.viewElementList.get(keys[index]);
        if (view == null || view.mindElementData == null) {
          continue
        }
        if (view.mindElementData.type == MindElementType.BAOWEI_VIEW ||
          view.mindElementData.type == MindElementType.NODE_CONNECT_LINE) {
          if (data == null || data.isEmpty() || 
              view.mindElementData.id != data.id) {
            view.setSelectedHoverLayer(false);
          } else {
            view.setSelectedHoverLayer(true);
          }
        }
      }
    },

    pantograph: function (value) {
      var changeScale = 0.1;
      let scale = this.editmapScrollView.scale;
      if (value) {
        if (scale < 1.0 && scale + changeScale > 1.0) {
          changeScale = 1.0 - scale;
        }
      } else {
        if (scale > 1.0 && scale - changeScale < 1.0) {
          changeScale = scale - 1.0;
        }
      }
      if (value) {
        scale += changeScale;
        this.editmapScrollView.setScale(scale);
      } else {
        scale -= changeScale;
        this.editmapScrollView.setScale(scale);
      }
    },
    centeredViewModal: function () {
      //让画布居中
      setTimeout(() => {
        this.editMindmapContent.setMindContentCenterDisplay(true, false);
      }, 100);
    },
    checkIsPreviewStatusAndToEdit() {
       if (this.isArvhiveMapPrevieStatus() || this.isGroupMapPrevieStatus()) {
        this.bus.$emit("editMindMap", true);//进入编辑状态
        return true;
      }
      return false;
    },
    checkMenuElement: function (obj) {
      
      if (obj.name == strings.Global_Edit) {
        if (this.checkIsPreviewStatusAndToEdit()) {
          this.showElementMenuList = false;
          return;
        }
        this.onDoubleClickSelected(this.lastClickMindData);
      } else if (obj.name == strings.Mind_Association_Map) {
        if (this.checkIsPreviewStatusAndToEdit()) {
          this.showElementMenuList = false;
          return;
        }
        // 关联导图
        if (!getJwt("jwt")) {
          this.showLoginModel(true);
          this.showElementMenuList = false;
          return;
        }
        if (this.editMindmapContent.selectedNodeId == IdGenerator.INVALID_ID) {
          this.editMindmapContent.selectAddTargetTips();
          this.showElementMenuList = false;
          return;
        }
        this.setIsAssociationModel(true);
      } else if (obj.name == strings.Mind_Association_Node) {
        // 连接线
        if (this.checkIsPreviewStatusAndToEdit()) {
          this.showElementMenuList = false;
          return;
        }
        if (this.mindType == MindType.FLOW_CHART) {
          this.getLineTypeLayout(ConnectLineType.RIGHT_ANGLE_LINE);
        } else {
          this.getLineTypeLayout(ConnectLineType.CURVE_LINE);
        }
      } else if (obj.name == strings.Mind_Edit_Insert_Remarks) {
        // 插入备注
        this.editMindmapContent.openRemarksPanel();
      } else if (obj.name == strings.Mind_Edit_Insert_Link) {
        this.editMindmapContent.openLinkPanel();
      } else if (obj.name == strings.Mind_Usinghelp_9) {
        //插入图片
        if (this.checkIsPreviewStatusAndToEdit()) {
          this.showElementMenuList = false;
          return;
        }
        var meMessage = localStorage.getItem("meMessage");
        if (meMessage != null && meMessage != undefined && meMessage != "") {
        } else {
          this.showLoginModel(true);
          this.showElementMenuList = false;
          return;
        }
        // this.$el.querySelector(".hiddenImgInput").click();

        this.materialLibraryModel(false);
        this.materialLibraryModel(true);
      } else if (obj.name == strings.Mind_Scan_Extraction) {
        //节点扫描提取
        if (this.checkIsPreviewStatusAndToEdit()) {
          this.showElementMenuList = false;
          return;
        }
        if (!getJwt("jwt")) {
          this.showLoginModel(true);
        } else {
          this.setOcrScanModel({
            value: true,
            type: ocrScanExtractTypes.ScanExtractionNode,
          });
        }
      } else if (obj.name == strings.Mind_add_Resource) {
        //添加附件
        if (this.checkIsPreviewStatusAndToEdit()) {
          this.showElementMenuList = false;
          return;
        }
        var meMessage = localStorage.getItem("meMessage");
        if (meMessage != null && meMessage != undefined && meMessage != "") {
        } else {
          this.showLoginModel(true);
          this.showElementMenuList = false;
          return;
        }
        // this.$el.querySelector(".hiddenImgInput").click();

        this.setResourceModel(false);
        this.setResourceModel(true);
      } else if (obj.name == strings.Mind_Edit_Insert_Video) {
        if (this.checkIsPreviewStatusAndToEdit()) {
          this.showElementMenuList = false;
          return;
        }
        var meMessage = localStorage.getItem("meMessage");
        if (meMessage != null && meMessage != undefined && meMessage != "") {
        } else {
          this.showLoginModel(true);
          this.showElementMenuList = false;
          return;
        }

        // if(!meMessage.isMember){
        // }
        // this.$el.querySelector(".hiddenImgInput").click();

        this.materiaVideoModal(false);
        this.materiaVideoModal(true);
      } else if (obj.name == strings.Mind_Usinghelp_14) {
        if (this.checkIsPreviewStatusAndToEdit()) {
          this.showElementMenuList = false;
          return;
        }
        this.setmaterialLibraryModulesModel({ accout: true, type: "bbg" });
      } else if (obj.name == strings.Mind_Mindmap_Deleted_Forever) {
        if (this.checkIsPreviewStatusAndToEdit()) {
          this.showElementMenuList = false;
          return;
        }
        this.editMindmapContent.removeNode();
      } else if (obj.name == strings.Mind_Edit_Node_Menu_Copy_Style) {
        this.editMindmapContent.setCopyStyleId(this.lastClickMindData.id);
      } else if (obj.name == strings.Mind_Edit_Node_Menu_Ai_Label) {
        this.editMindmapContent.openAiPanel();
      } else if (obj.name == strings.Mind_Node_To_Card_Label) {
        this.editMindmapContent.setNodeToCard();
      } else if (obj.name == strings.Mind_Edit_Node_Menu_Sticking_Style) {
        // this.isShowPasteStyle = this.editMindmapContent.isHasCopyStyle()
        if (this.checkIsPreviewStatusAndToEdit()) {
          this.showElementMenuList = false;
          return;
        }
        this.editMindmapContent.stickStyle(this.lastClickMindData.id);
      } else if (obj.name == strings.Mind_Edit_Node_Menu_Copy) {
        this.editMindmapContent.setCopyNodeId(this.lastClickMindData.id);
        this.copyPicture2Clipboard();
      } else if (obj.name == strings.Mind_Edit_Node_Menu_Paste) {
        if (this.checkIsPreviewStatusAndToEdit()) {
          this.showElementMenuList = false;
          return;
        }
        let that = this;
        if (this.editMindmapContent.isHasCopyNode()) {
          this.$confirm({
              title: getString(strings.Mind_Tips),
              content: getString(strings.Keep_Original_Style_Request),
              centered: true,
              okText: getString(strings.Keep_Original_Style),
              cancelText: getString(strings.Keep_FollowCurrent),
              onOk() {
                that.editMindmapContent.stickNode(
                  that.lastClickMindData.id,
                  null,
                  true
                );
              },
              onCancel() {
                that.editMindmapContent.stickNode(
                  that.lastClickMindData.id,
                  null,
                  false
                );
              },
            });
        } else {
          navigator.clipboard.readText().then( function(clipText) {
            if (clipText != null && clipText.length > 0) {
              that.editMindmapContent.replaceNodeString(that.editMindmapContent.selectedNodeId, clipText)
            }
          });
          navigator.clipboard.read().then((data) => {
              for (let i = 0; i < data.length; i++) {
                if (data[i].types.includes("image/png")) {
                  data[i].getType("image/png").then((blob) => {
                    new ImageFileUpload(that, null).upload(blob);
                  });
                }
              }
            });
        }
        
      } else if (obj.name == strings.Mind_Node_Overlay_Paste) {
        if (this.checkIsPreviewStatusAndToEdit()) {
          this.showElementMenuList = false;
          return;
        }
        this.editMindmapContent.replaceNode(this.lastClickMindData.id);
      } else if (obj.name == strings.Mind_Edit_Node_Menu_Delete_Picture) {
        this.editMindmapContent.removeImage();
        // this.editMindmapContent.stickNode(this.lastClickMindData.id);
      } else if (obj.name == strings.Mind_Edit_Node_Menu_Save_Picture) {
        this.saveUserPicture();
      } else if (obj.name == strings.Mind_Edit_Node_Menu_View_Picture) {
        // let imgList = this.editMindmapContent.getAllImageUrlList(-1);
        // let obj = {};
        // obj.src = this.lastClickMindData.imageContent.key;
        // obj.imgUrlList = imgList;
        // this.bus.$emit("examineImg", obj);
        this.showMindImage();
      } else if (obj.name == strings.Mind_Edit_Node_Delete_View_Video) {
        if (this.checkIsPreviewStatusAndToEdit()) {
          this.showElementMenuList = false;
          return;
        }
        this.editMindmapContent.removeVideo();
      } else if (obj.name == strings.Mind_Edit_Node_Menu_View_Video) {
        if (this.checkIsPreviewStatusAndToEdit()) {
          this.showElementMenuList = false;
          return;
        }
        let objVideo = {};
        if (this.lastClickMindData == null) {
          this.showElementMenuList = false;
          return;
        }
        objVideo.src = this.lastClickMindData.imageContent.key;
        objVideo.videoSrc = this.lastClickMindData.imageContent.videoUrl;
        this.bus.$emit("examineVideoImg", objVideo);
      } else if (obj.name == strings.Mind_Add_Background_Image) {
        //插入背景图
        if (this.checkIsPreviewStatusAndToEdit()) {
          this.showElementMenuList = false;
          return;
        }
        this.setmaterialLibraryModulesModel({ accout: true, type: "nbg" });
      } else if (obj.name == strings.Mind_Edit_Left_Menu_Save_Picture) {
        //保存图片
        if (this.checkIsPreviewStatusAndToEdit()) {
          this.showElementMenuList = false;
          return;
        }
        this.imgTypeModelAccout({ accout: true, type: "" });
        // 选择节点导出图片
        sessionStorage.setItem(
          sessionStorageKey.ImgSaveMode,
          saveImgModes.SelectNodeSaveImg
        );
      } else if (obj.name == strings.Mind_Delete_Background_Image) {
        //删除背景图
        if (this.checkIsPreviewStatusAndToEdit()) {
          this.showElementMenuList = false;
          return;
        }
        this.editMindmapContent.addImageForNodeBackgroun(
          this.$store.state.nodeContentMessage.id,
          null
        );
      } else if (obj.name == strings.Mind_Edit_Subscript) {
        this.addSubscript(this.lastClickMindData);
      } else if (obj.name == strings.Global_Edit_Task) {
        if (this.checkIsPreviewStatusAndToEdit()) {
          this.showElementMenuList = false;
          return;
        }
        this.showEditTask = true;
        this.setIsShowEditTask(true);
        this.dataNode =
          this.editMindmapContent.getSelectedNode().value.taskContent;
      } else if (obj.name == strings.Global_Text_Find) {
        if (this.checkIsPreviewStatusAndToEdit()) {
          this.showElementMenuList = false;
          return;
        }
        this.showFindTextView = true;
        this.setIsShowFindTextView(true);
      } else if (obj.name == strings.Mind_Edit_Latex) {
        this.showEditLatex = true;
        // this.setIsShowEditTask(true);
        // this.dataNode = this.editMindmapContent.getSelectedNode().value.taskContent;
      } else if (obj.name == strings.Mind_Edit_Order_1_Label || 
                obj.name == strings.Mind_Edit_Order_2_Label || 
                obj.name == strings.Mind_Edit_Order_3_Label || 
                obj.name == strings.Mind_Edit_Order_4_Label || 
                obj.name == strings.Mind_Edit_Order_5_Label || 
                obj.name == strings.Mind_Edit_Order_6_Label || 
                obj.name == strings.Mind_Edit_Order_7_Label || 
                obj.name == strings.Mind_Edit_Order_8_Label || 
                obj.name == strings.Mind_Edit_Order_Level_1_Label || 
                obj.name == strings.Mind_Edit_Order_Level_2_Label ||
                obj.name == strings.Mind_Edit_Order_Level_3_Label ||
                obj.name == strings.Mind_Edit_Order_Level_4_Label ||
                obj.name == strings.Mind_Edit_Order_Label) {
        this.showElementMenuList = true; 
        return
      } else if (obj.name == strings.Mind_Edit_Bottom_Subtopic ) {
        let mindOperateUIControllerView = new MindOperateUIControllerView(
          this.editMindmapContent,
          this.editMindmapContent
        );
        mindOperateUIControllerView.onSelected(MindElementType.ADD_BROTHER_BOTTOM);
        return
      } else if (obj.name == strings.Mind_Edit_Top_Subtopic ) {
        let mindOperateUIControllerView = new MindOperateUIControllerView(
          this.editMindmapContent,
          this.editMindmapContent
        );
        mindOperateUIControllerView.onSelected(MindElementType.ADD_BROTHER_TOP);
        return
      } else if (obj.name == strings.Mind_Edit_Subtopic ) {
        let mindOperateUIControllerView = new MindOperateUIControllerView(
          this.editMindmapContent,
          this.editMindmapContent
        );
        mindOperateUIControllerView.onSelected(MindElementType.ADD);
        return
      }
      this.showElementMenuList = false;
    },

    showMindImage() {
        let imgList = this.editMindmapContent.getAllImageUrlList(-1);
        let obj = {};
        obj.src = this.lastClickMindData.imageContent.key;
        obj.imgUrlList = imgList;
        this.bus.$emit("examineImg", obj);
    },

    saveUserPicture() {
      let node = this.editMindmapContent.getSelectedNode()
      if (node == null || node.isEmpty() ||
        node.value.imageContent == null || node.value.imageContent.key == null || node.value.imageContent.key.length == 0) {
        return
      }
      let url = node.value.imageContent.key;
      let name = Util.getNameByUrl(url);
      let type = Util.getUrlType(url);

      LoadLocalXJMind.loadResourceData(url).then((data) => {
        if (data != null) {
          new UiUtil().downLoadImg(data, name, {imgType:type})
        }
      })
    },

    checkMenuTask: function (obj) {
      if (obj.name == strings.Global_Edit_Task) {
        this.showEditTask = true;
        this.setIsShowEditTask(true);
      } else if (obj.name == strings.Global_Text_Find) {
        this.showFindTextView = true;
        this.setIsShowFindTextView(true);
      } else if (obj.name == strings.Global_Detele_Task) {
        this.setTsakSelectedNodeId(-1);
        let that = this;
        this.$confirm({
          title: getString(strings.Mind_Tips),
          content: getString(strings.Mind_Are_You_Sure_To_Delete_This_Task),
          centered: true,
          okText: getString(strings.Mind_Edit_Confirm),
          cancelText: getString(strings.Mind_Group_Cancel),
          onOk() {
            that.editMindmapContent.deleteTask();
            that.setTsakSelectedNodeId(that.editMindmapContent.selectedNodeId);
          },
          onCancel() {},
        });
      } else if (obj.name == strings.Mind_Lower) {
        this.editMindmapContent.setTaskPriority(1);
      } else if (obj.name == strings.Mind_Ordinary) {
        this.editMindmapContent.setTaskPriority(2);
      } else if (obj.name == strings.Mind_Urgent) {
        this.editMindmapContent.setTaskPriority(3);
      } else if (obj.name == strings.Mind_Extremely_Urgent) {
        this.editMindmapContent.setTaskPriority(4);
      } else if (obj.name == "0%") {
        this.editMindmapContent.setTaskProgress(0);
      } else if (obj.name == "10%") {
        this.editMindmapContent.setTaskProgress(10);
      } else if (obj.name == "20%") {
        this.editMindmapContent.setTaskProgress(20);
      } else if (obj.name == "30%") {
        this.editMindmapContent.setTaskProgress(30);
      } else if (obj.name == "40%") {
        this.editMindmapContent.setTaskProgress(40);
      } else if (obj.name == "50%") {
        this.editMindmapContent.setTaskProgress(50);
      } else if (obj.name == "60%") {
        this.editMindmapContent.setTaskProgress(60);
      } else if (obj.name == "70%") {
        this.editMindmapContent.setTaskProgress(70);
      } else if (obj.name == "80%") {
        this.editMindmapContent.setTaskProgress(80);
      } else if (obj.name == "90%") {
        this.editMindmapContent.setTaskProgress(90);
      } else if (obj.name == "100%") {
        this.editMindmapContent.setTaskProgress(100);
      }
      this.showTaskMenuList = false;
    },
    checkMenuLaTeX: function (obj) {
      if (obj.name == strings.Mind_Edit_Latex) {
        this.showEditLatex = true;
      } else if (obj.name == strings.Mind_Detele_Latex) {
        this.setTsakSelectedNodeId(-1);
        let that = this;
        this.$confirm({
          title: getString(strings.Mind_Tips),
          content: getString(strings.Mind_Are_You_Sure_To_Delete_This_LaTeX),
          centered: true,
          okText: getString(strings.Mind_Edit_Confirm),
          cancelText: getString(strings.Mind_Group_Cancel),
          onOk() {
            that.editMindmapContent.delLatext();
            that.setTsakSelectedNodeId(that.editMindmapContent.selectedNodeId);
          },
          onCancel() {},
        });
      }
      this.showLaTeXMenuList = false;
    },
    resizeImage: function (src, w, h) {
      var canvas = document.createElement("canvas"),
        ctx = canvas.getContext("2d"),
        im = new Image();
      im.setAttribute("crossOrigin", "Anonymouse");
      (w = w || 0), (h = h || 0);
      im.onload = function () {
        //为传入缩放尺寸用原尺寸
        !w && (w = this.width);
        !h && (h = this.height);
        //以长宽最大值作为最终生成图片的依据
        if (w !== this.width || h !== this.height) {
          var ratio;
          if (w > h) {
            ratio = this.width / w;
            h = this.height / ratio;
          } else if (w === h) {
            if (this.width > this.height) {
              ratio = this.width / w;
              h = this.height / ratio;
            } else {
              ratio = this.height / h;
              w = this.width / ratio;
            }
          } else {
            ratio = this.height / h;
            w = this.width / ratio;
          }
        }
        //以传入的长宽作为最终生成图片的尺寸
        if (w > h) {
          var offset = (w - h) / 2;
          canvas.width = canvas.height = w;
          ctx.drawImage(im, 0, offset, w, h);
        } else if (w < h) {
          var offset = (h - w) / 2;
          canvas.width = canvas.height = h;
          ctx.drawImage(im, offset, 0, w, h);
        } else {
          canvas.width = canvas.height = h;
          ctx.drawImage(im, 0, 0, w, h);
        }
      };
      im.src = src;
    },
    coverToImage: function () {
      const that = this;
      let rect = this.editMindmapContent.getPreviewImagetRect();
      let bgColor = this.DrawingBoardBackgroundColor;
      let toImage = new ToImage();
      let type = "cover";
      return new Promise(function (resolve) {
        toImage.toImage(rect, bgColor, type).then((res) => {
          if (!res) {
            resolve("");
            return;
          }
          let headers;
          if (that.$router.history.current.name == "gmap") {
            headers = {
              "X-Type": "gmap-cover",
              "X-ID": that.$route.query.id,
            };
          } else {
            headers = {
              "X-Type": "mindmap-cover",
              "X-ID": that.$route.query.id,
            };
          }
          postMindmapUploadImage(
            new imageToStyle().base64ToBlob(res),
            (imgData) => {
              resolve(imgData.imageUrl);
            },
            (error) => {
              if (error.code == 51) {
                if (!that.storageIsMeberModelAccout) {
                  that.storageIsMeberModelAccout = true;
                }
              } else {
                that.$message.error(error.desc);
              }
              resolve("");
            },
            null,
            headers
          );
        });
      });
    },
    checkMenuIconElement: function (obj) {
      if (obj.name == strings.Mind_Edit_Node_Menu_Delete_Icon) {
        this.editMindmapContent.removeIcon(
          this.iconNode.nodeId,
          this.iconNode.iconData
        );
      } else if (obj.name == strings.Mind_Edit_Node_Menu_Icon_Style) {
        this.bus.$emit("showSetIconStyle", 1);
      } else if (obj.name == strings.Mind_Edit_Node_Menu_Open_Link) {
        //打开链接

        let url;
        if (this.isTemplatePage || this.isSharePage) {
          let node = this.editMindmapContent.getNodeById(this.iconNode.nodeId);
          url = this.concatJumpUrl(node.value.linkElementContent.links[0]);
        } else {
          url = this.concatJumpUrl(
            this.lastClickMindData.linkElementContent.links[0]
          );
        }
        if (this.$tools.isElectron()) {
          ipcRenderer.send("open-url", url);
        } else {
          this.createATagJump(url);
        }
      } else if (obj.name == strings.Mind_Edit_Node_Menu_Delete_Link) {
        this.editMindmapContent.removeAllLink(this.lastClickMindData.id);
      } else if (obj.name == strings.Mind_Edit_Node_Menu_Delete_Remarks) {
        this.editMindmapContent.changeRemarks("", this.lastClickMindData.id);
      } else if (obj.name == strings.Mind_Edit_Node_Menu_Edit_Remarks) {
        if (this.isTemplatePage || this.isSharePage) {
          this.showRemarksPanel(
            this.editMindmapContent.getNodeById(this.iconNode.nodeId)
          );
        } else {
          this.editMindmapContent.openRemarksPanel();
        }
        // this.editMindmapContent.openRemarksPanel();
      } else if (obj.name == strings.Mind_Association_Map_See) {
        //查看关联导图
        let token = "";
        if (this.$route.name == "s") {
          if (this.shareToken == "") {
            token = this.$route.params.id;
          }
        }
        // this.editMindmapContent.openConnectMapByNode(this.iconNode.nodeId,token);
        this.viewAssociatedMap(this.iconNode.nodeId, token);
      } else if (obj.name == strings.Mind_Association_Map_Delete) {
        this.editMindmapContent.removeConnectMap(this.iconNode.nodeId);
      } else if (obj.name == strings.Mind_Resource_See) {
        if (this.$route.name == "s") {
          //查看附件
          if (!this.initShareMindDatas.viewAttachment) {
            this.$message.error("用户关闭了查看附件功能");
            this.showResourceModel = false;
            this.moreMenu = false;
            return;
          }
        }

        let node = this.editMindmapContent.getNodeById(this.iconNode.nodeId);
        this.openOrDownResource(node);
      } else if (obj.name == strings.Mind_Resource_Delete) {
        this.editMindmapContent.removeResource(this.iconNode.nodeId);
      }
      this.showElementIconMenuList = false;
      this.showLinkMenu = false;
      this.abstractMenu = false;
      this.showAssMapLink = false;
      this.moreMenu = false;
      this.showResourceModel = false;
    },

    checkMenuLineElement: function (obj) {
      if (obj.type == "iconMenuData") {
        //关联线相关
        if (obj.name == strings.Mind_Edit_Right_Menu_line_Color) {
          //关联线颜色
          this.lineColorColorAccout = true
          this.showRelevanceLineMenu = false;
          this.showChunkColor(true);
          this.markColorAccout(1);
        } else if (obj.name == strings.Mind_Edit_Node_Menu_Delete_Line) {
          //删除关联线
          this.editMindmapContent.removeNode();
          this.editmapScrollView.hideCircle();
          this.showRelevanceLineMenu = false;
        } else if (obj.name == strings.Global_Edit) {
          //编辑关联线
          let node = this.editMindmapContent.getSelectedNode();
          this.editmapScrollView.setInput(node.value);
          this.showRelevanceLineMenu = false;
        } else if (
          obj.name == strings.Mind_Edit_Right_Menu_Correlation_Line ||
          obj.name == strings.Mind_Edit_Right_Menu_Correlation_Line_Start ||
          obj.name == strings.Mind_Edit_Right_Menu_Correlation_Line_End
        ) {
          //关联线形状、起点、终点
          this.showRelevanceLineMenu = false;
        }
      } else if (obj.type == "encircle") {
        //包裹线相关
        if (obj.name == strings.Mind_Edit_Node_Menu_Delete_Wrapping_Line) {
          //删除包裹线
          this.editMindmapContent.removeNode();
          this.showEncircleMenu = false;
        } else if (obj.name == strings.Global_Edit) {
          //编辑包裹线标题
          let node = this.editMindmapContent.getSelectedNode();
          this.editmapScrollView.setInput(
            node.value,
            null,
            null,
            obj.clickIcon
          );
          this.showEncircleMenu = false;
        } else if (
          obj.name == strings.Mind_Edit_Node_Menu_Add_Encircle_Background
        ) {
          //添加包裹线背景
          this.setmaterialLibraryModulesModel({ accout: true, type: "bbg" });
          this.showEncircleMenu = false;
        } else if (
          obj.name == strings.Mind_Edit_Node_Menu_Delete_Encircle_Background
        ) {
          //删除包裹线背景
          this.editMindmapContent.addImageForNodeBackgroun(
            this.$store.state.nodeContentMessage.id,
            null
          );
          // this.editMindmapContent.addMaterialImage(
          //   null,
          //   this.$store.state.nodeContentMessage.id
          // );
          this.showEncircleMenu = false;
        }
      } else {
        this.showRelevanceLineMenu = false;
        this.showEncircleMenu = false;
      }
    },

    clickFreeNodeMenuOption(type, accout) {
      this.rightClickMenuOption = type;
      this.isShowFreeNodeMenu = accout;
      if (this.rightClickMenuOption == MindElementType.FREE_NODE) {
        this.editMindmapContent.addFreeNodeByPoint(this.point);
      } else if (this.rightClickMenuOption == "Add_Echart") {
        this.showEchartModal(true);
        setTimeout(() => {
          this.showEchartModal(null);
        }, 100);
      } else if (this.rightClickMenuOption == "Insert_Picture") {
        var meMessage = localStorage.getItem("meMessage");
        if (meMessage != null && meMessage != undefined && meMessage != "") {
        } else {
          this.showLoginModel(true);
          return;
        }
        this.rightMenuClickPoint = this.point;
        this.materialLibraryModel(false);
        this.materialLibraryModel(true);
      } else if (this.rightClickMenuOption == "Menu_Paste") {
        // this.editMindmapContent.stickNode(this.point);
        let that = this;
        let node = EditMindmapVM.getSelectedNodeById();
        if (node == null || node.isEmpty()) {
          if (MindCopyManager.isHasCopyNode()) {
            that.editMindmapContent.stickNode(
              that.editMindmapContent.selectedNodeId,
              that.point,
              true
            );
          } else {
            navigator.clipboard.readText().then( function(clipText) {
              if (clipText != null && clipText.length > 0) {
                that.editMindmapContent.stickString(-1, that.point, clipText)
              }
            });
            navigator.clipboard.read().then((data) => {
              for (let i = 0; i < data.length; i++) {
                if (data[i].types.includes("image/png")) {
                  data[i].getType("image/png").then((blob) => {
                    new ImageFileUpload(that, that.point).upload(blob);
                  });
                }
              }
            });
          }
        } else {
          this.$confirm({
            title: getString(strings.Mind_Tips),
            content: getString(strings.Keep_Original_Style_Request),
            centered: true,
            okText: getString(strings.Keep_Original_Style),
            cancelText: getString(strings.Keep_FollowCurrent),
            onOk() {
              that.editMindmapContent.stickNode(
                that.editMindmapContent.selectedNodeId,
                that.point,
                true
              );
            },
            onCancel() {
              that.editMindmapContent.stickNode(
                that.editMindmapContent.selectedNodeId,
                that.point,
                false
              );
            },
          });
        }
      }
    },
    checkScaleIconBackground: function (color) {
      this.isDarkColor = Colors.isLightColor(color);
    },

    isPlayStatus: function () {
      return EditMindmapVM.getMindMapPlayStatus() != MindMapPlayStatus.NORMAL
    },

    isEditStatus: function () {
      if (this.isPlayStatus()) {        
        return false;
      }
      return !this.isTemplatePage && !this.isSharePage;
    },

    getMindType() {
      return this.mindType;
    },

    //录音
    addGlobalAudio(newMp3Blob) {
      if (newMp3Blob == undefined) {
        return;
      }
      this.editMindmapContent.setGlobalAudioUrl(newMp3Blob);
    },
    removeGlobalAudio() {
      this.editMindmapContent.setGlobalAudioUrl("");
    },
    //修改统计图数据
    onStatisticChange(data) {
      if (data.statisticsContent.cellsData.length < 1) {
        this.editMindmapContent.removeNode();
      }
      this.editMindmapContent.onStatisticChange(data);
    },
    //选框
    setSelectedNodeByRect(data) {
      this.editMindmapContent.setSelectedNodeByRect(data);
      this.setTopToolBarForNode();
    },

    setTopToolBarForNode() {
      if (EditMindmapVM.isShowAlignment()) {
        this.isShowAlignButton(true);
      } else {
        this.isShowAlignButton(false);
      }
      if (EditMindmapVM.isShowSameWidth()) {
        this.isShowSameWidth(true);
      } else {
        this.isShowSameWidth(false);
      }
    },

    //对齐方式
    alignTypeToVm(type) {
      switch (type) {
        case "topAlignment":
          EditMindmapVM.topAlignment();
          break;
        case "centreHorizontalAlignment":
          EditMindmapVM.centreHorizontalAlignment();
          break;
        case "bottomAlignment":
          EditMindmapVM.bottomAlignment();
          break;
        case "averageDistributionVertical":
          EditMindmapVM.averageDistributionHorizontal();
          break;
        case "leftAlignment":
          EditMindmapVM.leftAlignment();
          break;
        case "centreVerticalAlignment":
          EditMindmapVM.centreVerticalAlignment();
          break;
        case "averageDistributionHorizontal":
          EditMindmapVM.averageDistributionVertical();
          break;
        case "rightAlignment":
          EditMindmapVM.rightAlignment();
          break;
        case "circleAlignment":
          EditMindmapVM.circleAlignment();
          break;
        case "equalDistribution":
          EditMindmapVM.equalDistribution();
          break;
        case "nodesSuchAsWide":
          EditMindmapVM.setNodeSameWidth();
          break;
        case "saveImg":
          // 多选节点导出图片
          this.imgTypeModelAccout({ accout: true, type: "" });
          sessionStorage.setItem(
            sessionStorageKey.ImgSaveMode,
            saveImgModes.MultiSelectNodeSaveImg
          );
          this.isShowSameWidth(false);
          this.isShowAlignButton(false);
          break;
        default:
          break;
      }
    },
    //播放导图
    setMindMapScale(mapScale) {
      this.editmapScrollView.setScale(mapScale);
    },

    drawingBoardScrollEnabled(enablied) {
      
    },

    clearPlayBackgroundImage() {
      this.editmapScrollView.clearPlayBackgroundImage();
    },

    setMindMapBackgroundImage(playbackground) {
      this.editmapScrollView.setMindMapBackgroundImage(playbackground);
    },

    getMindMapPoint() {      
      let rect = this.editmapScrollView.getScreenRect();
      return new CGPoint(rect.x, rect.y);
    },

    destroyedPage() {
      let gmapId = this.$route.query.id;
      window.addEventListener("beforeunload", function (e) {
        if (this.$tools.isElectron()) {
          return;
        }
        // Cancel the event
        e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        // Chrome requires returnValue to be set
        e.returnValue = "";

        // if(confirm("真的要关闭页面吗?")){
        //   // alert("点击了确认按钮");

        // }else{
        //   // alert("点击了取消按钮");
        // }
      });
      window.addEventListener("unload", function () {
        // var xhr=new XMLHttpRequest()
        // xhr.open('GET','http://192.168.3.4:8081')
        // xhr.send()
        // that.endGroupEditGmap({gmapId: gmapId});
        postGroupGmapEditFinish(
          { gmapId: gmapId },
          (res) => {
            // resolve(res);
          },
          (error) => {
            // this.$message.error(error.desc);
            // reject(error);
          }
        );
      });

      // if ( this.MindPageType == 'groupMapEdit' ) {
      //   let gmapId = this.$route.params.id
      //   this.endGroupEditGmap({gmapId: gmapId});
      // }
    },
    //保存按钮颜色
    // checkAddIconColor(btnColor) {
    //   let isDarkColor = Colors.isLightColor(btnColor);
    //   let color = "";
    //   let boxShadow = ""
    //   let Textcolor = "";

    //   if (isDarkColor) {
    //     color = "rgba(255,255,255,0.5)";
    //     boxShadow = "0px 3px 6px rgba(255,255,255,0.5)"
    //     Textcolor = "#666"
    //   } else {
    //     Textcolor = "#fff"
    //     color = "rgba(0,0,0)";
    //     boxShadow = "0px 3px 6px rgba(0,0,0,0.5)"
    //   }

    //   let bgColor = document.getElementById("free-node-menu");
    //   let fontColor = document.getElementsByClassName("free-node");
    //   bgColor.style.background = color;
    //   bgColor.style.boxShadow = boxShadow;
    //   fontColor[0].style.color = Textcolor;
    // },

    startGroupEditGmap(obj) {
      //开始编辑
      return new Promise((resolve, reject) => {
        postGroupEditGmap(
          obj,
          (res) => {
            resolve(res);
          },
          (error) => {
            this.$message.error(error.desc);
            reject(error);
          }
        );
      });
    },

    startGroupEditGmapPing(obj) {
      //由于时ping接口，就不需要报错提示了
      return new Promise((resolve, reject) => {
        postGroupGmapEditPing(
          obj,
          (res) => {
            resolve(res);
          },
          (error) => {
            // this.$message.error(error.desc);
            reject(error);
          }
        );
      });
    },

    endGroupEditGmap(obj) {
      //结束组编辑
      return new Promise((resolve, reject) => {
        postGroupGmapEditFinish(
          obj,
          (res) => {
            resolve(res);
          },
          (error) => {
            // this.$message.error(error.desc);
            reject(error);
          }
        );
      });
    },
    //列表展示到选中的地方
    selectListDisplay() {
      if (this.showIndex >= 0) {
        var scrollObj = document.getElementById("sheetScroll");
        // scrollObj.style.overflowX = 'auto';
        scrollObj.scrollLeft = 90 * this.showIndex;
      }
    },
    //获取分页列表
    getSheetPaginationList(lastIndex) {
      let obj = { rootId: this.$route.query.id, lastIndex: lastIndex };
      const scrollDiv = document.getElementById("sheetScroll");
      let scrollLeft = scrollDiv.scrollLeft;
      if (this.$route.name == "Complie") {
        MindmapDataRouter.postMindmapSheets(
          obj,
          (res) => {
            if (res.sheets == null || res.sheets == "") {
              this.isReightScrollButton = false;
              scrollDiv.scrollLeft = scrollLeft
              setTimeout(() => {
                scrollDiv.scrollLeft = scrollLeft
                }, 50);
            } else {
              if (lastIndex == 0) {
                this.initMindSheetDatas = res.sheets;
                this.initSearchMindSheetDatas = res.sheets;
              } else if (lastIndex > 0) {
                this.initMindSheetDatas = this.initMindSheetDatas.concat(
                  res.sheets
                );
                this.initSearchMindSheetDatas = this.initSearchMindSheetDatas.concat(res.sheets);
                setTimeout(() => {
                  if (res.sheets.length > 0) {
                    scrollDiv.scrollLeft = scrollLeft + 50
                  } else {
                    scrollDiv.scrollLeft = scrollLeft
                  }
                }, 50);
                if (res.sheets.length > 0) {
                  scrollDiv.scrollLeft = scrollLeft + 50
                } else {
                  scrollDiv.scrollLeft = scrollLeft
                }
              }
              let datasCount = this.initMindSheetDatas.length;
              let numberCount = Math.floor((this.sheetTopFullWidth - 150) / 90);
              if (datasCount > numberCount) {
                this.isReightScrollButton = true;
              } else {
                this.isReightScrollButton = false;
              }
            }
            this.sheetTotal = res.totalNum;
          },
          (error) => {
            this.$message.error(error.desc);
          }
        );
      } else if (this.$route.name == "template") {
        postTemplateListTsheets(
          obj,
          (res) => {
            if (res.sheets == null || res.sheets == "") {
              this.isReightScrollButton = false;
            } else {
              if (lastIndex == 0) {
                this.initMindSheetDatas = res.sheets;
                this.initSearchMindSheetDatas = res.sheets;
              } else if (lastIndex > 0) {
                this.initMindSheetDatas = this.initMindSheetDatas.concat(
                  res.sheets
                );
                this.initSearchMindSheetDatas =
                  this.initSearchMindSheetDatas.concat(res.sheets);
              }
            }
            this.sheetTotal = res.totalNum;
            // resolve(res);
          },
          (error) => {
            this.$message.error(error.desc);
          }
        );
      } else if (this.$route.name == "gmap") {
        let gmapObj = {
          rootId: this.$route.query.id,
          groupId: this.initMindGroupViewData.groupId,
          lastIndex: lastIndex,
        };
        postGroupListGsheets(
          gmapObj,
          (res) => {
            if (res.sheets == null || res.sheets == "") {
              this.isReightScrollButton = false;
            } else {
              if (lastIndex == 0) {
                this.initMindSheetDatas = res.sheets;
                this.initSearchMindSheetDatas = res.sheets;
              } else if (lastIndex > 0) {
                this.initMindSheetDatas = this.initMindSheetDatas.concat(
                  res.sheets
                );
                this.initSearchMindSheetDatas =
                  this.initSearchMindSheetDatas.concat(res.sheets);
              }
            }
            this.sheetTotal = res.totalNum;
          },
          (error) => {
            this.$message.error(error.desc);
          }
        );
      } else if (this.$route.name == "c") {
        let crispsObj = { rootId: this.$route.params.id, lastIndex: lastIndex };
        postCrispsListCsheets(
          crispsObj,
          (res) => {
            if (res.sheets == null || res.sheets == "") {
              this.isReightScrollButton = false;
            } else {
              if (lastIndex == 0) {
                this.initMindSheetDatas = res.sheets;
                this.initSearchMindSheetDatas = res.sheets;
              } else if (lastIndex > 0) {
                this.initMindSheetDatas = this.initMindSheetDatas.concat(
                  res.sheets
                );
                this.initSearchMindSheetDatas =
                  this.initSearchMindSheetDatas.concat(res.sheets);
              }
            }
            this.sheetTotal = res.totalNum;
            // resolve(res);
          },
          (error) => {
            this.$message.error(error.desc);
          }
        );
      } else if (this.$route.name == "s") {
        let shareObj = { rootId: this.shareRootId, lastIndex: lastIndex };
        MindmapDataRouter.postMindmapSheets(
          shareObj,
          (res) => {
            if (res.sheets == null || res.sheets == "") {
              this.isReightScrollButton = false;
            } else {
              if (lastIndex == 0) {
                this.initMindSheetDatas = res.sheets;
                this.initSearchMindSheetDatas = res.sheets;
              } else if (lastIndex > 0) {
                this.initMindSheetDatas = this.initMindSheetDatas.concat(
                  res.sheets
                );
                this.initSearchMindSheetDatas =
                  this.initSearchMindSheetDatas.concat(res.sheets);
              }
            }

            this.sheetTotal = res.totalNum;
            // resolve(res);
          },
          (error) => {
            this.$message.error(error.desc);
          }
        );
      } else if (this.$route.name == "arvhiveMap") {
        // let objArvhiveMap ={rootId:this.$route.query.id,lastIndex:lastIndex};
        MindmapDataRouter.postMindmapSheets(
          obj,
          (res) => {
            if (res.sheets == null || res.sheets == "") {
              this.isReightScrollButton = false;
            } else {
              if (lastIndex == 0) {
                this.initMindSheetDatas = res.sheets;
                this.initSearchMindSheetDatas = res.sheets;
              } else if (lastIndex > 0) {
                this.initMindSheetDatas = this.initMindSheetDatas.concat(
                  res.sheets
                );
                this.initSearchMindSheetDatas =
                  this.initSearchMindSheetDatas.concat(res.sheets);
              }
            }
            this.sheetTotal = res.totalNum;
            // resolve(res);
          },
          (error) => {
            this.$message.error(error.desc);
          }
        );
      }
    },
    //左边列表显示隐藏
    clickLookSheet(val) {
      if (this.showSheetLeft && !val) {
        val = true
      }
      if (this.isPlayStatus()) {
        return;
      }
      this.reightMenuIndex = -1;
      this.moveSheetMenu = false;
      this.searchText = "";
      if (!val) {
        // this.getSheetPaginationList();
        var scrollLeftObj = document.getElementsByClassName(
          "left-sheet-box-overflow"
        );
        if (scrollLeftObj != null && scrollLeftObj.length > 0) {
          scrollLeftObj[0].scrollTop = 163 * this.showIndex;
        }
        this.initSearchMindSheetDatas = this.initMindSheetDatas;
      }

      this.showSheetLeft = !val;
      this.showLookSheetOne = val;
      this.showLookSheetTow = !val;
      this.getComlieLeftMenuClass();
      // this.showSheetLeft = ;
    },
    clickCreated(aIndex) {
      if (
        (this.$route.name == "Complie" || this.$route.name == "arvhiveMap") &&
        aIndex == -1
      ) {
        let that = this;
        this.$confirm({
          title: getString(strings.Mind_Tips),
          content: "新建分页导图，或从其它导图导入",
          cancelText: getString(strings.Mind_Import),
          okText: getString(strings.Establish),
          centered: true,
          style: {
            borderRadius: "20px",
          },
          onOk() {
            that.afterIndex = aIndex;
            that.createdSheetName = "clickCreated";
            that.sheetCreatedModal = true;
            setTimeout(() => {
              that.$refs.sheet_name.focus();
            }, 50);
            that.sheetName = "";
          },
          onCancel() {
            // if (LoadLocalXJMind.isLoaclMind() && !LoadLocalXJMind.isCloudStorage()) {
            //   new UiUtil().showNeedToUpload(that, EditMindmapVM)
            // } else {
              that.showMoveInSheet = true;
              that.exitEditStatusSaveMap();
            // }            
          },
        });
      } else {
        // this.removeShortcutKey();
        this.afterIndex = aIndex;
        this.createdSheetName = "clickCreated";
        this.sheetCreatedModal = true;
        setTimeout(() => {
          this.$refs.sheet_name.focus();
        }, 50);
        this.sheetName = "";
      }
    },
    setRightMenu() {
      //右侧菜单是否展示
      if (this.rightMenuAccout) {
        this.rightShowMenu = true;
        this.fullWidth = document.documentElement.clientWidth - 291;
      } else {
        this.rightShowMenu = false;
        this.fullWidth = document.documentElement.clientWidth;
      }
    },

    hideCreatedBox(obj) {
      if (obj.show == false) {
        this.sheetName == "";
        this.sheetCreatedModal = false;
        this.showCreatedSheetMind = false;
        this.getSheetPaginationList(0);
      }
    },
    hideLatex(obj) {
      this.showEditLatex = false;
      this.setIsLaTeXModel(false);
      // this.latexRefresh();
    },
    //移入其它导图作为分页导图
    hideMoveInSheet(obj) {
      this.showMoveInSheet = false;
      let sheetId = "";
      if (obj.moveInSheet) {
        if (
          obj.mapItem.mindMapId == null ||
          obj.mapItem.mindMapId == undefined ||
          obj.mapItem.mindMapId == ""
        ) {
          sheetId = obj.mapItem.id;
        } else {
          sheetId = obj.mapItem.mindMapId;
        }
        this.moveInMapSheet(sheetId, obj);
      }
    },
    moveInMapSheet(sheetId, obj) {
      if (LoadLocalXJMind.isLoaclMind() && !LoadLocalXJMind.isCloudStorage()) {
        let that = this;
        let rootId = sheetId
        let requestData = null
        if (obj.mapItem.mindMapId != null && 
            obj.mapItem.mindMapId != "" && obj.mapItem.rootId == null) {
          rootId = obj.mapItem.mindMapId
          requestData = {id: rootId};
        } else if (obj.mapItem.rootId != null && obj.mapItem.rootId != "") {
          rootId = obj.mapItem.rootId
          sheetId = obj.mapItem.id
          requestData = {id: rootId, sheetId: sheetId};
        }
        if (requestData == null) {
          return
        }
        MindmapDataRouter.postGetMindmap(requestData, (res) => {
            let initData = res;
            new NewXJMindMap(LoadLocalXJMind.getName(), initData.type, that.$router.appPath, "").
                        createLocalSheetMindMap(initData.name, initData.content, "").then((creearData) => {
              if (creearData != null) {
                  setTimeout(() => {
                    that.getSheetPaginationList(0);
                    let itemObj = {};
                    itemObj.rootId = creearData.rootId;
                    itemObj.id = creearData.id;
                    that.clickSwitchSheet(itemObj, creearData.index);
                  }, 200);
              } else {
                that.$message.error("创建失败！")
              }
            })
          },
          (error) => {
            that.$message.error(error.desc);
          }
        );
        return
      }
      let that = this
      postMindmapMigrateSheet(
        { sheetId: sheetId, toMindMapId: this.$route.query.id },
        (res) => {
          if (LoadLocalXJMind.isLoaclMind() && LoadLocalXJMind.isCloudStorage()) {
            let newSheetId = res.sheetId
            let that = this
            MindmapDataRouter.postGetMindmap(
              { id: this.$route.query.id,sheetId: res.sheetId},
              (res) => {
                let initData = res;
                new NewXJMindMap(LoadLocalXJMind.getName(), initData.type, that.$router.appPath, "").
                            createLocalSheetMindMap(initData.name, initData.content, newSheetId).then((creearData) => {
                  if (creearData != null) {
                      setTimeout(() => {
                        that.getSheetPaginationList(0);
                        let itemObj = {};
                        itemObj.rootId = creearData.rootId;
                        itemObj.id = creearData.id;
                        that.clickSwitchSheet(itemObj, creearData.index);
                      }, 100);
                  } else {
                    that.$message.error("创建失败！")
                  }
                })
              },
              (error) => {
                that.$message.error(error.desc);
              }
            );
            return
          } else {
            this.getSheetPaginationList(0);
            let itemObj = {};
            itemObj.rootId = res.mindMapId;
            itemObj.id = res.sheetId;
            let that = this;
            setTimeout(() => {
              that.clickSwitchSheet(itemObj, res.index);
            }, 100);
          }
        },
        (error) => {
          this.$message.error(error.desc);
        }
      );
    },
    //创建新分页导图，或者重命名
    hideSheetCreatedModal() {
      //修改导图名
      if (this.sheetName == "") {
        this.$message.error(
          getString(strings.Message_Tips_Profile_Name_No_Null)
        );
        return;
      }
      //关闭输入框，
      this.sheetCreatedModal = false;
      if (this.createdSheetName == "clickCreated") {
        // this.bunidingShortcutKey();
        //打开创建导图框
        this.showCreatedSheetMind = true;
      } else if (this.createdSheetName == "folderRename") {        
        if (LoadLocalXJMind.isLoaclMind()) {
          new NewXJMindMap(LoadLocalXJMind.getName(), 1, this.$router.appPath, "").
                    renameLocalSheetMindMap(this.sheetNameObj.id, this.sheetName).then((success) => {
            if (success != null) {
                setTimeout(() => {
                  if (!LoadLocalXJMind.isCloudStorage()) {
                    this.$message.success(
                      getString(strings.Message_Tips_Modification_Success)
                    );
                    //清空修改框
                    this.sheetName = "";
                    this.getSheetPaginationList(0);
                  }
                }, 200);
            }
          })
          if (!LoadLocalXJMind.isCloudStorage()) {
            return
          }
        }
        if (!LoadLocalXJMind.isLoaclMind() || LoadLocalXJMind.isCloudStorage()) {
          let obj = {};
          obj.name = this.sheetName;
          obj.rootId = this.sheetNameObj.rootId;
          obj.sheetId = this.sheetNameObj.id;
          obj.groupId = this.groupGmapId;
          this.uploadSheetName(obj)
            .then((data) => {
              this.$message.success(
                getString(strings.Message_Tips_Modification_Success)
              );
              //清空修改框
              this.sheetName = "";
              this.getSheetPaginationList(0);
            })
            .then((res) => {})
            .catch((error) => {});
        }
      }
      this.createdSheetName = "";
      this.exitEditStatusSaveMap();
    },
    hideSheetNameCancel() {
      // this.bunidingShortcutKey();
      this.sheetCreatedModal = false;
      this.sheetCreatedModal = false;
      this.createdSheetName = "";
      this.sheetName = "";
    },

    openMindPasswordPanelForSetting() {
      this.mindPasswordPanelModal = true;
      this.mindPassword = EditMindmapVM.getMindkey();
      this.mindPasswordPlaceholder = getString(strings.Please_Enter_Mind_Password);
    },

    hideMindPasswordPanelModal() {
      if (EditMindmapVM.isUnlockMindMapStatus()) {
        let value = EditMindmapVM.verifyKey(this.mindPassword)
        if (value.length == 0) {
          this.mindPasswordPanelModal = false;
        } else {
          this.$message.error(value);
        }
      } else {
        if (!Util.isMember()) {
          this.$message.warning(getString(strings.Please_Enter_Mind_Password_Member_Tips));
          this.isShowShoppingModel(true);
          return;
        }
        if (this.mindPassword.length == 0) {
          EditMindmapVM.setMindKey(this.mindPassword)
          this.mindPasswordPanelModal = false;
          this.$message.success(getString(strings.Please_Enter_Mind_Password_Remove_Tips));
          return;
        }
        let mindKey = EditMindmapVM.getMindkey();
        if (this.mindPassword == mindKey) {
          this.mindPasswordPanelModal = false;
        } else if (this.mindPassword.length < 4) {
          this.$message.error(getString(strings.Please_Enter_Mind_Password_Length_Short));
        } else if (this.mindPassword.length > 8) {
          this.$message.error(getString(strings.Please_Enter_Mind_Password_Length_Long));
        } else {
          EditMindmapVM.setMindKey(this.mindPassword)
          this.mindPasswordPanelModal = false;
          this.$message.success(getString(strings.Please_Enter_Mind_Password_Success));
        }
      }
    },
    hideMindPasswordPanelModalCancel() {
      this.mindPasswordPanelModal = false;
      this.mindPassword = "";
    },

    //给图片加上前缀链接
    httpImageToPrefix(src) {
      return httpImageToPrefix(src);
    },
    //左边sheet查询
    searchButton() {
      //获取分页列表
      if (this.searchText == "" || this.searchText == null) {
        this.initSearchMindSheetDatas = this.initMindSheetDatas;
        return;
      }
      let obj = { rootId: this.$route.query.id, query: this.searchText };
      if (this.$route.name == "Complie" || this.$route.name == "arvhiveMap") {
        return new Promise((resolve, reject) => {
          postMindmapSearchSheet(
            obj,
            (res) => {
              this.initSearchMindSheetDatas = res.sheets;
            },
            (error) => {
              // this.$message.error(error.desc);
              reject(error);
            }
          );
        });
      } else if (this.$route.name == "template") {
        return new Promise((resolve, reject) => {
          postTemplateSearchTsheet(
            obj,
            (res) => {
              this.initSearchMindSheetDatas = res.sheets;
              // resolve(res);
            },
            (error) => {
              this.$message.error(error.desc);
              reject(error);
            }
          );
        });
      } else if (this.$route.name == "gmap") {
        let gmapObj = {
          groupId: this.groupGmapId,
          rootId: this.$route.query.id,
          query: this.searchText,
        };
        return new Promise((resolve, reject) => {
          postGroupSearchGsheet(
            gmapObj,
            (res) => {
              this.initSearchMindSheetDatas = res.sheets;
              // resolve(res);
            },
            (error) => {
              this.$message.error(error.desc);
              reject(error);
            }
          );
        });
      } else if (this.$route.name == "c") {
        let crispsObj = {
          rootId: this.$route.params.id,
          query: this.searchText,
        };
        return new Promise((resolve, reject) => {
          postCrispsSearchCsheet(
            crispsObj,
            (res) => {
              this.initSearchMindSheetDatas = res.sheets;
              // resolve(res);
            },
            (error) => {
              this.$message.error(error.desc);
              reject(error);
            }
          );
        });
      } else if (this.$route.name == "s") {
        let shareObj = { rootId: this.shareRootId, query: this.searchText };
        return new Promise((resolve, reject) => {
          postMindmapSearchSheet(
            shareObj,
            (res) => {
              this.initSearchMindSheetDatas = res.sheets;
            },
            (error) => {
              // this.$message.error(error.desc);
              reject(error);
            }
          );
        });
      }
    },
    //左边sheet列表，给div追加一个classname
    getComlieLeftMenuClass() {
      return this.showSheetLeft
        ? "menu-left-menu-after"
        : "menu-left-menu-befor";
    },
    //点击跳转sheet导图
    clickSwitchSheet(item, index) {
      //清空右键菜单
      this.reightMenuIndex = -1;
      this.moveSheetMenu = false;
      this.oldIndex = this.showIndex;
      this.showIndex = index;
      if (
        item.rootId == this.$route.query.id &&
        item.id == this.$route.query.sheetId
      ) {
        return;
      }
      if (
        (item.rootId == this.$route.params.id &&
          item.id == this.$route.query.sheetId) ||
        (item.id == item.rootId && this.$route.query.sheetId == null)
      ) {
        return;
      }
      //保存导图
      // this.delegate.coverMindData();

      this.spinning = true;
      this.spinningSmall = true;
      this.showSwitchSheet = true;
      this.spatialCuesFile();
      this.sheetSwitchItem = item;
      if (
        (this.$route.name == "gmap" && this.MindPageType != "groupMapEdit") ||
        (this.$route.name == "arvhiveMap" &&
          this.MindPageType != "arvhiveMapEdit") ||
        this.$route.name == "c" ||
        this.$route.name == "s" ||
        this.$route.name == "template"
      ) {
        this.saveSheetSwitchAccout("saveSuccess");
      } else {
        // clearTimeout(EditMindmapVM.coverMindTimeout);
        this.exitEditStatus();
        EditMindmapVM.saveDataForClosePage();
      }
      this.editAccout.url = "";
      this.showElementMenuList = false; //隐藏节点右键菜单
      this.isShowFreeNodeMenu = false; //隐藏右键菜单
      this.lineColorColorAccout = false
    },
    //切换分页之前保存导图的回调，保存导图成功则切换
    saveSheetSwitchAccout(accout) {
      this.editmapScrollView.hideInput();
      let item = this.sheetSwitchItem;
      if (accout == "saveSuccess") {
        this.showSwitchSheet = false;
        // this.editAccout.url = "";
        if (item.rootId == item.id) {
          if (this.$route.name == "c") {
            this.$router.replace({
              params: {
                id: item.rootId,
              },
            });
          } else if (this.$route.name == "s") {
            this.$router.replace({
              params: {},
            });
          } else {
            if (
              this.routerData == "gmap" &&
              this.MindPageType == "groupMapEdit"
            ) {
              this.MindPageType = null;
              this.$router.replace({
                query: {
                  id: item.rootId,
                  editState: "groupMapEdit",
                },
              });
            } else if (
              this.routerData == "arvhiveMap" &&
              this.MindPageType == "arvhiveMapEdit"
            ) {
              this.MindPageType = null;
              this.$router.replace({
                query: {
                  id: item.rootId,
                  editState: "arvhiveMapEdit",
                },
              });
            } else {
              if (LoadLocalXJMind.isLoaclMind()) {
                let path = MindmapDataRouter.getPath();
                let localpath = MindmapDataRouter.getLocalPath();
                if (localpath != null && localpath.length > 0) {
                  this.$router.replace({
                    query: {
                      id: item.rootId,
                      path: encodeURIComponent(path),
                      localpath: encodeURIComponent(localpath)
                    },
                  });
                } else {
                  this.$router.replace({
                    query: {
                      id: item.rootId,
                      path: encodeURIComponent(path)
                    },
                  });
                }
              } else {
                this.$router.replace({
                  query: {
                    id: item.rootId,
                  },
                });
              }
              
            }
          }
        } else {
          if (this.$route.name == "c" || this.$route.name == "s") {
            this.$router.replace({
              query: {
                sheetId: item.id,
              },
            });
          } else {
            if (
              this.routerData == "gmap" &&
              this.MindPageType == "groupMapEdit"
            ) {
              // this.MindPageType = null;
              this.$router.replace({
                // path: "mindmap",
                query: {
                  id: item.rootId,
                  sheetId: item.id,
                  editState: "groupMapEdit",
                },
              });
            } else if (
              this.routerData == "arvhiveMap" &&
              this.MindPageType == "arvhiveMapEdit"
            ) {
              this.MindPageType = null;
              this.$router.replace({
                query: {
                  id: item.rootId,
                  sheetId: item.id,
                  editState: "arvhiveMapEdit",
                },
              });
            } else {
              if (LoadLocalXJMind.isLoaclMind()) {
                let path = MindmapDataRouter.getPath();
                let localpath = MindmapDataRouter.getLocalPath();
                if (localpath != null && localpath.length > 0) {
                  this.$router.replace({
                    query: {
                      id: item.rootId,
                      sheetId: item.id,
                      path: encodeURIComponent(path),
                      localpath: encodeURIComponent(localpath)
                    },
                  });
                } else {
                  this.$router.replace({
                    query: {
                      id: item.rootId,
                      sheetId: item.id,
                      path: encodeURIComponent(path)
                    },
                  });
                }
              } else {
                this.$router.replace({
                  query: {
                    id: item.rootId,
                    sheetId: item.id
                  },
                });
              }
            }
          }
        }
      } else if (accout == "error") {
        this.showIndex = this.oldIndex;
        this.showSwitchSheet = false;
        this.spinning = false;
        this.spinningSmall = false;
        this.$message.error("导图保存失败，切换分页不成功");
      }
    },
    //sheet右键菜单回调
    // hideSheetMenu(){
    // }
    //sheet右键菜单
    reightMenuSheetClick(item, index, $event) {
      this.reightMenuX = $event.x;
      this.reightMenuIndex = index;
      if (
        this.$route.name == "Complie" ||
        (this.$route.name == "arvhiveMap" &&
          this.MindPageType == "arvhiveMapEdit") ||
        (this.routerData == "gmap" && this.MindPageType == "groupMapEdit")
      ) {
        this.moveSheetMenu = true;
      }
      this.sheetNameObj = item;
    },
    //右键菜单--插入
    sheetEditInsertCheck(item, index) {
      this.clickCreated(item.index);
    },
    //右键菜单--复制,,postGroupNewGsheet,
    sheetCopyCheck(item, index) {
      let obj = {};
      obj.rootId = item.rootId;
      obj.sheetId = item.id;
      obj.groupId = this.groupGmapId;
      let that = this;
      if (LoadLocalXJMind.isLoaclMind() && !LoadLocalXJMind.isCloudStorage()) {
        new NewXJMindMap(LoadLocalXJMind.getName(), 1, that.$router.appPath, "").
                    copyLocalSheetMindMap(item.id).then((creearData) => {
          if (creearData != null) {
              setTimeout(() => {
                that.getSheetPaginationList(0);
                let itemObj = {};
                itemObj.rootId = creearData.rootId;
                itemObj.id = creearData.id;
                that.clickSwitchSheet(itemObj, creearData.index);
              }, 200);
          } else {
            that.$message.error("创建失败！")
          }
        })
        return
      }
      if (this.$route.name == "Complie" || this.$route.name == "arvhiveMap") {
        return new Promise(function (resolve, reject) {
          postMindmapCopySheet(
            obj,
            (res) => {
              resolve(res);
              that.$message.success("复制成功");
              that.getSheetPaginationList(0);
            },
            (error) => {
              that.$message.error(error.desc);
              reject(error);
            }
          );
        });
      } else if (this.$route.name == "gmap") {
        return new Promise(function (resolve, reject) {
          postGroupCopyGsheet(
            obj,
            (res) => {
              resolve(res);
              that.$message.success("复制成功");
              that.getSheetPaginationList(0);
            },
            (error) => {
              that.$message.error(error.desc);
              reject(error);
            }
          );
        });
      }
    },
    //右键菜单插入--重命名
    sheetFolderRenameCheck(item, index) {
      // this.removeShortcutKey();
      this.createdSheetName = "folderRename";
      this.sheetName = item.name;
      this.sheetNameObj = item;
      this.sheetCreatedModal = true;
      setTimeout(() => {
        this.$refs.sheet_name.focus();
      }, 50);
    },
    //修改分页导图名
    uploadSheetName(obj) {
      let that = this;
      if (this.$route.name == "Complie" || this.$route.name == "arvhiveMap") {
        return new Promise(function (resolve, reject) {
          postMindmapModifySheet(
            obj,
            (res) => {
              resolve(res);
            },
            (error) => {
              that.$message.error(error.desc);
              reject(error);
            }
          );
        });
      } else if (this.$route.name == "gmap") {
        return new Promise(function (resolve, reject) {
          postGroupModifyGsheet(
            obj,
            (res) => {
              resolve(res);
            },
            (error) => {
              that.$message.error(error.desc);
              reject(error);
            }
          );
        });
      }
    },
    //右键菜单插入--删除
    sheetRemove(item, index) {
      if (item.id == this.$route.query.sheetId) {
        this.$message.error("您正在浏览当前导图，不允许删除！");
        return;
      }
      let that = this;
      this.$confirm({
        title: "删除?",
        content: "确定要删除分页导图吗？",
        cancelText: getString(strings.Global_Cancel),
        okText: getString(strings.Global_Ok),
        centered: true,
        style: {
          borderRadius: "20px",
        },
        onOk() {
          if (LoadLocalXJMind.isLoaclMind()) {
            new NewXJMindMap(LoadLocalXJMind.getName(), 1, that.$router.appPath).removeLocalSheetMindMap(item.id).then((success) => {
              if (success && !LoadLocalXJMind.isCloudStorage()) {
                setTimeout(() => {
                  that.getSheetPaginationList(0);
                }, 200);
              }
            })
            if (!LoadLocalXJMind.isCloudStorage()) {
              return;
            }
          }
          
          let obj = {};
          obj.rootId = item.rootId;
          obj.sheetId = item.id;
          obj.groupId = that.groupGmapId;
          that.deletSheet(obj).then((data) => {
            that.$message.success("删除成功");
            // that.$message({
            //   type: 'success',
            //   message: '删除成功',
            //   center: true,
            //   offset:50
            // });
            // this.clickSwitchSheet();

            that.getSheetPaginationList(0);
          });
        },
        onCancel() {},
      });
    },
    sheetExportCheck(item, index) {
      if (LoadLocalXJMind.isLoaclMind() && !LoadLocalXJMind.isCloudStorage() && item != null && item.id != null) {
        new UiUtil().showNeedToUpload(this, EditMindmapVM)
        return;
      }
      let obj = {};
      obj.sheetId = item.id;
      postMindmapExportSheet(
        obj,
        (res) => {
          let query = {};
          let rouName = "";
          query["id"] = res.mindMapId;
          if (this.$route.name == "arvhiveMap") {
            rouName = "/arvhiveMap";
          } else {
            rouName = "/mindmap";
          }
          const routerData = this.$router.resolve({
            path: rouName,
            query,
          });
          this.editMindmapContent.createATagJump(routerData.href);
        },
        (error) => {
          this.$message.error(error.desc);
        }
      );
    },
    //删除分页导图
    deletSheet(obj) {
      let that = this;
      if (this.$route.name == "Complie" || this.$route.name == "arvhiveMap") {
        return new Promise(function (resolve, reject) {
          postMindmapDeleteSheet(
            obj,
            (res) => {
              resolve(res);
            },
            (error) => {
              that.$message.error(error.desc);
              reject(error);
            }
          );
        });
      } else if (this.$route.name == "gmap") {
        return new Promise(function (resolve, reject) {
          postGroupDeleteGsheet(
            obj,
            (res) => {
              resolve(res);
            },
            (error) => {
              that.$message.error(error.desc);
              reject(error);
            }
          );
        });
      }
    },
    //右键菜单回调
    hiddenSheetTopReightMenu(obj) {
      //清空文本输入框
      this.sheetName = "";
      //关闭右键菜单
      this.reightMenuIndex = -1;
      this.moveSheetMenu = false;
      if (obj.menuName == "sheetEditInsertCheck") {
        this.sheetEditInsertCheck(obj.item, obj.index);
      } else if (obj.menuName == "sheetCopyCheck") {
        this.sheetCopyCheck(obj.item, obj.index);
      } else if (obj.menuName == "sheetFolderRenameCheck") {
        this.sheetFolderRenameCheck(obj.item, obj.index);
      } else if (obj.menuName == "sheetRemove") {
        this.sheetRemove(obj.item, obj.index);
      } else if (obj.menuName == "sheetExportCheck") {
        this.sheetExportCheck(obj.item, obj.index);
      }
    },
    outsideCloseSheetTopMenu() {
      this.reightMenuIndex = -1;
      this.moveSheetMenu = false;
    },
    spaceUpgradeCklickAlter(val) {
      this.spaceUpgradeModalShow = val;
    },

    // 初始化与绑定监听事件方法
    scrollInitTopSheet() {
      // 获取要绑定事件的元素
      const scrollDiv = document.getElementById("sheetScroll");
      // document.addEventListener('DOMMouseScroll', handler, false)
      // 添加滚轮滚动监听事件，一般是用下面的方法，上面的是火狐的写法
      scrollDiv.addEventListener("mousewheel", handler, false);
      let that = this;
      // 滚动事件的出来函数
      function handler(event) {
        // 获取滚动方向
        const detail = event.wheelDelta || event.detail;
        // 定义滚动方向，其实也可以在赋值的时候写
        const moveForwardStep = 1;
        const moveBackStep = -1;
        // 定义滚动距离
        let step = 0;
        // 判断滚动方向,这里的100可以改，代表滚动幅度，也就是说滚动幅度是自定义的
        if (detail < 0) {
          step = moveForwardStep * 100;
        } else {
          step = moveBackStep * 100;
        }
        // 对需要滚动的元素进行滚动操作
        scrollDiv.scrollLeft += step;

        if (Math.abs(scrollDiv.scrollWidth - scrollDiv.scrollLeft - scrollDiv.clientWidth) < 50 && that.isReightScrollButton) {
          let sheetsListConunt = that.initMindSheetDatas.length - 1;
          that.getSheetPaginationList(
            that.initMindSheetDatas[sheetsListConunt].index
          );
        }
        if (
          scrollDiv.scrollWidth - scrollDiv.scrollLeft - scrollDiv.clientWidth >
          0
        ) {
          that.isReightScrollButton = true;
        } else {
          that.isReightScrollButton = false;
        }
        if (scrollDiv.scrollLeft > 0) {
          that.isLeftScrollButton = true;
        } else {
          that.isLeftScrollButton = false;
        }
      }
    },
    //底部list右滚动
    scrollReightSheet() {
      this.reightMenuIndex = -1;
      this.moveSheetMenu = false;
      var scrollObj = document.getElementById("sheetScroll");
      scrollObj.scrollLeft += 90;
      this.isLeftScrollButton = true;
      if (Math.abs(scrollObj.scrollWidth - scrollObj.scrollLeft - scrollObj.clientWidth) < 50) {
        let sheetsListConunt = this.initMindSheetDatas.length - 1;
        this.getSheetPaginationList(
          this.initMindSheetDatas[sheetsListConunt].index
        );
      }
      if (scrollObj.scrollWidth - scrollObj.clientWidth > 0) {
        this.isReightScrollButton = true;
      } else {
        this.isReightScrollButton = false;
      }
    },
    //左滚动
    scrollLeftSheet() {
      this.reightMenuIndex = -1;
      this.moveSheetMenu = false;
      var scrollObj = document.getElementById("sheetScroll");
      scrollObj.scrollLeft -= 90;
      if (scrollObj.scrollLeft == 0) {
        this.isLeftScrollButton = false;
      }
      if (scrollObj.scrollWidth - scrollObj.clientWidth > 0) {
        this.isReightScrollButton = true;
      } else {
        this.isReightScrollButton = false;
      }
    },
    //滚动到选中的列表
    topListScroll() {
      var scrollObj = document.getElementById("sheetScroll");
      if (this.showIndex >= 2) {
        scrollObj.scrollLeft = 90 * (this.showIndex - 1);
      } else if (scrollObj.scrollLeft != 0) {
        scrollObj.scrollLeft = 0;
      }
    },
    // 已登录免费用户导图数量超过2个，则每次打开都弹购买弹窗
    mapsNumberMoreThanTwo() {
      const mapsNumber = localStorage.getItem("mapsNumber") || 0;
      const meMessage = MindMe.getMeMessage();
      if (mapsNumber >= numberOfMaps && meMessage && !meMessage.isMember) {
        // 已登录 弹付费窗 2分钟弹出一次
        let showShoppingTime = localStorage.getItem("showShoppingTime");
        let nowTime = Util.getCurrentTime();
        if (showShoppingTime) {
          let time = nowTime - showShoppingTime;
          if (time > Config.REGISTER_POPUP_WINDOW_TIME) {
            this.$store.state.showShopping = true;
            localStorage.setItem("showShoppingTime", nowTime);
          }
        } else {
          localStorage.setItem("showShoppingTime", nowTime);
          let firstOpenPage = sessionStorage.getItem("firstOpenPage");
          if (!firstOpenPage) {
            sessionStorage.setItem("firstOpenPage", true);
            this.$store.state.showShopping = true;
          }
        }
      }
    },
    getProgressBar(vPercentage) {
      let vp = vPercentage / 2 + 22;
      document.documentElement.style.setProperty("--windowW", vp + "px");
    },
    // 通用配置接口
    baseGlobalConfigAPI(obj) {
      postBaseGlobalConfig(
        obj,
        (res) => {
          this.generalConfigurationParameters = res;
          let timer = setTimeout(() => {
            if (
              this.$route.name == "Complie" ||
              (this.$route.name == "gmap" &&
                this.MindPageType != "groupMapEdit") ||
              (this.$route.name == "arvhiveMap" &&
                this.MindPageType != "arvhiveMapEdit")
            ) {
              this.editMindmapContent.checkLoginAndMemberFloatPopup();
            } else {
              clearInterval(this.timePaidPopUp);
              this.timePaidPopUp = null;
            }
            clearTimeout(timer);
          }, 10000);
        },
        (error) => {
          this.$message.error(error.desc);
          this.spinning = false;
        }
      );
    },
    //节点限制提示框
    titleNodeLimit(nodeCount) {
      let that = this;
      this.$confirm({
        title: getString(strings.Mind_Tips),
        content: "非会员节点最多只能创建" + nodeCount + "个,是否去充值会员？",
        centered: true,
        okText: getString(strings.Mind_Edit_Confirm),
        cancelText: getString(strings.Mind_Group_Cancel),
        onOk() {
          that.isShowShoppingModel(true);
          that.showTitleNodeLimit = false;
        },
        onCancel() {
          that.showTitleNodeLimit = false;
        },
      });
    },
    //关闭浮动弹窗
    colseTitleFloatPopup() {
      this.showTitleFloatPopup = false;
    },
    visionTitleFloatPopup() {
      this.showTitleFloatPopup = true;
    },
    getTitleFloatPopup() {
      this.isShowShoppingModel(true);
    },
    floatPopupLoop() {
      this.timePaidPopUp = setInterval(() => {
        if (
          this.$route.name == "Complie" ||
          (this.$route.name == "gmap" && this.MindPageType != "groupMapEdit") ||
          (this.$route.name == "arvhiveMap" &&
            this.MindPageType != "arvhiveMapEdit")
        ) {
          this.editMindmapContent.checkLoginAndMemberFloatPopup();
        } else {
          clearInterval(this.timePaidPopUp);
          this.timePaidPopUp = null;
        }
      }, Config.REGISTER_POPUP_WINDOW_TIME);
    },
    dblcickLineOrWrapping() {
      document.addEventListener("dblclick", (ev) => {
        window.event
          ? (window.event.cancelBubble = true)
          : ev.stopPropagation();
        this.dblclickHandle(ev);
      });
    },
    dblclickHandle(ev) {
      let x = ev.pageX;
      let y = ev.pageY;
      if (this.isClickFontStyleMenu(x, y)) {
        return
      }
      let screenRect = this.editmapScrollView.getScreenRect();
      let mapX = screenRect.x + x / this.editmapScrollView.scale;
      let mapY =
        screenRect.y +
        (y - this.canvasTopPattern1) / this.editmapScrollView.scale;
      let data = EditMindmapVM.getMindElementDataByPoint(new Point(mapX, mapY));

      if (data == null || data.isEmpty()) {
        return;
      }
      if (data.type == MindElementType.NODE_CONNECT_LINE) {
        //TODO Edit text
        // if(){
        this.editmapScrollView.setInput(data);
        // }
      } else if (data.type == MindElementType.BAOWEI_VIEW) {
        //TODO Edit text
        this.editmapScrollView.setInput(data);
      }
    },
    // 复制图片到剪切板
    copyPicture2Clipboard() {
      var clipboardText = "";

      navigator.clipboard.readText().then( function(clipText) {
          clipboardText = clipText
      });
      
      let rect = this.editMindmapContent.getNodeRectForSaveImage(); //获取节点信息
      let isSettingFontFamilyInSelectedNode = EditMindmapVM.isSettingFontFamilyInSelectedNode()
      this.editMindmapContent.removeNodeLineWithOut(); //移除线条
      this.editMindmapContent.uncheckNode(); //取消选中节点
      let name = this.editMindmapContent.getTitle();
      let bgColor = this.$store.state.saveImageBgcolor;
      let toImage = new ToImage();
      let type = "copyPicture";
      let outType = "png";
      let image = toImage.toImage(rect, bgColor, type, outType, name, isSettingFontFamilyInSelectedNode);
      image.then(async (res) => {
        if (res) {
          try {
            const response = await fetch(res);
            const blob = await response.blob();
            var imgeClipboardItem = null;

            if (clipboardText != null && clipboardText.length > 0) {
                imgeClipboardItem = new window.ClipboardItem({
                                    [blob.type]: blob,
                                    'text/plain': new Blob([clipboardText], {type: 'text/plain'})
                                })
            } else {
              imgeClipboardItem = new window.ClipboardItem({
                    [blob.type]: blob
                })
            }
            if (imgeClipboardItem != null) {
              await navigator.clipboard.write([imgeClipboardItem]);
            }
          } catch (error) {
            //this.$message.error(error);
          }
        }
      });
      this.editMindmapContent.refresh(true);
    },
    latexRefresh(obj) {
      // setTimeout(()=>{
      // window.MathJax.typesetPromise();
      // if (window.MathJax.typeset == null) {
      //   return;
      // }
      // window.MathJax.typeset();
      // },200)
      //  this.$formula(obj)
    },
    latexTurnImgs() {
      let that = this;
      return new Promise(function (resolve, reject) {
        let list = that.editMindmapContent.getAllLatexList();
        let count = list.length;
        for (var index = 0; index < count; index++) {
          let node = list[index];
          that.latexImg(
            node.value.id,
            node.value.latexContent.text,
            node.value.latexContent.width
          );
        }
        resolve(true);
      });
    },
    //将latex公式转为图片
    latexImg(id, text, width) {
      let obj = document.getElementById(text + id);
      // setTimeout(() => {
      var img = document.createElement("img");
      var canvas2 = document.createElement("canvas");
      let _canvas = document.getElementById(text + id);
      // let img = document.querySelector('.group-shera-img');
      var w = parseInt(window.getComputedStyle(_canvas).width);
      var h = parseInt(window.getComputedStyle(_canvas).height);
      var context = canvas2.getContext("2d");
      let ratio = Util.getPixelRatio(context);
      if (ratio == 1) {
        ratio = 2;
      }
      canvas2.width = w * ratio;
      canvas2.height = h * ratio;
      canvas2.style.width = w + "px";
      canvas2.style.height = h + "px";

      context.scale(ratio, ratio);
      let rect = obj.getBoundingClientRect();
      html2canvas(obj, {
        // canvas: canvas2,
        backgroundColor: null,
        x: 0,
        y: 0,
        allowTaint: true,
        imageTimeout: 15000,
        scale: 2,
        dpi: 48,
        logging: false,
        useCORS: true,
        // width:width*ratio,
        scrollY: 0,
        scrollX: 0,
      }).then(function (canvas) {
        obj.innerHTML = "";
        img.setAttribute("src", canvas.toDataURL());
        // img.style.height = canvas.style.height;
        obj.appendChild(img);
        img.style.width = width + "px";
      });
    },
    //测试代码
    // testDomtoimage(id){
    //   const that = this
    //   const node = document.getElementById(id) // 通过id获取dom
    //   console.log(node, 'node')
    //   console.log(domtoimage, 'domtoimage')
    //   domtoimage
    //     .toPng(node)
    //     .then((dataUrl) => {
    // 	   //输出图片的Base64,dataUrl
    // 	// 下载到PC
    //   console.log(dataUrl);
    //       // const a = document.createElement('a') // 生成一个a元素
    //       // const event = new MouseEvent('click') // 创建一个单击事件
    //       // a.download = 'xxxx' // 设置图片名称没有设置则为默认
    //       // a.href = dataUrl // 将生成的URL设置为a.href属性
    //       // a.dispatchEvent(event) // 触发a的单击事件
    //     })
    //     .catch(function (error) {
    //       console.error('oops, something went wrong!', error)
    //     })
    // },
    // deleteLatexNodeId(nodeId){
    //   let latexNodesId = this.$store.state.latexNodesId;
    //   let nodeIndex = latexNodesId.indexOf(nodeId);
    //   if(nodeIndex >= 0){
    //     return;
    //   }
    //   latexNodesId.splice(nodeIndex,1);
    //   this.setLatexNodesId(latexNodesId);
    // },
  },

  watch: {
    // "$route.query.sheetId": {
    //     handler() {
    //       this.postMindmapSheet();
    //       this.headerMenu = false;
    //       // this.hideDgModelAccout =
    //       setTimeout(()=>{
    //         this.headerMenu = true;
    //       },10)

    //     },
    //     deep: true,
    // },
    "$store.state.isShowMindmapRightMenu": {
      handler(newIsShowMindmapRightMenu) {
        this.rightShowMenu = newIsShowMindmapRightMenu;
        this.rightMenuAccout = newIsShowMindmapRightMenu;
        this.isShowRightAccout(newIsShowMindmapRightMenu);
      },
      immediate: true,
    },
    "$store.state.outlineViews"(outlineViews) {
      if (outlineViews.data == null) {
        return;
      }
      this.outlineData = [];
      this.outlineDataIndex = [];
      // this.bus.$emit('outlineViewOrMindmap', id)
      this.editMindmapContent.moveRootNodeCenterDisplay();
    },
    initMindData: function (newInitMindDatas, olInitMindDatas) {
      (this.initMindDatas = newInitMindDatas),
        (this.initMindData = newInitMindDatas);
      this.getInitMindData();
      if (this.$tools.isElectron() && this.initMindData.name != null) {
        ipcRenderer.send("setSonWinTitle", this.initMindData.name);
      }

      this.showIndex = this.initMindData.index;
      this.spinning = false;
      this.spinningSmall = false;

      //右侧菜单是否展示
      this.setRightMenu();
      // if (this.rightMenuAccout ) {
      //   this.rightShowMenu = true;
      //   this.fullWidth = document.documentElement.clientWidth - 291;
      // } else {
      //   this.rightShowMenu = false;
      //   this.fullWidth = document.documentElement.clientWidth;
      // }
      this.fullHeight = document.documentElement.clientHeight - 66 - this.bottomMenuHeight;
      this.canvasTopPattern1 = 66; //设置距离顶部的高度，用于在退出全屏等处使用。
      this.canvasTop = 66;
      // setTimeout(() => {
      //   this.scrollInitTopSheet();

      this.getSheetPaginationList(0);
      this.fullscreenSheet();
      // },200)
    },
    initMindGroupViewData: function (newInitMindGroupViewData) {
      (this.initMindGroupViewDatas = newInitMindGroupViewData),
        (this.initMindGroupViewData = newInitMindGroupViewData);
      this.isSharePage = true; //页面的布局类似于分享页面

      this.groupGmapId = this.initMindGroupViewDatas.groupId;
      this.setRightMenu();
      if (
        this.$tools.isElectron() &&
        this.initMindGroupViewDatas.name != null
      ) {
        ipcRenderer.send("setSonWinTitle", this.initMindGroupViewDatas.name);
      }
      // this.rightShowMenu = false;
      // this.fullWidth = document.documentElement.clientWidth;
      this.fullHeight = document.documentElement.clientHeight - 66 - this.bottomMenuHeight;
      this.canvasTopPattern1 = 66; //设置距离顶部的高度，用于在退出全屏等处使用。
      this.canvasTop = 66;

      this.showIndex = this.initMindGroupViewDatas.index;
      this.spinning = false;
      this.spinningSmall = false;

      this.setInitMindGroupViewData();
      this.getSheetPaginationList(0);
      this.fullscreenSheet();
    },
    mindMapingType: {
      handler(val) {
        if (val.value == true) {
          this.MindPageType = "groupMapEdit";
          if (this.$route.name == "arvhiveMap") {
            this.MindPageType = "arvhiveMapEdit";
          }
          this.floatPopupLoop();
          this.useShortcut = true;
          this.isSharePage = false; //页面的布局类似于分享页面
          // this.rightShowMenu = true;
          // this.rightShowMenu = false;
          // this.fullWidth = document.documentElement.clientWidth;
          this.setRightMenu();
          this.fullHeight = document.documentElement.clientHeight - 66 - this.bottomMenuHeight;
          this.canvasTopPattern1 = 66; //设置距离顶部的高度，用于在退出全屏等处使用。
          this.canvasTop = 66;
          //重新设置vm中的数据。
          //判断是不是第一次创建导图
          if (
            this.initMindGroupViewDatas.content != "" &&
            this.initMindGroupViewDatas.content != null
          ) {
            this.editMindmapContent.loadData(
              this.initMindGroupViewDatas.content
            );
          }
          //由于从查看切换到编辑状态，如果有节点选中，那么会有节点编辑菜单出现在菜单左上角，所以要在切换的时候隐藏。
          this.editmapScrollView.hideInput();
          this.destroyedPage();
          // let gmapId = this.$route.params.id
          // this.startGroupEditGmap({gmapId : gmapId}).then(res => {
          //   this.startGroupEditGmapData = res;
          //   clearInterval(this.pingIntervalFunc);
          //   //由于定时器会延时，所以第一个要默认执行。
          //   this.startGroupEditGmapPing({gmapId : gmapId});
          //   this.pingIntervalFunc =  setInterval(res => {
          //     this.startGroupEditGmapPing({gmapId : gmapId});
          //   },res.pingInterval * 1000 )
          // })
        } else {
          this.useShortcut = false;
        }

        this.fullscreenSheet();
      },
      deep: true,
    },

    initTemplateData: function (newInitTemplateData, olInitTemplateData) {
      (this.initTemplateDatas = newInitTemplateData),
        (this.initTemplateData = newInitTemplateData);

      this.isTemplatePage = true;
      this.rightShowMenu = false;
      this.fullWidth = document.documentElement.clientWidth;
      this.fullHeight = document.documentElement.clientHeight - 81 - this.bottomMenuHeight;
      this.canvasTopPattern1 = 81; //设置距离顶部的高度，用于在退出全屏等处使用。
      this.canvasTop = 81;
      this.getTemplateInitMindData();

      if (this.$tools.isElectron() && this.initTemplateData.title != null) {
        ipcRenderer.send("setSonWinTitle", this.initTemplateData.title);
      }

      this.showIndex = this.initTemplateData.index;
      this.spinning = false;
      this.spinningSmall = false;
      this.getSheetPaginationList(0);
      this.fullscreenSheet();
    },
    shareMindData: function (newShareMindData, olShareMindData) {
      this.initShareMindDatas = newShareMindData;
      this.shareMindData = newShareMindData;

      this.shareRootId = newShareMindData.rootId;
      this.showIndex = this.shareMindData.index;
      this.spinning = false;
      this.spinningSmall = false;

      this.isSharePage = true;
      this.rightShowMenu = false;

      if (this.$tools.isElectron() && this.shareMindData.name != null) {
        ipcRenderer.send("setSonWinTitle", this.shareMindData.name);
      }
      if (this.isNowPhone) {
        //是手机
        this.fullWidth = document.documentElement.clientWidth;
        this.fullHeight = document.documentElement.clientHeight;
        this.canvasTop = 0;
      } else {
        this.fullWidth = document.documentElement.clientWidth;
        this.fullHeight = document.documentElement.clientHeight - this.canvasTopPattern1 - this.bottomMenuHeight;
        this.canvasTop = this.canvasTopPattern1;
      }
      
      if (this.isSharePageView() && new UiUtil().isPhoneAndPad()) {
        var checkMenuColorScaleMenuObj = document.getElementById("checkMenuColor-scale-menu");
        this.fullHeight = document.documentElement.clientHeight// - this.canvasTopPattern1;
        checkMenuColorScaleMenuObj.style.display = "none";
      }
      let headerDom = document.getElementsByClassName("content-header-user-message");
      if (headerDom[0] != null) {
        headerDom[0].style.backgroundColor = "#F0F0F0";
        if (new UiUtil().isPhoneAndPad()) {
          headerDom[0].style.height = "60px"
        }
      }
      this.getShareMindData();
      this.getSheetPaginationList(0);
      this.fullscreenSheet();
    },
    initCrispsData: function (newCrispsData) {
      this.initCrispsData = newCrispsData;
      this.initCrispsMindData = newCrispsData;
      //风暴页面类似于模板页面。
      this.isTemplatePage = true;
      this.rightShowMenu = false;

      if (this.isNowPhone) {
        //是手机
        this.fullWidth = document.documentElement.clientWidth;
        this.fullHeight = document.documentElement.clientHeight;
        this.canvasTop = 0;
      } else {
        this.fullWidth = document.documentElement.clientWidth;
        this.fullHeight = document.documentElement.clientHeight - 81 - this.bottomMenuHeight;
        this.canvasTop = 81;
      }
      // this.fullWidth = document.documentElement.clientWidth;
      // this.fullHeight = document.documentElement.clientHeight - 81;
      // this.canvasTop = 81;

      if (this.$tools.isElectron() && this.initCrispsData.title != null) {
        ipcRenderer.send("setSonWinTitle", this.initCrispsData.title);
      }
      this.getCrispsData();

      this.showIndex = this.initCrispsData.index;
      this.spinning = false;
      this.spinningSmall = false;

      this.getSheetPaginationList(0);
      this.fullscreenSheet();
    },
    fullWidth: function (val) {
      //监控浏览器宽度变化
      this.fullWidth = val;
    },
    fullHeight: function (val) {
      //监控浏览器高度变化
      if (val < 0) {
        return
      }
      if (this.timetag == null) {
        this.timetag = Util.getCurrentTime()
      }
      this.fullHeight = val;
    },
    DrawingBoardBackgroundColor: function (bgColor) {
      if (bgColor == null) {
        return;
      }
      this.checkScaleIconBackground(bgColor);
      this.saveImageBgcolor(bgColor);
      this.checkAddIconColor(bgColor);
      this.DrawingBoardBackgroundColor = bgColor;
      this.topToolDardarkMode(bgColor); //判断是否是深色模式
      if (!this.isDarkColor) {
        if (bgColor == 1) {
          this.sheetBarColor = "#000000";
        } else {
          this.sheetBarColor = Colors.getNumberToRgbDark(bgColor, 0.2);
        }
      } else {
        this.sheetBarColor = "#f5f5f5";
      }
    },
    // isRightShowMenu(newRightMenuAccout) {
    //   this.isShowRightAccout(newRightMenuAccout);
    // },
    "$store.state.useShortcut"(newval) {
      this.useShortcut = newval;
    },
    "$store.state.spining"(newval) {
      this.spinning = newval;
    },
    "$store.state.busColor"(newval, olval) {
      if (newval == null) {
        return;
      }
      this.changMindBackgroundColor(colorChangeClass.ColorToSystem(newval));
    },
    "$store.state.dottedLineAccout": {
      handler(newval, olval) {
        if (newval == null) {
          return;
        }
        this.getDottedLineAccout(newval.type);
      },
      deep: true,
      immediate: true,
    },
    "$store.state.baoWeiLineColor"(newval, olval) {
      if (newval == null) {
        return;
      }
      this.getBaoWeiLineColor(parseInt(newval));
    },
    "$store.state.baoWeiFillColor"(newval, olval) {
      if (newval == null) {
        return;
      }
      this.getBaoWeiFillColor(parseInt(newval));
    },
     "$store.state.textShadowColor"(newval, olval) {
      if (newval == null) {
        return;
      }
      this.getTextShadowColor(parseInt(newval));
    },

    "$store.state.textColor"(newval, olval) {
      if (newval == null) {
        return;
      }
      let start = this.editmapScrollView.startSubscript;
      let end = this.editmapScrollView.endSubscript;
      if (
        start < 0 ||
        end < 0 ||
        start > end ||
        start == end ||
        (start == 0 && end >= this.editmapScrollView.inputView.innerText.length)
      ) {
        this.getTextColor(parseInt(newval));
      } else {
        // this.getTextColor(parseInt(newval));
        var obj = {
          styleName: "fontColor",
          textColor: newval,
        };
        this.editmapScrollView.updateStyle(obj);
      }
    },
    "$store.state.textSize"(newval, olval) {
      if (newval == null) {
        return;
      } else {
        this.textContent.textFontSize = newval;
        this.getTextFontSize(
          this.$store.state.nodeContentMessage,
          this.textContent
        );
        this.getTextSize(null);
      }
    },
    "$store.state.textStyle"(newval, olval) {
      if (newval == null) {
        return;
      } else {
        let start = this.editmapScrollView.startSubscript;
        let end = this.editmapScrollView.endSubscript;
        if (
          start < 0 ||
          end < 0 ||
          start > end ||
          start == end ||
          (start == 0 &&
            end >= this.editmapScrollView.inputView.innerText.length)
        ) {
          this.getTextStyle(parseInt(newval));
        } else {
          // this.getTextColor(parseInt(newval));
          let styleNameNew = "";
          if (newval == 0) {
            styleNameNew = "fontWeight";
          } else if (newval == 1) {
            styleNameNew = "fontItalic";
          } else if (newval == 2) {
            styleNameNew = "fontUnderline";
          }
          var obj = {
            styleName: styleNameNew,
          };
          this.editmapScrollView.updateStyle(obj);
        }
        // this.getTextStyle(newval);
        setTimeout(() => {
          this.getKeepAccout();
        }, 100);
      }
    },
    "$store.state.nodeFontAlign": {
      handler(newval, olval) {
        if (newval == null) {
          return;
        } else {
          this.editMindmapContent.changeAlignmentType(newval.type);
        }
      },
      deep: true,
    },

    "$store.state.borderColor"(newval, olval) {
      if (newval == null) {
        return;
      } else {
        this.getBorderColor(parseInt(newval));
      }
    },
    "$store.state.fillColor"(newval, olval) {
      if (newval == null) {
        return;
      } else {
        this.getFillColorColor(parseInt(newval));
      }
    },
    "$store.state.borderMinorStyle"(newval, olval) {
      if (newval == null) {
        return;
      } else {
        this.getLineMinorColor(parseInt(newval));
      }
    },
    "$store.state.borderline"(newval, olval) {
      if (newval == null) {
        return;
      } else {
        this.getBorderlLine(newval);
        this.setBorderlLine(null);
      }
    },
    "$store.state.nodeWidth"(newval, olval) {
      if (newval == null) {
        return;
      } else {
        this.getNodeWidth(newval);
        this.setNodeWidth(null);
      }
    },
    "$store.state.urlDomImg.imageBase64"(newval, olval) {
      if (newval == null || this.$store.state.urlDomImg.type == "storm") {
        return;
      }
      var id = -1;
      if (
        this.$store.state.nodeContentMessage != undefined &&
        this.$store.state.nodeContentMessage != null
      ) {
        id = this.$store.state.nodeContentMessage;
      }
      this.getAddImgData(
        newval,
        id,
        this.$store.state.urlDomImg.width,
        this.$store.state.urlDomImg.height
      );
    },
    "$store.state.urlDomVideo.videoBase64"(newval, olval) {
      if (newval == null || this.$store.state.urlDomVideo.type == "storm") {
        return;
      }
      var id = -1;
      if (
        this.$store.state.urlDomVideo.id != undefined &&
        this.$store.state.urlDomVideo.id != null
      ) {
        id = this.$store.state.urlDomVideo.id;
      }
      this.getAddVideoData(
        this.$store.state.urlDomVideo.videoBase64,
        this.$store.state.urlDomVideo.imageBase64,
        id,
        this.$store.state.urlDomVideo.width,
        this.$store.state.urlDomVideo.height
      );
    },
    "$store.state.nodeTypeNumber"(newval, olval) {
      if (newval == null) {
        return;
      } else {
        this.showLeftShapeView = false;
        this.getNodeStyle(newval);
        setTimeout(() => {
          this.getNodeStyleAccout();
        }, 100);
      }
    },
    "$store.state.encircleLineNumber"(newval, olval) {
      if (newval == null || newval == "") {
        return;
      } else {
        this.setEctrilineStyle(newval);
        //清空vuex中的包裹线状态
        this.setEncircleLineType(null);
      }
    },
    "$store.state.encircleLineTitleColor"(newval, olval) {
      if (newval == null || newval == "") {
        return;
      } else {
        // this.setEctrilineStyle(newval);
        this.editMindmapContent.changeEncircleLineTitleText(
          colorChangeClass.ColorToSystem(newval)
        );
      }
    },

    "$store.state.encircleLinePaddingNumber"(newval, olval) {
      if (newval == null || newval == "") {
        return;
      } else {
        this.setEncircleLinePadding(newval);
        //清空上次数据
        this.setPaddingSize(null);
      }
    },
    "$store.state.baoWeiTextSizeNumber"(newval, olval) {
      if (newval == null || newval == "") {
        return;
      } else {
        this.setEncircleLineTextSizeNumber(newval);
        //清空上次数据
        this.setbaoWeiTextSize(null);
      }
    },
    "$store.state.iconSize"(newval, olval) {
      if (newval == null) {
        return;
      } else {
        this.getIconSize(newval);
        //清空上次数据
        this.setIconSize(null);
      }
    },
    "$store.state.iconPlaceAccouts"(newval, olval) {
      if (newval == null) {
        return;
      } else {
        this.getIconPlace(newval);
      }
    },
    "$store.state.imageSize": {
      handler(newval, olval) {
        if (newval == null) {
          return;
        } else {
          this.getImgSize(newval.width, newval.height);
          // this.getImgSize(newval);
        }
      },
      deep: true,
    },
    "$store.state.imagePlace"(newval, olval) {
      if (newval == null) {
        return;
      } else {
        this.getImgPlace(newval);
      }
    },
    "$store.state.imageLayout"(newval, olval) {
      if (newval == null) {
        return;
      } else {
        this.getImgLayout(newval);
      }
    },
    "$store.state.lineMinorWitdh"(newval, olval) {
      if (newval == null) {
        return;
      } else {
        this.getLineMinorWidth(newval);
        //清空上次修改的数据
        this.setLineMinorWitdh(null);
      }
    },
    "$store.state.tiemLineArrow": {
      handler(newval, olval) {
        if (newval == null) {
          return;
        } else {
          // this.getImgSize();
          this.editMindmapContent.changeTimeLineArrow(newval.type);
        }
      },
      deep: true,
    },
    "$store.state.tiemLineDotted": {
      handler(newval, olval) {
        if (newval == null) {
          return;
        } else {
          this.editMindmapContent.changeTiemLineDotted(newval.type);
        }
      },
      deep: true,
    },
    "$store.state.freeNodeMoveAdsorbent": {
      handler(newval, olval) {
        if (newval == null) {
          return;
        } else {
          this.editMindmapContent.changeFreeNodeMoveAdsorbent(newval.type);
        }
      },
      deep: true,
    },
    "$store.state.branchFreeLayout": {
      handler(newval, olval) {
        if (newval == null) {
          return;
        } else {
          this.editMindmapContent.changeBranchNodeFreelayout(newval.type);
        }
      },
      deep: true,
    },
    "$store.state.lineColor"(newLineColor, olnewLineColor) {
      if (newLineColor == null) {
        return;
      } else {
        this.getLineColor(parseInt(newLineColor));
      }
    },
    "$store.state.lineWitdh"(newLineWidth, olnewLineWidth) {
      if (newLineWidth == null) {
        return;
      } else {
        this.getLineWidth(newLineWidth);
      }
    },
    "$store.state.lineTypeLayout"(newLineType, olnewLineWidth) {
      if (newLineType == null) {
        return;
      } else {
        this.getLineType(newLineType);
        setTimeout(() => {
          this.getLineTypeAccout();
        }, 100);
      }
    },
    "$store.state.remarkContent"(newRemarkContent, olnewRemarkContent) {
      if (newRemarkContent == null) {
        return;
      } else {
        this.getRemarkContent(newRemarkContent);
      }
    },
    // "$store.state.linkToAdd"(newLinkContent, olnewLinkContent) {
    //   this.addLink(newLinkContent, this.$store.state.nodeContentMessage.id);
    // },
    "$store.state.linkToRemove"(newLinkContent, olnewLinkContent) {
      this.removeLink(newLinkContent, this.$store.state.nodeContentMessage.id);
    },
    "$store.state.changeLineAccout"(
      newchangeLineAccout,
      olnewchangeLineAccout
    ) {
      this.setlineThicken(newchangeLineAccout);
    },
    "$store.state.tipsMessage"(newShowTipsMenuData, olnewShowTipsMenuData) {
      this.showTipsMenuData(
        getString(strings.Global_Add_Target_Tips),
        newShowTipsMenuData
      );
    },
    "$store.state.firstFontSize"(newVal, oldVal) {
      this.editMindmapContent.changeSubjectTextSize(newVal);
    },
    "$store.state.childrenNodeFontSize"(newVal, oldVal) {
      this.editMindmapContent.changeSonSubjectTextSize(newVal);
    },
    "$store.state.mp3Blob"(newMp3Blob) {
      if (newMp3Blob != "" && newMp3Blob != "del") {
        this.addGlobalAudio(newMp3Blob);
      } else {
        this.removeGlobalAudio(newMp3Blob);
      }
    },
    "$store.state.mp3Blob"(newMp3Blob) {
      if (newMp3Blob != "" && newMp3Blob != "del") {
        this.addGlobalAudio(newMp3Blob);
      } else {
        this.removeGlobalAudio(newMp3Blob);
      }
    },
    // "$store.state.chartContentToVm"(newChartContentToVm) {
    //   if (newChartContentToVm != null) {
    //     this.onStatisticChange(newChartContentToVm);
    //   }
    // },
    "$store.state.chartContentToVm"(newChartContentToVm) {
      if (newChartContentToVm != null) {
        this.onStatisticChange(newChartContentToVm);
      }
    },
    "$store.state.materialImgObj": {
      handler(newval, oldval) {
        if (newval == null) {
        } else {
          let type = this.$store.state.showMaterialLibraryModulesModel.type;
          var id = -1;
          if (
            this.$store.state.nodeContentMessage != undefined &&
            this.$store.state.nodeContentMessage != null
          ) {
            id = this.$store.state.nodeContentMessage.id;
          }
          if (type == "img") {
            this.editMindmapContent.addMaterialImage(
              newval.mageBase64,
              id,
              newval.width,
              newval.height,
              this.rightMenuClickPoint
            );
          } else if (type == "nbg" || type == "bbg") {
            this.editMindmapContent.addImageForNodeBackgroun(
              id,
              newval.mageBase64
            );
          }
        }
      },
      // immediate: true,    //在一开是绑定的时候执行
      deep: true, //深度监听
    },
    "$store.state.showMaterialLibraryModulesModel": {
      handler(newVal) {
        if (newVal.accout == true) {
        } else {
        }
      },
      // immediate: true,    //在一开是绑定的时候执行
      // deep: true, //深度监听
    },
    "$store.state.alignNodeStyleObj"(alignType) {
      if (alignType == null) {
        return;
      }
      this.alignTypeToVm(alignType);
    },
    "$store.state.saveAndEditAccout"(newSaveAndEdit, olSaveAndEdit) {
      if (newSaveAndEdit == null) {
        return;
      }
      if (newSaveAndEdit == "saveSuccess") {
        this.editAccout.url = "check-circle";
        this.editAccout.text = getString(strings.Header_Mind_Tips_Update_Saved);
        setTimeout(() => {
          this.editAccout.url = "";
        }, 2000);
      } else if (newSaveAndEdit == "alreadyEdit") {
        this.editAccout.url = "exclamation-circle";
        this.editAccout.text = getString(strings.Header_Mind_Tips_Edit);
      }
      this.checkAddIconColor(this.DrawingBoardBackgroundColor);
    },
    "$store.state.mindmapPlayPauseShowSetup.showSetup"(
      newIsShowMindmapPlaySetupModal
    ) {
      this.isShowMindmapPlaySetupModal = newIsShowMindmapPlaySetupModal;
    },
    "$store.state.mindmapPlayPauseShowSetup.playAccout"(newPlayAccout) {
      if (newPlayAccout == "") {
        return;
      }
      switch (newPlayAccout) {
        case "PAUSE":
          break;
        case "OVER":
          this.stopPlayMap();
          if (this.$refs["fullscreen"].getState()) {
            this.switchFullScreen();
          }
          break;
        case "PLAY":
          this.playMap();
          break;
        case "OnePlay":
          this.switchFullScreen();
          break;
        default:
          break;
      }
    },
    "$store.state.showVideoUpload": {
      handler(newval, olval) {
        if (newval == 0 || newval == "") {
          return;
        } else {
          this.getProgressBar(newval);
        }
      },
      deep: true,
    },
    "$store.state.isShowEditTask": {
      handler(newval, olval) {
        this.showEditTask = newval;
      },
      deep: true,
    },
     "$store.state.isShowFindTextView": {
      handler(newval, olval) {
        this.showFindTextView = newval;
      },
      deep: true,
    },
    "$store.state.isLaTeXModel"(newAccout) {
      this.showEditLatex = newAccout;
    },
    showElementMenuList(newVal) {
      // 节点右键菜单打开时隐藏导出菜单
      if (newVal) {
        this.mindmapExportMenu(null);
        setTimeout(() => {
          this.mindmapExportMenu(false); //隐藏导出菜单
        }, 10);
      }
    },
    isShowFreeNodeMenu(newVal) {
      // 右键菜单打开时隐藏导出菜单
      if (newVal) {
        this.mindmapExportMenu(null);
        setTimeout(() => {
          this.mindmapExportMenu(false); //隐藏导出菜单
        }, 10);
      }
    },
  },
  computed: {
    setScale: function () {
      // `this` 指向 vm 实例
      return 1 + (this.scale > 0 ? this.scale * 0.2 : this.scale * 0.1);
    },
    //$store.state.textColor
  },

  beforeDestroy() {
    clearInterval(this.saveTimeUilt);
    clearTimeout(this.coverTimeOut);
  },
};
</script>
<style lang="less" >
@keyframes path-line-animation-dash {
  to {
    stroke-dashoffset: 0;
  }
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  // transform: translateX(10px);
  opacity: 0;
}

@font-face {
  font-family: "iconfontScale";
  src: url("../../assets/font-icon/font_fip2rtszdp/iconfont.eot");
  src: url("../../assets/font-icon/font_fip2rtszdp/iconfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/font-icon/font_fip2rtszdp/iconfont.woff2") format("woff2"),
    url("../../assets/font-icon/font_fip2rtszdp/iconfont.woff") format("woff"),
    url("../../assets/font-icon/font_fip2rtszdp/iconfont.ttf")
      format("truetype"),
    url("../../assets/font-icon/font_fip2rtszdp/iconfont.svg#iconfont")
      format("svg");
}
.iconfont-scale {
  font-family: "iconfontScale" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "iconfontFullSucreen";
  src: url("../../assets/font-icon/font_lylohxll61/iconfont.eot");
  src: url("../../assets/font-icon/font_lylohxll61/iconfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/font-icon/font_lylohxll61/iconfont.woff2") format("woff2"),
    url("../../assets/font-icon/font_lylohxll61/iconfont.woff") format("woff"),
    url("../../assets/font-icon/font_lylohxll61/iconfont.ttf")
      format("truetype"),
    url("../../assets/font-icon/font_lylohxll61/iconfont.svg#iconfont")
      format("svg");
}
.iconfont-fullSucreen {
  font-family: "iconfontFullSucreen" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "iconfontAdd";
  src: url("../../assets/font-icon/font_afbn0y2vh9n/iconfont.eot");
  src: url("../../assets/font-icon/font_afbn0y2vh9n/iconfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/font-icon/font_afbn0y2vh9n/iconfont.woff2")
      format("woff2"),
    url("../../assets/font-icon/font_afbn0y2vh9n/iconfont.woff") format("woff"),
    url("../../assets/font-icon/font_afbn0y2vh9n/iconfont.ttf")
      format("truetype"),
    url("../../assets/font-icon/font_afbn0y2vh9n/iconfont.svg#iconfont")
      format("svg");
}

.iconfont-add {
  font-family: "iconfontAdd" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "hiddenChildNode";
  src: url("../../assets/font-icon/font_gjv25bj9i07/iconfont.eot");
  src: url("../../assets/font-icon/font_gjv25bj9i07/iconfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/font-icon/font_gjv25bj9i07/iconfont.woff2")
      format("woff2"),
    url("../../assets/font-icon/font_gjv25bj9i07/iconfont.woff") format("woff"),
    url("../../assets/font-icon/font_gjv25bj9i07/iconfont.ttf")
      format("truetype"),
    url("../../assets/font-icon/font_gjv25bj9i07/iconfont.svg#iconfont")
      format("svg");
}
.hidden-child-node {
  font-family: "hiddenChildNode" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: unset;
}

@font-face {
  font-family: "dragImage";
  src: url("../../assets/font-icon/font_fk3w3u91b2b/iconfont.eot");
  src: url("../../assets/font-icon/font_fk3w3u91b2b/iconfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/font-icon/font_fk3w3u91b2b/iconfont.woff2")
      format("woff2"),
    url("../../assets/font-icon/font_fk3w3u91b2b/iconfont.woff") format("woff"),
    url("../../assets/font-icon/font_fk3w3u91b2b/iconfont.ttf")
      format("truetype"),
    url("../../assets/font-icon/font_fk3w3u91b2b/iconfont.svg#iconfont")
      format("svg");
}
.drag-image-icon {
  font-family: "dragImage" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.tips-message-hide {
  display: none;
}
.tips-message-show {
  display: block;
  position: absolute;
  left: 50%;
  right: 50%;
  top: 16%;
  z-index: 9999;
  min-width: 158px;
  height: 32px;
  background: #000000;
  opacity: 0.4;
  border-radius: 4px;
  margin: 0 auto;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
.font-style {
  width: auto;
  display: inline-block;
  /* max-width: 100px; */
}
.complie-left-content {
  width: 100%;
  height: 100vh;
  /* border: 1px solid red; */
  /* 设置页面全局的字体样式，这对计算宽高很重要。 */
}
.content-account {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.content-account div {
  width: 150px;
  height: 80px;
  border: 3px solid red;
  border-radius: 3px;
  background-color: skyblue;
}
.content-account .content-account-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: white;
  font-size: 18px;
}
.content-account-small-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.complie-add-img {
  position: absolute;
  width: 28px;
  height: 28px;
  left: 0px;
}
.complie-add-img-right {
  position: absolute;
  width: 28px;
  height: 28px;
  right: 0px;
}
.complie-shape {
  transform: rotate(45deg);
}
.complie-shape-two {
  border-radius: 50%;
}
.complie-shape-three {
  border-radius: 5px;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset,
img {
  border: 0;
}

address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}

caption,
th {
  text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

q:before,
q:after {
  content: "";
}

abbr,
acronym {
  border: 0;
  font-variant: normal;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  *font-size: 100%;
}

legend {
  color: #000;
}

#yui3-css-stamp.cssreset {
  display: none;
}

.test {
  width: 86px;
  height: 134px;
}

#components-layout-demo-side .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.content {
  display: flex;
}

.left-content {
  width: 200px;
  background-color: #999;
}

/* 放大缩小菜单开始 */
.scale-menu {
  width: inherit;
  height: 32px;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 4;
  border: 0px solid var(--rightMenuBorderColor);//#eeeeee4d;
  user-select: none;

  .sheet-top-box-list {
    display: inline-block;
    margin-left: 97px;
    overflow-x: auto;
    white-space: nowrap;
    position: relative;
    .sheet-top-box-name:hover {
      background: #232323;
    }
    .class-sheet-click-select {
      color: #ededed;
      font-weight: 700;
    }
    .class-sheet-click {
      color: #ffffff;
    }
    .sheet-top-box-name {
      height: 32px;
      border-left: 1px solid #eeeeee4d;
      border-right: 1px solid #eeeeee4d;
      opacity: 1;
      display: inline-block;
      line-height: 32px;
      cursor: pointer;
      // color: #ffffff;
      width: 90px;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
      white-space: nowrap;
      padding: 0 10px;
    }
  }
  .zoom-function {
    position: relative;
    right: 10px;
    .each-scale-box {
      position: absolute;
      width: 30px;
      height: 30px;
      // background-color:red;
      color: #fff;
      // color: #9fa2a8;
      border-radius: 50%;
      cursor: pointer;
      user-select: none;
      .center-icon-box {
        position: absolute;
        left: 15px;
        top: 14.5px;
        transform: translate(-50%, -50%);
        z-index: 2;
        font-size: 18px;
      }
      .active-show-background {
        display: none;
        position: absolute;
        left: 50%;
        top: 50%;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        background-color: rgba(253, 73, 43, 0.2);
        z-index: 1;
      }
      .bottom-gray-text-content {
        display: none;
        padding: 0 6px;
        white-space: nowrap;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        bottom: 25px;
        text-align: center;
        line-height: 22px;
        font-size: 12px;
        color: #fff;
        background-color: #9fa2a8;
        border-radius: 4px;
      }
      &:hover {
        // background-color: rgb(253, 73, 43);
        // color: #fff;

        color: rgb(253, 73, 43);
        .bottom-gray-text-content {
          display: block;
        }
      }
      &:active .active-show-background {
        display: block;
      }
    }
  }
  .each-scale-box-Phone {
    margin-top: -31px;
  }
  .sheet-top-box {
    .sheet-top-box-total {
      height: 32px;
      display: inline-block;
      line-height: 32px;
      margin-left: 37px;
      cursor: pointer;
      width: 32px;
      text-align: center;
      position: absolute;
      color: #fff;
    }
  }
  .add-scale-box {
    right: 20px;
    // bottom: 21px;
  }
  .lost-scale-box {
    right: 85px;
    // bottom: 21px;
  }
  .full-screen-box {
    right: 170px;
    // bottom: 21px;
  }
  .center-canvas-box {
    right: 125px;
    // bottom: 21px;
  }

  .scale-number-show-box {
    position: absolute;
    right: 53px;
    // bottom: 25px;
    top: 5px;
    width: 30px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-size: 12px;
    color: #fff;
    user-select: none;
    // border: #9fa2a8;
  }
}

.checkMenuColor {
  width: inherit;
  height: 32px;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 4;
  border: 1px solid #eeeeee;
  user-select: none;
  .sheet-top-box {
    .sheet-top-box-list {
      display: inline-block;
      margin-left: 97px;
      overflow-x: auto;
      white-space: nowrap;
      position: relative;
      .sheet-top-box-name:hover {
        background: #f8f8f8;
      }
      .class-sheet-click-select {
        color: #222222;
        font-weight: 700;
      }
      .class-sheet-click {
        color: #666666;
      }
      .sheet-top-box-name {
        height: 32px;
        border: 1px solid #eeeeee;
        border-top: 0px;
        opacity: 1;
        display: inline-block;
        line-height: 32px;
        cursor: pointer;
        // color: #666666;
        width: 90px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        white-space: nowrap;
        padding: 0 10px;
      }
    }
    .sheet-top-box-list::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
  }
  .zoom-function {
    position: relative;
    right: 10px;
    .each-scale-box {
      position: absolute;
      width: 30px;
      height: 30px;
      // background-color:red;
      color: #9fa2a8;
      border-radius: 50%;
      cursor: pointer;
      user-select: none;
      .center-icon-box {
        position: absolute;
        left: 15px;
        top: 14.5px;
        transform: translate(-50%, -50%);
        z-index: 2;
        font-size: 18px;
      }
      .active-show-background {
        display: none;
        position: absolute;
        left: 50%;
        top: 50%;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        // background-color: rgba(0, 0, 0, 0.2);
        background-color: rgba(253, 73, 43, 0.2);
        z-index: 1;
      }
      .bottom-gray-text-content {
        display: none;
        // width: 80px;
        height: 22px;
        padding: 0 6px;
        white-space: nowrap;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        bottom: 25px;
        text-align: center;
        line-height: 22px;
        font-size: 12px;
        color: #fff;
        background-color: #9fa2a8;
        border-radius: 4px;
      }
      &:hover {
        // background-color: rgb(253, 73, 43);
        // color: #fff;

        // color: rgb(33, 33, 33);
        color: rgb(253, 73, 43);
        .bottom-gray-text-content {
          display: block;
        }
      }
      &:active .active-show-background {
        display: block;
      }
    }
  }

  .each-scale-box-Phone {
    margin-top: -31px;
  }
  .sheet-top-box {
    .sheet-top-box-total {
      height: 32px;
      display: inline-block;
      line-height: 32px;
      margin-left: 37px;
      width: 32px;
      cursor: pointer;
      text-align: center;
      position: absolute;
      color: #666666;
    }
  }
  .add-scale-box {
    right: 20px;
    // bottom: 21px;
  }
  .lost-scale-box {
    right: 85px;
    // bottom: 21px;
  }
  .full-screen-box {
    right: 170px;
    // bottom: 21px;
  }
  .center-canvas-box {
    right: 125px;
    // bottom: 21px;
  }

  .scale-number-show-box {
    position: absolute;
    right: 53px;
    // bottom: 25px;
    top: 5px;
    width: 30px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-size: 12px;
    border: #9fa2a8;
    user-select: none;
  }
}
/* 放大缩小菜单结束 */

/* 画布部分开始 */
.canvas-father {
  background-color: #fff;
  left: 0;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    border: 2px solid var(--styleScrollbarTackColor);
  }

  &::-webkit-scrollbar-track {
    box-shadow: 0px 0px 0px var(--styleScrollbarTackColor) inset;
    background: var(--styleScrollbarTackColor);
    border-radius: 0px;
  }
  //滑块
  &::-webkit-scrollbar-thumb {
    min-height: 10px;
    background-clip: content-box;
    border-radius: 6px;
    background: var(--styleScrollbarThumbColor);
    //box-shadow: 0 0 0 1px #132465 inset;
  }
  
  &::-webkit-scrollbar-corner {
    background: var(--styleScrollbarThumbColor);
  }
  .edit-accout {
    min-width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 6px;
    z-index: 201;
    min-height: 36px;
    // background: rgba(0, 0, 0, 0.27);
    border-radius: 6px;
    color: #ffffff;
    user-select: none;
    .save-button {
      padding: 4px;
      border-radius: 10px;
      cursor: pointer;
      margin-left: 10px;
      button {
        height: 28px;
        border-radius: 10px;
        padding: 2px 6px;
        background: transparent;
        color: #ffffff;
        border: none;
      }
    }
  }
  //导图播放
  #mindmap-play-box {
    position: absolute;
    bottom: 77px;
    z-index: 100;
    right: 50%;
    transform: translate(50%, 0%);
    cursor: pointer;
  }
}

.svg-father-box {
  position: absolute;
}
/* 编辑框开始 */
.textraea-box {
  position: absolute;
  outline: none;
  left: 20px;
  top: 100px;
  z-index: 9999;
}
.textraea-box textarea {
  border: transparent;
  resize: none;
  overflow: hidden;
  background: lime;
}
.textraea-box textarea:focus {
  border: none;
  outline: none;
}

/* 编辑框结束 */

.svg-box {
  position: absolute;
}
// .svg-content:hover {
// }
.svg-content {
  position: relative;
  color: #fff;
}
// .svg-content-child {
// }
.addicon {
  width: 20px;
  height: 20px;
  /* position: absolute; */
  /* left: 200px; */
  color: #fff;
  background-color: orange;
  z-index: 100;
}

// g标签中的添加子节点按钮开始;
g:hover .addChildMindIcon {
  visibility: hidden;
  // display: none;
}
g:hover .addChildMindIcon {
  // display: block;
  visibility: visible;
}
// g标签中的添加子节点按钮结束;

/* 画布部分结束 */
.left-contetn-box {
  position: absolute;
  z-index: 9999;
  right: 50%;
  top:50%;
  color: #ffffff;
  font-size: 16px;
  text-align: center;
  transform: translate(50%, 50%);
  background-color: #898989;
  padding: 10px;
  min-width: 20%;
  border-radius: 10px;
}
.noMacComputed {
  user-select: none;
}
.file-color-class {
  border-radius: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 300px;
  margin: 0 auto;
  margin-bottom: 20px;
}
.menu-left-menu-color {
  z-index: 2;
  position: absolute;
  .menu-left-menu {
    z-index: 2;
    border-right: 1px solid #eeeeee;
    background-color: #ffffff;
    position: fixed;
    .left-search {
      width: 187px;
      height: 30px;
      margin-left: 16px;
      position: relative;
      float: left;
      margin-top: 10px;
      .clear-search-text-icon {
        display: block;
        position: absolute;
        top: 0;
        font-size: 18px;
        left: 228px;
        height: 30px;
        line-height: 32px;
        color: #bbb;
        cursor: pointer;
        &:hover {
          color: #ff7354;
        }
        &:active {
          color: #d6301a;
        }
      }

      .search-button {
        position: absolute;
        margin-left: 10px;
        margin-top: 3px;
        border: 0px;
        background: none;
      }

      .ant-btn:hover,
      .ant-btn:focus {
        background: #fd492b;
        color: #fff;
        opacity: 1;
        border: none;
      }
      .search-box {
        width: 100%;
        border-radius: 15px;
        border: transparent;
        background: #f0f2f8;
        margin-right: 10px;
        height: 30px;
        padding-left: 35px;
        padding-right: 30px;
      }
      .search-box:focus {
        outline: none;
      }
    }
    .left-sheet-box-overflow-father {
      position: absolute;
      overflow: hidden;
      margin-top: 50px;
      .left-sheet-box-overflow {
        overflow-y: auto;
        .left-sheet-box-click-select {
          background: #cbcbcb;
        }
        // .left-sheet-box-click{
        //   background: #ffffff;
        // }
        .left-sheet-box {
          position: relative;
          float: left;
          cursor: pointer;
          width: 215px;
          height: 163px;
          // background: #ffffff;
          .left-sheet-box-index {
            position: absolute;
            margin-top: 14px;
            margin-left: 16px;
            font-size: 16px;
          }
          .left-sheet-box-cover-text {
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
            width: 167px;
            height: 131px;
            background: #ffffff;
            border-radius: 4px;
            margin-top: 16px;
            margin-left: 34px;
            transition: 0.3s;
            .left-sheet-box-text {
              width: 167px;
              height: 37px;
              line-height: 37px;
              font-size: 14px;
              text-align: center;
              overflow: hidden;
              text-overflow: ellipsis;
              word-break: break-all;
              white-space: nowrap;
              background-color: #ffffff;
              border-radius: 0px 0px 4px 4px;
              padding: 0 20px;
            }
          }
          .left-sheet-box-cover-text:hover {
            transform: scale(1.1);
            // box-shadow: 0px 10px 6px rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }
}
.menu-left-menu-scale {
  z-index: 2;
  position: absolute;
  .menu-left-menu {
    z-index: 2;
    // border-right: 1px solid #EEEEEE;
    background-color: #191919;
    position: absolute;
    .left-search {
      width: 187px;
      height: 30px;
      margin-left: 16px;
      position: relative;
      float: left;
      margin-top: 10px;
      .clear-search-text-icon {
        display: block;
        position: absolute;
        top: 0;
        font-size: 18px;
        left: 228px;
        height: 30px;
        line-height: 32px;
        color: #bbb;
        cursor: pointer;
        &:hover {
          color: #ff7354;
        }
        &:active {
          color: #d6301a;
        }
      }

      .search-button {
        position: absolute;
        margin-left: 10px;
        margin-top: 3px;
        border: 0px;
        background: none;
      }

      .ant-btn:hover,
      .ant-btn:focus {
        background: #fd492b;
        color: #fff;
        opacity: 1;
        border: none;
      }
      .search-box {
        width: 100%;
        border-radius: 15px;
        border: transparent;
        background: #f0f2f8;
        margin-right: 10px;
        height: 30px;
        padding-left: 35px;
        padding-right: 30px;
      }
      .search-box:focus {
        outline: none;
      }
    }
    .left-sheet-box-overflow-father {
      position: absolute;
      overflow: hidden;
      margin-top: 50px;
      .left-sheet-box-overflow {
        overflow-y: auto;
        .left-sheet-box-click-select {
          background: #cbcbcb;
        }
        // .left-sheet-box-click{
        //   background: #191919;
        // }
        .left-sheet-box {
          position: relative;
          float: left;
          cursor: pointer;
          width: 215px;
          height: 163px;
          // background: #191919;
          .left-sheet-box-index {
            position: absolute;
            margin-top: 14px;
            margin-left: 16px;
            font-size: 16px;
            color: #ffffff;
          }
          .left-sheet-box-cover-text {
            box-shadow: 0px 3px 6px rgba(255, 255, 255, 0.1);
            width: 167px;
            height: 131px;
            background: #191919;
            border-radius: 4px;
            margin-top: 16px;
            margin-left: 34px;
            transition: 0.3s;
            .left-sheet-box-text {
              width: 167px;
              height: 37px;
              line-height: 37px;
              font-size: 14px;
              text-align: center;
              overflow: hidden;
              text-overflow: ellipsis;
              word-break: break-all;
              white-space: nowrap;
              background-color: #ffffff;
              border-radius: 0px 0px 4px 4px;
              padding: 0 20px;
              // color: #ffffff;
            }
          }
          .left-sheet-box-cover-text:hover {
            // box-shadow: 0px 10px 6px rgba(255, 255, 255, 0.1);
            transform: scale(1.1);
          }
        }
      }
    }
  }
}
.menu-left-menu {
  .menu-use-content-inside {
    width: 216px;
  }
  .menu-use-content-inside {
    border: 0px;
    box-shadow: 0px 0px 0px rgba(255, 255, 255, 0);
    background: rgba(255, 255, 255, 0);
  }
}

.menu-left-menu-after {
  margin-left: 0;
  transition: 0.5s;
}
.menu-left-menu-befor {
  margin-left: -300px;
  transition: 0.5s;
}

.sheet-top-box-name:hover {
  background: #f8f8f8;
}
.left-sheet-box-click-select {
  background: #cbcbcb;
}
.left-sheet-box-cover {
  border-radius: 4px 4px 0px 0px;
}

.left-sheet-box-spinning {
  position: absolute;
  width: 100%;
  height: 100%;
}
.loading-child {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(2);
}
.ant-spin-dot-item {
  background-color: #fd492b;
}
.ant-spin {
  color: #fd492b;
}
.ant-spin .ant-spin-dot-item {
  background-color: #fd492b !important;
}

.sheet-top-box-look {
  height: 32px;
  display: inline-block;
  line-height: 28px;
  margin-left: 5px;
  cursor: pointer;
  width: 28px;
  text-align: center;
  position: absolute;
}

.sheet-top-box-leftslip {
  height: 32px;
  display: inline-block;
  line-height: 28px;
  margin-left: 69px;
  cursor: pointer;
  width: 28px;
  text-align: center;
  position: absolute;
}

.sheet-top-box-right-spli {
  height: 32px;
  display: inline-block;
  line-height: 28px;
  cursor: pointer;
  width: 25px;
  text-align: center;
  position: absolute;
  margin-left: 2px;
}
.sheet-top-box-created {
  height: 32px;
  display: inline-block;
  line-height: 28px;
  margin-left: 27px;
  cursor: pointer;
  width: 25px;
  text-align: center;
  position: absolute;
}
:root {
  --windowW: 24px;
}
.my-svg-path {
  stroke-dasharray: var(--windowW), 62px;
  stroke-dashoffset: 22px;
  transition: stroke-dashoffset 0.6s ease 0s, stroke 0.6s ease 0s;
  transform: rotateZ(90deg);
  transform-origin: 50% 50%;
}
@font-face {
  font-family: "wrappingPath";
  src: url("../../assets/font-icon/font_to4y6wnchti/iconfont.eot");
  src: url("../../assets/font-icon/font_to4y6wnchti/iconfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/font-icon/font_to4y6wnchti/iconfont.woff2")
      format("woff2"),
    url("../../assets/font-icon/font_to4y6wnchti/iconfont.woff") format("woff"),
    url("../../assets/font-icon/font_to4y6wnchti/iconfont.ttf")
      format("truetype"),
    url("../../assets/font-icon/font_to4y6wnchti/iconfont.svg#iconfont")
      format("svg");
}
.wrapping-path-div {
  font-family: "wrappingPath" !important;
  font-size: 12px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.title-float-popup {
  height: 172px;
  width: 232px;
  position: fixed;
  z-index: 9999;
  bottom: 42px;
  .title-float-colse-img {
    position: relative;
    float: right;
    z-index: 5;
    margin-top: 5px;
    margin-right: 10px;
    cursor: pointer;
    .close-img {
      width: 15px;
      opacity: 0.6;
    }
    .close-img:hover {
      opacity: 1;
    }
  }
  .title-float-upgrade-img {
    position: absolute;
    z-index: 4;
    cursor: pointer;
  }
  .title-float-upgrade-name {
    position: absolute;
    z-index: 4;
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }
}

.title-float-popup-after {
  left: 230px;
  transition: 0.5s;
}
.title-float-popup-befor {
  left: 10px;
  transition: 0.5s;
}
.CtxtMenu_MenuFrame {
  display: none;
}

.menu-right-bg {
  position: absolute;
  background-color: #ffffff;
  z-index: 9999;
  top: 60px;
}
</style>
