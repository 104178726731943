// package mind.yushu.com.mindmap.viewmodel.mindelementdata.mindcontent;

import { AlignmentType } from "docx";

// import java.util.ArrayList;
// import java.util.List;

// import mind.yushu.com.mindmap.viewmodel.core.base.basedata.CGPoint;

/**
 * ProjectName: MindMap
 * Created by tony on 1/12/21
 * Copyright(c) 2020 mindyushu.com
 */

class WordTextCell {
    constructor(text = "", size = 24, bold = false, 
        alignment = AlignmentType.LEFT, color = "#333333") {
        this.text = text;
        this.size = size;
        this.bold = bold;
        this.alignment = alignment;
        this.color = color;
    }    
}
export default WordTextCell