import { render, staticRenderFns } from "./ComplieChildMenuTheme.vue?vue&type=template&id=88db0066&scoped=true"
import script from "./ComplieChildMenuTheme.vue?vue&type=script&lang=js"
export * from "./ComplieChildMenuTheme.vue?vue&type=script&lang=js"
import style0 from "./ComplieChildMenuTheme.vue?vue&type=style&index=0&id=88db0066&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88db0066",
  null
  
)

export default component.exports