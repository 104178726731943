<template>
<!-- 修改形状 -->
  <div id="modify-shape-box" class="menu-content-border-box" :class="isExplain&&reigthMenu!=true?'explain-border-box':''">
    <div class="menu-use-content-inside" 
        :class="newNodeChangeShape?'menu-use-content-inside-for-changenode':''" ref="modifyShapeBox">
      <ul class="menu-use-box" v-if="isExplain">
        <li
          v-for="(item, index) in explainLayoutStyleList"
          :key="index"
          :class="getSelectedClassName(item)"
          @click="getNodeTypeAccout(item.nodeType,mapEditDarkMode?item.layoutIconWhite:item.layoutIcon)"
        >
          <img v-if="mapEditDarkMode && selectedShapeType == item.nodeType" :src="item.layoutIcon" />
          <img v-else-if="!mapEditDarkMode && selectedShapeType == item.nodeType" :src="item.layoutIconWhite" />
          <img v-else-if="mapEditDarkMode && selectedShapeType != item.nodeType" :src="item.layoutIconWhite" />
          <img v-else-if="!mapEditDarkMode && selectedShapeType != item.nodeType" :src="item.layoutIcon" />
        </li>
      </ul>
      <ul class="menu-use-box" v-else>
        <li
          v-for="(item, index) in layoutStyleList"
          :key="index"
          :class="getSelectedClassName(item)"
          tip="123"
          @click="getNodeTypeAccout(item.nodeType,mapEditDarkMode?item.layoutIconWhite:item.layoutIcon)"
        >
          <!-- <img v-if="mapEditDarkMode" :src="item.layoutIconWhite" />
          <img v-else :src="item.layoutIcon" /> -->
          <img v-if="mapEditDarkMode && selectedShapeType == item.nodeType" :src="item.layoutIcon" />
          <img v-else-if="!mapEditDarkMode && selectedShapeType == item.nodeType" :src="item.layoutIconWhite" />
          <img v-else-if="mapEditDarkMode && selectedShapeType != item.nodeType" :src="item.layoutIconWhite" />
          <img v-else-if="!mapEditDarkMode && selectedShapeType != item.nodeType" :src="item.layoutIcon" />
          <span class="tooltiptext" v-if="item.tips != null">{{item.tips}}</span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapMutations,mapState } from "vuex";
import MindElementShapeType from "../../../viewmodel/datatype/MindElementShapeType";
import MindElementType from '../../../viewmodel/datatype/MindElementType';
import EditMindmapVM from '../../../viewmodel/mindelementdata/vm/EditMindmapVM';
export default {
  name: "ComplieChildMenuLayout",
  props: [
    "isShowExplain",
    "reigthMenu",
    "shapeBorderType",
    "flowChartLeftMenu",
    "newNodeChangeShape",
  ],
  data() {
    return {
      layoutStyleList: [
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_rectangle.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderRectangleWhite.svg"),
          nodeType: MindElementShapeType.Corner,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_cornerShadow.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderCornerShadowWhite.svg"),
          nodeType: MindElementShapeType.CornerShadow,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_radio.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderRadioWhite.svg"),
          nodeType: MindElementShapeType.Semicircle,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_long_radio.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderLongRadioWhite.svg"),
          nodeType: MindElementShapeType.Left_Right_Corner_Top_Bottom_Semicircle,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_top_bottom_corner.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderTopBottomCornerWhite.svg"),
          nodeType: MindElementShapeType.Left_Right_Corner_Top_Bottom_Corner,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_line.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderLineWhite.svg"),
          nodeType: MindElementShapeType.Underline,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_two_line.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/border_two_lineWhite.svg"),
          nodeType: MindElementShapeType.Two_Underline,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_garden.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderGardenWhite.svg"),
          nodeType: MindElementShapeType.Circular,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/book.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/bookWhite.svg"),
          nodeType: MindElementShapeType.Book,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_Ring.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderRingWhite.svg"),
          nodeType: MindElementShapeType.Ring,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_Ring2.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderRing2White.svg"),
          nodeType: MindElementShapeType.Ring2_UP,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_Ring2_UP.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderRing2_UPWhite.svg"),
          nodeType: MindElementShapeType.Ring2,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_Ring3.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderRing3White.svg"),
          nodeType: MindElementShapeType.Ring3,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_card.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderCardWhite.svg"),
          nodeType: MindElementShapeType.Card,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_edge_shape.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderEdgeShapeWhite.svg"),
          nodeType: MindElementShapeType.Diamond,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_RightArrow2.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderRightArrow2White.svg"),
          nodeType: MindElementShapeType.RightArrow2,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_LeftArrow2.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderLeftArrow2White.svg"),
          nodeType: MindElementShapeType.LeftArrow2,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_Circular_Right_Top.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderCircularRightTopWhite.svg"),
          nodeType: MindElementShapeType.Circular_Right_Top,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_Three_Circular_Cloud.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderThreeCircularCloudWhite.svg"),
          nodeType: MindElementShapeType.Three_Circular_Cloud,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_Ellipse.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderEllipseWhite.svg"),
          nodeType: MindElementShapeType.Ellipse,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_Ellipse_3D.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/border_Ellipse_3D_White.svg"),
          nodeType: MindElementShapeType.Ellipse_3D,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_Cube.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderCubeWhite.svg"),
          nodeType: MindElementShapeType.Cube,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_Form.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderFormWhite.svg"),
          nodeType: MindElementShapeType.Form,
        },

        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_Corner_Right.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderCornerRightWhite.svg"),
          nodeType: MindElementShapeType.Corner_Right,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_polygon.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderPolygonWhite.svg"),
          nodeType: MindElementShapeType.Hexagon,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_et_polygon.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderEtPolygonWhite.svg"),
          nodeType: MindElementShapeType.Left_Right_Three_Side,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_right_long.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderRightLongWhite.svg"),
          nodeType: MindElementShapeType.Right_Straight_Left_Arrow,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_left_long.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderLeftLongWhite.svg"),
          nodeType: MindElementShapeType.Left_Straight_Right_Arrow,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_right_arrow.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderRightArrowWhite.svg"),
          nodeType: MindElementShapeType.RightArrow,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_left_arrow.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderLeftArrowWhite.svg"),
          nodeType: MindElementShapeType.LeftArrow,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_bubble.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderBubbleWhite.svg"),
          nodeType: MindElementShapeType.Cloud,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_triangle.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderTriangleWhite.svg"),
          nodeType: MindElementShapeType.Triangle,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_invertedTriangle.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderInvertedTriangleWhite.svg"),
          nodeType: MindElementShapeType.InvertedTriangle,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_rightTriangle.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderRightTriangleWhite.svg"),
          nodeType: MindElementShapeType.RightTriangle,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_leftTriangle.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderLeftTriangleWhite.svg"),
          nodeType: MindElementShapeType.LeftTriangle,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_circularAndRight.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderCircularAndRightWhite.svg"),
          nodeType: MindElementShapeType.CircularAndRight,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_oblique_corner.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderObliqueCornerWhite.svg"),
          nodeType: MindElementShapeType.Oblique_corner,//斜角60矩形
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_large_braces.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderLargeBracesWhite.svg"),
          nodeType: MindElementShapeType.Large_braces,//大括号
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_middle_brackets.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderMiddleBracketsWhite.svg"),
          nodeType: MindElementShapeType.Middle_brackets,//中括号
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_brackets.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderBracketsWhite.svg"),
          nodeType: MindElementShapeType.Brackets,//小括号
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_angle_braces.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderAngleBracesWhite.svg"),
          nodeType: MindElementShapeType.Angle_braces,//尖括号
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_heart.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderHeartWhite.svg"),
          nodeType: MindElementShapeType.Heart,//心
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_gemstone.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderGemstoneWhite.svg"),
          nodeType: MindElementShapeType.Gemstone,//宝石
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_star.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderStarWhite.svg"),
          nodeType: MindElementShapeType.Star,//五角星
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_octagonal.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderOctagonalWhite.svg"),
          nodeType: MindElementShapeType.Regular_hexagon,//正六边形
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_regular_hexagon.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderRegularHexagonWhite.svg"),
          nodeType: MindElementShapeType.Octagonal,//八边形
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_shield.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderShieldWhite.svg"),
          nodeType: MindElementShapeType.Shield,//盾牌
        },

      ],
      explainLayoutStyleList:[
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/explain_rectangle.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/explain_rectangle_white.svg"),
          nodeType: MindElementShapeType.Corner,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/explain_radio.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/explain_radio_white.svg"),
          nodeType: MindElementShapeType.Semicircle,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/explain_line.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/explain_line_white.svg"),
          nodeType: MindElementShapeType.Underline,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/explain_ellipse.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/explain_ellipse_white.svg"),
          nodeType: MindElementShapeType.Ellipse,
        },
      ],
      layoutType: "",
      viewModel: null,
      isExplain:false,
      selectedShapeType:-1,
    };
  },
  computed: {
    ...mapState({
      mapEditDarkMode: (state) => !state.mapEditIsDardarkMode.darkMode,//深色模式
    }),
  },
  created() {
    let node = EditMindmapVM.getSelectedNode();
    if(node.value.type == MindElementType.EXPLAIN){
      this.isExplain = true;
    }
    if (this.newNodeChangeShape) {
      this.layoutStyleList = [
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_rectangle.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderRectangleWhite.svg"),
          nodeType: MindElementShapeType.Corner,
          tips: "流程",
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_top_bottom_corner.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderTopBottomCornerWhite.svg"),
          nodeType: MindElementShapeType.Left_Right_Corner_Top_Bottom_Corner,
          tips: "开始",
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_long_radio.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderLongRadioWhite.svg"),
          nodeType: MindElementShapeType.Left_Right_Corner_Top_Bottom_Semicircle,
          tips: "结束",
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_edge_shape.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderEdgeShapeWhite.svg"),
          nodeType: MindElementShapeType.Diamond,
          tips: "判定",
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_garden.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderGardenWhite.svg"),
          nodeType: MindElementShapeType.Circular,
          tips: "圆形:结束",
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_Ellipse.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderEllipseWhite.svg"),
          nodeType: MindElementShapeType.Ellipse,
          tips: "椭圆:开始",
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_Ellipse_3D.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/border_Ellipse_3D_White.svg"),
          nodeType: MindElementShapeType.Ellipse_3D,
          tips: "数据库",
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_Cube.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderCubeWhite.svg"),
          nodeType: MindElementShapeType.Cube,
          tips: "科学",
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_Form.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderFormWhite.svg"),
          nodeType: MindElementShapeType.Form,
          tips: "文档",
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_Corner_Right.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderCornerRightWhite.svg"),
          nodeType: MindElementShapeType.Corner_Right,
          tips: "卡片",
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_radio.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderRadioWhite.svg"),
          nodeType: MindElementShapeType.Semicircle,
          tips: "开始/结束",
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/book.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/bookWhite.svg"),
          nodeType: MindElementShapeType.Book,
          tips: "书本",
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_Ring.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderRingWhite.svg"),
          nodeType: MindElementShapeType.Ring,
          tips: "圆环",
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_Ring2.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderRing2White.svg"),
          nodeType: MindElementShapeType.Ring2_UP,
          tips: "圆向上",
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_Ring2_UP.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderRing2_UPWhite.svg"),
          nodeType: MindElementShapeType.Ring2,
          tips: "圆向下",
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_Ring3.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderRing3White.svg"),
          nodeType: MindElementShapeType.Ring3,
          tips: "按钮",
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_card.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderCardWhite.svg"),
          nodeType: MindElementShapeType.Card,
          tips: "标题卡片",
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_RightArrow2.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderRightArrow2White.svg"),
          nodeType: MindElementShapeType.RightArrow2,
          tips: "指向右",
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_LeftArrow2.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderLeftArrow2White.svg"),
          nodeType: MindElementShapeType.LeftArrow2,
          tips: "指向左",
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_Circular_Right_Top.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderCircularRightTopWhite.svg"),
          nodeType: MindElementShapeType.Circular_Right_Top,
          tips: "水滴",
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_Three_Circular_Cloud.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderThreeCircularCloudWhite.svg"),
          nodeType: MindElementShapeType.Three_Circular_Cloud,
          tips: "云",
        },        
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_polygon.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderPolygonWhite.svg"),
          nodeType: MindElementShapeType.Hexagon,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_et_polygon.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderEtPolygonWhite.svg"),
          nodeType: MindElementShapeType.Left_Right_Three_Side,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_right_long.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderRightLongWhite.svg"),
          nodeType: MindElementShapeType.Right_Straight_Left_Arrow,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_left_long.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderLeftLongWhite.svg"),
          nodeType: MindElementShapeType.Left_Straight_Right_Arrow,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_right_arrow.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderRightArrowWhite.svg"),
          nodeType: MindElementShapeType.RightArrow,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_left_arrow.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderLeftArrowWhite.svg"),
          nodeType: MindElementShapeType.LeftArrow,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_bubble.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderBubbleWhite.svg"),
          nodeType: MindElementShapeType.Cloud,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_triangle.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderTriangleWhite.svg"),
          nodeType: MindElementShapeType.Triangle,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_invertedTriangle.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderInvertedTriangleWhite.svg"),
          nodeType: MindElementShapeType.InvertedTriangle,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_rightTriangle.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderRightTriangleWhite.svg"),
          nodeType: MindElementShapeType.RightTriangle,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_leftTriangle.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderLeftTriangleWhite.svg"),
          nodeType: MindElementShapeType.LeftTriangle,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_circularAndRight.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderCircularAndRightWhite.svg"),
          nodeType: MindElementShapeType.CircularAndRight,
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_oblique_corner.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderObliqueCornerWhite.svg"),
          nodeType: MindElementShapeType.Oblique_corner,//斜角60矩形
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_large_braces.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderLargeBracesWhite.svg"),
          nodeType: MindElementShapeType.Large_braces,//大括号
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_middle_brackets.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderMiddleBracketsWhite.svg"),
          nodeType: MindElementShapeType.Middle_brackets,//中括号
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_brackets.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderBracketsWhite.svg"),
          nodeType: MindElementShapeType.Brackets,//小括号
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_angle_braces.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderAngleBracesWhite.svg"),
          nodeType: MindElementShapeType.Angle_braces,//尖括号
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_heart.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderHeartWhite.svg"),
          nodeType: MindElementShapeType.Heart,//心
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_gemstone.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderGemstoneWhite.svg"),
          nodeType: MindElementShapeType.Gemstone,//宝石
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_star.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderStarWhite.svg"),
          nodeType: MindElementShapeType.Star,//五角星
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_octagonal.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderOctagonalWhite.svg"),
          nodeType: MindElementShapeType.Regular_hexagon,//正六边形
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_regular_hexagon.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderRegularHexagonWhite.svg"),
          nodeType: MindElementShapeType.Octagonal,//八边形
        },
        {
          layoutIcon: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/lightModeIcon/border_shield.svg"),
          layoutIconWhite: require("../../../assets/img/mapIcon/res/mipmap-right-menu/element-border/darkModeIcon/borderShieldWhite.svg"),
          nodeType: MindElementShapeType.Shield,//盾牌
        },

      ]
    }
  },
  mounted() {
    if (this.viewModel == null) {
      this.viewModel = this.$store.state.editMindmap;
    }
    this.setDardarkMode();
  },
  methods: {
    ...mapMutations(["setNodeType","borderStyleLayout"]),
    getNodeTypeAccout(type,url) {
      this.setNodeType(type)
      this.selectedShapeType = type;
      //将点击的值传送到页面。
      this.bus.$emit("isShowMindMenu", false);
      this.borderStyleLayout(url)
    },
    getSelectedClassName(item){
      if(this.selectedShapeType == item.nodeType){
        if(this.mapEditDarkMode){
          return 'shape-select-type-li-true'
        }else{
          return 'shape-select-type-li-false'
        }
      }
      return ''
    },
    setDardarkMode() {
      // 设置深色模式
      this.$nextTick(() => {
        let boxDom = this.$refs.modifyShapeBox;
        let dardarkMode = this.$store.state.mapEditIsDardarkMode;
        if (boxDom) {
          if (this.flowChartLeftMenu) {
            boxDom.style.background = "rgba(255, 255, 255, 0)";
          } else if (!dardarkMode.darkMode) {
            boxDom.style.color = "#fff" //dardarkMode.fontColor;
            boxDom.style.background = "#3E3E3E" //dardarkMode.bgColor;
          } else {
            // boxDom.style.color = "#666666";
            boxDom.style.background = "#fff";
          }
        }
      });
    },
  },
  watch:{
    isShowExplain(newVal, old) {
      //console.log('改变了菜单状态')
      this.isExplain = newVal;
    },
    shapeBorderType:{
      handler(newVal, oldVal) {
        if(newVal == null || newVal == "" || newVal == undefined){
          return;
        }
        this.selectedShapeType = newVal.nodeType
      },
      deep: true,
      immediate: true
    },
    "$store.state.mapEditIsDardarkMode": {
      //导图编辑页面是否为深色模式
      handler(newVal, oldVal) {
        this.setDardarkMode();
      },
      deep: true,
    },
  }
};
</script>
<style scoped>
.menu-content-border-box {
  border-radius: 6px;
}
.explain-border-box{
  margin-top: 70px;
}
.menu-use-content-inside {
  width: 268px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 2px 10px 6px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  border: 1px solid var(--rightClickMenuBorderColor);
  box-shadow: 0px 0px 0px 1px var(--rightClickMenuInsetBorderColor) inset;
  /* background-color: #ffffff; */
  position: relative;
  z-index: 9999;
  cursor: auto;
}
.menu-use-content-inside-for-changenode {
  width: 198px;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 2px 10px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--rightClickMenuBorderColor);  
  /* background-color: #ffffff; */
  border-radius: 6px;
  position: relative;
  z-index: 9999;
  cursor: auto;
}
.menu-use-content-inside ul {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  flex-wrap: wrap;
}
.menu-use-content-inside ul li {
  width: 45px;
  height: 36px;
  list-style: none;
  margin: 3px 8px;
  padding: 3px 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
}

.menu-use-content-inside-for-changenode ul li {
  width: 30px;
  height: 28px;
  list-style: none;
  margin: 0px 0px;
  padding: 3px 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.menu-use-content-inside ul li:hover {
  background: var(--rightClickMenuBgHover);
}
.shape-select-type-li-true{
  background: #e0e0e0;
}
.shape-select-type-li-false{
  background: #ff1818;
}
.menu-use-content-inside ul li img {
  width: auto;
  height: auto;
}
.menu-theme-border {
  background: #e0e0e0;
}
.tooltiptext {
  visibility: hidden;
  /* width: 90px; */
  max-width: 150px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 15px;
  margin-top: 80px;
  /* 定位 */
  position: absolute;
  z-index: 1;  
}
.menu-use-box li:hover .tooltiptext {
    visibility: visible;
}
</style>