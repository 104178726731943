<template>
  <div id="map-insert-content" class="ComplieHeaderImg-content">
    <div class="ComplieHeaderImg" ref="mapInsert">
      <div class="complie-header-imgbox">
        <div class="complie-com-box" @click="setImgDataOrMaterial()">
          <!-- 本地图片上传 -->
          <input
            type="file"
            accept="image/*"
            @change="handleFile"
            class="hiddenInput"
          />
          <!-- 素材库上传 -->
          <input
            type="file"
            accept="image/*"
            @change="handleMaterialFile"
            class="hiddenInputMaterial"
          />
          <div class="complie-left-icon">
            <img
              v-if="!darkMode"
              src="../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/addImgWhite.svg"
              alt="插入图片"
            />
            <img
              v-else
              src="../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/add_img_af.svg"
              alt="插入图片"
            />
          </div>
          <div class="complie-right-text">
            {{ getString(strings.Mind_Edit_Insert_Picture) }}
          </div>
          <img
            v-if="
              globalConfigList &&
              !globalConfigList.uploadImageFree &&
              !isMember()
            "
            src="../../../assets/img/user/icon/vip.svg"
            class="need-member"
            alt="vip"
          />
        </div>
        <div class="complie-com-box" @click="setVideo()">
          <!-- 本地视频上传 -->
          <input
            type="file"
            accept="video/mp4"
            @change="handleVideoFile"
            class="hiddenInputVideo"
          />
          <div class="complie-left-icon">
            <img
              v-if="!darkMode"
              src="../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/addVideoWhite.svg"
              alt="添加视频"
            />
            <img
              v-else
              src="../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/add_video_af.svg"
              alt=""
            />
          </div>
          <div class="complie-right-text">
            {{ getString(strings.Mind_Edit_Insert_Video) }}
          </div>
        </div>
        <div class="complie-com-box" @click="setResource()">
          <!-- 附件上传 @click="setVideo()"-->
          <input
            type="file"
            accept="*"
            @change="handleResourceFile"
            class="hiddenInputResource"
          />
          <div class="complie-left-icon">
            <img
              v-if="!darkMode"
              src="../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/addEnclosureWhite.svg"
              alt="附件"
            />
            <img
              v-else
              src="../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/add_enclosure.svg"
              alt="附件"
            />
          </div>
          <div class="complie-right-text">
            {{ getString(strings.Mind_add_Resource) }}
          </div>
        </div>
        <div
          class="complie-com-box"
          v-for="(item, index) in imgDataList"
          :key="index"
          @click="setImgDataList(index, item.imgDataAccout)"
        >
          <div class="complie-left-icon">
            <img v-if="!darkMode" :src="item.imgWhite" alt="" />
            <img v-else :src="item.imgDataAf" alt="" />
          </div>
          <div class="complie-right-text">
            {{ getString(item.imgDataText) }}
          </div>
          <!-- <img
            v-if="item.needMember && !isMember()"
            src="../../../assets/img/user/icon/vip.svg"
            class="need-member"
            alt="vip"
          /> -->
        </div>
      </div>
    </div>
    <!-- 添加附件模态窗 -->
    <a-modal
      class="material-main-content-model"
      :centered="true"
      v-model="showResourceModel"
      :title="getString(strings.Mind_Edit_choose_explain)"
      :cancelText="getString(strings.Global_Cancel)"
      :okText="getString(strings.Mind_Edit_Select_locally)"
      @ok="setFileResource"
    >
      <!-- 添加编辑 -->
      <div class="material-content-resource">
        <div class="insert-picture-icon">
          <img
            src="../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/upload.svg"
          />
        </div>
        <span class="insert-picture-text">{{
          getString(strings.Mind_Edit_Drag_And_Drop_The_explain_Here)
        }}</span>
      </div>
    </a-modal>
    <!-- 插入图片模态窗 -->
    <a-modal
      class="material-main-content-model"
      :centered="true"
      v-model="materialLibraryModel"
      :title="getString(strings.Mind_Edit_Choose_Material)"
      :cancelText="getString(strings.Mind_Edit_Select_locally)"
      :okText="getString(strings.Mind_Edit_Choose_From)"
      @ok="materialMainContentHandleOk"
      @cancel="materialMainContentHandleCancel"
    >
      <!-- 添加编辑 -->
      <div class="material-content">
        <div class="insert-picture-icon">
          <img
            src="../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/upload.svg"
          />
        </div>
        <span class="insert-picture-text">{{
          getString(strings.Mind_Edit_Drag_And_Drop_The_Picture_Here)
        }}</span>
      </div>
    </a-modal>
    <!-- ocr扫描模态窗 -->
    <a-modal
      class="material-main-content-model"
      :centered="true"
      v-model="ocrScanModel"
      :title="getString(strings.Mind_Scan_Extraction)"
      :cancelText="getString(strings.Global_Cancel)"
      :okText="getString(strings.Mind_Edit_Select_locally)"
      @ok="ocrScanModelHandleOk"
    >
      <div class="material-content" id="import-Ocr-Scan-Img">
        <div class="insert-picture-icon">
          <img
            src="../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/upload.svg"
          />
        </div>
        <span class="insert-picture-text">
          {{ getString(strings.Mind_Edit_Drag_And_Drop_The_Picture_Here) }}
        </span>
        <div class="available-times-box">
          <!-- 可用次数 -->
          <section class="available-times-title" v-if="ocrScanAvailableTimes">
            {{ getString(strings.Mind_Available_Times) }}：{{
              ocrScanAvailableTimes.scanLeft
            }}/{{ ocrScanAvailableTimes.scanTotal }}
          </section>
          <img
            class="upgrade-member-img"
            src="../../../assets/images/ocr/upgrade_member.svg"
            @click="upgradeMember"
          />
        </div>
      </div>
    </a-modal>
    <input
      id="ocrScanInput"
      type="file"
      accept=".png, .jpg, .webp, .jpeg"
      style="display:none;"
      @change="ocrScanFile"
    />
    <!-- 视频模态窗 -->
    <a-modal
      class="material-main-content-model"
      :centered="true"
      v-model="showVideoModel"
      :title="getString(strings.Mind_Edit_Choose_Material)"
      :cancelText="getString(strings.Global_Cancel)"
      :okText="getString(strings.Mind_Edit_Select_locally)"
      @ok="setFindFromLocal"
    >
      <!-- 添加编辑 -->
      <div class="material-content-vi">
        <div
          :class="
            isSafari
              ? 'safari-material-content-vi'
              : 'other-material-content-vi'
          "
          style="position: absolute; font-size: 15px"
        >
          <span style="">{{
            getString(strings.Mind_Upload_Video_Restrictions)
          }}</span>
        </div>
        <div>
          <img
            src="../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/upload.svg"
          />
        </div>
        <div
          :class="isSafari ? 'safari-video-title' : 'other-video-title'"
          style="position: absolute"
        >
          <span style="color: #cccccc">{{
            getString(strings.Mind_Edit_Drag_And_Drop_The_Video_Here)
          }}</span>
        </div>
      </div>
    </a-modal>

    <a-modal
      class="video-content-model"
      width="820"
      :centered="true"
      v-model="showVideoUrlModel"
      :title="getString(strings.Mind_Edit_Choose_Material)"
      :cancelText="getString(strings.Global_Cancel)"
      :okText="getString(strings.Global_Ok)"
      @ok="setUploadVideoPictures"
      @cancel="cancelUploadVideo"
    >
      <!-- 添加编辑 -->
      <div class="material-content-video">
        <video
          id="video"
          controls="controls"
          preload="metadata"
          :src="videoUrl.result + '#t=1'"
          :style="{ position: 'absolute', zIndex: -1, opacity: 0.01 }"
        ></video>
        <video
          id="video1"
          preload="metadata"
          controls="controls"
          style="height: 100%; max-width: 800px"
          :src="videoUrl.result + '#t=1'"
        ></video>
      </div>
      <div class="video-size">
        <label style="font-size: 18px; font-weight: 540; color: #000000"
          >{{ getString(strings.Mind_Video_Size) }}:&nbsp;&nbsp;</label
        >
        <span style="font-size: 18px; color: #000000; letter-spacing: 1.2px"
          >{{ videoSize }}M</span
        >
      </div>
    </a-modal>
    <!-- 素材库模态框 -->
    <a-modal
      class="material-main-modules-model"
      :centered="true"
      v-model="materialLibraryModulesModel"
    >
      <div class="material-modules">
        <!-- <a-input-search
          :placeholder="materialTitleList.hotSearch"
          style="width: 687px"
          @search="onSearch"
          @focus="isShowRecommendSelect()"
          ref="howValue"
        /> -->
        <div class="left-search">
          <a-button
            class="search-button"
            shape="circle"
            icon="search"
            @click="onSearch()"
          />
          <input
            v-model="searchText"
            v-on:keyup.enter="onSearch()"
            class="search-box"
            type="text"
            :placeholder="materialTitleList.hotSearch"
          />
          <span
            v-if="searchText != ''"
            @click="clearSearchText()"
            class="cleariconfont clear-search-text-icon"
            >&#xe605;</span
          >
        </div>

        <div class="material-main-content">
          <!-- 主标题 -->
          <div
            v-if="
              nowClassifyTitle.isSearch == undefined ||
              nowClassifyTitle.isSearch == false
            "
            class="material-title"
          >
            <ul>
              <li
                v-for="(item, index) in materialTitleList.tags"
                :key="index"
                @click="getMaterialModule(item, index)"
                :class="materialTitleIndex == index ? 'active' : ''"
              >
                <span>{{ item }}</span>
              </li>
            </ul>
          </div>
          <!-- 内容 -->
          <div
            class="material-main"
            :style="{
              marginTop:
                nowClassifyTitle.isSearch != undefined &&
                nowClassifyTitle.isSearch == true
                  ? '50px'
                  : '',
            }"
          >
            <!-- 我的素材title -->
            <div
              class="material-my-title"
              v-if="
                noMyMaterial &&
                (nowClassifyTitle.isSearch == undefined ||
                  nowClassifyTitle.isSearch == false)
              "
            >
              <div
                class="material-my-title-content"
                v-for="(each, index) in materialChildTitleList"
                :key="index"
                :class="materialChildTitleIndex == index ? 'active' : ''"
                @click="getMaterialChildTitle(index, each)"
              >
                <span>{{ each }}</span>
              </div>
              <div
                class="material-my-edit"
                @click="materialEdit"
                v-if="materialMyEditButton"
              >
                <span v-if="!materialEditButton">{{
                  getString(strings.Global_Edit)
                }}</span>
                <span v-else>{{ getString(strings.Global_Cancel) }}</span>
              </div>
            </div>
            <!-- 我的素材内容 -->
            <div
              class="material-my-content"
              v-if="
                materialContentList == null ||
                materialContentList.length > 0 ||
                nowClassifyTitle.childName ==
                  getString(strings.Mind_Edit_My_Material)
              "
              id="material-my-content-box"
            >
              <div id="material-my-content-box-child">
                <!-- 添加本地图片 -->
                <div
                  class="material-my-source"
                  @click="setMaterialImgData"
                  v-if="setMaterialLocal"
                >
                  <img
                    src="../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/add_localsotrm_img.svg"
                    alt=""
                  />
                </div>
                <!-- 素材 -->
                <div
                  class="material-my-source-content"
                  v-for="(item, index) in materialContentList"
                  :key="index"
                >
                  <img
                    v-show="
                      item.needMember != undefined &&
                      item.needMember &&
                      !isMember()
                    "
                    class="subscription-text"
                    src="../../../assets/img/user/subscription.png"
                    alt=""
                  />
                  <!-- 封面 -->
                  <div
                    @click="onSelectedImg(item)"
                    class="material-my-img"
                    :style="{
                      backgroundImage: 'url(' + item.cover + ')',
                    }"
                  >
                    <div
                      class="material-delete-icon"
                      @click.stop="materialDelete(item.id)"
                      v-if="materialEditButton"
                    >
                      <img
                        src="../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/click_hide.png"
                        alt=""
                      />
                    </div>
                    <div class="material-cover">
                      <!-- <img :src="item.cover" alt="" /> -->
                    </div>
                  </div>
                  <!-- 底部按钮 -->
                  <div class="material-my-bottom">
                    <div class="material-text">
                      {{ item.title }}
                    </div>
                    <div
                      class="material-button"
                      @click.stop="materialUse(item)"
                      v-if="typeTipsIf(item.types)"
                    >
                      <span
                        v-if="item.needMember != undefined && item.needMember"
                        >{{ getString(strings.Mind_Edit_Members_Use) }}</span
                      >
                      <span v-else>{{ getString(strings.Mind_Edit_Use) }}</span>
                    </div>
                    <div class="material-tips" v-else>
                      <span>{{ typeTips() }}</span>
                    </div>
                    <!-- <div
                      class="material-button"
                      @click.stop="materialUse(item)"
                    >
                      <span>{{ getString(strings.Mind_Edit_Use) }}</span>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>

            <div class="material-my-content-is-null" v-else>
              <div>
                <img
                  src="../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/material_is_null.png"
                  alt=""
                />
                <p>
                  {{
                    getString(
                      strings.Mind_Edit_No_Material_Temporarily_Go_Add_It
                    )
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-modal>
    <!-- 插入chart类型模态框 -->
    <a-modal
      class="chart-main-modules-model"
      :centered="true"
      v-model="chartModel"
      :title="getString(strings.Mind_Edit_Select_Chart_Type)"
    >
      <!-- 添加编辑 -->
      <div class="chart-main-content">
        <div class="chart-main-style">
          <div
            class="chart-main-style-content"
            v-for="(item, index) in chartEditShowType"
            :key="index"
          >
            <img :src="item.url" alt="" @click="getShowType(item.type)" />
          </div>
        </div>
      </div>
    </a-modal>
    <!-- 会员权益modal -->
    <a-modal
      class="report-member-model"
      :centered="true"
      v-model="storageIsMeberModelAccout"
      :title="getString(strings.Mind_Edit_Join_Membership)"
      :cancelText="getString(strings.Global_Cancel)"
      :okText="getString(strings.Mind_Edit_Upgrade)"
      @cancel="storageIsMeberCancelHandleOk"
      @ok="storageIsMeberDeleteHandleOk"
    >
      <div class="report-crisps-style-box">
        <div
          class="report-crisps-style"
          style="
            font-size: 18px;
            font-family: Noto Sans SC;
            text-align: center;
            display: block;
          "
        >
          {{ getString(strings.Insufficient_Storage_Space) }}
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import EditMindmapVM from "../../../viewmodel/mindelementdata/vm/EditMindmapVM";
import materialContentModel from "../../../assets/css/materialContentModel/materialContentModel.less";
import {
  postMindmapUploadImage,
  postMindmapUploadAttachment,
  postMindmapOcrScan,
  postMindmapOcrInfo,
} from "../../../common/netWork/mind_map_api";
import { postBaseGlobalConfig } from "../../../common/netWork/networkcache";
import {
  postMaterialMyMaterials,
  postMaterialHistory,
  postMaterialQuery,
  postMaterialSearch,
  postMaterialDelete,
  postMaterialUpload,
  postMaterialUse,
  postMaterialView,
} from "../../../common/netWork/material_api";
import Logger from "../../../utils/Logger";
import StatisticsChapeType from "../../../viewmodel/datatype/StatisticsChapeType";
import imageToStyle from "../../../utils/ImageToolkit";
import httpImageToPrefix from "../../../utils/httpImageToPrefix";
import getString from "../../../common/lang/language";
import strings from "../../../common/lang/strings";
import MindDisplayType from "../../../viewmodel/datatype/MindDisplayType";
import MindElementType from "../../../viewmodel/datatype/MindElementType";
import publicModalStyle from "../../../assets/css/publicModalStyle/publicModalStyle.less";
import MindOperateUIControllerView from "../tools/MindOperateUIControllerView";
import { getJwt, md5 } from "../../../common/netWork/base";

import IdGenerator from "../../../viewmodel/core/base/IdGenerator";
import VideoStatus from "../../../viewmodel/datatype/VideoStatus";
import VideoStatusData from "../../../viewmodel/core/minddata/VideoStatusData";
import HttpServerConfig from "../../../common/HttpServerConfig";
import ResourceType from "../../../viewmodel/datatype/ResourceType";
import {
  materialLibraryType,
  eventBusMethod,
  ocrSupportedScanImgTypes,
  ocrScanExtractTypes,
} from "../../../assets/js/GlobalVariable.js";
import LoadLocalXJMind from '../../../viewmodel/core/tools/mapfile/LoadLocalXJMind';
import SaveMindMapToLocal from '../../../viewmodel/core/tools/mapfile/SaveMindMapToLocal';
import Util from '../../../utils/Util';
import MindMapZipConstant from '../../../viewmodel/core/tools/mapfile/MindMapZipConstant';
import MindMe from '../../../viewmodel/facade/MindMe';

export default {
  inject: ["commonShipping"],
  props: ["darkMode"],
  components: {
    // SpinningAnimation,
  },
  data() {
    return {
      imgDataList: [
        {
          imgDataAf: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/add_icon_af.svg"),
          imgDataText: strings.Mind_Edit_Insert_Icon,
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/addIconWhite.svg"),
          imgDataAccout: "ADD_ICON",
        },
        {
          imgDataAf: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/add_remark_af.svg"),
          imgDataText: strings.Mind_Edit_Insert_Remarks,
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/addRemarkWhite.svg"),
          imgDataAccout: "ADD_Remark",
        },
        {
          imgDataAf: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/add_insert_link_af.svg"),
          imgDataText: strings.Mind_Edit_Insert_Link,
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/insertLinkWhite.svg"),
          imgDataAccout: "ADD_Link",
        },
        {
          imgDataAf: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/add_chart_icon.svg"),
          imgDataText: strings.Mind_Edit_Insert_Summary_Graph,
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/addChartWhite.svg"),
          imgDataAccout: "ADD_Chart",
        },

        {
          imgDataAf: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/add_subScript_icon.svg"),
          imgDataText: strings.Mind_Edit_Subscript,
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/addSubScriptWhite.svg"),
          imgDataAccout: "ADD_Subscript",
        },

        {
          imgDataAf: require("../../../assets/img/canvasMenu/association_map.svg"),
          imgDataText: strings.Mind_Association_Map,
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/associationMapWhite.svg"),
          needMember: true,
          imgDataAccout: "ADD_Association",
        },
        {
          imgDataAf: require("../../../assets/img/canvasMenu/editLatex.svg"),
          imgDataText: strings.Mind_Edit_Latex,
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/editLatexWhite.svg"),
          needMember: true,
          imgDataAccout: "ADD_Latex",
        },
        {
          imgDataAf: require("../../../assets/img/mindNewEditMenuIocn/freeNode.svg"),
          imgDataText: strings.Mind_Edit_Free_Node,
          imgWhite: require("../../../assets/img/mindNewEditMenuIocn/darkMode/freeNode.svg"),
          imgDataAccout: "ADD_FREENODE",
        },
      ], //菜单icon
      imgDataAccout: 0, //菜单index
      userImage: null, //图片信息
      materialLibraryModel: false, // 选择模态框
      ocrScanModel: false, // ocr扫描模态框
      ocrScanAvailableTimes: null, //ocr扫描可用次数
      showVideoModel: false, //视频上传模态框
      showVideoUrlModel: false, //视频展示
      videoUrl: "", //视频连接
      videoFile: null,
      videoName: "", //视频名称
      localPath: "", //本地地址
      videoSize: 0, //视频大小
      isSafari: false, //是不是safari浏览器
      materialLibraryModulesModel: false, // 素材库模态框
      materialTitleList: [], //主标题
      materialTitleIndex: 0, //素材库标题索引
      materialChildTitleList: [
        getString(strings.Mind_Edit_My_Material),
        getString(strings.Mind_Edit_Recently_Used),
      ], //我的素材库标题
      materialChildTitleIndex: 0, //我的素材库标题索引
      materialContentList: [], //素材内容
      colorList: [
        "#9db2c4",
        "#9179c3",
        "#e18995",
        "#ebc185",
        "#82d0dc",
        "#60a5a2",
        "#6d6a8c",
        "#f1cecc",
      ], //未加载成功色块
      chartEditShowType: [
        {
          url: require("../../../assets/img/create_chart_icon/pie_chart.svg"),
          type: StatisticsChapeType.CakeShape_1,
        },
        {
          url: require("../../../assets/img/create_chart_icon/ring_chart.svg"),
          type: StatisticsChapeType.CakeShape_2,
        },
        {
          url: require("../../../assets/img/create_chart_icon/halving_chart.svg"),
          type: StatisticsChapeType.CakeShape_3,
        },
        {
          url: require("../../../assets/img/create_chart_icon/pie_s_chart.svg"),
          type: StatisticsChapeType.CakeShape_4,
        },
        {
          url: require("../../../assets/img/create_chart_icon/no_halving_chart.svg"),
          type: StatisticsChapeType.CakeShape_5,
        },
        {
          url: require("../../../assets/img/create_chart_icon/histogram_chart.svg"),
          type: StatisticsChapeType.Histogram_1,
        },
        {
          url: require("../../../assets/img/create_chart_icon/cylinder _chart.svg"),
          type: StatisticsChapeType.Histogram_2,
        },
        {
          url: require("../../../assets/img/create_chart_icon/dot_chart.svg"),
          type: StatisticsChapeType.Histogram_3,
        },
      ], //导图类型
      chartModel: false, // 统计图类型模态框

      materialEditButton: false, //编辑导图

      setMaterialLocal: true, //本地素材添加入口
      noMyMaterial: true, //是否为我的素材库
      materialMyEditButton: true, //是否显示编辑按钮
      
      loadingMore: false, //加载更多
      isScroll: true, //是否可以滚动
      pageIndex: 0, //当前所在的页码

      nowClassifyTitle: {
        //当前所在的分类页面
        name: getString(strings.Mind_Edit_Mine),
        childName: getString(strings.Mind_Edit_My_Material),
        isSearch: false,
      },
      searchText: "", //当前搜索的内容
      spinning: false, // 是否展示加载动画
      strings: strings, // 语言包
      nodeType: null, //节点类型
      storageIsMeberModelAccout: false, //会员权益motal框
      insertImageBlockText: "",
      baseGlobalConfigApiRequested: false, //通用配置接口是否已请求
      MindOperateUIControllerView: null,
      globalConfigList: null,
      nodeId: null,

      showResourceModel: false, //附件上传模态框
      resourceUrl: "", //附件连接
      resourceName: "", //附件名称
      localPathResource: "", //附件本地地址
      resourceSize: 0, //附件大小
      executeOnlyOnce: true, //只执行一次
    };
  },
  created() {
    this.MindOperateUIControllerView = new MindOperateUIControllerView(
      this.$store.state.editMindmap,
      this.$store.state.editMindmap
    );

    var ua = navigator.userAgent.toLowerCase();
    if (ua.match(/version\/([\d.]+).*safari/) == null) {
      this.isSafari = false;
    } else {
      this.isSafari = true;
    }
  },
  mounted() {
    this.editMindmapContent = EditMindmapVM;
    //根据素材数据 实现色块渲染
    // if (this.materialContentList.length > this.colorList.length) {
    //   for (let i = 0; i < this.colorList.length; i++) {
    //     const color = this.colorList[i];
    //     if (this.materialContentList.length != this.colorList.length) {
    //       this.colorList.push(color);
    //     }
    //   }
    // }
    this.baseGlobalConfigAPI().then(
      (res) => {
        this.globalConfigList = res;
      },
      (err) => {
        this.globalConfigList = false;
      }
    );
    this.setDardarkMode();
  },
  methods: {
    ...mapMutations([
      "setAddIconPage",
      "setDomImg",
      "setDomVideo",
      "setShowVideoUpload",
      "setShowNodeId",
      "setShowAddRemark",
      "setShowAddLink",
      "showCropperImg",
      "showCropperImgUrl",
      "showLoginModel",
      "setMaterialImg",
      "isShowShoppingModel",
      "setmaterialLibraryModulesModel",
      "setIsAssociationModel",
      "setIsLaTeXModel",
      "setAddRemarkContent",
    ]),
    //国际化
    getString(i) {
      return getString(i);
    },

    materialRequestUrl(obj) {
      //根据title类型渲染素材，除了我的
      let that = this;
      return new Promise(function (resolve, reject) {
        postMaterialView(
          obj,
          (res) => {
            resolve(res);
          },
          (error) => {
            that.$message.error(error.desc);
            that.spinning = false;
            reject(error);
          }
        );
      });
    },

    onSelectedImg: function (data, point) {
      //只有模板和分享
      this.materialRequestUrl({ id: data.id }).then((res) => {
        let obj = {};
        obj.src = res.url;
        obj.width = res.width;
        obj.height = res.height;
        obj.typeName = "material";
        this.bus.$emit("examineImg", obj);
      });
    },
    clearSearchText() {
      this.searchText = "";
    },
    checkTitle(obj) {
      //改变当前所在的页面
      // if(obj.name == this.nowClassifyTitle.name) {  //如果大标题标题相等。
      //   return;
      // }
      this.nowClassifyTitle = obj;
    },

    scrollMoreData() {
      //滚动事件
      let scrollDom = document.getElementById("material-my-content-box");
      let child = document.getElementById("material-my-content-box-child");
      const scrollTopHeight = scrollDom.scrollTop || scrollDom.scrollTop; //滚动高度
      const clientHeight = 406; //屏幕可用工作区高度
      const offsetHeight = child.offsetHeight || child.offsetHeight; //网页可见区域高(包括边线的宽)

      if (
        scrollTopHeight + clientHeight + 50 >= offsetHeight &&
        this.isScroll
      ) {
        this.isScroll = false;
        // this.loadingMore = true

        // 判断是哪个请求
        if (this.nowClassifyTitle.name == getString(strings.Mind_Edit_Mine)) {
          if (
            this.nowClassifyTitle.childName ==
            getString(strings.Mind_Edit_My_Material)
          ) {
            this.checkTitle({
              name: getString(strings.Mind_Edit_Mine),
              childName: getString(strings.Mind_Edit_My_Material),
            });
            this.setMaterialLocal = true;
            this.materialMyEditButton = true;
            this.MyMaterials({
              page: this.pageIndex + 1,
              type: this.nodeType,
            })
              .then((res) => {
                if (res.materials == null && res.totalNum == 0) {
                  return;
                }
                this.pageIndex = this.pageIndex + 1;
                // this.materialTitleList = res;
                // this.materialTitleList.tags.unshift(getString(strings.Mind_Edit_Mine));
                for (let i = 0; i < res.materials.length; i++) {
                  const cover = res.materials[i].cover;
                  res.materials[i].cover = httpImageToPrefix(cover);
                }
                this.materialContentList = this.materialContentList.concat(
                  res.materials
                );
              })
              .finally((res) => {
                this.isScroll = true;
              });
          } else if (this.nowClassifyTitle.childName == "最近使用") {
            this.checkTitle({
              name: getString(strings.Mind_Edit_Mine),
              childName: "最近使用",
            });

            this.materialHistory({ page: this.pageIndex + 1 })
              .then((res) => {
                if (res.materials == null && res.totalNum == 0) {
                  return;
                }
                this.materialMyEditButton = false;
                this.pageIndex = this.pageIndex + 1;
                for (let i = 0; i < res.materials.length; i++) {
                  const cover = res.materials[i].cover;
                  res.materials[i].cover = httpImageToPrefix(cover);
                }
                this.materialContentList = res.materials;
                this.setMaterialLocal = false;
              })
              .finally((res) => {
                this.isScroll = true;
              });
          }
        } else if (
          this.nowClassifyTitle.isSearch != undefined &&
          this.nowClassifyTitle.isSearch == true
        ) {
          //搜索

          this.materialSearch({
            query: this.searchText,
            page: this.pageIndex + 1,
          })
            .then((res) => {
              if (res.materials == null && res.totalNum == 0) {
                return;
              }
              this.pageIndex = this.pageIndex + 1;
              for (let i = 0; i < res.materials.length; i++) {
                const cover = res.materials[i].cover;
                res.materials[i].cover = httpImageToPrefix(cover);
              }
              this.materialContentList = this.materialContentList.concat(
                res.materials
              );
            })
            .finally((res) => {
              this.isScroll = true;
            });
        } else {
          this.materialQuery({
            tag: this.nowClassifyTitle.name,
            page: this.pageIndex + 1,
          })
            .then((res) => {
              if (res.materials == null && res.totalNum == 0) {
                return;
              }
              // this.materialContentList = [];
              this.pageIndex = this.pageIndex + 1;
              // this.materialTitleList.tags.unshift(getString(strings.Mind_Edit_Mine));
              for (let i = 0; i < res.materials.length; i++) {
                const cover = res.materials[i].cover;
                res.materials[i].cover = httpImageToPrefix(cover);
              }
              this.materialContentList = this.materialContentList.concat(
                res.materials
              );
            })
            .finally((res) => {
              this.isScroll = true;
            });
        }
      }
    },

    storageIsMeberCancelHandleOk() {
      this.storageIsMeberModelAccout = false;
    },
    storageIsMeberDeleteHandleOk() {
      this.storageIsMeberModelAccout = false;
      this.isShowShoppingModel(true);
    },
    //图标 备注 链接
    setImgDataList(index, accout) {
      this.imgDataAccout = index;
      if (accout == "ADD_ICON") {
        this.$store.state.isShowMindmapRightMenu = true;
        this.setAddIconPage(null); //重置数据
        this.setAddIconPage(accout);
      }
      if (accout == "ADD_Remark") {
        // this.setShowAddRemark(true);
        this.editMindmapContent.openRemarksPanel();
      }
      if (accout == "ADD_Link") {
        this.editMindmapContent.openLinkPanel();
      }
      if (accout == "ADD_Chart") {
        this.chartModel = true;
      }

      if (accout == "ADD_Subscript") {
        this.bus.$emit("ADD_Subscript", true);
      }

      if (accout == "ADD_FREENODE") {
        this.MindOperateUIControllerView.onSelected(MindElementType.FREE_NODE);
      }

      if (accout == "ADD_EXPLAIN") {
        this.MindOperateUIControllerView.onSelected(MindElementType.EXPLAIN);
      }

      if (accout == "ADD_BLOCK") {
        this.MindOperateUIControllerView.onSelected(
          MindElementType.BAOWEI_VIEW
        );
      }

      if (accout == "ADD_SUMMARY") {
        this.MindOperateUIControllerView.onSelected(
          MindElementType.CONTENT_GENERALIZATION
        );
      }

      if (accout == "ADD_Association") {
        // 关联导图
        if (!getJwt("jwt")) {
          this.showLoginModel(true);
          return;
        }
        if (this.editMindmapContent.selectedNodeId == IdGenerator.INVALID_ID) {
          this.editMindmapContent.selectAddTargetTips();
          return;
        }
        if (false) {
          this.isShowShoppingModel(true);
        } else {
          this.setIsAssociationModel(true);
        }
      }

      if (accout == "ADD_Latex") {
        // 关联导图
        if (!getJwt("jwt")) {
          this.showLoginModel(true);
          return;
        }
        if (this.editMindmapContent.selectedNodeId == IdGenerator.INVALID_ID) {
          this.editMindmapContent.selectAddTargetTips();
          return;
        }
        this.setIsLaTeXModel(true);
      }
    },
    //点击获取统计图类型
    getShowType(type) {
      this.editMindmapContent.setStatisticsNode(type);
      this.chartModel = false;
    },
    //获取本地图片
    handleFile(e) {
      let $target = e.$target || e.srcElement;
      let file = $target.files[0];
      if (LoadLocalXJMind.isLoaclMind() && !LoadLocalXJMind.isCloudStorage()) {
        let path = file.path
        let width;
        let height;
        let img = new Image();
        img.setAttribute("crossOrigin", "Anonymouse");
        img.src = HttpServerConfig.getLocalPathPrefix(path, this.$router.appPath)
        img.onload = () => {
          width = img.width;
          height = img.height;
          this.setDomImg({
            imageBase64: path,
            type: "uploadImg",
            width: width,
            height: height,
          });
          setTimeout(() => {
            this.setDomImg({
              imageBase64: null,
              type: "uploadImg",
              width: 0,
              height: 0,
            });
          }, 100);
        };                  
        let localPath = LoadLocalXJMind.getLocalFileRootPath()
        if (localPath != null && localPath.length > 0) {
          this.copyFile(path, localPath, LoadLocalXJMind.getFileName(path, true));
        }
        return
      }

      var reader = new FileReader();
      reader.onload = (data) => {
        let res = data.target || data.srcElement;
        this.setUploadImg(res);
      };
      reader.readAsDataURL(file);
      if (file < 1024 * 1024 * 3) {
        this.$message.error(
          getString(strings.Message_Tips_Share_Link_Does_Not_Exist)
        );
        return false;
      } else {
        // this.showCropperImg(true);

        this.$el.querySelector(".hiddenInput").value = null;
      }
    },

    //获取本地附件
    handleResourceFile(e) {
      let $target = e.$target || e.srcElement;
      let file = $target.files[0];
      if (file.size > 1024 * 1024 * 20) {
        this.$message.error(
          getString(strings.Message_Tips_Share_Explain_Does_Not_Exist)
        );
        this.cancelUploadExplain();
        return false;
      }
      this.localPathResource = file.path;
      if (LoadLocalXJMind.isLoaclMind() && !LoadLocalXJMind.isCloudStorage()) {
        let resourceFileSize = file.size ? file.size : 1;
        let resoType = ResourceType.fromByStr(file.type);
        let name = file.name;
        this.editMindmapContent.setResourceById(
          this.localPathResource,
          resoType,
          resourceFileSize,
          this.nodeId,
          encodeURIComponent(name)
        );
        let localPath = LoadLocalXJMind.getLocalFileRootPath()
        if (localPath != null && localPath.length > 0) {
          this.copyFile(this.localPathResource, localPath, LoadLocalXJMind.getFileName(this.localPathResource, true));
        }
        return
      }
      var reader = new FileReader();
      reader.onload = (data) => {
        let res = data.target || data.srcElement;
        if (file.size > 1024 * 1024 * 20) {
          this.$message.error(
            getString(strings.Message_Tips_Share_Explain_Does_Not_Exist)
          );
          this.cancelUploadExplain();
          return false;
        }
        let calculationResource = file.size;

        this.resourceSize = calculationResource.toFixed(2);
        //此处处理附件
        this.resourceName = file.name;
        this.resourceUrl = res;
        this.setUploadResource();
        // console.log(res,file);
      };
      reader.readAsDataURL(file);
    },
    //获取本地视频
    handleVideoFile(e) {
      let $target = e.$target || e.srcElement;
      let file = $target.files[0];
      if (file.size > 1024 * 1024 * 10) {
        this.$message.error(
          getString(strings.Message_Tips_Share_Video_Does_Not_Exist)
        );
        this.cancelUploadVideo();
        return false;
      } else {
        // this.showCropperImg(true);
        // this.$el.querySelector(".hiddenInputVideo").value = null;
      }
      this.localPath = this.$el.querySelector(".hiddenInputVideo").value;
      var reader = new FileReader();
      reader.onload = (data) => {
        let res = data.target || data.srcElement;
        if (file.size > 1024 * 1024 * 10) {
          this.$message.error(
            getString(strings.Message_Tips_Share_Video_Does_Not_Exist)
          );
          this.cancelUploadVideo();
          return false;
        }
        let calculationVideo = file.size / 1024 / 1024;

        this.videoSize = calculationVideo.toFixed(2);
        //此处处理视频
        this.videoName = file.name;
        // this.setUploadImg(res);
        this.videoUrl = res;
        this.showVideoUrlModel = true;
      };
      this.videoFile = file;
      // this.videoUrl = file.name;
      reader.readAsDataURL(file);
    },
    //触发选取的图片
    setImgData() {
      this.$el.querySelector(".hiddenInput").click();
    },
    //触发添加附件
    setResourceData() {
      this.$el.querySelector(".hiddenInputResource").click();
    },
    //触发选取的视频
    setVideoData() {
      this.$el.querySelector(".hiddenInputVideo").click();
    },
    //打开添加图片面板 拖动添加图片
    setImgDataOrMaterial() {
      if (getJwt("jwt")) {
        this.insertPictureDialog(false);
        // 新用户第二天才能插入图片
        /*
        let isNewUser = JSON.parse(localStorage.getItem("isNewUser"));
        if(!this.baseGlobalConfigApiRequested){
          let registerTime = meMessage.registerTime;
          this.baseGlobalConfigAPI()
          .then((res) => {
            let time = Math.round(new Date() / 1000) - parseInt(registerTime);
            this.insertImageBlockText = res.insertImageBlockText;  
            this.baseGlobalConfigApiRequested = true;
            if((time / 60 / 60)<res.insertImageDelayHours){            
             localStorage.setItem("isNewUser", true);
             isNewUser = true;
             this.insertPictureDialog(isNewUser)       
            }else{
              localStorage.setItem("isNewUser", false);
              this.insertPictureDialog(isNewUser)
            }           
          });
        }else{
          this.insertPictureDialog(isNewUser)
        }
        */
      } else {
        this.showLoginModel(true);
        return;
      }
    },
    //打开添加视频面板 拖动添加视频
    setVideo() {
      //当前未选中节点
      if (this.editMindmapContent.selectedNodeId == IdGenerator.INVALID_ID) {
        this.editMindmapContent.selectAddTargetTips();
        return;
      }
      if (getJwt("jwt")) {
        this.insertVideoDialog(false);
        if (this.videoUrl != "" && this.videoUrl != null) {
          this.$message.warning("当前正在上传视频中，请上传完成之后在上传");
          return;
        }

        // 新用户第二天才能插入视频
        /*
        let isNewUser = JSON.parse(localStorage.getItem("isNewUser"));
        if(!this.baseGlobalConfigApiRequested){
          let registerTime = meMessage.registerTime;
          this.baseGlobalConfigAPI()
          .then((res) => {
            let time = Math.round(new Date() / 1000) - parseInt(registerTime);
            this.insertImageBlockText = getString(strings.Non_Members_Insert_Videos);  
            this.baseGlobalConfigApiRequested = true;
            if((time / 60 / 60)<res.insertImageDelayHours){            
             localStorage.setItem("isNewUser", true);
             isNewUser = true;
             this.insertVideoDialog(isNewUser)       
            }else{
              localStorage.setItem("isNewUser", false);
              this.insertVideoDialog(isNewUser)
            }           
          });
        }else{
          this.insertVideoDialog(isNewUser)
        }*/
      } else {
        this.showLoginModel(true);
        return;
      }
    },
    setResource() {
      //当前未选中节点
      if (this.editMindmapContent.selectedNodeId == IdGenerator.INVALID_ID) {
        this.editMindmapContent.selectAddTargetTips();
        return;
      }
      if (getJwt("jwt")) {
        this.insertResourceDialog(false);
      } else {
        this.showLoginModel(true);
        return;
      }
    },
    // 控制插入图片
    insertPictureDialog(isNewUser) {
      if (isNewUser) {
        this.$message.warning(this.insertImageBlockText);
      } else {
        this.materialLibraryModel = true;
        this.showResourceModel = false;
        this.showVideoModel = false;
        this.$nextTick(() => {
          let upload = document.querySelector(".material-content");
          if (upload) {
            upload.addEventListener("dragenter", this.onDrag, false);
            upload.addEventListener("dragover", this.onDrag, false);
            upload.addEventListener("drop", this.onDrop, false);
          }
        });
      }
    },
    //控制添加附件
    insertResourceDialog(isNewUser) {
      if (isNewUser) {
        this.$message.warning(this.insertImageBlockText);
      } else {
        this.nodeId = this.editMindmapContent.selectedNodeId;
        this.materialLibraryModel = false;
        this.showVideoModel = false;
        this.showResourceModel = true;
        this.$nextTick(() => {
          let upload = document.querySelector(".material-content-resource");
          upload.addEventListener("dragenter", this.onDragExplain, false);
          upload.addEventListener("dragover", this.onDragExplain, false);
          upload.addEventListener("drop", this.onDropExplain, false);
        });
      }
    },
    //控制插入视频
    insertVideoDialog(isNewUser) {
      if (isNewUser) {
        this.$message.warning(this.insertImageBlockText);
      } else {
        this.nodeId = this.editMindmapContent.selectedNodeId;
        this.setShowNodeId(this.nodeId);
        this.materialLibraryModel = false;
        this.showResourceModel = false;
        this.showVideoModel = true;
        this.$nextTick(() => {
          let upload = document.querySelector(".material-content-vi");
          upload.addEventListener("dragenter", this.onDrag, false);
          upload.addEventListener("dragover", this.onDrag, false);
          upload.addEventListener("drop", this.onDrop, false);
        });
      }
    },
    // 通用配置接口
    baseGlobalConfigAPI(obj) {
      return new Promise((resolve, reject) => {
        postBaseGlobalConfig(
          obj,
          (res) => {
            resolve(res);
          },
          (error) => {
            this.$message.error(error.desc);
            this.spinning = false;
            reject(error);
          }
        );
      });
    },
    materialQuery(obj) {
      //根据title类型渲染素材，除了我的
      let that = this;
      return new Promise(function (resolve, reject) {
        postMaterialQuery(
          obj,
          (res) => {
            resolve(res);
          },
          (error) => {
            that.$message.error(error.desc);
            that.spinning = false;
            reject(error);
          }
        );
      });
    },

    //根据title类型渲染素材
    getMaterialModule(tag, index) {
      this.materialTitleIndex = index;
      this.materialEditButton = false;
      if (index == 0) {
        this.checkTitle({
          name: tag,
          childName: getString(strings.Mind_Edit_My_Material),
        });
        this.postMaterialMyMaterials();
        this.noMyMaterial = true;
        this.setMaterialLocal = true;
      } else {
        this.checkTitle({ name: tag, childName: "" });
        this.noMyMaterial = false;
        this.setMaterialLocal = false;
        this.materialContentList = [];
        this.materialQuery({ tag: tag, page: 1 }).then((res) => {
          // this.materialContentList = [];
          this.pageIndex = 1;
          // this.materialTitleList.tags.unshift(getString(strings.Mind_Edit_Mine));
          for (let i = 0; i < res.materials.length; i++) {
            const cover = res.materials[i].cover;
            res.materials[i].cover = httpImageToPrefix(cover);
          }
          this.materialContentList = res.materials;
        });
      }
    },

    materialHistory(obj) {
      //根据历史记录渲染素材，除了我的
      let that = this;
      return new Promise(function (resolve, reject) {
        postMaterialHistory(
          obj,
          (res) => {
            resolve(res);
          },
          (error) => {
            that.$message.error(error.desc);
            that.spinning = false;
            reject(error);
          }
        );
      });
    },

    //根据我的素材库title类型渲染素材
    getMaterialChildTitle(index, each) {
      this.materialChildTitleIndex = index;
      this.materialEditButton = false;

      if (index == 0) {
        this.checkTitle({
          name: getString(strings.Mind_Edit_Mine),
          childName: each,
        });
        this.postMaterialMyMaterials(true);
        this.setMaterialLocal = true;
        this.materialMyEditButton = true;
      } else if (index == 1) {
        this.checkTitle({
          name: getString(strings.Mind_Edit_Mine),
          childName: each,
        });

        this.materialHistory({ page: 1 }).then((res) => {
          this.materialMyEditButton = false;
          this.pageIndex = 1;

          if (res.materials != null) {
            for (let i = 0; i < res.materials.length; i++) {
              const cover = res.materials[i].cover;
              res.materials[i].cover = httpImageToPrefix(cover);
            }
          }
          this.materialContentList = res.materials;
          this.setMaterialLocal = false;
        });
      }
    },
    //本地素材
    handleMaterialFile(e) {
      let $target = e.$target || e.srcElement;
      let file = $target.files[0];
      var reader = new FileReader();
      reader.onload = (data) => {
        let res = data.target || data.srcElement;
        let userImage = res.result;
        //转Blob
        function blobData() {
          var arr = userImage.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
          while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
          }
          return new Blob([u8arr], { type: mime });
        }
        var blob = blobData();
        let imgUrlData = new Promise((resolve, reject) => {
          postMindmapUploadImage(
            blob,
            (res) => {
              resolve(res.imageUrl);
            },
            (error) => {
              this.$message.error(error.desc);
            },
            null,
            {
              "X-Type": "material-content",
              "X-ID": this.$route.query.id,
            }
          );
        });
        //url请求
        imgUrlData.then((img) => {
          postMaterialUpload({ imageUrl: img }, (res) => {
            res.cover = httpImageToPrefix(res.cover);
            res.imageUrl = httpImageToPrefix(res.imageUrl);
            this.postMaterialMyMaterials();
          });
        });
      };
      reader.readAsDataURL(file);
      if (file < 1024 * 1024 * 3) {
        this.$message.error(
          getString(strings.Message_Tips_Share_Link_Does_Not_Exist)
        );
        return false;
      } else {
        this.$el.querySelector(".hiddenInputMaterial").value = null;
      }
    },
    //本地添加素材
    setMaterialImgData() {
      let showMember = this.isMemberFunction();
      if (showMember) {
        return;
      }
      this.$el.querySelector(".hiddenInputMaterial").click();
      this.materialLibraryModel = false;
      this.nodeType = "img";
    },
    //从素材库选择
    materialMainContentHandleOk() {
      let meMessage = localStorage.getItem("meMessage");
      if (meMessage == null) {
        this.showLoginModel(true);
        return;
      }
      this.materialLibraryModulesModel = true;
      this.materialLibraryModel = false;
      this.nodeType = "img";
      this.setmaterialLibraryModulesModel({ accout: true, type: "img" });
    },
    isMemberFunction() {
      var meMessage = MindMe.getMeMessage();
      if (meMessage == null || !meMessage.isMember) {
        let that = this;
        if (
          this.globalConfigList != null &&
          this.globalConfigList != false &&
          !this.globalConfigList.uploadImageFree
        ) {
          this.$confirm({
            title: getString(strings.Mind_Tips),
            content: getString(strings.Member_Exclusive),
            centered: true,
            okText: getString(strings.Mind_Edit_Confirm),
            cancelText: getString(strings.Global_Cancel),
            onOk() {
              that.isShowShoppingModel(true);
            },
            onCancel() {},
          });
          return true;
        }
      }
      return false;
    },
    //从本地选择
    materialMainContentHandleCancel(e) {
      if (e.target.nodeName == "BUTTON") {
        // if (this.$store.state.nodeContentMessage == undefined) {
        //   this.materialLibraryModel = false;
        //   this.materialLibraryModulesModel = false;
        //   this.editMindmapContent.selectAddTargetTips();
        //   return;
        // }
        let isShow = this.isMemberFunction();
        if (isShow) {
          return;
        }
        this.setImgData();
        this.materialLibraryModel = false;
      }
      this.nodeType = "img";
      this.setmaterialLibraryModulesModel({ accout: false, type: "img" });
    },
    //从本地选择附件
    setFileResource(e) {
      if (e.target.nodeName == "BUTTON") {
        // let isShow = this.isMemberFunction();
        // if (isShow) {
        //   return;
        // }
        this.setResourceData();
        this.showResourceModel = false;
      }
    },
    getOcrScanAvailableTimes() {
      //ocr扫描可用次数
      postMindmapOcrInfo(
        "",
        (res) => {
          if (res) {
            this.ocrScanAvailableTimes = res;
          }
        },
        (error) => {
          this.$message.error(error.desc);
        }
      );
    },
    upgradeMember() {
      //升级会员
      this.isShowShoppingModel(true);
    },
    onDropOcrScanImg(e) {
      e.stopPropagation();
      e.preventDefault();
      let file = e.dataTransfer.files[0];
      this.readOcrScanFile(file);
    },
    //从本地选择ocr扫描图片
    ocrScanModelHandleOk(e) {
      if (e.target.nodeName == "BUTTON") {
        let ocrScanInputDom = document.getElementById("ocrScanInput");
        if (ocrScanInputDom) {
          ocrScanInputDom.click();
        }
      }
    },
    //获取本地ocr扫描图片
    ocrScanFile(e) {
      let $target = e.$target || e.srcElement;
      let file = $target.files[0];
      this.readOcrScanFile(file);
    },
    //读取ocr扫描图片
    readOcrScanFile(file) {
      if (file) {
        let fileName = file.name;
        let fileType = fileName.substring(fileName.lastIndexOf("."));
        if (!ocrSupportedScanImgTypes.includes(fileType)) {
          this.$message.error(
            getString(strings.Not_Supported_At_The_Moment) +
              fileType +
              getString(strings.Mind_File)
          );
          return;
        }
        let reader = new FileReader();
        reader.onload = (data) => {
          let res = data.target;
          this.setOcrScanImg(res);
        };
        reader.readAsDataURL(file);
        let ocrScanInputDom = document.getElementById("ocrScanInput");
        if (ocrScanInputDom) {
          ocrScanInputDom.value = null;
        }
      }
    },
    blobData(image) {
      let arr = image.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    setOcrScanImg(read) {
      this.ocrScanModel = false;
      this.commonShipping(true);
      let userImage = read.result;
      //转Blob
      var blob = this.blobData(userImage);
      const formData = new FormData();
      formData.append("image", blob);
      formData.append("type", "TEXT"); //CREATE（扫码创建新导图）,TEXT（在节点或备注中输入扫码后的文字）
      postMindmapOcrScan(
        formData,
        (res) => {
          let text = res.content;
          if (text.length == 0) {
            this.delegate.$message.error(
              getString(strings.Mind_Group_The_Content_Cannot_Be_Empty)
            );
            return;
          }
          let type = this.$store.state.ocrScanModelShow.type;
          if (type == ocrScanExtractTypes.ScanExtractionNode) {
            //插入节点内容
            EditMindmapVM.textAppend(text);
          } else if (type == ocrScanExtractTypes.ScanExtractionRemarks) {
            //插入备注内容
            let remarkContent = this.$store.state.remarkContent;
            let remarksText = text;
            if (remarkContent) {
              remarksText = remarkContent+text;
            }
            this.setAddRemarkContent(remarksText);
          }
        },
        (error) => {
          if (error.code == 179) {
            //掃碼次數已用完請充值會員
            let that = this;
            this.$confirm({
              title: getString(strings.Mind_Edit_Upgrade),
              content: getString(
                strings.Recharge_Members_Can_Get_More_Use_Times
              ), //充值会员可获得更多使用次数。
              centered: true,
              maskClosable: true,
              okText: getString(strings.Mind_Go_Now),
              cancelText: getString(strings.Global_Cancel),
              onOk() {
                that.upgradeMember();
              },
              onCancel() {},
            });
          } else {
            this.$message.error(error.desc);
          }
        },
        () => {
          this.commonShipping(false);
        }
      );
    },
    setFindFromLocal() {
      this.setVideoData();
      this.showVideoModel = false;
    },
    //拖拽上传
    onDrag(e) {
      e.stopPropagation();
      e.preventDefault();
    },
    onDrop(e) {
      e.stopPropagation();
      e.preventDefault();
      if (this.$store.state.nodeContentMessage == undefined) {
        this.materialLibraryModel = false;
        this.materialLibraryModulesModel = false;
        this.editMindmapContent.selectAddTargetTips();
        return;
      }

      // console.log(e.dataTransfer.files);
      if (e.dataTransfer.files[0].type.indexOf("image") > -1) {
        let isShow = this.isMemberFunction();
        if (isShow) {
          return;
        }
        this.imgPreview(e.dataTransfer.files);
      } else {
        this.videoPreview(e.dataTransfer.files);
      }
      //
    },
    //拖拽上传
    onDragExplain(e) {
      e.stopPropagation();
      e.preventDefault();
    },
    onDropExplain(e) {
      e.stopPropagation();
      e.preventDefault();
      if (this.$store.state.nodeContentMessage == undefined) {
        this.materialLibraryModel = false;
        this.materialLibraryModulesModel = false;
        this.editMindmapContent.selectAddTargetTips();
        return;
      }

      this.explainPreview(e.dataTransfer.files);
      //
    },
    explainPreview(files) {
      let that = this;
      let read = new FileReader();
      let file = files[0];
      if (LoadLocalXJMind.isLoaclMind() && !LoadLocalXJMind.isCloudStorage()) {
        let resourceFileSize = file.size ? file.size : 1;
        let resoType = ResourceType.fromByStr(file.type);
        let name = file.name;
        let path = file.path
        this.editMindmapContent.setResourceById(
          path,
          resoType,
          resourceFileSize,
          this.nodeId,
          encodeURIComponent(name)
        );
        let localPath = LoadLocalXJMind.getLocalFileRootPath()
        if (localPath != null && localPath.length > 0) {
          this.copyFile(path, localPath, LoadLocalXJMind.getFileName(path, true));
        }
        return
      }
      read.onload = function (data) {
        let res = data.target || data.srcElement;
        if (file.size > 1024 * 1024 * 20) {
          that.$message.error(
            getString(strings.Message_Tips_Share_Explain_Does_Not_Exist)
          );
          that.cancelUploadExplain();

          return false;
        }
        let calculationResource = file.size;

        that.resourceSize = calculationResource.toFixed(2);
        //此处处理附件
        that.resourceName = file.name;
        that.resourceUrl = res;
        that.setUploadResource();
        // console.log(res,file);
      };
      read.readAsDataURL(file);
      //   let calculationResource = file.size;

      //   that.resourceSize = calculationResource.toFixed(2);
      //   //此处处理附件
      //   that.resourceName = file.name;
      //   that.resourceUrl = res;
      //   // that.materialLibraryModel = false;
      // };
    },
    imgPreview(files) {
      let that = this;
      if (LoadLocalXJMind.isLoaclMind() && !LoadLocalXJMind.isCloudStorage()) {
        let path = files[0].path
        let width;
        let height;
        let img = new Image();
        img.setAttribute("crossOrigin", "Anonymouse");
        img.src = HttpServerConfig.getLocalPathPrefix(path, this.$router.appPath)
        console.log("add img path-", path);
        img.onload = () => {
          width = img.width;
          height = img.height;
          console.log("add img-", width, height);
          this.setDomImg({
            imageBase64: path,
            type: "uploadImg",
            width: width,
            height: height,
          });
          setTimeout(() => {
            this.setDomImg({
              imageBase64: null,
              type: "uploadImg",
              width: 0,
              height: 0,
            });
          }, 100);
        };                  
        let localPath = LoadLocalXJMind.getLocalFileRootPath()
        if (localPath != null && localPath.length > 0) {
          that.copyFile(path, localPath, LoadLocalXJMind.getFileName(path, true));
        }
        return
      }
      let read = new FileReader();
      read.readAsDataURL(files[0]);
      read.onload = function () {
        // let userImage = read.result;
        // that.showCropperImgUrl({ img: userImage, type: "uploadImg" });
        that.setUploadImg(read);
        // that.showCropperImg(true);
        that.materialLibraryModel = false;
      };
    },
    videoPreview(files) {
      let that = this;
      let read = new FileReader();
      read.readAsDataURL(files[0]);

      read.onload = function (res) {
        let videoUrl = res.target.result;
        //转Blob
        function blobData() {
          var arr = videoUrl.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
          while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
          }
          return new Blob([u8arr], { type: mime });
        }
        var blob = blobData();
        if (blob.type.indexOf("video") != -1) {
          that.videoUrl = res.target;
          that.showVideoModel = false;
          that.showVideoUrlModel = true;
        } else {
          that.$message.warning("拖拽的文件上传的不是视频");
          return;
        }
      };
    },

    setUploadImg(read) {
      //本地图片上传。
      let userImage = read.result;
      //转Blob
      function blobData() {
        var arr = userImage.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
      }
      var blob = blobData();

      this.spinning = true;
      if (blob.type.indexOf("image/") > -1) {
        let handler;
        //判断是否处在群组编辑页面。
        if (this.$router.history.current.name == "gmap") {
          handler = {
            "X-Type": "gmap-inner-image",
            "X-ID": this.$route.query.id,
          };
        } else {
          handler = {
            "X-Type": "mindmap-inner-image",
            "X-ID": this.$route.query.id,
          };
        }
        postMindmapUploadImage(
          blob,
          (res) => {
            this.spinning = false;
            // this.showCropperImgUrl("");
            let imgUrlData = HttpServerConfig.getMediaSrc(res.imageUrl);
            new imageToStyle().webToBase64(imgUrlData).then((imageBase64) => {
              let width;
              let height;
              let img = new Image();
              img.setAttribute("crossOrigin", "Anonymouse");
              img.src = imgUrlData;
              img.onload = () => {
                width = img.width;
                height = img.height;
                // this.setDomImg({ imageBase64: imgUrlData, type: "storm", width: width, height: height});
                // this.showCropperImg(false)
                // console.log('图片', imgUrlData);
                this.setDomImg({
                  imageBase64: imgUrlData,
                  type: "uploadImg",
                  width: width,
                  height: height,
                });
                setTimeout(() => {
                  this.setDomImg({
                    imageBase64: null,
                    type: "uploadImg",
                    width: 0,
                    height: 0,
                  });
                }, 100);
              };
            });
            // this.showCropperImg(false);
          },
          (error) => {
            this.spinning = false;
            if (error.code == 51) {
              if (!this.editMindmapContent.delegate.storageIsMeberModelAccout) {
                this.editMindmapContent.delegate.storageIsMeberModelAccout = true;
              }
            } else {
              this.$message.error(error.desc);
            }
          },
          null,
          handler
        );
      } else {
        this.spinning = false;
        console.log("file is not a image");
      }
    },
    //上传本地附件
    setUploadResource() {
      //本地图片上传。
      let userResource = this.resourceUrl.result;
      //转Blob
      function blobData() {
        var arr = userResource.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
      }
      var blob = blobData();
      // return;
      this.spinning = true;
      // if (blob.type == "application/msword" || blob.type == "application/pdf" || blob.type == "application/vnd.ms-excel") {

      let resoType = ResourceType.fromByStr(blob.type);
      let sheetId = "";
      if (
        this.$route.query.sheetId == undefined ||
        this.$route.query.sheetId == null
      ) {
        sheetId = "";
      } else {
        sheetId = this.$route.query.sheetId;
      }
      let handler;
      //判断是否处在群组编辑页面。
      if (this.$router.history.current.name == "gmap") {
        handler = {
          "X-Type": "gmap-attachment",
          "X-Name": encodeURIComponent(this.resourceName),
          "X-Id": this.$route.query.id,
          "Sheet-Id": sheetId,
        };
      } else {
        handler = {
          "X-Type": "mindmap-attachment",
          "X-Name": encodeURIComponent(this.resourceName),
          "X-Id": this.$route.query.id,
          "Sheet-Id": sheetId,
        };
      }
      console.log("res-> ", handler);
      postMindmapUploadAttachment(
        blob,
        (res) => {
          // this.showCropperImgUrl("");
          let attachmentUrl = res.attachmentUrl;
          let resourceFileSize = this.resourceSize ? this.resourceSize : 1;
          this.editMindmapContent.setResourceById(
            attachmentUrl,
            resoType,
            resourceFileSize,
            this.nodeId,
            encodeURIComponent(this.resourceName)
          );
          if (this.$tools.isElectron()) {
            let cacheDir = localStorage.getItem("cacheDir");
            // var isMac = /macintosh|mac os x/i.test(navigator.userAgent)
            if (window.require) {
              let orgfilepath;
              let desdirpath = res.attachmentUrl.substring(
                0,
                res.attachmentUrl.lastIndexOf("/")
              );
              let desfilename = res.attachmentUrl.substring(
                res.attachmentUrl.lastIndexOf("/") + 1
              );
              desdirpath =
                desdirpath.indexOf("http") > -1
                  ? desdirpath.replaceAll("://", "/")
                  : desdirpath;
              desdirpath =
                desdirpath.indexOf(":") > -1
                  ? desdirpath.replaceAll(":", "/")
                  : desdirpath;
              orgfilepath = this.localPathResource;
              if (this.$tools.isWindows()) {
                orgfilepath = orgfilepath.replaceAll("/", "\\");
                desdirpath = desdirpath.replaceAll("/", "\\");
                cacheDir = cacheDir.replaceAll("/", "\\");
                desdirpath = cacheDir + "\\" + desdirpath;
              } else {
                orgfilepath = orgfilepath.replaceAll("\\", "/");
                desdirpath = desdirpath.replaceAll("\\", "/");
                cacheDir = cacheDir.replaceAll("\\", "/");
                desdirpath = cacheDir + "/" + desdirpath;
              }
              let localPath = LoadLocalXJMind.getLocalFileRootPath()
              if (localPath != null && localPath.length > 0) {
                this.copyFile(orgfilepath, localPath, LoadLocalXJMind.getFileName(orgfilepath, true));
              }
              this.copyFile(orgfilepath, desdirpath, desfilename);
            }
          }
          this.spinning = false;
          this.cancelUploadExplain();
        },
        (error) => {
          this.cancelUploadExplain();
          this.spinning = false;
          if (error.code == 51) {
            if (!this.editMindmapContent.delegate.storageIsMeberModelAccout) {
              this.editMindmapContent.delegate.storageIsMeberModelAccout = true;
            }
          } else {
            this.$message.error(error.desc);
          }
        },
        null,
        handler
      );
      // } else {
      //   this.spinning = false;
      //   console.log("file is not a word/pdf/excel");
      // }
    },
    // 增加文件夹
    createFolder(dirpath, dirname) {
      var fs = window.require("fs");
      var path = window.require("path");

      if (typeof dirname === "undefined") {
        if (fs.existsSync(dirpath)) {
        } else {
          this.createFolder(dirpath, path.dirname(dirpath));
        }
      } else {
        if (dirname !== path.dirname(dirpath)) {
          this.createFolder(dirpath);
          return;
        }
        if (fs.existsSync(dirname)) {
          fs.mkdirSync(dirpath, { recursive: true });
        } else {
          this.createFolder(dirname, path.dirname(dirname));
          fs.mkdirSync(dirpath, { recursive: true });
        }
      }
    },

    // 复制文件
    copyFile(orgfilepath, desdirpath, desfilename) {
      var fs = window.require("fs");
      var path = window.require("path");

      if (fs.existsSync(orgfilepath)) {
        let desfilepath = path.join(desdirpath, desfilename);
        if (!fs.existsSync(desfilepath)) {
          this.createFolder(desdirpath);
          fs.copyFileSync(orgfilepath, desfilepath);
        } else {
          console.error(
            Date().toString() +
              "FolderAndFileOperation_copyFile: des file already existed1." +
              " new path: " +
              desfilepath.toString()
          );
        }
      } else {
        console.error(
          Date().toString() +
            "FolderAndFileOperation_copyFile: org file not existed2." +
            " org path: " +
            orgfilepath.toString()
        );
      }
    },
    //上传的视频缩略图
    setUploadVideoPictures() {
      let videoText = document.getElementById("video");
      let time = videoText.duration;
      if (time > 120) {
        this.$message.error(
          getString(strings.Message_Tips_Video_Upload_Time_Limit)
        );
        return;
      }

      var canvas = document.createElement("canvas");

      videoText.currentTime = 5;

      canvas.width = videoText.videoWidth * 0.8;
      canvas.height = videoText.videoHeight * 0.8;
      canvas
        .getContext("2d")
        .drawImage(videoText, 0, 0, canvas.width, canvas.height);

      let userImage = canvas.toDataURL("image/png");

      //转Blob
      function blobData() {
        var arr = userImage.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
      }
      var blob = blobData();
      if (LoadLocalXJMind.isLoaclMind() && !LoadLocalXJMind.isCloudStorage()) {
        if (this.videoFile == null || this.videoFile.path == null) {
          that.setShowNodeId(null);
          VideoStatusData.status = VideoStatus.NORMAL;
          VideoStatusData.totalSize = 0;
          VideoStatusData.uploadSize = 0;
          that.videoUrl = "";
          that.videoFile = null;
          that.showVideoUrlModel = false;
          that.$el.querySelector(".hiddenInputVideo").value = null;
        }
        let videoPath = this.videoFile.path
        let nodeImageLocalPath = LoadLocalXJMind.getLocalFileRootPath() + MindMapZipConstant.FileSeparator + md5(Util.getCurrentTime())
        if (blob.type.indexOf("png") >= -1) {
          nodeImageLocalPath = nodeImageLocalPath + ".png"
        } else {
          nodeImageLocalPath = nodeImageLocalPath + ".jpg"
        }
        let nodeViodeLocalVideoPath = LoadLocalXJMind.getLocalFileRootPath() + MindMapZipConstant.FileSeparator + md5(Util.getCurrentTime()) + "." + Util.getUrlType(videoPath)
        
        let localPath = LoadLocalXJMind.getLocalFileRootPath() + MindMapZipConstant.FileSeparator + md5(nodeImageLocalPath) + "." + Util.getUrlType(nodeImageLocalPath)
        let localVideoPath = LoadLocalXJMind.getLocalFileRootPath() + MindMapZipConstant.FileSeparator + md5(nodeViodeLocalVideoPath) + "." + Util.getUrlType(videoPath)

        let videoPicturesFileReader = new FileReader()
        videoPicturesFileReader.readAsArrayBuffer(blob)
        videoPicturesFileReader.onload = function (e){ 
            let buf = e.target.result
            let data = new Int8Array(buf)
            SaveMindMapToLocal.saveLocalContent(localPath, "", data)
        };
        let videoFileReader = new FileReader()
        videoFileReader.readAsArrayBuffer(this.videoFile)
        videoFileReader.onload = function (e){ 
            let buf = e.target.result
            let data = new Int8Array(buf)
            SaveMindMapToLocal.saveLocalContent(localVideoPath, "", data)
        };
        let that = this;
        let a = new FileReader()
        a.readAsDataURL(blob)
        a.onload = function (e){        
          let base64 = e.target.result
          let width;
          let height;
          let img = new Image();
          img.setAttribute("crossOrigin", "Anonymouse");
          img.src = base64

          that.setShowNodeId(null);
          VideoStatusData.status = VideoStatus.NORMAL;
          VideoStatusData.totalSize = 0;
          VideoStatusData.uploadSize = 0;
          that.videoUrl = "";
          that.videoFile = null;
            
          img.onload = () => {
            width = img.width;
            height = img.height;

            that.setDomVideo({
              videoBase64: nodeViodeLocalVideoPath,
              imageBase64: nodeImageLocalPath,
              id: that.nodeId,
              type: "uploadImg",
              width: width,
              height: height,
            });
            setTimeout(() => {
              that.setDomVideo({
                videoBase64: null,
                imageBase64: null,
                type: "uploadImg",
                id: -1,
                width: 0,
                height: 0,
              });
            }, 100);
            that.showVideoUrlModel = false;
            that.$el.querySelector(".hiddenInputVideo").value = null;
          }; 
        }
        return
      }

      this.spinning = true;
      if (blob.type.indexOf("image/") > -1) {
        let handler;
        //判断是否处在群组编辑页面。
        if (this.$router.history.current.name == "gmap") {
          handler = {
            "X-Type": "gmap-inner-image",
            "X-ID": this.$route.query.id,
          };
        } else {
          handler = {
            "X-Type": "mindmap-inner-image",
            "X-ID": this.$route.query.id,
          };
        }
        postMindmapUploadImage(
          blob,
          (res) => {
            this.spinning = false;
            // this.showCropperImgUrl("");
            this.showVideoUrlModel = false;
            // this.videoUrl = "";
            let imgUrlData = HttpServerConfig.getMediaSrc(res.imageUrl);

            new imageToStyle().webToBase64(imgUrlData).then((imageBase64) => {
              let width;
              let height;
              let img = new Image();
              img.setAttribute("crossOrigin", "Anonymouse");
              img.src = imgUrlData;
              img.onload = () => {
                width = img.width;
                height = img.height;
                this.setDomVideo({
                  videoBase64: this.localPath,
                  imageBase64: imgUrlData,
                  id: this.nodeId,
                  type: "uploadImg",
                  width: width,
                  height: height,
                });
                setTimeout(() => {
                  this.setDomVideo({
                    videoBase64: null,
                    imageBase64: null,
                    type: "uploadImg",
                    id: -1,
                    width: 0,
                    height: 0,
                  });
                }, 100);
              };
            });
            this.setUploadVideo(imgUrlData);
          },
          (error) => {
            this.spinning = false;
            this.showVideoUrlModel = false;
            this.videoUrl = "";
            this.videoFile = null;
            this.$message.error(error.desc);
          },
          null,
          handler
        );
      } else {
        this.spinning = false;
        this.showVideoUrlModel = false;
        this.videoUrl = "";
        this.videoFile = null;
        console.log("file is not a img");
      }
    },

    setUploadVideo(imgUrl) {
      //本地视频上传。
      let userVideo = this.videoUrl.result;
      //转Blob
      function blobData() {
        var arr = userVideo.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
      }
      var blob = blobData();
      this.spinning = true;
      if (blob.type.indexOf("video/") > -1) {
        let handler;
        //判断是否处在群组编辑页面。
        let sheetId = this.$route.query.sheetId;
        if (sheetId == null || sheetId == undefined) {
          sheetId = "";
        }

        if (this.$router.history.current.name == "gmap") {
          handler = {
            "X-Type": "gmap-attachment",
            "X-Name": encodeURIComponent(this.videoName),
            "X-Id": this.$route.query.id,
            "Sheet-Id": sheetId,
          };
        } else {
          handler = {
            "X-Type": "mindmap-attachment",
            "X-Name": encodeURIComponent(this.videoName),
            "X-Id": this.$route.query.id,
            "Sheet-Id": sheetId,
          };
        }
        postMindmapUploadAttachment(
          blob,
          (res) => {
            this.spinning = false;
            let videoUrlData = HttpServerConfig.getMediaSrc(res.attachmentUrl);
            this.setShowNodeId(null);
            VideoStatusData.status = VideoStatus.NORMAL;
            VideoStatusData.totalSize = 0;
            VideoStatusData.uploadSize = 0;
            this.videoUrl = "";
            this.videoFile = null;
            new imageToStyle().webToBase64(imgUrl).then((imageBase64) => {
              let width;
              let height;
              let img = new Image();
              img.setAttribute("crossOrigin", "Anonymouse");
              img.src = imgUrl;
              img.onload = () => {
                width = img.width;
                height = img.height;
                this.setDomVideo({
                  videoBase64: videoUrlData,
                  imageBase64: imgUrl,
                  type: "uploadVideo",
                  id: this.nodeId,
                  width: width,
                  height: height,
                });
                setTimeout(() => {
                  this.setDomVideo({
                    videoBase64: null,
                    imageBase64: null,
                    type: "uploadVideo",
                    id: this.nodeId,
                    width: 0,
                    height: 0,
                  });
                }, 100);
              };
            });
            // this.showCropperImg(false);
            this.$el.querySelector(".hiddenInputVideo").value = null;
          },
          (error) => {
            // console.log(error);
            var mesage = "";
            if (error != null && error.desc != "" && error.desc != null) {
              mesage = error.desc;
            } else {
              mesage = getString(strings.Upload_Failed_Title_Message);
            }

            this.spinning = false;
            this.cancelUploadVideo();
            this.$confirm({
              title: getString(strings.Upload_Failed),
              content: mesage,
              centered: true,
              cancelButtonProps: {
                props: { disabled: true },
              },
              okText: getString(strings.Mind_Edit_Confirm),
              cancelText: getString(strings.Mind_Group_Cancel),
              onOk() {
                EditMindmapVM.removeVideo();
              },
              onCancel() {},
            });
          },
          null,
          handler,
          (percent, loaded, total) => {
            VideoStatusData.status = VideoStatus.UPLOAD;
            VideoStatusData.totalSize = total;
            VideoStatusData.uploadSize = loaded;
            this.setShowVideoUpload(percent);
          }
        );
      } else {
        this.spinning = false;
        console.log("file is not a video");
      }
    },
    cancelUploadVideo() {
      this.showVideoUrlModel = false;
      this.videoUrl = "";
      this.videoFile = null;
      this.$el.querySelector(".hiddenInputVideo").value = null;
      (this.showVideoModel = false), (this.videoName = ""); //视频名称
      this.localPath = ""; //本地地址
      this.videoSize = 0; //视频大小
      this.setShowNodeId(null);
    },
    cancelUploadExplain() {
      this.showResourceModel = false;
      this.resourceUrl = "";
      this.$el.querySelector(".hiddenInputResource").value = null;
      this.resourceName = ""; //视频名称
      this.localPathResource = ""; //本地地址
      this.resourceSize = 0; //视频大小
      this.setShowNodeId(null);
    },

    //搜索焦点
    isShowRecommendSelect() {},
    //搜索词
    onSearch() {
      if (this.searchText == "") {
        this.materialTitleIndex = 0;
        this.postMaterialMyMaterials();
      } else {
        this.checkTitle({ name: "", childName: "", isSearch: true });
        this.materialSearch({ query: this.searchText, page: 1 }).then((res) => {
          this.pageIndex = 1;
          for (let i = 0; i < res.materials.length; i++) {
            const cover = res.materials[i].cover;
            res.materials[i].cover = httpImageToPrefix(cover);
          }
          this.materialContentList = res.materials;
          this.setMaterialLocal = false;
        });
      }
    },
    materialSearch(obj) {
      //获取我的素材
      let that = this;
      return new Promise(function (resolve, reject) {
        postMaterialSearch(
          obj,
          (res) => {
            resolve(res);
          },
          (error) => {
            that.$message.error(error.desc);
            that.spinning = false;
            reject(error);
          }
        );
      });
    },

    MyMaterials(obj) {
      //获取我的素材
      let that = this;
      obj.type =
        obj.type == materialLibraryType.InsertBgImage
          ? materialLibraryType.Nbg
          : obj.type; //保存图片插入背景图使用节点背景
      return new Promise(function (resolve, reject) {
        postMaterialMyMaterials(
          obj,
          (res) => {
            resolve(res);
          },
          (error) => {
            that.$message.error(error.desc);
            that.spinning = false;
            reject(error);
          }
        );
      });
    },

    //获取我的素材
    postMaterialMyMaterials(myMaterialFlag = false) {
      this.checkTitle({
        name: getString(strings.Mind_Edit_Mine),
        childName: getString(strings.Mind_Edit_My_Material),
      });
      this.MyMaterials({ page: 1, type: this.nodeType }).then((res) => {
        this.pageIndex = 1;
        this.materialTitleList = res;
        this.materialTitleList.tags.unshift(getString(strings.Mind_Edit_Mine));

        if (res.materials != null) {
          for (let i = 0; i < res.materials.length; i++) {
            const cover = res.materials[i].cover;
            res.materials[i].cover = httpImageToPrefix(cover);
          }
        }
        this.noMyMaterial = true; //为素材库
        this.materialTitleIndex = 0; //素材库标题索引
        this.materialContentList = res.materials;
        this.materialChildTitleIndex = 0;
        this.materialMyEditButton = true; // 显示编辑按钮
        this.setMaterialLocal = true; //添加素材入口
        // if (this.materialContentList.length>0 || myMaterialFlag) {//选中效果 我的素材为空则为最近使用
        //   this.getMaterialChildTitle(0, this.materialChildTitleList[0])
        // }else{
        //   // this.materialChildTitleIndex = 1;
        //   this.getMaterialChildTitle(1, this.materialChildTitleList[1])
        // }
        this.$nextTick(() => {
          let scrollDom = document.getElementById("material-my-content-box");
          if (scrollDom != undefined) {
            scrollDom.removeEventListener("scroll", this.scrollMoreData); //解除绑定
            scrollDom.addEventListener("scroll", this.scrollMoreData, false); //添加绑定
          }
        });
      });
    },
    //使用素材
    materialUse(item) {
      if (!this.executeOnlyOnce) {
        return;
      }
      this.executeOnlyOnce = false;

      if (item.needMember != undefined && item.needMember) {
        var meMessage = localStorage.getItem("meMessage");
        if (meMessage != null && meMessage != undefined && meMessage != "") {
          meMessage = JSON.parse(meMessage);
          if (!meMessage.isMember) {
            //不是会员
            this.isShowShoppingModel(true);
          }
        } else {
          //没有登录
          return;
        }
      }

      
      if (LoadLocalXJMind.isLoaclMind() && !LoadLocalXJMind.isCloudStorage()) {
        this.materialRequestUrl({ id: item.id }).then((res) => {
          let imgUrlData = HttpServerConfig.getMediaSrc(res.url);
          new imageToStyle().webToBase64(imgUrlData).then((imageBase64) => {
            this.materialLibraryModel = false;
            this.materialLibraryModulesModel = false;

            let type = this.$store.state.showMaterialLibraryModulesModel.type;
            if (type == materialLibraryType.InsertBgImage) {
              //保存图片插入背景图
              this.bus.$emit(eventBusMethod.InsertBgImgEvent, { imageBase64 });
              return;
            }

            this.setMaterialImg(null);
            this.setMaterialImg({
              mageBase64: imgUrlData,
              type: "uploadImg",
              width: item.width,
              height: item.height,
            });
          });
        });
      } else {
        let id = item.id;
        const mindMapId = this.$route.query.id; //导图id
        let obj;
        if (this.$router.history.current.name == "gmap") {
          obj = { id: id, gmapId: mindMapId, mindMapId: "" };
        } else {
          obj = { id: id, mindMapId: mindMapId };
        }
        postMaterialUse(
          obj,
          (res) => {
            let imgUrlData = HttpServerConfig.getMediaSrc(res.imageUrl);
            new imageToStyle().webToBase64(imgUrlData).then((imageBase64) => {
              this.materialLibraryModel = false;
              this.materialLibraryModulesModel = false;

              let type = this.$store.state.showMaterialLibraryModulesModel.type;
              if (type == materialLibraryType.InsertBgImage) {
                //保存图片插入背景图
                this.bus.$emit(eventBusMethod.InsertBgImgEvent, { imageBase64 });
                return;
              }

              this.setMaterialImg(null);
              this.setMaterialImg({
                mageBase64: imgUrlData,
                type: "uploadImg",
                width: item.width,
                height: item.height,
              });
            });
          },
          (error) => {
            if (error.code == 51) {
              this.storageIsMeberModelAccout = true;
            } else {
              this.$message.error(error.desc);
            }
          }
        );
      }
    },
    //编辑素材
    materialEdit() {
      this.materialEditButton = !this.materialEditButton;
    },
    //删除素材
    materialDelete(id) {
      postMaterialDelete(
        { id: id },
        (res) => {
          this.$message.success(getString(strings.Message_Tips_Dlt_Success));
          for (let i = 0; i < this.materialContentList.length; i++) {
            const dltDataId = this.materialContentList[i].id;
            if (dltDataId == id) {
              this.materialContentList.splice(i, 1);
            }
          }
        },
        (error) => {
          this.$message.error(error.desc);
        }
      );
    },
    //判断当前节点支持素材类型
    typeTipsIf(type) {
      if (type == null) {
        return;
      }
      if (this.nodeType == "pbb") {
        for (let i = 0; i < type.length; i++) {
          const materialType = type[i];
          if (materialType == materialLibraryType.Bbg) {
            return true;
          }
        }
        return false
      }
      this.nodeType =
        this.nodeType == materialLibraryType.InsertBgImage
          ? materialLibraryType.Nbg
          : this.nodeType; //保存图片插入背景图使用节点背景
      for (let i = 0; i < type.length; i++) {
        const materialType = type[i];
        if (materialType == this.nodeType) {
          return true;
        }
      }
    },
    //判断素材类型
    typeTips(types) {
      switch (this.nodeType) {
        case "img":
          return getString(strings.Cannot_Add_To_Img_Background);
        case "nbg":
          return getString(strings.Cannot_Add_To_Node_Background);
        case "bbg":
        case "pbb":
          return getString(strings.Cannot_Add_To_Mind_Background);
        default:
          return;
      }
    },
    setDardarkMode() {
      // 设置深色模式
      this.$nextTick(() => {
        let boxDom = this.$refs.mapInsert;
        let dardarkMode = this.$store.state.mapEditIsDardarkMode;
        if (boxDom) {
          if (!dardarkMode.darkMode) {
            boxDom.style.color = "#fff";//dardarkMode.fontColor;
            boxDom.style.background = "#3E3E3E";//dardarkMode.bgColor;
          } else {
            boxDom.style.color = "#333";
            boxDom.style.background = "#fff";
          }
        }
      });
    },
  },
  watch: {
    materialLibraryModulesModel(newIsShowModelAccout) {
      if (newIsShowModelAccout) {
        this.postMaterialMyMaterials();
        this.executeOnlyOnce = true;
      } else {
        this.setmaterialLibraryModulesModel({ accout: false, type: "img" });
      }
    },
    videoUrl(newV) {},

    "$store.state.windowWidth": {
      handler(newVal) {
        if (newVal > 1235) {
          this.imgDataList = [
            {
              imgDataAf: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/add_icon_af.svg"),
              imgDataText: strings.Mind_Edit_Insert_Icon,
              imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/addIconWhite.svg"),
              imgDataAccout: "ADD_ICON",
            },
            {
              imgDataAf: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/add_remark_af.svg"),
              imgDataText: strings.Mind_Edit_Insert_Remarks,
              imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/addRemarkWhite.svg"),
              imgDataAccout: "ADD_Remark",
            },
            {
              imgDataAf: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/add_insert_link_af.svg"),
              imgDataText: strings.Mind_Edit_Insert_Link,
              imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/insertLinkWhite.svg"),
              imgDataAccout: "ADD_Link",
            },
            {
              imgDataAf: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/add_chart_icon.svg"),
              imgDataText: strings.Mind_Edit_Insert_Summary_Graph,
              imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/addChartWhite.svg"),
              imgDataAccout: "ADD_Chart",
            },

            {
              imgDataAf: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/add_subScript_icon.svg"),
              imgDataText: strings.Mind_Edit_Subscript,
              imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/addSubScriptWhite.svg"),
              imgDataAccout: "ADD_Subscript",
            },
            {
              imgDataAf: require("../../../assets/img/canvasMenu/association_map.svg"),
              imgDataText: strings.Mind_Association_Map,
              needMember: true,
              imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/associationMapWhite.svg"),
              imgDataAccout: "ADD_Association",
            },
            {
              imgDataAf: require("../../../assets/img/canvasMenu/editLatex.svg"),
              imgDataText: strings.Mind_Edit_Latex,
              imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/editLatexWhite.svg"),
              needMember: true,
              imgDataAccout: "ADD_Latex",
            },
            {
              imgDataAf: require("../../../assets/img/mindNewEditMenuIocn/freeNode.svg"),
              imgDataText: strings.Mind_Edit_Free_Node,
              imgWhite: require("../../../assets/img/mindNewEditMenuIocn/darkMode/freeNode.svg"),
              imgDataAccout: "ADD_FREENODE",
            },
          ]; //菜单icon
        } else if (newVal <= 1235 && newVal > 1000) {
          this.imgDataList = [
            {
              imgDataAf: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/add_icon_af.svg"),
              imgDataText: strings.Mind_Edit_Insert_Icon,
              imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/addIconWhite.svg"),
              imgDataAccout: "ADD_ICON",
            },
            {
              imgDataAf: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/add_remark_af.svg"),
              imgDataText: strings.Mind_Edit_Insert_Remarks,
              imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/addRemarkWhite.svg"),
              imgDataAccout: "ADD_Remark",
            },
            {
              imgDataAf: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/add_insert_link_af.svg"),
              imgDataText: strings.Mind_Edit_Insert_Link,
              imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/insertLinkWhite.svg"),
              imgDataAccout: "ADD_Link",
            },
            {
              imgDataAf: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/add_chart_icon.svg"),
              imgDataText: strings.Mind_Edit_Insert_Summary_Graph,
              imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/addChartWhite.svg"),
              imgDataAccout: "ADD_Chart",
            },

            {
              imgDataAf: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/add_subScript_icon.svg"),
              imgDataText: strings.Mind_Edit_Subscript,
              imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/addSubScriptWhite.svg"),
              imgDataAccout: "ADD_Subscript",
            },

            {
              imgDataAf: require("../../../assets/img/mindNewEditMenuIocn/freeNode.svg"),
              imgDataText: strings.Mind_Edit_Free_Node,
              imgWhite: require("../../../assets/img/mindNewEditMenuIocn/darkMode/freeNode.svg"),
              imgDataAccout: "ADD_FREENODE",
            },

            {
              imgDataAf: require("../../../assets/img/mindNewEditMenuIocn/floatExplain.svg"),
              imgDataText: strings.Mind_Float_Explain,
              imgWhite: require("../../../assets/img/mindNewEditMenuIocn/darkMode/floatExplain.svg"),
              imgDataAccout: "ADD_EXPLAIN",
            },

            {
              imgDataAf: require("../../../assets/img/mindNewEditMenuIocn/digest.svg"),
              imgDataText: strings.Mind_Edit_Summary,
              imgWhite: require("../../../assets/img/mindNewEditMenuIocn/darkMode/digest.svg"),

              imgDataAccout: "ADD_SUMMARY",
            },
            {
              imgDataAf: require("../../../assets/img/canvasMenu/association_map.svg"),
              imgDataText: strings.Mind_Association_Map,
              needMember: true,
              imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/associationMapWhite.svg"),
              imgDataAccout: "ADD_Association",
            },
            {
              imgDataAf: require("../../../assets/img/canvasMenu/editLatex.svg"),
              imgDataText: strings.Mind_Edit_Latex,
              imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/editLatexWhite.svg"),
              needMember: true,
              imgDataAccout: "ADD_Latex",
            },
          ]; //菜单icon
        } else {
          this.imgDataList = [
            {
              imgDataAf: require("../../../assets/img/mindNewEditMenuIocn/floatExplain.svg"),
              imgDataText: strings.Mind_Float_Explain,
              imgWhite: require("../../../assets/img/mindNewEditMenuIocn/darkMode/floatExplain.svg"),
              imgDataAccout: "ADD_EXPLAIN",
            },
            {
              imgDataAf: require("../../../assets/img/mindNewEditMenuIocn/digest.svg"),
              imgDataText: strings.Mind_Edit_Summary,
              imgWhite: require("../../../assets/img/mindNewEditMenuIocn/darkMode/digest.svg"),

              imgDataAccout: "ADD_SUMMARY",
            },
            {
              imgDataAf: require("../../../assets/img/mindNewEditMenuIocn/blocking.svg"),
              imgDataText: strings.Mind_Edit_Block,
              imgWhite: require("../../../assets/img/mindNewEditMenuIocn/darkMode/blocking.svg"),

              imgDataAccout: "ADD_BLOCK",
            },

            {
              imgDataAf: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/add_icon_af.svg"),
              imgDataText: strings.Mind_Edit_Insert_Icon,
              imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/addIconWhite.svg"),
              imgDataAccout: "ADD_ICON",
            },
            {
              imgDataAf: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/add_remark_af.svg"),
              imgDataText: strings.Mind_Edit_Insert_Remarks,
              imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/addRemarkWhite.svg"),
              imgDataAccout: "ADD_Remark",
            },
            {
              imgDataAf: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/add_insert_link_af.svg"),
              imgDataText: strings.Mind_Edit_Insert_Link,
              imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/insertLinkWhite.svg"),
              imgDataAccout: "ADD_Link",
            },
            {
              imgDataAf: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/add_chart_icon.svg"),
              imgDataText: strings.Mind_Edit_Insert_Summary_Graph,
              imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/addChartWhite.svg"),
              imgDataAccout: "ADD_Chart",
            },

            {
              imgDataAf: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/add_subScript_icon.svg"),
              imgDataText: strings.Mind_Edit_Subscript,
              imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/addSubScriptWhite.svg"),
              imgDataAccout: "ADD_Subscript",
            },

            {
              imgDataAf: require("../../../assets/img/mindNewEditMenuIocn/freeNode.svg"),
              imgDataText: strings.Mind_Edit_Free_Node,
              imgWhite: require("../../../assets/img/mindNewEditMenuIocn/darkMode/freeNode.svg"),
              imgDataAccout: "ADD_FREENODE",
            },
            {
              imgDataAf: require("../../../assets/img/canvasMenu/association_map.svg"),
              imgDataText: strings.Mind_Association_Map,
              needMember: true,
              imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/associationMapWhite.svg"),
              imgDataAccout: "ADD_Association",
            },
            {
              imgDataAf: require("../../../assets/img/canvasMenu/editLatex.svg"),
              imgDataText: strings.Mind_Edit_Latex,
              imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/editLatexWhite.svg"),
              needMember: true,
              imgDataAccout: "ADD_Latex",
            },
          ]; //菜单icon
        }
      },
      immediate: true, //在一开是绑定的时候执行
    },

    "$store.state.showMateriaModal": {
      handler(newVal) {
        if (newVal == true) {
          this.setImgDataOrMaterial();
        } else {
          this.materialLibraryModel = false;
        }
      },
      // immediate: true,    //在一开是绑定的时候执行
      deep: true, //深度监听
    },
    "$store.state.ocrScanModelShow.value": {
      //是否展示ocr扫描模态框
      handler(newVal) {
        if (newVal) {
          this.ocrScanModel = true;
          this.getOcrScanAvailableTimes();
          this.$nextTick(() => {
            let upload = document.getElementById("import-Ocr-Scan-Img");
            if (upload) {
              upload.addEventListener("dragenter", this.onDrag, false);
              upload.addEventListener("dragover", this.onDrag, false);
              upload.addEventListener("drop", this.onDropOcrScanImg, false);
            }
          });
          this.$store.state.ocrScanModelShow.value = false; //重置
        }
      },
      deep: true,
    },
    "$store.state.showResourceModal": {
      handler(newVal) {
        if (newVal == true) {
          this.setResource();
        } else {
          this.showResourceModel = false;
        }
      },
      // immediate: true,    //在一开是绑定的时候执行
      deep: true, //深度监听
    },
    "$store.state.showMateriaVideoModal": {
      handler(newVal) {
        if (newVal == true) {
          this.setVideo();
        } else {
          this.showVideoModel = false;
        }
      },
      // immediate: true,    //在一开是绑定的时候执行
      deep: true, //深度监听
    },
    "$store.state.showMaterialLibraryModulesModel": {
      handler(newVal) {
        if (newVal.accout == true) {
          this.nodeType = newVal.type;
          this.getMaterialChildTitle(
            0,
            getString(strings.Mind_Edit_My_Material)
          );
          this.materialLibraryModulesModel = true;
        } else {
          this.materialLibraryModulesModel = false;
        }
      },
      immediate: true, //在一开是绑定的时候执行
      deep: true, //深度监听
    },
    "$store.state.showEchartModal"(showEcahrt) {
      if (showEcahrt == null) {
        return;
      }
      this.chartModel = showEcahrt;
    },
    "$store.state.mapEditIsDardarkMode": {
      //导图编辑页面是否为深色模式
      handler(newVal, oldVal) {
        this.setDardarkMode();
      },
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
@font-face {
  font-family: "cleariconfont";
  src: url("../../../assets/font-icon/font_ik9g4nleyx/iconfont.eot");
  src: url("../../../assets/font-icon/font_ik9g4nleyx/iconfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../../assets/font-icon/font_ik9g4nleyx/iconfont.woff2")
      format("woff2"),
    url("../../../assets/font-icon/font_ik9g4nleyx/iconfont.woff")
      format("woff"),
    url("../../../assets/font-icon/font_ik9g4nleyx/iconfont.ttf")
      format("truetype"),
    url("../../../assets/font-icon/font_ik9g4nleyx/iconfont.svg#iconfont")
      format("svg");
}
.cleariconfont {
  font-family: "cleariconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hiddenInput {
  display: none;
}
.hiddenInputMaterial {
  display: none;
}
.hiddenInputVideo {
  display: none;
}
.hiddenInputResource {
  display: none;
}

.ComplieHeaderImg-content {
  // position: relative;
  //本地
  .ComplieHeaderImg {
    width: 198px;
    min-height: 216px;
    max-height: 500px;
    overflow-y: auto;
    background: #ffffff;
    border: 1px solid var(--exportMenuDashedColor);
    opacity: 1;
    border-radius: 8px;
    position: absolute;
    // top: 10px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 100;
    &::-webkit-scrollbar {
      width: 0;
      // display: none;
    }
    &::-webkit-scrollbar-track {
      background: rgb(239, 239, 239);
      border-radius: 2px;
    }
    &::-webkit-scrollbar-thumb {
      background: #bfbfbf;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #bfbfbf;
    }
    .complie-header-imgbox {
      margin: 5px 25px;
      .complie-com-box {
        // width: 152px;
        height: 44px;
        border-bottom: 1px dashed var(--exportMenuDashedColor);
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;
        .complie-left-icon {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-left: 6px;
        }
        .complie-right-text {
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 24px;
        }
        .complie-right-text-before {
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          color: #fd492b;
          opacity: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 24px;
        }
        .need-member {
          position: absolute;
          top: 50%;
          right: -12px;
          transform: translateY(-50%);
        }
        &:last-child {
          border-bottom: transparent;
        }
        &:hover {
          background-color: var(--exportMenuBg);
        }
      }
    }
    // .complie-header-imgbox:last-child {
    //   border-bottom: transparent;
    // }
  }
}

//素材库
.material-content {
  width: 100%;
  height: 200px;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .insert-picture-text {
    color: #cccccc;
    margin-top: 10px;
  }
  .available-times-box {
    display: flex;
    margin-top: 10px;
    .available-times-title {
      font-size: 14px;
      color: #666666;
    }
    .upgrade-member-img {
      margin-left: 10px;
      cursor: pointer;
    }
  }
}
.material-content-vi {
  width: 100%;
  height: 200px;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.material-content-resource {
  width: 100%;
  height: 200px;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-box-direction: normal;
  flex-direction: column;
  .insert-picture-text {
    color: #cccccc;
    margin-top: 10px;
  }
}
.safari-material-content-vi {
  margin-top: -80px;
}
.other-material-content-vi {
  margin-top: -130px;
}
.safari-video-title {
  margin-top: 50px;
}
.other-video-title {
  margin-top: 100px;
}
//视频预览
.material-content-video {
  width: 820px;
  height: 500px;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  // overflow: hidden;
}
.video-size {
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  margin-top: 12px;
}

//素材库

.material-modules {
  .left-search {
    width: 420px;
    height: 50px;
    padding-right: 20px;
    // display: flex;
    // flex-direction: row-reverse;
    // align-items: center;
    position: relative;
    .clear-search-text-icon {
      display: block;
      position: absolute;
      top: 0;
      // transform: translate(0,-50%);
      font-size: 18px;
      left: 361px;
      height: 40px;
      line-height: 42px;
      color: #bbb;
      cursor: pointer;
      &:hover {
        color: #ff7354;
      }
      &:active {
        color: #d6301a;
      }
    }

    .search-button {
      position: absolute;
      margin-left: 10px;
      margin-top: 4px;
      border: 0px;
      background: none;
    }

    .ant-btn:hover,
    .ant-btn:focus {
      background: #fd492b;
      color: #fff;
      opacity: 1;
      border: none;
    }
    .search-box {
      width: 100%;
      border-radius: 20px;
      border: transparent;
      background: #f0f2f8;
      margin-right: 10px;
      height: 40px;
      padding-left: 45px;
    }
    .search-box:focus {
      outline: none;
    }
  }

  .material-main-content {
    display: flex;
    flex-wrap: wrap;
    //标题
    .material-title {
      margin: 16px 0;
      width: 100%;
      display: flex;
      ul {
        width: 687px;
        overflow: hidden;
        display: -webkit-box;
        padding: 0 0 5px 0;
        height: 35px;
      }
      ul:hover {
        overflow-x: auto;
        .active {
          margin-bottom: -5px;
          transition: 0.3s;
        }
      }
      //滚动条样式
      ul::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        /**/
      }
      ul::-webkit-scrollbar-track {
        background: rgb(239, 239, 239);
        border-radius: 2px;
      }
      ul::-webkit-scrollbar-thumb {
        background: #bfbfbf;
        border-radius: 5px;
      }
      ul::-webkit-scrollbar-thumb:hover {
        background: #bfbfbf;
      }
      li {
        margin-right: 40px;
        cursor: pointer;
        span {
          font-size: 16px;
          font-family: Noto Sans SC;
          font-weight: 400;
          line-height: 22px;
          color: #333333;
          opacity: 1;
        }
      }
      .active {
        border-bottom: 2px solid #fd492b;
        transition: 0.3s;
        span {
          color: #fd492b;
        }
      }
    }
    //页面主体
    .material-main {
      width: 100%;
      .material-my-title {
        display: flex;
        margin-right: 20px;
        margin-bottom: 12px;
        width: 100%;
        height: 24px;
        transition: 0.3s;
        position: relative;
        .material-my-title-content {
          padding: 1px 11px 3px 12px;
          border-radius: 12px;
          cursor: pointer;
          span {
            font-size: 12px;
            font-family: Noto Sans SC;
            font-weight: 400;
            line-height: 17px;
            color: #333333;
            opacity: 1;
          }
        }
        .active {
          border-radius: 12px;
          background-color: #fd492b;
          color: #ffffff;
          transition: 0.3s;
          span {
            color: #ffffff;
            transition: 0.3s;
          }
        }
        //编辑
        .material-my-edit {
          position: absolute;
          right: 19px;
          cursor: pointer;
          span {
            height: 17px;
            font-size: 12px;
            font-family: Noto Sans SC;
            font-weight: 400;
            line-height: 17px;
            color: #333333;
            opacity: 1;
          }
        }
      }
      //内容
      .material-my-content {
        display: flex;
        flex-wrap: wrap;
        height: 406px;
        overflow: auto;
        //添加本地图片
        #material-my-content-box-child {
          display: flex;
          flex-wrap: wrap;
          overflow: auto;
        }
        .material-my-source {
          width: 160px;
          height: 186px;
          box-shadow: 0px 3px 6px #f9ebeb;
          opacity: 1;
          border-radius: 4px;
          margin-right: 10px;
          margin-bottom: 17px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
        //添加素材
        .material-my-source-content {
          width: 160px;
          height: 186px;
          box-shadow: 0px 3px 6px #f9ebeb;
          opacity: 1;
          border-radius: 4px;
          margin-right: 10px;
          margin-bottom: 17px;
          position: relative;
          padding-top: 3px;
          padding-left: 3px;
          // padding-right: 5px;
          // overflow-x: hidden;
          .subscription-text {
            display: block;
            position: absolute;
            left: -12px;
            top: -11px;
            z-index: 99;
          }
          //素材封面
          .material-my-img {
            width: 160px;
            height: 120px;
            position: relative;
            overflow: hidden;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;

            .material-delete-icon {
              cursor: pointer;
              position: absolute;
              top: 5px;
              right: 5px;
              background-color: #ececec;
              width: 24px;
              height: 24px;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                width: 12px;
                height: 12px;
              }
            }
            .material-cover {
              overflow: hidden;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                transition: 0.2s;
              }
            }
          }
          //素材底部
          .material-my-bottom {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            height: 66px;
            .material-text {
              display: flex;
              justify-content: center;
              width: 100%;
              height: 17px;
              font-size: 12px;
              font-family: Noto Sans SC;
              font-weight: 400;
              line-height: 17px;
              color: #999999;
              opacity: 1;
            }
            .material-button {
              width: 84px;
              height: 24px;
              background: #fd492b;
              opacity: 1;
              border-radius: 12px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              margin-bottom: 8px;
              span {
                // width: 28px;
                height: 20px;
                font-size: 14px;
                font-family: Noto Sans SC;
                font-weight: 400;
                line-height: 20px;
                color: #ffffff;
                opacity: 1;
              }
            }
            .material-tips {
              height: 32px;
              span {
                text-align: center;
                font-size: 12px;
                font-family: Noto Sans SC;
                font-weight: 400;
                line-height: 17px;
                color: #ff8470;
                opacity: 1;
              }
            }
          }
        }
      }
      //滚动条
      .material-my-content::-webkit-scrollbar {
        width: 0;
      }
      //内容为空
      .material-my-content-is-null {
        height: 406px;
        flex-wrap: wrap;
        display: flex;
        justify-content: center;
        align-items: center;
        div {
          p {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
}

//统计图
.chart-main-content {
  //展示类型
  .chart-main-style {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .chart-main-style-content {
      display: flex;
      align-items: center;
      img {
        cursor: pointer;
        width: 89px;
        height: 89px;
      }
    }
  }
}
.video-content-model {
  /deep/.ant-modal-content {
    .ant-modal-body {
      padding: 24px 24px 12px 24px;
    }
    .ant-modal-header {
      background: #e5e4e4;
    }
    .ant-modal-footer {
      border-top: 0px;
    }
  }
}
</style>
