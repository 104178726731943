import Config from "../../../core/core/calcule/Config";
import MindElementCalculation from "../../../core/core/calcule/elementCalculation/MindElementCalculation";
import EncircleNodesPointsCalculation from "../../../core/core/calcule/EncircleNodesPointsCalculation";
import NodeConnectLineCalcul from "../../../core/core/calcule/NodeConnectLineCalcul";
import NodesRectCalculation from "../../../core/core/calcule/NodesRectCalculation";
import DoubleBubbleMindDateFormat from "../../../core/core/dataformat/DoubleBubbleMindDateFormat";
import MindDateFormat from "../../../core/core/dataformat/MindDateFormat";
import TimeLineMindDateFormat from "../../../core/core/dataformat/TimeLineMindDateFormat";
import Colors from "../../../utils/Colors";
import Util from "../../../utils/Util";
import HashMap from "../../core/base/HashMap";
import IdGenerator from "../../core/base/IdGenerator";
import MindMapStyleColor from "../../core/base/MindMapStyleColor";
import RectsMergeCalculation from "../../core/layout/RectsMergeCalculation";
import SettingData from "../../core/minddata/SettingData";
import LineLayout from "../../datatype/LineLayout";
import MindBGStripesType from "../../datatype/MindBGStripesType";
import MindDisplayType from "../../datatype/MindDisplayType";
import MindElementType from "../../datatype/MindElementType";
import MindType from "../../datatype/MindType";
import NodeLayoutType from "../../datatype/NodeLayoutType";
import DoubleBubbleMindNodeUnit from "../DoubleBubbleMindNodeUnit";
import LineMindTypeNode from "../LineMindTypeNode";
import LineMindTypeNodeUnit from "../LineMindTypeNodeUnit";
import TimeMindTypeNodeUnit from "../TimeMindTypeNodeUnit";

class GroupMindMapData {

    constructor(mindType, serverId, sheetId) {
        this.isEditMap = true;
        this.isAutoUpdataMap = false;
        this.mainMindNodeUnit;
        this.freeNodes = [];
        this.timeMindTypeNodeUnit = new TimeMindTypeNodeUnit();
        this.doubleBubbleMindNodeUnit = new DoubleBubbleMindNodeUnit(MindType.DOUBLE_BUBBLE_MAP, 0);
        this.nodeConnectLineDataDict = new HashMap(); //节点连接线
        this.removeDatas = []
        this.mindMapStyleColor = new MindMapStyleColor(3);
        this.globalLayout = NodeLayoutType.LAYOUT_RIGHT;
        this.globalLineLayout = LineLayout.CURVE_LINE_2;
        this.mindBGStripesType = MindBGStripesType.NonExistent;
        this.mindType = mindType;
        this.mindGlobalAudio = "";
        this.mindBGColor = Colors.white;
        this.mindkey = "";
        this.mindDisplayType = MindDisplayType.MindMap;
        this.settingData = new SettingData();
        this.serverId = serverId
        this.sheetId = sheetId
        this.init()
    }

    init() {
        if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.FLOW_CHART || this.mindType == MindType.BUBBLE_MAP) {
            this.mainMindNodeUnit = new LineMindTypeNodeUnit(this.mindBGColor, this.mindType, this.mindMapStyleColor.currentSelectedIndex, false);
        } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            this.doubleBubbleMindNodeUnit = new DoubleBubbleMindNodeUnit(this.mindType, this.mindMapStyleColor.currentSelectedIndex);
        }
    }

    merge(target, lastData) {
        this.removeDatas = [];
        this.mergeBaseData(target, lastData);
        this.mergeSettingData(target, lastData);
        this.mergeDoubleBubbleMindNodeUnit(this.doubleBubbleMindNodeUnit, target.doubleBubbleMindNodeUnit, lastData.doubleBubbleMindNodeUnit);
        this.mergeTimeMindTypeNodeUnit(this.timeMindTypeNodeUnit, target.timeMindTypeNodeUnit, lastData.timeMindTypeNodeUnit);
        this.mergeLineMindTypeNodeUnit(this.mainMindNodeUnit, target.mainMindNodeUnit, lastData.mainMindNodeUnit);
        this.mergeNodeConnectLineDataDict(this.nodeConnectLineDataDict, target.nodeConnectLineDataDict, lastData.nodeConnectLineDataDict,
                this.mainMindNodeUnit, target.mainMindNodeUnit, lastData.mainMindNodeUnit);
        var serverAddList = [];
        var serverDelList = [];
        
        for (let index = 0; index < target.freeNodes.length; index++) {
            const cell = target.freeNodes[index];
            if (cell.rootTreeNode == null || cell.rootTreeNode.isEmpty()) {
                continue;
            }
            
            const serverAdd = this.getNodeById(cell.rootTreeNode.value.id).isEmpty() && lastData.getNodeById(cell.rootTreeNode.value.id).isEmpty();
            if (serverAdd) {
                serverAddList.push(cell);
            }
        }
        for (let index = 0; index < this.freeNodes.length; index++) {
            const cell = this.freeNodes[index];
            if (cell.rootTreeNode == null || cell.rootTreeNode.isEmpty()) {
                continue;
            }
            const serverDel = target.getNodeById(cell.rootTreeNode.value.id).isEmpty() && !lastData.getNodeById(cell.rootTreeNode.value.id).isEmpty();
            if (serverDel) {
                serverDelList.push(cell);
            }
        }
        for (let index = 0; index < serverAddList.length; index++) {
            const cell = serverAddList[index];
            this.freeNodes.push(cell);
            let targetRoot = null;
            let dataRoot = null;
            if (this.mainMindNodeUnit.rootTreeNode != null && target.mainMindNodeUnit.rootTreeNode != null &&
                    !target.mainMindNodeUnit.rootTreeNode.isEmpty() && !this.mainMindNodeUnit.rootTreeNode.isEmpty()) {
                targetRoot = target.mainMindNodeUnit.rootTreeNode.value;
                dataRoot = this.mainMindNodeUnit.rootTreeNode.value;
            } else if (this.timeMindTypeNodeUnit.title != null && target.timeMindTypeNodeUnit.title != null &&
                    !target.timeMindTypeNodeUnit.title.isEmpty() && !this.timeMindTypeNodeUnit.title.isEmpty()) {
                targetRoot = target.timeMindTypeNodeUnit.title;
                dataRoot = this.timeMindTypeNodeUnit.title;
            } else if (this.doubleBubbleMindNodeUnit.rootTreeNode != null && target.doubleBubbleMindNodeUnit.rootTreeNode != null &&
                    !target.doubleBubbleMindNodeUnit.rootTreeNode.isEmpty() && !this.doubleBubbleMindNodeUnit.rootTreeNode.isEmpty()) {
                targetRoot = target.doubleBubbleMindNodeUnit.rootTreeNode.value;
                dataRoot = this.doubleBubbleMindNodeUnit.rootTreeNode.value;
            }
            if (dataRoot == null || dataRoot.isEmpty() ||
                    targetRoot == null || targetRoot.isEmpty()) {
                continue;
            }
    
            let xStartAndRootContent =  (cell.rootTreeNode.value.x - targetRoot.x + (targetRoot.width) / 2) /  targetRoot.width;
            let yStartAndRootContent =  (cell.rootTreeNode.value.y - targetRoot.y + (targetRoot.height) / 2) /  targetRoot.height;
    
            let x = (xStartAndRootContent *  dataRoot.width) - dataRoot.width / 2 + dataRoot.x;
            let y = (yStartAndRootContent *  dataRoot.height) - dataRoot.height / 2 + dataRoot.y;
    
            cell.rootTreeNode.value.x = x;
            cell.rootTreeNode.value.y = y;
        }
        serverDelList.forEach(cell => {
            for (let index = 0; index < this.freeNodes.length; index++) {
                const cellDel = this.freeNodes[index];
                if (cellDel.rootTreeNode.value.id == cell.rootTreeNode.value.id) {
                    this.freeNodes.splice(index, 1);
                    break;
                }
           }
        })
        this.freeNodes.forEach(cell => {
           let targetUnit = null;
           let lastUnit = null;
           for (let index = 0; index < target.freeNodes.length; index++) {
                const targteCell = target.freeNodes[index];
                if (cell.rootTreeNode.value.id == targteCell.rootTreeNode.value.id) {
                    targetUnit = targteCell;
                    break;
                }
           }
           for (let index = 0; index < lastData.freeNodes.length; index++) {
                const lastCell = lastData.freeNodes[index];
                if (cell.rootTreeNode.value.id == lastCell.rootTreeNode.value.id) {
                    lastUnit = lastCell;
                    break;
                }
           }
           this.mergeLineMindTypeNodeUnit(cell, targetUnit, lastUnit);
        })
     }
  
     mergeTimeMindTypeNodeUnit(data,  target, last) {
        if (data == null || data.title == null || data.title.isEmpty()) {
           return;
        }
        if (target == null || target.title == null || target.title.isEmpty()) {
           return;
        }
        if (last == null || last.title == null || last.title.isEmpty()) {
           return;
        }
        if (!data.title.equal(target.title, true, true, true, true, true) &&
                data.title.equal(last.title, true, true, true, true, true)) {
           data.title.stick(target.title, true);
        }
        if (!data.baseLine.equal(target.baseLine, true, true, true, true, true) &&
                data.baseLine.equal(last.baseLine, true, true, true, true, true)) {
           data.baseLine.stick(target.baseLine, true);
        }
        // mainMindElementDataDict
        let localLineDelList = [];
        let serverLineAddList = [];
  
        let mainMindElementDataDictArray = target.mainMindElementDataDict.keys();
        let mainMindElementDataDictLength = mainMindElementDataDictArray.length

        for (let index = 0; index < mainMindElementDataDictLength; index++) {
            let key = mainMindElementDataDictArray[index];

           let cellData = target.mainMindElementDataDict.get(key);
           let localDel = !data.mainMindElementDataDict.containsKey(key) && last.mainMindElementDataDict.containsKey(key);
           if (localDel) {
              localLineDelList.push(cellData);
              continue;
           }
           let serverAdd = !data.mainMindElementDataDict.containsKey(key) && !last.mainMindElementDataDict.containsKey(key);
           if (serverAdd) {
              serverLineAddList.push(cellData);
           }
        }
  
        let serverLineDelList = [];
        mainMindElementDataDictArray = data.mainMindElementDataDict.keys();
        mainMindElementDataDictLength = mainMindElementDataDictArray.length

        for (let index = 0; index < mainMindElementDataDictLength; index++) {
            let key = mainMindElementDataDictArray[index];
           let cellData = data.mainMindElementDataDict.get(key);
           let serverDel = !target.mainMindElementDataDict.containsKey(key) && last.mainMindElementDataDict.containsKey(key);
           if (serverDel) {
              serverLineDelList.push(cellData);
              continue;
           }
           let targetData = target.mainMindElementDataDict.get(key);
           let lastData = last.mainMindElementDataDict.get(key);
           if (targetData == null || lastData == null || targetData.isEmpty() || lastData.isEmpty()) {
              continue;
           }
           if (!cellData.title.equal(targetData.title, true, true, true, true, true) && cellData.title.equal(lastData.title, true, true, tru, true, truee)) {
              cellData.title.stick(targetData.title);
           }
           if (!cellData.head.equal(targetData.head, true, true, true, true, true) && cellData.head.equal(lastData.head, true, true, true, true, true)) {
              cellData.head.stick(targetData.head);
           }
           if (!cellData.desc.equal(targetData.desc, true, true, true, true, true) && cellData.desc.equal(lastData.desc, true, true, true, true, true)) {
              cellData.desc.stick(targetData.desc);
           }
        }
        if (serverLineAddList.length > 0) {
            serverLineAddList.forEach(cell => {
                data.mainMindElementDataDict.put(cell.id, cell);
            });
        }
        if (serverLineDelList.length > 0) {
            serverLineDelList.forEach(cell => {
              data.mainMindElementDataDict.remove(cell.id);
              this.removeDatas.push(cell.title);
              this.removeDatas.push(cell.head);
              this.removeDatas.push(cell.desc);
           })
        }
     }
  
     mergeNodeConnectLineDataDict(data,  target, last, dataUnit,  targetUnit, lastUnit) {
        if (data == null || target == null || last == null) {
           return;
        }
        let localLineDelList = [];
        let serverLineAddList = [];
  
        let keyArray = target.keys();
        let keyLength = keyArray.length

        for (let index = 0; index < keyLength; index++) {
            let key = keyArray[index];
           let cellData = target.get(key);
           let localDel = !data.containsKey(key) && last.containsKey(key);
           if (localDel) {
              localLineDelList.push(cellData);
              continue;
           }
           let serverAdd = !data.containsKey(key) && !last.containsKey(key);
           if (serverAdd) {
              serverLineAddList.push(cellData);
              continue;
           }
        }
  
        let serverLineDelList = [];

        keyArray = data.keys();
        keyLength = keyArray.length
        for (let index = 0; index < keyLength; index++) {
            let key = keyArray[index];
           let cellData = data.get(key);
           let serverDel = !target.containsKey(key) && last.containsKey(key);
           if (serverDel) {
              serverLineDelList.push(cellData);
              continue;
           }
           let targetData = target.get(key);
           let lastData = last.get(key);
           if (targetData == null || lastData == null || targetData.isEmpty() || lastData.isEmpty()) {
              continue;
           }
           if (!cellData.equal(targetData, true, true, true, true, true) && 
                cellData.equal(lastData, true, true, true, true, true) && 
                !lastData.equal(targetData, true, true, true, true, true)) {
                if (targetData.lineContent.startControlPointX == lastData.lineContent.startControlPointX &&
                    targetData.lineContent.startControlPointY == lastData.lineContent.startControlPointY &&
                    targetData.lineContent.endControlPointX == lastData.lineContent.endControlPointX &&
                    targetData.lineContent.endControlPointY == lastData.lineContent.endControlPointY) {
                    let startControlPointX = cellData.lineContent.startControlPointX;
                    let startControlPointY = cellData.lineContent.startControlPointY;
                    let endControlPointX = cellData.lineContent.endControlPointX;
                    let endControlPointY = cellData.lineContent.endControlPointY;
                    cellData.stick(targetData);
                    cellData.lineContent.startControlPointX = startControlPointX;
                    cellData.lineContent.startControlPointY = startControlPointY;
                    cellData.lineContent.endControlPointX = endControlPointX;
                    cellData.lineContent.endControlPointY = endControlPointY;
                } else {
                    cellData.stick(targetData);
                    if (targetUnit.rootTreeNode != null && dataUnit.rootTreeNode != null &&
                        !targetUnit.rootTreeNode.isEmpty() && !dataUnit.rootTreeNode.isEmpty()) {
    
                        let startPoint = targetData.lineContent.getStartControlPoint();
                        let endPoint = targetData.lineContent.getEndControlPoint();
                        let targetRoot = targetUnit.rootTreeNode;
                        let dataRoot = dataUnit.rootTreeNode;
                        let xStartAndRootContent =  (startPoint.x - targetRoot.value.x + (targetRoot.value.width) / 2) /  targetRoot.value.width;
                        let yStartAndRootContent =  (startPoint.y - targetRoot.value.y + (targetRoot.value.height) / 2) /  targetRoot.value.height;
                        let xEndAndRootContent =  (endPoint.x - targetRoot.value.x + (targetRoot.value.width) / 2) /  targetRoot.value.width;
                        let yEndAndRootContent =  (endPoint.y - targetRoot.value.y + (targetRoot.value.height) / 2) /  targetRoot.value.height;
            
                        let xStart = (xStartAndRootContent *  dataRoot.value.width) - dataRoot.value.width / 2 + dataRoot.value.x;
                        let yStart = (yStartAndRootContent *  dataRoot.value.height) - dataRoot.value.height / 2 + dataRoot.value.y;
            
                        let xEnd = (xEndAndRootContent *  dataRoot.value.width) - dataRoot.value.width / 2 + dataRoot.value.x;
                        let yEnd = (yEndAndRootContent *  dataRoot.value.height) - dataRoot.value.height / 2 + dataRoot.value.y;
                        cellData.lineContent.startControlPointX = xStart;
                        cellData.lineContent.startControlPointY = yStart;
                        cellData.lineContent.endControlPointX = xEnd;
                        cellData.lineContent.endControlPointY = yEnd;
                    }
                }
           }
        }
        if (serverLineAddList.length > 0) {
            for (let index = 0; index < serverLineAddList.length; index++) {
                const cell = serverLineAddList[index];
              data.put(cell.id, cell);
              if (targetUnit.rootTreeNode == null || dataUnit.rootTreeNode == null ||
                      targetUnit.rootTreeNode.isEmpty() || dataUnit.rootTreeNode.isEmpty()) {
                 continue;
              }
              let startPoint = cell.lineContent.getStartControlPoint();
              let endPoint = cell.lineContent.getEndControlPoint();
              let targetRoot = targetUnit.rootTreeNode;
              let dataRoot = dataUnit.rootTreeNode;
              let xStartAndRootContent =  (startPoint.x - targetRoot.value.x + (targetRoot.value.width)/2) / targetRoot.value.width;
              let yStartAndRootContent =  (startPoint.y - targetRoot.value.y + (targetRoot.value.height)/2) / targetRoot.value.height;
              let xEndAndRootContent =  (endPoint.x - targetRoot.value.x + (targetRoot.value.width)/2) / targetRoot.value.width;
              let yEndAndRootContent =  (endPoint.y - targetRoot.value.y + (targetRoot.value.height)/2) / targetRoot.value.height;
  
              let xStart = (xStartAndRootContent * dataRoot.value.width) - dataRoot.value.width/2 + dataRoot.value.x;
              let yStart = (yStartAndRootContent * dataRoot.value.height) - dataRoot.value.height/2 + dataRoot.value.y;
  
              let xEnd = (xEndAndRootContent * dataRoot.value.width) - dataRoot.value.width/2 + dataRoot.value.x;
              let yEnd = (yEndAndRootContent * dataRoot.value.height) - dataRoot.value.height/2 + dataRoot.value.y;
              cell.lineContent.startControlPointX = xStart;
              cell.lineContent.startControlPointY = yStart;
              cell.lineContent.endControlPointX = xEnd;
              cell.lineContent.endControlPointY = yEnd;
           }
        }
        if (serverLineDelList.length > 0) {
            
           for (let index = 0; index < serverLineDelList.length; index++) {
                const cell = serverLineDelList[index];
              data.remove(cell.id);
              this.removeDatas.push(cell);
           }
        }
     }
  
     mergeLineMindTypeNodeUnit(data, target, last) {
        if (data == null || data.rootTreeNode == null || data.rootTreeNode.isEmpty()) {
           return;
        }
        if (target == null || target.rootTreeNode == null || target.rootTreeNode.isEmpty()) {
           return;
        }
        if (last == null || last.rootTreeNode == null || last.rootTreeNode.isEmpty()) {
           return;
        }
        let currentLocalServerTime = Util.getCurrentTimeInsecond()
        if (!data.rootTreeNode.value.equal(target.rootTreeNode.value, true, true, true, true, true) &&
                data.rootTreeNode.value.equal(last.rootTreeNode.value, true, true, true, true, true)) {
           if (this.settingData.sectionIds != null && this.settingData.sectionIds.length > 0 &&
                 data.rootTreeNode.value.textContent.text != target.rootTreeNode.value.textContent.text) {
            let deviceUuid = Config.getgMyMachineId()
            let findChange = false;
            for (let index = 0; index < this.settingData.sectionIds.length; index++) {
                let cell = this.settingData.sectionIds[index];
                if (cell.uuid == deviceUuid || cell.changeList == null || cell.changeList.length == 0) {
                    continue;
                }
                for (let j = 0; j < cell.changeList.length; j++) {
                    let mindSectionChangeData = cell.changeList[j];
                    if (mindSectionChangeData.id == target.rootTreeNode.value.id && (currentLocalServerTime - mindSectionChangeData.time < 20)) {
                        findChange = true;
                        break;
                    }
                }
                if (findChange) {
                    break
                }
            }
            if (findChange) {
                data.rootTreeNode.value.stick(target.rootTreeNode.value, true);
                MindElementCalculation.set(data.rootTreeNode.value).caluleTextForData();
            }
         } else {
            data.rootTreeNode.value.stick(target.rootTreeNode.value, true);
            MindElementCalculation.set(data.rootTreeNode.value).caluleTextForData();
         }
        }
        // lineMindElementDataDict
        let localDataDelList = [];
        let serverDataAddList = [];
  
        let keyArray = target.lineMindElementDataDict.keys();
        let keyLength = keyArray.length
        
        for (let index = 0; index < keyLength; index++) {
            const key = keyArray[index];
           let cellData = target.lineMindElementDataDict.get(key);
           let localDel = !data.lineMindElementDataDict.containsKey(key) && last.lineMindElementDataDict.containsKey(key);
           if (localDel) {
              localDataDelList.push(cellData);
              continue;
           }
           let serverAdd = !data.lineMindElementDataDict.containsKey(key) && !last.lineMindElementDataDict.containsKey(key);
           if (serverAdd) {
              serverDataAddList.push(cellData);
              continue;
           }
        }
  
        let serverDataDelList = [];
        keyArray = data.lineMindElementDataDict.keys();
        keyLength = keyArray.length
        for (let index = 0; index < keyLength; index++) {
            const key = keyArray[index];
           let cellData = data.lineMindElementDataDict.get(key);
           let serverDel = !target.lineMindElementDataDict.containsKey(key) && last.lineMindElementDataDict.containsKey(key);
           if (serverDel) {
              serverDataDelList.push(cellData);
              continue;
           }
           let targetData = target.lineMindElementDataDict.get(key);
           let lastData = last.lineMindElementDataDict.get(key);
           if (targetData == null || lastData == null || targetData.isEmpty() || lastData.isEmpty()) {
              continue;
           }
           
           if (!cellData.equal(targetData, true, true, true, true, true) && cellData.equal(lastData, true, true, true, true, true)) {
              cellData.stick(targetData);
           }
        }
        if (serverDataAddList.length > 0) {
            
           serverDataAddList.forEach(cell => {
              data.lineMindElementDataDict.put(cell.id, cell);
              data.textElementLineMindElementDataDict.put(cell.lineContent.targetId, cell);
           })
        }
        if (serverDataDelList.length > 0) {
            
           serverDataDelList.forEach(cell => {
              data.lineMindElementDataDict.remove(cell.id);
              data.textElementLineMindElementDataDict.remove(cell.lineContent.targetId);
              this.removeDatas.push(cell);
           })
        }
  
        // generalizationLineMindElementDataDict
        localDataDelList = [];
        serverDataAddList = [];
  
        keyArray = target.generalizationLineMindElementDataDict.keys();
        keyLength = keyArray.length
        for (let index = 0; index < keyLength; index++) {
            const key = keyArray[index];
           let cellData = target.generalizationLineMindElementDataDict.get(key);
           let localDel = !data.generalizationLineMindElementDataDict.containsKey(key) && last.generalizationLineMindElementDataDict.containsKey(key);
           if (localDel) {
              localDataDelList.push(cellData);
              continue;
           }
           let serverAdd = !data.generalizationLineMindElementDataDict.containsKey(key) && !last.generalizationLineMindElementDataDict.containsKey(key);
           if (serverAdd) {
              serverDataAddList.push(cellData);
              continue;
           }
        }
  
        serverDataDelList = [];
        keyArray = data.generalizationLineMindElementDataDict.keys();
        keyLength = keyArray.length
        for (let index = 0; index < keyLength; index++) {
            const key = keyArray[index];
           let cellData = data.generalizationLineMindElementDataDict.get(key);
           let serverDel = !target.generalizationLineMindElementDataDict.containsKey(key) && last.generalizationLineMindElementDataDict.containsKey(key);
           if (serverDel) {
              serverDataDelList.push(cellData);
              continue;
           }
           let targetData = target.generalizationLineMindElementDataDict.get(key);
           let lastData = last.generalizationLineMindElementDataDict.get(key);
           if (targetData == null || lastData == null || targetData.isEmpty() || lastData.isEmpty()) {
              continue;
           }
           if (!cellData.equal(targetData, true, true, true, true, true) && cellData.equal(lastData, true, true, true, true, true)) {
              cellData.stick(targetData);
           }
        }
        if (serverDataAddList.length > 0) {
           serverDataAddList.forEach(cell => {
              data.generalizationLineMindElementDataDict.put(cell.id, cell);
           })
        }
        if (serverDataDelList.length > 0) {
           serverDataDelList.forEach(cell => {
              data.generalizationLineMindElementDataDict.remove(cell.id);
              this.removeDatas.push(cell);
           })
        }
  
        // generalizationMindElementDataDict
        let localNodeDelList = [];
        let serverNodeAddList = [];
        keyArray = target.generalizationMindElementDataDict.keys();
        keyLength = keyArray.length
        for (let index = 0; index < keyLength; index++) {
            const key = keyArray[index];
           let cellNode = target.generalizationMindElementDataDict.get(key);
           let localDel = !data.generalizationMindElementDataDict.containsKey(key) && last.generalizationMindElementDataDict.containsKey(key);
           if (localDel) {
              localNodeDelList.push(cellNode);
              continue;
           }
           let serverAdd = !data.generalizationMindElementDataDict.containsKey(key) && !last.generalizationMindElementDataDict.containsKey(key);
           if (serverAdd) {
              serverNodeAddList.push(cellNode);
              continue;
           }
        }
  
        let serverNodeDelList = [];
        keyArray = data.generalizationMindElementDataDict.keys();
        keyLength = keyArray.length
        for (let index = 0; index < keyLength; index++) {
            const key = keyArray[index];
           let cellNode = data.generalizationMindElementDataDict.get(key);
           let cellData = cellNode.value;
           let serverDel = !target.generalizationMindElementDataDict.containsKey(key) && last.generalizationMindElementDataDict.containsKey(key);
           if (serverDel) {
              serverNodeDelList.push(cellNode);
              continue;
           }
           let targetData = target.generalizationMindElementDataDict.get(key);
           let lastData = last.generalizationMindElementDataDict.get(key);
           if (targetData == null || lastData == null || targetData.isEmpty() || lastData.isEmpty()) {
              continue;
           }
           if (!cellData.equal(targetData.value, true, true, true, true, true) && cellData.equal(lastData.value, true, true, true, true, true)) {
              cellData.stick(targetData.value);
              MindElementCalculation.set(cellData).caluleTextForData();
           }
        }
        if (serverNodeAddList.length > 0) {
           serverNodeAddList.forEach(cell => {
              data.generalizationMindElementDataDict.put(cell.value.id, cell);
           })
        }
        if (serverNodeDelList.length > 0) {
           serverNodeDelList.forEach(cell => {
              data.generalizationMindElementDataDict.remove(cell.value.id);
              this.removeDatas.push(cell.value);
           })
        }
  
        // mainMindElementDataDict
        localNodeDelList = [];
        serverNodeAddList = [];
  
        keyArray = target.mainMindElementDataDict.keys();
        keyLength = keyArray.length

        for (let index = 0; index < keyLength; index++) {
            let key = keyArray[index];
           let cellNode = target.mainMindElementDataDict.get(key);
           let localDel = !data.mainMindElementDataDict.containsKey(key) && last.mainMindElementDataDict.containsKey(key);
           
           if (localDel) {
              localNodeDelList.push(cellNode);
              continue;
           }
           let serverAdd = !data.mainMindElementDataDict.containsKey(key) && !last.mainMindElementDataDict.containsKey(key);
           if (serverAdd) {
              serverNodeAddList.push(cellNode);
              continue;
           }
        }
  
        serverNodeDelList = [];
        keyArray = data.mainMindElementDataDict.keys();
        keyLength = keyArray.length
        for (let index = 0; index < keyLength; index++) {
            let key = keyArray[index];
            let cellNode = data.mainMindElementDataDict.get(key);
            let cellData = cellNode.value;
            let serverDel = !target.mainMindElementDataDict.containsKey(key) && last.mainMindElementDataDict.containsKey(key);
            
            if (serverDel) {
                serverNodeDelList.push(cellNode);
                continue;
            }
            if (!target.mainMindElementDataDict.containsKey(key) || !last.mainMindElementDataDict.containsKey(key) ||
                    cellData.id == data.rootTreeNode.value.id) {
                continue;
            }
  
            let targetData = target.mainMindElementDataDict.get(key).value;
            let lastData = last.mainMindElementDataDict.get(key).value;
            if (targetData == null || lastData == null || targetData.isEmpty() || lastData.isEmpty()) {
                continue;
            }
            let localServerNotChange = cellData.equal(targetData, true, true, true, true, true);
            let localOldServerNotChange = cellData.equal(lastData, true, true, true, true, true);
            if (!localServerNotChange && localOldServerNotChange) {
                if (this.settingData.sectionIds != null && this.settingData.sectionIds.length > 0 &&
                    cellData.textContent.text != targetData.textContent.text) {
                    let deviceUuid = Config.getgMyMachineId()
                    let findChange = false;
                    for (let index = 0; index < this.settingData.sectionIds.length; index++) {
                            let cell = this.settingData.sectionIds[index];
                            if (cell.uuid == deviceUuid || cell.changeList == null || cell.changeList.length == 0) {
                                continue;
                            }
                            for (let j = 0; j < cell.changeList.length; j++) {
                                let mindSectionChangeData = cell.changeList[j];
                                if (mindSectionChangeData.id == cellData.id) {
                                    // console.log("text----", currentLocalServerTime - mindSectionChangeData.time, cellData.textContent.text);
                                    if (currentLocalServerTime - mindSectionChangeData.time < 30) {
                                        findChange = true;
                                        break;
                                    }
                                }
                            }
                            if (findChange) {
                                break;
                            }
                    }
                    if (findChange) {
                        let isHidden = cellData.isHidden;
                        let hiddenNumber = cellData.hiddenNumber;
                        cellData.stick(targetData);
                        cellData.isHidden = isHidden;
                        cellData.hiddenNumber = hiddenNumber;
                        MindElementCalculation.set(cellData).caluleTextForData();
                    }
                } else {
                    let isHidden = cellData.isHidden;
                    let hiddenNumber = cellData.hiddenNumber;
                    cellData.stick(targetData);
                    cellData.isHidden = isHidden;
                    cellData.hiddenNumber = hiddenNumber;
                    MindElementCalculation.set(cellData).caluleTextForData();
                }              
            }
        }
        if (serverNodeAddList.length > 0) {
            let selfSectionId = this.settingData.getSelfSectionId();
            serverNodeAddList.forEach(cell => {
                if (data.getNodeById(cell.value.id).isEmpty()) {
                    let isDel = false;
                    if (selfSectionId != null && selfSectionId.removeList != null)  {
                        for(let j = 0; j < selfSectionId.removeList.length; j++) {
                            if (selfSectionId.removeList[j].id == cell.value.id && currentLocalServerTime - selfSectionId.removeList[j].time < 15) {
                                isDel = true;
                                break;
                            }
                        }
                    }
                    if (!isDel) {
                        let parentNode = this.getNodeById(cell.value.parentNodeId);
                        if (!parentNode.isEmpty() && parentNode.value.hiddenNumber > 0) {
                            cell.value.isHidden = true;
                            parentNode.value.hiddenNumber = parentNode.value.hiddenNumber + 1;
                        }
                        data.mainMindElementDataDict.put(cell.value.id, cell);
                        IdGenerator.shared.setId(cell.value.id);
                        MindElementCalculation.set(cell.value).caluleTextForData();
                    };
                }
            })
        }
        if (serverNodeDelList.length > 0) {
           serverNodeDelList.forEach(delCell => {
                if (this.settingData.sectionIds != null && this.settingData.sectionIds.length > 0) {
                    let deviceUuid = Config.getgMyMachineId();
                    let findChange = false;
                    for (let index = 0; index < this.settingData.sectionIds.length; index++) {
                        let cell = this.settingData.sectionIds[index];
                        if (cell.uuid == deviceUuid || cell.removeList == null || cell.removeList.length == 0) {
                            continue;
                        }
                        for (let j = 0; j < cell.removeList.length; j++) {
                            let mindSectionChangeData = cell.removeList[j];
                            if (mindSectionChangeData.id == delCell.value.id && (currentLocalServerTime - mindSectionChangeData.time < 20)) {
                                findChange = true;
                                break;
                            }
                        }
                        if (findChange) {
                            break;
                        }
                    }
                    if (findChange) {
                        let parentNode = this.getNodeById(delCell.value.parentNodeId);
                        if (!parentNode.isEmpty() && parentNode.value.hiddenNumber > 0) {
                           parentNode.value.hiddenNumber = parentNode.value.hiddenNumber - 1;
                        }
                        data.mainMindElementDataDict.remove(delCell.value.id);
                        this.removeDatas.push(delCell.value);
                    }
                } else {
                    let parentNode = this.getNodeById(delCell.value.parentNodeId);
                    if (!parentNode.isEmpty() && parentNode.value.hiddenNumber > 0) {
                        parentNode.value.hiddenNumber = parentNode.value.hiddenNumber - 1;
                    }
                    data.mainMindElementDataDict.remove(delCell.value.id);
                    this.removeDatas.push(delCell.value);
                }
              
           })
        }
        // encircleMindElementDataDict
        localDataDelList = [];
        serverDataAddList = [];
        keyArray = target.encircleMindElementDataDict.keys();
        keyLength = keyArray.length

        for (let index = 0; index < keyLength; index++) {
            let key = keyArray[index];
           let cellData = target.encircleMindElementDataDict.get(key);
           let localDel = !data.encircleMindElementDataDict.containsKey(key) && last.encircleMindElementDataDict.containsKey(key);
           if (localDel) {
              localDataDelList.push(cellData);
              continue;
           }
           let serverAdd = !data.encircleMindElementDataDict.containsKey(key) && !last.encircleMindElementDataDict.containsKey(key);
           if (serverAdd) {
              serverDataAddList.push(cellData);
              continue;
           }
        }
  
        serverDataDelList = [];
        keyArray = data.encircleMindElementDataDict.keys();
        keyLength = keyArray.length

        for (let index = 0; index < keyLength; index++) {
            let key = keyArray[index];
           let cellData = data.encircleMindElementDataDict.get(key);
           let serverDel = !target.encircleMindElementDataDict.containsKey(key) && last.encircleMindElementDataDict.containsKey(key);
           if (serverDel) {
              serverDataDelList.push(cellData);
              continue;
           }
           let targetData = target.encircleMindElementDataDict.get(key);
           let lastData = last.encircleMindElementDataDict.get(key);
           if (targetData == null || lastData == null || targetData.isEmpty() || lastData.isEmpty()) {
              continue;
           }
           if (!cellData.equal(targetData, true, true, true, true, true) && cellData.equal(lastData, true, true, true, true, true)) {
              cellData.stick(targetData);
           }
        }
        if (serverDataAddList.length > 0) {
           serverDataAddList.forEach(cell => {
              data.encircleMindElementDataDict.put(cell.id, cell);
           })
        }
        if (serverDataDelList.length > 0) {
           serverDataDelList.forEach(cell => {
              data.encircleMindElementDataDict.remove(cell.id);
              this.removeDatas.push(cell);
           })
        }
  
        // explainMindElementDataDict
        localDataDelList = [];
        serverDataAddList = [];
        keyArray = target.explainMindElementDataDict.keys();
        keyLength = keyArray.length

        for (let index = 0; index < keyLength; index++) {
            let key = keyArray[index];
           let cellData = target.explainMindElementDataDict.get(key);
           let localDel = !data.explainMindElementDataDict.containsKey(key) && last.explainMindElementDataDict.containsKey(key);
           if (localDel) {
              localDataDelList.push(cellData);
              continue;
           }
           let serverAdd = !data.explainMindElementDataDict.containsKey(key) && !last.explainMindElementDataDict.containsKey(key);
           if (serverAdd) {
              serverDataAddList.push(cellData);
              continue;
           }
        }
  
        serverDataDelList = [];
        keyArray = data.explainMindElementDataDict.keys();
        keyLength = keyArray.length

        for (let index = 0; index < keyLength; index++) {
            let key = keyArray[index];
           let cellData = data.explainMindElementDataDict.get(key);
           let serverDel = !target.explainMindElementDataDict.containsKey(key) && last.explainMindElementDataDict.containsKey(key);
           if (serverDel) {
              serverDataDelList.push(cellData);
              continue;
           }
           let targetData = target.explainMindElementDataDict.get(key);
           let lastData = last.explainMindElementDataDict.get(key);
           if (targetData == null || lastData == null || targetData.isEmpty() || lastData.isEmpty()) {
              continue;
           }
           if (!cellData.equal(targetData, true, true, true, true, true) && cellData.equal(lastData, true, true, true, true, true)) {
              cellData.stick(targetData);
           }
        }
        if (serverDataAddList.length > 0) {
           serverDataAddList.forEach(cell => {
              data.explainMindElementDataDict.put(cell.id, cell);
           })
        }
        if (serverDataDelList.length > 0) {
           serverDataDelList.forEach(cell => {
              data.explainMindElementDataDict.remove(cell.id);
              this.removeDatas.push(cell);
           })
        }
        this.setNodeDelChild(data.rootTreeNode, data, target);
        this.setNodeAddChild(target.rootTreeNode, data, target, last);

        keyArray = data.generalizationMindElementDataDict.keys();
        keyLength = keyArray.length

        for (let index = 0; index < keyLength; index++) {
            let key = keyArray[index];
           let cellNode = data.generalizationMindElementDataDict.get(key);
           this.setNodeDelChild(cellNode, data, target);
        }
        keyArray = target.generalizationMindElementDataDict.keys();
        keyLength = keyArray.length

        for (let index = 0; index < keyLength; index++) {
            let key = keyArray[index];
           let cellNode = target.generalizationMindElementDataDict.get(key);
           this.setNodeAddChild(cellNode, data, target, last);
        }
     }
  
     setNodeDelChild(node, dataUnit, targetUnit) {
        let children = [];
  
        for (let index = 0; index < node.children.length; index++) {
           let cellNode = node.children[index];
           let findNode = dataUnit.getNodeById(cellNode.value.id);
           if (!findNode.isEmpty()) {
              children.push(cellNode);
           }
        }
        node.children = children;
  
        for (let index = 0; index < node.children.length; index++) {
           let cellNode = node.children[index];
           this.setNodeDelChild(cellNode, dataUnit, targetUnit);
        }
     }
  
     setNodeAddChild(node, dataUnit, targetUnit, lastUnit) {
        let localNode = dataUnit.getNodeById(node.value.id);
        if (node.isEmpty() || localNode.isEmpty()) {
           return;
        }
        for (let index = 0; index < node.children.length; index++) {
           let cellNode = node.children[index];
           let findNode = dataUnit.getNodeById(cellNode.value.id);
           if (findNode.isEmpty() || findNode.value.parentNodeId != localNode.value.id) {
              continue;
           }
           let existIndex = -1;
           for (let localIndex = 0; localIndex < localNode.children.length; localIndex++) {
              if (localNode.children[localIndex].value.id == cellNode.value.id) {
                 existIndex = localIndex;
                 break;
              }
           }
  
           if (existIndex == -1) {
              if (index < localNode.children.length) {
                 localNode.addChildrenNode(findNode, index);;
              } else {
                 localNode.children.push(findNode);
              }
           }
        }
  
        for (let index = 0; index < node.children.length; index++) {
           let cellNode = node.children[index];
           this.setNodeAddChild(cellNode, dataUnit, targetUnit, lastUnit);
        }
     }
  
    mergeDoubleBubbleMindNodeUnit(data,  target, last) {
        if (data == null || data.rootTreeNode == null || data.rootTreeNode.isEmpty()) {
           return;
        }
        if (target == null || target.rootTreeNode == null || target.rootTreeNode.isEmpty()) {
           return;
        }
        if (last == null || last.rootTreeNode == null || last.rootTreeNode.isEmpty()) {
           return;
        }
        if (!data.rootTreeNode.value.equal(target.rootTreeNode.value, true, true, true, true, true) &&
                data.rootTreeNode.value.equal(last.rootTreeNode.value, true, true, true, true, true)) {
           data.rootTreeNode.value.stick(target.rootTreeNode.value, true);
        }
        if (!data.rootTreeBrotherNode.value.equal(target.rootTreeBrotherNode.value, true, true, true) &&
                data.rootTreeBrotherNode.value.equal(last.rootTreeBrotherNode.value, true, true, true)) {
           data.rootTreeBrotherNode.value.stick(target.rootTreeBrotherNode.value, true);
        }
        // lineMindElementDataDict
        let localLineDelList = [];
        let serverLineAddList = [];

        let keyArray = target.lineMindElementDataDict.keys();
        let keyLength = keyArray.length
        for (let index = 0; index < keyLength; index++) {
            const key = keyArray[index];
           let cellData = target.lineMindElementDataDict.get(key);
           let localDel = !data.lineMindElementDataDict.containsKey(key) && last.lineMindElementDataDict.containsKey(key);
           if (localDel) {
              localLineDelList.push(cellData);
              continue;
           }
           let serverAdd = !data.lineMindElementDataDict.containsKey(key) && !last.lineMindElementDataDict.containsKey(key);
           if (serverAdd) {
              serverLineAddList.push(cellData);
              continue;
           }
        }
  
        let serverLineDelList = [];
        keyArray = data.lineMindElementDataDict.keys();
        keyLength = keyArray.length
        for (let index = 0; index < keyLength; index++) {
            const key = keyArray[index];
           let cellData = data.lineMindElementDataDict.get(key);
           let serverDel = !target.lineMindElementDataDict.containsKey(key) && last.lineMindElementDataDict.containsKey(key);
           if (serverDel) {
              serverLineDelList.push(cellData);
              continue;
           }
           let targetData = target.lineMindElementDataDict.get(key);
           let lastData = last.lineMindElementDataDict.get(key);
           if (targetData == null || lastData == null || targetData.isEmpty() || lastData.isEmpty()) {
              continue;
           }
           if (!cellData.equal(targetData, true, true, true, true, true) && cellData.equal(lastData, true, true, true, true, true)) {
              cellData.stick(targetData);
           }
        }
        if (serverLineAddList.length > 0) {
           for (let index = 0; index < serverLineAddList.length; index++) {
                const cell = serverLineAddList[index];
              data.lineMindElementDataDict.put(cell.id, cell);
              data.textElementLineMindElementDataDict.put(cell.lineContent.targetId, cell);
           }
        }
        if (serverLineDelList.length > 0) {
           for (let index = 0; index < serverLineDelList.length; index++) {
                const cell = serverLineDelList[index];
              data.lineMindElementDataDict.remove(cell.id);
              data.textElementLineMindElementDataDict.remove(cell.lineContent.targetId);
           }
        }
        // mainMindElementDataDict
        let localNodeDelList = [];
        let serverNodeAddList = [];
  
        let mainMindElementDataDictArray = target.mainMindElementDataDict.keys();
        let mainMindElementDataDictLength = mainMindElementDataDictArray.length

        for (let index = 0; index < mainMindElementDataDictLength; index++) {
            let key = mainMindElementDataDictArray[index];
           let cellNode = target.mainMindElementDataDict.get(key);
           let localDel = !data.mainMindElementDataDict.containsKey(key) && last.mainMindElementDataDict.containsKey(key);
           if (localDel) {
              localNodeDelList.push(cellNode);
              continue;
           }
           let serverAdd = !data.mainMindElementDataDict.containsKey(key) && !last.mainMindElementDataDict.containsKey(key);
           if (serverAdd) {
              serverNodeAddList.push(cellNode);
              continue;
           }
        }
  
        let serverNodeDelList = [];
        keyArray = data.mainMindElementDataDict.keys();
        keyLength = keyArray.length
        for (let index = 0; index < keyLength; index++) {
            const key = keyArray[index];
           let cellNode = data.mainMindElementDataDict.get(key);
           let cellData = cellNode.value;
           let serverDel = !target.mainMindElementDataDict.containsKey(key) && last.mainMindElementDataDict.containsKey(key);
           if (serverDel) {
              serverNodeDelList.push(cellNode);
              continue;
           }
           let targetData = target.mainMindElementDataDict.get(key);
           let lastData = last.mainMindElementDataDict.get(key);
           if (targetData == null || lastData == null || targetData.isEmpty() || lastData.isEmpty()) {
              continue;
           }
           if (!cellData.equal(targetData.value, true, true, true, true, true) && cellData.equal(lastData.value, true, true, true, true, true)) {
              cellData.stick(targetData.value);
           }
        }
        if (serverNodeAddList.length > 0) {
           serverNodeAddList.forEach(cell => {
              data.mainMindElementDataDict.put(cell.value.id, cell);
           })
        }
        if (serverNodeDelList.length > 0) {
           serverNodeDelList.forEach(cell => {
              data.mainMindElementDataDict.remove(cell.value.id);
           })
        }
  
        // commonGroundMindElementDatas
        serverLineAddList = [];
  
        for(let index = 0; index < target.commonGroundMindElementDatas.length; index++) {
            let cellData = target.commonGroundMindElementDatas[index]
           let serverAdd = data.getNodeById(cellData.id).isEmpty() && last.getNodeById(cellData.id).isEmpty();
           if (serverAdd) {
              serverLineAddList.push(cellData);
              continue;
           }
        }
  
        serverLineDelList = [];
        for(let index = 0; index < data.commonGroundMindElementDatas.length; index++) {
            let cellData = data.commonGroundMindElementDatas[index]
           let serverDel = target.getNodeById(cellData.id).isEmpty() && !last.getNodeById(cellData.id).isEmpty();
           if (serverDel) {
              serverLineDelList.push(cellData);
              continue;
           }
           let targetData = target.getNodeById(cellData.id).value;
           let lastData = last.getNodeById(cellData.id).value;
           if (targetData == null || lastData == null || targetData.isEmpty() || lastData.isEmpty()) {
              continue;
           }
           if (!cellData.equal(targetData, true, true, true, true, true) && cellData.equal(lastData, true, true, true, true, true)) {
              cellData.stick(targetData);
           }
        }
        if (serverLineAddList.length > 0) {
           for (let index = 0; index < serverLineAddList.length; index++) {
                const cell = serverLineAddList[index];
              data.commonGroundMindElementDatas.push(cell);
           }
        }
        if (serverLineDelList.length > 0) {
           for (let index = 0; index < serverLineDelList.length; index++) {
                const cell = serverLineDelList[index];
              data.commonGroundMindElementDatas.remove(cell);
           }
        }
        this.setNodeDelChild(data.rootTreeNode, data, target);
        this.setNodeAddChild(target.rootTreeNode, data, target, last);
  
        this.setNodeDelChild(data.rootTreeBrotherNode, data, target);
        this.setNodeAddChild(target.rootTreeBrotherNode, data, target, last)
    }
  
    setNodeDelChild(node, dataUnit, targetUnit) {
        if (node == null || node.isEmpty()) {
           return;
        }
        let children = [];
  
        for (let index = 0; index < node.children.length; index++) {
           let cellNode = node.children[index];
           let findNode = dataUnit.getNodeById(cellNode.value.id);
           if (!findNode.isEmpty()) {
              children.push(cellNode);
           }
        }
        node.children = children;
  
        for (let index = 0; index < node.children.length; index++) {
           let cellNode = node.children[index];
           this.setNodeDelChild(cellNode, dataUnit, targetUnit);
        }
    }
  
    setNodeAddChild(node, dataUnit, targetUnit, lastUnit) {
        if (node == null || node.isEmpty()) {
           return;
        }
        let localNode = dataUnit.getNodeById(node.value.id);
        if (node.isEmpty() || localNode.isEmpty()) {
           return;
        }
        for (let index = 0; index < node.children.length; index++) {
           let cellNode = node.children[index];
           let findNode = dataUnit.getNodeById(cellNode.value.id);
           if (findNode.isEmpty()) {
              continue;
           }
           let existIndex = -1;
           for (let localIndex = 0; localIndex < localNode.children.length; localIndex++) {
              if (localNode.children[localIndex].value.id == cellNode.value.id) {
                 existIndex = localIndex;
                 break;
              }
           }
  
           if (existIndex == -1) {
              if (index < localNode.children.length) {
                 localNode.addChildrenNode(findNode, index);;
              } else {
                 localNode.children.push(findNode);
              }
           }
        }
  
        for (let index = 0; index < node.children.length; index++) {
           let cellNode = node.children[index];
           this.setNodeAddChild(cellNode, dataUnit, targetUnit, lastUnit);
        }
    }
  
    mergeBaseData(target, lastData) {
        if (this.mindDisplayType != target.mindDisplayType && this.mindDisplayType == lastData.mindDisplayType) {
           this.mindDisplayType = target.mindDisplayType;
        }
        if (this.mindkey != target.mindkey && this.mindkey == lastData.mindkey) {
           this.mindkey = target.mindkey;
        }
        if (this.mindGlobalAudio != target.mindGlobalAudio && this.mindGlobalAudio == lastData.mindGlobalAudio) {
           this.mindGlobalAudio = target.mindGlobalAudio;
        }
        if (this.mindType == MindType.TIME_MAP) {
           if (this.timeMindTypeNodeUnit.globalLayout != target.timeMindTypeNodeUnit.globalLayout && this.timeMindTypeNodeUnit.globalLayout == lastData.timeMindTypeNodeUnit.globalLayout) {
              this.timeMindTypeNodeUnit.globalLayout = target.timeMindTypeNodeUnit.globalLayout;
           }
        } else {
           if (this.globalLayout != target.globalLayout && this.globalLayout == lastData.globalLayout) {
              this.globalLayout = target.globalLayout;
           }
        }
  
        if (this.globalLineLayout != target.globalLineLayout && this.globalLineLayout == lastData.globalLineLayout) {
           this.globalLineLayout = target.globalLineLayout;
        }
        if (this.mindBGColor != target.mindBGColor && this.mindBGColor == lastData.mindBGColor) {
           this.mindBGColor = target.mindBGColor;
        }
        if (this.mindBGStripesType != target.mindBGStripesType && this.mindBGStripesType == lastData.mindBGStripesType) {
           this.mindBGStripesType = target.mindBGStripesType;
        }
    }
  
    mergeSettingData(target, lastData) {
        if (!this.settingData.freeNodeMoveAdsorbent.equal(target.settingData.freeNodeMoveAdsorbent) && this.settingData.freeNodeMoveAdsorbent.equal(lastData.settingData.freeNodeMoveAdsorbent)) {
           this.settingData.freeNodeMoveAdsorbent = target.settingData.freeNodeMoveAdsorbent.copy();
        }
        if (!this.settingData.branchNodeFreelayout.equal(target.settingData.branchNodeFreelayout) && this.settingData.branchNodeFreelayout.equal(lastData.settingData.branchNodeFreelayout)) {
           this.settingData.branchNodeFreelayout = target.settingData.branchNodeFreelayout.copy();
        }
        if (this.settingData.subjectSpcaeHorizontal != target.settingData.subjectSpcaeHorizontal && this.settingData.subjectSpcaeHorizontal == lastData.settingData.subjectSpcaeHorizontal) {
           this.settingData.subjectSpcaeHorizontal = target.settingData.subjectSpcaeHorizontal;
        }
        if (this.settingData.subjectSpcaeVertical != target.settingData.subjectSpcaeVertical && this.settingData.subjectSpcaeVertical == lastData.settingData.subjectSpcaeVertical) {
           this.settingData.subjectSpcaeVertical = target.settingData.subjectSpcaeVertical;
        }
        if (this.settingData.sonSubjectSpcaeHorizontal != target.settingData.sonSubjectSpcaeHorizontal && this.settingData.sonSubjectSpcaeHorizontal == lastData.settingData.sonSubjectSpcaeHorizontal) {
           this.settingData.sonSubjectSpcaeHorizontal = target.settingData.sonSubjectSpcaeHorizontal;
        }
        if (this.settingData.sonSubjectSpcaeVertical != target.settingData.sonSubjectSpcaeVertical && this.settingData.sonSubjectSpcaeVertical == lastData.settingData.sonSubjectSpcaeVertical) {
           this.settingData.sonSubjectSpcaeVertical = target.settingData.sonSubjectSpcaeVertical;
        }
  
        if (this.settingData.useTimeLineColor != target.settingData.useTimeLineColor && this.settingData.useTimeLineColor == lastData.settingData.useTimeLineColor) {
           this.settingData.useTimeLineColor = target.settingData.useTimeLineColor;
        }
        if (this.settingData.tightMode != target.settingData.tightMode && this.settingData.tightMode == lastData.settingData.tightMode) {
           this.settingData.tightMode = target.settingData.tightMode;
        }
        if (this.settingData.sameLevelAlignment != target.settingData.sameLevelAlignment && this.settingData.sameLevelAlignment == lastData.settingData.sameLevelAlignment) {
           this.settingData.sameLevelAlignment = target.settingData.sameLevelAlignment;
        }
        if (this.settingData.lineThicken != target.settingData.lineThicken && this.settingData.lineThicken == lastData.settingData.lineThicken) {
           this.settingData.lineThicken = target.settingData.lineThicken;
        }
        if (this.settingData.lineEndThicken != target.settingData.lineEndThicken && this.settingData.lineEndThicken == lastData.settingData.lineEndThicken) {
           this.settingData.lineEndThicken = target.settingData.lineEndThicken;
        }
        //lineColor
        let targetChange = this.settingData.lineColor.length != target.settingData.lineColor.length;
        let selfChange = this.settingData.lineColor.length != lastData.settingData.lineColor.length;
        if (!targetChange) {
           for (let index = 0; index < this.settingData.lineColor.length; index++) {
              targetChange = targetChange || this.settingData.lineColor[index] != target.settingData.lineColor[index];
              if (targetChange) {
                 break;
              }
           }
        }
        if (targetChange && !selfChange) {
           for (let index = 0; index < this.settingData.lineColor.length; index++) {
              selfChange = selfChange || this.settingData.lineColor[index] != lastData.settingData.lineColor[index];
              if (selfChange) {
                 break;
              }
           }
        }
        if (targetChange && !selfChange) {
           this.settingData.lineColor = target.settingData.lineColor;
        }
        //changeDataStyleId
        targetChange = this.settingData.changeDataStyleId.length != target.settingData.changeDataStyleId.length;
        selfChange = this.settingData.changeDataStyleId.length != lastData.settingData.changeDataStyleId.length;
        if (!targetChange) {
           for (let index = 0; index < this.settingData.changeDataStyleId.length; index++) {
              targetChange = targetChange || this.settingData.changeDataStyleId[index] != target.settingData.changeDataStyleId[index];
              if (targetChange) {
                 break;
              }
           }
        }
        if (targetChange && !selfChange) {
           for (let index = 0; index < this.settingData.changeDataStyleId.length; index++) {
              selfChange = selfChange || this.settingData.changeDataStyleId[index] != lastData.settingData.changeDataStyleId[index];
              if (selfChange) {
                 break;
              }
           }
        }
        if (targetChange && !selfChange) {
           this.settingData.changeDataStyleId = target.settingData.changeDataStyleId;
        }
        if (target.settingData.sectionIds != null) {
            for (let index = 0; index < target.settingData.sectionIds.length; index++) {
                let cell = target.settingData.sectionIds[index];
                this.settingData.mergeSectionId(cell);
            }
        }
    }
  
    setContent(data) {
        if (data == null || data.length == 0) {
           return;
        }

        if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.FLOW_CHART || this.mindType == MindType.BUBBLE_MAP) {
            let mind = new MindDateFormat().format(data);
              this.setMindDataFormCache(mind);
        } else if (this.mindType == MindType.TIME_MAP) {
           let mind = new TimeLineMindDateFormat().format(data);
           this.setTimeLineMindDataFormCache(mind);
        } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
           let mind = new DoubleBubbleMindDateFormat().format(data);
           this.setDoubleBubbleMindDateFormCache(mind);
        }
    }
  
    generalSave() {
        var content = "";
        if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.FLOW_CHART || this.mindType == MindType.BUBBLE_MAP) {
            let mindDateFormat = new MindDateFormat();
            mindDateFormat.mindBGColor = this.mindBGColor;
            mindDateFormat.settingData = this.settingData;
            mindDateFormat.styleIndex = this.mindMapStyleColor.currentSelectedIndex;
            mindDateFormat.version = Config.MindFormatVersion;
            mindDateFormat.mindGlobalAudio = this.mindGlobalAudio;
            mindDateFormat.mindkey = this.mindkey;
            mindDateFormat.mindBGStripesType = this.mindBGStripesType;
            mindDateFormat.mindDisplayType = this.mindDisplayType;
            let freeUnitNodes = new Array();
            let length = this.freeNodes.length;
            for (let index = 0; index < length; index++) {
                const unit = this.freeNodes[index];
                if (unit.rootTreeNode == null) {
                    continue;
                }
                let unitMindDateFormat = new MindDateFormat();
                let lineMindElementDataList = unit.lineMindElementDataDict.values();
                let generalizationLineMindElementDataList = unit.generalizationLineMindElementDataDict.values();
                let generalizationNode = unit.generalizationMindElementDataDict.values();
                let encircle = unit.encircleMindElementDataDict.values();
                let explainList = unit.explainMindElementDataDict.values();

                unitMindDateFormat = unitMindDateFormat.setDatas(unit.rootTreeNode, lineMindElementDataList,
                    generalizationLineMindElementDataList,
                    generalizationNode,
                    new Array(),
                    encircle,
                    explainList)
                    .setLayout(unit.globalLayout, unit.globalLineLayout);
                freeUnitNodes.push(unitMindDateFormat);
            }
            mindDateFormat.nodeConnectLinet = this.nodeConnectLineDataDict.values();
            mindDateFormat = mindDateFormat.setDatas(this.mainMindNodeUnit.rootTreeNode,
                this.mainMindNodeUnit.lineMindElementDataDict.values(),
                this.mainMindNodeUnit.generalizationLineMindElementDataDict.values(),
                this.mainMindNodeUnit.generalizationMindElementDataDict.values(),
                freeUnitNodes,
                this.mainMindNodeUnit.encircleMindElementDataDict.values(),
                this.mainMindNodeUnit.explainMindElementDataDict.values()).setLayout(this.globalLayout, this.globalLineLayout);

            content = mindDateFormat.toJSONString();
        } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            let mindDateFormat = new DoubleBubbleMindDateFormat();
            mindDateFormat.mindBGColor = this.mindBGColor;
            mindDateFormat.settingData = this.settingData;
            mindDateFormat.styleIndex = this.mindMapStyleColor.currentSelectedIndex;
            mindDateFormat.version = Config.MindFormatVersion;
            mindDateFormat.mindkey = this.mindkey;
            mindDateFormat.mindGlobalAudio = this.mindGlobalAudio;
            mindDateFormat.mindBGStripesType = this.mindBGStripesType;
            let freeUnitNodes = new Array();
            let length = this.freeNodes.length;
            for (let index = 0; index < length; index++) {
                const unit = this.freeNodes[index];
                if (unit.rootTreeNode == null) {
                    continue;
                }
                let unitMindDateFormat = new MindDateFormat();
                let lineMindElementDataList = unit.lineMindElementDataDict.values();
                let generalizationLineMindElementDataList = unit.generalizationLineMindElementDataDict.values();
                let generalizationNode = unit.generalizationMindElementDataDict.values();
                let encircle = unit.encircleMindElementDataDict.values();
                let explainList = unit.explainMindElementDataDict.values();
                unitMindDateFormat = unitMindDateFormat.setDatas(unit.rootTreeNode, lineMindElementDataList,
                    generalizationLineMindElementDataList,
                    generalizationNode,
                    new Array(),
                    encircle,
                    explainList).setLayout(unit.globalLayout, unit.globalLineLayout);
                freeUnitNodes.push(unitMindDateFormat);
            };
            mindDateFormat.nodeConnectLinet = this.nodeConnectLineDataDict.values();
            mindDateFormat = mindDateFormat.setDatas(this.doubleBubbleMindNodeUnit.rootTreeNode,
                this.doubleBubbleMindNodeUnit.lineMindElementDataDict.values(),
                freeUnitNodes,
                this.doubleBubbleMindNodeUnit.rootTreeBrotherNode,
                this.doubleBubbleMindNodeUnit.commonGroundMindElementDatas)
                .setLayout(this.globalLayout, this.globalLineLayout);

            content = mindDateFormat.toJSONString();
        } else if (this.mindType == MindType.TIME_MAP) {
            let format = new TimeLineMindDateFormat();
            format.mindBGColor = this.mindBGColor;
            format.settingData = this.settingData;
            format.styleIndex = this.mindMapStyleColor.currentSelectedIndex;
            format.version = Config.MindFormatVersion;
            format.mindkey = this.mindkey;
            format.mindGlobalAudio = this.mindGlobalAudio;
            format.mindBGStripesType = this.mindBGStripesType;
            format.nodeConnectLinet = this.nodeConnectLineDataDict.values();
            let freeUnitNodes = new Array();
            let length = this.freeNodes.length;
            for (let index = 0; index < length; index++) {
                const unit = this.freeNodes[index];
                if (unit.rootTreeNode == null) {
                    continue;
                }
                let unitMindDateFormat = new MindDateFormat();
                let lineMindElementDataList = unit.lineMindElementDataDict.values();
                let generalizationLineMindElementDataList = unit.generalizationLineMindElementDataDict.values();
                let generalizationNode = unit.generalizationMindElementDataDict.values();
                let encircle = unit.encircleMindElementDataDict.values();
                let explainList = unit.explainMindElementDataDict.values();
                unitMindDateFormat = unitMindDateFormat.setDatas(unit.rootTreeNode, lineMindElementDataList,
                    generalizationLineMindElementDataList,
                    generalizationNode,
                    new Array(),
                    encircle,
                    explainList)
                    .setLayout(unit.globalLayout, unit.globalLineLayout);
                freeUnitNodes.push(unitMindDateFormat);
            };

            format = format.setDatas(this.timeMindTypeNodeUnit.title,
                this.timeMindTypeNodeUnit.baseLine,
                this.timeMindTypeNodeUnit.mainMindElementDataDict,
                freeUnitNodes, this.timeMindTypeNodeUnit.globalLayout);
            content = format.toJSONString();
        }
        return content;
    }
     
    setMindDataFormCache(data) {
        this.mindkey = data.mindkey;
        if (data.rootNode != null) {
            this.mainMindNodeUnit.globalLayout = data.globalLayout;
            this.mainMindNodeUnit.setRootNode(data.rootNode);
            this.mindBGColor = data.mindBGColor;
            this.settingData = data.settingData.copy()
            this.mainMindNodeUnit.setLineColors(data.settingData.lineColor);
            this.mindMapStyleColor = new MindMapStyleColor(data.styleIndex);
            this.mainMindNodeUnit.setMindMapStyleColor(data.styleIndex)
            this.mindGlobalAudio = data.mindGlobalAudio;
            this.mindBGStripesType = data.mindBGStripesType;
            this.mainMindNodeUnit.setMindBGColor(this.mindBGColor);
            this.freeNodes.forEach(item => {
                item.setMindBGColor(this.mindBGColor);
                item.setMindDisplayType(data.mindDisplayType);
            });
        }
        this.mindDisplayType = data.mindDisplayType;
        this.mainMindNodeUnit.mindDisplayType = data.mindDisplayType;
        this.globalLineLayout = data.globalLineLayout;
        this.mainMindNodeUnit.globalLineLayout = data.globalLineLayout;
        data.generalizationLine.forEach(line => {
            this.mainMindNodeUnit.generalizationLineMindElementDataDict.put(line.id, line);
            IdGenerator.shared.setId(line.id);
        });
        data.explainData.forEach(cell => {
            this.mainMindNodeUnit.explainMindElementDataDict.put(cell.id, cell);
            IdGenerator.shared.setId(cell.id);
        });
        data.generalizationNode.forEach(node => {
            this.mainMindNodeUnit.setGeneralizationNode(node);
            IdGenerator.shared.setId(node.value.id);
        });
        let rootLineCount = data.rootLine.length;
        for (let nber = 0; nber < rootLineCount; nber++) {
            let line = data.rootLine[nber];
            if (line.lineContent != null) {
                IdGenerator.shared.setId(line.id);
                let node = this.mainMindNodeUnit.getNodeById(line.lineContent.targetId);
                let targetLine = this.mainMindNodeUnit.getNodeLine(line.lineContent.targetId);
                if (!node.isEmpty() &&
                        (targetLine.isEmpty() ||
                        line.type == MindElementType.LAYOUT_CUSTOM_LINE && targetLine.type != MindElementType.LAYOUT_CUSTOM_LINE)) {
                    let isAdd = false;
                    if (line.type == MindElementType.LAYOUT_CUSTOM_LINE) {
                        if (node.value.layout != NodeLayoutType.LAYOUT_RADIATE) {
                            continue;
                        }
                    } else {                   
                        let values = this.mainMindNodeUnit.lineMindElementDataDict.values();
                        let valuesCount = values.length;
                        for (let index = 0; index < valuesCount; index++) {
                            let lineData = values[index];
                            if ((lineData.type == MindElementType.LINE ||
                                lineData.type == MindElementType.SON_LINE) &&
                                lineData.lineContent != null &&
                                lineData.lineContent.targetId == line.lineContent.targetId &&
                                lineData.parentNodeId == line.parentNodeId) {
                                isAdd = true;
                                break;
                            }
                        }
                    }
                    if (isAdd) {
                        continue;
                    }

                    this.mainMindNodeUnit.lineMindElementDataDict.put(line.id, line);
                    this.mainMindNodeUnit.textElementLineMindElementDataDict.put(line.lineContent.targetId, line);
                }
            }
        }
        
        data.nodeConnectLinet.forEach(line => {
            this.nodeConnectLineDataDict.put(line.id, line);
            IdGenerator.shared.setId(line.id);
        });
        data.encircle.forEach(line => {
            this.mainMindNodeUnit.encircleMindElementDataDict.put(line.id, line);
            IdGenerator.shared.setId(line.id);
        });
        this.freeNodes = [];
        data.freeNodes.forEach(unit => {
            if (unit.rootNode != null) {
                let freeNode = this.createFreeNodeUnit();
                // console.log(unit);
                freeNode.setRootNode(unit.rootNode);
                freeNode.mindDisplayType = this.mindDisplayType;
                unit.rootLine.forEach(line => {
                    freeNode.lineMindElementDataDict.put(line.id, line);
                    IdGenerator.shared.setId(line.id);
                    freeNode.textElementLineMindElementDataDict.put(line.lineContent.targetId, line);
                });
                unit.generalizationLine.forEach(line => {
                    freeNode.generalizationLineMindElementDataDict.put(line.id, line);
                    IdGenerator.shared.setId(line.id);
                });
                unit.generalizationNode.forEach(node => {
                    freeNode.setGeneralizationNode(node);
                });
                unit.encircle.forEach(line => {
                    freeNode.encircleMindElementDataDict.put(line.id, line);
                    IdGenerator.shared.setId(line.id);
                });
                unit.explainData.forEach(cell => {
                    freeNode.explainMindElementDataDict.put(cell.id, cell);
                    IdGenerator.shared.setId(cell.id);
                });
                if (unit.globalLayout == NodeLayoutType.LAYOUT_UNKNOWN) {
                    freeNode.setElementLayout(data.globalLayout, true);
                } else {
                    freeNode.setElementLayout(unit.globalLayout, true);
                }
                if (unit.globalLineLayout == LineLayout.UNKNOWN) {
                    freeNode.globalLineLayout = data.globalLineLayout;
                } else {
                    freeNode.globalLineLayout = unit.globalLineLayout;
                }
            }
        });
        this.setGlobalLayout(data.globalLayout, true);
        this.refresh()
     }
  
     setTimeLineMindDataFormCache(data) {
        this.mindkey = data.mindkey;
        if (data == null || data.title == null || data.title == undefined) {
            return
        }
        this.timeMindTypeNodeUnit.title = data.title;
        this.mindDisplayType = data.mindDisplayType == undefined || data.mindDisplayType == null ? MindDisplayType.MindMap : data.mindDisplayType;
        IdGenerator.shared.setId(data.title.id);
        this.timeMindTypeNodeUnit.baseLine = data.baseLine;
        IdGenerator.shared.setId(data.baseLine.id);
        this.mindBGColor = data.mindBGColor;
        this.settingData = data.settingData.copy()
        this.mindMapStyleColor = new MindMapStyleColor(data.styleIndex);
        this.timeMindTypeNodeUnit.setMindMapStyleColor(data.styleIndex);
        this.mindGlobalAudio = data.mindGlobalAudio;
        this.mindBGStripesType = data.mindBGStripesType;
        data.mainMindElement.forEach(node => {
            this.timeMindTypeNodeUnit.mainMindElementDataDict.put(node.id, node);
            IdGenerator.shared.setId(node.id);
            IdGenerator.shared.setId(node.head.id);
            IdGenerator.shared.setId(node.title.id);
            IdGenerator.shared.setId(node.desc.id);
        });
        this.timeMindTypeNodeUnit.setElementLayout(data.globalLayout);
        this.freeNodes = [];
        data.freeNodes.forEach(unit => {
            let freeNode = this.createFreeNodeUnit();
            freeNode.setRootNode(unit.rootNode);
            freeNode.setFrameworkModle(unit.frameworkIndex);

            unit.rootLine.forEach(line => {
                freeNode.lineMindElementDataDict.put(line.id, line);
                IdGenerator.shared.setId(line.id);
                freeNode.textElementLineMindElementDataDict.put(line.lineContent.targetId, line);
            });
            unit.generalizationLine.forEach(line => {
                freeNode.generalizationLineMindElementDataDict.put(line.id, line);
                IdGenerator.shared.setId(line.id);
            });
            unit.generalizationNode.forEach(node => {
                freeNode.setGeneralizationNode(node);
            });
            unit.encircle.forEach(line => {
                freeNode.encircleMindElementDataDict.put(line.id, line);
                IdGenerator.shared.setId(line.id);
            });
            unit.explainData.forEach(cell => {
                freeNode.explainMindElementDataDict.put(cell.id, cell);
                IdGenerator.shared.setId(cell.id);
            });
            if (unit.globalLayout == NodeLayoutType.LAYOUT_UNKNOWN) {
                freeNode.setElementLayout(data.globalLayout, true);
            } else {
                freeNode.setElementLayout(unit.globalLayout, true);
            }
        });
        data.nodeConnectLinet.forEach(line => {
            this.nodeConnectLineDataDict.put(line.id, line);
            IdGenerator.shared.setId(line.id);
        });
     }
  
     setDoubleBubbleMindDateFormCache(data) {
        this.mindkey = data.mindkey;
        if (data.rootNode != null) {
            this.doubleBubbleMindNodeUnit.setRootNode(data.rootNode);
            this.mindBGColor = data.mindBGColor;
            this.settingData = data.settingData.copy()
            this.mindMapStyleColor = new MindMapStyleColor(data.styleIndex);
            this.doubleBubbleMindNodeUnit.setMindMapStyleColor(data.styleIndex);
            this.mindGlobalAudio = data.mindGlobalAudio;
            this.mindBGStripesType = data.mindBGStripesType;
        }
        this.mindDisplayType = data.mindDisplayType == undefined || data.mindDisplayType == null ? MindDisplayType.MindMap : data.mindDisplayType;
        this.globalLineLayout = data.globalLineLayout;
        this.doubleBubbleMindNodeUnit.globalLineLayout = data.globalLineLayout;
        if (data.rootTreeBrotherNode != null) {
            this.doubleBubbleMindNodeUnit.setRootTreeBrotherNode(data.rootTreeBrotherNode);
        }
        this.doubleBubbleMindNodeUnit.commonGroundMindElementDatas = data.commonGroundMindElementDatas;

        data.rootLine.forEach(line => {
            if (line.lineContent != null) {
                let node = this.doubleBubbleMindNodeUnit.getNodeById(line.lineContent.targetId);
                if (!node.isEmpty()) {
                    this.doubleBubbleMindNodeUnit.lineMindElementDataDict.put(line.id, line);
                    IdGenerator.shared.setId(line.id);
                    this.doubleBubbleMindNodeUnit.textElementLineMindElementDataDict.put(line.lineContent.targetId, line);
                }
            }
        });

        data.nodeConnectLinet.forEach(line => {
            this.nodeConnectLineDataDict.put(line.id, line);
            IdGenerator.shared.setId(line.id);
        });
        this.freeNodes = [];
        data.freeNodes.forEach(unit => {
            let freeNode = this.createFreeNodeUnit();
            freeNode.setRootNode(unit.rootNode);
            unit.rootLine.forEach(line => {
                freeNode.lineMindElementDataDict.put(line.id, line);
                IdGenerator.shared.setId(line.id);
                freeNode.textElementLineMindElementDataDict.put(line.lineContent.targetId, line);
            });
            unit.generalizationLine.forEach(line => {
                freeNode.generalizationLineMindElementDataDict.put(line.id, line);
                IdGenerator.shared.setId(line.id);
            });
            unit.generalizationNode.forEach(node => {
                freeNode.setGeneralizationNode(node);
            });
            unit.encircle.forEach(line => {
                freeNode.encircleMindElementDataDict.put(line.id, line);
                IdGenerator.shared.setId(line.id);
            });
            unit.explainData.forEach(cell => {
                freeNode.explainMindElementDataDict.put(cell.id, cell);
                IdGenerator.shared.setId(cell.id);
            });
            if (unit.globalLayout == NodeLayoutType.LAYOUT_UNKNOWN) {
                freeNode.setElementLayout(data.globalLayout, true);
            } else {
                freeNode.setElementLayout(unit.globalLayout, true);
            }
            if (unit.globalLineLayout == LineLayout.UNKNOWN) {
                freeNode.globalLineLayout = data.globalLineLayout;
            } else {
                freeNode.globalLineLayout = unit.globalLineLayout;
            }
        });
        this.setGlobalLayout(data.globalLayout, true);
     }
     
     setGlobalLayout(type, isInit = false) {
        this.globalLayout = type; 
        this.globalLayout = this.getGlobalLayout();
        if (this.mainMindNodeUnit != null) {
            this.mainMindNodeUnit.setElementLayout(this.globalLayout, isInit);
        }
        if (this.timeMindTypeNodeUnit != null) {
            this.timeMindTypeNodeUnit.setElementLayout(this.globalLayout);
        }
        if (this.doubleBubbleMindNodeUnit != null) {
            this.doubleBubbleMindNodeUnit.setElementLayout(NodeLayoutType.LAYOUT_DOUBLE_BUBBLE);
        }
    }

     createFreeNodeUnit() {
        let mindNodeUnit = new LineMindTypeNodeUnit(this.mindBGColor, this.mindType, this.mindMapStyleColor.currentSelectedIndex, true);
        if (this.mindType == MindType.TIME_MAP) {
            mindNodeUnit.setElementLayout(NodeLayoutType.LAYOUT_RIGHT, true);
            mindNodeUnit.globalLineLayout = LineLayout.RIGHT_ANGLE_CORNER_LINE;
        } else {
            if (this.getGlobalLayout() == NodeLayoutType.LAYOUT_FORM || this.getGlobalLayout() == NodeLayoutType.LAYOUT_FORM_HORIZONTAL || this.getGlobalLayout() == NodeLayoutType.LAYOUT_TRIANGLE) {
                mindNodeUnit.setElementLayout(NodeLayoutType.LAYOUT_RIGHT, true);
            } else {
                mindNodeUnit.setElementLayout(this.getFreeGlobalLayout(), true);
            }
            mindNodeUnit.globalLineLayout = this.globalLineLayout;
        }

        mindNodeUnit.setMindBGColor(this.mindBGColor);
        this.freeNodes.push(mindNodeUnit);

        return mindNodeUnit;
    }
  
     getGlobalLayout() {
        if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            return NodeLayoutType.LAYOUT_TREE_LEFT;
        } else {
            return this.globalLayout;
        }
    }
  
     getFreeGlobalLayout() {
        if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            return NodeLayoutType.LAYOUT_BUBBLE;
        } else if (this.globalLayout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT_RIGHT) {
            return NodeLayoutType.LAYOUT_TOP_TREE_RIGHT;
        } else if (this.globalLayout == NodeLayoutType.LAYOUT_TREE_LEFT_RIGHT) {
            return NodeLayoutType.LAYOUT_TREE_RIGHT;
        } else if (this.globalLayout == NodeLayoutType.LAYOUT_LEFT_RIGHT ||
            this.globalLayout == NodeLayoutType.LAYOUT_RIGHT_LEFT ||
            this.globalLayout == NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE ||
            this.globalLayout == NodeLayoutType.LAYOUT_HORIZONTAL ||
            this.globalLayout == NodeLayoutType.LAYOUT_VERTICAL ||
            this.globalLayout == NodeLayoutType.LAYOUT_FISH_RIGHT ||
            this.globalLayout == NodeLayoutType.LAYOUT_FISH_LEFT ||
            this.globalLayout == NodeLayoutType.LAYOUT_FORM ||
            this.globalLayout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL ||
            this.globalLayout == NodeLayoutType.LAYOUT_TRIANGLE) {
            return NodeLayoutType.LAYOUT_RIGHT;
        } else {
            return this.globalLayout;
        }
     }
  
    getNodeById(id) {
        let node = new LineMindTypeNode();
        if (this.mainMindNodeUnit != null) {
            node = this.mainMindNodeUnit.getNodeById(id);
        }
        if (!node.isEmpty()) {
            return node;
        }
        let freeNodesLength = this.freeNodes.length
        for (let index = 0; index < freeNodesLength; index++) {
            let unit = this.freeNodes[index];
            node = unit.getNodeById(id);
            if (!node.isEmpty()) {
                return node;
            }
        }
        node = this.doubleBubbleMindNodeUnit.getNodeById(id);
        if (!node.isEmpty()) {
            return node;
        }
        if (node.isEmpty() && this.nodeConnectLineDataDict.containsKey(id)) {
            return new LineMindTypeNode(this.nodeConnectLineDataDict.get(id));
        }
        if (node.isEmpty()) {
            return new LineMindTypeNode(this.timeMindTypeNodeUnit.getNodeById(id));
        }
        return node;
    }

    checkRemoveEncircleLine(unit) {
        if (unit == null) {
            return
        }
        var removeEncircleMindLineKeys = []
        if (unit.encircleMindElementDataDict != null &&
            !unit.encircleMindElementDataDict.isEmpty()) {

            let encircleDatas = unit.encircleMindElementDataDict.values()

            for (let index = 0; index < encircleDatas.length; index++) {
                let mind = encircleDatas[index]
                if (mind.lineContent == null) {
                    removeEncircleMindLineKeys.push(mind)
                    continue;
                }
                let node = this.getNodeById(mind.parentNodeId)
                let hit = !node.isEmpty()
                for (let targetIndex = 0; targetIndex < mind.lineContent.targetIds; targetIndex++) {
                    let cell = this.getNodeById(mind.lineContent.targetIds[targetIndex])
                    if (!cell.isEmpty()) {
                        hit = true
                        break
                    }
                }
                if (!hit) {
                    removeEncircleMindLineKeys.push(mind)
                }
            }
        }
        if (removeEncircleMindLineKeys.length > 0) {
            for (let index = 0; index < removeEncircleMindLineKeys.length; index++) {
                unit.removeEncircleMindElementData(removeEncircleMindLineKeys[index]);
            }
        }
    }

    removeUselessElement() {
        this.mainMindNodeUnit.removeUselessElement()
        this.doubleBubbleMindNodeUnit.removeUselessElement()
        this.freeNodes.forEach(unit => {
            unit.removeUselessElement()
            this.checkRemoveEncircleLine(unit)
        })
        this.checkRemoveEncircleLine(this.mainMindNodeUnit)

        var removeNodeConnectLineKeys = []
        let nodeConnectLineDatas = this.nodeConnectLineDataDict.values();
        for (let index = 0; index < nodeConnectLineDatas.length; index++) {
            let line = nodeConnectLineDatas[index]
            let target = this.getNodeById(line.parentNodeId).value;
            let to = this.getNodeById(line.lineContent.targetId).value;
            if (target.isEmpty() || to.isEmpty()) {
                removeNodeConnectLineKeys.push(line.id);
            }
        }
        removeNodeConnectLineKeys.forEach(id => {
            this.nodeConnectLineDataDict.remove(id);
        });
    }

    isRootNode(id) {
        let node = this.getNodeById(id);
        if (node.isEmpty() || (node.value.type != MindElementType.MAIN_SUBJECT && node.value.type != MindElementType.SUBJECT)) {
            return false;
        }
        let result = false;
        result = result || this.mainMindNodeUnit.isRootNode(node.value.id);
        this.freeNodes.forEach(unit => {
            result = result || unit.isRootNode(node.value.id);
        })
        result = result || this.timeMindTypeNodeUnit.isRootNode(node.value.id);
        result = result || this.doubleBubbleMindNodeUnit.isRootNode(node.value.id);
        return result;
    }

    refreshEncircle(encircleMindElementDataDict) {
        if (arguments.length == 0) {
            if (this.mainMindNodeUnit != null) {
                this.refreshEncircle(this.mainMindNodeUnit.encircleMindElementDataDict);
            }
            for (let index = 0; index < this.freeNodes.length; index++) {
                let unit = this.freeNodes[index];
                this.refreshEncircle(unit.encircleMindElementDataDict);
            }
        } else if (arguments.length == 1) {
            if (encircleMindElementDataDict == null || encircleMindElementDataDict.isEmpty()) {
                return;
            }
            let encircleMindElementDataDictArr = encircleMindElementDataDict.keys();
            for (let index = 0; index < encircleMindElementDataDictArr.length; index++) {
                let key = encircleMindElementDataDictArr[index];
                let mind = encircleMindElementDataDict.get(key);
                let targetIds = new Array();
                if (mind.lineContent == null) {
                    continue;
                }
                if (mind.lineContent.targetIds.isEmpty()) {
                    targetIds.push(mind.lineContent.targetId > -1 ? mind.lineContent.targetId : mind.parentNodeId);
                } else {
                    targetIds = mind.lineContent.targetIds;
                }
                if (targetIds.isEmpty()) {
                    continue;
                }
                if (!this.isRootNode(targetIds[0])) {
                    continue;
                }

                mind.lineContent.encircleShapeType = mind.lineContent.encircleShapeType == EncircleShapeType.LAYOUT_TRAPEZOID ?
                    EncircleShapeType.LAYOUT_RECTANGLE_2 : mind.lineContent.encircleShapeType;

                let rectList = new Array();
                for (let count = 0; count < targetIds.length; count++) {
                    let id = targetIds[count];
                    let node = this.getNodeById(id);
                    if (node.isEmpty()) {
                        continue;
                    }

                    let rect = new NodesRectCalculation().calcule(node);
                    rectList.push(rect);
                }
                if (rectList.isEmpty()) {
                    continue;
                }
                let padding = mind.lineContent == null ? 0 : mind.lineContent.padding;
                let rect = new RectsMergeCalculation().calcule(rectList);

                let textHeight = mind.lineContent != null && mind.lineContent.isContainText() ? mind.lineContent.textContent.height : 0;
                if (mind.lineContent != null && mind.lineContent.isContainText()) {
                    mind.lineContent.textContent.x = new EncircleNodesPointsCalculation().space;
                    mind.lineContent.textContent.y = 0;
                }
                mind.x = rect.x - new EncircleNodesPointsCalculation().space - padding;
                mind.y = rect.y - new EncircleNodesPointsCalculation().space - padding - textHeight;
                mind.width = rect.width() + new EncircleNodesPointsCalculation().space * 2 + padding * 2;
                mind.height = rect.height() + new EncircleNodesPointsCalculation().space * 2 + padding * 2 + textHeight;
                mind.lineContent.encircleNodesPoint = new Array();

                if (mind.lineContent != null && mind.lineContent.isContainText()) {
                    if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_MIDDLE ||
                            mind.lineContent.encircleTitleType == EncircleTitleLayoutType.MIDDLE_MIDDLE) {
                        mind.lineContent.textContent.x = (mind.width - mind.lineContent.textContent.width)/2;
                        mind.lineContent.textContent.y = 0;
                    } else if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_RIGHT ||
                            mind.lineContent.encircleTitleType == EncircleTitleLayoutType.MIDDLE_RIGHT) {
                        mind.lineContent.textContent.x = mind.width - mind.lineContent.textContent.width - new EncircleNodesPointsCalculation().space;
                        mind.lineContent.textContent.y = 0;
                    } else if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_FULL) {
                        mind.lineContent.textContent.width = mind.width;
                        mind.lineContent.textContent.x = 0;
                        mind.lineContent.textContent.y = 0;
                    } else if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_RIGHT) {
                        mind.lineContent.textContent.x = mind.width - mind.lineContent.textContent.width;
                        mind.lineContent.textContent.y = 0;
                    } else if (mind.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_LEFT) {
                        mind.lineContent.textContent.x = 0;
                        mind.lineContent.textContent.y = 0;
                    } else {
                        mind.lineContent.textContent.x = new EncircleNodesPointsCalculation().space;
                        mind.lineContent.textContent.y = 0;
                    }
                }
            }
        }

    }

    getNodeConnectLine(id) {
        var lines = [];
        let keys = this.nodeConnectLineDataDict.keys();
        for (let index = 0; index < keys.length; index++) {
            let line = this.nodeConnectLineDataDict.get(keys[index]);
            if (line.isEmpty() || line.lineContent == null) {
                continue;
            }
            if (line.parentNodeId == id || line.lineContent.targetId == id) {
                lines.push(line);
            }
        }
        return lines;
    }

    checkNodeConnectLinePointByChangeSize(original, data) {
        if (original.isEmpty() || data.isEmpty() ||
            (original.width == data.width && original.height == data.height)) {
            return;
        }
        let nodeConnectLines = this.getNodeConnectLine(data.id);
        if (!nodeConnectLines.isEmpty()) {
            let length = nodeConnectLines.length;
            for (let index = 0; index < length; index++) {
                let cell = nodeConnectLines[index]
                if (cell.lineContent == null) {
                    continue;
                }
                if (cell.parentNodeId == data.id) {
                    let targetOriginalCenterPoint = original.getCenterPoint();
                    let targetCenterPoint = data.getCenterPoint();
                    let startControlPointScaleX = (targetOriginalCenterPoint.x - cell.lineContent.startControlPointX) / original.width;
                    let startControlPointScaleY = (targetOriginalCenterPoint.y - cell.lineContent.startControlPointY) / original.height;

                    if (original.y < cell.lineContent.startControlPointY &&
                        original.y + original.height > cell.lineContent.startControlPointY &&
                        original.x + original.width < cell.lineContent.startControlPointX) { // 正右侧
                        cell.lineContent.startControlPointX = data.x + data.width + (cell.lineContent.startControlPointX - original.x - original.width);
                        cell.lineContent.startControlPointY = (targetCenterPoint.y - startControlPointScaleY * (data.height));
                    } else if (original.y < cell.lineContent.startControlPointY &&
                        original.y + original.height > cell.lineContent.startControlPointY &&
                        original.x > cell.lineContent.startControlPointX) { // 正左侧
                        cell.lineContent.startControlPointX = data.x - (original.x - cell.lineContent.startControlPointX);
                        cell.lineContent.startControlPointY = (targetCenterPoint.y - startControlPointScaleY * (data.height));
                    } else if (original.y > cell.lineContent.startControlPointY &&
                        original.x + original.width > cell.lineContent.startControlPointX &&
                        original.x < cell.lineContent.startControlPointX) { // 正上方
                        cell.lineContent.startControlPointX = (targetCenterPoint.x - startControlPointScaleX * (data.width));
                        cell.lineContent.startControlPointY = data.y - (original.y - cell.lineContent.startControlPointY);
                    } else if (original.y + original.height < cell.lineContent.startControlPointY &&
                        original.x + original.width > cell.lineContent.startControlPointX &&
                        original.x < cell.lineContent.startControlPointX) { // 正下方
                        cell.lineContent.startControlPointX = (targetCenterPoint.x - startControlPointScaleX * (data.width));
                        cell.lineContent.startControlPointY = data.y + data.height + (cell.lineContent.startControlPointY - original.y - original.height);
                    } else {
                        cell.lineContent.startControlPointX = (targetCenterPoint.x - startControlPointScaleX * (data.width));
                        cell.lineContent.startControlPointY = (targetCenterPoint.y - startControlPointScaleY * (data.height));
                    }
                }
                if (cell.lineContent.targetId == data.id) {
                    let targetOriginalCenterPoint = original.getCenterPoint();
                    let endControlPointScaleX = (targetOriginalCenterPoint.x - cell.lineContent.endControlPointX) / original.width;
                    let endControlPointScaleY = (targetOriginalCenterPoint.y - cell.lineContent.endControlPointY) / original.height;

                    let targetCenterPoint = data.getCenterPoint();

                    if (original.y < cell.lineContent.endControlPointY &&
                        original.y + original.height > cell.lineContent.endControlPointY &&
                        original.x + original.width < cell.lineContent.endControlPointX) { // 正右侧
                        cell.lineContent.endControlPointX = data.x + data.width + (cell.lineContent.endControlPointX - original.x - original.width);
                        cell.lineContent.endControlPointY = (targetCenterPoint.y - endControlPointScaleY * (data.height));
                    } else if (original.y < cell.lineContent.endControlPointY &&
                        original.y + original.height > cell.lineContent.endControlPointY &&
                        original.x > cell.lineContent.endControlPointX) { // 正左侧
                        cell.lineContent.endControlPointX = data.x - (original.x - cell.lineContent.endControlPointX);
                        cell.lineContent.endControlPointY = (targetCenterPoint.y - endControlPointScaleY * (data.height));
                    } else if (original.y > cell.lineContent.endControlPointY &&
                        original.x + original.width > cell.lineContent.endControlPointX &&
                        original.x < cell.lineContent.endControlPointX) { // 正上方
                        cell.lineContent.endControlPointX = (targetCenterPoint.x - endControlPointScaleX * (data.width));
                        cell.lineContent.endControlPointY = data.y - (original.y - cell.lineContent.endControlPointY);
                    } else if (original.y + original.height < cell.lineContent.endControlPointY &&
                        original.x + original.width > cell.lineContent.endControlPointX &&
                        original.x < cell.lineContent.endControlPointX) { // 正下方
                        cell.lineContent.endControlPointX = (targetCenterPoint.x - endControlPointScaleX * (data.width));
                        cell.lineContent.endControlPointY = data.y + data.height + (cell.lineContent.endControlPointY - original.y - original.height);
                    } else {
                        cell.lineContent.endControlPointX = (targetCenterPoint.x - endControlPointScaleX * (data.width));
                        cell.lineContent.endControlPointY = (targetCenterPoint.y - endControlPointScaleY * (data.height));
                    }
                }
            }

        }
    }

    refresh(isChange = true, isRefreshUI = true, isCheckConnectLine = true, checkNodeViewOffScreen = true) {

        MindElementCalculation.layoutRefreshCalculeDataList = []
        this.removeUselessElement()
        var refreshForwardRelativePoint = new HashMap();
        if (isCheckConnectLine) {
            let keys = this.nodeConnectLineDataDict.keys();
            let length = keys.length;
            for (let index = 0; index < length; index++) {
                let key = keys[index];
                let line = this.nodeConnectLineDataDict.get(key);
                if (line.lineContent == null) {
                    continue;
                }
                let target = this.getNodeById(line.parentNodeId).value;
                let to = this.getNodeById(line.lineContent.targetId).value;
                if (target.isEmpty() || to.isEmpty()) {
                    continue;
                }
                let lineContent = line.lineContent
                if (lineContent == null || lineContent.startControlPointX == -1 || lineContent.startControlPointY == -1 ||
                    lineContent.endControlPointX == -1 || lineContent.endControlPointY == -1) {
                    continue;
                }
                let list = new Array();
                let targetCenterPoint = target.getCenterPoint();
                let toCenterPoint = to.getCenterPoint();
                list.push((targetCenterPoint.x - lineContent.startControlPointX) / target.width);
                list.push((targetCenterPoint.y - lineContent.startControlPointY) / target.height);

                list.push((toCenterPoint.x - lineContent.endControlPointX) / to.width);
                list.push((toCenterPoint.y - lineContent.endControlPointY) / to.height);
                refreshForwardRelativePoint.put(key, list);
            }
        }
        let rootOriginalDatas = [];
        if (this.mainMindNodeUnit.rootTreeNode != null &&
            !this.mainMindNodeUnit.rootTreeNode.isEmpty() &&
            this.mainMindNodeUnit.rootTreeNode.value.x > 1 &&
            this.mainMindNodeUnit.rootTreeNode.value.y > 1 &&
            this.mainMindNodeUnit.rootTreeNode.children.length == 0) {
            rootOriginalDatas.push(this.mainMindNodeUnit.rootTreeNode.value.copy());
        }
        if (this.doubleBubbleMindNodeUnit.rootTreeNode != null &&
            !this.doubleBubbleMindNodeUnit.rootTreeNode.isEmpty() &&
            this.doubleBubbleMindNodeUnit.rootTreeNode.value.x > 1 &&
            this.doubleBubbleMindNodeUnit.rootTreeNode.value.y > 1 &&
            this.doubleBubbleMindNodeUnit.rootTreeNode.children.length == 0) {
            rootOriginalDatas.push(this.doubleBubbleMindNodeUnit.rootTreeNode.value.copy());
        }
        let freeNodesLength = this.freeNodes.length;
        for (let index = 0; index < freeNodesLength; index++) {
            let unit = this.freeNodes[index];
            if (unit.rootTreeNode != null &&
                !unit.rootTreeNode.isEmpty() &&
                unit.rootTreeNode.children.length == 0) {
                rootOriginalDatas.push(unit.rootTreeNode.value.copy())
            }
        }

        this.mainMindNodeUnit.refreshLayout(isChange, this.settingData);
        if (!isChange && (this.mindType == MindType.LINE_MAP || this.mindType == MindType.FLOW_CHART) &&
            this.globalLayout != NodeLayoutType.LAYOUT_TREE_LEFT_RIGHT &&
            (this.globalLineLayout == LineLayout.RIGHT_ANGLE_LINE ||
                this.globalLineLayout == LineLayout.RIGHT_ANGLE_CORNER_LINE ||
                this.globalLineLayout == LineLayout.FULL_RIGHT_ANGLE_CORNER_LINE ||
                this.globalLineLayout == LineLayout.RIGHT_ANGLE_CORNER_ARROW_LINE ||
                this.globalLineLayout == LineLayout.FULL_RIGHT_ANGLE_CORNER_ARROW_LINE ||
                this.globalLineLayout == LineLayout.STRAIGHT_LINE_2)) {
            this.mainMindNodeUnit.setlineThicken(true);
        }
        this.doubleBubbleMindNodeUnit.refreshLayout(isChange, this.settingData);
        let node = null;
        if (this.mindDisplayType == MindDisplayType.Outline) {
            node = this.mainMindNodeUnit.getOutlinePreviousNode();
        }
        this.freeNodes.forEach(unit => {
            if (this.mindDisplayType == MindDisplayType.Outline) {
                unit.setOutlinePreviousNode(node)
            }
            unit.refreshLayout(isChange, this.settingData);
            node = unit.getOutlinePreviousNode()
        });
        this.timeMindTypeNodeUnit.refreshLayout(isChange, this.settingData);

        let rootOriginalDatasLength = rootOriginalDatas.length;
        for (let index = 0; index < rootOriginalDatasLength; index++) {
            let rootData = rootOriginalDatas[index]
            let cell = this.getNodeById(rootData.id);
            if (cell.isEmpty()) {
                continue;
            }
            cell.value.x = rootData.x - (cell.value.width - rootData.width) / 2;
            cell.value.y = rootData.y - (cell.value.height - rootData.height) / 2;
            this.checkNodeConnectLinePointByChangeSize(rootData, cell.value);
        }

        this.refreshEncircle();
        let keys = refreshForwardRelativePoint.keys();
        let length = keys.length;
        for (let index = 0; index < length; index++) {
            let key = keys[index];
            if (!this.nodeConnectLineDataDict.containsKey(key)) {
                continue;
            }
            let list = refreshForwardRelativePoint.get(key);

            let line = this.nodeConnectLineDataDict.get(key);
            let target = this.getNodeById(line.parentNodeId).value;
            let to = this.getNodeById(line.lineContent.targetId).value;
            if (target.isEmpty() || to.isEmpty() || list.length != 4) {
                continue;
            }
            let lineContent = line.lineContent;
            if (lineContent == null || lineContent.startControlPointX == -1 || lineContent.startControlPointY == -1 ||
                lineContent.endControlPointX == -1 || lineContent.endControlPointY == -1) {
                continue;
            }
            let targetCenterPoint = target.getCenterPoint();
            let toCenterPoint = to.getCenterPoint();

            let startControlPointX = targetCenterPoint.x - list[0] * target.width;
            if (Math.abs(startControlPointX - lineContent.startControlPointX) > 5) {
                lineContent.startControlPointX = startControlPointX;
            }
            let startControlPointY = targetCenterPoint.y - list[1] * (target.height);
            if (Math.abs(startControlPointY - lineContent.startControlPointY) > 5) {
                lineContent.startControlPointY = startControlPointY;
            }

            let endControlPointX = toCenterPoint.x - list[2] * to.width;
            if (Math.abs(endControlPointX - lineContent.endControlPointX) > 5) {
                lineContent.endControlPointX = endControlPointX
            }
            let endControlPointY = toCenterPoint.y - list[3] * (to.height);
            if (Math.abs(endControlPointY - lineContent.endControlPointY) > 5) {
                lineContent.endControlPointY = endControlPointY;
            }
        }
        let nodeConnectLineCalcul = new NodeConnectLineCalcul();
        nodeConnectLineCalcul.setData(this.mindType, this.mainMindNodeUnit,
            this.freeNodes,
            this.nodeConnectLineDataDict,
            this.timeMindTypeNodeUnit,
            this.doubleBubbleMindNodeUnit,
            this.mindBGColor,
            this.lastClickPoint);
        nodeConnectLineCalcul.refreshNodeConnectLineLayout();
        MindElementCalculation.layoutRefreshCalculeDataList = []
    }

    isAvailable() {
        if (this.mindType == MindType.LINE_MAP || this.mindType == MindType.FLOW_CHART || this.mindType == MindType.BUBBLE_MAP) {
            return this.mainMindNodeUnit != null && this.mainMindNodeUnit.rootTreeNode != null && !this.mainMindNodeUnit.rootTreeNode.isEmpty()
        } else if (this.mindType == MindType.DOUBLE_BUBBLE_MAP) {
            return this.doubleBubbleMindNodeUnit != null && this.doubleBubbleMindNodeUnit.rootTreeNode != null && !this.doubleBubbleMindNodeUnit.rootTreeNode.isEmpty()
        } else if (this.mindType == MindType.TIME_MAP) {
            return this.timeMindTypeNodeUnit != null && this.timeMindTypeNodeUnit.title != null && !this.timeMindTypeNodeUnit.title.isEmpty()
        }
        return false;
    }
}


export default GroupMindMapData