import Colors from "../../../utils/Colors"

/**
 * ProjectName: MindMap
 * Created by tony on 2024/6/5
 * Copyright(c) 2024 mindyushu.com
 */
import BaseElementView from "./BaseElementView";
import HandwritingType from "../../../viewmodel/datatype/HandwritingType";
import LinePath from "../../../viewmodel/core/base/LinePath";
import CGRect from "../../../viewmodel/core/base/basedata/Rect";
import UiUtil from "../../../utils/UiUtil";


class HandwritingView extends BaseElementView {
    constructor(viewCanvs, data) {
        super(viewCanvs, data);
        this.path;  //包裹线
        this.arrow; //用来画箭头的元素
        this.selectPath; //选中的线条

        this.radius = 4;
        this.color; //线条色
        this.fillColor; //填充色
        this.lineWidth = 1;
        this.isAnimation = false;
        this.duration = 1;
        this.onDraw();
    }

    setAnimation(animation, duration) {
        this.isAnimation = animation;
        this.duration = duration;
        this.onDraw();
        this.isAnimation = false;
    }

    setSelectedHoverLayer(value) {
        if (this.isSelected) {
            return
        }
        if (this.mindElementData == null) {
            return;
        }
        if (this.isHover == value) {
            return;
        }
        this.isHover = value;
        this.isRefresh = true;
        this.onDraw();
    }

    setSelected(bool) {
        if (this.isSelected == bool) {
            return;
        }
        this.isRefresh = true;
        this.isSelected = bool;
        this.onDraw();
    }

    refresh() {
        if (!this.isRefresh) {
            return;
        }

        super.refresh();
        this.onDraw();
    }

    onDraw() {
        this.drawLine();
    }

    drawLine() {
        this.radius = 8;
        if (this.mindElementData == null || this.mindElementData.handwritingContent == null) {
            return;
        }

        if (this.path == null) {
            this.path = document.createElementNS('http://www.w3.org/2000/svg', "path")
            this.context.appendChild(this.path)
        }
        if (this.selectPath != null && this.selectPath.parentNode != null) {    //移除选中
            this.selectPath.parentNode.removeChild(this.selectPath);
            this.selectPath = null
        }
        let handwritingContent = this.mindElementData.handwritingContent;

        //类型可能为字符串要改为数字
        switch (handwritingContent.type) {
            case HandwritingType.FREE_WRITE:
                this.drawFreeLine();
                break;
            case HandwritingType.STRAIGHT_LINE:
                this.drawStraightLine()
                break
            case HandwritingType.ARROW:
                this.drawStraightArrowLine()
                break;
            case HandwritingType.RECTANGLE:
                this.drawRectangleLine()
                break;
            case HandwritingType.CIRCULAR:
                this.drawCircularLine()
                break;
            default:
        }
        if (this.mindElementData.isShadow) {
            this.context.setAttribute('filter', 'url(#baseLinemapLineShadow)');
        } else {
            this.context.setAttribute('filter', '');
        }
    }

    drawFreeLine() {
        let handwritingContent = this.mindElementData.handwritingContent
        let linePath = new LinePath();

        linePath.moveTo(handwritingContent.startPointX, handwritingContent.startPointY);
        for (let index = 0; index < handwritingContent.points.length; index++) {
            linePath.lineTo(handwritingContent.points[index].x, handwritingContent.points[index].y);
        }
        linePath.lineTo(handwritingContent.endPointX, handwritingContent.endPointY);
        this.path.setAttribute("stroke-linecap", "round")
        this.path.setAttribute("stroke-linejoin", "round")
        
        this.renderCanvas(this.path, linePath, Colors.getUiColor(handwritingContent.strokeColor, handwritingContent.strokeColorAlpha),
            "", handwritingContent.lineWidth);
        if (this.isSelected) {
            if (this.selectPath == null) {
                this.selectPath = document.createElementNS('http://www.w3.org/2000/svg', "path")
                this.context.appendChild(this.selectPath)
                this.selectPath.setAttribute("stroke-linecap", "round")
                this.selectPath.setAttribute("stroke-linejoin", "round")
            }
            this.renderCanvas(this.selectPath, linePath, Colors.getUiColor(0xff3300, 0.5),
                "", handwritingContent.lineWidth + 3);
        }
    }
    
    drawStraightLine() {
        let handwritingContent = this.mindElementData.handwritingContent
        let linePath = new LinePath();

        linePath.moveTo(handwritingContent.startPointX, handwritingContent.startPointY);
        linePath.lineTo(handwritingContent.endPointX, handwritingContent.endPointY);

        this.path.setAttribute("stroke-linecap", "square")
        this.renderCanvas(this.path, linePath, Colors.getUiColor(handwritingContent.strokeColor, handwritingContent.strokeColorAlpha),
            "", handwritingContent.lineWidth);
        if (this.isSelected) {
            if (this.selectPath == null) {
                this.selectPath = document.createElementNS('http://www.w3.org/2000/svg', "path")
                this.context.appendChild(this.selectPath)
                this.selectPath.setAttribute("stroke-linecap", "square")
            }
            this.renderCanvas(this.selectPath, linePath, Colors.getUiColor(0xff3300, 0.5),
                "", handwritingContent.lineWidth + 3);
        }
    }

    drawStraightArrowLine() {
        let handwritingContent = this.mindElementData.handwritingContent
        let linePath = new LinePath();

        linePath.moveTo(handwritingContent.startPointX, handwritingContent.startPointY);
        linePath.lineTo(handwritingContent.endPointX, handwritingContent.endPointY);

        this.path.setAttribute("stroke-linecap", "square")
        this.renderCanvas(this.path, linePath, Colors.getUiColor(handwritingContent.strokeColor, handwritingContent.strokeColorAlpha),
            "", handwritingContent.lineWidth);
        
        let p = new UiUtil().getArrowPoint(handwritingContent.startPoint(), handwritingContent.endPoint(), handwritingContent.lineWidth);
        if (p.length != 3) {
            return;
        }
        let arrowPath = new LinePath();
        arrowPath.moveTo(p[0].x, p[0].y);
        arrowPath.lineTo(p[1].x, p[1].y);
        arrowPath.lineTo(p[2].x, p[2].y);
        arrowPath.lineTo(p[0].x, p[0].y);
        arrowPath.lineTo(p[1].x, p[1].y);
        if (this.arrow == null) {
            this.arrow = document.createElementNS('http://www.w3.org/2000/svg', "path")
            this.context.appendChild(this.arrow)
        }
        this.renderCanvas(this.arrow, arrowPath, Colors.getUiColor(handwritingContent.strokeColor, handwritingContent.strokeColorAlpha),
        Colors.getUiColor(handwritingContent.strokeColor, handwritingContent.strokeColorAlpha), handwritingContent.lineWidth);

        if (this.isSelected) {
            if (this.selectPath == null) {
                this.selectPath = document.createElementNS('http://www.w3.org/2000/svg', "path")
                this.context.appendChild(this.selectPath)
                this.selectPath.setAttribute("stroke-linecap", "square")
            }
            this.renderCanvas(this.selectPath, linePath, Colors.getUiColor(0xff3300, 0.5),
                "", handwritingContent.lineWidth + 3);
        }
     }

    drawRectangleLine() {
        let handwritingContent = this.mindElementData.handwritingContent
        let linePath = new LinePath();
        linePath.setEffectRadius(6)

        let sx = handwritingContent.startPointX;
        let sy = handwritingContent.startPointY + (handwritingContent.endPointY - handwritingContent.startPointY) / 2;
        linePath.moveTo(sx, sy);
        linePath.lineTo(handwritingContent.startPointX, handwritingContent.endPointY);
        linePath.lineTo(handwritingContent.endPointX, handwritingContent.endPointY);
        linePath.lineTo(handwritingContent.endPointX, handwritingContent.startPointY);
        linePath.lineTo(handwritingContent.startPointX, handwritingContent.startPointY);
        linePath.lineTo(sx, sy);

        if (!Colors.isClear(this.mindElementData.handwritingContent.color)) {
            this.renderCanvas(this.path, linePath, Colors.getUiColor(handwritingContent.strokeColor, handwritingContent.strokeColorAlpha),
                Colors.getUiColor(handwritingContent.color, handwritingContent.colorAlpha), handwritingContent.lineWidth);
        } else {
            this.renderCanvas(this.path, linePath, Colors.getUiColor(handwritingContent.strokeColor, handwritingContent.strokeColorAlpha),
                "", handwritingContent.lineWidth);
        }

        if (this.isSelected) {
            if (this.selectPath == null) {
                this.selectPath = document.createElementNS('http://www.w3.org/2000/svg', "path")
                this.context.appendChild(this.selectPath)
            }
            this.renderCanvas(this.selectPath, linePath, Colors.getUiColor(0xff3300, 0.5),
                "", handwritingContent.lineWidth + 3);
        }
    }

    drawCircularLine() {
        let handwritingContent = this.mindElementData.handwritingContent
        let linePath = new LinePath();
        linePath.moveTo(this.mindElementData.width, this.mindElementData.height / 2);
        linePath.circle(this.mindElementData.width / 2, this.mindElementData.height / 2, 0, 0, 1, 0, this.mindElementData.height / 2);    //这里都是小圆
        linePath.circle(this.mindElementData.width / 2, this.mindElementData.height / 2, 0, 0, 1, this.mindElementData.width, this.mindElementData.height / 2);

        if (!Colors.isClear(this.mindElementData.handwritingContent.color)) {
            this.renderCanvas(this.path, linePath, Colors.getUiColor(handwritingContent.strokeColor, handwritingContent.strokeColorAlpha),
                Colors.getUiColor(handwritingContent.color, handwritingContent.colorAlpha), handwritingContent.lineWidth);
        } else {
            this.renderCanvas(this.path, linePath, Colors.getUiColor(handwritingContent.strokeColor, handwritingContent.strokeColorAlpha),
                "", handwritingContent.lineWidth);
        }

        if (this.isSelected) {
            if (this.selectPath == null) {
                this.selectPath = document.createElementNS('http://www.w3.org/2000/svg', "path")
                this.context.appendChild(this.selectPath)
            }
            this.renderCanvas(this.selectPath, linePath, Colors.getUiColor(0xff3300, 0.5),
                "", handwritingContent.lineWidth + 3);
        }
    }

    renderCanvas(dom, path, lineColor, fillColor, lineWidth) {
        dom.setAttribute("d", path.getLine())
        dom.setAttribute("stroke", lineColor);
        dom.setAttribute("stroke-width", lineWidth);

        dom.setAttribute("stroke-dasharray", "none");
        if (this.isAnimation) {
            dom.style.strokeDashoffset = dom.getTotalLength()
            dom.style.strokeDasharray = dom.getTotalLength()
            dom.style.animation = 'path-line-animation-dash ' + (this.duration / 1000) + 's linear forwards'
        } else {
            dom.style.strokeDashoffset = 0
            dom.style.strokeDasharray = 0
            dom.style.animation = ''
        }


        if (fillColor == null || fillColor == "" || Colors.isClear(fillColor)) {
            dom.setAttribute("fill", 'none')
        } else {
            dom.setAttribute("fill", fillColor)
        }
    }

    createBackgroundView() {

    }
}
export default HandwritingView