// package mind.yushu.com.mindmap.viewmodel.core.layout;

import NodeLayoutType from "../../../datatype/NodeLayoutType";
import RightLayout from "../../layout/RightLayout";
import BottomLayout from "../../layout/BottomLayout";
import LeftLayout from "../../layout/LeftLayout";
import LeftRightLayout from "../../layout/LeftRightLayout";
import RightLeftLayout from "../../layout/RightLeftLayout";
import RightLeftClockwiseLayout from "../../layout/RightLeftClockwiseLayout";
import BubbleLayout from "../../layout/BubbleLayout";
import FormLayut from "../../layout/FormLayut";
import TreeRightLayout from "../../layout/TreeRightLayout";
import TreeLeftLayout from "../../layout/TreeLeftLayout";
import TreeLeftRightLayout from "../../layout/TreeLeftRightLayout";
import TriangleLayout from "../../layout/TriangleLayout";
import BracketsRightLayout from "../../layout/BracketsRightLayout";
import TopLayout from "../../layout/TopLayout";
import TreeTopRightLayout from "../../layout/TreeTopRightLayout";
import TreeTopLeftLayout from "../../layout/TreeTopLeftLayout";
import TreeTopLeftRightLayout from "../../layout/TreeTopLeftRightLayout";
import HorizontalRightLayout from "../../layout/HorizontalRightLayout";
import VerticaRightLayout from "../../layout/VerticaRightLayout";
import FishLayout from "../../layout/FishLayout";
import BracketsLeftLayout from "../../layout/BracketsLeftLayout";
import FormHorizontalLayut from "../../layout/FormHorizontalLayut";
import RadiateLayout from "../../layout/RadiateLayout";
import FishLeftLayout from "../../layout/FishLeftLayout";


/**
 * ProjectName: MindMap
 * Created by tony on 6/27/21
 * Copyright(c) 2020 mindyushu.com
 */
 
class MindLayoutSelect {
    getLayout( parentType, currentType, targetType) {
        return this.getNodeNodeLayoutType(parentType, currentType, targetType);
    }

    getNodeLayout(node, nodeLayout) {  //切换节点的布局之后会调用这里的函数。

        let elementLayout = new RightLayout(); 
        let layout = node.value.layout;
        if (arguments.length == 2 && nodeLayout != null) {
            layout = nodeLayout
        }

        if (layout == NodeLayoutType.LAYOUT_RIGHT) {
            elementLayout = new RightLayout();
        } else if (layout == NodeLayoutType.LAYOUT_BOTTOM) {
            elementLayout = new BottomLayout();
        } else if (layout == NodeLayoutType.LAYOUT_LEFT) {
            elementLayout = new LeftLayout();
        } else if (layout == NodeLayoutType.LAYOUT_LEFT_RIGHT) {
            elementLayout = new LeftRightLayout();
        } else if (layout == NodeLayoutType.LAYOUT_RIGHT_LEFT) {
            elementLayout = new RightLeftLayout();
        } else if (layout == NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE) {
            elementLayout = new RightLeftClockwiseLayout();
        } else if (layout == NodeLayoutType.LAYOUT_BUBBLE) {
            elementLayout = new BubbleLayout();
        } else if (layout == NodeLayoutType.LAYOUT_FORM) {
            elementLayout = new FormLayut();
        } else if (layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
            elementLayout = new FormHorizontalLayut()
        } else if (layout == NodeLayoutType.LAYOUT_TREE_RIGHT) {
            elementLayout = new TreeRightLayout();
        } else if (layout == NodeLayoutType.LAYOUT_TREE_LEFT) {
            elementLayout = new TreeLeftLayout();
        } else if (layout == NodeLayoutType.LAYOUT_TREE_LEFT_RIGHT) {
            elementLayout = new TreeLeftRightLayout();
        } else if (layout == NodeLayoutType.LAYOUT_TRIANGLE) {
            elementLayout = new TriangleLayout();
        } else if (layout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT) {
            elementLayout = new BracketsRightLayout();
        } else if (layout == NodeLayoutType.LAYOUT_BRACKETS_LEFT) {
            elementLayout = new BracketsLeftLayout();
        } else if (layout == NodeLayoutType.LAYOUT_RADIATION_MAP) {
            elementLayout = new RightLayout();
        } else if (layout == NodeLayoutType.LAYOUT_TOP) {
            elementLayout = new TopLayout();
        } else if (layout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT) {
            elementLayout = new TreeTopRightLayout();
        } else if (layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT) {
            elementLayout = new TreeTopLeftLayout();
        } else if (layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT_RIGHT) {
            elementLayout = new TreeTopLeftRightLayout();
        } else if (layout == NodeLayoutType.LAYOUT_HORIZONTAL_RIGHT) {
            elementLayout = new HorizontalRightLayout();
        } else if (layout == NodeLayoutType.LAYOUT_VERTICAL_RIGHT) {
            elementLayout = new VerticaRightLayout();
        } else if (layout  == NodeLayoutType.LAYOUT_FISH_RIGHT) {
            elementLayout = new FishLayout();
        } else if (layout  == NodeLayoutType.LAYOUT_FISH_LEFT) {
            elementLayout = new FishLeftLayout();
        } else if (layout  == NodeLayoutType.LAYOUT_RADIATE) {
            elementLayout = new RadiateLayout();
        }

        elementLayout.mindLayoutSelect = this
        return elementLayout;
    }

    getNodeNodeLayoutType( parentType, currentType, targetType) {
        if (parentType == null && targetType == null) {
            return NodeLayoutType.LAYOUT_RIGHT;
        } else if (parentType == null) {
            return targetType;
        }
        switch (parentType) {
            case NodeLayoutType.LAYOUT_TREE_RIGHT:
                if (targetType == NodeLayoutType.LAYOUT_RIGHT ||
                        targetType == NodeLayoutType.LAYOUT_TREE_RIGHT ||
                        targetType == NodeLayoutType.LAYOUT_BRACKETS_RIGHT ||
                        targetType == NodeLayoutType.LAYOUT_FISH_RIGHT ||
                        targetType == NodeLayoutType.LAYOUT_FORM ||
                        targetType == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                    return targetType;
                } else if (currentType == NodeLayoutType.LAYOUT_RIGHT ||
                            currentType == NodeLayoutType.LAYOUT_TREE_RIGHT ||
                            currentType == NodeLayoutType.LAYOUT_BRACKETS_RIGHT ||
                            currentType == NodeLayoutType.LAYOUT_FISH_RIGHT) {
                    return currentType;
                } else {
                    return parentType;
                }
            case NodeLayoutType.LAYOUT_TOP_TREE_RIGHT:
                if (targetType == NodeLayoutType.LAYOUT_RIGHT ||
                        targetType == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT ||
                        targetType == NodeLayoutType.LAYOUT_BRACKETS_RIGHT ||
                        targetType == NodeLayoutType.LAYOUT_FISH_RIGHT ||
                        targetType == NodeLayoutType.LAYOUT_FORM ||
                        targetType == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                    return targetType;
                } else if (currentType == NodeLayoutType.LAYOUT_RIGHT ||
                    currentType == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT ||
                    currentType == NodeLayoutType.LAYOUT_BRACKETS_RIGHT ||
                    currentType == NodeLayoutType.LAYOUT_FISH_RIGHT) {
                    return currentType;
                } else {
                    return parentType;
                }
            case NodeLayoutType.LAYOUT_RIGHT:
            case NodeLayoutType.LAYOUT_BRACKETS_RIGHT:
                if (targetType == NodeLayoutType.LAYOUT_RIGHT ||
                        targetType == NodeLayoutType.LAYOUT_TREE_RIGHT ||
                        targetType == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT ||
                        targetType == NodeLayoutType.LAYOUT_BRACKETS_RIGHT ||
                        targetType == NodeLayoutType.LAYOUT_FISH_RIGHT ||
                        targetType == NodeLayoutType.LAYOUT_FORM ||
                        targetType == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                    return targetType;
                } else if (currentType == NodeLayoutType.LAYOUT_RIGHT ||
                    currentType == NodeLayoutType.LAYOUT_TREE_RIGHT ||
                    currentType == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT ||
                    currentType == NodeLayoutType.LAYOUT_BRACKETS_RIGHT ||
                    currentType == NodeLayoutType.LAYOUT_FISH_RIGHT) {
                    return currentType;
                } else {
                    return parentType;
                }
            case NodeLayoutType.LAYOUT_TREE_LEFT:
                if (targetType == NodeLayoutType.LAYOUT_LEFT ||
                    targetType == NodeLayoutType.LAYOUT_BRACKETS_LEFT ||
                        targetType == NodeLayoutType.LAYOUT_TREE_LEFT ||
                        targetType == NodeLayoutType.LAYOUT_FORM ||
                        targetType == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                    return targetType;
                } else if (currentType == NodeLayoutType.LAYOUT_LEFT ||
                        currentType == NodeLayoutType.LAYOUT_BRACKETS_LEFT ||
                        currentType == NodeLayoutType.LAYOUT_TREE_LEFT) {
                    return currentType;
                } else if (targetType == NodeLayoutType.LAYOUT_RIGHT ||
                    targetType == NodeLayoutType.LAYOUT_BRACKETS_RIGHT) {
                    return NodeLayoutType.LAYOUT_LEFT;
                } else {
                    return parentType;
                }
            case NodeLayoutType.LAYOUT_TOP_TREE_LEFT:
                if (targetType == NodeLayoutType.LAYOUT_LEFT ||
                    targetType == NodeLayoutType.LAYOUT_BRACKETS_LEFT ||
                        targetType == NodeLayoutType.LAYOUT_TOP_TREE_LEFT ||
                        targetType == NodeLayoutType.LAYOUT_FORM ||
                        targetType == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                    return targetType;
                } else if (currentType == NodeLayoutType.LAYOUT_LEFT ||
                        currentType == NodeLayoutType.LAYOUT_BRACKETS_LEFT ||
                        currentType == NodeLayoutType.LAYOUT_TOP_TREE_LEFT) {
                    return currentType;
                } else if (targetType == NodeLayoutType.LAYOUT_RIGHT ||
                    targetType == NodeLayoutType.LAYOUT_BRACKETS_RIGHT) {
                    return NodeLayoutType.LAYOUT_LEFT;
                } else {
                    return parentType;
                }
            case NodeLayoutType.LAYOUT_LEFT:
            case NodeLayoutType.LAYOUT_BRACKETS_LEFT:
                if (targetType == NodeLayoutType.LAYOUT_LEFT ||
                    targetType == NodeLayoutType.LAYOUT_BRACKETS_LEFT ||
                        targetType == NodeLayoutType.LAYOUT_TREE_LEFT ||
                        targetType == NodeLayoutType.LAYOUT_TOP_TREE_LEFT ||
                        targetType == NodeLayoutType.LAYOUT_FORM ||
                        targetType == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                    return targetType;
                } else if (currentType == NodeLayoutType.LAYOUT_LEFT ||
                        currentType == NodeLayoutType.LAYOUT_BRACKETS_LEFT ||
                        currentType == NodeLayoutType.LAYOUT_TREE_LEFT ||
                        currentType == NodeLayoutType.LAYOUT_TOP_TREE_LEFT) {
                    return currentType;
                } else {
                    return parentType;
                }
            case NodeLayoutType.LAYOUT_BOTTOM:
                if (targetType == NodeLayoutType.LAYOUT_TREE_LEFT ||
                        targetType == NodeLayoutType.LAYOUT_TREE_LEFT_RIGHT ||
                        targetType == NodeLayoutType.LAYOUT_TREE_RIGHT ||
                        targetType == NodeLayoutType.LAYOUT_BOTTOM ||
                        targetType == NodeLayoutType.LAYOUT_FORM ||
                        targetType == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                    return targetType;
                } else if (currentType == NodeLayoutType.LAYOUT_TREE_LEFT ||
                        currentType == NodeLayoutType.LAYOUT_TREE_LEFT_RIGHT ||
                        currentType == NodeLayoutType.LAYOUT_TREE_RIGHT ||
                        currentType == NodeLayoutType.LAYOUT_BOTTOM) {
                    return currentType;
                } else {
                    return parentType;
                }
            case NodeLayoutType.LAYOUT_TOP:
                if (targetType == NodeLayoutType.LAYOUT_TOP_TREE_LEFT ||
                        targetType == NodeLayoutType.LAYOUT_TOP_TREE_LEFT_RIGHT ||
                        targetType == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT ||
                        targetType == NodeLayoutType.LAYOUT_TOP ||
                        targetType == NodeLayoutType.LAYOUT_FORM ||
                        targetType == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                    return targetType;
                } else if (currentType == NodeLayoutType.LAYOUT_TOP_TREE_LEFT ||
                    currentType == NodeLayoutType.LAYOUT_TOP_TREE_LEFT_RIGHT ||
                    currentType == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT ||
                    currentType == NodeLayoutType.LAYOUT_TOP ||
                    currentType == NodeLayoutType.LAYOUT_FORM ||
                    currentType == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                    return currentType;
                } else {
                    return parentType;
                }
            case NodeLayoutType.LAYOUT_TREE_LEFT_RIGHT:
                if (currentType == NodeLayoutType.LAYOUT_TREE_LEFT) {
                    if (targetType == NodeLayoutType.LAYOUT_LEFT ||
                        targetType == NodeLayoutType.LAYOUT_BRACKETS_LEFT ||
                            targetType == NodeLayoutType.LAYOUT_TREE_LEFT ||
                            targetType == NodeLayoutType.LAYOUT_FORM ||
                            targetType == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                        return targetType;
                    } else {
                        return NodeLayoutType.LAYOUT_LEFT;
                    }
                } else if (currentType == NodeLayoutType.LAYOUT_TREE_RIGHT) {
                    if (targetType == NodeLayoutType.LAYOUT_RIGHT ||
                            targetType == NodeLayoutType.LAYOUT_TREE_RIGHT ||
                            targetType == NodeLayoutType.LAYOUT_BRACKETS_RIGHT ||
                            targetType == NodeLayoutType.LAYOUT_FISH_RIGHT ||
                            targetType == NodeLayoutType.LAYOUT_FORM ||
                            targetType == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                        return targetType;
                    } else {
                        return NodeLayoutType.LAYOUT_TREE_RIGHT;
                    }
                } else {
                    if (targetType == NodeLayoutType.LAYOUT_RIGHT ||
                        targetType == NodeLayoutType.LAYOUT_LEFT ||                        
                            targetType == NodeLayoutType.LAYOUT_TREE_LEFT ||
                            targetType == NodeLayoutType.LAYOUT_TREE_RIGHT ||
                            targetType == NodeLayoutType.LAYOUT_BRACKETS_LEFT ||
                            targetType == NodeLayoutType.LAYOUT_BRACKETS_RIGHT ||
                            targetType == NodeLayoutType.LAYOUT_FISH_RIGHT ||
                            targetType == NodeLayoutType.LAYOUT_FORM ||
                            targetType == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                        return targetType;
                    } else {
                        return NodeLayoutType.LAYOUT_TREE_RIGHT;
                    }
                }
            case NodeLayoutType.LAYOUT_TOP_TREE_LEFT_RIGHT:
                if (currentType == NodeLayoutType.LAYOUT_TOP_TREE_LEFT) {
                    if (targetType == NodeLayoutType.LAYOUT_LEFT ||
                        targetType == NodeLayoutType.LAYOUT_BRACKETS_LEFT ||
                            targetType == NodeLayoutType.LAYOUT_TOP_TREE_LEFT ||
                            targetType == NodeLayoutType.LAYOUT_FORM ||
                            targetType == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                        return targetType;
                    } else {
                        return NodeLayoutType.LAYOUT_LEFT;
                    }
                } else if (currentType == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT) {
                    if (targetType == NodeLayoutType.LAYOUT_RIGHT ||
                            targetType == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT ||
                            targetType == NodeLayoutType.LAYOUT_BRACKETS_RIGHT ||
                            targetType == NodeLayoutType.LAYOUT_FISH_RIGHT ||
                            targetType == NodeLayoutType.LAYOUT_FORM ||
                            targetType == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                        return targetType;
                    } else {
                        return NodeLayoutType.LAYOUT_TOP_TREE_RIGHT;
                    }
                } else {
                    return NodeLayoutType.LAYOUT_TOP_TREE_RIGHT;
                }
            case NodeLayoutType.LAYOUT_HORIZONTAL_RIGHT:
                if (targetType == NodeLayoutType.LAYOUT_TREE_RIGHT ||
                        targetType == NodeLayoutType.LAYOUT_TREE_LEFT ||
                        targetType == NodeLayoutType.LAYOUT_TREE_LEFT_RIGHT ||
                        targetType == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT ||
                        targetType == NodeLayoutType.LAYOUT_TOP_TREE_LEFT ||
                        targetType == NodeLayoutType.LAYOUT_TOP_TREE_LEFT_RIGHT ||
                        targetType == NodeLayoutType.LAYOUT_BOTTOM ||
                        targetType == NodeLayoutType.LAYOUT_TOP) {
                    return targetType;
                } else if (currentType == NodeLayoutType.LAYOUT_TREE_RIGHT ||
                        currentType == NodeLayoutType.LAYOUT_TREE_LEFT ||
                        currentType == NodeLayoutType.LAYOUT_TREE_LEFT_RIGHT ||
                        currentType == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT ||
                        currentType == NodeLayoutType.LAYOUT_TOP_TREE_LEFT ||
                        currentType == NodeLayoutType.LAYOUT_TOP_TREE_LEFT_RIGHT ||
                        currentType == NodeLayoutType.LAYOUT_BOTTOM ||
                        currentType == NodeLayoutType.LAYOUT_TOP) {
                    return currentType;
                } else {
                    return NodeLayoutType.LAYOUT_TREE_RIGHT;
                }
            case NodeLayoutType.LAYOUT_VERTICAL_RIGHT:
                if (targetType == NodeLayoutType.LAYOUT_RIGHT ||
                        targetType == NodeLayoutType.LAYOUT_LEFT ||
                        targetType == NodeLayoutType.LAYOUT_BRACKETS_LEFT ||
                        targetType == NodeLayoutType.LAYOUT_BRACKETS_RIGHT ||
                        targetType == NodeLayoutType.LAYOUT_RIGHT_LEFT ||
                        targetType == NodeLayoutType.LAYOUT_LEFT_RIGHT ||
                        targetType == NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE ||
                        targetType == NodeLayoutType.LAYOUT_FISH_RIGHT) {
                    return targetType;
                } else if (currentType == NodeLayoutType.LAYOUT_RIGHT ||
                        currentType == NodeLayoutType.LAYOUT_LEFT ||
                        currentType == NodeLayoutType.LAYOUT_BRACKETS_LEFT ||
                        currentType == NodeLayoutType.LAYOUT_BRACKETS_RIGHT ||
                        currentType == NodeLayoutType.LAYOUT_RIGHT_LEFT ||
                        currentType == NodeLayoutType.LAYOUT_LEFT_RIGHT ||
                        currentType == NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE ||
                        currentType == NodeLayoutType.LAYOUT_FISH_RIGHT) {
                    return currentType;
                } else {
                    return NodeLayoutType.LAYOUT_RIGHT;
                }
            case NodeLayoutType.LAYOUT_FISH_RIGHT:
                if (targetType == NodeLayoutType.LAYOUT_RIGHT ||
                    targetType == NodeLayoutType.LAYOUT_FORM ||
                    targetType == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                    return targetType;
                } else {
                    return NodeLayoutType.LAYOUT_RIGHT;
                }
            case NodeLayoutType.LAYOUT_FISH_LEFT:
                if (targetType == NodeLayoutType.LAYOUT_LEFT ||
                    targetType == NodeLayoutType.LAYOUT_FORM ||
                    targetType == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                    return targetType;
                } else {
                    return NodeLayoutType.LAYOUT_LEFT;
                }
            case NodeLayoutType.LAYOUT_LEFT_RIGHT:
            case NodeLayoutType.LAYOUT_RIGHT_LEFT:
            case NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE:
            case NodeLayoutType.LAYOUT_FREE_TREE:
                if (currentType == NodeLayoutType.LAYOUT_LEFT || 
                    currentType == NodeLayoutType.LAYOUT_BRACKETS_LEFT ||
                        currentType == NodeLayoutType.LAYOUT_TREE_LEFT ||
                        currentType == NodeLayoutType.LAYOUT_TOP_TREE_LEFT ||
                        currentType == NodeLayoutType.LAYOUT_FORM ||
                        currentType == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                    if (targetType == NodeLayoutType.LAYOUT_LEFT ||
                        targetType == NodeLayoutType.LAYOUT_BRACKETS_LEFT ||
                            targetType == NodeLayoutType.LAYOUT_TREE_LEFT ||
                            targetType == NodeLayoutType.LAYOUT_TOP_TREE_LEFT ||
                            targetType == NodeLayoutType.LAYOUT_FORM ||
                            targetType == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                        return targetType;
                    } else {
                        return NodeLayoutType.LAYOUT_LEFT;
                    }
                } else if (currentType == NodeLayoutType.LAYOUT_RIGHT ||
                        currentType == NodeLayoutType.LAYOUT_BRACKETS_RIGHT ||
                        currentType == NodeLayoutType.LAYOUT_TREE_RIGHT ||
                        currentType == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT ||
                        currentType == NodeLayoutType.LAYOUT_FISH_RIGHT ||
                        currentType == NodeLayoutType.LAYOUT_FORM ||
                        currentType == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                    if (targetType == NodeLayoutType.LAYOUT_RIGHT ||
                            targetType == NodeLayoutType.LAYOUT_TREE_RIGHT ||
                            targetType == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT ||
                            targetType == NodeLayoutType.LAYOUT_BRACKETS_RIGHT ||
                            targetType == NodeLayoutType.LAYOUT_FISH_RIGHT ||
                            targetType == NodeLayoutType.LAYOUT_FORM ||
                            targetType == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                        return targetType;
                    } else {
                        return NodeLayoutType.LAYOUT_RIGHT;
                    }
                } else {
                    return NodeLayoutType.LAYOUT_RIGHT;
                }
            case NodeLayoutType.LAYOUT_FORM:
                if (targetType == NodeLayoutType.LAYOUT_RIGHT_LEFT ||
                    targetType == NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE ||
                    targetType == NodeLayoutType.LAYOUT_LEFT_RIGHT ||
                    targetType == NodeLayoutType.LAYOUT_CIRCLE ||
                    targetType == NodeLayoutType.LAYOUT_TRIANGLE ||
                    targetType == NodeLayoutType.LAYOUT_HORIZONTAL_RIGHT ||
                    targetType == NodeLayoutType.LAYOUT_VERTICAL_RIGHT) {
                    return NodeLayoutType.LAYOUT_FORM;
                }
                return targetType;
            case NodeLayoutType.LAYOUT_FORM_HORIZONTAL:
                if (targetType == NodeLayoutType.LAYOUT_RIGHT_LEFT ||
                    targetType == NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE ||
                    targetType == NodeLayoutType.LAYOUT_LEFT_RIGHT ||
                    targetType == NodeLayoutType.LAYOUT_CIRCLE ||
                    targetType == NodeLayoutType.LAYOUT_TRIANGLE ||
                    targetType == NodeLayoutType.LAYOUT_HORIZONTAL_RIGHT ||
                    targetType == NodeLayoutType.LAYOUT_VERTICAL_RIGHT) {
                    return NodeLayoutType.LAYOUT_FORM_HORIZONTAL;
                }
                return targetType;
            case NodeLayoutType.LAYOUT_TRIANGLE:
                return NodeLayoutType.LAYOUT_RIGHT;
            case NodeLayoutType.LAYOUT_CIRCLE:
                return NodeLayoutType.LAYOUT_CIRCLE;
            default:
        }
        return parentType;
    }

    isNodeLayoutEqual(parentType, targetType) {

        if (targetType == NodeLayoutType.LAYOUT_FISH_RIGHT && parentType != NodeLayoutType.LAYOUT_FISH_RIGHT) {
            return false;
        }
        if (targetType == NodeLayoutType.LAYOUT_FISH_LEFT && parentType != NodeLayoutType.LAYOUT_FISH_LEFT) {
            return false;
        }
        switch (parentType) {
            case NodeLayoutType.LAYOUT_TREE_LEFT_RIGHT:
                if (targetType == NodeLayoutType.LAYOUT_TREE_LEFT ||
                    targetType == NodeLayoutType.LAYOUT_TREE_RIGHT ||
                    targetType == NodeLayoutType.LAYOUT_LEFT ||
                    targetType == NodeLayoutType.LAYOUT_RIGHT ||
                    targetType == NodeLayoutType.LAYOUT_BRACKETS_LEFT ||
                    targetType == NodeLayoutType.LAYOUT_BRACKETS_RIGHT) {
                    return true;
                } else {
                    return false;
                }
            case NodeLayoutType.LAYOUT_TOP_TREE_LEFT_RIGHT:
                if (targetType == NodeLayoutType.LAYOUT_TOP_TREE_LEFT ||
                    targetType == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT ||
                    targetType == NodeLayoutType.LAYOUT_LEFT ||
                    targetType == NodeLayoutType.LAYOUT_RIGHT ||
                    targetType == NodeLayoutType.LAYOUT_BRACKETS_LEFT ||
                    targetType == NodeLayoutType.LAYOUT_BRACKETS_RIGHT) {
                    return true;
                } else {
                    return false;
                }
            case NodeLayoutType.LAYOUT_HORIZONTAL_RIGHT:
            if (targetType == NodeLayoutType.LAYOUT_TREE_RIGHT ||
                    targetType == NodeLayoutType.LAYOUT_TREE_LEFT ||
                    targetType == NodeLayoutType.LAYOUT_TREE_LEFT_RIGHT ||
                    targetType == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT ||
                    targetType == NodeLayoutType.LAYOUT_TOP_TREE_LEFT ||
                    targetType == NodeLayoutType.LAYOUT_TOP_TREE_LEFT_RIGHT ||
                    targetType == NodeLayoutType.LAYOUT_BOTTOM ||
                    targetType == NodeLayoutType.LAYOUT_TOP) {
                return true;
            } else {
                return true;
            }
            case NodeLayoutType.LAYOUT_VERTICAL_RIGHT:
                if (targetType == NodeLayoutType.LAYOUT_RIGHT ||
                        targetType == NodeLayoutType.LAYOUT_LEFT ||
                        targetType == NodeLayoutType.LAYOUT_BRACKETS_LEFT ||
                        targetType == NodeLayoutType.LAYOUT_BRACKETS_RIGHT ||
                        targetType == NodeLayoutType.LAYOUT_RIGHT_LEFT ||
                        targetType == NodeLayoutType.LAYOUT_LEFT_RIGHT ||
                        targetType == NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE ||
                        targetType == NodeLayoutType.LAYOUT_FISH_RIGHT) {
                    return true;
                } else {
                    return false;
                }
            case NodeLayoutType.LAYOUT_FISH_RIGHT:
                return false;
            case NodeLayoutType.LAYOUT_FISH_LEFT:
                return false;
            case NodeLayoutType.LAYOUT_LEFT_RIGHT:
            case NodeLayoutType.LAYOUT_RIGHT_LEFT:
            case NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE:
                if (targetType == NodeLayoutType.LAYOUT_LEFT ||
                        targetType == NodeLayoutType.LAYOUT_BRACKETS_LEFT ||
                        targetType == NodeLayoutType.LAYOUT_TREE_LEFT ||
                        targetType == NodeLayoutType.LAYOUT_TOP_TREE_LEFT) {
                    return true;
                } else{
                    return false;
                }
            case NodeLayoutType.LAYOUT_FORM:
                return false;
            case NodeLayoutType.LAYOUT_FORM_HORIZONTAL:
                return false;
            case NodeLayoutType.LAYOUT_TRIANGLE:
                return false;
            case NodeLayoutType.LAYOUT_CIRCLE:
                return false;
            default:
                return parentType == targetType;
        }
    }
}

export default new MindLayoutSelect()
