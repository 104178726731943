
class MindAngle {

    constructor(id = -1, angle = 0, rect = null, point = null) {
        this.id = id;
        this.angle = angle;
        this.rect = rect
        this.point = point
    }
}
export default MindAngle