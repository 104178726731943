import Colors from "../../../utils/Colors";
import UiUtil from "../../../utils/UiUtil";
import MindElementCalculation from "../../../core/core/calcule/elementCalculation/MindElementCalculation"
import Point from "../../../viewmodel/core/base/Point";
import Config from "../../../core/core/calcule/Config";
import MindElementType from "../../../viewmodel/datatype/MindElementType";
import CheckTextIsSelected from "../../../utils/CheckTextIsSelected";
import LinePath from "../../../viewmodel/core/base/LinePath";
import InnerHTMLParse from "./InnerHTMLParse";
import NodeLayoutType from "../../../viewmodel/datatype/NodeLayoutType";
import IconLayoutType from "../../../viewmodel/datatype/IconLayoutType";
import MindNodeUpView from "./MindNodeUpView";
import ControllMindSize from "./ControllMindSize";
import MindType from "../../../viewmodel/datatype/MindType";
import MindElementShapeType from "../../../viewmodel/datatype/MindElementShapeType";
import Strings from "../../../utils/Strings";
import resetMindView from "./restMindView";
import checkBoxView from "./checkBoxView";
import CGRect from "../../../viewmodel/core/base/basedata/Rect";
import GlobalInputTextType from "../../../viewmodel/datatype/GlobalInputTextType";
import Util from "../../../utils/Util";
import TextContent from "../../../viewmodel/mindelementdata/mindcontent/TextContent";
import EditMindmapVM from "../../../viewmodel/mindelementdata/vm/EditMindmapVM";
import TextStyleCell from "../../../viewmodel/mindelementdata/mindcontent/TextStyleCell";
import MindElementAlignmentType from "../../../viewmodel/datatype/MindElementAlignmentType";
import CGPoint from "../../../viewmodel/core/base/basedata/CGPoint";
import MindMe from "../../../viewmodel/facade/MindMe";
import ConnectLineType from "../../../viewmodel/datatype/ConnectLineType";
import FlowChartDirection from "../../../viewmodel/datatype/FlowChartDirection";
import MindAddDataView from "./MindAddDataView";
import HttpServerConfig from "../../../common/HttpServerConfig";
import imageToStyle from "../../../utils/ImageToolkit";

class ScrollView {
  constructor(root, width, height, delegate) {
    this.root = root;
    this.contentView;
    this.contentViewSvg; //存放节点的svg
    this.lineViewSvg;  //存放线条的svg
    this.baseViewSvg; // 存放背景的svg，比如包裹线
    this.delegate = delegate;
    this.ihoneMoveDisX = 0;
    this.ihoneMoveDisY = 0;
    this.ihoneMoveToucheOneDisX = 0;
    this.ihoneMoveToucheOneDisY = 0;
    this.UiUtil = new UiUtil();
    this.width = width;
    this.height = height;
    this.scale = 1.0; // 导图比例，0-1.0
    this.maxScale = 4.0; // 导图最大比例
    this.minScale = 0.1; // 导图最小比例
    this.selectData; //被选中节点的data
    this.vueThis;  //vue对象
    this.inputView; //输入框
    this.controllCircle = false; //一开始控制圆的显示与隐藏
    this.controllStartCircle;//控制圆1
    this.controllEndCircle;//控制圆2
    this.controllCircleDrag = false;
    this.changeDataAngleDrag = false;
    this.startCircle;//控制圆1
    this.endCircle;//控制圆2
    this.isMousePoint; // 当前鼠标是否在画布上
    this.elementView; //当前正在拖动的元素
    this.inputViewShow = false; //输入框的显示隐藏状态，这个变量不能控制，只能用于检查输入框状态。
    this.controllCircle1Data = {
      x: 0,
      y: 1904,
      width: 15,
      height: 15,
    }; //控制圆1的data
    this.controllCircle2Data = {
      x: 1944,
      y: 1904,
      width: 15,
      height: 15,
    }; //控制圆2的data
    this.circleStartData = {
      x: 0,
      y: 1904,
      width: 8,
      height: 8,
    }; //控制圆1的data
    this.circleEndData = {
      x: 0,
      y: 1904,
      width: 8,
      height: 8,
    }; //控制圆1的data
    this.rightAnglePoints = new Array()
    this.guideLineG;//辅助线的g标签
    this.guideLineLeft; //左线
    this.guideLineTop;// 头线
    this.guideLineRight; //右线
    this.guideLineBottom; //下线
    this.AssistNode = null;  //辅助节点g
    this.AssistNodeChild; //辅助节点g的path
    // this.AssistNodeLine; //辅助节点g连线
    this.AssistNodeLineChild; //辅助节点g连线的path
    this.mindChildshowButton;
    this.resetMindView; // 用于存放选中包裹线的边框实例
    this.pressAndHold; //长按
    this.checkBoxView;//选框
    this.timeOutEvent;//长按定时器
    this.drageSeatMax = 0; //移动的最大值
    this.moveAnimationInterval = -1;
    this.controllMindSize; //控制节点大小
    this.isEditSubscript = false; //当前是否在下标编辑输入框状态
    this.isEditCardTitle = false; //当前是否在下标编辑输入框状态
    this.mobolOpenAppText = null;
    this.backgroundImageView = null;
    this.playBackgroundImageView = null;
    this.isChangeControllBoxSize = false;
    // this.showcontrollCircle = false ;
    this.initView();
    
    //字体样式修改参数
    this.monitorIndex = 0;//判断第一次进入节点输入框时，内容是否为默认内容
    this.inputBoxIndex = 0;//判断是否是第一次进入节点输入框

    this.startSubscript = -1; //鼠标选中文本的开始位置
    this.startSubscriptLabel = -1; //鼠标点击标签的开始位置
    this.endSubscript = -1; //鼠标选中文本的结束位置
    this.endSubscriptLabel = -1; //鼠标点击标签的开始位置
    this.objTextArea; //当前输入框里面的对象
    this.selectedTextContent = [];//选中的内容

    this.firstSpanId = -1;//选中区域的开始标签的id
    this.endSpanId = -1;//选中区域的结束标签的id
    this.styleList = new Array();//存放内容的样式集合
    this.textFontSize = 18;//用于存放span标签的字体
    this.localNameLabel = "";//判断选中内容是在一个标签内，还是选中了多个span
    this.spanSelectColor = [];//存放选中内容的字体颜色，取消选区时重新赋值

    this.typeNode = "";//当前节点的类型
    this.defaultStr = '@%*&^#^^(#)%)(~?Fd>,.=+!)(&^%54730.dfgr';    //输入框中间的默认内容
    this.oldInputInnerHtml = ''; //输入框的默认内容。
    this.mouseMovePoint = new Point();
    this.inputtingTimer = -1
    this.isDragCanvas = false;
    this.contentViewMousedownPoint = null;
    this.addFlowChartDataLeftView = null;
    this.addFlowChartDataTopView = null;
    this.addFlowChartDataRightView = null;
    this.addFlowChartDataBottomView = null;
  }
  isEditState() {
    let path = this.delegate.$route.path
    if (path == '/mindmap') {
      return false;
    } else {
      return true;
    }
}
  initView() {

    this.contentView = document.createElement("div")
    this.contentView.id = "mindmap_scrollview"
    this.contentView.style.position = "absolute"
    this.root.appendChild(this.contentView)

    //用于存放节点的svg
    this.contentViewSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    this.contentViewSvg.setAttribute("id", "editmap");
    this.contentViewSvg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
    this.contentView.appendChild(this.contentViewSvg);    

    this.contentViewSvg.style.position = 'absolute';
    this.contentViewSvg.style.left = '0';
    this.contentViewSvg.style.top = '0';
    this.contentViewSvg.style.zIndex = 30;

    //用于存放线条的svg。
    this.lineViewSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    this.lineViewSvg.setAttribute("id", "linemap");
    this.lineViewSvg.setAttribute("shape-rendering", "geometricPrecision");
    this.lineViewSvg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
    this.contentView.appendChild(this.lineViewSvg);
    this.lineViewSvg.style.position = 'absolute';
    this.lineViewSvg.style.left = '0';
    this.lineViewSvg.style.top = '0';
    this.lineViewSvg.style.zIndex = 20;

    //用于存放线条的svg。
    this.baseViewSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    this.baseViewSvg.setAttribute("id", "basemap");
    this.baseViewSvg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
    this.contentView.appendChild(this.baseViewSvg);
    this.baseViewSvg.style.position = 'absolute';
    this.baseViewSvg.style.left = '0';
    this.baseViewSvg.style.top = '0';
    this.baseViewSvg.style.zIndex = 10;

    //用于存放水印的svg。
    this.watermarkViewSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    this.watermarkViewSvg.setAttribute("id", "cloneChop");
    this.watermarkViewSvg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
    this.contentView.appendChild(this.watermarkViewSvg);
    this.watermarkViewSvg.style.position = 'absolute';
    this.watermarkViewSvg.style.left = '0';
    this.watermarkViewSvg.style.top = '0';    
    this.watermarkViewSvg.style.zIndex = 1;
    this.spacingScale = 0;
    this.spacing = -1;

    this.setSize(this.width, this.height);

    this.addEditmapDefs()
    this.addLinemapDefs()
    this.addBaseLinemapDefs()
    //拖动
    this.dargeDom()
    //滚轮事件
    this.wheelEventToTouchPad()
    //放大缩小
    this.scroll()
    //创建输入框
    this.createInput()
    //创建小圆
    this.createControllCircle();
    //隐藏控制小圆
    // this.showCircle();
    this.hideCircle();
    //绑定双击事件
    this.dblClickCanvas();
    //辅助线
    this.guideLine();
    //创建全局的隐藏节点
    this.mindChildshowButton = new MindNodeUpView(this.contentView, { width: Config.showHideCircleDia, height: Config.showHideCircleDia }, this.delegate);
    this.mindDataChangeAngleButton = new MindNodeUpView(this.contentView, { width: Config.showHideCircleDia, height: Config.showHideCircleDia }, this.delegate, true);
    this.controllMindSize = new ControllMindSize(this.contentView, this.delegate);
    //创建全局的选择编辑框
    this.resetMindView = new resetMindView(this.contentViewSvg, this);
    //选框
    this.checkBoxView = new checkBoxView(this.contentViewSvg, this)
    this.mobolOpenAppView();

    // this.addEventListenerPaste();
    // this.addBackgroundImage()
  }

  createAddFlowDataView() {    
    if (this.vueThis.mindType != MindType.FLOW_CHART || this.addFlowChartDataLeftView != null) {
      return
    }
    this.addFlowChartDataLeftView = new MindAddDataView(this.contentView, { width: Config.showHideCircleDia, height: Config.showHideCircleDia }, this.delegate, FlowChartDirection.LEFT);
    this.addFlowChartDataTopView = new MindAddDataView(this.contentView, { width: Config.showHideCircleDia, height: Config.showHideCircleDia }, this.delegate, FlowChartDirection.TOP);
    this.addFlowChartDataRightView = new MindAddDataView(this.contentView, { width: Config.showHideCircleDia, height: Config.showHideCircleDia }, this.delegate, FlowChartDirection.RIGHT);
    this.addFlowChartDataBottomView = new MindAddDataView(this.contentView, { width: Config.showHideCircleDia, height: Config.showHideCircleDia }, this.delegate, FlowChartDirection.BOTTOM);
  }

  hideAddFlowChartDataLeftView() {
    if (this.addFlowChartDataLeftView == null) {
      return
    }
    this.addFlowChartDataLeftView.hideDom()
    this.addFlowChartDataTopView.hideDom()
    this.addFlowChartDataRightView.hideDom()
    this.addFlowChartDataBottomView.hideDom()
  }

  initBackgroundImage() {
    if ( 1 == 1) {//TODO 暂时关闭
      return
    }
    if (this.backgroundImageView != null) {
      if (this.backgroundImageView.childNodes != null || this.backgroundImageView.childNodes.length == 0) {
        this.addBackgroundImage()
      }
      return;
    }
    this.backgroundImageView = document.createElement("div")
    this.backgroundImageView.setAttribute("id", "editmapBackgroundImage");
    this.backgroundImageView.style.position = 'fixed';
    this.backgroundImageView.style.left = '0';
    this.backgroundImageView.style.top = '0';
    this.backgroundImageView.style.width = '100%';
    this.backgroundImageView.style.height = '100%';
    this.backgroundImageView.style.zIndex = 29;
    this.backgroundImageView.style.pointerEvents = "none";
    this.root.appendChild(this.backgroundImageView);  
    
    this.addBackgroundImage()
  }

  addBackgroundImage() {    
    if (this.backgroundImageView == null) {
      return
    }
    this.removeBackgroundImageViewNodes()
    let id = MindMe.getEditMindBackgroundImageId()
    let target = null
    for (let i = 0; i < Config.EditMindMapBackgroundImage.length; i++) {
      if (id == Config.EditMindMapBackgroundImage[i].id) {
        target = Config.EditMindMapBackgroundImage[i]
        break
      }
    }
    if (target == null) {
      target = Config.EditMindMapBackgroundImage[0]
    }
    if (target.data == null || target.data.length < 0) {
      return
    }
    for (let i = 0; i < target.data.length; i++) {
      let cell = target.data[i]
      let url = require("../../../assets/img/backgroundimg/" + cell.url)
      var img = document.createElement("img");
      let id = "backgroundId_" + target.id + "_" + i
      img.setAttribute("id", id);
      img.src = url
      img.style.position = 'absolute';
      img.style.width = cell.width + "px"
      img.style.height = cell.heigth + "px"
      img.style.opacity = cell.opacity

      if (cell.left > -1) {
        img.style.left = cell.left + "px";
      } else {
        img.style.removeProperty("left")
      }
      if (cell.top > -1) {
        img.style.top = cell.top + "px";
      } else {
        img.style.removeProperty("top")
      }
      if (cell.right > -1) {
        img.style.right = cell.right + "px";
      } else {
        img.style.removeProperty("right")
      }
      if (cell.bottom > -1) {
        img.style.bottom = cell.bottom + "px";
      } else {
        img.style.removeProperty("bottom")
      }
      this.backgroundImageView.appendChild(img)
    }
  }

  initPlayBackgroundImage() {
    if (this.playBackgroundImageView != null) {
      return;
    }
    this.playBackgroundImageView = document.createElement("div")
    this.playBackgroundImageView.setAttribute("id", "editmapBackgroundImage");
    this.playBackgroundImageView.style.position = 'fixed';
    this.playBackgroundImageView.style.left = '0';
    this.playBackgroundImageView.style.top = '0';
    this.playBackgroundImageView.style.width = '100%';
    this.playBackgroundImageView.style.height = '100%';
    // this.playBackgroundImageView.style.zIndex = 0;
    this.playBackgroundImageView.style.pointerEvents = "none";
    this.root.insertBefore(this.playBackgroundImageView, this.contentView);  
  }

  clearPlayBackgroundImage() {
    if (this.playBackgroundImageView == null || this.root == null) {
      return;
    }
    this.root.removeChild(this.playBackgroundImageView);  
    this.playBackgroundImageView = null
  }

  setMindMapBackgroundImage(playBackground) {
    if (playBackground == null || playBackground.key == null || 
      playBackground.key.length < 0 || playBackground.key == "mind_background_1000") {
      return
    }
    this.initPlayBackgroundImage()
    
    let width = playBackground.width;
    let height = playBackground.height;
    let imageScale = width/height;

    let superViewScale = Util.getScreenWidth()/Util.getScreenHeight();
    // playBackgroundImage.setBitmap(bitmap);
    // playBackgroundImage.bringToFront();
    let imageWidth = 0
    let imageHeight = 0
    let screenWidth = Util.getScreenWidth();
    let screenHeight = Util.getScreenHeight();
    if (imageScale > superViewScale) {
        imageHeight = screenHeight;
        imageWidth = imageScale *  screenHeight;
    } else {
        imageWidth = screenWidth;
        imageHeight = screenWidth / imageScale;
    }

    
    var img = document.createElement("img");
    let id = "playbackgroundId_" + playBackground.key
    img.setAttribute("id", id);
    if (playBackground.key.indexOf("http://") > -1 || playBackground.key.indexOf("https://") > -1) {
      let srcHttp = HttpServerConfig.getMediaSrc(playBackground.key)
      new imageToStyle().webToBase64(srcHttp).then(imageBase64 => {
        img.style.background = "center center url(" + imageBase64 + ")";
        img.style.backgroundRepeat = "no-repeat";
        img.style.backgroundSize = "contain";
      })
    } else {
      let url = require("../../../assets/img/playbackground/" + playBackground.key + ".png")
      img.src = url
    }
    
    img.style.position = 'absolute';
    img.style.width = imageWidth == screenWidth ? "100%" : (imageWidth + "px")
    img.style.height = imageHeight == screenHeight ? "100%" : (imageHeight + "px")

    if (playBackground.alignment == "left") {
      img.style.left = "0px";
      img.style.top = ((screenHeight - imageHeight) / 2) + "px";
    } else if (playBackground.alignment == "right") {
      img.style.left = (screenWidth - imageWidth) + "px";
      img.style.top = ((screenHeight - imageHeight) / 2) + "px";
    } else if (playBackground.alignment == "top") {
      img.style.left = "0px";
      img.style.top = "0px";
    } else if (playBackground.alignment == "bottom") {
      img.style.left = (screenWidth - imageWidth) / 2 + "px";
      img.style.top = (screenHeight - imageHeight) + "px";
    } else {
      img.style.left = (screenWidth - imageWidth) / 2 + "px";
      img.style.top = (screenHeight - imageHeight) / 2 + "px";
    }
    this.playBackgroundImageView.appendChild(img)
  }

  onClickBackgroundImageBt(event) {
    if (event == null) {
      return
    }
    let editmapBackgroundImage = document.getElementById("editmapBackgroundImage")
    if (editmapBackgroundImage == null) {
      return
    }
    let x = event.pageX;
    let y = event.pageY;
    
    let screenWidth = document.documentElement.clientWidth
    let screenHeight = document.documentElement.clientHeight
    let childNodes = editmapBackgroundImage.childNodes
    if (childNodes == null || childNodes.length == 0) {
      return
    }
    let btImgSize = 24;
    let margin = 10;
    for (let i = 0; i < childNodes.length; i++) {
      let cell = childNodes[i]
      let id = cell.getAttribute("id")
      if (id.indexOf("backgroundChangeId_") > -1) {          
        let rect = new CGRect()
        rect.width(btImgSize)
        rect.height(btImgSize)
        let left = this.getViewPxValue(cell.style.left)
        let top = this.getViewPxValue(cell.style.top)
        let right = this.getViewPxValue(cell.style.right)
        let bottom = this.getViewPxValue(cell.style.bottom)

        if (left > -1) {
          rect.x = left;
        }
        if (top > -1) {
          rect.y = top;
        }
        if (right > -1) {
          rect.x = screenWidth - btImgSize - right;
        }
        if (bottom > -1) {
          rect.y = screenHeight - bottom - btImgSize;
        }
        if (Util.containsInRectForXY(rect, x, y)) {
          let currentId = MindMe.getEditMindBackgroundImageId()
          for (let c = 0; c < Config.EditMindMapBackgroundImage.length; c++) {
            if (currentId == Config.EditMindMapBackgroundImage[c].id) {
              if (c < Config.EditMindMapBackgroundImage.length - 1) {
                MindMe.setEditMindBackgroundImageId(Config.EditMindMapBackgroundImage[c+1].id)
              } else {
                MindMe.setEditMindBackgroundImageId(Config.EditMindMapBackgroundImage[0].id)
              }
              this.addBackgroundImage()
              return
            }
          }
        }
      } else if (id.indexOf("backgroundCloseId_") > -1) {          
        let rect = new CGRect()
        rect.width(btImgSize)
        rect.height(btImgSize)
        let left = this.getViewPxValue(cell.style.left)
        let top = this.getViewPxValue(cell.style.top)
        let right = this.getViewPxValue(cell.style.right)
        let bottom = this.getViewPxValue(cell.style.bottom)

        if (left > -1) {
          rect.x = left;
        }
        if (top > -1) {
          rect.y = top;
        }
        if (right > -1) {
          rect.x = screenWidth - btImgSize - right;
        }
        if (bottom > -1) {
          rect.y = screenHeight - bottom - btImgSize;
        }
        if (Util.containsInRectForXY(rect, x, y)) {
          this.removeBackgroundImageViewNodes()
        }
      }    
    }
  }

  removeBackgroundImageViewNodes() {
    if (this.backgroundImageView == null) {
      return
    }
    let childNodes = this.backgroundImageView.childNodes
    if (childNodes != null && childNodes.length > 0) {
      let list = []
      for (let i = 0; i < childNodes.length; i++) {
        let cellView = childNodes[i]
        list.push(cellView)
      }
      for (let i = 0; i < list.length; i++) {
        this.backgroundImageView.removeChild(list[i])
      }
      list = []
    }
  }

  showChangeBackgroundImageBt(event) {
    if (event == null) {
      return
    }
    let editmapBackgroundImage = document.getElementById("editmapBackgroundImage")
    if (editmapBackgroundImage == null) {
      return
    }
    let x = event.pageX;
    let y = event.pageY;
    
    let screenWidth = document.documentElement.clientWidth//this.UiUtil.getScreenWidth();
    let screenHeight = document.documentElement.clientHeight//this.UiUtil.getScreenHeight();
    let childNodes = editmapBackgroundImage.childNodes
    if (childNodes != null && childNodes.length > 0) {
      let id = MindMe.getEditMindBackgroundImageId()
      let target = null
      for (let i = 0; i < Config.EditMindMapBackgroundImage.length; i++) {
        if (id == Config.EditMindMapBackgroundImage[i].id) {
          target = Config.EditMindMapBackgroundImage[i]
          break
        }
      }
      if (target == null) {
        target = Config.EditMindMapBackgroundImage[0]
      }
      if (target.data == null || target.data.length < 0) {
        return
      }
      
      for (let i = 0; i < childNodes.length; i++) {
        let cell = childNodes[i]
        let id = cell.getAttribute("id")
        if (id.indexOf("backgroundId_") == -1) {
          continue
        }
        let cellData = null
        for (let i = 0; i < target.data.length; i++) {
          let dataCellId = "backgroundId_" + target.id + "_" + i
          if (dataCellId == id) {
            cellData = target.data[i]
            break
          }
        }
        if (cellData == null) {
          continue
        }
        let rect = new CGRect()
        rect.width(cellData.width)
        rect.height(cellData.heigth)
        if (cellData.left > -1) {
          rect.x = cellData.left;
        }
        if (cellData.top > -1) {
          rect.y = cellData.top;
        }
        if (cellData.right > -1) {
          rect.x = screenWidth - cellData.width - cellData.right;
        }
        if (cellData.bottom > -1) {
          rect.y = screenHeight - cellData.heigth - cellData.bottom;
        }
        let backgroundCloseId = "backgroundCloseId_" + target.id + "_" + i;
        let backgroundChangeId = "backgroundChangeId_" + target.id + "_" + i;
        let closeImg = document.getElementById(backgroundCloseId)
        let changeImg = document.getElementById(backgroundChangeId)
        if (Util.containsInRectForXY(rect, x, y)) {
          let btImgSize = 24;
          let margin = 10;
          if (closeImg == null) {
            let closeUrl = require("../../../assets/img/backgroundimg/bg_close_icon.svg")
            let changeUrl = require("../../../assets/img/backgroundimg/bg_change_icon.svg")
            closeImg = document.createElement("img");
            changeImg = document.createElement("img");
            closeImg.setAttribute("id", "backgroundCloseId_" + target.id + "_" + i);
            closeImg.src = closeUrl
            closeImg.style.position = 'absolute';
            closeImg.style.width = btImgSize + "px"
            closeImg.style.height = btImgSize + "px"
            closeImg.style.backgroundColor = "#ffffff";
            closeImg.style.borderRadius = "50%";

            changeImg.setAttribute("id", "backgroundChangeId_" + target.id + "_" + i);
            changeImg.src = changeUrl
            changeImg.style.position = 'absolute';
            changeImg.style.width = btImgSize + "px"
            changeImg.style.height = btImgSize + "px"
            changeImg.style.backgroundColor = "#ffffff";
            changeImg.style.borderRadius = "50%";

            let completionClick = (ev) => {
              //console.log("123");
            }
  
            changeImg.addEventListener('click', completionClick)
          }
          if (cellData.left > -1) {
            closeImg.style.left = (cellData.left + margin) + "px"
            changeImg.style.left = (cellData.left + margin) + "px"
          } else {
            closeImg.style.removeProperty("left")
            changeImg.style.removeProperty("left")
          }
          if (cellData.right > -1) {
            closeImg.style.right = (cellData.right + margin) + "px"
            changeImg.style.right = (cellData.right + margin) + "px"
          } else {
            closeImg.style.removeProperty("right")
            changeImg.style.removeProperty("right")
          }
          if (cellData.top > -1) {
            closeImg.style.top = (cellData.top + margin) + "px"
            changeImg.style.top = (cellData.top + btImgSize + margin * 1.5) + "px"
          } else {
            closeImg.style.removeProperty("top")
            changeImg.style.removeProperty("top")
          }
          if (cellData.bottom > -1) {
            closeImg.style.bottom = (cellData.bottom + margin) + "px"
            changeImg.style.bottom = (cellData.bottom + btImgSize + margin * 1.5) + "px"
          } else {
            closeImg.style.removeProperty("bottom")
            changeImg.style.removeProperty("bottom")
          }
          editmapBackgroundImage.appendChild(closeImg)
          editmapBackgroundImage.appendChild(changeImg)
          closeImg.style.display = 'block'
          changeImg.style.display = 'block'

          
        } else {
          if (closeImg != null) {
            closeImg.style.display = 'none'
          }
          if (changeImg != null) {
            changeImg.style.display = 'none'
          }
        }      
      }
    }
  }

  addEditmapDefs() {
    let editmapDefs = document.createElementNS('http://www.w3.org/2000/svg', "defs")
    let editmapNodeShadowfilter = document.createElementNS('http://www.w3.org/2000/svg',"filter")
    let editmapNodeFeOffset = document.createElementNS('http://www.w3.org/2000/svg', "feOffset")
    let editmapNodeFeColorMatrix = document.createElementNS('http://www.w3.org/2000/svg', "feColorMatrix")
    let editmapNodeFeGaussianBlur = document.createElementNS('http://www.w3.org/2000/svg', "feGaussianBlur")
    let editmapNodeFeBlend = document.createElementNS('http://www.w3.org/2000/svg', "feBlend")

    editmapNodeShadowfilter.id = "editmapNodeShadow"
    // editmapNodeShadowfilter.setAttribute("x", "0")
    // editmapNodeShadowfilter.setAttribute("y", "0")
    editmapNodeShadowfilter.setAttribute("width", "150%")
    editmapNodeShadowfilter.setAttribute("height", "150%")

    editmapNodeFeOffset.setAttribute("result", "offOut")
    editmapNodeFeOffset.setAttribute("in","SourceGraphic")
    editmapNodeFeOffset.setAttribute("dx", "0")
    editmapNodeFeOffset.setAttribute("dy", "2")

    editmapNodeFeColorMatrix.setAttribute("result", "matrixOut")
    editmapNodeFeColorMatrix.setAttribute("in", "offOut")
    editmapNodeFeColorMatrix.setAttribute("type", "matrix")
    editmapNodeFeColorMatrix.setAttribute("values", "0.5 0 0 0 0 0 0.5 0 0 0 0 0 0.5 0 0 0 0 0 1 0")

    editmapNodeFeGaussianBlur.setAttribute("result", "blurOut")
    editmapNodeFeGaussianBlur.setAttribute("in", "matrixOut")
    editmapNodeFeGaussianBlur.setAttribute("stdDeviation", "0")

    editmapNodeFeBlend.setAttribute("in", "SourceGraphic")
    editmapNodeFeBlend.setAttribute("in2", "blurOut")
    editmapNodeFeBlend.setAttribute("mode", "normal")

    this.contentViewSvg.appendChild(editmapDefs);
    editmapDefs.appendChild(editmapNodeShadowfilter);
    editmapNodeShadowfilter.appendChild(editmapNodeFeOffset);
    editmapNodeShadowfilter.appendChild(editmapNodeFeColorMatrix);
    editmapNodeShadowfilter.appendChild(editmapNodeFeGaussianBlur);
    editmapNodeShadowfilter.appendChild(editmapNodeFeBlend);

    let linemapDefs = document.createElementNS('http://www.w3.org/2000/svg', "defs")
    let linemapNodeShadowfilter = document.createElementNS('http://www.w3.org/2000/svg',"filter")
    let linemapNodeFeOffset = document.createElementNS('http://www.w3.org/2000/svg', "feOffset")
    let linemapNodeFeColorMatrix = document.createElementNS('http://www.w3.org/2000/svg', "feColorMatrix")
    let linemapNodeFeGaussianBlur = document.createElementNS('http://www.w3.org/2000/svg', "feGaussianBlur")
    let linemapNodeFeBlend = document.createElementNS('http://www.w3.org/2000/svg', "feBlend")

    linemapNodeShadowfilter.id = "connectLinemapLineShadow"
    linemapNodeShadowfilter.setAttribute("width", "120%")
    linemapNodeShadowfilter.setAttribute("height", "120%")

    linemapNodeFeOffset.setAttribute("result", "offOut")
    linemapNodeFeOffset.setAttribute("in","SourceGraphic")
    linemapNodeFeOffset.setAttribute("dx", "1")
    linemapNodeFeOffset.setAttribute("dy", "1")

    linemapNodeFeColorMatrix.setAttribute("result", "matrixOut")
    linemapNodeFeColorMatrix.setAttribute("in", "offOut")
    linemapNodeFeColorMatrix.setAttribute("type", "matrix")
    linemapNodeFeColorMatrix.setAttribute("values", "0.5 0 0 0 0 0 0.5 0 0 0 0 0 0.5 0 0 0 0 0 1 0")

    linemapNodeFeGaussianBlur.setAttribute("result", "blurOut")
    linemapNodeFeGaussianBlur.setAttribute("in", "matrixOut")
    linemapNodeFeGaussianBlur.setAttribute("stdDeviation", "0")

    linemapNodeFeBlend.setAttribute("in", "SourceGraphic")
    linemapNodeFeBlend.setAttribute("in2", "blurOut")
    linemapNodeFeBlend.setAttribute("mode", "normal")

    editmapDefs.appendChild(linemapNodeShadowfilter);
    linemapNodeShadowfilter.appendChild(linemapNodeFeOffset);
    linemapNodeShadowfilter.appendChild(linemapNodeFeColorMatrix);
    linemapNodeShadowfilter.appendChild(linemapNodeFeGaussianBlur);
    linemapNodeShadowfilter.appendChild(linemapNodeFeBlend);
  }

  addLinemapDefs() {
    let linemapDefs = document.createElementNS('http://www.w3.org/2000/svg', "defs")
    let linemapNodeShadowfilter = document.createElementNS('http://www.w3.org/2000/svg',"filter")
    let linemapNodeFeOffset = document.createElementNS('http://www.w3.org/2000/svg', "feOffset")
    let linemapNodeFeColorMatrix = document.createElementNS('http://www.w3.org/2000/svg', "feColorMatrix")
    let linemapNodeFeGaussianBlur = document.createElementNS('http://www.w3.org/2000/svg', "feGaussianBlur")
    let linemapNodeFeBlend = document.createElementNS('http://www.w3.org/2000/svg', "feBlend")

    linemapNodeShadowfilter.id = "linemapLineShadow"
    // linemapNodeShadowfilter.setAttribute("x", "0")
    // linemapNodeShadowfilter.setAttribute("y", "0")
    linemapNodeShadowfilter.setAttribute("width", "120%")
    linemapNodeShadowfilter.setAttribute("height", "120%")

    linemapNodeFeOffset.setAttribute("result", "offOut")
    linemapNodeFeOffset.setAttribute("in","SourceGraphic")
    linemapNodeFeOffset.setAttribute("dx", "1")
    linemapNodeFeOffset.setAttribute("dy", "1")

    linemapNodeFeColorMatrix.setAttribute("result", "matrixOut")
    linemapNodeFeColorMatrix.setAttribute("in", "offOut")
    linemapNodeFeColorMatrix.setAttribute("type", "matrix")
    linemapNodeFeColorMatrix.setAttribute("values", "0.5 0 0 0 0 0 0.5 0 0 0 0 0 0.5 0 0 0 0 0 1 0")

    linemapNodeFeGaussianBlur.setAttribute("result", "blurOut")
    linemapNodeFeGaussianBlur.setAttribute("in", "matrixOut")
    linemapNodeFeGaussianBlur.setAttribute("stdDeviation", "0")

    linemapNodeFeBlend.setAttribute("in", "SourceGraphic")
    linemapNodeFeBlend.setAttribute("in2", "blurOut")
    linemapNodeFeBlend.setAttribute("mode", "normal")

    this.lineViewSvg.appendChild(linemapDefs);
    linemapDefs.appendChild(linemapNodeShadowfilter);
    linemapNodeShadowfilter.appendChild(linemapNodeFeOffset);
    linemapNodeShadowfilter.appendChild(linemapNodeFeColorMatrix);
    linemapNodeShadowfilter.appendChild(linemapNodeFeGaussianBlur);
    linemapNodeShadowfilter.appendChild(linemapNodeFeBlend);
  }

  addBaseLinemapDefs() {
    let linemapDefs = document.createElementNS('http://www.w3.org/2000/svg', "defs")
    let linemapNodeShadowfilter = document.createElementNS('http://www.w3.org/2000/svg',"filter")
    let linemapNodeFeOffset = document.createElementNS('http://www.w3.org/2000/svg', "feOffset")
    let linemapNodeFeColorMatrix = document.createElementNS('http://www.w3.org/2000/svg', "feColorMatrix")
    let linemapNodeFeGaussianBlur = document.createElementNS('http://www.w3.org/2000/svg', "feGaussianBlur")
    let linemapNodeFeBlend = document.createElementNS('http://www.w3.org/2000/svg', "feBlend")

    linemapNodeShadowfilter.id = "baseLinemapLineShadow"
    // linemapNodeShadowfilter.setAttribute("x", "0")
    // linemapNodeShadowfilter.setAttribute("y", "0")
    linemapNodeShadowfilter.setAttribute("width", "120%")
    linemapNodeShadowfilter.setAttribute("height", "120%")

    linemapNodeFeOffset.setAttribute("result", "offOut")
    linemapNodeFeOffset.setAttribute("in","SourceGraphic")
    linemapNodeFeOffset.setAttribute("dx", "1")
    linemapNodeFeOffset.setAttribute("dy", "1")

    linemapNodeFeColorMatrix.setAttribute("result", "matrixOut")
    linemapNodeFeColorMatrix.setAttribute("in", "offOut")
    linemapNodeFeColorMatrix.setAttribute("type", "matrix")
    linemapNodeFeColorMatrix.setAttribute("values", "0.5 0 0 0 0 0 0.5 0 0 0 0 0 0.5 0 0 0 0 0 1 0")

    linemapNodeFeGaussianBlur.setAttribute("result", "blurOut")
    linemapNodeFeGaussianBlur.setAttribute("in", "matrixOut")
    linemapNodeFeGaussianBlur.setAttribute("stdDeviation", "2")

    linemapNodeFeBlend.setAttribute("in", "SourceGraphic")
    linemapNodeFeBlend.setAttribute("in2", "blurOut")
    linemapNodeFeBlend.setAttribute("mode", "normal")

    this.baseViewSvg.appendChild(linemapDefs);
    linemapDefs.appendChild(linemapNodeShadowfilter);
    linemapNodeShadowfilter.appendChild(linemapNodeFeOffset);
    linemapNodeShadowfilter.appendChild(linemapNodeFeColorMatrix);
    linemapNodeShadowfilter.appendChild(linemapNodeFeGaussianBlur);
    linemapNodeShadowfilter.appendChild(linemapNodeFeBlend);
  }

  addEventListenerPaste() {
    let that = this;
    this.root.addEventListener('paste', function(e){
      that.setCtrlVMonitor(e)
    });
  }

  mobolOpenAppView() {
    if (!(this.UiUtil.isPhoneAndPad())) {
      return;
    }
    let list = window.location.href.split("/s/")
    if (list.length <= 1) {
      return;
    }
    var text = document.createElement("div");

    // text.innerHTML = "打开寻简APP"
    this.root.appendChild(text);
    text.style.position = 'absolute';
    text.style.textAlign = 'center';
    text.style.fontSize = "14"
    text.style.height = "140px"
    text.style.width = "90%"
    text.style.color = Colors.getUiColor(Colors.white);
    text.style.backgroundColor = "#514784";
    let url = require("../../../assets/img/user/share_page_down_background_2.png")
    text.style.backgroundImage =  'url(' + url + ')';   
    text.style.backgroundPosition = 'center center';
    text.style.backgroundRepeat = 'no-repeat';
    text.style.backgroundSize = '100% 100%'; 
    text.style.borderRadius = '12px';
    text.style.paddingLeft = 6 + 'px'
    text.style.paddingTop = 6 + 'px'
    text.style.paddingRight = 6 + 'px'
    text.style.paddingBottom = 6 + 'px'
    text.style.boxShadow = "0px 2px 6px rgba(0,0,0,0.3)"

    var logo = document.createElement("img");
    let logoUrl = require("../../../assets/img/code/logo.png")
    logo.src = logoUrl
    logo.style.position = 'absolute';
    logo.style.left = "20px"
    logo.style.top = "40px"
    logo.style.width = "50px"
    logo.style.height = "50px"
    logo.style.borderRadius = "12px"
    text.appendChild(logo);

    var mindText = document.createElement("div");
    mindText.innerHTML = "寻简思维导图"
    text.appendChild(mindText);
    mindText.style.position = 'absolute';
    mindText.style.textAlign = 'center';
    mindText.style.fontSize = "18px"
    mindText.style.fontWeight = 700
    mindText.style.color = Colors.getUiColor(Colors.white);
    mindText.style.left = "80px"
    mindText.style.top = "39px"

    var mindInfoText = document.createElement("div");
    mindInfoText.innerHTML = "全平台,随时随地,梳理思维"
    text.appendChild(mindInfoText);
    mindInfoText.style.position = 'absolute';
    mindInfoText.style.textAlign = 'center';
    mindInfoText.style.fontSize = "12px"
    mindInfoText.style.color = "#A8A7B2";
    mindInfoText.style.left = "80px"
    mindInfoText.style.top = "66px"

    var downText = document.createElement("div");
    text.appendChild(downText);
    downText.style.position = 'absolute';
    downText.style.textAlign = 'center';
    downText.style.fontSize = "16px"
    downText.style.fontWeight = 700
    downText.style.color = Colors.getUiColor(Colors.white);
    downText.style.right = "20px"
    downText.style.top = logo.style.top
    downText.style.width = "105px"
    downText.style.height = "43px"
    downText.style.borderRadius = "8px"
    downText.style.lineHeight = "42px"
    downText.style.backgroundColor = "#DB2D26";

    var downTextLabel = document.createElement("div");
    downTextLabel.innerHTML = "下载"
    downText.appendChild(downTextLabel);

    // let appstore = "https://a.app.qq.com/o/simple.jsp?pkgname=mind.yushu.com"
    // downTextLabel.setAttribute("href", appstore);

    var describe = document.createElement("div");

    describe.innerHTML = "可以前往各大应用市场搜索\"寻简思维导图\"下载"
    text.appendChild(describe);
    describe.style.position = 'absolute';
    describe.style.textAlign = 'center';
    describe.style.fontSize = "14px"
    describe.style.width = "100%"
    describe.style.left = "0px"
    describe.style.bottom = "18px"
    describe.style.color = Colors.getUiColor(Colors.black30);

    var close = document.createElement("img");
    let closeUrl = require("../../../assets/img/backgroundimg/bg_close_icon.svg")
    close.src = closeUrl
    close.style.position = 'absolute';
    close.style.right = "10px"
    close.style.top = "10px"
    close.style.width = "20px"
    close.style.height = "20px"
    close.style.borderRadius = "10px"
    close.style.opacity = 0.3
    // close.style.backgroundColor = Colors.getUiColor(Colors.black, 0.6);
    text.appendChild(close);

    this.mobolOpenAppText = text;
    this.setMobolOpenAppTextPoint();

    text.zIndex = 1000;
    let that = this;
    this.mousdownFunction = (ev) => {
      that.openApp();
    }
    downText.addEventListener('mousedown', this.mousdownFunction)
    this.closeFunction = (ev) => {
      that.mobolOpenAppText.style.display = "none";
    }
    close.addEventListener('click', this.closeFunction)
  }

  setMobolOpenAppTextPoint() {
    if (this.mobolOpenAppText == null || this.mobolOpenAppText.style == null) {
      return;
    }
    this.mobolOpenAppText.style.position = "fixed"
    this.mobolOpenAppText.style.left = '5%';
    this.mobolOpenAppText.style.bottom = '30px';
  }

  isWeixinBrowser(ua) {
    // return (/micromessenger/.test(ua)) ? true : false;
    if (/micromessenger/.test(ua)) {
      return true;//weixin
    } else if (ua.match(/QQ/i) == "qq") {
      return true;//QQ
    }
      return false;
  }

  isAndroid(ua) {
      return ua.indexOf('android') > -1 || ua.indexOf('linux') > -1;
  }

  openApp() {
    let ua = navigator.userAgent.toLowerCase();
    if(this.isWeixinBrowser(ua)) {
        // alert("在系统浏览器中打开该页面")
        let appstore = "https://a.app.qq.com/o/simple.jsp?pkgname=mind.yushu.com"
        // const a = document.createElement("a"); //创建a标签浏览器不会阻止弹出窗口
        // a.setAttribute("href", appstore);
        // a.setAttribute("target", "_self");
        // document.body.appendChild(a);
        // a.click();
        // setTimeout(() => {
        //   document.body.removeChild(a);
        // }, 1000);

        // window.location.href = appstore;
        //     let loadDateTime = Date.now();
        //     let turn = setTimeout(function(){
        //         let timeOutDateTime = Date.now();
        //         if ((timeOutDateTime - loadDateTime) < 3000 ) {
        //           window.location.href = appstore;
        //         }
        //     },100);

        let a = document.createElement("a");
        let event = new MouseEvent("click");
        a.download = "寻简";
        a.href = appstore;
        a.dispatchEvent(event);
        setTimeout(() => a = null, 1000);
    } else {
        let list = window.location.href.split("/s/")
        if (list.length <= 1) {
          return;
        }
        if(this.isAndroid(ua)){
            //android
            window.location.href = 'xunjianmind://web.mindyushu.com/s/' + list[list.length-1];
            let loadDateTime = Date.now();
            let turn = setTimeout(function(){
                let timeOutDateTime = Date.now();
                if ((timeOutDateTime - loadDateTime) < 3000 ) {
                  window.location.href = 'https://mindyushu.com';
                }
            },2000);
        } else {
            //ios
            window.location.href = 'xunjianmind://web.mindyushu.com/s/' + list[list.length-1];
            let loadDateTime = Date.now();
            let turn = setTimeout(function(){
                let timeOutDateTime = Date.now();
                if ((timeOutDateTime - loadDateTime) < 1000 ) {
                  window.location.href = 'https://mindyushu.com';
                }
            },600);
        }
    }
      
  }

  setHiddenButton(data) {
    // 如果子节点没有隐藏，且子节点数量不为0，展示隐藏按钮
    let node = this.vueThis.editMindmapContent.getNodeById(data.id)
    let selectMindView = null;
    let elementMindViewsArr = this.vueThis.viewElementList.values();
    for (let index = 0; index < elementMindViewsArr.length; index++) {
      let view = elementMindViewsArr[index];
      let isSelected = view.mindElementData.id == data.id;
      // view.setSelected(isSelected);
      if (isSelected) {
        selectMindView = view;
      }
    }

    if (this.vueThis.mindType == MindType.LINE_MAP &&
      selectMindView != null &&
      data.hiddenNumber == 0 &&
      node.children.length > 0) {
      let space = this.UiUtil.dip2px(0);
      let left;
      let top;
      let showHideCircleDia = Config.showHideCircleDia
      let layoutBottomCenterDistance = Config.layoutBottomCenterDistance
      if (data.type == MindElementType.SUBJECT || data.type == MindElementType.MAIN_SUBJECT) {
        if (data.layout == NodeLayoutType.LAYOUT_LEFT ||
          data.layout == NodeLayoutType.LAYOUT_BRACKETS_LEFT ||
          data.layout == NodeLayoutType.LAYOUT_TREE_LEFT ||
          data.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT) {
          left = data.x + data.width + space
          if (data.mindElementShape == MindElementShapeType.Underline || data.mindElementShape == MindElementShapeType.Two_Underline) {
            top = data.y + data.height - showHideCircleDia;
          } else {
            top = data.y + data.height / 2 - showHideCircleDia / 2;
          }
          this.mindChildshowButton.setPosition({ left: left, top: top });
        } else if (
          data.layout == NodeLayoutType.LAYOUT_LEFT_RIGHT ||
          data.layout == NodeLayoutType.LAYOUT_RIGHT_LEFT ||
          data.layout == NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE ||
          data.layout == NodeLayoutType.LAYOUT_RIGHT ||
          data.layout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT ||
          data.layout == NodeLayoutType.LAYOUT_TREE_RIGHT ||
          data.layout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT ||
          data.layout == NodeLayoutType.LAYOUT_FISH_RIGHT ||
          data.layout == NodeLayoutType.LAYOUT_FISH_LEFT ||
          data.layout == NodeLayoutType.LAYOUT_FORM||
          data.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL||
          data.layout == NodeLayoutType.LAYOUT_CIRCLE ||
          data.layout == NodeLayoutType.LAYOUT_FREE_TREE) {
          left = data.x - space - showHideCircleDia;
          if (data.mindElementShape == MindElementShapeType.Underline || data.mindElementShape == MindElementShapeType.Two_Underline) {
            top = data.y + data.height - showHideCircleDia / 2;
          } else {
            top = data.y + data.height / 2 - showHideCircleDia / 2;
          }
          this.mindChildshowButton.setPosition({ left: left, top: top });
        } else if (data.layout == NodeLayoutType.LAYOUT_BOTTOM ||
                  data.layout == NodeLayoutType.LAYOUT_TREE_LEFT_RIGHT) {
          left = data.x + data.width / 2 - showHideCircleDia / 2;
          top = data.y - space - showHideCircleDia;
          this.mindChildshowButton.setPosition({ left: left, top: top });
        }
         else if (data.layout == NodeLayoutType.LAYOUT_TOP ||
          data.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT_RIGHT) {
          left = data.x + data.width/2 - showHideCircleDia/2;
          top = data.y + space  + data.height;
          this.mindChildshowButton.setPosition({ left: left, top: top });
        }

      } else if (data.type == MindElementType.SON_SUBJECT) {
        if (
          data.layout == NodeLayoutType.LAYOUT_LEFT ||
          data.layout == NodeLayoutType.LAYOUT_BRACKETS_LEFT ||
          data.layout == NodeLayoutType.LAYOUT_TREE_LEFT ||
          data.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT
          ) {
          left = data.x + data.width + space
          if (data.mindElementShape == MindElementShapeType.Underline || data.mindElementShape == MindElementShapeType.Two_Underline) {
            top = data.y + data.height - showHideCircleDia / 2;
          } else {
            top = data.y + data.height / 2 - showHideCircleDia / 2;
          }
        } else if (data.layout == NodeLayoutType.LAYOUT_RIGHT ||
          data.layout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT ||
          data.layout == NodeLayoutType.LAYOUT_TREE_RIGHT ||
          data.layout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT ||
          data.layout == NodeLayoutType.LAYOUT_FISH_RIGHT ||
          data.layout == NodeLayoutType.LAYOUT_FISH_LEFT ||
          data.layout == NodeLayoutType.LAYOUT_FORM ||
          data.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL ||
          data.layout == NodeLayoutType.LAYOUT_FREE_TREE ||
          data.layout == NodeLayoutType.LAYOUT_CIRCLE) {
          left = data.x - space - showHideCircleDia;
          if (data.mindElementShape == MindElementShapeType.Underline || data.mindElementShape == MindElementShapeType.Two_Underline) {
            top = data.y + data.height - showHideCircleDia / 2;
          } else {
            top = data.y + data.height / 2 - showHideCircleDia / 2;
          }
          
        } else if (data.layout == NodeLayoutType.LAYOUT_BOTTOM) {
          left = data.x + data.width / 2 - showHideCircleDia / 2;
          top = data.y - space - showHideCircleDia;
        } 
         else if (data.layout == NodeLayoutType.LAYOUT_TOP) {
            left = data.x + data.width/2 - showHideCircleDia/2;
            top = data.y + space + data.height;
        }
        this.mindChildshowButton.setPosition({ left: left, top: top });
      }


      // UiUtil.setViewVisible(mindNodeUpIcon);
      this.mindChildshowButton.checkId(data.id);

      if (Colors.isClear(data.borderColor) || data.borderWidth == 0) {
        //如果没有设置线条颜色，隐藏图标的颜色就取画布的相反颜色。
        this.mindChildshowButton.setColor(this.vueThis.DrawingBoardBackgroundColor, this.vueThis.DrawingBoardBackgroundColor);
      } else {
        this.mindChildshowButton.setColor(this.vueThis.DrawingBoardBackgroundColor, data.borderColor);
      }
      // mindNodeUpIcon.setLayoutParams(params);
    } else {
      this.mindChildshowButton.hideDom()
    }

  };

  hideDataAngleButton() {
    if (this.mindDataChangeAngleButton == null) {
      return
    }
    this.mindDataChangeAngleButton.hideDom()
  }

  setDataAngleButton(data) {
    // 如果子节点没有隐藏，且子节点数量不为0，展示隐藏按钮
    if (data == null || this.controllCircleDrag || this.changeDataAngleDrag || this.isChangeControllBoxSize || 
        (this.checkBoxView != null && this.checkBoxView.isCheckBox) || 
        !this.vueThis.editMindmapContent.isFlowChart() ||  
        (data.type != MindElementType.MAIN_SUBJECT && data.type != MindElementType.SUBJECT)) {
        this.hideDataAngleButton();
        return
    }
    this.mindDataChangeAngleButton.checkId(data.id, data);
    let space = this.UiUtil.dip2px(6);
    let x = data.x + data.width + space
    let y = data.y - this.mindDataChangeAngleButton.size.height - space
    let center = data.getCenterPoint();
    let spcae = Util.getPointSpacing(center, new Point(x + this.mindDataChangeAngleButton.size.width/2, y + this.mindDataChangeAngleButton.size.height/2))
    let defaultAngle = Util.getCircleDegreesInPoint(center, new Point(x + this.mindDataChangeAngleButton.size.width/2, y + this.mindDataChangeAngleButton.size.height/2))
    let point = Util.getCirclePoint(center, data.angle + defaultAngle, spcae)
    this.mindDataChangeAngleButton.updateAngleText(-1000);
    this.mindDataChangeAngleButton.setPosition({ left: point.x - this.mindDataChangeAngleButton.size.width/2, top: point.y - this.mindDataChangeAngleButton.size.height/2});
    
    let color = (!Colors.isClear(data.borderColor) && data.borderWidth > 0 && data.borderColor != this.vueThis.DrawingBoardBackgroundColor) ? data.borderColor : data.backgroundColor;
    if (Colors.isClear(color) || color == this.vueThis.DrawingBoardBackgroundColor) {
      if (data.textContent != null) {
        color = data.textContent.textColor
      } else if (data.generalizationContent != null) {
        color = data.generalizationContent.textColor
      }
    }
    this.mindDataChangeAngleButton.setColor(this.vueThis.DrawingBoardBackgroundColor, color);
  };

  setAddFlowChartView(data) {
    // 如果子节点没有隐藏，且子节点数量不为0，展示隐藏按钮
    if (data == null || this.controllCircleDrag || this.changeDataAngleDrag || this.isChangeControllBoxSize || 
        (this.checkBoxView != null && this.checkBoxView.isCheckBox) || 
        !this.vueThis.editMindmapContent.isFlowChart() ||  
        (data.type != MindElementType.MAIN_SUBJECT && data.type != MindElementType.SUBJECT)) {
        this.hideAddFlowChartDataLeftView();
        return
    }
    this.createAddFlowDataView()

    this.addFlowChartDataLeftView.checkId(data.id, data);
    this.addFlowChartDataTopView.checkId(data.id, data);
    this.addFlowChartDataRightView.checkId(data.id, data);
    this.addFlowChartDataBottomView.checkId(data.id, data);

    let size = this.addFlowChartDataLeftView.size.width    
    let space = this.UiUtil.dip2px(4);
    let leftPoint = new CGPoint(data.x - size/2 - space, data.y + data.height/2); //中心点
    let topPoint = new CGPoint(data.x + data.width / 2, data.y - size/2 - space);
    let rightPoint = new CGPoint(data.x + data.width + space + size/2, data.y + data.height/2);
    let bottomPoint = new CGPoint(data.x + data.width / 2, data.y + data.height + space + size/2);

    let center = data.getCenterPoint();
    let radius = Util.getPointSpacing(center, leftPoint)
    leftPoint = Util.getCirclePoint(center, data.angle + 180, radius)
    
    radius = Util.getPointSpacing(center, topPoint)
    topPoint = Util.getCirclePoint(center, data.angle + 270, radius)

    radius = Util.getPointSpacing(center, rightPoint)
    rightPoint = Util.getCirclePoint(center, data.angle + 0, radius)

    radius = Util.getPointSpacing(center, bottomPoint)
    bottomPoint = Util.getCirclePoint(center, data.angle + 90, radius)

    this.addFlowChartDataLeftView.setPosition({ left: leftPoint.x - size/2, top: leftPoint.y - size/2});
    this.addFlowChartDataTopView.setPosition({ left: topPoint.x - size/2, top: topPoint.y - size/2});
    this.addFlowChartDataRightView.setPosition({ left: rightPoint.x - size/2, top: rightPoint.y - size/2});
    this.addFlowChartDataBottomView.setPosition({ left: bottomPoint.x - size/2, top: bottomPoint.y - size/2});
    let color = (!Colors.isClear(data.borderColor) && data.borderWidth > 0 && data.borderColor != this.vueThis.DrawingBoardBackgroundColor) ? data.borderColor : data.backgroundColor;
    if (Colors.isClear(color) || color == this.vueThis.DrawingBoardBackgroundColor) {
      if (data.textContent != null) {
        color = data.textContent.textColor
      } else if (data.generalizationContent != null) {
        color = data.generalizationContent.textColor
      }
    }
    this.addFlowChartDataLeftView.setColor(this.vueThis.DrawingBoardBackgroundColor, color);
    this.addFlowChartDataTopView.setColor(this.vueThis.DrawingBoardBackgroundColor, color);
    this.addFlowChartDataRightView.setColor(this.vueThis.DrawingBoardBackgroundColor, color);
    this.addFlowChartDataBottomView.setColor(this.vueThis.DrawingBoardBackgroundColor, color);
  };

  createdAssistNode() {
    this.AssistNode = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    this.AssistNodeChild = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
    this.AssistNode.appendChild(this.AssistNodeChild);
    this.contentViewSvg.appendChild(this.AssistNode);
    this.AssistNodeLineChild = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    this.AssistNode.appendChild(this.AssistNodeLineChild);
  }

  showAssistNode(obj) {
    if (this.AssistNode == null) {
      this.createdAssistNode()
    }

    let color;
    if (this.delegate.isDarkColor) {
      color = 'rgba(253,73,43,.8)'

    } else {
      color = 'rgb(255,255,255)'
    }
    this.AssistNode.style.display = 'block'
    this.AssistNodeChild.setAttribute('x', obj.rect.x);
    this.AssistNodeChild.setAttribute('y', obj.rect.y);
    this.AssistNodeChild.setAttribute('width', obj.rect.size.width);
    this.AssistNodeChild.setAttribute('height', obj.rect.size.height);
    this.AssistNodeChild.setAttribute('fill', color);

    let line = new LinePath();
    line.moveTo(obj.startPoint.x, obj.startPoint.y);
    line.lineTo(obj.endPoint.x, obj.endPoint.y);
    this.AssistNodeLineChild.setAttribute('d', line.getLine());
    this.AssistNodeLineChild.setAttribute('stroke', color);
    this.AssistNodeLineChild.setAttribute('stroke-width', '2');

  };

  hideAssistNode() {
    if (this.AssistNode == null) {
      return
    }
    this.AssistNode.parentNode.removeChild(this.AssistNode);  //之所以要remove是因为要一直保持节点不被其它节点遮挡。
    this.AssistNode = null
  }


  guideLine() {
    this.guideLineG = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    this.guideLineLeft = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    this.guideLineTop = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    this.guideLineRight = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    this.guideLineBottom = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    this.guideLineG.appendChild(this.guideLineLeft);
    this.guideLineG.appendChild(this.guideLineTop);
    this.guideLineG.appendChild(this.guideLineRight);
    this.guideLineG.appendChild(this.guideLineBottom);
    this.lineViewSvg.appendChild(this.guideLineG);
    this.guideLineG.setAttribute('width', this.width);
    this.guideLineG.setAttribute('height', this.height);

  }

  hidenGuideLine() {
    this.guideLineG.style.display = 'none'
  }

  isShowGuideLine() {
    if (this.guideLineG == null || this.guideLineG.style == null) {
      return false
    }
    return this.guideLineG.style.display == 'block'
  }

  setGuideLine(dom, newx, newy) {
    this.guideLineG.style.display = 'block'
    let width = dom.width;
    let height = dom.height;
    let color;
    if (this.delegate.isDarkColor) {
      color = Colors.getUiColor(Colors.black50)
    } else {
      color = 'rgb(255,255,255)'
    }

    let leftLine = new LinePath()
    leftLine.moveTo(newx, 0);
    leftLine.lineTo(newx, this.height);
    this.renderGuideLine(leftLine, this.guideLineLeft, color);

    let topLine = new LinePath()
    topLine.moveTo(0, newy);
    topLine.lineTo(this.width, newy);
    this.renderGuideLine(topLine, this.guideLineTop, color);

    let rightLine = new LinePath()
    rightLine.moveTo(newx + width, 0);
    rightLine.lineTo(newx + width, this.height);
    this.renderGuideLine(rightLine, this.guideLineRight, color);

    let bottomLine = new LinePath()
    bottomLine.moveTo(0, newy + height);
    bottomLine.lineTo(this.width, newy + height);
    this.renderGuideLine(bottomLine, this.guideLineBottom, color);
  }

  renderGuideLine(line, guide, color) {
    guide.setAttribute('d', line.getLine());
    guide.setAttribute('stroke', color);
    guide.setAttribute('stroke-width', '1');
    guide.setAttribute("stroke-dasharray", "11,5")
  }

  windowMouse(elementView) {
    let mouse = (e) => {
      this.isMousePoint = this.isMouseViewPort(e.pageX, e.pageY, this.root.offsetLeft, this.root.offsetTop, this.root.offsetWidth, this.root.offsetHeight, this.vueThis.canvasFullscreen)
      
      if (elementView != null && !this.isMousePoint) {
        this.delegate.changeNodePoint(elementView, new Point(elementView.mindElementData.x, elementView.mindElementData.y))
        // window.removeEventListener('mousemove',mouse);
        this.contentView.onmousemove = null;
        this.contentView.onmouseup = null;
      }
      return;
    }
    window.addEventListener('mousemove', mouse);
  }

  isMouseViewPort(x, y, left, top, width, height, canvasFullscreen) {
    let deviantLeft = 0;
    if (!canvasFullscreen) {
      deviantLeft = 52
    }
    if (x < left + deviantLeft || x > left + width - 1 || y < top + 1 || y > top + height - 1) {
      return false;
    }
    return true;
  }
  //滚轮事件
  wheelEventToTouchPad() {
    var that = this
    //判断鼠标滚轮滚动方向
    if (window.addEventListener)
      //FF,火狐浏览器会识别该方法
      this.contentView.addEventListener("DOMMouseScroll", wheel, false);
    this.contentView.onmousewheel = this.contentView.onmousewheel = wheel; //W3C
    //统一处理滚轮滚动事件
    var scaleRatio = 400;
    function wheel(event) {
      event.stopPropagation()
      event.preventDefault()
      if (event.ctrlKey) {
        let change = event.deltaY / scaleRatio
        let scale = that.scale - change
        that.setScale(scale)
      } else {
        let left = that.getContentScrollX()
        let top = that.getContentScrollY()

        left = left + event.deltaX;
        top = top + event.deltaY;

        that.scrollTo(left, top);
        that.boundaryHandler();
        that.gtouchend()
      }
    }
  }



  contentMouseupForRightKey(e) {//画布右键
    if (e == null) {
      return;
    }
    let that = this
    // this.contentView.onmouseup = function (e) {
      //点击空白画布时关闭更多和附件图标的菜单
      let rootRect = that.root.getBoundingClientRect()
      var rootClientX = rootRect.left;
      var rootClientY = rootRect.top;

      let left = that.getContentScrollX()
      let top = that.getContentScrollY()
      let eventX = (e.clientX - rootClientX) / that.scale;
      let eventY = (e.clientY - rootClientY) / that.scale;
      let contentX = left / that.scale;
      let contentY = top / that.scale;
      
      let clickPointX = eventX + contentX;
      let clickPointY = eventY + contentY;      
      if (e.button == 2) {
        if (that.contentViewMousedownPoint == null ||
          (Math.abs(that.contentViewMousedownPoint.x - e.clientX) < 10 &&
          Math.abs(that.contentViewMousedownPoint.y - e.clientY) < 10)) {
            let elementView = that.delegate.getMindElementDataInDrawingBoardByPoint(new Point(clickPointX, clickPointY));
            // that.delegate.onClickInDrawingBoard(e, new Point(clickPointX, clickPointY), e.button);  //用于判断点击到画布上的什么东西了。
            if (elementView.id == -1) {
              if (!that.delegate.isTemplatePage && !that.delegate.isSharePage) {

                that.delegate.moreMenu = false;
                that.delegate.moreNodeId = null;
                that.delegate.showResourceModel = false;
                that.isDragCanvas = false;
                
                // 当前单击的画布坐标点x和y
                // that.delegate.onClickInDrawingBoard(e, new Point(clickPointX, clickPointY));
                if (e.button == 0 || e.button == 2) {
                  that.allHiddenMenu()
                  
                }

                that.delegate.onAddFreeNodeMenu(e, new Point(clickPointX, clickPointY))
              }
            }
        }
      }
      that.contentViewMousedownPoint = null;
    // };
  }

  dargeDom() {    //拖动函数
    let that = this

    this.contentView.onmousedown = function (e) {
      //点击空白画布时关闭更多和附件图标的菜单
      that.delegate.moreMenu = false;
      that.delegate.moreNodeId = null;
      that.delegate.showResourceModel = false;
      that.isDragCanvas = false;
      if (e.button == 0 && that.selectData != null && that.isShowInput()) {   //单击了画布，隐藏输入框。
        that.hideInput();
      }
      
      let rootRect = that.root.getBoundingClientRect()
      var rootClientX = rootRect.left;
      var rootClientY = rootRect.top;

      let left = that.getContentScrollX()
      let top = that.getContentScrollY()
      let eventX = (e.clientX - rootClientX) / that.scale;
      let eventY = (e.clientY - rootClientY) / that.scale;
      let contentX = left / that.scale;
      let contentY = top / that.scale;
      
      let clickPointX = eventX + contentX;
      let clickPointY = eventY + contentY;
      that.contentViewMousedownPoint = new Point(e.clientX, e.clientY)
      // 当前单击的画布坐标点x和y
      // that.delegate.onClickInDrawingBoard(e, new Point(clickPointX, clickPointY));
      if (e.button == 0 || e.button == 2) {
        that.allHiddenMenu()
        
      }
      
      if (e.button == 2) {
        let elementView = that.delegate.getMindElementDataInDrawingBoardByPoint(new Point(clickPointX, clickPointY));
         //用于判断点击到画布上的什么东西了。
        if (elementView.id == -1) {//移动画布
          that.isDragCanvas = true
          that.dragContentView(e, clickPointX, clickPointY, true);
          that.delegate.isShowFontStyleMenu({x:null,y:null},false)
        } else {
          that.delegate.onClickInDrawingBoard(e, new Point(clickPointX, clickPointY), e.button);
          that.onClickBackgroundImageBt(e)
        }
      }
      if (e.button == 0) { //0左键，2右键
        if (that.resetMindView != null &&
          that.resetMindView.hit(clickPointX, clickPointY)) {
            that.delegate.onClickInDrawingBoard(e, new Point(clickPointX, clickPointY), e.button);
            that.onClickBackgroundImageBt(e)
        }
        let circleDraw1 = that.isClickPointCircle(that.controllCircle1Data, clickPointX, clickPointY);
        let leftBoundary1 = that.controllCircle1Data.x;
        let topBoundary1 = that.controllCircle1Data.y;
        let circleDraw2 = that.isClickPointCircle(that.controllCircle2Data, clickPointX, clickPointY);
        let leftBoundary2 = that.controllCircle2Data.x;
        let topBoundary2 = that.controllCircle2Data.y;

        let circleDrawStart = that.isClickPointCircle(that.circleStartData, clickPointX, clickPointY);
        let leftBoundaryStart = that.circleStartData.x;
        let topBoundaryStart = that.circleStartData.y;
        let circleDrawEnd = that.isClickPointCircle(that.circleEndData, clickPointX, clickPointY);
        let leftBoundaryEnd = that.circleEndData.x;
        let topBoundaryEnd = that.circleEndData.y;
        let controllMindSizeLeft = that.isClickPointCircle(that.controllMindSize.leftControllSizeBoxObj, clickPointX, clickPointY);
        let controllMindSizeRight = that.isClickPointCircle(that.controllMindSize.rightControllSizeBoxObj, clickPointX, clickPointY);
        let isHitAngleButton = Util.containsInRectForXY(that.mindDataChangeAngleButton.getRect(), clickPointX, clickPointY);
        let rightAngleHitPointData = null;
        let rightAngleHitPointDataIndex = -1
        for (let index = 0; index < that.rightAnglePoints.length; index++) {
          let cell = that.rightAnglePoints[index]
          if (cell != null && that.isClickPointCircle(cell.data, clickPointX, clickPointY)) {
            rightAngleHitPointData = cell
            rightAngleHitPointDataIndex = index;
            break
          }
        }
        if (isHitAngleButton) {
          that.changeDataAngleDraw(e.pageX, e.pageY, that.mindDataChangeAngleButton.left, that.mindDataChangeAngleButton.top)
        } else if (rightAngleHitPointData != null) {
          that.controllCircleDraw(e.pageX, e.pageY, rightAngleHitPointData.data.x, rightAngleHitPointData.data.y, 
            rightAngleHitPointData.dom, rightAngleHitPointData.data, true, rightAngleHitPointDataIndex);
          //隐藏拖动节点大小的区域
          that.controllMindSize.hiddenBottom();
        } else if (circleDrawStart) {
          that.controllCircleDraw(e.pageX, e.pageY, leftBoundaryStart, topBoundaryStart, that.startCircle, that.circleStartData, true);
          //隐藏拖动节点大小的区域
          that.controllMindSize.hiddenBottom();
        } else if (circleDrawEnd) {
          that.controllCircleDraw(e.pageX, e.pageY, leftBoundaryEnd, topBoundaryEnd, that.endCircle, that.circleEndData, false);
          //隐藏拖动节点大小的区域
          that.controllMindSize.hiddenBottom();
        } else if (circleDraw1) {
          that.controllCircleDraw(e.pageX, e.pageY, leftBoundary1, topBoundary1, that.controllStartCircle, that.controllCircle1Data, true);
          //隐藏拖动节点大小的区域
          that.controllMindSize.hiddenBottom();
        } else if (circleDraw2) {
          that.controllCircleDraw(e.pageX, e.pageY, leftBoundary2, topBoundary2, that.controllEndCircle, that.controllCircle2Data, false);
          //隐藏拖动节点大小的区域
          that.controllMindSize.hiddenBottom();
        } else if (controllMindSizeLeft && that.resetMindView.isDragFlag() == false ) {
          that.controllBoxSize(e.pageX, e.pageY, 'left');
        } else if (controllMindSizeRight && that.resetMindView.isDragFlag() == false ) {
          that.controllBoxSize(e.pageX, e.pageY, 'right');
        } else {
            if (that.resetMindView.DragImageFlag) {
              that.scalImage(e.pageX, e.pageY)
            } else {
              let elementView = that.delegate.getElemeViewInDrawingBoardByPoint(new Point(clickPointX, clickPointY));
              if (that.delegate.isEditStatus() && elementView != null && elementView.id != -1 && !that.isResetMindViewDrag()) { //拖动元素
                if (elementView.mindElementData != null && 
                    (elementView.mindElementData.type == MindElementType.NODE_CONNECT_LINE || 
                      elementView.mindElementData.type == MindElementType.BAOWEI_VIEW ||
                      elementView.mindElementData.isHandwritingContent())) {
                  that.delegate.onClickInDrawingBoard(e, new Point(clickPointX, clickPointY), e.button);
                }
                that.setElementMove(elementView, e.pageX, e.pageY);
              } else  if (that.resetMindView.DragFlag) { //拖动包裹线的范围。
                that.dragEncircleBox(e.pageX, e.pageY)
              }  else { //移动画布
                that.isDragCanvas = true
                that.dragContentView(e, clickPointX, clickPointY);
                that.delegate.isShowFontStyleMenu({x:null,y:null},false)
              }
            }
          //隐藏拖动节点大小的区域
          that.controllMindSize.hiddenBottom();
        }
      }
    };
    
    //当鼠标放在了节点的可拖动位置的时候鼠标变为可左右拖动的样式。
    let changeMouseMoveSizeIcon = function(e) {
      if (that.isDragCanvas) {
        return
      }
      that.showChangeBackgroundImageBt(e)
      var rootRect = that.root.getBoundingClientRect()
      var rootClientX = rootRect.left;
      var rootClientY = rootRect.top;

      let left = that.getContentScrollX()
      let top = that.getContentScrollY()
      let eventX = (e.clientX - rootClientX) / that.scale;
      let eventY = (e.clientY - rootClientY) / that.scale;
      let contentX = left / that.scale;
      let contentY = top / that.scale;
      let clickPointX = eventX + contentX;
      let clickPointY = eventY + contentY;

      let controllMindSizeLeft = that.isClickPointCircle(that.controllMindSize.leftControllSizeBoxObj, clickPointX, clickPointY);
      let controllMindSizeRight = that.isClickPointCircle(that.controllMindSize.rightControllSizeBoxObj, clickPointX, clickPointY);

      if (controllMindSizeLeft) {
        if (that.controllMindSize.mindElementData != null &&
          that.controllMindSize.mindElementData.isHandwritingContent()) {
          that.contentView.style.cursor = ''
        } else {
          that.contentView.style.cursor = 'w-resize'
          that.mouseMovePoint.x = 0;
          that.mouseMovePoint.y = 0;
        }        
      } else if (controllMindSizeRight) {
        if (that.controllMindSize.mindElementData != null &&
          that.controllMindSize.mindElementData.isHandwritingContent()) {
          that.contentView.style.cursor = ''
        } else {
          that.contentView.style.cursor = 'e-resize'
          that.mouseMovePoint.x = 0;
          that.mouseMovePoint.y = 0;
        }
      } else {
        that.contentView.style.cursor = ''
        if (that.controllCircleDrag || 
          (that.resetMindView != null && (that.resetMindView.DragImageFlag || that.resetMindView.DragFlag))) {
            that.mouseMovePoint.x = 0;
            that.mouseMovePoint.y = 0;
        } else {
            that.mouseMovePoint.x = clickPointX;
            that.mouseMovePoint.y = clickPointY;
        }
        
      }
      if (that.mouseMovePoint != null && 
        that.mouseMovePoint.x > 0 &&
        that.mouseMovePoint.y > 0) {
          if (EditMindmapVM.checkMouseMove(that.mouseMovePoint)) {
            that.contentView.style.cursor = 'pointer';
          } else {
            that.contentView.style.cursor = '';
          }
      }
    }
    let changeMouseonMouseup = function(e) {
      if (EditMindmapVM.isCurrentAddNodeConnectLine()) {
        let rootRect = that.root.getBoundingClientRect()
        var rootClientX = rootRect.left;
        var rootClientY = rootRect.top;

        let left = that.getContentScrollX()
        let top = that.getContentScrollY()
        let eventX = (e.clientX - rootClientX) / that.scale;
        let eventY = (e.clientY - rootClientY) / that.scale;
        let contentX = left / that.scale;
        let contentY = top / that.scale;
        
        let clickPointX = eventX + contentX;
        let clickPointY = eventY + contentY;   
        EditMindmapVM.clickPoints(new Point(clickPointX, clickPointY), e)
      }
    }
    this.contentView.addEventListener('mousemove',changeMouseMoveSizeIcon);    
    this.contentView.addEventListener('mouseup',changeMouseonMouseup);    
    this.setIhoneMoveView();
  }



  setIhoneMoveView() {
    let that = this
    this.ihoneMoveDisX = -100000
    this.ihoneMoveDisY = -100000
    this.ihoneMoveToucheOneDisX = -100000
    this.ihoneMoveToucheOneDisY = -100000
    
    this.moveFunc = null
    
    var lastTouchEnd = 0;
    
    document.addEventListener('touchstart', function(event) {
        if (event.touches.length > 1 && event.preventDefault != null) {
            event.preventDefault();
        }
    });
    document.addEventListener('touchend', function(event) {
        var now = (new Date()).getTime();
        if (now - lastTouchEnd <= 300) {
            event.preventDefault();
        }
        that.spacingScale = 0;
        that.spacing = -1
        lastTouchEnd = now;
    }, false);
    document.addEventListener('gesturestart', function(event) {
        event.preventDefault();
    });
    document.addEventListener('dblclick', function (event) {
        event.preventDefault();
    })

    that.contentView.addEventListener('touchstart', function (touchStartEvent) {
      if (touchStartEvent.touches.length == 1) {
        that.ihoneMoveDisX = touchStartEvent.touches[0].pageX - that.getContentScrollX();
        that.ihoneMoveDisY = touchStartEvent.touches[0].pageY - that.getContentScrollY();

        that.ihoneMoveToucheOneDisX = touchStartEvent.touches[0].pageX + that.getContentScrollX();
        that.ihoneMoveToucheOneDisY = touchStartEvent.touches[0].pageY + that.getContentScrollY();

        let moveScrollViewSelf = that;
        if (that.moveFunc != null) {
          that.contentView.removeEventListener('touchmove', that.moveFunc);
        }

        that.moveFunc = function (moveEvent) {
          if (moveScrollViewSelf.ihoneMoveDisX == -100000) {
            return
          }
          if (moveEvent.touches.length == 1) {
            moveEvent.stopPropagation()
            moveEvent.preventDefault()
            let left = moveScrollViewSelf.ihoneMoveToucheOneDisX - moveEvent.touches[0].pageX;
            let top = moveScrollViewSelf.ihoneMoveToucheOneDisY - moveEvent.touches[0].pageY;
            
            moveScrollViewSelf.scrollTo(left, top);
            moveScrollViewSelf.boundaryHandler();
          } else if (moveEvent.touches.length == 2) {
            moveEvent.stopPropagation()
            moveEvent.preventDefault()
            if (that.spacingScale <= 0 || that.spacing <= 0) {
              that.spacingScale = that.scale;
              that.spacing = (Util).getPointSpacing(
                new Point(moveEvent.touches[0].pageX, moveEvent.touches[0].pageY), 
                new Point(moveEvent.touches[1].pageX, moveEvent.touches[1].pageY));
              return;
            }
            let left1 = moveEvent.touches[0].pageX - moveScrollViewSelf.ihoneMoveDisX;
            let top1 = moveEvent.touches[0].pageY - moveScrollViewSelf.ihoneMoveDisY;

            let left2 = moveEvent.touches[1].pageX - moveScrollViewSelf.ihoneMoveDisX;
            let top2 = moveEvent.touches[1].pageY - moveScrollViewSelf.ihoneMoveDisY;
            let pointSpace = (Util).getPointSpacing(
              new Point(moveEvent.touches[0].pageX, moveEvent.touches[0].pageY), 
              new Point(moveEvent.touches[1].pageX, moveEvent.touches[1].pageY));
            let changeScale = that.spacingScale * (pointSpace / that.spacing);
            if (changeScale != that.scale) {
              that.setScale(changeScale)
            }
          }
        }
        that.contentView.addEventListener('touchmove', that.moveFunc);
      } else if (touchStartEvent.touches.length == 2) {
        moveEvent.stopPropagation()
        moveEvent.preventDefault()
        that.spacingScale = that.scale;
        that.spacing = (Util).getPointSpacing(new Point(touchStartEvent.touches[0].pageX, touchStartEvent.touches[0].pageY), 
                                              new Point(touchStartEvent.touches[1].pageX, touchStartEvent.touches[1].pageY));
      }
    });

    that.contentView.addEventListener('touchend', function (touchStartEvent) {
      that.ihoneMoveDisX = -100000
      that.ihoneMoveDisY = -100000
      that.ihoneMoveToucheOneDisX = -100000
      that.ihoneMoveToucheOneDisY = -100000
      that.contentView.removeEventListener('touchmove', that.moveFunc);
      that.moveFunc = null;
    });
  }

  setElementMove(elementView, startX, startY) {
    if (elementView == null) {
      return;
    }
    
    this.controllMindSize.setPosition(elementView.mindElementData);

    let moveX = 0;
    let moveY = 0;
    let elementViewX = elementView.mindElementData.x;
    let elementViewY = elementView.mindElementData.y;
    // let elementViewWidth = elementView.mindElementData.width;
    // let elementViewHeight = elementView.mindElementData.height;
    let that = this;
    that.contentView.onmousemove = function (dargeEvent) {
      if (that.isResetMindViewDrag()) {
        return;
      }
      //限制注释不可拖动
      if( elementView.constructor != undefined && 
        elementView.constructor != null && 
        elementView.constructor.name == "FloatExplainView"){
        return;
      }
      //拖动节点的时候隐藏隐藏子节点按钮。
      that.mindChildshowButton.hideDom()
      that.hideDataAngleButton()
      that.hideAddFlowChartDataLeftView();
      let movePxX = dargeEvent.pageX - startX;
      let movePxY = dargeEvent.pageY - startY
      let moveDistance = Util.getPointSpacing(new Point(dargeEvent.pageX, dargeEvent.pageY),
                                  new Point(startX, startY));
      if (!that.isShowGuideLine() && moveDistance < 6) {
        return
      }
      // let point = that.isMouseViewPort(dargeEvent.pageX, dargeEvent.pageY, that.root.offsetLeft, that.root.offsetTop, that.root.offsetWidth, that.root.offsetHeight)
      moveX = movePxX * (1 / that.scale);
      moveY = movePxY * (1 / that.scale);
      that.drageSeatMax = Math.max(Math.abs(movePxX), that.drageSeatMax);
      that.drageSeatMax = Math.max(Math.abs(movePxY), that.drageSeatMax);
      if (elementView.hideAddNodeIcon != null) {
        elementView.hideAddNodeIcon(false);
      }
      elementView.setPoint(elementViewX + moveX, elementViewY + moveY)
      that.hiddenResetMindView();  
      if (elementView.mindElementData != null && elementView.mindElementData.type == MindElementType.NODE_CONNECT_LINE) {
        that.hideCircle()
      }
      that.setGuideLine(elementView.mindElementData, elementViewX + moveX, elementViewY + moveY); //设置辅助线的坐标
      let info = that.delegate.getHitMindElementDataByPoint(elementView, new Point(elementViewX + moveX, elementViewY + moveY))
      if (info != null && info.isHit()) {
        //TODO 显示辅助节点
        that.showAssistNode(info); 
      } else {
        //Hidden
        that.hideAssistNode();
      }
      that.delegate.hideAllMenu()
    };
    that.contentView.onmouseup = function () {
      if (that.isResetMindViewDrag()) {
        return;
      }
      //设置可以自由拖动的节点的位置。3235002338
      that.controllMindSize.setPosition(elementView.mindElementData, elementViewX + moveX, elementViewY + moveY);
      //如果拖动距离大于5像素就移动
      if (that.drageSeatMax > 5) {
        that.delegate.changeNodePoint(elementView, new Point(elementViewX + moveX, elementViewY + moveY))
        //如果拖动距离小于5像素就恢复原状。
      } else {
        elementView.setPoint(elementViewX, elementViewY)
      }
      that.drageSeatMax = 0;
      if (elementView.mindElementData != null && elementView.mindElementData.type == MindElementType.NODE_CONNECT_LINE) {
        that.showCircle(elementView.mindElementData)
      }
      //鼠标拖动完成后让隐藏的隐藏子节点图表展示出来
      that.setHiddenButton(elementView.mindElementData);
      that.setDataAngleButton(elementView.mindElementData);
      that.setAddFlowChartView(elementView.mindElementData);
      that.hidenGuideLine() //隐藏辅助线
      that.contentView.onmousemove = that.contentView.onmouseup = null;
      that.hideAssistNode();
    };
  }

  hiddenResetMindView() {
    if (this.resetMindView == null) {
      return
    }
    this.resetMindView.hidden()
  }
  //双击创建自由节点。
  dblClickCanvas() {
    let dblclickFreeNode = (e) => {
      // this.hideInput();
      
      let rootRect = this.root.getBoundingClientRect() 
      var rootClientX = rootRect.left
      var rootClientY = rootRect.top

      let left = this.getContentScrollX()
      let top = this.getContentScrollY()
      let eventX = (e.clientX - rootClientX) / this.scale;
      let eventY = (e.clientY - rootClientY) / this.scale;

      let contentX = left / this.scale;
      let contentY = top / this.scale;

      let clickPointX = eventX + contentX;
      let clickPointY = eventY + contentY;
      this.delegate.onDoubleClickDrawingBoard(e, new Point(clickPointX, clickPointY));
    }
    this.contentView.addEventListener('dblclick', dblclickFreeNode)
  }

  //隐藏所有的菜单。
  allHiddenMenu() {
    this.vueThis.showElementMenuList = false;   //隐藏节点菜单
    this.vueThis.showElementIconMenuList = false; //隐藏图标菜单
	  this.vueThis.showIconMenu = false; //隐藏icon弹框
    this.vueThis.showLinkMenu = false; //隐藏链接菜单
    this.vueThis.showRelevanceLineMenu = false; //隐藏关联线条菜单
    this.vueThis.showEncircleMenu = false; //隐藏包裹线菜单
    this.vueThis.abstractMenu = false; //隐藏备注菜单
    this.vueThis.showAssMapLink = false; //隐藏关联导图菜单
    this.vueThis.isShowFreeNodeMenu = false; //隐藏自由借
  }

  //计算是否点击到关联线的控制点上，返回布尔值。
  isClickPointCircle(data, clickPointX, clickPointY) {
    let ignore = 4;
    let left = data.x - ignore;
    let right = data.x + data.width + ignore;
    let top = data.y - ignore;
    let bottom = data.y + data.height + ignore;
    let circleDraw1 = clickPointX >= left && clickPointX <= right &&
      clickPointY >= top && clickPointY <= bottom
    return circleDraw1
  }
  
  //拖动关联线的控制点
  controllCircleDraw(startX, startY, leftBoundary1, topBoundary1, ele, controllData, bool, rightAngleHitPointDataIndex = -1) {
    //可以拖动
    let moveX;
    let moveY;
    this.hideAddFlowChartDataLeftView()
    this.hideDataAngleButton()
    this.contentView.onmousemove = (dargeEvent) => {
      moveX = (dargeEvent.pageX - startX) * (1 / this.scale);
      moveY = (dargeEvent.pageY - startY) * (1 / this.scale);
      ele.style.left = (leftBoundary1 + moveX) + "px";
      ele.style.top = (topBoundary1 + moveY) + "px";
      controllData.x = leftBoundary1 + moveX
      controllData.y = topBoundary1 + moveY
      if (rightAngleHitPointDataIndex > -1) {
        this.delegate.onMoveRightAngleLinePoint(new Point(controllData.x + controllData.width / 2, controllData.y + controllData.height / 2), rightAngleHitPointDataIndex);
      } else if (ele == this.controllStartCircle || ele == this.controllEndCircle) {
        this.delegate.onMoveLineControllPoint(new Point(controllData.x + controllData.width / 2, controllData.y + controllData.height / 2), bool);
      } else {
        this.delegate.onMoveLinePoint(new Point(controllData.x + controllData.width / 2, controllData.y + controllData.height / 2), bool);
      }
      this.updataRightAnglePoints(this.selectData)
      ele.style.cursor = ""
    };
    this.controllCircleDrag = true;
    this.contentViewMouseUp(ele);
  }

  //旋转角度
  changeDataAngleDraw(startX, startY, leftBoundary1, topBoundary1) {
    //可以拖动
    let moveX;
    let moveY;
    // this.mindDataChangeAngleButton.hideDom()
    this.changeDataAngleDrag = true;
    let data = this.mindDataChangeAngleButton.data
    let space = this.UiUtil.dip2px(4);
    let x = data.x + data.width + space
    let y = data.y - this.mindDataChangeAngleButton.size.height - space
    let center = this.mindDataChangeAngleButton.data.getCenterPoint();
    let defaultAngle = Util.getCircleDegreesInPoint(center, new Point(x + this.mindDataChangeAngleButton.size.width/2, y + this.mindDataChangeAngleButton.size.height/2))
    let spcae = Util.getPointSpacing(center, new Point(x + this.mindDataChangeAngleButton.size.width/2, y + this.mindDataChangeAngleButton.size.height/2))
    this.delegate.hideAddNodeIcon(-1)
    this.hideAddFlowChartDataLeftView()
    this.hiddenResetMindView()
    this.contentView.onmousemove = (dargeEvent) => {
      moveX = (dargeEvent.pageX - startX) * (1 / this.scale);
      moveY = (dargeEvent.pageY - startY) * (1 / this.scale);
      let left = leftBoundary1 + moveX + this.mindDataChangeAngleButton.size.width / 2
      let top = topBoundary1 + moveY + this.mindDataChangeAngleButton.size.height / 2
      let angle = Util.getCircleDegreesInPoint(center, new Point(left, top)) - defaultAngle
      if (angle < 0) {
        angle = 360 +  angle;
      }
      if (Math.abs(angle) < 2 || Math.abs(angle) > 358) {
        angle = 0
      } else if (Math.abs(angle) < 272 && Math.abs(angle) > 268) {
        angle = 270
      } else if (Math.abs(angle) < 182 && Math.abs(angle) > 178) {
        angle = 180
      } else if (Math.abs(angle) < 92 && Math.abs(angle) > 88) {
        angle = 90
      }
      this.delegate.changeDataAngle(angle, data.id);    
      
      let point = Util.getCirclePoint(center, angle + defaultAngle, spcae)
      this.mindDataChangeAngleButton.setPosition({ left: point.x - this.mindDataChangeAngleButton.size.width/2, top: point.y - this.mindDataChangeAngleButton.size.height/2});
      this.mindDataChangeAngleButton.updateAngleText(data.angle)
      this.delegate.hideAddNodeIcon(-1)  
    };
    this.contentView.onmouseup = () => {
      this.delegate.hideAddNodeIcon(-1)
      this.changeDataAngleDrag = false;
      this.contentView.onmousemove = this.contentView.onmouseup = null;
      this.mindDataChangeAngleButton.hideDom()
    };
  }

  contentViewMouseUp(ele) {
    this.contentView.onmouseup = () => {
      this.controllCircleDrag = false;
      this.contentView.onmousemove = this.contentView.onmouseup = null;
      if (ele == this.endCircle || ele == this.startCircle || 
          ele == this.controllEndCircle || ele == this.controllStartCircle) {
        ele.style.cursor = "all-scroll"
      } else {
        ele.style.cursor = "pointer"
      }
    };
  }

  controllBoxSize (startX, startY, clickButton) {
    //可以拖动
    let moveX;
    let moveY;
    this.hideAddFlowChartDataLeftView()
    this.hideDataAngleButton()
    this.isChangeControllBoxSize = true
    this.contentView.onmousemove = (dargeEvent) => {
      moveX = (dargeEvent.pageX - startX) * (1 / this.scale);
      moveY = (dargeEvent.pageY - startY) * (1 / this.scale);
      this.controllMindSize.changeBoxSize(moveX, moveY, clickButton);
    };

    this.contentView.onmouseup = () => {
      this.controllMindSize.changeBoxSizeEnd();
      this.isChangeControllBoxSize = false
      setTimeout(() => {
        this.hideInput();
      }, 100);
      this.contentView.onmousemove = this.contentView.onmouseup = null;
    };
  }

  //拖动画布触发的函数。
  dragContentView(e, clickPointX, clickPointY, isRightKey = false) {
    if (e == null) {
      return;
    }
    let pagex = e.pageX
    let pagey = e.pageY
    var disx = pagex - this.contentView.offsetLeft + this.getContentScrollX();
    var disy = pagey - this.contentView.offsetTop + this.getContentScrollY();
    this.timeOutEvent = 0;//定时器 
    this.gtouchstart(e)
    let isMouseMove = false;

    if (this.contentViewSvg.style.cursor != 'grab') {
      this.contentViewSvg.style.cursor = 'grab';
    }
    this.hideAddFlowChartDataLeftView()
    this.hideDataAngleButton()
    this.contentView.onmousemove = (e) => {
      //是否拖动了选中区域。
      if (!isRightKey && this.checkBoxView.isCheckBox && !this.delegate.isTemplatePage && !this.delegate.isSharePage) {
        this.checkBoxView.computedNowPoint(e, pagex, pagey, clickPointX, clickPointY)
        if (this.contentViewSvg.style.cursor != '') {
          this.contentViewSvg.style.cursor = '';
        }
      } else {        
        let left = e.pageX - disx;
        let top = e.pageY - disy; 
        if (this.contentViewSvg.style.cursor != 'grabbing') {
          this.contentViewSvg.style.cursor = 'grabbing';
        }
        if (!isMouseMove && (Math.abs(e.pageX - pagex) > 4 || Math.abs(e.pageY - pagey) > 4)) {
          isMouseMove = true
        }
        this.scrollTo(-left, -top);
        this.gtouchend()
      }
    };
    this.contentView.onmouseup = (e) => {
      // console.log(e);
      this.isDragCanvas = false;
      this.gtouchend()
      this.onClickBackgroundImageBt(e)
      if (!isMouseMove) {
        this.delegate.onClickInDrawingBoard(e, new Point(clickPointX, clickPointY));  //用于判断点击到画布上的什么东西了。
      }
      this.checkBoxView.isCheckBox = false
      this.contentView.onmousemove = this.contentView.onmouseup = document.onmouseup = null;
      if (this.checkBoxView.checkBoxParent != undefined) {
        if (this.checkBoxView.checkBoxData != null) {
          this.delegate.setSelectedNodeByRect(new CGRect(this.checkBoxView.checkBoxData.x, this.checkBoxView.checkBoxData.y, this.checkBoxView.checkBoxData.width, this.checkBoxView.checkBoxData.height))
        }
        this.checkBoxView.checkBoxParent.remove();
        // this.checkBoxView.
        this.checkBoxView.checkBoxData = null
      }
      this.contentMouseupForRightKey(e);
      if (this.contentViewSvg.style.cursor != '') {
        this.contentViewSvg.style.cursor = '';
      }
    };

    document.onmouseup = (e) => {
      // console.log(e);
      this.gtouchend()
      this.onClickBackgroundImageBt(e)
      this.delegate.onClickInDrawingBoard(e, new Point(clickPointX, clickPointY));  //用于判断点击到画布上的什么东西了。
      this.checkBoxView.isCheckBox = false
      this.contentView.onmousemove = this.contentView.onmouseup = document.onmouseup = null;
      if (this.checkBoxView.checkBoxParent != undefined) {
        if (this.checkBoxView.checkBoxData != null) {
          this.delegate.setSelectedNodeByRect(new CGRect(this.checkBoxView.checkBoxData.x, this.checkBoxView.checkBoxData.y, this.checkBoxView.checkBoxData.width, this.checkBoxView.checkBoxData.height))
        }
        this.checkBoxView.checkBoxParent.remove();
        // this.checkBoxView.
        this.checkBoxView.checkBoxData = null
      }


    };
  }

  gtouchstart(e) {
    this.timeOutEvent = setTimeout(() => {
      this.longPress(e)
    }, 150);
    return false;
  }
  //在500毫秒内就释放，取消长按事件
  gtouchend() {
    if (this.timeOutEvent > -1) {
      clearTimeout(this.timeOutEvent);//清除定时器  
    }
    if (this.timeOutEvent != 0) {
      this.checkBoxView.isCheckBox = false
    }
    return false;
  };
  //有移动，取消所有事件
  gtouchmove() {
    clearTimeout(this.timeOutEvent);//清除定时器  
    this.timeOutEvent = 0;
    this.checkBoxView.isCheckBox = false
  };

  //长按事件
  longPress(e) {
    this.timeOutEvent = 0;
    if (!this.delegate.downControlKey) {//没有按下ctrl键
      this.checkBoxView.isCheckBox = true
      if (e != null && e.button == 0) {
        this.contentViewSvg.style.cursor = '';
      }
    }
    
    this.checkBoxView.initCheckBox()
  }

  //拖动画布触发的函数。
  dragEncircleBox(pagex, pagey) {
    // var disx = pagex - this.contentView.offsetLeft;
    // var disy = pagey - this.contentView.offsetTop;
    this.contentView.onmousemove = (e) => {
      this.hideAddFlowChartDataLeftView()
      this.hideDataAngleButton()
      this.resetMindView.computedNowPoint(e, pagex, pagey);
    };
    this.contentView.onmouseup = (e) => {
      this.resetMindView.DragFlag = false;
      this.resetMindView.saveData();
      let nowData = this.resetMindView.getNowData()
      let obj = new CGRect(nowData.x, nowData.y, nowData.width, nowData.height);
      // this.resizeBaoweiNode(rect);
      this.delegate.setResizeNode(obj);
      this.contentView.onmousemove = this.contentView.onmouseup = null;
    };
  }

  isResetMindViewDrag() {
    if (this.resetMindView == null || !this.resetMindView.isShow()) {
      return false
    }
    return this.resetMindView.DragFlag;
  }

  scalImage(pagex, pagey) {
    // var disx = pagex - this.contentView.offsetLeft;
    // var disy = pagey - this.contentView.offsetTop;
    this.hideAddFlowChartDataLeftView()
    this.hideDataAngleButton()
    this.contentView.onmousemove = (e) => {
      this.resetMindView.computedNowPoint(e, pagex, pagey);
    };
    this.contentView.onmouseup = (e) => {
      // this.resetMindView.DragFlag = false;
      this.resetMindView.DragImageFlag = false;

      this.resetMindView.saveData();
      let nowData = this.resetMindView.getNowData()
      // let obj = new CGRect(nowData.x, nowData.y, nowData.width, nowData.height);
      this.delegate.getImgSize(nowData.width, nowData.height);
      // this.delegate.setResizeNode(obj);
      this.contentView.onmousemove = this.contentView.onmouseup = null;
    };
  }

  //计算画布的位置。还有画布的拖动边界。
  boundaryHandler() {
    let translationX = this.getContentScrollX()
    let translationY = this.getContentScrollY()
    let mapWidth = this.getViewPxValue(this.contentView.offsetWidth);
    let mapHeight = this.getViewPxValue(this.contentView.offsetHeight);
    let parentWidth = this.UiUtil.getScreenWidth();
    let parentHeight = this.UiUtil.getScreenHeight();
    
    if (translationX < 0) {
      this.root.scrollLeft = 0
    }
    if ((translationX + parentWidth) / this.scale > mapWidth) {
      this.root.scrollLeft = mapWidth * this.scale - parentWidth
    }
    if (translationY < 0) {
      this.root.scrollTop = 0
    }

    if ((translationY + parentHeight) / this.scale > mapHeight) {
      this.root.scrollTop = mapHeight * this.scale - parentHeight
    }
  }

  //将带px的字符串转换为数字
  getViewPxValue(value) {
    if (typeof value == "string" && value.indexOf("px") > -1) {
      value = value.replace("px", "");
      return parseInt(value);
    } else if (typeof value == "number") {
      return value;
    }
    return -1;
  }

  //按住ctrl加鼠标滚动改变画布大小（并且禁止浏览器默认的ctrl加鼠标滚动还有右击出现浏览器菜单）
  scroll() {
    //禁止浏览器默认的ctrl滚动鼠标放大缩小网页。改为放大缩小画布。
    let that = this;
    var scrollFunc = function (e) {
      e = e || window.event;
      let scale = that.scale;
      if (
        (e.wheelDelta == "120" || e.wheelDelta == "-120") &&
        e.ctrlKey == true
      ) {
        //IE/Opera/Chrome
        e.returnValue = false;
        if (e.wheelDelta == "120") {
          scale = that.scale + 0.1
        } else if (e.wheelDelta == "-120") {
          scale = that.scale - 0.1
        }
      } else if (e.detail && e.ctrlKey == true) {
        //Firefox
        e.returnValue = false;
        if (e.detail == "3") {
          scale = that.scale + 0.1
        } else if (e.detail == "-3") {
          scale = that.scale - 0.1
        }
      }
      if (scale > this.maxScale)
        scale = this.maxScale;
      if (scale < this.minScale)
        scale = this.minScale;
      that.setScale(scale)
    };
    /*注册事件*/
    if (document.addEventListener) {
      document.addEventListener("DOMMouseScroll", scrollFunc, false);
    }
    document.addEventListener("mousewheel", scrollFunc, { passive: false });
    //阻止浏览器右击弹出菜单
    function Click() {
      window.event.returnValue = false;
    }
    document.oncontextmenu = Click;
  }

  setScroll(val) {    //设置放大缩小的倍数
    this.scale = val
  }

  //创建输入框
  createInput() {
    // this.inputView = document.createElement("div")
    if (document.getElementById("textArea") == null) {
      this.inputView = document.createElement("div")
      this.inputView.setAttribute('id', 'textArea');
      this.inputView.style.position = "fixed"//"absolute"
      this.inputView.style.zIndex = 31
      this.setShowInput()
      this.inputView.style.fontWeight = Config.textNormalFontWeight
      this.inputView.addEventListener("mousedown", (ev) => {
        //阻止事件冒泡，造成点击输入框隐藏
        window.event ? window.event.cancelBubble = true : ev.stopPropagation();
      }, false);

      this.contentView.appendChild(this.inputView)
      this.inputView.focus()

      let that = this;
      let mouseUpss = function(e){
        if(that.selectData.generalizationContent != null || that.selectData.lineContent != null){
          return;
        }
        //阻止事件冒泡，造成点击输入框隐藏
        window.event ? window.event.cancelBubble = true : ev.stopPropagation();
        that.cancelConstituency();
        that.setStyleList();
        that.getSelectedSubscriptAssignment();
        that.monitorIndex +=1;
        let divHeight = that.getViewPxValue(that.selectData.height);
        // let screenRect = this.getScreenRect();
        let dataPoint = that.getEventAndDataPointByRoot(e, that.selectData)
        const pos = {x:dataPoint.x, 
                      y:dataPoint.y, 
                      inputHeight:divHeight, screenRect:that.getScreenRect(), scale:that.scale};

        if (that.startSubscript < 0 || that.endSubscript < 0 ||
          that.startSubscript > that.endSubscript || that.startSubscript == that.endSubscript) {
              that.delegate.isShowFontStyleMenu({x:null,y:null},false);
        }else{
          if (that.delegate.isTemplatePage == false && that.delegate.isSharePage == false &&
             that.typeNode!="setSubscript") { 
            that.delegate.isShowFontStyleMenu(pos,true);
          }
        }
      }

      // 图标悬浮事件
      let ShowfontStyleMenuTimeDiv = null;
      let fontStyleSuspension = function (ev){
        //阻止事件冒泡，造成点击输入框隐藏
        window.event ? window.event.cancelBubble = true : ev.stopPropagation();
        if(that.selectData.generalizationContent != null || that.selectData.lineContent != null){
          return;
        }

          that.getSelectedSubscriptAssignment();
          let divHeight = that.getViewPxValue(that.selectData.height);
          let dataPoint = that.getEventAndDataPointByRoot(ev, that.selectData)
          const pos = {x:dataPoint.x,
                        y:dataPoint.y,
                        inputHeight:divHeight,screenRect:that.getScreenRect(),scale:that.scale};
          if (that.startSubscript < 0 || that.endSubscript < 0 ||
            that.startSubscript > that.endSubscript || that.startSubscript == that.endSubscript ) {
              // that.delegate.showFontStyleMenu = false; 
          } else {
            if(that.delegate.isTemplatePage == false && that.delegate.isSharePage == false &&
              that.typeNode!="setSubscript") {
              clearTimeout(that.delegate.showFontStyleMenuTime);
              ShowfontStyleMenuTimeDiv = setTimeout(() => { 
                that.delegate.isShowFontStyleMenu(pos,true);
              }, 200);
            }
          }

          if(that.inputBoxIndex == 0){
            if(that.delegate.isTemplatePage == false && that.delegate.isSharePage == false &&
              that.typeNode!="setSubscript"){
              that.delegate.isShowFontStyleMenu(pos,true);
            }
            if (that.delegate.showFontStyleMenuTime != null && that.delegate.showFontStyleMenuTime > -1) {
              clearTimeout(that.delegate.showFontStyleMenuTime);
            }
            
            that.delegate.showFontStyleMenuTime = setTimeout(() => {
                that.delegate.showFontStyleMenu = false;        
              }, 10000);   
              that.inputBoxIndex++;
            }
      }
      // 图标移出事件
      let fontStyleRemove =(ev)=>{
        //阻止事件冒泡
        window.event ? window.event.cancelBubble = true : ev.stopPropagation();
          clearTimeout(ShowfontStyleMenuTimeDiv);
          clearTimeout(that.delegate.showFontStyleMenuTime);
          that.delegate.showFontStyleMenuTime = setTimeout(() => {
            that.delegate.showFontStyleMenu = false;         
          }, 5000);                   
      }
      
      this.inputView.addEventListener("mouseup", mouseUpss, false);
      this.inputView.addEventListener('mouseenter', fontStyleSuspension, false);
      this.inputView.addEventListener('mouseleave', fontStyleRemove, false);
      // DomSize.bind(this.inputView, function(){
      //   that.checkInputPoint()
      // })

      let clickKeyCode = function(e) {
        if (that.isShowInput()){
          var keyNum = window.event ? e.keyCode : e.which; //获取被按下的键值
          if (keyNum == 13 || keyNum == 32 ){  //enter、空格或者tab
            that.checkInputPoint()
          }
        }
       
      }

      this.inputView.addEventListener("keydown", clickKeyCode, false);

    }
  }

  getEventPointByRoot(event) {
    if (event == null) {
      return {x: this.getContentScrollX(), y: this.getContentScrollY()}
    } else {
      return {x: this.getContentScrollX() + event.x, y: this.getContentScrollY() + event.y}
    }
  }

  getEventAndDataPointByRoot(event, data, isTop = true) {
    if (data == null || event == null) {
      return {x: this.getContentScrollX(), y: this.getContentScrollY()}
    } else if (data == null || data.x == null || data.y == null) {
      return {x: this.getContentScrollX() + event.x, y: this.getContentScrollY() + event.y}
    } else {
      let dataRootY = (data.y * this.getScale())
      if (isTop) {
        return {x: this.getContentScrollX() + event.x, y: dataRootY}
      } else {
        return {x: this.getContentScrollX() + event.x, y: this.getContentScrollY() + event.y}
      }
    }
  }

  removeInput() {
    if (this.inputView == null ||
        this.inputView.parentNode == null) {
      return
    }
    // DomSize.remove(this.inputView)
    this.inputView.parentNode.removeChild(this.inputView);
  }
  //获取颜色以#开头的rbg
  HexToRgb (str) {
    //replace替换查找的到的字符串
    if (str == null || str == "") {
      str = "#ffffff"
    }
    str = str.replace("#", "");
    if (str.length == 0) {
      str = "ffffff"
    } else if (str.length == 1) {
      str = "00000" + str
    } else if (str.length == 2) {
      str = "0000" + str
    } else if (str.length == 3) {
      str = "000" + str
    } else if (str.length == 4) {
      str = "00" + str
    } else if (str.length == 5) {
      str = "0" + str
    }
    //match得到查询数组
    var hxs = str.match(/../g);
    //alert('bf:'+hxs)
    var i = 0
    if (hxs.length == 2) {
      i = 1;
      hxs[0] = '00'
    } else if (hxs.length == 1) {
      i = 2;
      hxs[0] = '00'
      hxs[1] = '00'
    }
    for (; i < 3; i++) hxs[i] = parseInt(hxs[i], 16);
    //alert(parseInt(80, 16))
    return hxs;
  }


  //去除选区
  cancelConstituency(){
    var coords = document.getElementById("textArea");
    let obj = {innerTextObj:coords.innerText,innerHTMLObj:coords.innerHTML,childrenObj:coords.childNodes};  
    let spanCount =obj.childrenObj.length;
    let start = 0;
    let end = 0;

    if (this.startSubscript < 0 || this.endSubscript < 0 ||
      this.startSubscript > this.endSubscript || this.startSubscript == this.endSubscript ||
      (this.startSubscript == 0 && this.endSubscript >= this.inputView.innerText.length)) {
        return;
      }
    let indexColor = 0;
    for (let index = 0; index < spanCount; index++) {
      
      let elementSpan = document.getElementById('s'+index);
      if(elementSpan == null){
        return;
      }
      let elementCount = elementSpan.innerText.length;
      end += elementCount
      if(this.startSubscript <= start && this.endSubscript >=end){
        if (elementSpan.style.backgroundColor == "rgb(253,73,43)") {
          elementSpan.style.backgroundColor = "";
        }
        if(this.spanSelectColor.length > 0){
          elementSpan.style.color = this.spanSelectColor[indexColor];
        }else{
          // elementSpan.style.color = '';
          let textHtml = Util.getContentText(null,coords.innerText,this.styleList,this.selectData.textContent.textFontSize, this.isEditCardTitle);
          this.inputView.innerHTML = textHtml;
        }
        indexColor ++;
      }
      start += elementCount;
      
    }
    this.startSubscript = -1;
    this.endSubscript = -1;
    this.spanSelectColor = [];
        
  }
   //添加选区
  addConstituency( starta,enda){
    if(starta != undefined && enda != undefined){
      this.startSubscript = starta;
      this.endSubscript = enda;
    }
    this.spanSelectColor = [];
    var coords = document.getElementById("textArea");
    // let obj = {innerTextObj:coords.innerText,innerHTMLObj:coords.innerHTML,childrenObj:coords.childNodes};  
    let spanCount = coords.childNodes.length;

    if (this.startSubscript < 0 || this.endSubscript < 0 ||
      this.startSubscript > this.endSubscript || this.startSubscript == this.endSubscript ||
      (this.startSubscript == 0 && this.endSubscript >= this.inputView.innerText.length)) {
        return;
      }
    let start = 0;
    let end = 0;
    for (let index = 0; index < spanCount; index++) {
      let elementSpan = document.getElementById('s'+index);
      if(elementSpan == null){
        return
      }
      let elementCount = elementSpan.innerText.length;
      end += elementCount
      if(this.startSubscript <= start && this.endSubscript >=end) {
        if ((elementSpan.style.backgroundColor == null ||
          elementSpan.style.backgroundColor == "")) {
            elementSpan.style.backgroundColor = "rgb(253,73,43)";
        } else {

        }
        this.spanSelectColor.push(elementSpan.style.color);
        if (elementSpan.style.backgroundColor == null ||
          elementSpan.style.backgroundColor == "") {
            elementSpan.style.color = 'white';
        }
      }
      start += elementCount;
    }
    let startEndSub = {startSub:this.startSubscript,endSub:this.endSubscript}
    this.spanSelectColor.push(startEndSub);
  }
    //点击字体不同样式，修改样式大小颜色
  updateStyle(val){
    let styleName = val.styleName
    // let styleName = 'fontSizePlus';
    let text = this.inputView.innerText;
    if (this.selectData == null) {
      return
    }
    let textContent = this.isEditCardTitle && this.selectData.titleContent != null ? this.selectData.titleContent : this.selectData.textContent
    if (textContent == null) {
      textContent = this.selectData.generalizationContent
    }
    if (textContent == null) {
      return;
    }
    let fontsize = textContent.textFontSize;
    this.textFontSize = fontsize;
    let span = document.getElementById(this.firstSpanId);
    // this.styleList = cellStyles;
    let selectStartIndex = this.getSelectionStart();
    let selectEndIndex = this.getSelectionEnd();
    if(styleName != '') {
      if(styleName == "fontSizePlus") {
        if (this.startSubscript < 0 || this.endSubscript < 0 ||
          this.startSubscript > this.endSubscript || this.startSubscript == this.endSubscript ||
          (this.startSubscript == 0 && this.endSubscript >= this.inputView.innerText.length)) {
            let fontSizeD = textContent.textFontSize
            let fontSizeDiv = this.getViewPxValue(fontSizeD) + 1;
            this.delegate.editMindmapContent.changeTextSize(fontSizeDiv, this.isEditCardTitle);
            this.setTextContentInputStyle(this.selectData, 'textContent', true); 
          }else{
            let fontSizeD = textContent.textFontSize;
            let fontSizeDiv = this.getViewPxValue(fontSizeD);
            if(span == undefined || span ==null ||
              span.style == undefined || span.style == null || span.style == ""
              || span.style.fontSize == undefined || span.style.fontSize == null
              || span.style.fontSize == ""){
                this.textFontSize = fontSizeDiv
            }else{
              let spanSize = this.getViewPxValue(span.style.fontSize)
              this.textFontSize = spanSize
            }

            this.setTextFontSizeUp(this.startSubscript,this.endSubscript)
            let textHtml = Util.getContentText(null,text,this.styleList,fontsize);
            this.inputView.innerHTML = textHtml;
            this.setInputSelectedIndex(selectStartIndex, selectEndIndex);
            this.addConstituency();
          }

      }else if(styleName == "fontSizeReduce"){
        if (this.startSubscript < 0 || this.endSubscript < 0 ||
          this.startSubscript > this.endSubscript || this.startSubscript == this.endSubscript ||
          (this.startSubscript == 0 && this.endSubscript >= this.inputView.innerText.length)) {
            let fontSizeD = textContent.textFontSize
            let fontSizeDiv = this.getViewPxValue(fontSizeD) - 1;
            this.delegate.editMindmapContent.changeTextSize(fontSizeDiv, this.isEditCardTitle);
            this.setTextContentInputStyle(this.selectData, 'textContent', true); 
          }else{
            let fontSizeD = textContent.textFontSize;
            let fontSizeDiv = this.getViewPxValue(fontSizeD);
            if(span == undefined || span ==null ||
              span.style == undefined || span.style == null || span.style == ""
              || span.style.fontSize == undefined || span.style.fontSize == null
              || span.style.fontSize == ""){
                this.textFontSize = fontSizeDiv
            }else{
              let spanSize = this.getViewPxValue(span.style.fontSize)
              this.textFontSize = spanSize
            }
            this.setTextFontSizeDown(this.startSubscript,this.endSubscript)
            let textHtml = Util.getContentText(null,text,this.styleList,fontsize);
            this.inputView.innerHTML = textHtml;
            this.setInputSelectedIndex(selectStartIndex, selectEndIndex);
            this.addConstituency();
          }

      } else if(styleName == "fontColor"){
        let cell = new TextStyleCell();
        for (let i = 0; i < this.styleList.length; i++) {
          if (this.styleList[i].startIndex == this.startSubscript &&
            this.styleList[i].endIndex == this.endSubscript) {
              cell = this.styleList[i]
              break;
          }
        }
        cell.startIndex = this.startSubscript;
        cell.endIndex = this.endSubscript;
        let colorNew = 0;
        if (val.textBgColor == 1) {
          cell.color = 1
          let textHtml = Util.getContentText(null,text,this.styleList,fontsize);
          this.inputView.innerHTML = textHtml;
          this.setInputSelectedIndex(selectStartIndex, selectEndIndex);
          this.addConstituency();
        } else {
          if ((typeof val.textColor) == "string") {
            if(val.textColor.indexOf("#") == -1){
              let colorRbgNew = Colors.getUiColor(val.textColor);
              let colorRgb = (colorRbgNew.replace("rgb(","")).replace(")","");
              let colorRgbs = colorRgb.split(",");
              let r = parseInt(colorRgbs[0]);
              let g = parseInt(colorRgbs[1]);
              let b = parseInt(colorRgbs[2]);
              colorNew = Colors.getColorByRgb(r,g,b);
            } else {
              let spanTextColor = this.HexToRgb(val.textColor);
              let r = parseInt(spanTextColor[0]);
              let g = parseInt(spanTextColor[1]);
              let b = parseInt(spanTextColor[2]);
              colorNew = Colors.getColorByRgb(r,g,b);
            }
          } else {
            colorNew = val.textColor
          }
          
          if (this.startSubscript < 0 || this.endSubscript < 0 ||
            this.startSubscript > this.endSubscript || this.startSubscript == this.endSubscript ||
            (this.startSubscript == 0 && this.endSubscript >= this.inputView.innerText.length)) {
              this.delegate.editMindmapContent.changeTextColor(colorNew, this.isEditCardTitle);
              this.setTextContentInputStyle(this.selectData, 'textContent', true); 
            }else{
              cell.textColor = colorNew
              this.styleList.push(cell);
  
              let styleCellList = this.styleList;
              if (styleCellList.length > 0) {
                
                let textHtml = Util.getContentText(null,text,styleCellList,fontsize);
                this.inputView.innerHTML = textHtml;
                this.setInputSelectedIndex(selectStartIndex, selectEndIndex);
                this.addConstituency();
              }
            }
        }
      } else if(styleName == "fontBgColor") {
        let cell = new TextStyleCell();
        let existIndex = -1
        for (let i = 0; i < this.styleList.length; i++) {
          if (this.styleList[i].startIndex == this.startSubscript &&
            this.styleList[i].endIndex == this.endSubscript) {
              cell = this.styleList[i]
              existIndex = i;
              break;
          }
        }
        cell.startIndex = this.startSubscript;
        cell.endIndex = this.endSubscript;
        let colorNew = 0;
        if (val.textBgColor == 1) {
          if (existIndex == -1) {
            this.delegate.editMindmapContent.changeTextBgColor(val.textBgColor, this.isEditCardTitle)
            let textHtml = Util.getContentText(null,text,this.styleList,fontsize);
            this.inputView.innerHTML = textHtml;
            this.setInputSelectedIndex(selectStartIndex, selectEndIndex);
            this.addConstituency();
            return
          } else {
            cell.bgColor = -10000
            let textHtml = Util.getContentText(null,text,this.styleList,fontsize);
            this.inputView.innerHTML = textHtml;
            this.setInputSelectedIndex(selectStartIndex, selectEndIndex);
            this.addConstituency();
          }
        } else {
          if(val.textBgColor.indexOf("#") == -1){
            let colorRbgNew = Colors.getUiColor(val.textBgColor);
            let colorRgb = ""
          if (colorRbgNew.indexOf("rgba(") != -1) {
              colorRgb = (colorRbgNew.replace("rgba(","")).replace(")","");
            } else if (colorRbgNew.indexOf("rgba(") != -1){
              colorRgb = (colorRbgNew.replace("rgb(","")).replace(")","");
            } else {
              colorRgb = (colorRbgNew.replace("rgb(","")).replace(")","");
            }
            let colorRgbs = colorRgb.split(",");
            let r = parseInt(colorRgbs[0]);
            let g = parseInt(colorRgbs[1]);
            let b = parseInt(colorRgbs[2]);
            colorNew = Colors.getColorByRgb(r,g,b);
          }else{
            let spanTextColor = this.HexToRgb(val.textBgColor);
            let r = parseInt(spanTextColor[0]);
            let g = parseInt(spanTextColor[1]);
            let b = parseInt(spanTextColor[2]);
            colorNew = Colors.getColorByRgb(r,g,b);
          }
  
          if (this.startSubscript < 0 || this.endSubscript < 0 ||
            this.startSubscript > this.endSubscript || this.startSubscript == this.endSubscript ||
            (this.startSubscript == 0 && this.endSubscript >= this.inputView.innerText.length)) {
              this.delegate.editMindmapContent.changeTextBgColor(colorNew, this.isEditCardTitle);
              this.setTextContentInputStyle(this.selectData, 'textContent', true); 
            }else{
              cell.bgColor = colorNew
              this.styleList.push(cell);
  
              let styleCellList = this.styleList;
              if(styleCellList.length > 0){
                
                let textHtml = Util.getContentText(null,text,styleCellList,fontsize);
                this.inputView.innerHTML = textHtml;
                this.setInputSelectedIndex(selectStartIndex, selectEndIndex);
                this.addConstituency();
              }
            }
        }
      } else if(styleName == "textShadowColor") {
        let cell = new TextStyleCell();
        let existIndex = -1
        for (let i = 0; i < this.styleList.length; i++) {
          if (this.styleList[i].startIndex == this.startSubscript &&
            this.styleList[i].endIndex == this.endSubscript) {
              cell = this.styleList[i]
              existIndex = i;
              break;
          }
        }
        cell.startIndex = this.startSubscript;
        cell.endIndex = this.endSubscript;
        let colorNew = 0;
        if (val.textShadowColor == 1) {
          if (existIndex == -1) {
            this.delegate.editMindmapContent.changeTextShadowColor(val.textShadowColor, this.isEditCardTitle)
            let textHtml = Util.getContentText(null,text,this.styleList,fontsize);
            this.inputView.innerHTML = textHtml;
            this.setInputSelectedIndex(selectStartIndex, selectEndIndex);
            this.addConstituency();
            return
          } else {
            cell.bgColor = -10000
            let textHtml = Util.getContentText(null,text,this.styleList,fontsize);
            this.inputView.innerHTML = textHtml;
            this.setInputSelectedIndex(selectStartIndex, selectEndIndex);
            this.addConstituency();
          }
        } else {
          if(val.textShadowColor.indexOf("#") == -1){
            let colorRbgNew = Colors.getUiColor(val.textShadowColor);
            let colorRgb = ""
          if (colorRbgNew.indexOf("rgba(") != -1) {
              colorRgb = (colorRbgNew.replace("rgba(","")).replace(")","");
            } else if (colorRbgNew.indexOf("rgba(") != -1){
              colorRgb = (colorRbgNew.replace("rgb(","")).replace(")","");
            } else {
              colorRgb = (colorRbgNew.replace("rgb(","")).replace(")","");
            }
            let colorRgbs = colorRgb.split(",");
            let r = parseInt(colorRgbs[0]);
            let g = parseInt(colorRgbs[1]);
            let b = parseInt(colorRgbs[2]);
            colorNew = Colors.getColorByRgb(r,g,b);
          }else{
            let spanTextColor = this.HexToRgb(val.textShadowColor);
            let r = parseInt(spanTextColor[0]);
            let g = parseInt(spanTextColor[1]);
            let b = parseInt(spanTextColor[2]);
            colorNew = Colors.getColorByRgb(r,g,b);
          }
  
          if (this.startSubscript < 0 || this.endSubscript < 0 ||
            this.startSubscript > this.endSubscript || this.startSubscript == this.endSubscript ||
            (this.startSubscript == 0 && this.endSubscript >= this.inputView.innerText.length)) {
              this.delegate.editMindmapContent.changeTextShadowColor(colorNew, this.isEditCardTitle);
              this.setTextContentInputStyle(this.selectData, 'textContent', true); 
            }else{
              cell.textShadow = colorNew
              this.styleList.push(cell);
  
              let styleCellList = this.styleList;
              if(styleCellList.length > 0){
                
                let textHtml = Util.getContentText(null,text,styleCellList,fontsize);
                this.inputView.innerHTML = textHtml;
                this.setInputSelectedIndex(selectStartIndex, selectEndIndex);
                this.addConstituency();
              }
            }
        }
      } else if(styleName == "fontWeight"){
        if (this.startSubscript < 0 || this.endSubscript < 0 ||
          this.startSubscript > this.endSubscript || this.startSubscript == this.endSubscript ||
          (this.startSubscript == 0 && this.endSubscript >= this.inputView.innerText.length)) {
            this.delegate.editMindmapContent.changeTextStyle(0, this.isEditCardTitle);  
            this.setTextContentInputStyle(this.selectData, 'textContent', true);   
          }else{
            let cell = new TextStyleCell();
            cell.startIndex = this.startSubscript;
            cell.endIndex = this.endSubscript;
            let isBold = this.getJudgeStyle("bold");
            cell.setTextBold(isBold);
            this.styleList.push(cell);
            let textHtml = Util.getContentText(null,text,this.styleList,fontsize);
            this.inputView.innerHTML = textHtml;
            this.setInputSelectedIndex(selectStartIndex, selectEndIndex);
            this.addConstituency();  
          }
      }else if(styleName == "fontItalic"){
        if (this.startSubscript < 0 || this.endSubscript < 0 ||
          this.startSubscript > this.endSubscript || this.startSubscript == this.endSubscript ||
          (this.startSubscript == 0 && this.endSubscript >= this.inputView.innerText.length)) {
            this.delegate.editMindmapContent.changeTextStyle(1, this.isEditCardTitle);
            this.setTextContentInputStyle(this.selectData, 'textContent', true);  
          }else{
            let cell = new TextStyleCell();
            cell.startIndex = this.startSubscript;
            cell.endIndex = this.endSubscript;

            let isItalic = this.getJudgeStyle("italic");
            cell.setTextItalics(isItalic);
            this.styleList.push(cell);

            let textHtml = Util.getContentText(null,text,this.styleList,fontsize);
            this.inputView.innerHTML = textHtml; 
            this.setInputSelectedIndex(selectStartIndex, selectEndIndex);
            this.addConstituency();  
          }
      }else if(styleName == "fontUnderline"){
        if (this.startSubscript < 0 || this.endSubscript < 0 ||
          this.startSubscript > this.endSubscript || this.startSubscript == this.endSubscript ||
          (this.startSubscript == 0 && this.endSubscript >= this.inputView.innerText.length)) {
            this.delegate.editMindmapContent.changeTextStyle(2, this.isEditCardTitle);
            this.setTextContentInputStyle(this.selectData, 'textContent', true); 
          }else{
            let cell = new TextStyleCell();
            cell.startIndex = this.startSubscript;
            cell.endIndex = this.endSubscript;

            let isStrikethrough = this.getJudgeStyle("line-through");
            
            cell.setTextStrikethrough(isStrikethrough);
            this.styleList.push(cell);

            let textHtml = Util.getContentText(null,text,this.styleList,fontsize);
            this.inputView.innerHTML = textHtml;
            this.setInputSelectedIndex(selectStartIndex, selectEndIndex);
            this.addConstituency();  
          }
      }else if(styleName == "clearStyle"){
        this.styleList == new Array;
        this.delegate.editMindmapContent.clearTextStyle(this.delegate.lastDblclickMindData.id, this.isEditCardTitle);
        let textHtml = Util.getContentText(null,text,null,fontsize);;
        this.inputView.innerHTML = textHtml;
      }
    }
  }
  //判断当前字体是不是粗体斜体分割线
  getJudgeStyle(val){
    var coords = document.getElementById("textArea");
    let obj = {innerTextObj:coords.innerText,innerHTMLObj:coords.innerHTML,childrenObj:coords.childNodes};  
    let spanCount =obj.childrenObj.length;

    let isNew = false;
    if(this.localNameLabel == "div" || this.localNameLabel == ''){
      isNew = true;
    }
    let start = 0;
    let end = 0;
    for (let index = 0; index < spanCount; index++) {
        let elementSpan = document.getElementById('s'+index);
        if(elementSpan == null){
          return 
        }
        let elementCount = elementSpan.innerText.length;
        end += elementCount
        
        if(this.startSubscript <= start && this.endSubscript >=end){
          if(val == "bold"){
            if(elementSpan.style.fontWeight == "bold"){
            
            }else{
              isNew = true;
            }
          }else if(val == "italic"){
            if(elementSpan.style.fontStyle == "italic"){
              
            }else{
              isNew = true;
            }
          }else if(val == "line-through"){
            if(elementSpan.style.textDecoration == "line-through"){
              
            }else{
              isNew = true;
            }
          }
        }
        start += elementCount;
      }
      return isNew;
  }
  setInputSelectedIndex(start, end) {
    if (!this.isShowInput() || start < 0 || end < 0) { //if (!this.isShowInput() || start == end || start < 0 || end < 0) {
      return;
    }
    if(this.inputView.setSelectionRange) {
      this.inputView.setSelectionRange(start,end);	
    } else if(this.inputView.createTextRange){
      var rang = this.inputView.createTextRange();
      rang.collapse(true);
      rang.moveStart('character',start);
      rang.moveEnd('character',end-start);
      rang.select();	
    }
    // this.inputView.focus();
  }
  //字体加
  setTextFontSizeUp(start, end) {
      if (start < 0 || end < 0 ||
              start > end || start == end ||
              (start == 0 && end >= this.inputView.innerText.length)) {
          if (parseInt( this.textFontSize) < 100) {
            this.textFontSize = parseInt( this.textFontSize) + 1;
          }

          if (start == 0 && end >= this.inputView.innerText.length) {
              let cell = new TextStyleCell()
              for (let index = 0 ; index<this.styleList.length ; index++) {
                cell = this.styleList[idnex];
                  if (cell.isSetFontSize()) {
                      if (cell.textFontSize < 100) {
                          cell.textFontSize += 1;
                      }
                  } else {
                      cell.textFontSize = parseInt( this.textFontSize) + 1;
                  }
              }
          }
      } else {
          let isNew = true;
          let allContainCells = new Array();
          allContainCells = this.getAllContainTextStyleCells(start, end);
          let cell = new TextStyleCell()
          for (let index = 0 ; index<allContainCells.length ; index++) {
            cell = allContainCells[index]
            if (cell.isSetFontSize()) {
                if (cell.textFontSize < 100) {
                    cell.textFontSize += 1;
                }
            } else {
                cell.textFontSize = this.textFontSize + 1;
            }
            if (cell.startIndex == start && cell.endIndex == end) {
                isNew = false;
            }
          }
          if (isNew) {
              let cell = new TextStyleCell()
              cell.startIndex = start;
              cell.endIndex = end;
              if (cell.isSetFontSize()) {
                  if (cell.textFontSize < 100) {
                      cell.textFontSize += 1;
                  }
              } else {
                  cell.textFontSize = parseInt( this.textFontSize) + 1;
              }
              this.styleList.push(cell);
          }
      }
  }
  //字体减
  setTextFontSizeDown(start, end) {
    if (start < 0 || end < 0 ||
      start > end || start == end ||
      (start == 0 && end >= this.inputView.innerText.length)) {
      if (parseInt( this.textFontSize) > 12) {
        this.textFontSize = parseInt( this.textFontSize) - 1;
      }

      if (start == 0 && end >= this.inputView.innerText.length) {
          let cell = new TextStyleCell()
          for (let index = 0 ; index<this.styleList.length ; index++) {
            cell = this.styleList[idnex];
              if (cell.isSetFontSize()) {
                  if (cell.textFontSize > 12) {
                      cell.textFontSize -= 1;
                  }
              } else {
                  cell.textFontSize = parseInt( this.textFontSize) - 1;
              }
          }
      }
    } else {
      let isNew = true;
      let allContainCells = new Array();
      allContainCells = this.getAllContainTextStyleCells(start, end);
      let cell = new TextStyleCell()
      for (let index = 0 ; index<allContainCells.length ; index++) {
        cell = allContainCells[index]
        if (cell.isSetFontSize()) {
            if (cell.textFontSize > 12) {
                cell.textFontSize -= 1;
            }
        } else {
            cell.textFontSize = this.textFontSize - 1;
        }
        if (cell.startIndex == start && cell.endIndex == end) {
            isNew = false;
        }
      }
      if (isNew) {
          let cell = new TextStyleCell()
          cell.startIndex = start;
          cell.endIndex = end;
          if (cell.isSetFontSize()) {
              if (cell.textFontSize > 12) {
                  cell.textFontSize -= 1;
              }
          } else {
              cell.textFontSize = parseInt( this.textFontSize) - 1;
          }
          this.styleList.push(cell);
      }
    }   
  }
  getAllContainTextStyleCells(start, end) {
    let list = new Array();
    if (this.styleList == null) {
        return list;
    }

    let cell = new TextStyleCell()
    for (let index = 0; index<this.styleList.length; index++) {
      cell = this.styleList[index];
        if (cell.startIndex >= start && cell.endIndex <= end) {
            list.push(cell);
        }
    }
    return list;
  }
  //复制的内容||暂时不用
  setCtrlVMonitor(e){
    if (!(e.clipboardData && e.clipboardData.items)) {
      return;
    }
    for (var i = 0, len = e.clipboardData.items.length; i < len; i++) {
              var itemz = e.clipboardData.items[i];
              if (itemz.type.indexOf('image') !== -1) {
                let file = itemz.getAsFile();
                // break;
              } else if (itemz.kind === "string") {
                // console.log("string ", itemz);
              }
              if (itemz.kind === "string") {

                itemz.getAsString(function (str) {
                let strCopy =  str.replace( /<[^>]*>/g, "" )
                if(window.getSelection.toString != "" && window.getSelection.toString != null && this.firstSpanId != undefined){
                  var coords = document.getElementById("textArea");
                  if(coords.children.length>0){
                    let coordsSpanCount = coords.children.children.length;
                    let countDiv = 0;
                    for(let index = 0; index < coordsSpanCount; index++){
                      
                      var son=document.getElementById(index);
                      let sonCount = son.innerText.length;
                      countDiv += sonCount;

                      if(coords.children.children[index].id == firstSpanId){
                        let spanIdenx = son.innerText;
                        let spanStart = spanIdenx.substring(0,this.startSubscriptLabel);
                        spanStart+=strCopy;
                        son.innerText = spanStart;
                      }else if(countDiv > this.startSubscript && countDiv <= this.endSubscript+1){
                        coords.removeChild(son);
                      }else if( countDiv > this.endSubscript+1){
                        let spanEnd = son.innerText.substring(this.endSubscriptLabel,sonCount)
                        son.innerText = spanEnd;
                        return;
                      }

                    }
                  }

                }else{

                }
              })
            }
              else if (itemz.kind === "file") {
                  var pasteFile = itemz.getAsFile();
              }
              
    } 
  }

  //给文本style赋值，把样式赋值进入styleCells
  setStyleList(){

    // this.cancelConstituency();
    
    var coords = document.getElementById("textArea");
    
    let obj = {innerTextObj:coords.innerText,innerHTMLObj:coords.innerHTML,childrenObj:coords.childNodes};  

    let spanCount =obj.childrenObj.length;
    let spanCounts =obj.childrenObj
      if(spanCount>1){
        let start = 0;
        let end = 0;
        let cellListStyle = new Array();

        for(let index = 0; index < spanCount; index++){
          let cell = new TextStyleCell();
          let spanText = spanCounts[index];
          if (spanText == null ||
              spanText == undefined ||
              spanText.style == null ||
              spanText.style == undefined) {
              continue
          }
          if(spanText.style.color != null && 
            spanText.style.color != "" && 
            spanText.style.color != "rgb(0, 0, 0)"){

            let endTo = end + spanText.innerText.length
            let color = spanText.style.color
            let colorRgb = (color.replace("rgb(","")).replace(")","");
            let colorRgbs = colorRgb.split(",");
            let r = parseInt(colorRgbs[0]);
            let g = parseInt(colorRgbs[1]);
            let b = parseInt(colorRgbs[2]);
            let colorNew = Colors.getColorByRgb(r,g,b);
            cell.startIndex = start;
            cell.endIndex = endTo;
            cell.textColor = colorNew;
            cellListStyle.push(cell); 

          }
          if(spanText.style.backgroundColor != null && 
            spanText.style.backgroundColor != "" && 
            spanText.style.backgroundColor != "rgb(253, 73, 43)"){

            let endTo = end + spanText.innerText.length
            let color = spanText.style.backgroundColor
            let colorRgb = (color.replace("rgb(","")).replace(")","");
            let colorRgbs = colorRgb.split(",");
            let r = parseInt(colorRgbs[0]);
            let g = parseInt(colorRgbs[1]);
            let b = parseInt(colorRgbs[2]);
            let colorNew = Colors.getColorByRgb(r,g,b);
            cell.startIndex = start;
            cell.endIndex = endTo;
            cell.bgColor = colorNew;
            cellListStyle.push(cell); 

          }
          if(spanText.style.fontSize != null && spanText.style.fontSize != ""){
            if(spanText.classList.length == 0) {
          
                let endTo = end + spanText.innerText.length
                let fontSize = spanText.style.fontSize
                let fontSizeSpan = this.getViewPxValue(fontSize);
                cell.startIndex = start;
                cell.endIndex = endTo;
                cell.textFontSize = fontSizeSpan;
                cellListStyle.push(cell); 
           }
          }
          if(spanText.style.fontWeight == "bold"){
            let endTo = end + spanText.innerText.length
            cell.startIndex = start;
            cell.endIndex = endTo;
            cell.setTextBold(true);
            cellListStyle.push(cell); 
          }
          if(spanText.style.fontStyle =="italic"  && spanText.style.fontStyle != ""){
            let endTo = end + spanText.innerText.length
            cell.startIndex = start;
            cell.endIndex = endTo;
            cell.setTextItalics(true);
            cellListStyle.push(cell); 
          }
          if(spanText.style.textDecoration != "line-through" && spanText.style.textDecoration != ""){

            let endTo = end + spanText.innerText.length
            cell.startIndex = start;
            cell.endIndex = endTo;
            cell.setTextStrikethrough(true);
            cellListStyle.push(cell); 
                
          }

          start += spanText.innerText.length;
          end += spanText.innerText.length;

        }
        // this.addConstituency();
        this.styleList = cellListStyle;
    }
  }

  isShowInput() {
    return this.inputView != null && this.inputView.style.display != "none"
  }

  setShowInput() {
    if (this.inputView == null) {
      return
    }
    this.contentViewLeftForShowInput = this.getContentScrollX()
    this.contentViewTopForShowInput = this.getContentScrollY()
    this.inputView.style.display = "block"
    clearInterval(this.inputtingTimer)
    this.lastCursortPosition = -1
    this.inputtingTimer = setInterval(() => {
      if (!this.isShowInput()) {
        clearInterval(this.inputtingTimer)
        return
      }
      let currentCursortPosition = this.getCursortPosition(this.inputView)
      if (currentCursortPosition != this.lastCursortPosition && currentCursortPosition > -1) {
        // this.checkInputPoint()
        this.lastCursortPosition = currentCursortPosition
      }
    }, 50)
  }

  setHideInput() {
    if (this.inputView == null) {
      return
    }
    this.inputView.style.display = "none"
    clearInterval(this.inputtingTimer)
  }

  //给选中开始结束下标赋值
  getSelectedSubscriptAssignment(){
    
    var coords = document.getElementById("textArea");
    let textC = coords.innerText;
    let obj = this.getSelectedContents();
    // let obj = "";
    // 
    
    let spans = coords;

    if(coords.children.length > 0){
      spans = coords.children;
    }
    let spansCount = spans.length;

    let start = 0;
    let end = 0;

    let spanSelectColorCount = this.spanSelectColor.length;
    if(obj == "span"){
      
        // this.firstSpanId = obj.children[0].id;
      // for(let index = 0; index < objCounts ;index++){
        let spanId = this.firstSpanId
        for(let indexSpan = 0; indexSpan < spansCount; indexSpan++){
            if(spanId === spans[indexSpan].id){
              // if(){

              // }
              start += this.startSubscriptLabel;
              end = end + start + this.selectedTextContent.length;
              this.startSubscript = start;
              this.endSubscript = end;
              // return ;
            }
            start += spans[indexSpan].innerText.length;

        }
      // }
    }else if(obj == "div"){
      start += this.startSubscriptLabel;
      end = end + this.endSubscriptLabel;
      this.startSubscript = start;
      this.endSubscript = end;
    }
    if(spanSelectColorCount>0){
      let startEnd = this.spanSelectColor[spanSelectColorCount-1];
      this.startSubscript = startEnd.startSub;
      this.endSubscript = startEnd.endSub;
    }

    if (CheckTextIsSelected.isSelected(this.selectData.textContent.text) && this.monitorIndex == 0) {
      this.startSubscript = 0;
      this.endSubscript = this.selectData.textContent.text.length;
    }
    if(coords.innerText.substring(this.startSubscript,this.endSubscript) != this.selectedTextContent){
      let objText = this.getxuanz();
      if(objText != ""){
        this.startSubscript = objText.indexOf('#@$');
        this.endSubscript = this.startSubscript+this.selectedTextContent.length;
      }
    }
    
  }

  //获取到选中的内容的标签
  getSelectedContents(){
    if (window.getSelection) { //chrome,firefox,opera
        var range=window.getSelection().getRangeAt(0);
        var container = document.createElement('div');
        container.appendChild(range.cloneContents());
        

        if(range.startContainer.parentNode.localName == "span"){
          this.firstSpanId = range.startContainer.parentNode.id;
          this.endSpanId = range.endContainer.parentNode.id;
        }
        this.localNameLabel = range.startContainer.parentNode.localName
        this.startSubscriptLabel = range.startOffset;
        this.endSubscriptLabel = range.endOffset;
        this.selectedTextContent = window.getSelection().toString();
        return range.startContainer.parentNode.localName;
        // return container;
        //return window.getSelection(); //只复制文本
    }
    else if (document.getSelection) { //其他
        var range=document.getSelection().getRangeAt(0);
        var container = document.createElement('div');
        container.appendChild(range.cloneContents());
        this.startSubscriptLabel = document.getSelection().anchorOffset;
        this.endSubscriptLabel = document.getSelection().focusOffset;
        this.selectedTextContent = document.getSelection().toString();
        return container.innerHTML;
        //return document.getSelection(); //只复制文本
    }
    else if (document.selection) { //IE特有的
        return document.selection.createRange().htmlText;
        //return document.selection.createRange().text; //只复制文本
    }
}

getxuanz(){
  var coords = document.getElementById("textArea");
  const cds = coords.innerHTML;
  const sel = window.getSelection();
  var checkStr = sel.toString();
  this.selectedTextContent = checkStr;
  if(sel.getRangeAt && sel.rangeCount && checkStr!=""){
    let range = sel.getRangeAt(0);
    let range2 = sel.getRangeAt(0);
    

    range.deleteContents();
    range2.deleteContents();
    let randomId = "rid123456"
    var el = document.createElement("span");
    var newStr = '<span id="'+randomId+'" class="forbidcheck" contenteditable="false" >#@$'+checkStr+'#@$</span>';
    el.innerHTML = newStr;
    // let tempStr = newStr;
    var frag = document.createDocumentFragment(),node,lastNode;
    while((node = el.firstChild)){
      lastNode = frag.appendChild(node);
    }
    range.insertNode(frag);
    const coordsCount = coords.innerText;
    range.deleteContents();

    var el2 = document.createElement("span");
    var newStr2 = '<span id="'+randomId+'" class="forbidcheck" contenteditable="false" >'+checkStr+'</span>';
    el2.innerHTML = newStr2;
    var frag2 = document.createDocumentFragment(),node2,lastNode2;
    while((node2 = el2.firstChild)){
      lastNode2 = frag2.appendChild(node2);
    }
    
    range2.insertNode(frag2);
    if(lastNode){
      // range = range.cloneRange();
      // range.setStartAfter(lastNode);
      // range.collapse(true);
      // sel.removeAllRanges();
      // sel.addRange(range);
    }
    // coords.innerHTML = cds;
    // console.log(range2);
    // sel.removeAllRanges();
    // sel.addRange(range2);
    return coordsCount;
  }
  return "";
}

 //给文本内容改变之后的list集合赋值
 setMonitorDivContent(){

  this.cancelConstituency();
  
  var coords = document.getElementById("textArea");
  
  this.startSubscript = this.endSubscriptLabel;
  let obj = {innerTextObj:coords.innerText,innerHTMLObj:coords.innerHTML,childrenObj:coords.childNodes};  
        

    let spanCount = obj.childrenObj.length;
    let spanCounts = obj.childrenObj
    if(spanCount>0) {      
      //清除样式
      this.delegate.editMindmapContent.clearTextStyle(this.delegate.lastDblclickMindData.id, this.isEditCardTitle);

      let start = 0;
      let end = 0;

      for(let index = 0; index < spanCount; index++){
        let spanText = spanCounts[index];
        if(spanText.style == null && spanText.style == undefined ){
          continue;
        }
        if(spanText.style.color != null && spanText.style.color != "" && spanText.style.color != "rgb(0, 0, 0)"){
          let endTo = end + spanText.innerText.length
          let color = spanText.style.color
          let colorRgb = (color.replace("rgb(","")).replace(")","");
          let colorRgbs = colorRgb.split(",");
          let r = parseInt(colorRgbs[0]);
          let g = parseInt(colorRgbs[1]);
          let b = parseInt(colorRgbs[2]);
          let colorNew = Colors.getColorByRgb(r,g,b);
              
          this.startSubscript = start;
          this.endSubscript = endTo;
          if(this.startSubscript != -1 && this.endSubscript != -1){
            this.delegate.editMindmapContent.changeTextColor(colorNew, this.isEditCardTitle);
          }

        }
        if(spanText.style.backgroundColor != null && spanText.style.backgroundColor != "" && spanText.style.backgroundColor != "rgb(253, 73, 43)"){
          let endTo = end + spanText.innerText.length
          let color = spanText.style.backgroundColor
          let colorRgb = (color.replace("rgb(","")).replace(")","");
          let colorRgbs = colorRgb.split(",");
          let r = parseInt(colorRgbs[0]);
          let g = parseInt(colorRgbs[1]);
          let b = parseInt(colorRgbs[2]);
          let colorNew = Colors.getColorByRgb(r,g,b);
              
          this.startSubscript = start;
          this.endSubscript = endTo;
          if(this.startSubscript != -1 && this.endSubscript != -1){
            this.delegate.editMindmapContent.changeTextBgColor(colorNew, this.isEditCardTitle);
          }
        }
        if(spanText.style.textShadow != null && spanText.style.textShadow != ""){
          let endTo = end + spanText.innerText.length
          let shadowStr = spanText.style.textShadow
          
          let color = shadowStr.replace("1px 1px", "");
          let colorRgb = (color.replace("rgb(","")).replace(")","");

          let colorRgbs = colorRgb.split(",");
          let r = parseInt(colorRgbs[0]);
          let g = parseInt(colorRgbs[1]);
          let b = parseInt(colorRgbs[2]);
          let colorNew = Colors.getColorByRgb(r,g,b);
              
          this.startSubscript = start;
          this.endSubscript = endTo;
          if(this.startSubscript != -1 && this.endSubscript != -1){
            this.delegate.editMindmapContent.changeTextShadowColor(colorNew, this.isEditCardTitle);
          }
        }
        if(spanText.style.fontSize != null && spanText.style.fontSize != ""){
          if(spanText.classList.length == 0) {
              let endTo = end + spanText.innerText.length
              let fontSize = spanText.style.fontSize
              let fontSizeSpan = this.getViewPxValue(fontSize);
              this.startSubscript = start;
              this.endSubscript = endTo;
              this.delegate.editMindmapContent.changeTextSize(fontSizeSpan, this.isEditCardTitle);
          }
        }
        if(spanText.style.fontWeight == "bold" && spanText.style.fontWeight != ""){
          let endTo = end + spanText.innerText.length
          this.startSubscript = start;
          this.endSubscript = endTo;
          this.delegate.editMindmapContent.changeTextStyle(0, this.isEditCardTitle);
        }
        if(spanText.style.fontStyle =="italic"  && spanText.style.fontStyle != ""){
          let endTo = end + spanText.innerText.length
          this.startSubscript = start;
          this.endSubscript = endTo;
          this.delegate.editMindmapContent.changeTextStyle(1, this.isEditCardTitle);
        }
        if(spanText.style.textDecoration == "line-through" && spanText.style.textDecoration != ""){

          let endTo = end + spanText.innerText.length
          this.startSubscript = start;
          this.endSubscript = endTo;
          this.delegate.editMindmapContent.changeTextStyle(2, this.isEditCardTitle);
              
        }
      
        start += spanText.innerText.length;
        end += spanText.innerText.length;

      }
      

    // }
      
  }
}

  //创建关联线的两个控制圆
  createControllCircle() {

    if (document.getElementById("controllCircle1") == null) {
      this.controllStartCircle = document.createElement("div")
      this.controllStartCircle.setAttribute('id', 'controllCircle1');
      this.contentView.appendChild(this.controllStartCircle)
    }
    this.setControllCircle(this.controllStartCircle, this.controllCircle1Data);
    
    if (document.getElementById("controllCircle2") == null) {
      this.controllEndCircle = document.createElement("div")
      this.controllEndCircle.setAttribute('id', 'controllCircle2');
      this.contentView.appendChild(this.controllEndCircle)
    }
    this.setControllCircle(this.controllEndCircle, this.controllCircle2Data);
    
    if (document.getElementById("startCircle1") == null) {
      this.startCircle = document.createElement("div")
      this.startCircle.setAttribute('id', 'startCircle1');      
      this.contentView.appendChild(this.startCircle)
    }
    this.setControllCircle(this.startCircle, this.circleStartData);
    
    if (document.getElementById("endCircle1") == null) {
      this.endCircle = document.createElement("div")
      this.endCircle.setAttribute('id', 'endCircle1');
      this.contentView.appendChild(this.endCircle)
    }
    this.setControllCircle(this.endCircle, this.circleEndData);
    this.startCircle.style.cursor = "all-scroll"
    this.controllEndCircle.style.cursor = "all-scroll"
    this.controllStartCircle.style.cursor = "all-scroll"
    this.endCircle.style.cursor = "all-scroll"
  }

  updataRightAnglePoints(data) {
    if (data.lineContent.connectLineType == ConnectLineType.RIGHT_ANGLE_LINE && data.lineContent.rightAnglePoints.length > 0) {
      if (this.rightAnglePoints.length == data.lineContent.rightAnglePoints.length) {
        for (let index = 0; index < data.lineContent.rightAnglePoints.length; index++) {
          const cell = data.lineContent.rightAnglePoints[index];
          const rightAnglePointCell = this.rightAnglePoints[index];
          let point = new Point(cell.x + data.x, cell.y + data.y);
          let viewData = {
            x: point.x - 4,
            y: point.y - 4,
            width: 8,
            height: 8,
          }
          rightAnglePointCell.data = viewData
          this.setControllCircle(rightAnglePointCell.dom, viewData);
        }
      } else {
        let points = []
        for (let index = 0; index < data.lineContent.rightAnglePoints.length; index++) {
          const cell = data.lineContent.rightAnglePoints[index];
          points.push(new Point(cell.x + data.x, cell.y + data.y))
        }
        this.setRightAnglePoints(points);
      }
    } else {
      this.setRightAnglePoints([]);
    }
    if (this.controllStartCircle.style.display == 'block') {
      let controlStartPoint = data.lineContent.getStartControlPoint();
      let controlEndPoint = data.lineContent.getEndControlPoint();

      this.controllCircle1Data.x = (controlStartPoint.x - this.controllCircle1Data.width / 2);
      this.controllCircle1Data.y = (controlStartPoint.y - this.controllCircle1Data.height / 2);
      this.controllCircle2Data.x = (controlEndPoint.x - this.controllCircle2Data.width / 2);
      this.controllCircle2Data.y = (controlEndPoint.y - this.controllCircle2Data.height / 2);

      this.controllStartCircle.style.left = (controlStartPoint.x - this.controllCircle1Data.width / 2) + 'px';
      this.controllStartCircle.style.top = (controlStartPoint.y - this.controllCircle1Data.height / 2) + 'px';
      this.controllEndCircle.style.left = (controlEndPoint.x - this.controllCircle2Data.width / 2) + 'px';
      this.controllEndCircle.style.top = (controlEndPoint.y - this.controllCircle2Data.height / 2) + 'px';
    }
    
    let startPoint = new Point(data.lineContent.startPoint().x + data.x, data.lineContent.startPoint().y + data.y);
    let endPoint = new Point(data.lineContent.endPoint().x + data.x, data.lineContent.endPoint().y + data.y);
    
    this.circleStartData.x = startPoint.x - this.circleStartData.width / 2;
    this.circleStartData.y = startPoint.y - this.circleStartData.height / 2;
    this.circleEndData.x = endPoint.x - this.circleEndData.width / 2;
    this.circleEndData.y = endPoint.y - this.circleEndData.height / 2;

    this.startCircle.style.left = (startPoint.x - this.circleStartData.width / 2) + 'px';
    this.startCircle.style.top = (startPoint.y - this.circleStartData.height / 2) + 'px';
    this.endCircle.style.left = (endPoint.x - this.circleEndData.width / 2) + 'px';
    this.endCircle.style.top = (endPoint.y - this.circleEndData.height / 2) + 'px';

    
  }

  setRightAnglePoints(points) {
    if (this.rightAnglePoints.length > 0) {
    for (let index = 0; index < this.rightAnglePoints.length; index++) {
        let cell = this.rightAnglePoints[index]
        if (cell != null && cell.dom.parentNode != null) {
          cell.dom.parentNode.removeChild(cell.dom)
        }
      }
    }
    this.rightAnglePoints = new Array()
    if (points.length == 0) {
      return
    }
    for (let index = 0; index < points.length; index++) {
      let point = points[index]
      let dom = document.createElement("div")
      let data = {
        x: point.x - 4,
        y: point.y - 4,
        width: 8,
        height: 8,
      }
      dom.setAttribute('id', 'circle_right_angle_' + index);
      this.contentView.appendChild(dom)
      this.setControllCircle(dom, data);
      this.rightAnglePoints.push({dom: dom, data: data})
    }
  }

  
  checkRectOffScreen(rect, isCenter, isAnimation) {
    if (this.moveAnimationInterval > -1) {
      clearInterval(this.moveAnimationInterval)
      this.moveAnimationInterval = -1
    }
    
    let screenRect = this.getScreenRect();
    let padding = Math.min((this.UiUtil).dip2px(100) * this.scale, screenRect.height() * 0.1);
    if (this.delegate != null && this.delegate.isPlayStatus()) {
      padding = 10;
    }
    let marginX = isCenter ? (screenRect.width() - rect.width()) / 2 : padding;
    let marginY = isCenter ? (screenRect.height() - rect.height()) / 2 : padding;
    var moveX = 0;
    var moveY = 0;
    if (screenRect.x > rect.x - padding / 4) {
        moveX = rect.x - screenRect.x - marginX;
    } else if (screenRect.x + screenRect.width() < rect.x + rect.width() + padding * 0.8) {
        moveX = rect.x + rect.width() - (screenRect.x + screenRect.width()) + marginX;
    }
    if (screenRect.y > rect.y - padding / 4) {
        moveY = rect.y - screenRect.y - marginY;
    } else if (screenRect.y + screenRect.height() < rect.y + rect.height() + padding * 0.8) {
        moveY = rect.y + rect.height() - (screenRect.y + screenRect.height()) + marginY;
    }
    if (moveX != 0 || moveY != 0) {
      let translationX = this.getContentScrollX();
      let translationY = this.getContentScrollY();

      let toTranslationX = translationX + (this.scale * moveX);
      let toTranslationY = translationY + (this.scale * moveY);
      if (isAnimation) {
        let moveSpaceX = (toTranslationX - translationX) / 5
        let moveSpaceY = (toTranslationY - translationY) / 5
        let obj = new Object()
        obj.moveSpaceX = moveSpaceX
        obj.moveSpaceY = moveSpaceY
        this.moveAnimationInterval = setInterval(() => {
          
          let x = this.getContentScrollX();
          let y = this.getContentScrollY();

          this.scrollTo((x + obj.moveSpaceX), (y + obj.moveSpaceY));
          if ((Math.abs(x + obj.moveSpaceX) - toTranslationX) <=  Math.abs(obj.moveSpaceX) &&
            (Math.abs(y + obj.moveSpaceY) - toTranslationY) <=  Math.abs(obj.moveSpaceY)) {
            clearInterval(this.moveAnimationInterval)
            this.boundaryHandler()
          }

          obj.moveSpaceY = obj.moveSpaceY/3
          obj.moveSpaceX = obj.moveSpaceX/3
          if (Math.abs(obj.moveSpaceY) < 1) {
            obj.moveSpaceY = obj.moveSpaceY * 3
          }
          if (Math.abs(obj.moveSpaceX) < 1) {
            obj.moveSpaceX = obj.moveSpaceX * 3
          }
        }, 20);
      } else {
        this.scrollTo(toTranslationX, toTranslationY)
      }

    }
  }

  getScreenRect() {
    let translationX = this.getContentScrollX();
    let translationY = this.getContentScrollY();
    
    let screenX = translationX / this.scale;
    let screenY = translationY / this.scale;
    let parentWidth = this.UiUtil.getScreenWidth();
    let parentHeight = this.UiUtil.getScreenHeight();
    
    return new CGRect(screenX , screenY,
            parentWidth/this.scale, parentHeight/this.scale);
  }

  getMindMapCentralPointInScreen() {
    let rect = this.getScreenRect();
    return new CGPoint(rect.x + rect.width() / 2, rect.y + rect.height() / 2);
}

  setControllCircle(element, data) {  //设置控制圆的样式。
    element.style.position = "absolute"
    element.style.zIndex = 31
    element.style.display = 'block';
    element.style.left = data.x + 'px';
    element.style.top = data.y + 'px';
    element.style.width = data.width + 'px';
    element.style.height = data.height + 'px';
    element.style.boxSizing = 'border-box';
    element.style.borderRadius = '50%';
    element.style.border = '1px solid red';
    element.style.cursor = 'pointer';
    element.style.backgroundColor = '#fff';
  }

  //展示控制圆，并计算位置。
  showCircle(data) {
    if (data == null || data.type != MindElementType.NODE_CONNECT_LINE || data.lineContent == null) {
      return false;
    }
    this.selectData = data
    let controlStartPoint = data.lineContent.getStartControlPoint();
    let controlEndPoint = data.lineContent.getEndControlPoint();
    let startPoint = new Point(data.lineContent.startPoint().x + data.x, data.lineContent.startPoint().y + data.y);
    let endPoint = new Point(data.lineContent.endPoint().x + data.x, data.lineContent.endPoint().y + data.y);
    
    this.circleStartData.x = startPoint.x - this.circleStartData.width / 2;
    this.circleStartData.y = startPoint.y - this.circleStartData.height / 2;
    this.circleEndData.x = endPoint.x - this.circleEndData.width / 2;
    this.circleEndData.y = endPoint.y - this.circleEndData.height / 2;

    this.controllCircle1Data.x = (controlStartPoint.x - this.controllCircle1Data.width / 2);
    this.controllCircle1Data.y = (controlStartPoint.y - this.controllCircle1Data.height / 2);
    this.controllCircle2Data.x = (controlEndPoint.x - this.controllCircle2Data.width / 2);
    this.controllCircle2Data.y = (controlEndPoint.y - this.controllCircle2Data.height / 2);

    
    if (data.lineContent.connectLineType == ConnectLineType.CURVE_LINE) {
      this.controllEndCircle.style.display = 'block'
      this.controllStartCircle.style.display = 'block'
    } else {
      this.controllEndCircle.style.display = 'none'
      this.controllStartCircle.style.display = 'none'
    }
    this.startCircle.style.display = 'block'
    this.endCircle.style.display = 'block'
    this.controllStartCircle.style.left = (controlStartPoint.x - this.controllCircle1Data.width / 2) + 'px';
    this.controllStartCircle.style.top = (controlStartPoint.y - this.controllCircle1Data.height / 2) + 'px';
    this.controllEndCircle.style.left = (controlEndPoint.x - this.controllCircle2Data.width / 2) + 'px';
    this.controllEndCircle.style.top = (controlEndPoint.y - this.controllCircle2Data.height / 2) + 'px';

    this.startCircle.style.left = (startPoint.x - this.circleStartData.width / 2) + 'px';
    this.startCircle.style.top = (startPoint.y - this.circleStartData.height / 2) + 'px';
    this.endCircle.style.left = (endPoint.x - this.circleEndData.width / 2) + 'px';
    this.endCircle.style.top = (endPoint.y - this.circleEndData.height / 2) + 'px';
    this.controllCircle = true;
    if (data.lineContent.connectLineType == ConnectLineType.RIGHT_ANGLE_LINE) {
      if (data.lineContent.rightAnglePoints.length > 0) {
        let points = []
        for (let index = 0; index < data.lineContent.rightAnglePoints.length; index++) {
          const cell = data.lineContent.rightAnglePoints[index];
          points.push(new Point(cell.x + data.x, cell.y + data.y))
        }
        this.setRightAnglePoints(points);
      } else {
        this.setRightAnglePoints([]);
      }
    } else {
      this.setRightAnglePoints([]);
    }
    
    //删除菜单展示
    return true
  }

  hideCircle(show) {  //控制小圆的显示隐藏
    this.controllEndCircle.style.display = 'none';
    this.controllStartCircle.style.display = 'none';
    this.endCircle.style.display = 'none';
    this.startCircle.style.display = 'none';
    this.controllCircle = false;
    this.setRightAnglePoints([]);
  }

  clearMouseEvent() {
    this.gtouchend()
    this.checkBoxView.isCheckBox = false
    this.contentView.onmousemove = this.contentView.onmouseup = document.onmouseup = null;
  }

  //设置输入框  clickIcon判断是否点击了包裹线加号的图标
  setInput(data, type, refresh, clickIcon) {
    let selectStartIndex = -1;
    let selectEndIndex = -1;
    if (this.isShowInput()) {
      selectStartIndex = this.getSelectionStart();
      selectEndIndex = this.getSelectionEnd();
      if (this.selectData != null && data != null &&
          this.selectData.id == data.id && (this.typeNode == type || (this.typeNode == null && type == "textContent"))) {
          return;
      }
    }
    this.clearMouseEvent()
    this.selectData = data
    
    this.typeNode = type;
    this.clearInputStyle();

    //外边框的样式
    if (type == "setCardTitleEdit" && data.isCardContent()) {
      if(window.event == undefined) {
      } else {
        if (this.delegate.isTemplatePage == false && this.delegate.isSharePage == false) { 
          let divHeight = this.getViewPxValue(this.selectData.height);
          // const pos  = {x:window.event.x,y:window.event.target.getBoundingClientRect().y,inputHeight:divHeight};
          let dataPoint = this.getEventAndDataPointByRoot(window.event, this.selectData)
          const pos = {x: dataPoint.x, y: dataPoint.y, inputHeight:divHeight,screenRect:this.getScreenRect(),scale:this.scale};
          this.delegate.isShowFontStyleMenu(pos,true);
        }
      }
      this.setTextContentInputStyle(data, 'titleContent', refresh);  //为节点时
    } else if (data.textContent != null) {  //为正常节点时
      if (type == "setSubscript") {
        this.isEditCardTitle = false
        this.setSubscriptInputStyle(data);  //为下标时
      } else {
        if(window.event == undefined) {
        } else {
          if (this.delegate.isTemplatePage == false && this.delegate.isSharePage == false) { 
            let divHeight = this.getViewPxValue(this.selectData.height);
            // const pos  = {x:window.event.x,y:window.event.target.getBoundingClientRect().y,inputHeight:divHeight};
            let dataPoint = this.getEventAndDataPointByRoot(window.event, this.selectData)
            const pos = {x: dataPoint.x, y: dataPoint.y, inputHeight:divHeight,screenRect:this.getScreenRect(),scale:this.scale};
            this.delegate.isShowFontStyleMenu(pos,true);
          }
        }
        this.isEditCardTitle = false
        this.setTextContentInputStyle(data, 'textContent', refresh);  //为节点时
      }
    } else if (data.generalizationContent != null) {   //为摘要节点时
      if (type == "setSubscript") {
        this.isEditCardTitle = false
        this.setSubscriptInputStyle(data);  //为下标时
      } else {
        if(window.event == undefined) {

        } else {
          if (this.delegate.isTemplatePage == false && this.delegate.isSharePage == false) { 
            let divHeight = this.getViewPxValue(this.selectData.height);
            // const pos  = {x:window.event.x,y:window.event.target.getBoundingClientRect().y,inputHeight:divHeight};
            let dataPoint = this.getEventAndDataPointByRoot(window.event, this.selectData)
            const pos = {x: dataPoint.x,
              y: dataPoint.y,
              inputHeight:divHeight,screenRect:this.getScreenRect(),scale:this.scale};
            this.delegate.isShowFontStyleMenu(pos,true);
          }
        }
        this.isEditCardTitle = false
        this.setInputStyle(data, 'generalizationContent');  //为节点时
      }
    } else if (data.lineContent != null) { //包裹线//关联线
      this.setLineTextInputStyle(data, 'lineContent', clickIcon);
    }
    this.bundingClickTab();
    this.checkInputPoint();
    if (selectStartIndex > -1 && selectEndIndex > -1) {
      setTimeout(() => {
        this.setInputSelectedIndex(selectStartIndex, selectEndIndex);
        this.addConstituency();
      }, 1000);
      this.setInputSelectedIndex(selectStartIndex, selectEndIndex);
      this.addConstituency();
    }
    
    //有可能需要
    // this.inputView.style.boxSizing = 'border-box'
    // setInterval(() => {
    //   console.log(this.inputView.innerHTML);
    // }, 2000);
  }

  checkInputPoint() {
    if (!this.isShowInput()) {
      return
    }
    let x = this.getViewPxValue(this.inputView.style.left);
    let y = this.getViewPxValue(this.inputView.style.top);
    let width = this.inputView.clientWidth
    let height = this.inputView.clientHeight
    let rect = new CGRect(x, y, width, height)

    let focusPoint = this.getSelectionCoords(this.inputView);

    if ((focusPoint.x < 0 && focusPoint.y < 0) || focusPoint.x < -800 || focusPoint.y < -800) {
      return
    }
    if (focusPoint.x == 0 || focusPoint.y == 0) {
      this.checkRectOffScreen(rect,false, false)
    } else {  
      let rootRect = this.root.getBoundingClientRect()
      var rootClientX = rootRect.left;
      var rootClientY = rootRect.top;
      let left = this.getContentScrollX()
      let top = this.getContentScrollY()

      let eventX = (focusPoint.x - rootClientX) / this.scale;
      let eventY = (focusPoint.y - rootClientY) / this.scale;
      let contentX = left / this.scale;
      let contentY = top / this.scale;
      
      let clickPointX = eventX + contentX;
      let clickPointY = eventY + contentY;
      this.checkRectOffScreen(new CGRect(clickPointX, clickPointY, 20, 50),false, false)
    }
    
  }

  getCursortPosition(input) {
    var caretOffset = 0;
    var doc = input.ownerDocument || input.document;
    var win = doc.defaultView || doc.parentWindow;
    var sel;
    if (typeof win.getSelection != "undefined") {//谷歌、火狐
      sel = win.getSelection();
      if (sel.rangeCount > 0) {//选中的区域
        var range = win.getSelection().getRangeAt(0);
        var preCaretRange = range.cloneRange();//克隆一个选中区域
        preCaretRange.selectNodeContents(input);//设置选中区域的节点内容为当前节点
        preCaretRange.setEnd(range.endContainer, range.endOffset);  //重置选中区域的结束位置
        caretOffset = preCaretRange.toString().length;
      }
    } else if ((sel = doc.selection) && sel.type != "Control") {//IE
      var textRange = sel.createRange();
      var preCaretTextRange = doc.body.createTextRange();
      preCaretTextRange.moveToElementText(input);
      preCaretTextRange.setEndPoint("EndToEnd", textRange);
      caretOffset = preCaretTextRange.text.length;
    }
    return caretOffset;
  }

  getSelectionCoords(input) {
    input = input || window;
    var doc = input.ownerDocument || input.document;
    var win = doc.defaultView || doc.parentWindow;
    var sel = null, range, rects, rect;
    var x = 0, y = 0;
    if (win.getSelection) {
      sel = win.getSelection();
      if (sel.rangeCount) {
        range = sel.getRangeAt(0).cloneRange();
        let isInInputText = false;
        let checkElement = sel.getRangeAt(0).startContainer;
        for (let i = 0; i < 6; i++) {
          if (checkElement == null || checkElement == undefined) {
            break;
          }
          if (!isInInputText && checkElement.id == "textArea") {
            isInInputText = true
            break;
          }
          checkElement = checkElement.parentNode
        }
        if (!isInInputText) {
          return { x: 0, y: 0};
        }
        if (range.getClientRects) {
            range.collapse(true);
            rects = range.getClientRects();
            if (rects.length > 0) {
                rect = rects[0];
            }
            // 光标在行首时，rect为undefined
            if(rect){
                x = rect.left;
                y = rect.top;
            }
        }
        // Fall back to inserting a temporary element
        if ((x == 0 && y == 0) || rect === undefined) {
            var span = doc.createElement("span");
            if (span.getClientRects) {
                // Ensure span has dimensions and position by
                // adding a zero-width space character
                span.appendChild( doc.createTextNode("\u200b") );
                range.insertNode(span);
                rect = span.getClientRects()[0];
                if (rect != null && rect.left != null) {
                  x = rect.left;
                  y = rect.top;
                }
                var spanParent = span.parentNode;
                spanParent.removeChild(span);

                // Glue any broken text nodes back together
                spanParent.normalize();
            }
        }
      }
    }
    return { x: x, y: y };
  }

  bundingClickTab() { //用来在tab键加空格的。
    let that = this;
    this.tabFunction = function(e) {
      if(9 == (window.event ? e.keyCode : e.which)) {
          e.preventDefault();
          e.returnValue = false;
          //添加两个空格
          // let number = that.getCursortPosition(this);
          // let str = that.inputView.innerHTML;
          // let nullString = '  '
          // let starString = str.slice(0,number)
          // let endString = str.slice(number,str.length)
          // that.inputView.innerHTML = starString + nullString + endString;
          // that.setCaretPosition(this, number + 2)
          
          /*EditMindmapVM.addChildMindElement(true); //添加子主题
          { //键盘事件只触发一次
            that.inputView.style.opacity = 0;
            that.inputView.style.display = "block";
            setTimeout(()=>{
              that.inputView.style.display = "none";
            },10)
          }*/

      }
    }

    this.inputView.addEventListener('keydown',this.tabFunction)
  }

  getCursortPosition(element) {
    var caretOffset = 0;
    var doc = element.ownerDocument || element.document;
    var win = doc.defaultView || doc.parentWindow;
    var sel;
    if (typeof win.getSelection != "undefined") {//谷歌、火狐
      sel = win.getSelection();
      if (sel.rangeCount > 0) {//选中的区域
        var range = win.getSelection().getRangeAt(0);
        var preCaretRange = range.cloneRange();//克隆一个选中区域
        preCaretRange.selectNodeContents(element);//设置选中区域的节点内容为当前节点
        preCaretRange.setEnd(range.endContainer, range.endOffset);  //重置选中区域的结束位置
        caretOffset = preCaretRange.toString().length;
      }
    } else if ((sel = doc.selection) && sel.type != "Control") {//IE
      var textRange = sel.createRange();
      var preCaretTextRange = doc.body.createTextRange();
      preCaretTextRange.moveToElementText(element);
      preCaretTextRange.setEndPoint("EndToEnd", textRange);
      caretOffset = preCaretTextRange.text.length;
    }
    return caretOffset;
  }

  setCaretPosition (element, pos) {
    var range, selection;
    if (document.createRange)//Firefox, Chrome, Opera, Safari, IE 9+
    {
      range = document.createRange();//创建一个选中区域
      range.selectNodeContents(element);//选中节点的内容
      if(element.innerHTML.length > 0) {
        range.setStart(element.childNodes[0], pos); //设置光标起始为指定位置
      }
      range.collapse(true);       //设置选中区域为一个点
      selection = window.getSelection();//获取当前选中区域
      selection.removeAllRanges();//移出所有的选中范围
      selection.addRange(range);//添加新建的范围
    }
    else if (document.selection)//IE 8 and lower
    {
      range = document.body.createTextRange();//Create a range (a range is a like the selection but invisible)
      range.moveToElementText(element);//Select the entire contents of the element with the range
      range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
      range.select();//Select the range (make it the visible selection
    }
  }


  //全选div内容
  selectText(element) {
    var text = document.getElementById(element);
    setTimeout(() => {
      if (document.body.createTextRange) {
        var range = document.body.createTextRange();
        range.moveToElementText(text);
        range.select();
      } else if (window.getSelection) {
        var selection = window.getSelection();
        var range = document.createRange();
        range.selectNodeContents(text);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }, 100);    
  }

  //将光标移入div内容的最后
  selectFocusLast(element) {
    let obj = document.getElementById(element);
      let range = document.createRange();
      range.selectNodeContents(obj);
      range.collapse(false);
      let sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    // if(obj.innerText == "" || obj.innerText == null ){
    //   obj.focus();

    // }else{
    //   let range = document.createRange();
    //   range.selectNodeContents(obj);
    //   range.collapse(false);
    //   let sel = window.getSelection();
    //   sel.removeAllRanges();
    //   sel.addRange(range);
    // }
  }
  //将光标移入div内容的最后包裹线的
  selectFocusLastWrappingLine(element) {
    
    let obj = document.getElementById(element);
    setTimeout(() => {
      let range = document.createRange();
      range.selectNodeContents(obj);
      range.collapse(false);
      let sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    }, 200);
    // if(obj.innerText == "" || obj.innerText == null ){
    //   obj.focus();

    // }else{
    //   let range = document.createRange();
    //   range.selectNodeContents(obj);
    //   range.collapse(false);
    //   let sel = window.getSelection();
    //   sel.removeAllRanges();
    //   sel.addRange(range);
    // }
  }

  //获取选中内容的开始位置
  getSelectionStart(){
    if (!this.isShowInput()) {
      return -1;
    }

    let start = this.startSubscript;

    return start //TODO 实际选中文字起点
  }

  //获取选中内容的结束位置
  getSelectionEnd(){
    if (!this.isShowInput()) {
      return -1;
    }
    let end = this.endSubscript;
    return end //TODO 实际选中文字终点
  }

  //设置节点的文字
  setTextContentInputStyle(data, name, refresh) {

    if (data.isStatisticsContent() || this.contentView == null) {   //是图表就不让出现编辑框
      return;
    };
    if (this.inputView != null && this.inputView.parentNode != null) {
      this.removeInput()
    }
    this.createInput();

    this.inputViewShow = true;
    //TODO span 设置输入框
    this.setShowInput()
    let fontFamily = "";
    if (data != null && data != "") {
      if (name == "titleContent" && data.titleContent != null) {
        fontFamily = data.titleContent.fontFamily
      } else if (data.textContent != null) {
          fontFamily = data.textContent.fontFamily
      } else if (data.generalizationContent != null) {
          fontFamily = data.generalizationContent.fontFamily
      }
    }
    if (fontFamily == null || fontFamily == "") {
        fontFamily = Config.textFontFamily
    }

    this.inputView.style.left = data.x + data[name].x + data[name].contentInsetLeft + 'px'
    if (data.layout == NodeLayoutType.LAYOUT_FORM || data.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {    //如果是表格布局
      let textY = data.y + data[name].y + data[name].contentInsetTop
      let textHeight = data[name].height;
      //如果没有图标且没有图片时
      if ((data.iconElementContents == null || data.iconElementContents.length == 0) &&
        data.imageContent == null) {
        textY = data.y + (data.height - textHeight) / 2 + data[name].contentInsetTop;

      }
      //如果有图标时
      if (data.iconElementContents != null && data.iconElementContents.length > 0) {
        //如果图标为左布局或右布局
        if (data.iconElementContents[0].layoutType == IconLayoutType.LAYOUT_LEFT ||
          data.iconElementContents[0].layoutType == IconLayoutType.LAYOUT_RIGHT) {
          textY = data.y + (data.height - textHeight) / 2 + data[name].contentInsetTop;
        }
      }

      this.inputView.style.top = textY + 'px'
    } else {
      this.inputView.style.top = data.y + data[name].y + data[name].contentInsetTop + 'px'
    }
    this.inputView.style.fontSize = 12 + 'px'  //文字最小为12px，一开始设置为1px在safari浏览器会出现如果节点内容删除完了光标就变为一个1像素的小点了。
    //设置最小宽高,防止出现后面的文字
    this.inputView.style.minWidth = data[name].width - data[name].contentInsetLeft - data[name].contentInsetRight + 'px';
    this.inputView.style.minHeight = data[name].height - data[name].contentInsetTop - data[name].contentInsetBottom + 'px';
    
    // this.inputView.innerText = data[name].text
    let renderHtml = Util.getContentText(data, null, null, null, "titleContent" == name);
    this.inputView.innerHTML = renderHtml;
    //之所以不用renderHtml赋值时因为自己拼接的dom字符串和浏览器拼接的dom字符串不相同，所以要用浏览器拼接的。
    this.oldInputInnerHtml = this.inputView.innerHTML;

    if (data.textContent != null) {
      this.styleList = data.textContent.styleCells
    } else if (data.generalizationContent != null) {
        this.styleList = data.generalizationContent.styleCells
    }

    //固定样式
    // this.inputView.style.lineHeight = "28px"

    this.inputView.style.lineHeight = Config.textLineHeight
    this.inputView.style.whiteSpace = "pre-wrap"
    // this.inputView.style.whiteSpace = "pre-line"
    this.inputView.style.wordBreak = "break-word"
    //最大宽度
    if (data.customWidth != null && data.customWidth > 10) {
      this.inputView.style.maxWidth = data.customWidth + "px"
    } else {
      this.inputView.style.maxWidth = Config.contentMaxWidth + "px"
    }    
    // this.inputView.style.maxWidth = 500 + data[name].contentInsetLeft + data[name].contentInsetRight + "px"
    this.inputView.style.outline = "none"

    //设置文字显示位置。
    let textAlignment = this.getInputTextAlignment(data)
    if (textAlignment == MindElementAlignmentType.MIDDLE) {
        this.inputView.style.textAlign = 'center'
    } else if (textAlignment == MindElementAlignmentType.RIGHT) {
        this.inputView.style.textAlign = 'right'
    } else {
        this.inputView.style.textAlign = 'left'
    }

    let backStr = Colors.getUiColor(data.backgroundColor);    //转化节点颜色
    if (data.backgroundColorAlpha < 1.0) {
      if (data.backgroundColorAlpha < 0.5) {
        backStr = Colors.getUiColor(this.vueThis.DrawingBoardBackgroundColor)
      } else {
        backStr = Colors.getUiColor(data.backgroundColor, data.backgroundColorAlpha)
      }
    }
    if (name == "titleContent" && !Colors.isClear(data.borderColor)) {
      backStr = Colors.getUiColor(data.borderColor);
    }
    if (backStr == 'rgba(255,255,255,0)') {   //如果节点的背景色为透明
      let str = Colors.getUiColor(this.vueThis.DrawingBoardBackgroundColor) //转化背景颜色      
      if (str == 'rgba(255,255,255,0)') { //如果是透明背景就设为白色。
        str = '#fff'    //画布透明背景时的颜色。
      }
      backStr = str
    }
    this.inputView.style.background = backStr;
    

    if (this.delegate.isTemplatePage || this.delegate.isSharePage) {  //禁止在模板或者分享页面编辑
      this.inputView.style.userSelect = 'text'
    } else {
      this.inputView.setAttribute('contenteditable', 'plaintext-only');
    }
    this.inputView.style.fontFamily = fontFamily;//Config.textFontFamily //"SimSun"

    this.inputView.style.color = Colors.getUiColor(data[name].textColor)
    if (data[name].textBold) { //字体是否加粗
      this.inputView.style.fontWeight = Config.textBoldFontWeight
    } else {
      this.inputView.style.fontWeight = Config.textNormalFontWeight
    }

    if (data[name].textItalics) {    //设置为斜体
      this.inputView.style.fontStyle = 'italic'
    } else {
      this.inputView.style.fontStyle = 'normal'
    }

    if (data[name].textStrikethrough) {    //删除线
      this.inputView.style.textDecoration = 'line-through'
    } else {
      this.inputView.style.textDecoration = 'none'
    }

    if (refresh == null) {
      //光标移入最后
      this.selectFocusLast('textArea');
      // 判断节点是否是默认内容
      if (CheckTextIsSelected.isSelected(data[name].text)) {
        setTimeout(() => {
          this.selectFocusLast('textArea');
          this.selectText('textArea');
        }, 50);   
        
      }
      //如果只有一个空格默认选中这个空格。
      if (data[name].text == ' ') {
        this.selectText('textArea');
      }
    } else {
      // var p = document.getElementById('textArea')
      // s = window.getSelection()
      // r = document.createRange();
      // r.setStart(p, 0);
      // r.setEnd(p, 1);
      // s.removeAllRanges();
      // s.addRange(r);
    }

    //如果当前节点内容为0就默认选中第一个span。由于span里面没内容选中不了，所以加了个空格。
    if ((data[name].styleCells == null || data[name].styleCells.length == 0) && (data[name].text == null || data[name].text == "")  ) {
      let s0Span = document.getElementById('s0');
      s0Span.innerHTML = ' '
      this.selectText('s0');
    }

    if (this.delegate.isTemplatePage || this.delegate.isSharePage) {  //在模板或者分享页面可以全选
      this.selectText('textArea');
    }

  }

  //设置摘要输入框的具体细节。
  setInputStyle(data, name) {
    if (data.isStatisticsContent()) {   //是图表就不让出现编辑框
      return;
    };
    this.removeInput();
    this.createInput();

    this.inputViewShow = true;
    //TODO span 设置输入框
    this.setShowInput()
    this.inputView.style.left = data.x + data[name].x + data[name].contentInsetLeft + 'px'
    if (data.layout == NodeLayoutType.LAYOUT_FORM || data.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {    //如果是表格布局
      let textY = data.y + data[name].y + data[name].contentInsetTop
      let textHeight = data[name].height;
      //如果没有图标且没有图片时
      if ((data.iconElementContents == null || data.iconElementContents.length == 0) &&
        data.imageContent == null) {
        textY = data.y + (data.height - textHeight) / 2 + data[name].contentInsetTop;

      }
      //如果有图标时
      if (data.iconElementContents != null && data.iconElementContents.length > 0) {
        //如果图标为左布局或右布局
        if (data.iconElementContents[0].layoutType == IconLayoutType.LAYOUT_LEFT ||
          data.iconElementContents[0].layoutType == IconLayoutType.LAYOUT_RIGHT) {
          textY = data.y + (data.height - textHeight) / 2 + data[name].contentInsetTop;
        }
      }

      this.inputView.style.top = textY + 'px'

    } else {
      
      this.inputView.style.top = data.y + data[name].y + data[name].contentInsetTop + 'px'

    }
    this.inputView.style.fontSize = data[name].textFontSize + 'px'
    //设置最小宽高,防止出现后面的文字
    this.inputView.style.minWidth = data[name].width - data[name].contentInsetLeft - data[name].contentInsetRight + 'px';
    this.inputView.style.minHeight = data[name].height - data[name].contentInsetTop - data[name].contentInsetBottom + 'px';
    // this.inputView.innerText = data[name].text
    let renderHtml =  Util.getContentText(data, null, null, null, name == "titleContent");
    this.inputView.innerHTML = renderHtml;
    this.oldInputInnerHtml = this.inputView.innerHTML;
    
    
    if (data.textContent != null) {
      this.styleList = data.textContent.styleCells
    } else if (data.generalizationContent != null) {
        this.styleList = data.generalizationContent.styleCells
    }

    //固定样式
    // this.inputView.style.lineHeight = "28px"

    this.inputView.style.lineHeight = data[name].textFontSize * (Config.textLineHeight) + 'px'
    this.inputView.style.whiteSpace = "pre-wrap"
    // this.inputView.style.whiteSpace = "pre-line"
    this.inputView.style.wordBreak = "break-word"
    //最大宽度
    
    if (data.customWidth != null && data.customWidth > 10) {
      this.inputView.style.maxWidth = data.customWidth + "px"
    } else {
      this.inputView.style.maxWidth = Config.contentMaxWidth + "px"
    } 
    // this.inputView.style.maxWidth = 500 + data[name].contentInsetLeft + data[name].contentInsetRight + "px"
    this.inputView.style.outline = "none"

    // if (data.backgroundColor == 1) {// 如果节点没有背景就用画布背景色
    //   let str = Colors.getUiColor(this.vueThis.DrawingBoardBackgroundColor)
    //   if (str == 'rgba(255,255,255,0)') { //如果是透明背景就设为灰色。
    //     str = '#ccc'
    //   }
    //   this.inputView.style.background = str;
    // } else {
    //   this.inputView.style.background = Colors.getInputUiColor(data.backgroundColor)
    // }
    let backStr = Colors.getUiColor(data.backgroundColor);    //转化节点颜色
    if (backStr == 'rgba(255,255,255,0)') {   //如果节点的背景色为透明
      let str = Colors.getUiColor(this.vueThis.DrawingBoardBackgroundColor) //转化背景颜色
      if (str == 'rgba(255,255,255,0)') { //如果是透明背景就设为白色。
        str = '#fff'    //画布透明背景时的颜色。
      }
      backStr = str
    }
    this.inputView.style.background = backStr;

    if (this.delegate.isTemplatePage || this.delegate.isSharePage) {  //禁止在模板或者分享页面编辑
      this.inputView.style.userSelect = 'text'
    } else {
      // this.inputView.setAttribute('contenteditable', true);
      this.inputView.setAttribute('contenteditable', 'plaintext-only');
    }
    //   span.style.transform = `scale(${scale})`
    this.inputView.style.fontFamily = Config.textFontFamily //"SimSun"
    // this.inputView.style.letterSpacing = 0;
    this.inputView.style.color = Colors.getUiColor(data[name].textColor)


    if (data[name].textBold) { //字体是否加粗
      this.inputView.style.fontWeight = Config.textBoldFontWeight
    } else {
      this.inputView.style.fontWeight = Config.textNormalFontWeight
    }

    if (data[name].textItalics) {    //设置为斜体
      this.inputView.style.fontStyle = 'italic'
    } else {
      this.inputView.style.fontStyle = 'normal'
    }

    if (data[name].textStrikethrough) {    //删除线
      this.inputView.style.textDecoration = 'line-through'
    } else {
      this.inputView.style.textDecoration = 'none'
    }
    //光标移入最后
    this.selectFocusLast('textArea');
    //判断节点是否是默认内容
    if (CheckTextIsSelected.isSelected(data[name].text)) {
      this.selectText('textArea');
    }

    if (this.delegate.isTemplatePage || this.delegate.isSharePage) {  //在模板或者分享页面可以全选
      this.selectText('textArea');
    }

    
  }

  setSubscriptInputStyle(data) { //设置下标的输入框

    if ( data.isStatisticsContent() || data.layout == NodeLayoutType.LAYOUT_FORM || data.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {   //是图表就不让出现编辑框
      return;
    };
    this.removeInput();
    this.createInput();
    let borderWidth = 1;
    this.inputViewShow = true;
    //TODO span 设置输入框
    this.setShowInput()
    this.inputView.style.backgroundColor = Colors.getUiColor(Colors.white);
    this.inputView.style.fontSize = Config.NodeExplainFontSize + 'px';
    this.inputView.style.whiteSpace = "nowrap";
    this.inputView.style.wordBreak = "break-word";
    this.inputView.style.fontFamily = Config.textFontFamily //"SimSun"

    let topMargin;
    let leftMargin;
    let  size = MindElementCalculation.setWidthCaluleText(data.explain, Config.NodeExplainFontSize, false , data.width);
    if (data.layout == NodeLayoutType.LAYOUT_TRIANGLE) {
      if (Colors.isClear(data.borderColor)) {
          topMargin = data.height - Config.NodeExplainTop - size.getHeight() - Config.NodeExplainPadding;
      } else {
          topMargin = data.height - Config.NodeExplainTop - size.getHeight() - Config.NodeExplainPadding - borderWidth;
      }
    } else {
        if (Colors.isClear(data.borderColor)) {
            topMargin = data.height + Config.NodeExplainTop + Config.NodeExplainPadding;
        } else {
            topMargin = data.height + Config.NodeExplainTop + Config.NodeExplainPadding + borderWidth;
        }
    }


    if (data.mindElementShape == MindElementShapeType.Circular ||
      data.mindElementShape == MindElementShapeType.Diamond ||
      data.mindElementShape == MindElementShapeType.InvertedTriangle) {
      leftMargin = (data.width - size.getWidth()) / 2 ;
    } else {
        leftMargin = data.layout == NodeLayoutType.LAYOUT_TRIANGLE ? this.UiUtil.dip2px(20) : 0;
    }

    this.inputView.style.left = data.x + Config.NodeExplainPadding + borderWidth + leftMargin + 'px';
    this.inputView.style.top = data.y + topMargin  + 'px';


    
    //设置最小宽高,防止出现后面的文字
    this.inputView.style.minWidth = size.getWidth() + 'px';
    this.inputView.style.minHeight = size.getHeight() + 'px';


    this.inputView.innerText = data.explain;
    this.oldInputInnerHtml = this.inputView.innerHTML;
        // //判断节点是否是默认内容
        // if (CheckTextIsSelected.isSelected(data[name].text)) {
        //   this.selectText('textArea');
        // }
    if (this.delegate.isTemplatePage || this.delegate.isSharePage) {  //禁止在模板或者分享页面编辑
      this.inputView.style.userSelect = 'text'
    } else {
      this.inputView.setAttribute('contenteditable', true);
    }
    this.inputView.style.outline = "none"
    //光标移入最后
    this.selectFocusLast('textArea');
  }

  getInputTextAlignment(data) {   //获取当前的文字显示位置。
    if (data.mindElementShape == MindElementShapeType.Circular ||
        data.mindElementShape == MindElementShapeType.Diamond ||
        data.mindElementShape == MindElementShapeType.Triangle ||
        data.mindElementShape == MindElementShapeType.InvertedTriangle ||
        data.mindElementShape == MindElementShapeType.LeftTriangle||
        data.mindElementShape == MindElementShapeType.RightTriangle ||
        data.mindElementShape == MindElementShapeType.Gemstone ||
        data.mindElementShape == MindElementShapeType.Heart||
        data.mindElementShape == MindElementShapeType.Octagonal ||
        data.mindElementShape == MindElementShapeType.Regular_hexagon||
        data.mindElementShape == MindElementShapeType.Book||
        data.mindElementShape == MindElementShapeType.Shield ||
        data.layout == NodeLayoutType.LAYOUT_TRIANGLE ||
        data.alignmentType == MindElementAlignmentType.MIDDLE) {
          // console.log(data.mindElementShape);
        return MindElementAlignmentType.MIDDLE;
    } else {
        return data.alignmentType == MindElementAlignmentType.UNKNOWN ? MindElementAlignmentType.LEFT : data.alignmentType;
    }
  }


  clearInputStyle() {
    this.inputView.style.border = '';
    this.inputView.style.borderRadius = '';
    this.inputView.style.textAlign = '';
  }

  //设置包裹线/关联线输入框
  setLineTextInputStyle(data, name, clickIcon) {
    // console.log(data);
    this.removeInput();
    this.createInput();

    this.inputViewShow = true;
    //TODO span 设置输入框
    if (data[name].textContent != undefined) {
      this.setShowInput()
      this.inputView.style.left = data.x + data[name].textContent.x + data[name].textContent.contentInsetLeft + 'px'
      this.inputView.style.top = data.y + data[name].textContent.y + data[name].textContent.contentInsetTop + 'px'
      if(clickIcon == "clickIcon"){
        this.inputView.style.top = data.y + data[name].textContent.y + data[name].textContent.contentInsetTop - 30 + 'px'
        this.inputView.style.left = data.x + data[name].textContent.x + data[name].textContent.contentInsetLeft - 15 + 'px'
      }
      this.inputView.style.fontSize = data[name].textContent.textFontSize + 'px'
      //设置最小宽高,防止出现后面的文字
      this.inputView.style.minWidth = data[name].textContent.width - data[name].textContent.contentInsetLeft - data[name].textContent.contentInsetRight + 'px';
      this.inputView.style.minHeight = data[name].textContent.height - data[name].textContent.contentInsetTop - data[name].textContent.contentInsetBottom + 'px';
      this.inputView.innerText = data[name].textContent.text
      this.oldInputInnerHtml = this.inputView.innerHTML
      // //固定样式
      this.inputView.style.lineHeight = data[name].textContent.height - data[name].textContent.contentInsetTop - data[name].textContent.contentInsetBottom + 'px'
      if (data.type == MindElementType.NODE_CONNECT_LINE) {
        let backStr = Colors.getUiColor(this.vueThis.DrawingBoardBackgroundColor) //转化背景颜色
        if (backStr == 'rgba(255,255,255,0)') { //如果是透明背景就设为白色。
          backStr = '#fff'    //画布透明背景时的颜色。
        }
        this.inputView.style.background = backStr;
      } else {
        let backStr = Colors.getUiColor(data[name].strokeColor);    //转化节点颜色
        if (backStr == 'rgba(255,255,255,0)') {   //如果节点的背景色为透明
          let str = Colors.getUiColor(this.vueThis.DrawingBoardBackgroundColor) //转化背景颜色
          if (str == 'rgba(255,255,255,0)') { //如果是透明背景就设为白色。
            str = '#fff'    //画布透明背景时的颜色。
          }
          backStr = str
        }
        this.inputView.style.background = backStr;
      }      
    } else {
      this.setShowInput()
      this.inputView.style.left = data.x + 'px'
      this.inputView.style.top = data.y + 'px'
      if(clickIcon == "clickIcon"){
        this.inputView.style.left = data.x - 15 + 'px'
        this.inputView.style.top = data.y - 31 + 'px'
      }
      this.inputView.style.fontSize = Config.normalFontSize + 'px'
      //设置最小宽高,防止出现后面的文字
      this.inputView.style.minWidth = 50 + 'px';
      this.inputView.style.minHeight = 24 + 'px';
      this.inputView.innerText = ''
      // //固定样式
      this.inputView.style.lineHeight = 24 + 'px';
      this.inputView.style.background = '#fff';
      this.inputView.style.border = '1px solid #eee';
      this.inputView.style.borderRadius = '4px';
    }
    this.inputView.style.textAlign = 'center';

    // this.inputView.style.whiteSpace = "pre-wrap"
    this.inputView.style.whiteSpace = 'nowrap';
    // this.inputView.style.wordBreak = "break-word"
    // //最大宽度
    this.inputView.style.maxWidth = Config.contentMaxWidth + "px"
    this.inputView.style.outline = "none"   //下划线


    if (this.delegate.isTemplatePage || this.delegate.isSharePage) {  //禁止在模板或者分享页面编辑,但是可以复制
      this.inputView.style.userSelect = 'text'
    } else {
      this.inputView.setAttribute('contenteditable', true);
    }
    //   span.style.transform = `scale(${scale})`
    this.inputView.style.fontFamily = Config.textFontFamily //"SimSun"
    // this.inputView.style.letterSpacing = 0;

    if (data[name].textContent != undefined) {
      this.inputView.style.color = Colors.getUiColor(data[name].textContent.textColor)
      if (data[name].textContent.textBold) { //字体是否加粗
        this.inputView.style.fontWeight = Config.textBoldFontWeight
      } else {
        this.inputView.style.fontWeight = Config.textNormalFontWeight
      }
      if (data[name].textContent.textItalics) {    //设置为斜体
        this.inputView.style.fontStyle = 'italic'
      } else {
        this.inputView.style.fontStyle = 'normal'
      }
      if (data[name].textContent.textStrikethrough) {    //删除线
        this.inputView.style.textDecoration = 'line-through'
      } else {
        this.inputView.style.textDecoration = 'none'
      }
    } else {
      this.inputView.style.color = '#333';
    }
    //判断节点是否是默认内容
    if (data[name].textContent != undefined && CheckTextIsSelected.isSelected(data[name].textContent.text)) {
      this.selectText('textArea');
    }else{
      //光标移入最后
      this.selectFocusLastWrappingLine('textArea');
    }

    if (this.delegate.isTemplatePage || this.delegate.isSharePage) {  //在模板或者分享页面可以全选
      this.selectText('textArea');
    }
  }

  getInnerHTMLValue(innerHTML, innerText) {
    return new InnerHTMLParse(innerHTML, innerText).getInnerText(false);
  }

  hideAddChildNodeButton() {
    if (this.mindChildshowButton == null) {
      return;
    }
    this.mindChildshowButton.hideDom();
  }

  //隐藏输入框
  hideInput() {
    if (!this.isShowInput()) {
      return
    }
    //设置文本字体样式
    let text = this.getInnerHTMLValue(this.inputView.innerHTML, this.inputView.innerText);
    let textHtml = this.inputView.innerHTML;
    let lineText = this.inputView.innerText //new Strings().removeLineFeed(text);
    //底下这个方法会重新设置新的innerHTML
    if (this.selectData != undefined && this.selectData.textContent != null && this.oldInputInnerHtml != textHtml) {    //避免在其它输入框时候调用
      this.setMonitorDivContent()
    }
    //隐藏输入框时清空数据
    this.monitorIndex = 0;
    this.inputBoxIndex = 0;
    this.startSubscript = -1; //鼠标选中文本的开始位置
    this.startSubscriptLabel = -1; //鼠标选中标签的开始位置
    this.endSubscript = -1; //鼠标选中文本的结束位置
    this.endSubscriptLabel = -1; //鼠标选中标签的开始位置
    this.objTextArea = []; //当前输入框里面的对象
    this.selectedTextContent =[];//选中的内容
    this.firstSpanId = -1;
    this.endSpanId = -1;
    this.styleList = new Array();
    this.textFontSize = 18;
    this.localNameLabel = "";
    this.spanSelectColor = [];
    this.typeNode = "";

    this.delegate.showFontStyleMenu = false;
    //去除输入框
    this.inputView.removeEventListener('keydown',this.tabFunction);
    this.inputViewShow = false;
    //如果节点内容未改变。
    if (this.oldInputInnerHtml == textHtml) {
      this.inputView.innerText = this.defaultStr
      this.setHideInput()
      this.vueThis.refreshNodeText(text, this.selectData.id)
      return;
    }
    this.setHideInput()
    if (this.inputView.innerText == this.defaultStr) {    //如果是默认字符串就不允许改值
      return
    }
    let sizeObj;
    
    if (this.selectData != undefined && this.selectData.textContent != null) {  //为正常节点时
      if (this.isEditCardTitle && this.selectData.isCardContent()) {//卡片标题编辑状态
        sizeObj = MindElementCalculation.caluleText(textHtml, this.selectData.titleContent.textFontSize, this.selectData.titleContent.textBold);
        if (sizeObj != null) {
          this.vueThis.onTextSizeChanged(text, sizeObj.width, sizeObj.height, this.selectData.id, GlobalInputTextType.NodeTitleInput)
        }
        //清空输入框，否则如果编辑框输入了内容，撤回的时候再次双击节点触发编辑框会把之前编辑框的内容赋值到节点内。
        this.inputView.innerText = this.defaultStr
        //下标编辑状态清空。
        this.isEditSubscript = false;
        this.isEditCardTitle = false;
        return
      } else if ( this.isEditSubscript ) { //下标编辑状态
        this.vueThis.onTextSizeChanged(text, null, null, this.selectData.id, GlobalInputTextType.NodeExplain)
        //清空输入框，否则如果编辑框输入了内容，撤回的时候再次双击节点触发编辑框会把之前编辑框的内容赋值到节点内。
        this.inputView.innerText = this.defaultStr
        //下标编辑状态清空。
        this.isEditSubscript = false;
        this.isEditCardTitle = false;
        return
      } else { //为正常节点时
        sizeObj = MindElementCalculation.caluleText(textHtml, this.selectData.textContent.textFontSize, this.selectData.textContent.textBold);
        if (sizeObj != null) {
          this.vueThis.onTextSizeChanged(text, sizeObj.width, sizeObj.height, this.selectData.id, GlobalInputTextType.NodeInput)
        }
        //清空输入框，否则如果编辑框输入了内容，撤回的时候再次双击节点触发编辑框会把之前编辑框的内容赋值到节点内。
        this.inputView.innerText = this.defaultStr
        //下标编辑状态清空。
        this.isEditSubscript = false;
        this.isEditCardTitle = false;
        return
      }
    }

    if (this.selectData != undefined && this.selectData.generalizationContent != null) {   //为摘要节点时
      sizeObj = MindElementCalculation.caluleText(text, this.selectData.generalizationContent.textFontSize, this.selectData.generalizationContent.bold);
      if (sizeObj != null) {
        this.vueThis.onTextSizeChanged(text, sizeObj.width, sizeObj.height, this.selectData.id, GlobalInputTextType.NodeInput)
      }
      //清空输入框，否则如果编辑框输入了内容，撤回的时候再次双击节点触发编辑框会把之前编辑框的内容赋值到节点内。
      this.inputView.innerText = this.defaultStr
      //下标编辑状态清空。
      this.isEditSubscript = false;
      this.isEditCardTitle = false;
      return
    }

    if (this.selectData != undefined && this.selectData.lineContent != null && 
      (this.selectData.type == MindElementType.BAOWEI_VIEW || this.selectData.type == MindElementType.NODE_CONNECT_LINE)) {
      
        if (this.selectData.type == MindElementType.NODE_CONNECT_LINE) {  //线条的备注
        sizeObj = MindElementCalculation.setWidthCaluleText(lineText, this.selectData.lineContent.textContent.textFontSize, this.selectData.lineContent.textContent.bold);
        if (sizeObj != null) {
          this.vueThis.onTextSizeChanged(lineText, sizeObj.width, sizeObj.height, this.selectData.id, this.selectData.type)
        }
        //清空输入框，否则如果编辑框输入了内容，撤回的时候再次双击节点触发编辑框会把之前编辑框的内容赋值到节点内。
        this.inputView.innerText = this.defaultStr
        //下标编辑状态清空。
        this.isEditSubscript = false;
        this.isEditCardTitle = false;
        return
      } else if(this.selectData.type == MindElementType.BAOWEI_VIEW) {  //包裹线的标题。
        sizeObj = MindElementCalculation.caluleText(lineText, Config.normalFontSize, false);
        this.vueThis.onTextSizeChanged(lineText, sizeObj.width, sizeObj.height, this.selectData.id, this.selectData.type)
        //清空输入框，否则如果编辑框输入了内容，撤回的时候再次双击节点触发编辑框会把之前编辑框的内容赋值到节点内。
        this.inputView.innerText = this.defaultStr
        //下标编辑状态清空。
        this.isEditSubscript = false;
        this.isEditCardTitle = false;
        return
      }
    }
    if (sizeObj != null) {
      this.vueThis.onTextSizeChanged(text, sizeObj.width, sizeObj.height, this.selectData.id)
    }
    //清空输入框，否则如果编辑框输入了内容，撤回的时候再次双击节点触发编辑框会把之前编辑框的内容赋值到节点内。
    this.inputView.innerText = this.defaultStr
    //下标编辑状态清空。
    this.isEditSubscript = false;
    this.isEditCardTitle = false;
  }

  addView() {

  }

  getContentWidth() {
    return this.width;
  }

  getContentHeight() {
    return this.height;
  }

  scrollTo(x, y) {
    if (this.moveAnimationInterval != -1) {
      clearInterval(this.moveAnimationInterval)
    }
    
    this.root.scrollLeft = x;
    this.root.scrollTop = y;
    this.boundaryHandler()
    this.setMobolOpenAppTextPoint();
  }

  //设置大小，并且按照当前画布中心缩小。
  setScale(scale) {
    let parentWidth = this.UiUtil.getScreenWidth();
    let parentHeight = this.UiUtil.getScreenHeight();
    let mapWidth = this.getViewPxValue(this.contentView.offsetWidth);
    let mapHeight = this.getViewPxValue(this.contentView.offsetHeight);
    let selfConterPoint = new Point(parentWidth / 2, parentHeight / 2);
    let conterPoint = this.getSelfConterPoint();


    if (scale > this.maxScale) {
      scale = this.maxScale;
    }
    if (scale < this.minScale) {
      scale = this.minScale;
    }
    this.scale = scale;
    this.contentView.style.transform = "scale(" + scale + ")";
    this.contentView.style.transformOrigin = "0 0"

    let widthChange = mapWidth * (scale - 1);
    let heightChange = mapHeight * (scale - 1);

    let left = conterPoint.x * scale - parentWidth / 2;
    let top = conterPoint.y * scale - parentHeight / 2;
    this.scrollTo(left, top);
    this.boundaryHandler()
  }

  getSelfConterPoint() {
    let parentWidth = this.UiUtil.getScreenWidth();
    let parentHeight = this.UiUtil.getScreenHeight();
    let contentScrollX = this.getContentScrollX();
    let contentScrollY = this.getContentScrollY();

    let eventX = parentWidth / 2;
    let eventY = parentHeight / 2;

    let contentX = contentScrollX / this.getScale();
    let contentY = contentScrollY / this.getScale();
    let x = eventX / this.getScale() + contentX;
    let y = eventY / this.getScale() + contentY;
    return new Point(x, y);
  }

  getScale() {
    return this.scale;
  }

  constrainScrollBy(dx, dy) {
    let translationX = this.getContentScrollX();
    let translationY = this.getContentScrollY();
    this.scrollTo(translationX + dx, translationY + dy);
    this.boundaryHandler();
  }

  getContentX() {
    return this.getViewPxValue(this.contentView.style.left);
  }

  getContentY() {
    return this.getViewPxValue(this.contentView.style.top);
  }

  getContentScrollX() {
    return this.root.scrollLeft
  }

  getContentScrollY() {
    return this.root.scrollTop;
  }

  setBackgroundColor(color) {
    this.root.style.background = Colors.getUiColor(color);
    this.contentView.style.background = Colors.getUiColor(Colors.clear);
  }

  setSize(width, height) {
    this.width = width;
    this.height = height;

    this.contentView.style.width = this.width + "px";
    this.contentView.style.height = this.height + "px";

    this.contentViewSvg.style.width = this.width + "px";
    this.contentViewSvg.style.height = this.height + "px";

    this.lineViewSvg.style.width = this.width + "px";
    this.lineViewSvg.style.height = this.height + "px";

    this.baseViewSvg.style.width = this.width + "px";
    this.baseViewSvg.style.height = this.height + "px";

    this.watermarkViewSvg.style.width = this.width + "px";
    this.watermarkViewSvg.style.height = this.height + "px";
  }

  //取消当前选中的节点。
  cancelCheckNode() {
    //如果有输入框去除输入框
    if (this.inputViewShow) {
      this.hideInput();
    }
    //隐藏所有菜单
    this.allHiddenMenu()
    // // 去除所有选择节点
    this.delegate.clearAllSelectedView()
  }

  getScrollPointByClentPoint(clientX, clientY) {
    if (this.root == null || this.contentView == null) {
      return new Point(0, 0)
    }
    var rootRect = this.root.getBoundingClientRect()

    var rootClientX = rootRect.left - this.root.scrollLeft;
    var rootClientY = rootRect.top - this.root.scrollTop;

    // let left = this.getViewPxValue(this.contentView.style.left)
    // let top = this.getViewPxValue(this.contentView.style.top)
    // let contentWidth = this.getViewPxValue(this.contentView.style.width);
    // let contentHeight = this.getViewPxValue(this.contentView.style.height);
    // let eventX = (clientX - rootClientX) / this.scale;
    // let eventY = (clientY - rootClientY) / this.scale;
    // let widthChange = (contentWidth) * (this.scale - 1);
    // let heightChange = (contentHeight) * (this.scale - 1);
    // let contentX = Math.abs(left - widthChange / 2) / this.scale;
    // let contentY = Math.abs(top - heightChange / 2) / this.scale;
    // let clickPointX = eventX + contentX;
    // let clickPointY = eventY + contentY;

    let left = this.getContentScrollX()
    let top = this.getContentScrollY()
    let eventX = (clientX - rootClientX) / this.scale;
    let eventY = (clientY - rootClientY) / this.scale;
    let contentX = left / this.scale;
    let contentY = top / this.scale;
    let clickPointX = eventX + contentX;
    let clickPointY = eventY + contentY;

    return new Point(clickPointX, clickPointY)
  }
}

export default ScrollView