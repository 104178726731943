


let HandwritingType = {
    FREE_WRITE:1,
    STRAIGHT_LINE:2,
    ARROW:3,
    RECTANGLE:4,
    CIRCULAR:5,

    from:function(value) {
        for(let mealName of Object.values(this)){
            if(mealName == value){
                return value;
            }
        }
        return this.FREE_WRITE;
    }
}
export default HandwritingType