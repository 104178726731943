<template>
  <!--  连接线  -->
  <div id="connecting-line" ref="connectingLine" class="ComplieHeaderImg">
    <div class="complie-header-imgbox">
      <div
        class="complie-com-box"
        v-for="(item, index) in imgDataList"
        :key="index"
        :class="index == imgDataList.length - 1 ? 'complie-com-box-last' : ''"
        @click="setImgDataList(index, item.lineType)"
      >
        <div class="complie-left-icon">
          <img v-if="!darkMode" :src="item.imgWhite" alt="" />
          <img v-else :src="item.imgData" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import ConnectLineType from "../../../viewmodel/datatype/ConnectLineType";
export default {
  props: ["darkMode"],
  data() {
    return {
      imgDataList: [
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/joinCurve.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/joinCurveWhite.svg"),
          lineType: ConnectLineType.CURVE_LINE,
        },
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/joinStraight.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/joinStraightWhite.svg"),
          lineType: ConnectLineType.STRAIGHT_ARROW_LINE,
        },
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/joinZigZag.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/joinZigZagWhite.svg"),
          lineType: ConnectLineType.RIGHT_ANGLE_LINE,
        },
        {
          imgData: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/joinDotStraight.svg"),
          imgWhite: require("../../../assets/img/mapIcon/res/mipmap-icon/mipmap-header-icon/darkModeIcon/joinDotStraightWhite.svg"),
          lineType: ConnectLineType.STRAIGHT_CIRCULAR_LINE,
        },
      ],
      imgDataAccout: 0,
    };
  },
  mounted() {
    this.setDardarkMode();
  },
  methods: {
    ...mapMutations(["getLineTypeLayout"]),
    setImgDataList(index, type) {
      this.imgDataAccout = index;
      this.getLineTypeLayout(type);
    },
    setDardarkMode() {
      // 设置深色模式
      this.$nextTick(() => {
        let boxDom = this.$refs.connectingLine;
        let dardarkMode = this.$store.state.mapEditIsDardarkMode;
        if (boxDom) {
          if (!dardarkMode.darkMode) {
            boxDom.style.color = "#fff";//dardarkMode.fontColor;
            boxDom.style.background = "#3E3E3E"//dardarkMode.bgColor;
          } else {
            boxDom.style.color = "#333";
            boxDom.style.background = "#fff";
          }
        }
      });
    },
  },
  watch: {
    "$store.state.mapEditIsDardarkMode": {
      //导图编辑页面是否为深色模式
      handler(newVal, oldVal) {
        this.setDardarkMode();
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
.ComplieHeaderImg {
  width: 198px;
  height: 193px;
  background: #ffffff;
  border: 1px solid var(--exportMenuDashedColor);
  opacity: 1;
  border-radius: 8px;
  position: absolute;
  right: 0;
  top: 10px;
  z-index: 9999;
}
.complie-header-imgbox {
  margin: 7px 25px;
}
.complie-com-box {
  width: 138px;
  height: 44px;
  border-bottom: 1px dashed var(--exportMenuDashedColor);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: var(--exportMenuBg);
  }
}
.complie-com-box-last {
  border-bottom: transparent;
}
.complie-left-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 6px;
}
</style>
