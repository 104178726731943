// package mind.yushu.com.mindmap.core.dataformat;

// import com.google.gson.Gson;

// import java.util.ArrayList;
// import java.util.HashMap;
// import java.util.List;

// import mind.yushu.com.mindmap.core.basemode.GsonDataTypeAdaptor;
// import mind.yushu.com.mindmap.core.calcule.Config;
// import mind.yushu.com.mindmap.utils.Colors;
// import mind.yushu.com.mindmap.viewmodel.datatype.DeviceType;
// import mind.yushu.com.mindmap.viewmodel.datatype.LineLayout;
// import mind.yushu.com.mindmap.viewmodel.datatype.MindBGStripesType;
// import mind.yushu.com.mindmap.viewmodel.datatype.MindType;
// import mind.yushu.com.mindmap.viewmodel.datatype.NodeLayoutType;
// import mind.yushu.com.mindmap.viewmodel.mindelementdata.LineMindTypeNode;
// import mind.yushu.com.mindmap.viewmodel.mindelementdata.MindElementData;
import MindBaseFormat from "./MindBaseFormat"
import LineLayout from "../../../viewmodel/datatype/LineLayout";
import NodeLayoutType from "../../../viewmodel/datatype/NodeLayoutType";
import Colors from "../../../utils/Colors";
import MindType from "../../../viewmodel/datatype/MindType";
import MindBGStripesType from "../../../viewmodel/datatype/MindBGStripesType"
import LineMindTypeNode from "../../../viewmodel/mindelementdata/LineMindTypeNode"
import MindElementData from "../../../viewmodel/mindelementdata/MindElementData"
import MindFormatCheck from "./MindFormatCheck";
import Config from "../calcule/Config";
import HashMap from "../../../viewmodel/core/base/HashMap";
import DeviceType from "../../../viewmodel/datatype/DeviceType";
import MindDateFormat from "./MindDateFormat";
import { getMachineId } from "../../../common/netWork/base";
import SettingData from "../../../viewmodel/core/minddata/SettingData";
import BoldData from "../../../viewmodel/mindelementdata/mindcontent/BoldData";
import LatexContent from "../../../viewmodel/mindelementdata/mindcontent/LatexContent";
/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/24
 * Copyright(c) 2020 mindyushu.com
 */

class DoubleBubbleMindDateFormat extends MindBaseFormat {

    constructor() {
        super();
        this.rootNode; //主体树状数据结构
        this.rootLine = new Array(); //主体线
        this.rootTreeBrotherNode; //主体兄弟节点数据结构
        this.commonGroundMindElementDatas = new Array();//两个主节点的共同子节点
        this.globalLineLayout = LineLayout.CURVE_LINE;
        this.globalLayout = NodeLayoutType.LAYOUT_RIGHT;
        this.nodeConnectLinet = new Array(); //节点连接线
        this.freeNodes = new Array();//自由节点
        this.mindType = MindType.LINE_MAP;
        this.mindBGColor = Colors.white;
        this.settingData = new SettingData();
        this.mindBGStripesType = MindBGStripesType.NonExistent;
    }

    setDatas(rootNode,
        rootLine,
        freeNodes,
        rootTreeBrotherNode,
        commonGroundMindElementDatas) {
        this.rootNode = rootNode;
        this.rootLine = rootLine;
        this.freeNodes = freeNodes;
        this.rootTreeBrotherNode = rootTreeBrotherNode;
        this.commonGroundMindElementDatas = commonGroundMindElementDatas;
        this.uuid = getMachineId();
        return this;
    }

    setLayout(globalLayout, globalLineLayout) {
        this.globalLayout = globalLayout;
        this.globalLineLayout = globalLineLayout;
        return this;
    }

    toJSONString() {
        this.deviceType = DeviceType.WEB;
        this.nodeConnectLineControlPointMap = {};
        this.mindOriginDataRelativeRootInfo = {};
        this.freeNodes.forEach(unit => {
            unit.nodeConnectLineControlPointMap = {};
            unit.mindOriginDataRelativeRootInfo = {};
        });
        let nodeStr = JSON.stringify(this);
        return nodeStr;
    }

    format(vaule, originalDataMap) {
        let mind = JSON.parse(vaule)
        let mindDateFormat = this.deserializationData(mind)
        if (mindDateFormat != null && mindDateFormat.rootNode != null) {
            this.checkPoint(mindDateFormat.rootNode);
            mindDateFormat.freeNodes.forEach(item => {
                if (item.rootNode != null) {
                    this.checkPoint(item.rootNode);
                }
            });
            mindDateFormat.checkDate(originalDataMap);
            return mindDateFormat;
        }
        return new DoubleBubbleMindDateFormat();
    }

    setNodeToDictStructure(checkData, node) {
        this.resetDataInCheckData(checkData, node.value);
        node.children.forEach(child => {
            this.setNodeToDictStructure(checkData, child);
        });
    }

    resetDataInCheckData(checkData, data) {
        checkData.checkData(data);
    }

    resetData(checkData) {
        this.setNodeToDictStructure(checkData, this.rootNode);
        this.setNodeToDictStructure(checkData, this.rootTreeBrotherNode);

        this.rootLine.forEach(line => {
            this.resetDataInCheckData(checkData, line);
        });

        this.commonGroundMindElementDatas.forEach(data => {
            this.resetDataInCheckData(checkData, data);
        });

        this.freeNodes.forEach(unit => {
            unit.rootLine.forEach(line => {
                this.resetDataInCheckData(checkData, line);
            });

            unit.generalizationLine.forEach(line => {
                this.resetDataInCheckData(checkData, line);
            });

            unit.generalizationNode.forEach(node => {
                this.resetDataInCheckData(checkData, node.value);
            });

            unit.encircle.forEach(line => {
                this.resetDataInCheckData(checkData, line);
            });
            unit.explainData.forEach(data => {
                this.resetDataInCheckData(checkData, data);
            });
        });
        this.nodeConnectLinet.forEach(line => {
            this.resetDataInCheckData(checkData, line);
        });
    }

    checkDate(originalDataMap) {
        if (this.deviceType == DeviceType.UNKNOWN) {
            this.deviceDensity = 2;
        }
        let checkData = new MindFormatCheck(this.deviceType, this.deviceDensity, this.rootNode, 
            this.nodeConnectLinet, this.version, this.freeNodes, this.uuid, this.settingData, originalDataMap);
        checkData.setRootLine(this.rootLine);
        checkData.setIsOriginCutValue(true);
        this.resetData(checkData);
        this.nodeConnectLineControlPointMap = checkData.nodeConnectLineControlPointMap;
        this.mindOriginDataRelativeRootInfo = checkData.mindOriginDataRelativeRootInfo;

        checkData.setIsOriginCutValue(false);
        this.resetData(checkData);
    }

    checkPoint(node) {
    }

    getMindDateFormat() {
        return new MindDateFormat();
    }

    deserializationDoubleBubbleMindDateFormat(mind) {
        let data = new DoubleBubbleMindDateFormat();
        for (var key in mind) {
            let keyValue = mind[key]
            data[key] = keyValue
            if (key == "rootNode") {
                let rootNode = this.deserializationLineMindTypeNode(keyValue)
                data[key] = rootNode
            } else if (key == "rootLine") {
                let rootLines = new Array();
                let rootLinesSize = keyValue.length
                for (let index = 0; index < rootLinesSize; index++) {
                    rootLines.push(this.deserializationLineMindElementData(keyValue[index]))
                }
                data[key] = rootLines
            } else if (key == "rootTreeBrotherNode") {
                let rootTreeBrotherNode = this.deserializationLineMindTypeNode(keyValue)
                data[key] = rootTreeBrotherNode
            } else if (key == "nodeConnectLinet") {
                let nodeConnectLinet = new Array();
                let nodeConnectLinetSize = keyValue.length
                for (let index = 0; index < nodeConnectLinetSize; index++) {
                    nodeConnectLinet.push(this.deserializationLineMindElementData(keyValue[index]))
                }
                data[key] = nodeConnectLinet
            } else if (key == "commonGroundMindElementDatas") {
                let commonGroundMindElementDatas = new Array();
                let commonGroundMindElementDatasSize = keyValue.length
                for (let index = 0; index < commonGroundMindElementDatasSize; index++) {
                    commonGroundMindElementDatas.push(this.deserializationLineMindElementData(keyValue[index]))
                }
                data[key] = commonGroundMindElementDatas
            } else if (key == "latexContent" && keyValue != null) {
                let latexContent = new LatexContent();
                for (var latexContentKey in keyValue) {
                    let latexContentValue = keyValue[latexContentKey];
                    latexContent[latexContentKey] = latexContentValue;

                }
                data[key] = latexContent
            } else if ((key == "globalLineLayout" ||
                key == "globalLayout" ||
                key == "mindType" ||
                key == "mindBGStripesType") && keyValue != null && typeof keyValue == "string") {
                data[key] = parseInt(keyValue)
            } else if (key == "settingData" && keyValue != null) {
                let settingData = new SettingData()
                let settingDataValue = keyValue
                for (var keyDataValueKey in settingDataValue) {
                    if (keyDataValueKey == "freeNodeMoveAdsorbent" || 
                        keyDataValueKey == "branchNodeFreelayout") {
                            let  boldData = new BoldData()
                            let boldDataValue = settingDataValue[keyDataValueKey]
                            for (var boldDataKey in boldDataValue) {
                                boldData[boldDataKey] = boldDataValue[boldDataKey]
                            }
                            settingData[keyDataValueKey] = boldData
                    } else {
                        settingData[keyDataValueKey] = settingDataValue[keyDataValueKey]
                    }
                }
                data[key] = settingData
            } else if (key == "explainData") {
                let explainData = new Array();
                let explainDataSize = keyValue.length
                for (let index = 0; index < explainDataSize; index++) {
                    explainData.push(this.deserializationLineMindElementData(keyValue[index]))
                }
                data[key] = explainData
            }
        }
        return data
    }

    deserializationData(mind) {
        let data = this.deserializationDoubleBubbleMindDateFormat(mind);
        let freeNodes = this.deserializationFreeNodes(mind)
        data.freeNodes = freeNodes;
        return data
    }
}
export default DoubleBubbleMindDateFormat
