import strings from "../../../../common/lang/strings"
import getString from "../../../../common/lang/language";
import { AlignmentType, Document, Packer, Paragraph, TextRun } from "docx";
class ExportFile {
    constructor(delegate) {
        this.delegate = delegate;
    }

    format(data) {
        if (data.length == 0) {
            this.delegate.$message.error(getString(strings.Export_Failed));
            return ""
        }
        let title = data[0];
        data.shift();
        let str = '';
        let dataLength = data.length;
        for (let index = 0; index < dataLength; index++) {
            const txt = data[index];
            str += txt + '\r\n'
        }
        let allStr = title + '\r\n' + str;
        return {
            title,
            allStr
        }
    }

    // word文件导出
    wordExport(data) {
        let firstData = data[0];
        let title = "";
        if (firstData.text != null) {
            title = firstData.text + '.docx';
        } else {
            title = firstData + '.docx';
        }
        
        // let blob = new Blob([allStr],
        // { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document;charset=UTF-8' }); //application/vnd.openxmlformats-officedocument.wordprocessingml.document这里表示docx类型
        // { type: 'application/msword;charset=utf-8' }); //application/msword这里表示doc类型
        let paragraphList = []
        let dataLength = data.length;
        for (let index = 0; index < dataLength; index++) {
            const dataCell = data[index];
            if (dataCell.text != null) {
                const cell = new Paragraph({
                    alignment: dataCell.alignment,
                    children: [
                        new TextRun({
                            text: dataCell.text,
                            bold: dataCell.bold,
                            size: dataCell.size,
                            color: dataCell.color,
                          })
                    ],
                })
                paragraphList.push(cell)
            } else {
                const cell = new Paragraph({
                    children: [
                        new TextRun(dataCell)
                    ],
                })
                paragraphList.push(cell)
            }            
        } 
        const doc = new Document({
            sections: [{
                properties: {},
                children: paragraphList,
            }],
        });
        Packer.toBlob(doc).then(blob => {
            if (navigator.msSaveBlob) {//兼容IE浏览器
                return navigator.msSaveBlob(blob, title)
            }
            let downloadElement = document.createElement('a');
            let href = window.URL.createObjectURL(blob); //创建下载的链接
            downloadElement.href = href;
            downloadElement.download = title; //下载后文件名
            document.body.appendChild(downloadElement);
            downloadElement.click(); //点击下载
            document.body.removeChild(downloadElement); //下载完成移除元素
            window.URL.revokeObjectURL(href); //释放掉blob对象
        })
    }

    // txt文件导出
    txtExport(data) {
        let { allStr, title } = this.format(data);
        title += '.txt';
        let export_blob = new Blob([allStr]);
        if (navigator.msSaveBlob) {//兼容IE浏览器
            return navigator.msSaveBlob(export_blob, title)
        }
        let save_link = document.createElement("a");
        save_link.href = window.URL.createObjectURL(export_blob);
        save_link.download = title;
        this.fakeClick(save_link);
    }

    fakeClick(obj) {
        let ev = document.createEvent("MouseEvents");
        ev.initMouseEvent(
            "click",
            true,
            false,
            window,
            0,
            0,
            0,
            0,
            0,
            false,
            false,
            false,
            false,
            0,
            null
        );
        obj.dispatchEvent(ev);
    }
}
export default ExportFile