import Colors from "../../../utils/Colors"
import LinePath from "../../../viewmodel/core/base/LinePath"
import UiUtil from '../../../utils/UiUtil'
import CGPoint from '../../../viewmodel/core/base/basedata/CGPoint'

/**
 * ProjectName: MindMap
 * Created by tony on 2020/6/23
 * Copyright(c) 2020 mindyushu.com
 */
import BaseElementView from "./BaseElementView";
import EncircleShapeType from "../../../viewmodel/datatype/EncircleShapeType"
import imageToStyle from "../../../utils/ImageToolkit"
import Config from "../../../core/core/calcule/Config"
import EditMindmapVM from "../../../viewmodel/mindelementdata/vm/EditMindmapVM"
import httpImageToPrefix from '../../../utils/httpImageToPrefix';
import EncircleTitleLayoutType from "../../../viewmodel/datatype/EncircleTitleLayoutType";
import Util from "../../../utils/Util";
import NodeLayoutType from "../../../viewmodel/datatype/NodeLayoutType";


class EncircleLineView extends BaseElementView {
    constructor(viewCanvs, data) {
        super(viewCanvs, data);
        this.path;  //包裹线
        this.encircleLineBackgroungPath; //包裹线背景
        this.encircleLineText; //包裹线文字
        this.radius = 4;
        this.fillColor; //填充色
        this.EncircleLineBackground; //包裹线背景
        this.EncircleLineBackgroundChildren; //包裹线背景中的图片
        this.textBackground;  //文字背景。
        this.textForeignObject; //装文字的父盒子。
        this.textForeignObjectChildren; //文字。
        this.isAnimation = false;
        this.duration = 1;
        this.bgUrl = "";
        this.mindMoveFunctin = null;
        this.mindOutFunctin = null;
        this.EncircleLineView();
        this.onDraw();
    }

    EncircleLineView(context, data) {
        this.isSelected = false;
    }

    setAnimation(animation, duration) {
        this.isAnimation = animation;
        this.duration = duration;
        this.onDraw();
        this.isAnimation = false;
    }

    setSelectedHoverLayer(value) {
        if (this.isSelected) {
            return
        }
        if (this.mindElementData == null) {
            return;
        }
        if (this.isHover == value) {
            return;
        }
        this.isHover = value;
        this.isRefresh = true;
        this.onDraw();
    }

    setSelected(bool) {
        if (this.isSelected == bool) {
            return;
        }
        this.isRefresh = true;
        this.isSelected = bool;
        this.onDraw();
    }

    refresh() {
        if (!this.isRefresh) {
            return;
        }

        super.refresh();
        this.onDraw();
    }

    onDraw() {
        this.drawLine();
    }

    drawLine() {
        this.radius = 8;
        if (this.mindElementData == null || this.mindElementData.lineContent == null) {
            return;
        }
        if (this.encircleLineBackgroungPath != undefined && this.encircleLineBackgroungPath != null && this.encircleLineBackgroungPath.parentNode != null) {
            this.encircleLineBackgroungPath.parentNode.removeChild(this.encircleLineBackgroungPath);
            this.encircleLineBackgroungPath = null;
        }

        this.drawBgImage();
        if (this.path == null) {
            this.path = document.createElementNS('http://www.w3.org/2000/svg', "path")
            this.context.appendChild(this.path)
        }
        if (this.encircleLineBackgroungPath == null) {
            this.encircleLineBackgroungPath = document.createElementNS('http://www.w3.org/2000/svg', "path")
            this.context.appendChild(this.encircleLineBackgroungPath)
        }

        let lineMindElementContent = this.mindElementData.lineContent;

        this.fillColor = -1;
        if (this.mindElementData.backgroundColorAlpha != null) {
            this.fillColor = Colors.getUiColorByAlpha(lineMindElementContent.color, this.mindElementData.backgroundColorAlpha);
        } else {
            this.fillColor = Colors.getUiColorByAlpha(lineMindElementContent.color, 1.0);
        }
        

        //类型可能为字符串要改为数字
        switch (lineMindElementContent.encircleShapeType - 0) {
            case EncircleShapeType.LAYOUT_TRAPEZOID:
                this.drawTrapezoid();
                if (this.mindElementData.layout == NodeLayoutType.LAYOUT_TRIANGLE) {
                    this.drawRectangle1();
                } else {
                    this.drawTrapezoid();
                }
                break;
            case EncircleShapeType.LAYOUT_RECTANGLE_1:
                this.drawRectangle1();
                break;
            case EncircleShapeType.LAYOUT_RECTANGLE_2:
                this.drawRectangle2();
                break;
            case EncircleShapeType.LAYOUT_RECTANGLE_3:
                if (this.mindElementData.layout == NodeLayoutType.LAYOUT_TRIANGLE) {
                    this.drawRectangle1();
                } else {
                    this.drawRectangle3();
                }
                break;
            case EncircleShapeType.LAYOUT_RECTANGLE_4:
                if (this.mindElementData.layout == NodeLayoutType.LAYOUT_TRIANGLE) {
                    this.drawRectangle1();
                } else {
                    this.drawRectangle4(true);
                }
                let textHeight = this.mindElementData.lineContent.isContainText() ? lineMindElementContent.textContent.height : 0;
                let path1 = new LinePath;
                path1.moveTo(0, textHeight);
                path1.lineTo(this.mindElementData.width, textHeight);
                path1.lineTo(this.mindElementData.width, this.mindElementData.height);
                path1.lineTo(0, this.mindElementData.height);
                path1.lineTo(0, textHeight);
                this.renderObj({ dom: this.encircleLineBackgroungPath, fill: this.fillColor, d: path1 })

                break;
            default:
                if (this.mindElementData.layout == NodeLayoutType.LAYOUT_TRIANGLE) {
                    this.drawRectangle1();
                } else {
                    this.drawTrapezoid();
                }
        }

        this.drawTitle();
        if (this.mindElementData.isShadow) {
            this.context.setAttribute('filter', 'url(#baseLinemapLineShadow)');
        } else {
            this.context.setAttribute('filter', '');
        }
    }

    drawTitle() {
        this.radius = 6;
        if (this.textBackground != undefined && this.textBackground != null && this.textBackground.parentNode != null) {
            this.textBackground.parentNode.removeChild(this.textBackground);
            this.textForeignObject.parentNode.removeChild(this.textForeignObject);
            this.textBackground = null;
        }
        if (!this.mindElementData.lineContent.isContainText()) { //如果内容为空，这里的lineContent就是LineElementContent
            return;
        }
        this.textBackground = document.createElementNS('http://www.w3.org/2000/svg', "path");        

        this.context.appendChild(this.textBackground);
        this.textForeignObject = document.createElementNS('http://www.w3.org/2000/svg', "foreignObject");
        this.textForeignObjectChildren = document.createElement("div");
        this.textForeignObject.appendChild(this.textForeignObjectChildren);
        this.context.appendChild(this.textForeignObject);

        let textContent = this.mindElementData.lineContent.textContent;
        let textFontSize = textContent.textFontSize;
        let textItalics = textContent.textItalics;
        let textBold = textContent.textBold;
        let strikethroughStyle = textContent.textStrikethrough;
        let title = textContent.text;

        let lineWidth = this.mindElementData.lineContent.lineWidth;
        let lineMindElementContent = this.mindElementData.lineContent;
        let x = textContent.x - lineWidth / 2;
        let y = textContent.y - lineWidth / 2;
        let width = textContent.width + lineWidth;
        let height = textContent.height + lineWidth;

        let titleBackgroundColor;
        let textColor = textContent.textColor;


        this.textForeignObject.setAttribute('x', x);
        this.textForeignObject.setAttribute('y', y);
        this.textForeignObject.setAttribute('width', width);
        this.textForeignObject.setAttribute('height', height);
        this.textForeignObjectChildren.style.userSelect = 'none';
        this.textForeignObjectChildren.style.fontSize = textFontSize + 'px';
        this.textForeignObjectChildren.style.color = Colors.getUiColor(textColor);

        //标题只有一行
        this.textForeignObjectChildren.style.lineHeight = height + 'px';
        this.textForeignObjectChildren.style.textAlign = 'center';
        if (textItalics) {
            this.textForeignObjectChildren.style.fontStyle = 'italic'
        } else {
            this.textForeignObjectChildren.style.fontStyle = 'normal'
        }
        if (textBold) {
            this.textForeignObjectChildren.style.fontWeight = Config.textBoldFontWeight
        } else {
            this.textForeignObjectChildren.style.fontWeight = Config.textNormalFontWeight
        }
        if (strikethroughStyle) {
            this.textForeignObjectChildren.style.textDecoration = 'line-through'
        } else {
            this.textForeignObjectChildren.style.textDecoration = 'none'
        }
        this.textForeignObjectChildren.innerHTML = title;

        if (Colors.isClear(this.mindElementData.lineContent.strokeColor)) { //判断边框线条是否颜色。
            if (Colors.isDarkColor(EditMindmapVM.mindBGColor)) {
                titleBackgroundColor = Colors.getUiColor(Colors.white);
            } else {
                titleBackgroundColor = Colors.getUiColor(Colors.black60);
            }
        } else {
            titleBackgroundColor = Colors.getUiColor(this.mindElementData.lineContent.strokeColor)
        }

        let titleBgRadius = this.radius;
        let linePath = new LinePath();
        if (this.mindElementData.lineContent.encircleTitleType == EncircleTitleLayoutType.MIDDLE_LEFT ||
            this.mindElementData.lineContent.encircleTitleType == EncircleTitleLayoutType.MIDDLE_MIDDLE ||
            this.mindElementData.lineContent.encircleTitleType == EncircleTitleLayoutType.MIDDLE_RIGHT) {
            titleBgRadius = height / 2;
            linePath.moveTo(x + titleBgRadius, y + height);
            linePath.circle(titleBgRadius, titleBgRadius, 0, 0, 1, x + titleBgRadius, textContent.y);
            linePath.lineTo(textContent.x + width - titleBgRadius, y);
            linePath.circle(titleBgRadius, titleBgRadius, 0, 0, 1, x + width - titleBgRadius, y + height);
            linePath.lineTo(x + titleBgRadius, y + height);
        } else if (this.mindElementData.lineContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_LEFT) {
            this.radius = 8;
            if (lineMindElementContent.encircleShapeType == EncircleShapeType.LAYOUT_RECTANGLE_2) {
                linePath.moveTo(x, y + this.radius);
                linePath.circle(this.radius, this.radius, 0, 0, 1, x + this.radius, y);
                linePath.lineTo(x + this.radius, y);
                linePath.lineTo(x + width, y);
                linePath.lineTo(x + width, y + height - this.radius);
                linePath.circle(this.radius, this.radius, 0, 0, 1, x + width - this.radius, y + height);

                linePath.lineTo(x, y + height);
                linePath.lineTo(x, y + this.radius);
            } else {
                linePath.moveTo(x,y + this.radius);
                linePath.lineTo( x, y);
                linePath.lineTo( x + width, y);
                linePath.lineTo( x + width, y + height - this.radius);
                linePath.circle(this.radius, this.radius, 0, 0, 1, x + width - this.radius, y + height);

                linePath.lineTo( x, y + height);
                linePath.lineTo( x, y + this.radius);
            }

        } else if (lineMindElementContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_RIGHT) {
            this.radius = 8;
            if (lineMindElementContent.encircleShapeType == EncircleShapeType.LAYOUT_RECTANGLE_2) {
                linePath.moveTo(x,y + this.radius);
                linePath.lineTo( x, y);
                linePath.lineTo( x + width - this.radius, y);
                linePath.circle(this.radius, this.radius, 0, 0, 1, x + width, y + this.radius);

                linePath.lineTo( x + width, y + height);
                linePath.lineTo( x + this.radius, y + height);

                linePath.circle(this.radius, this.radius, 0, 0, 1, x, y + height - this.radius);

                linePath.lineTo( x, y + this.radius);
            } else {
                linePath.moveTo(x,y + this.radius);
                linePath.lineTo( x, y);
                linePath.lineTo( x + width, y);
                linePath.lineTo( x + width, y + height);
                linePath.lineTo( x + this.radius, y + height);
                linePath.circle(this.radius, this.radius, 0, 0, 1, x, y + height - this.radius);
                linePath.lineTo( x, y + this.radius);
            }
        } else if (lineMindElementContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_FULL) {
            this.radius = 8;
            if (lineMindElementContent.encircleShapeType == EncircleShapeType.LAYOUT_RECTANGLE_2) {
                linePath.moveTo(x,y + this.radius);
                linePath.circle(this.radius, this.radius, 0, 0, 1, x + this.radius, y);

                linePath.lineTo( x + width - this.radius, y);
                linePath.circle(this.radius, this.radius, 0, 0, 1, x + width, y + this.radius);

                linePath.lineTo( x + width, y + height);
                linePath.lineTo( x, y + height);
                linePath.lineTo( x, y + this.radius);
            } else {
                this.radius = 16;
                linePath.moveTo(x,y + this.radius);
                linePath.lineTo( x, y);
                linePath.lineTo( x + width, y);
                linePath.lineTo( x + width, y + height - this.radius);
                linePath.lineTo( x + width, y + height);
                linePath.lineTo( x, y + height);

                linePath.lineTo( x, y + height);
                linePath.lineTo( x, y + this.radius);
            }
        } else {
            linePath.moveTo(x, y + height);
            linePath.lineTo(x, y + titleBgRadius);
            linePath.circle(titleBgRadius, titleBgRadius, 0, 0, 1, x + titleBgRadius, y);
            linePath.lineTo(x + width - titleBgRadius, y);
            linePath.circle(titleBgRadius, titleBgRadius, 0, 0, 1, x + width, y + titleBgRadius);
            linePath.lineTo(x + width, y + height);
            linePath.lineTo(x, y + height);
        }
        this.renderObj({ dom: this.textBackground, d: linePath, fill: titleBackgroundColor })
    }



    renderObj(obj = { dom, d, stroke, strokeWidth }) {  //设置path
        if (obj.d != undefined) {
            obj.dom.setAttribute("d", obj.d.getLine())
        }
        if (obj.stroke != undefined) {
            obj.dom.setAttribute("stroke", obj.stroke)
        }
        if (obj.strokeWidth != undefined) {
            obj.dom.setAttribute("stroke-width", obj.strokeWidth)
        }
        if (obj.fill != undefined) {
            obj.dom.setAttribute("fill", obj.fill)
        }
    }



    drawRectangle1() {
        let lineMindElementContent = this.mindElementData.lineContent;

        let textHeight = lineMindElementContent.isContainText() ? lineMindElementContent.textContent.height : 0;
        if (lineMindElementContent.encircleTitleType == EncircleTitleLayoutType.MIDDLE_LEFT ||
            lineMindElementContent.encircleTitleType == EncircleTitleLayoutType.MIDDLE_MIDDLE ||
            lineMindElementContent.encircleTitleType == EncircleTitleLayoutType.MIDDLE_RIGHT) {
            textHeight = textHeight/2;
        } else if (lineMindElementContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_FULL ||
            lineMindElementContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_LEFT ||
            lineMindElementContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_RIGHT) {
            textHeight = 0;
        }
        let width = this.mindElementData.width;
        let height = this.mindElementData.height - textHeight;

        let linePath = new LinePath();

        if (this.mindElementData.layout == NodeLayoutType.LAYOUT_TRIANGLE) {
            linePath.moveTo(0, textHeight + height);
            let startX = (Util.tan(30) * height);
            linePath.lineTo(startX, textHeight);
            linePath.lineTo(width, textHeight);
            linePath.lineTo(width, textHeight + height);
            linePath.lineTo(startX, textHeight + height);
        } else {
            linePath.moveTo(0, textHeight);
            linePath.lineTo(width, textHeight);
            linePath.lineTo(width, textHeight + height);
            linePath.lineTo(0, textHeight + height);
            linePath.lineTo(0, textHeight);
            linePath.lineTo(6, textHeight);
        }
        this.renderCanvas(linePath, this.fillColor);
    }

    drawRectangle2() {
        let lineMindElementContent = this.mindElementData.lineContent;
        let textHeight = lineMindElementContent.isContainText() ? lineMindElementContent.textContent.height : 0;
        if (lineMindElementContent.encircleTitleType == EncircleTitleLayoutType.MIDDLE_LEFT ||
            lineMindElementContent.encircleTitleType == EncircleTitleLayoutType.MIDDLE_MIDDLE ||
            lineMindElementContent.encircleTitleType == EncircleTitleLayoutType.MIDDLE_RIGHT) {
            textHeight = textHeight/2;
        } else if (lineMindElementContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_FULL ||
            lineMindElementContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_LEFT ||
            lineMindElementContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_RIGHT) {
            textHeight = 0;
        }
        let width = this.mindElementData.width;
        let height = this.mindElementData.height - textHeight;

        let linePath = new LinePath();
        if (this.mindElementData.layout == NodeLayoutType.LAYOUT_TRIANGLE) {
            let startX = Util.tan(30) * height;
            linePath.moveTo(startX, textHeight);
            linePath.lineTo(width - this.radius, textHeight);
            linePath.circle(this.radius, this.radius, 0, 0, 1, width, textHeight + this.radius);
            linePath.lineTo(width, textHeight + height - this.radius);
            linePath.circle(this.radius, this.radius, 0, 0, 1, width - this.radius, textHeight + height);
            linePath.lineTo(0, textHeight + height);
            linePath.lineTo(startX, textHeight);
        } else {
            linePath.moveTo(0, textHeight + this.radius);
            linePath.circle(this.radius, this.radius, 0, 0, 1, this.radius, textHeight);
            linePath.lineTo(width - this.radius, textHeight);
            linePath.circle(this.radius, this.radius, 0, 0, 1, width, textHeight + this.radius);
            linePath.lineTo(width, textHeight + height - this.radius);
            linePath.circle(this.radius, this.radius, 0, 0, 1, width - this.radius, textHeight + height);
            linePath.lineTo(this.radius, textHeight + height);
            linePath.circle(this.radius, this.radius, 0, 0, 1, 0, textHeight + height - this.radius);
            linePath.lineTo(0, textHeight + this.radius);
        }
        this.renderCanvas(linePath, this.fillColor);
    }

    drawRectangle3() {
        let lineMindElementContent = this.mindElementData.lineContent;

        let textHeight = lineMindElementContent.isContainText() ? lineMindElementContent.textContent.height : 0;
        if (lineMindElementContent.encircleTitleType == EncircleTitleLayoutType.MIDDLE_LEFT ||
            lineMindElementContent.encircleTitleType == EncircleTitleLayoutType.MIDDLE_MIDDLE ||
            lineMindElementContent.encircleTitleType == EncircleTitleLayoutType.MIDDLE_RIGHT) {
            textHeight = textHeight/2;
        } else if (lineMindElementContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_FULL ||
            lineMindElementContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_LEFT ||
            lineMindElementContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_RIGHT) {
            textHeight = 0;
        }
        let width = this.mindElementData.width;
        let height = this.mindElementData.height - textHeight;

        let ellipseRadius = new UiUtil().dip2px(8);
        let radiusX = new UiUtil().dip2px(28);
        let countX = (Math.ceil(width / (radiusX * 2)));
        radiusX = width / countX / 2;

        let radiusY = new UiUtil().dip2px(28);
        let countY = (Math.ceil(height / (radiusY * 2)));
        radiusY = height / countY / 2;
        if (countX == 0 || countY == 0) {
            return;
        }


        let linePath = new LinePath()

        linePath.moveTo(0, textHeight + ellipseRadius);
        for (let index = 0; index < countX; index++) {      //上
            linePath.circle(radiusX, ellipseRadius, 0, 0, 1, radiusX * 2 + index * radiusX * 2, ellipseRadius + textHeight);
        }

        for (let index = 0; index < countY; index++) {  //右
            if (index == 0) {
                linePath.lineTo(width, radiusY + textHeight)
                linePath.circle(ellipseRadius, radiusY, 0, 0, 1, width - ellipseRadius, radiusY * 2 + textHeight);
            } else {
                linePath.circle(ellipseRadius, radiusY, 0, 0, 1, width - ellipseRadius, radiusY * 2 * (index + 1) + textHeight);
            }

        }

        for (let index = 0; index < countX; index++) {    //下
            if (index == 0) {
                linePath.lineTo(width - radiusX, height + textHeight)
                linePath.circle(radiusX, ellipseRadius, 0, 0, 1, width - radiusX * 2, height - ellipseRadius + textHeight);
            } else {
                linePath.circle(radiusX, ellipseRadius, 0, 0, 1, width - (index + 1) * radiusX * 2, height - ellipseRadius + textHeight);
            }
        }

        for (let index = 0; index < countY; index++) {  //左
            if (index == 0) {
                linePath.lineTo(0, height + textHeight - radiusY)
                linePath.circle(ellipseRadius, radiusY, 0, 0, 1, ellipseRadius, height + textHeight - radiusY * 2);
            } else {
                if (index == countY - 1) {
                    linePath.circle(ellipseRadius, radiusY, 0, 0, 1, 0, radiusY + textHeight);
                    linePath.lineTo(0, textHeight + ellipseRadius)
                } else {
                    linePath.circle(ellipseRadius, radiusY, 0, 0, 1, ellipseRadius, height - 2 * (index + 1) * radiusY + textHeight);
                }

            }
        }
        this.renderCanvas(linePath, this.fillColor)

    }

    drawRectangle4(flag) {
        let lineMindElementContent = this.mindElementData.lineContent;

        let textHeight = lineMindElementContent.isContainText() ? lineMindElementContent.textContent.height : 0;
        if (lineMindElementContent.encircleTitleType == EncircleTitleLayoutType.MIDDLE_LEFT ||
            lineMindElementContent.encircleTitleType == EncircleTitleLayoutType.MIDDLE_MIDDLE ||
            lineMindElementContent.encircleTitleType == EncircleTitleLayoutType.MIDDLE_RIGHT) {
            textHeight = textHeight/2;
        } else if (lineMindElementContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_FULL ||
            lineMindElementContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_LEFT ||
            lineMindElementContent.encircleTitleType == EncircleTitleLayoutType.TOP_INSIDE_RIGHT) {
            textHeight = 0;
        }
        let width = this.mindElementData.width;
        let height = this.mindElementData.height - textHeight;

        let lineWidth = ((Math.min(width, height)) * 0.2);
        if (lineWidth > new UiUtil().dip2px(50)) {
            lineWidth = new UiUtil().dip2px(50);
        }
        let linePath = new LinePath;
        linePath.moveTo(0, textHeight + lineWidth);
        linePath.lineTo(0, textHeight);
        linePath.lineTo(lineWidth, textHeight);

        linePath.moveTo(width - lineWidth, textHeight);
        linePath.lineTo(width, textHeight);
        linePath.lineTo(width, textHeight + lineWidth);

        linePath.moveTo(width, textHeight + height - lineWidth);
        linePath.lineTo(width, textHeight + height);
        linePath.lineTo(width - lineWidth, textHeight + height);

        linePath.moveTo(lineWidth, textHeight + height);
        linePath.lineTo(0, textHeight + height);
        linePath.lineTo(0, textHeight + height - lineWidth);


        this.renderCanvas(linePath, this.fillColor, flag)
    }




    drawTrapezoid() {
        let lineMindElementContent = this.mindElementData.lineContent;
        let linePath = new LinePath();
        let encircleNodesPoint = lineMindElementContent.encircleNodesPoint;

        if (encircleNodesPoint.length == 0 || encircleNodesPoint.length < 4) {
            return;
        }
        if (encircleNodesPoint[0].x == encircleNodesPoint[1].x) {
            if (encircleNodesPoint[0].y > encircleNodesPoint[1].y) {
                let pointOne = new CGPoint(encircleNodesPoint[0].x, encircleNodesPoint[0].y - this.radius * 2);
                linePath.moveTo(pointOne.x, pointOne.y);
            } else {
                let pointOne = new CGPoint(encircleNodesPoint[0].x, encircleNodesPoint[0].y + this.radius * 2)
                linePath.moveTo(pointOne.x, pointOne.y);
            }
            for (let index = 1; index < encircleNodesPoint.length; index++) {
                let pointTwo = new CGPoint(encircleNodesPoint[index].x, encircleNodesPoint[index].y)
                linePath.lineTo(pointTwo.x, pointTwo.y);
            }
            if (encircleNodesPoint[0].y > encircleNodesPoint[1].y) {
                let pointThree = new CGPoint(encircleNodesPoint[0].x, encircleNodesPoint[0].y - this.radius * 2)
                linePath.lineTo(pointThree.x, pointThree.y);
            } else {
                let pointThree = new CGPoint(encircleNodesPoint[0].x, encircleNodesPoint[0].y + this.radius * 2)
                linePath.lineTo(pointThree.x, pointThree.y);
            }
        } else if (encircleNodesPoint[0].y == encircleNodesPoint[1].y) {
            if (encircleNodesPoint[0].x < encircleNodesPoint[1].x) {
                let pointOne = new CGPoint(encircleNodesPoint[0].x + this.radius * 2, encircleNodesPoint[0].y);
                linePath.moveTo(pointOne.x, pointOne.y);

            } else {
                let pointOne = new CGPoint(encircleNodesPoint[0].x - this.radius * 2, encircleNodesPoint[0].y);
                linePath.moveTo(pointOne.x, pointOne.y);

            }
            for (let index = 1; index < encircleNodesPoint.length; index++) {
                let pointTwo = new CGPoint(encircleNodesPoint[index].x, encircleNodesPoint[index].y)
                linePath.lineTo(pointTwo.x, pointTwo.y);

            }
            if (encircleNodesPoint[0].x < encircleNodesPoint[1].x) {
                let pointThree = new CGPoint(encircleNodesPoint[0].x + this.radius * 2, encircleNodesPoint[0].y)
                linePath.lineTo(pointThree.x, pointThree.y);

            } else {
                let pointThree = new CGPoint(encircleNodesPoint[0].x - this.radius * 2, encircleNodesPoint[0].y)
                linePath.lineTo(pointThree.x, pointThree.y);

            }
        } else {
            let pointOne = new CGPoint(encircleNodesPoint[0].x, encircleNodesPoint[0].y);
            linePath.moveTo(pointOne.x, pointOne.y);

            for (let index = 1; index < encircleNodesPoint.length; index++) {
                let pointTwo = new CGPoint(encircleNodesPoint[index].x, encircleNodesPoint[index].y)
                linePath.lineTo(pointTwo.x, pointTwo.y);
            }
        }
        this.renderCanvas(linePath, this.fillColor)
    }



    //自己添加的一个函数
    renderCanvas(path, fillColor, flag) {
        let lineMindElementContent = this.mindElementData.lineContent
        this.path.setAttribute("d", path.getLine())
        if (this.isHover) {
            if (Colors.isClear(lineMindElementContent.strokeColor)) {
                this.path.setAttribute("stroke", Colors.getUiColor(0xfd715a));
            } else {
                this.path.setAttribute("stroke", Colors.getUiColor(lineMindElementContent.strokeColor));
            }
            this.path.setAttribute("stroke-width", lineMindElementContent.lineWidth);
        } else {
            this.path.setAttribute("stroke", Colors.getUiColor(lineMindElementContent.strokeColor));
            this.path.setAttribute("stroke-width", lineMindElementContent.lineWidth);
        }
        
        if (lineMindElementContent.dottedLine) {
            this.path.setAttribute("stroke-dasharray", Config.strokeDasharray1Str);
            if (this.isAnimation) {
                this.path.style.strokeDashoffset = this.path.getTotalLength()
                this.path.style.strokeDasharray = this.path.getTotalLength()
                this.path.style.animation = 'path-line-animation-dash ' + (this.duration / 1000) + 's linear forwards'
                setTimeout(() => {
                    this.path.setAttribute("stroke-dasharray", Config.strokeDasharray1Str);
                    this.path.style.strokeDasharray = Config.strokeDasharray1
                    this.path.style.strokeDashoffset = 1
                    this.path.style.animation = ""
                }, this.duration + 10);
            } else {
                this.path.setAttribute("stroke-dasharray", Config.strokeDasharray1Str);
                this.path.style.strokeDasharray = Config.strokeDasharray1
                this.path.style.strokeDashoffset = 1
                this.path.style.animation = ""
            }
        } else {
            this.path.setAttribute("stroke-dasharray", "none");
            if (this.isAnimation) {
                this.path.style.strokeDashoffset = this.path.getTotalLength()
                this.path.style.strokeDasharray = this.path.getTotalLength()
                this.path.style.animation = 'path-line-animation-dash ' + (this.duration / 1000) + 's linear forwards'
            } else {
                this.path.style.strokeDashoffset = 0
                this.path.style.strokeDasharray = 0
                this.path.style.animation = ''
            }
        }


        if (flag == true) {
            this.path.setAttribute("fill", 'none')
        } else {
            this.path.setAttribute("fill", fillColor)
        }


    }

    drawBgImage() {
        let lineMindElementContent = this.mindElementData.lineContent;
        if (lineMindElementContent.backgroundUrl == '') {
            if (this.EncircleLineBackground != null) {
                this.EncircleLineBackground.parentNode.removeChild(this.EncircleLineBackground);
                this.EncircleLineBackground = null
            }
            return;
        }
        let textHeight = lineMindElementContent.isContainText() ? lineMindElementContent.textContent.height : 0;
        let width = this.mindElementData.width;
        let height = this.mindElementData.height - textHeight;
        

        if (this.EncircleLineBackground != null && this.EncircleLineBackgroundChildren != null) {
            let viewPoint = this.UiUtil.getViewPoint(this.EncircleLineBackground);
            let viewSize = this.UiUtil.getViewSize(this.EncircleLineBackgroundChildren);
            if (this.bgUrl == lineMindElementContent.backgroundUrl &&
                Math.abs(viewPoint.y - textHeight) < 2 && 
                Math.abs(viewSize.width - width) < 2 &&
                Math.abs(viewSize.height - height) < 2) {
                return;
            }
            this.EncircleLineBackground.parentNode.removeChild(this.EncircleLineBackground);
            this.EncircleLineBackground = null
        }
        this.bgUrl = lineMindElementContent.backgroundUrl;

        this.EncircleLineBackground = document.createElementNS('http://www.w3.org/2000/svg', "foreignObject")
        this.EncircleLineBackgroundChildren = document.createElement("div")
        this.EncircleLineBackground.setAttribute('y', textHeight);
        this.EncircleLineBackground.setAttribute('width', width);
        this.EncircleLineBackground.setAttribute('height', height);
        this.EncircleLineBackground.appendChild(this.EncircleLineBackgroundChildren);
        this.context.appendChild(this.EncircleLineBackground);
        this.EncircleLineBackgroundChildren.style.width = width + 'px';
        this.EncircleLineBackgroundChildren.style.height = height + 'px';
        if (this.bgUrl == null) {
            this.EncircleLineBackgroundChildren.style.backgroundImage = 'url()';
        } else {
            let srcHttp = httpImageToPrefix(this.bgUrl)
            new imageToStyle().webToBase64(this.bgUrl).then(imageBase64 => {
                this.EncircleLineBackgroundChildren.style.backgroundImage = 'url(' + imageBase64 + ')';
                this.EncircleLineBackgroundChildren.style.backgroundPosition = 'center center';
                this.EncircleLineBackgroundChildren.style.backgroundRepeat = 'no-repeat';
                this.EncircleLineBackgroundChildren.style.backgroundSize = '100% 100%';
            })
        }
    }

    createBackgroundView() {

    }
}
export default EncircleLineView