

import HashMap from "../core/base/HashMap"

import IdGenerator from "../core/base/IdGenerator"
import BubbleColorMode from "../core/base/BubbleColorMode"
import LineColorMode from "../core/base/LineColorMode"
import MindMapStyleColor from "../core/base/MindMapStyleColor"
import CalculSaveImageRect from "../../core/core/calcule/CalculSaveImageRect"

import BottomLayout from "../core/layout/BottomLayout"
import BubbleLayout from "../core/layout/BubbleLayout"
import FormLayut from "../core/layout/FormLayut"
import LeftLayout from "../core/layout/LeftLayout"
import LeftRightLayout from "../core/layout/LeftRightLayout"
import RightLayout from "../core/layout/RightLayout"
import TreeLeftLayout from "../core/layout/TreeLeftLayout"
import TreeLeftRightLayout from "../core/layout/TreeLeftRightLayout"
import TreeRightLayout from "../core/layout/TreeRightLayout"

import LineLayout from "../datatype/LineLayout"
import MindDisplayType from "../datatype/MindDisplayType"
import MindElementShapeType from "../datatype/MindElementShapeType";
import MindElementType from "../datatype/MindElementType"
import MindType from "../datatype/MindType"
import NodeLayoutType from "../datatype/NodeLayoutType"
import LineMindTypeNode from "./LineMindTypeNode"
import GeneralizationContent from "../mindelementdata/mindcontent/GeneralizationContent"
import TextContent from "../mindelementdata/mindcontent/TextContent"


import MindElementData from "./MindElementData"

import UiUtil from "../../utils/UiUtil"
import Colors from "../../utils/Colors"
import ZhCn from "../../common/lang/zh-cn"
import Logger from "../../utils/Logger"
import MindElementCalculation from "../../core/core/calcule/elementCalculation/MindElementCalculation"
import TriangleLayout from "../core/layout/TriangleLayout"
import Strings from "../../utils/Strings"
import LineElementContent from "./mindcontent/LineElementContent"
import Point from "../core/base/Point"
import EncircleShapeType from "../datatype/EncircleShapeType"

import StringsLanguage from '../../common/lang/strings';
import OutlineMindElementCalculation from "../../core/core/calcule/OutlineMindElementCalculation"
import OutlineLayout from "../core/layout/OutlineLayout"
import CGRect from "../core/base/basedata/Rect"
import Util from "../../utils/Util"
import NodesRectCalculation from "../../core/core/calcule/NodesRectCalculation"
import RectsMergeCalculation from "../core/layout/RectsMergeCalculation"
import BracketsRightLayout from "../core/layout/BracketsRightLayout"
import RightLeftLayout from "../core/layout/RightLeftLayout"
import RightLeftClockwiseLayout from "../core/layout/RightLeftClockwiseLayout"
import TopLayout from "../core/layout/TopLayout"
import MindLayoutSelect from "../core/tools/setting/MindLayoutSelect"
import HorizontalRightLayout from "../core/layout/HorizontalRightLayout"
import TreeTopLeftRightLayout from "../core/layout/TreeTopLeftRightLayout"
import VerticaRightLayout from "../core/layout/VerticaRightLayout"
import TreeTopLeftLayout from "../core/layout/TreeTopLeftLayout"
import TreeTopRightLayout from "../core/layout/TreeTopRightLayout"
import FishLayout from "../core/layout/FishLayout"
import Config from "../../core/core/calcule/Config"
import BracketsLeftLayout from "../core/layout/BracketsLeftLayout"
import BoldData from "./mindcontent/BoldData"
import SettingData from "../core/minddata/SettingData"
import CircleLayout from "../core/layout/CircleLayout"
import CGPoint from "../core/base/basedata/CGPoint"
import MindSettingManager from "../core/tools/setting/MindSettingManager"
import MindElementAlignmentType from "../datatype/MindElementAlignmentType"
import MindMapFramework from "../mindelementdata/MindMapFramework";
import OrderContent from "./mindcontent/OrderContent"
import OrderType from "../datatype/OrderType"
import FormHorizontalLayut from "../core/layout/FormHorizontalLayut"
import FreeTreeLayout from "../core/layout/FreeTreeLayout"
import RadiateLayout from "../core/layout/RadiateLayout"
import FishLeftLayout from "../core/layout/FishLeftLayout"
import NewFreeTreeLayout from "../core/layout/NewFreeTreeLayout"
import EncircleTitleLayoutType from "../datatype/EncircleTitleLayoutType"


class LineMindTypeNodeUnit {
    constructor(mindBGColor = -1, mindType = MindType.LINE_MAP, mindMapStyleSelectedIndex = 0, isFreeNode = false) {
        this.delegate;
        this.rootTreeNode; //主体树状数据结构

        this.lineMindElementDataDict = new HashMap(); //主体线字典， Int为线ID data为line
        this.mainMindElementDataDict = new HashMap(); //主体节点字典， Int为线ID data为节点：包括中心主题、主题、子主题
        this.textElementLineMindElementDataDict = new HashMap(); //主体节点对应的线，Int为节点ID，data为line
        this.generalizationLineMindElementDataDict = new HashMap(); //概要线字典， Int为线ID data为line
        this.generalizationMindElementDataDict = new HashMap(); //概要字典， Int为概要ID data为概要节点
        this.encircleMindElementDataDict = new HashMap(); //包裹节点字典， Int为线ID
        this.explainMindElementDataDict = new HashMap(); //解释节点字典， Int为线ID


        this.elementLayout = new RightLayout()
        this.elementLayout.mindLayoutSelect = MindLayoutSelect
        this.globalLayout = NodeLayoutType.LAYOUT_BUBBLE //布局
        this.globalLineLayout = LineLayout.RIGHT_ANGLE_CORNER_LINE
        this.lineColorMode = new LineColorMode()
        this.lineColors = new Array()
        this.fullColors = new Array()
        this.mindType = mindType
        this.mindMapStyleColor = new MindMapStyleColor(mindMapStyleSelectedIndex)
        this.mindMapFramework = new MindMapFramework(mindMapStyleSelectedIndex);
        this.mindDisplayType = MindDisplayType.MindMap
        this.mindBGColor = this.mindMapStyleColor.getMindMapFillColor(); // mindBGColor
        this.isFreeNode = isFreeNode
        this.selfStaticDataType = false
    }

    callbackResetDatas(rootTreeNode, lineMindElementDataDict,
        mainMindElementDataDict, textElementLineMindElementDataDict,
        generalizationLineMindElementDataDict, generalizationMindElementDataDict,
        explainMindElementDataDict) {
        this.rootTreeNode = rootTreeNode;
        this.lineMindElementDataDict = lineMindElementDataDict;
        this.mainMindElementDataDict = mainMindElementDataDict;
        this.textElementLineMindElementDataDict = textElementLineMindElementDataDict;
        this.generalizationLineMindElementDataDict = generalizationLineMindElementDataDict;
        this.generalizationMindElementDataDict = generalizationMindElementDataDict;
        this.explainMindElementDataDict = explainMindElementDataDict;
    }

    setMindMapStyleColor(index) {
        this.mindMapStyleColor = new MindMapStyleColor(index);
    }

    setFrameworkModle(selectedIndex) {
        this.mindMapFramework.currentSelectedIndex = selectedIndex;
    }

    clearAllData() {
        this.rootTreeNode = new LineMindTypeNode();
        this.lineMindElementDataDict.clear();
        this.mainMindElementDataDict.clear();
        this.textElementLineMindElementDataDict.clear();
        this.generalizationLineMindElementDataDict.clear();
        this.generalizationMindElementDataDict.clear();
        this.encircleMindElementDataDict.clear();
        this.explainMindElementDataDict.clear();
    }

    createRootNode() {
        let rootData = (new MindElementData());
        rootData.id = IdGenerator.getRootId();
        rootData.type = MindElementType.MAIN_SUBJECT;
        rootData.parentNodeId = IdGenerator.shared.INVALID_ID;
        rootData.layout = this.globalLayout;
        let text = (this.globalLayout == NodeLayoutType.LAYOUT_BOTTOM || this.globalLayout == NodeLayoutType.LAYOUT_TOP) ? new UiUtil().getString(StringsLanguage.Mind_Organization_Edit_Main_Idea) : new UiUtil().getString(StringsLanguage.Mind_Edit_Main_Idea);
        let textContent = new TextContent(text);

        textContent.width = 112;
        textContent.height = 56;
        textContent.x = 0;
        textContent.y = 0;
        rootData.textContent = textContent;
        rootData.level = 0;
        if (this.globalLayout == NodeLayoutType.LAYOUT_BUBBLE ||
            this.globalLayout == NodeLayoutType.LAYOUT_CIRCLE) {
            rootData.mindElementShape = MindElementShapeType.Circular;
        } else {
            rootData.mindElementShape = this.mindMapStyleColor.getRootShapeType();
            rootData.checkShapeCard(this.mindBGColor)
        }
        rootData.backgroundColor = this.mindMapStyleColor.getRootNodeFillColor();
        rootData.backgroundFullType = this.mindMapStyleColor.getRootNodeBackgorundFullShape();
        rootData.borderColor = this.mindMapStyleColor.getRootNodeBorderColor();
        rootData.borderWidth = this.mindMapStyleColor.getRootNodeBorderWidth();
        textContent.textColor = this.mindMapStyleColor.getRootNodeTextColor();
        textContent.textFontSize = this.mindMapStyleColor.getrootNodeTextFontSize();
        textContent.textShadow = this.mindMapStyleColor.getRootNodeTextShadowColor();
        textContent.textBold = true;
        rootData.styleColor = this.mindMapStyleColor.getLineColor();
        rootData.styleFillColor = this.mindMapStyleColor.getSubjectViewFillColor();
        // if (this.mindMapStyleColor.getRootNodeOrder() && this.globalLayout != NodeLayoutType.LAYOUT_RADIATE) {
        //     rootData.orderContent = new OrderContent(0, 0);
        //     rootData.orderContent.parentNodeOrderLevel = rootData.level;
        //     rootData.orderContent.orderDepth = 3
        //     rootData.orderContent.orderType = OrderType.Order_6
        // }
        

        (MindElementCalculation.set(rootData)).caluleTextForData().calcule();
        let rootNode = new LineMindTypeNode();
        rootNode.value = rootData;
        this.setRootNode(rootNode);
    }

    setRootNode(rootTreeNode) {
        this.rootTreeNode = rootTreeNode;
        if (this.rootTreeNode != null && !this.rootTreeNode.isEmpty()) {
            this.rootTreeNode.value.layout = this.globalLayout;
        }
        this.setNodeToDictStructure(this.rootTreeNode);
    }

    setGeneralizationNode(node) {
        if (node.value.type != MindElementType.CONTENT_GENERALIZATION) {
            return
        }
        this.generalizationMindElementDataDict.put(node.value.id, node)
        if (!this.selfStaticDataType) {
            IdGenerator.shared.setId(node.value.id)
        }
        this.setNodeToDictStructure(node)
    }

    addYoungerBrotherMindElement(targetId) {
        let targetNodeId = targetId;

        let targetNode = this.getNodeById(targetNodeId);

        if (targetNode.isEmpty() ||
            targetNode.value.type == MindElementType.CONTENT_GENERALIZATION ||
            targetNode.value.type == MindElementType.EXPLAIN) {
            return (new LineMindTypeNode).emptyMindNode();
        }
        let parentNode = this.getNodeById(targetNode.value.parentNodeId);
        let parentNodeData = parentNode.value;

        if (parentNode.isEmpty()) {
            return this.addChildMindElement(targetId);
        } else {
            let node;
            let point = parentNode.getChildIndex(targetNode) + 1;

            if (parentNodeData.type == MindElementType.MAIN_SUBJECT || targetId == 0) {
                node = this.createSubjectNode(parentNodeData.id, parentNodeData.level + 1, point);
            } else {
                node = this.createSonSubjectNode(parentNodeData.id, parentNodeData.level + 1);
            }
            parentNode.addChildrenNode(node, point);
            if (parentNode.value.id == this.rootTreeNode.value.id &&
                this.isUnderline(node.value) &&
                this.lineColors != null &&
                this.lineColors.length > 0) {
                let colorPoint = point % this.lineColors.length
                node.value.borderColor = this.lineColors[colorPoint]
                if (node.value.isCardContent()) {
                    node.value.checkSetTextColor(this.mindBGColor)
                }
            }
            return node;
        }
    }

    isUnderline(data) {
        return data != null && (data.mindElementShape == MindElementShapeType.Underline || data.mindElementShape == MindElementShapeType.Two_Underline);
    }

    setNodeToDictStructure(node) {
        if (node.value.isEmpty() || node.value.id == node.value.parentNodeId) {
            return;
        }
        if (!this.mainMindElementDataDict.containsKey(node.value.id)) {
            //导入基础属性
            this.mainMindElementDataDict.put(node.value.id, node);
        }

        node.value.isEdit = false;
        if (!this.selfStaticDataType) {
            IdGenerator.shared.setId(node.value.id);
        }
        let removeList = []
        node.children.forEach(child => {
            if (child.value.parentNodeId == node.value.id) {
                this.setNodeToDictStructure(child);
            } else {
                removeList.push(child);
            }
        })
        removeList.forEach(child => {
            node.removeChildrenNode(child);
        })
    }

    getLineDataById(id) {
        if (this.lineMindElementDataDict.containsKey(id)) {
            return this.lineMindElementDataDict.get(id);
        } else if (this.generalizationLineMindElementDataDict.containsKey(id)) {
            return this.generalizationLineMindElementDataDict.get(id);
        }
        return new MindElementData();
    }

    getNodeById(id) {
        if (this.mainMindElementDataDict.containsKey(id)) {
            return this.mainMindElementDataDict.get(id);
        }
        else if (this.generalizationMindElementDataDict.containsKey(id)) {

            return this.generalizationMindElementDataDict.get(id);
        }
        else if (this.encircleMindElementDataDict.containsKey(id)) {

            return new LineMindTypeNode(this.encircleMindElementDataDict.get(id));
        }
        else if (this.explainMindElementDataDict.containsKey(id)) {
            return new LineMindTypeNode(this.explainMindElementDataDict.get(id));
        }


        return new LineMindTypeNode();
    }

    changeNodeId(oldNode, newNode,
                generalizationDict = new HashMap(), 
                explainDict = new HashMap(), 
                encircleDict = new HashMap()) {
        if (this.getNodeById(newNode.value.id).isEmpty()) {
            return;
        }
        if (oldNode.isEmpty() || newNode.isEmpty() ||
            oldNode.children.length != newNode.children.length) {
            return;
        }
        let newId = newNode.value.id;
        let oldId = oldNode.value.id;
        let generalizationKeys = this.generalizationMindElementDataDict.keys()
        let generalizationKeysLength = generalizationKeys.length
        for (let index = 0; index < generalizationKeysLength; index++) {
            let key = generalizationKeys[index]
            let node = this.generalizationMindElementDataDict.get(key);
            if (node == null ||
                node.isEmpty() ||
                node.value.generalizationContent == null ||
                node.value.generalizationContent.targetIds == null ||
                node.value.generalizationContent.targetIds.indexOf(oldId) == -1) {
                continue;
            }
            var targetIds = []
            for (let cellIndex = 0; cellIndex < node.value.generalizationContent.targetIds.length; cellIndex++) {
                let cellId = node.value.generalizationContent.targetIds[cellIndex]
                if (cellId != oldId) {
                    targetIds.push(cellId);
                }
            }
            targetIds.push(newId);
            node.value.generalizationContent.targetIds = targetIds;
        }

        generalizationKeys = generalizationDict.keys()
        generalizationKeysLength = generalizationKeys.length
        for (let index = 0; index < generalizationKeysLength; index++) {
            let key = generalizationKeys[index]
            if (this.generalizationMindElementDataDict.containsKey(key)) {
                continue;
            }
            let node = generalizationDict.get(key);
            if (node == null ||
                node.isEmpty() ||
                node.value.generalizationContent == null ||
                node.value.generalizationContent.targetIds == null ||
                node.value.generalizationContent.targetIds.indexOf(oldId) == -1) {
                continue;
            }
            var targetIds = []
            for (let cellIndex = 0; cellIndex < node.value.generalizationContent.targetIds.length; cellIndex++) {
                let cellId = node.value.generalizationContent.targetIds[cellIndex]
                if (cellId != oldId) {
                    targetIds.push(cellId);
                }
            }
            targetIds.push(newId);
            let newGeneralizationNode = node.copy(true);
            newGeneralizationNode.value.generalizationContent.targetIds = targetIds;
            this.generalizationMindElementDataDict.put(newGeneralizationNode.value.id, newGeneralizationNode);
            for (let nodeIndex = 0; nodeIndex < newGeneralizationNode.children.length; nodeIndex++) {
                this.setNodeToDictStructure(newGeneralizationNode.children[nodeIndex]);
            }
        }

        let explainMindElementDataDictKeys = this.explainMindElementDataDict.keys();
        let explainMindElementDataDictKeysLength = explainMindElementDataDictKeys.length;
        for (let index = 0; index < explainMindElementDataDictKeysLength; index++) {
            let key = explainMindElementDataDictKeys[index];
            let data = this.explainMindElementDataDict.get(key);
            if (data == null ||
                data.isEmpty() ||
                data.lineContent == null ||
                data.parentNodeId != oldId) {
                continue;
            }
            data.parentNodeId = newId;
        }

        explainMindElementDataDictKeys = explainDict.keys();
        explainMindElementDataDictKeysLength = explainMindElementDataDictKeys.length;
        for (let index = 0; index < explainMindElementDataDictKeysLength; index++) {
            let key = explainMindElementDataDictKeys[index];
            if (this.explainMindElementDataDict.containsKey(key)) {
                continue;
            }
            let data = explainDict.get(key);
            if (data == null ||
                data.isEmpty() ||
                data.lineContent == null ||
                data.parentNodeId != oldId) {
                continue;
            }
            data.parentNodeId = newId;
            let newExplainData = data.copy();
            newExplainData.id = IdGenerator.shared.getId();
            newExplainData.parentNodeId = newId;
            if (newExplainData.lineContent != null) {
                newExplainData.lineContent.targetId = newId;
            }
            this.explainMindElementDataDict.put(newExplainData.id, newExplainData);
        }

        let line = this.getNodeLine(oldId);
        if (!line.isEmpty() && line.lineContent != null && oldNode.value.type == newNode.value.type) {
            line.parentNodeId = newNode.value.parentNodeId;
            line.lineContent.targetId = newNode.value.id;
            this.textElementLineMindElementDataDict.remove(oldId);
            this.textElementLineMindElementDataDict.put(newId, line);
        }
        let encircleKeys = this.encircleMindElementDataDict.keys()
        let encircleKeysLength = encircleKeys.length
        for (let index = 0; index < encircleKeysLength; index++) {
            let key = encircleKeys[index]
            let mind = this.encircleMindElementDataDict.get(key);
            if (mind == null ||
                mind.isEmpty() || mind.lineContent == null ||
                mind.lineContent.targetIds == null ||
                mind.lineContent.targetIds.indexOf(oldId) == -1) {
                continue;
            }
            if (mind.parentNodeId == oldId) {
                mind.parentNodeId = newId;
            }
            if (mind.lineContent.targetId == oldId) {
                mind.lineContent.targetId = newId;
            }
            var targetIds = []
            for (let cellIndex = 0; cellIndex < mind.lineContent.targetIds.length; cellIndex++) {
                let cellId = mind.lineContent.targetIds[cellIndex]
                if (cellId != oldId) {
                    targetIds.push(cellId);
                }
            }
            targetIds.push(newId);
            mind.lineContent.targetIds = targetIds;
        }
        encircleKeys = encircleDict.keys()
        encircleKeysLength = encircleKeys.length
        for (let index = 0; index < encircleKeysLength; index++) {
            let key = encircleKeys[index]
            if (this.encircleMindElementDataDict.containsKey(key)) {
                continue;
            }
            let mind = encircleDict.get(key);
            if (mind == null ||
                mind.isEmpty() || mind.lineContent == null ||
                mind.lineContent.targetIds == null ||
                mind.lineContent.targetIds.indexOf(oldId) == -1) {
                continue;
            }
            let newEncircleMind = mind.copy();
            newEncircleMind.id = IdGenerator.shared.getId();

            if (newEncircleMind.parentNodeId == oldId) {
                newEncircleMind.parentNodeId = newId;
            }
            if (newEncircleMind.lineContent.targetId == oldId) {
                newEncircleMind.lineContent.targetId = newId;
            }
            var targetIds = []
            for (let cellIndex = 0; cellIndex < mind.lineContent.targetIds.length; cellIndex++) {
                let cellId = mind.lineContent.targetIds[cellIndex]
                if (cellId != oldId) {
                    targetIds.push(cellId);
                }
            }
            targetIds.push(newId);
            newEncircleMind.lineContent.targetIds = targetIds;
            this.encircleMindElementDataDict.put(newEncircleMind.id, newEncircleMind);
        }
        for (let index = 0; index < oldNode.children.length; index++) {
            this.changeNodeId(oldNode.children[index], newNode.children[index], 
                generalizationDict, explainDict, encircleDict)
        }
    }

    setNodeLayout(id, layout) {  //设置节点的布局。
        let node = this.getNodeById(id);
        if (node.isEmpty()) {
            return;
        }
        let nodeOriginalLayout = node.value.layout;
        this.setLineWidthForBracketsLayout(id);
        if (node.value.type == MindElementType.CONTENT_GENERALIZATION &&
            node.value.generalizationContent != null &&
            !node.value.generalizationContent.targetIds.isEmpty()) {
            let targetNode = this.getNodeById(node.value.generalizationContent.targetIds[0]);
            if (targetNode.isEmpty()) {
                node.value.layout = this.globalLayout;
            } else {
                node.value.layout = MindLayoutSelect.getLayout(targetNode.value.layout, node.value.layout, layout);
            }
        } else {
            let parentNode = this.getNodeById(node.value.parentNodeId);
            if (parentNode.isEmpty()) {
                node.value.layout = MindLayoutSelect.getLayout(this.globalLayout, node.value.layout, layout);
            } else {
                node.value.layout = MindLayoutSelect.getLayout(parentNode.value.layout, node.value.layout, layout);
            }
        }

        let generalizationNode = this.getNodeGeneralization(node.value);
        if (!generalizationNode.isEmpty()) {
            generalizationNode.value.layout = node.value.layout;
            generalizationNode.children.forEach(child => {
                this.setChidlNodeLayout(child, child.value.layout);
            })
        }

        if (!node.children.isEmpty()) {
            node.children.forEach(child => {
                if ((layout == NodeLayoutType.LAYOUT_FORM || layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) &&
                    (this.isRootNode(node.value.id) || child.value.layout == nodeOriginalLayout)) {
                    this.setChidlNodeLayout(child, layout);
                } else if (layout != NodeLayoutType.LAYOUT_FORM && layout != NodeLayoutType.LAYOUT_FORM_HORIZONTAL &&
                        child.value.layout == nodeOriginalLayout && (nodeOriginalLayout == NodeLayoutType.LAYOUT_FORM || nodeOriginalLayout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL)) {
                    this.setChidlNodeLayout(child, layout);
                } else {
                    this.setChidlNodeLayout(child, child.value.layout);
                }
            })
        }
    }

    setChidlNodeLayout(node, layout) {
        this.setLineWidthForBracketsLayout(node.value.id);
        let nodeOriginalLayout = node.value.layout;
        let parentNode = this.getNodeById(node.value.parentNodeId);
        if (parentNode.isEmpty()) {
            node.value.layout = MindLayoutSelect.getLayout(this.globalLayout, node.value.layout, layout);
        } else {
            node.value.layout = MindLayoutSelect.getLayout(parentNode.value.layout, node.value.layout, layout);
        }
        let generalizationNode = this.getNodeGeneralization(node);
        if (!generalizationNode.isEmpty()) {
            generalizationNode.value.layout = node.value.layout;
            generalizationNode.children.forEach(child => {
                this.setChidlNodeLayout(child, child.value.layout);
            })
        }
        node.children.forEach(child => {
            if ((layout == NodeLayoutType.LAYOUT_FORM || layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) &&
                (this.isRootNode(node.value.id) || child.value.layout == nodeOriginalLayout)) {
                this.setChidlNodeLayout(child, layout);
            } else if (layout != NodeLayoutType.LAYOUT_FORM && layout != NodeLayoutType.LAYOUT_FORM_HORIZONTAL &&
                    child.value.layout == nodeOriginalLayout &&
                    (nodeOriginalLayout == NodeLayoutType.LAYOUT_FORM || nodeOriginalLayout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL)) {
                this.setChidlNodeLayout(child, layout);
            } else {
                this.setChidlNodeLayout(child, child.value.layout);
            }
        })
    }

    setElementLayout(layout, isInit = false) {
        if (this.globalLayout != layout && this.rootTreeNode != null && !isInit) {
            if (this.globalLayout == NodeLayoutType.LAYOUT_FORM) {
                //new FormLayut().changeLayoutFromForm(this.rootTreeNode);
            } else if (this.globalLayout == NodeLayoutType.LAYOUT_TRIANGLE) {
                new TriangleLayout().changeLayoutFromTriangle(this.rootTreeNode);
            } else if (this.globalLayout == NodeLayoutType.LAYOUT_CIRCLE) {
                new CircleLayout().changeLayoutFromCircle(this.rootTreeNode);
            } else if (this.globalLayout == NodeLayoutType.LAYOUT_RADIATE && this.elementLayout.changeLayoutFromRadiate != null) {
                this.elementLayout.changeLayoutFromRadiate(this.rootTreeNode);
            } else if (this.globalLayout == NodeLayoutType.LAYOUT_FISH_RIGHT) {
                new FishLayout().changeLayoutFromFish(this.rootTreeNode);
            } else if (this.globalLayout == NodeLayoutType.LAYOUT_FISH_LEFT) {
                new FishLeftLayout().changeLayoutFromFish(this.rootTreeNode);
            }

            if (layout == NodeLayoutType.LAYOUT_FORM) {
                //new FormLayut().changeLayoutToForm(this.rootTreeNode);
            } else if (layout == NodeLayoutType.LAYOUT_TRIANGLE) {
                new TriangleLayout().changeLayoutToTriangle(this.rootTreeNode);
            } else if (layout == NodeLayoutType.LAYOUT_CIRCLE) {
                this.changeLayoutToCircle(this.rootTreeNode);
            } else if (layout == NodeLayoutType.LAYOUT_RADIATE) {
                new RadiateLayout().changeLayoutToRadiate(this.rootTreeNode);
            } else if (layout == NodeLayoutType.LAYOUT_FISH_RIGHT) {
                new FishLayout().changeLayoutToFish(this.rootTreeNode);
            } else if (layout == NodeLayoutType.LAYOUT_FISH_LEFT) {
                new FishLeftLayout().changeLayoutToFish(this.rootTreeNode);
            }

            this.setLineWidthForBracketsLayout(this.rootTreeNode.value.id);
        }
        this.globalLayout = layout;
        if (this.mindDisplayType == MindDisplayType.MindMap) {
            if (layout == NodeLayoutType.LAYOUT_RIGHT) {
                this.elementLayout = new RightLayout();
                if (this.globalLineLayout == LineLayout.STRAIGHT_LINE ||
                    this.globalLineLayout == LineLayout.STRAIGHT_LINE_2) {
                    this.changeLineLayout(this.rootTreeNode, LineLayout.CURVE_LINE, false);
                }
            } else if (layout == NodeLayoutType.LAYOUT_BOTTOM) {
                this.elementLayout = new BottomLayout();
                if (this.globalLineLayout == LineLayout.CURVE_LINE ||
                    this.globalLineLayout == LineLayout.CURVE_LINE_2 ||
                    this.globalLineLayout == LineLayout.STRAIGHT_LINE ||
                    this.globalLineLayout == LineLayout.STRAIGHT_LINE_2 ||
                    this.globalLineLayout == LineLayout.CURVE_LINE_AVERAGE ||
                    this.globalLineLayout == LineLayout.CURVE_LINE_CIRCULAR ||
                    this.globalLineLayout == LineLayout.STRAIGHT_CURVE_LINE) {
                    this.changeLineLayout(this.rootTreeNode, LineLayout.RIGHT_ANGLE_CORNER_LINE, true);
                }
            }
            else if (layout == NodeLayoutType.LAYOUT_LEFT) {
                this.elementLayout = new LeftLayout();
                if (this.globalLineLayout == LineLayout.STRAIGHT_LINE ||
                    this.globalLineLayout == LineLayout.STRAIGHT_LINE_2) {
                    this.changeLineLayout(this.rootTreeNode, LineLayout.CURVE_LINE, false);
                }
            }
            else if (layout == NodeLayoutType.LAYOUT_LEFT_RIGHT) {
                this.elementLayout = new LeftRightLayout();
                if (this.globalLineLayout == LineLayout.STRAIGHT_LINE ||
                    this.globalLineLayout == LineLayout.STRAIGHT_LINE_2) {
                    this.changeLineLayout(this.rootTreeNode, LineLayout.CURVE_LINE, false);
                }
            }
            else if (layout == NodeLayoutType.LAYOUT_RIGHT_LEFT) {
                this.elementLayout = new RightLeftLayout();
                if (this.globalLineLayout == LineLayout.STRAIGHT_LINE ||
                    this.globalLineLayout == LineLayout.STRAIGHT_LINE_2) {
                    this.changeLineLayout(this.rootTreeNode, LineLayout.CURVE_LINE, false);
                }
            }
            else if (layout == NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE) {
                this.elementLayout = new RightLeftClockwiseLayout();
                if (this.globalLineLayout == LineLayout.STRAIGHT_LINE ||
                    this.globalLineLayout == LineLayout.STRAIGHT_LINE_2) {
                    this.changeLineLayout(this.rootTreeNode, LineLayout.CURVE_LINE, false);
                }
            }
            else if (layout == NodeLayoutType.LAYOUT_BUBBLE) {
                this.elementLayout = new BubbleLayout();
                this.setvColorMode(new BubbleColorMode());
            }
            else if (layout == NodeLayoutType.LAYOUT_FORM) {
                this.elementLayout = new FormLayut();
            }
            else if (layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                this.elementLayout = new FormHorizontalLayut();
            }
            else if (layout == NodeLayoutType.LAYOUT_TREE_RIGHT) {
                this.elementLayout = new TreeRightLayout();
                if (this.globalLineLayout == LineLayout.CURVE_LINE ||
                    this.globalLineLayout == LineLayout.CURVE_LINE_2) {
                    this.globalLineLayout = LineLayout.RIGHT_ANGLE_CORNER_LINE;
                }
            }
            else if (layout == NodeLayoutType.LAYOUT_TREE_LEFT) {
                this.elementLayout = new TreeLeftLayout();
                if (this.globalLineLayout == LineLayout.CURVE_LINE ||
                    this.globalLineLayout == LineLayout.CURVE_LINE_2) {
                    this.globalLineLayout = LineLayout.RIGHT_ANGLE_CORNER_LINE;
                }
            }
            else if (layout == NodeLayoutType.LAYOUT_TREE_LEFT_RIGHT) {
                this.elementLayout = new TreeLeftRightLayout();
                if (this.globalLineLayout == LineLayout.CURVE_LINE ||
                    this.globalLineLayout == LineLayout.CURVE_LINE_2) {
                    this.globalLineLayout = LineLayout.RIGHT_ANGLE_CORNER_LINE;
                }
            } else if (layout == NodeLayoutType.LAYOUT_TRIANGLE) {
                this.elementLayout = new TriangleLayout();
            } else if (layout == NodeLayoutType.LAYOUT_BRACKETS_RIGHT) {
                this.elementLayout = new BracketsRightLayout();
            } else if (layout == NodeLayoutType.LAYOUT_BRACKETS_LEFT) {
                this.elementLayout = new BracketsLeftLayout();
            } else if (layout == NodeLayoutType.LAYOUT_RADIATION_MAP) {
                this.elementLayout = new RightLayout();
            } else if (layout == NodeLayoutType.LAYOUT_TOP) {
                this.elementLayout = new TopLayout();
                if (this.globalLineLayout == LineLayout.CURVE_LINE ||
                    this.globalLineLayout == LineLayout.CURVE_LINE_2 ||
                    this.globalLineLayout == LineLayout.STRAIGHT_LINE ||
                    this.globalLineLayout == LineLayout.STRAIGHT_LINE_2 ||
                    this.globalLineLayout == LineLayout.CURVE_LINE_AVERAGE ||
                    this.globalLineLayout == LineLayout.CURVE_LINE_CIRCULAR ||
                    this.globalLineLayout == LineLayout.STRAIGHT_CURVE_LINE) {
                    this.changeLineLayout(this.rootTreeNode, LineLayout.RIGHT_ANGLE_CORNER_LINE, true);
                }
            } else if (layout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT) {
                this.elementLayout = new TreeTopRightLayout();
                if (this.globalLineLayout == LineLayout.CURVE_LINE ||
                    this.globalLineLayout == LineLayout.CURVE_LINE_2) {
                    this.globalLineLayout = LineLayout.RIGHT_ANGLE_CORNER_LINE;
                }
            } else if (layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT) {
                this.elementLayout = new TreeTopLeftLayout();
                if (this.globalLineLayout == LineLayout.CURVE_LINE ||
                    this.globalLineLayout == LineLayout.CURVE_LINE_2) {
                    this.globalLineLayout = LineLayout.RIGHT_ANGLE_CORNER_LINE;
                }
            } else if (layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT_RIGHT) {
                this.elementLayout = new TreeTopLeftRightLayout();
                if (this.globalLineLayout == LineLayout.CURVE_LINE ||
                    this.globalLineLayout == LineLayout.CURVE_LINE_2) {
                    this.globalLineLayout = LineLayout.RIGHT_ANGLE_CORNER_LINE;
                }
            } else if (layout == NodeLayoutType.LAYOUT_HORIZONTAL_RIGHT) {
                this.elementLayout = new HorizontalRightLayout();
                if (this.globalLineLayout == LineLayout.CURVE_LINE ||
                    this.globalLineLayout == LineLayout.CURVE_LINE_2) {
                    this.globalLineLayout = LineLayout.RIGHT_ANGLE_CORNER_LINE;
                }
            } else if (layout == NodeLayoutType.LAYOUT_VERTICAL_RIGHT) {
                this.elementLayout = new VerticaRightLayout();
                if (this.globalLineLayout == LineLayout.CURVE_LINE ||
                    this.globalLineLayout == LineLayout.CURVE_LINE_2) {
                    this.globalLineLayout = LineLayout.RIGHT_ANGLE_CORNER_LINE;
                }
            } else if (layout == NodeLayoutType.LAYOUT_FREE_TREE) {
                this.elementLayout = new FreeTreeLayout();
                // this.elementLayout = new NewFreeTreeLayout();                
            } else if (layout == NodeLayoutType.LAYOUT_FISH_RIGHT) {
                this.elementLayout = new FishLayout();
            } else if (layout == NodeLayoutType.LAYOUT_FISH_LEFT) {
                this.elementLayout = new FishLeftLayout();
            } else if (layout == NodeLayoutType.LAYOUT_CIRCLE) {
                this.elementLayout = new CircleLayout();
            } else if (layout == NodeLayoutType.LAYOUT_RADIATE) {
                this.elementLayout = new RadiateLayout();
            } else {
                this.elementLayout = new RightLayout();
            }

        } else if (this.mindDisplayType == MindDisplayType.Outline) {
            this.elementLayout = new OutlineLayout()
        }
        this.elementLayout.mindLayoutSelect = MindLayoutSelect
        if (this.rootTreeNode != null) {
            let parentOriginalLayout = this.rootTreeNode.value.layout;
            this.rootTreeNode.value.layout = this.globalLayout;
            if (!isInit) {
                if (this.globalLayout == NodeLayoutType.LAYOUT_FORM) {
                    this.setNodeLayoutForGlobalForFrom(new LineMindTypeNode(), this.rootTreeNode, parentOriginalLayout);
                } else if (this.globalLayout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                    this.setNodeLayoutForGlobalForFromHorizontal(new LineMindTypeNode(), this.rootTreeNode, parentOriginalLayout);
                } else {
                    this.setNodeLayoutForGlobal(new LineMindTypeNode(), this.rootTreeNode, parentOriginalLayout);
                }
            }
        }

        if (layout != NodeLayoutType.LAYOUT_TRIANGLE) {
            this.generalizationMindElementDataDict.keys().forEach(key => {
                let node = this.generalizationMindElementDataDict.get(key);
                let parent = this.getNodeById(node.value.parentNodeId);
                if (!parent.isEmpty() && parent.value.type != MindElementType.CONTENT_GENERALIZATION) {
                    this.setNodeLayoutForGlobal(new LineMindTypeNode(), node, parent.value.layout);
                } else {
                    this.setNodeLayoutForGlobal(new LineMindTypeNode(), node, this.globalLayout);
                }

            });
        }

        this.encircleMindElementDataDict.keys().forEach(key => {
            let data = this.encircleMindElementDataDict.get(key);
            data.layout = this.globalLayout;
        });

        this.elementLayout.delegate = this;
    }

    setNodeLayoutForGlobal(parentNode, node, parentOriginalLayout) {
        let nodeOriginalLayout = (this.isRootNode(node.value.id) || parentNode.isEmpty()) ? parentOriginalLayout : node.value.layout;
        if (this.isRootNode(node.value.id) || parentNode.isEmpty()) {
            if (node.value.type == MindElementType.CONTENT_GENERALIZATION &&
                node.value.generalizationContent != null &&
                !node.value.generalizationContent.targetIds.isEmpty()) {
                let targetNode = this.getNodeById(node.value.generalizationContent.targetIds[0]);
                if (targetNode.isEmpty()) {
                    node.value.layout = this.globalLayout;
                } else {
                    node.value.layout = targetNode.value.layout;
                }
            } else {
                node.value.layout = this.globalLayout;
            }
        } else {
            if (parentNode.isEmpty()) {
                node.value.layout = MindLayoutSelect.getLayout(this.globalLayout, node.value.layout, node.value.layout);
            } else {
                if (this.globalLayout == NodeLayoutType.LAYOUT_FISH_LEFT) {
                    node.value.layout = NodeLayoutType.LAYOUT_LEFT;
                } else if (this.globalLayout == NodeLayoutType.LAYOUT_FISH_RIGHT) {
                    node.value.layout = NodeLayoutType.LAYOUT_RIGHT;
                } else if (parentOriginalLayout == NodeLayoutType.LAYOUT_FORM &&
                    node.value.layout == NodeLayoutType.LAYOUT_FORM) {
                    node.value.layout = MindLayoutSelect.getLayout(parentNode.value.layout, parentNode.value.layout, parentNode.value.layout);
                } else if (parentOriginalLayout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL &&
                    node.value.layout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
                    node.value.layout = MindLayoutSelect.getLayout(parentNode.value.layout, parentNode.value.layout, parentNode.value.layout);
                } else if (parentNode.value.layout == NodeLayoutType.LAYOUT_TREE_LEFT_RIGHT ||
                    parentNode.value.layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT_RIGHT) {                    
                    let leftLayoutMaxIndex = Math.ceil(parentNode.children.length / 2)
                    let targetLayout = NodeLayoutType.LAYOUT_LEFT
                    for (let index = 0; index < parentNode.children.length; index++) {
                        if (parentNode.children[index].value.id == node.value.id) {
                            targetLayout = index < leftLayoutMaxIndex ? NodeLayoutType.LAYOUT_LEFT : NodeLayoutType.LAYOUT_RIGHT;
                            break;
                        }
                    }                    
                    node.value.layout = MindLayoutSelect.getLayout(parentNode.value.layout, node.value.layout, targetLayout);
                } else {
                    node.value.layout = MindLayoutSelect.isNodeLayoutEqual(parentOriginalLayout, node.value.layout) ?
                        MindLayoutSelect.getLayout(parentNode.value.layout, node.value.layout, parentNode.value.layout) :
                        MindLayoutSelect.getLayout(parentNode.value.layout, node.value.layout, node.value.layout);
                }
            }
        }
        node.children.forEach(child => {
            this.setNodeLayoutForGlobal(node, child, nodeOriginalLayout);
        })
    }

    changeLineLayout(node, lineLayout, isChangeAllChild = false) {
        if (node == null || node.isEmpty()) {
            return;
        }
        
        let children = node.children
        if (isChangeAllChild || (lineLayout == LineLayout.FULL_RIGHT_ANGLE_CORNER_ARROW_LINE ||
            lineLayout == LineLayout.RIGHT_ANGLE_CORNER_ARROW_LINE) || 
            (this.globalLineLayout == LineLayout.FULL_RIGHT_ANGLE_CORNER_ARROW_LINE ||
                this.globalLineLayout == LineLayout.RIGHT_ANGLE_CORNER_ARROW_LINE)) {
                children = node.getAllChild()
        }
        let childCount = children.length;
        for (let index = 0; index < childCount; index++) {
            let child = children[index];
            let lineData = this.getNodeLine(child.value.id);
            if (!lineData.isEmpty() && lineData.lineContent != null) {
                lineData.lineContent.lineLayout = lineLayout;
            }
        }
        if (this.isRootNode(node.value.id)) {
            this.globalLineLayout = lineLayout;
        }
    }

    setNodeLayoutForGlobalForFrom(parentNode, node, rootOriginalLayout) {
        let nodeOriginalLayout = (this.isRootNode(node.value.id) || parentNode.isEmpty()) ? rootOriginalLayout : node.value.layout;
        if (this.isRootNode(node.value.id) || parentNode.isEmpty()) {
            if (node.value.type == MindElementType.CONTENT_GENERALIZATION &&
                    node.value.generalizationContent != null &&
                    !node.value.generalizationContent.targetIds.isEmpty()) {
                        let targetNode = this.getNodeById(node.value.generalizationContent.targetIds[0]);
                if (targetNode.isEmpty()) {
                    node.value.layout = this.globalLayout;
                } else {
                    node.value.layout = targetNode.value.layout;
                }
            } else {
                node.value.layout = this.globalLayout;
            }
            if (rootOriginalLayout == NodeLayoutType.LAYOUT_TREE_LEFT_RIGHT ||
                    rootOriginalLayout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT_RIGHT ||
                    rootOriginalLayout == NodeLayoutType.LAYOUT_RIGHT_LEFT ||
                    rootOriginalLayout == NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE ||
                    rootOriginalLayout == NodeLayoutType.LAYOUT_LEFT_RIGHT ||
                    rootOriginalLayout == NodeLayoutType.LAYOUT_CIRCLE ||
                    rootOriginalLayout == NodeLayoutType.LAYOUT_TRIANGLE ||
                    rootOriginalLayout == NodeLayoutType.LAYOUT_HORIZONTAL_RIGHT ||
                    rootOriginalLayout == NodeLayoutType.LAYOUT_VERTICAL_RIGHT ||
                    rootOriginalLayout == NodeLayoutType.LAYOUT_FREE_TREE) {
                nodeOriginalLayout = NodeLayoutType.LAYOUT_FORM;
            }

        } else {
            if (parentNode.isEmpty()) {
                node.value.layout = MindLayoutSelect.getLayout(this.globalLayout, node.value.layout, node.value.layout);
            } else {
                if (nodeOriginalLayout == node.value.layout) {
                    node.value.layout = NodeLayoutType.LAYOUT_FORM;
                } else {
                    node.value.layout = MindLayoutSelect.isNodeLayoutEqual(rootOriginalLayout, node.value.layout) ?
                            MindLayoutSelect.getLayout(parentNode.value.layout, node.value.layout, parentNode.value.layout) :
                            MindLayoutSelect.getLayout(parentNode.value.layout, node.value.layout, node.value.layout);
                }
            }
        }
        node.children.forEach(child => {
            this.setNodeLayoutForGlobalForFrom(node, child, nodeOriginalLayout);
        })
    }

    setNodeLayoutForGlobalForFromHorizontal(parentNode, node, rootOriginalLayout) {
        let nodeOriginalLayout = (this.isRootNode(node.value.id) || parentNode.isEmpty()) ? rootOriginalLayout : node.value.layout;
        if (this.isRootNode(node.value.id) || parentNode.isEmpty()) {
            if (node.value.type == MindElementType.CONTENT_GENERALIZATION &&
                    node.value.generalizationContent != null &&
                    !node.value.generalizationContent.targetIds.isEmpty()) {
                        let targetNode = this.getNodeById(node.value.generalizationContent.targetIds[0]);
                if (targetNode.isEmpty()) {
                    node.value.layout = this.globalLayout;
                } else {
                    node.value.layout = targetNode.value.layout;
                }
            } else {
                node.value.layout = this.globalLayout;
            }
            if (rootOriginalLayout == NodeLayoutType.LAYOUT_TREE_LEFT_RIGHT ||
                    rootOriginalLayout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT_RIGHT ||
                    rootOriginalLayout == NodeLayoutType.LAYOUT_RIGHT_LEFT ||
                    rootOriginalLayout == NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE ||
                    rootOriginalLayout == NodeLayoutType.LAYOUT_LEFT_RIGHT ||
                    rootOriginalLayout == NodeLayoutType.LAYOUT_CIRCLE ||
                    rootOriginalLayout == NodeLayoutType.LAYOUT_TRIANGLE ||
                    rootOriginalLayout == NodeLayoutType.LAYOUT_HORIZONTAL_RIGHT ||
                    rootOriginalLayout == NodeLayoutType.LAYOUT_VERTICAL_RIGHT ||
                    rootOriginalLayout == NodeLayoutType.LAYOUT_FREE_TREE) {
                nodeOriginalLayout = NodeLayoutType.LAYOUT_FORM_HORIZONTAL;
            }

        } else {
            if (parentNode.isEmpty()) {
                node.value.layout = MindLayoutSelect.getLayout(this.globalLayout, node.value.layout, node.value.layout);
            } else {
                if (nodeOriginalLayout == node.value.layout) {
                    node.value.layout = NodeLayoutType.LAYOUT_FORM_HORIZONTAL;
                } else {
                    node.value.layout = MindLayoutSelect.isNodeLayoutEqual(rootOriginalLayout, node.value.layout) ?
                            MindLayoutSelect.getLayout(parentNode.value.layout, node.value.layout, parentNode.value.layout) :
                            MindLayoutSelect.getLayout(parentNode.value.layout, node.value.layout, node.value.layout);
                }
            }
        }
        node.children.forEach(child => {
            this.setNodeLayoutForGlobalForFromHorizontal(node, child, nodeOriginalLayout);
        })
    }

    setvColorMode(mode) {
        this.lineColorMode = mode;
    }

    refreshLayout(isChange, settingData = new SettingData()) {
        if (this.rootTreeNode == null) {
            return;
        }
        this.elementLayout.setDatas(
            this.rootTreeNode,
            this.lineMindElementDataDict,
            this.mainMindElementDataDict,
            this.textElementLineMindElementDataDict,
            this.generalizationLineMindElementDataDict,
            this.generalizationMindElementDataDict,
            this.explainMindElementDataDict,
            this.mindMapStyleColor,
            this.mindBGColor,
            settingData);
        this.elementLayout.encircleMindElementDataDict = this.encircleMindElementDataDict;
        this.elementLayout.globalLayout = this.globalLineLayout;
        this.elementLayout.layout(isChange);
    }

    moveNodePoint(moveX, moveY) {
        if (this.rootTreeNode != null) {
            this.rootTreeNode.value.x += moveX;
            this.rootTreeNode.value.y += moveY;
            for (let index = 0; index < this.rootTreeNode.children.length; index++) {
                const child = this.rootTreeNode.children[index];
                if (child.value.isFreeLayoutNode) {
                    child.value.x += moveX;
                    child.value.y += moveY;
                }
            }
        }
    }

    createSubjectNode(parentNodeId, level, point) {
        let textData = new MindElementData();

        textData.id = IdGenerator.shared.getId();
        textData.type = MindElementType.SUBJECT;
        textData.parentNodeId = parentNodeId;
        textData.level = level;

        let parentNode = this.getNodeById(parentNodeId);
        let brotherNode = (new LineMindTypeNode).emptyMindNode();
        var text = (this.globalLayout == NodeLayoutType.LAYOUT_BOTTOM ||
            this.globalLayout == NodeLayoutType.LAYOUT_TOP) ?
            (new UiUtil).getString(StringsLanguage.Mind_Organization_Main_Topic) :
            (new UiUtil).getString(StringsLanguage.Mind_Edit_Main_Topic);

        text = text + " " + (this.rootTreeNode.children.length + 1);

        if (!parentNode.isEmpty() && parentNode.children.length > 0) {
            brotherNode = parentNode.children[0];

            if (this.globalLayout == NodeLayoutType.LAYOUT_HORIZONTAL_RIGHT) {
                if (parentNode.children.length > 1) {
                    let layout1 = parentNode.children[0].value.layout;
                    let layout2 = parentNode.children[1].value.layout
                    let layoutLast = parentNode.children[parentNode.children.length - 1].value.layout;
                    
                    if ((layout1 == NodeLayoutType.LAYOUT_TREE_RIGHT && layout2 == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT) ||
                        layout2 == NodeLayoutType.LAYOUT_TREE_RIGHT && layout1 == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT) {
                        if (layoutLast == NodeLayoutType.LAYOUT_TREE_RIGHT) {
                            textData.layout = NodeLayoutType.LAYOUT_TOP_TREE_RIGHT
                        } else if (layoutLast == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT) {
                            textData.layout = NodeLayoutType.LAYOUT_TREE_RIGHT
                        } else {
                            textData.layout = brotherNode.value.layout;
                        }
                    } else if ((layout1 == NodeLayoutType.LAYOUT_TREE_LEFT && layout2 == NodeLayoutType.LAYOUT_TOP_TREE_LEFT) ||
                        layout2 == NodeLayoutType.LAYOUT_TREE_LEFT && layout1 == NodeLayoutType.LAYOUT_TOP_TREE_LEFT) {
                        if (layoutLast == NodeLayoutType.LAYOUT_TREE_LEFT) {
                            textData.layout = NodeLayoutType.LAYOUT_TOP_TREE_LEFT
                        } else if (layoutLast == NodeLayoutType.LAYOUT_TOP_TREE_LEFT) {
                            textData.layout = NodeLayoutType.LAYOUT_TREE_LEFT
                        } else {
                            textData.layout = brotherNode.value.layout;
                        }
                    } else if ((layout1 == NodeLayoutType.LAYOUT_BOTTOM && layout2 == NodeLayoutType.LAYOUT_TOP) ||
                        layout2 == NodeLayoutType.LAYOUT_BOTTOM && layout1 == NodeLayoutType.LAYOUT_TOP) {
                        if (layoutLast == NodeLayoutType.LAYOUT_BOTTOM) {
                            textData.layout = NodeLayoutType.LAYOUT_TOP
                        } else if (layoutLast == NodeLayoutType.LAYOUT_TOP) {
                            textData.layout = NodeLayoutType.LAYOUT_BOTTOM
                        } else {
                            textData.layout = brotherNode.value.layout;
                        }
                    } else if ((layout1 == NodeLayoutType.LAYOUT_TREE_LEFT_RIGHT && layout2 == NodeLayoutType.LAYOUT_TOP_TREE_LEFT_RIGHT) ||
                        layout2 == NodeLayoutType.LAYOUT_TREE_LEFT_RIGHT && layout1 == NodeLayoutType.LAYOUT_TOP_TREE_LEFT_RIGHT) {
                        if (layoutLast == NodeLayoutType.LAYOUT_TREE_LEFT_RIGHT) {
                            textData.layout = NodeLayoutType.LAYOUT_TOP_TREE_LEFT_RIGHT
                        } else if (layoutLast == NodeLayoutType.LAYOUT_TOP_TREE_LEFT_RIGHT) {
                            textData.layout = NodeLayoutType.LAYOUT_TREE_LEFT_RIGHT
                        } else {
                            textData.layout = brotherNode.value.layout;
                        }
                    } else {
                        textData.layout = brotherNode.value.layout;
                    }
                } else {
                    textData.layout = brotherNode.value.layout;
                }
            } else {
                textData.layout = brotherNode.value.layout;
            }
        } else {
            let brotherNodeTextContent = new TextContent(text);
            brotherNode.value.textContent = brotherNodeTextContent;
            let linesColors = this.mindMapStyleColor.getLineColors();
            if (this.mindMapStyleColor.getSubjectBorderColorFollowLineColor() && linesColors != null && linesColors.length > 1) {
                brotherNode.value.styleColor = this.mindMapStyleColor.getLineColor();
                brotherNode.value.styleFillColor = this.mindMapStyleColor.getSubjectViewFillColor();
                brotherNode.value.backgroundColor = this.mindMapStyleColor.getSubjectViewFillColor();
                brotherNode.value.borderColor = linesColors[0];
            } else if (linesColors != null && linesColors.length > 1) {
                let index = parentNode.children.length;
                brotherNode.value.styleColor = linesColors[index % linesColors.length];
                brotherNode.value.styleFillColor = linesColors[index % linesColors.length];
                brotherNode.value.backgroundColor = linesColors[index % linesColors.length];
                brotherNode.value.borderColor = this.mindMapStyleColor.getSubjectBorderColor();
            } else {
                brotherNode.value.styleColor = this.mindMapStyleColor.getLineColor();
                brotherNode.value.styleFillColor = this.mindMapStyleColor.getSubjectViewFillColor();
                brotherNode.value.backgroundColor = this.mindMapStyleColor.getSubjectViewFillColor();
                brotherNode.value.borderColor = this.mindMapStyleColor.getSubjectBorderColor();
            }

            brotherNode.value.mindElementShape = this.mindMapFramework.getSubjectNodeShapeType();            
            brotherNode.value.borderWidth = this.mindMapFramework.getSubjectBorderWidths();
            brotherNodeTextContent.textColor = this.mindMapStyleColor.getSubjectTextColor();
            brotherNodeTextContent.textFontSize = this.mindMapStyleColor.getSubjectTextFontSize();
            brotherNodeTextContent.textBold = true;
            textData.layout = parentNode.isEmpty() ? this.globalLayout : parentNode.value.layout;            
        }


        let textContent = new TextContent(text);

        textContent.x = 0;
        textContent.y = 0;
        textContent.width = 65;
        textContent.height = 38;
        textData.textContent = textContent;

        textData.styleColor = brotherNode.value.styleColor;
        textData.styleFillColor = brotherNode.value.styleFillColor;
        if (this.globalLayout == NodeLayoutType.LAYOUT_BUBBLE ||
            this.globalLayout == NodeLayoutType.LAYOUT_CIRCLE) {
            textData.mindElementShape = MindElementShapeType.Circular;
            if (Colors.isClear(textData.backgroundColor) &&
                Colors.isClear(textData.borderColor)) {
                let line = this.getNodeLine(textData.parentNodeId);
                if (!line.isEmpty() && line.lineContent != null) {
                    textData.borderColor = line.lineContent.color;
                }
            }
        } else {
            textData.mindElementShape = brotherNode.value.mindElementShape;
        }
        if (this.lineColors.length > 0) {
            let index = parentNode.children.length;
            let point = index % this.lineColors.length;
            if (!Colors.isClear(brotherNode.value.borderColor) && 
                brotherNode.value.borderWidth > 0) {
                textData.borderColor = this.lineColors[point];//brotherNode.value.borderColor;
            } else {
                textData.borderColor = Colors.clear
            }
            if (!Colors.isClear(brotherNode.value.backgroundColor) && 
                (Colors.isClear(textData.borderColor) || brotherNode.value.borderWidth == 0)) {
                textData.backgroundColor = this.lineColors[point];
            } else {
                if (!Colors.isClear(brotherNode.value.borderColor) && brotherNode.value.borderWidth > 0 && 
                    brotherNode.value.borderColor == brotherNode.value.backgroundColor) {
                        textData.backgroundColor = textData.borderColor
                } else if (!Colors.isClear(brotherNode.value.borderColor) && brotherNode.value.borderWidth > 0 &&
                    !Colors.isClear(brotherNode.value.backgroundColor)) {
                        textData.backgroundColor = brotherNode.value.backgroundColor
                } else {
                    textData.backgroundColor = Colors.clear
                }
            }       
        } else {
            textData.backgroundColor = brotherNode.value.backgroundColor;
            textContent.textColor = brotherNode.value.textContent.textColor;
            textData.borderColor = brotherNode.value.borderColor;
        }
        
        textData.borderWidth = brotherNode.value.borderWidth;
        textData.backgroundFullType = brotherNode.value.backgroundFullType;
        textData.backgroundColorAlpha = brotherNode.value.backgroundColorAlpha;
        
        textContent.textShadow = brotherNode.value.textContent.textShadow;
        textContent.bgColor = brotherNode.value.textContent.bgColor;
        textContent.textFontSize = brotherNode.value.textContent.textFontSize;
        textContent.textBold = brotherNode.value.textContent.textBold;
        if (parentNode.value.layout == NodeLayoutType.LAYOUT_BOTTOM ||
            parentNode.value.layout == NodeLayoutType.LAYOUT_TOP) {
            textData.alignmentType = MindElementAlignmentType.MIDDLE
        }
        textData.checkShapeCard(this.mindBGColor)
        textData.checkSetTextColor(this.mindBGColor)
        if ((textData.mindElementShape == MindElementShapeType.Underline ||
            textData.mindElementShape == MindElementShapeType.Two_Underline) && 
            !textData.isBackgroundAndBorder()) {
            textData.mindElementShape = MindElementShapeType.Corner
        }
        MindElementCalculation.set(textData).caluleTextForData().calcule();
        let node = new LineMindTypeNode(textData);
        this.mainMindElementDataDict.put(textData.id, node);
        return node;
    }

    addBrotherMindElement(targetId) {
        let targetNodeId = targetId;

        let targetNode = this.getNodeById(targetNodeId);
        if (targetNode.isEmpty() || targetNode.value.type == MindElementType.EXPLAIN) {
            return (new LineMindTypeNode).emptyMindNode();
        }
        let parentNode = this.getNodeById(targetNode.value.parentNodeId);
        let parentNodeData = parentNode.value;

        if (parentNode.isEmpty()) {
            return this.addChildMindElement(targetId);
        } else {
            let node;
            let point = parentNode.getChildIndex(targetNode);
            if (parentNodeData.type == MindElementType.MAIN_SUBJECT || targetId == 0) {
                node = this.createSubjectNode(parentNodeData.id, parentNodeData.level + 1, point);
            } else {
                node = this.createSonSubjectNode(parentNodeData.id, parentNodeData.level + 1);
            }
            parentNode.addChildrenNode(node, point);
            if (parentNode.value.id == this.rootTreeNode.value.id &&
                this.isUnderline(node.value) &&
                this.lineColors != null &&
                this.lineColors.length > 0) {
                let colorPoint = point % this.lineColors.length
                node.value.borderColor = this.lineColors[colorPoint]
                if (node.value.isCardContent()) {
                    node.value.checkSetTextColor(this.mindBGColor)
                }
            }
            return node;
        }
    }

    addChildMindElement(targetId) {
        let parentNodeId = targetId;
        let parentNode = this.getNodeById(parentNodeId);
        if (parentNode.isEmpty() || parentNode.value.type == MindElementType.EXPLAIN) {
            return (new LineMindTypeNode).emptyMindNode();
        }
        let parentNodeData = parentNode.value;
        let node;
        let point = parentNode.children.length + 1;
        if (parentNodeData.type == MindElementType.MAIN_SUBJECT || targetId == 0) {
            node = this.createSubjectNode(parentNodeId, parentNodeData.level + 1, point);
        } else {
            node = this.createSonSubjectNode(parentNodeId, parentNodeData.level + 1);
        }

        parentNode.addChildrenNode(node);
        if (parentNode.value.id == this.rootTreeNode.value.id &&
            this.isUnderline(node.value) &&
            this.lineColors != null &&
            this.lineColors.length > 0) {
            let colorPoint = (parentNode.children.length - 1) % this.lineColors.length
            node.value.borderColor = this.lineColors[colorPoint]
            if (node.value.isCardContent()) {
                node.value.checkSetTextColor(this.mindBGColor)
            }
        }
        return node;
    }

    createSonSubjectNode(parentNodeId, level) {
        let sonTextData = new MindElementData();
        let parentNode = this.getNodeById(parentNodeId);

        sonTextData.parentNodeId = parentNodeId;
        sonTextData.level = level;
        sonTextData.id = IdGenerator.shared.getId();
        sonTextData.type = MindElementType.SON_SUBJECT;
        sonTextData.width = 52;
        sonTextData.height = 23;

        var text = new UiUtil().getString(StringsLanguage.Mind_Edit_Sub_Topic)
        if (!parentNode.isEmpty()) {
            text = text + " " + (parentNode.children.length + 1)
        }

        let textContent = new TextContent(text);

        textContent.x = 0;
        textContent.y = 0;
        textContent.width = 58;
        textContent.height = 26;
        sonTextData.textContent = textContent;
        let referenceStyleSonNode = this.getReferenceStyleForSonNode(parentNodeId, level);
        if (referenceStyleSonNode.isEmpty() || referenceStyleSonNode.value.textContent == null) {
            sonTextData.mindElementShape = this.mindMapFramework.getSonSubjectNodeShapeType();
            sonTextData.borderWidth = this.mindMapFramework.getSonSubjectBorderWidths();
            sonTextData.backgroundColorAlpha = this.mindMapFramework.getSonSubjectNodeFullColorAlpha();

            if (this.isUnderline(sonTextData) || this.mindMapFramework.isSubjectNodeShowBorderColor()) {
                let line = this.getNodeLine(parentNodeId);
                if (!line.isEmpty() && line.lineContent != null) {
                    sonTextData.borderColor = line.lineContent.color;
                    if (this.isUnderline(sonTextData) && sonTextData.borderWidth < line.lineContent.lineWidth) {
                        sonTextData.borderWidth = line.lineContent.lineWidth
                    }
                } else {
                    sonTextData.borderColor = this.mindMapStyleColor.getSonSubjectBorderColor();
                }
            } else {
                sonTextData.borderColor = this.mindMapStyleColor.getSonSubjectBorderColor();
            }
            if (this.mindMapFramework.isSubjectNodeShowFullColor()) {
                if (!Colors.isClear(sonTextData.borderColor)) {
                    sonTextData.backgroundColor = sonTextData.borderColor
                } else {
                    let line = this.getNodeLine(parentNodeId);
                    if (!line.isEmpty() && line.lineContent != null) {
                        sonTextData.backgroundColor = line.lineContent.color;
                    }
                }
            } else {
                sonTextData.backgroundColor = Colors.clear;
            }

            textContent.textFontSize = this.lineColorMode.sonSubjectTextFontSize;
            if (this.globalLayout == NodeLayoutType.LAYOUT_BUBBLE ||
                this.globalLayout == NodeLayoutType.LAYOUT_CIRCLE) {
                sonTextData.mindElementShape = MindElementShapeType.Circular;
                if (Colors.isClear(sonTextData.backgroundColor) &&
                    Colors.isClear(sonTextData.borderColor)) {
                    let line = this.getNodeLine(sonTextData.parentNodeId);
                    if (!line.isEmpty() && line.lineContent != null) {
                        sonTextData.borderColor = line.lineContent.color;
                    }
                }
            }
            if (parentNode.isEmpty()) {
                sonTextData.layout = this.globalLayout;
            } else {
                sonTextData.layout = MindLayoutSelect.getLayout(parentNode.value.layout, parentNode.value.layout, parentNode.value.layout);
            }
        } else {
            if (referenceStyleSonNode.value.parentNodeId != sonTextData.parentNodeId) {
                let referenceNodeParentNode = this.getNodeById(referenceStyleSonNode.value.parentNodeId)
                if (parentNode.value.type == MindElementType.SUBJECT &&
                    !referenceNodeParentNode.isEmpty() && referenceNodeParentNode.value.type != MindElementType.SUBJECT) {
                    for (let index = 0; index < this.rootTreeNode.children.length; index++) {
                        let item = this.rootTreeNode.children[index];
                        if (item.isChildNode(referenceStyleSonNode)) {
                            referenceNodeParentNode = item
                            break
                        }
                    }
                }
                if (Colors.isClear(referenceStyleSonNode.value.backgroundColor)) {
                    sonTextData.backgroundColor = referenceStyleSonNode.value.backgroundColor;
                } else if (!referenceNodeParentNode.isEmpty()) {
                    if (referenceNodeParentNode.value.backgroundColor == referenceStyleSonNode.value.backgroundColor) {
                        sonTextData.backgroundColor = parentNode.value.backgroundColor
                    } else if (referenceNodeParentNode.value.borderColor == referenceStyleSonNode.value.backgroundColor) {
                        sonTextData.backgroundColor = parentNode.value.borderColor
                    } else {
                        sonTextData.backgroundColor = referenceStyleSonNode.value.backgroundColor;
                    }
                } else {
                    sonTextData.backgroundColor = referenceStyleSonNode.value.backgroundColor;
                }
            } else {
                sonTextData.backgroundColor = referenceStyleSonNode.value.backgroundColor;
            }
            
            sonTextData.backgroundColorAlpha = referenceStyleSonNode.value.backgroundColorAlpha;
            sonTextData.borderColor = referenceStyleSonNode.value.borderColor;
            if (referenceStyleSonNode.value.parentNodeId != sonTextData.parentNodeId) {
                let line = this.getNodeLine(parentNode.value.id);
                if (parentNode.value.type == MindElementType.SON_SUBJECT) {
                    sonTextData.borderColor = referenceStyleSonNode.value.borderColor
                } else if (line.isEmpty() || line.lineContent == null || Colors.isClear(referenceStyleSonNode.value.borderColor)) {
                    sonTextData.borderColor = referenceStyleSonNode.value.borderColor;
                } else {
                    sonTextData.borderColor = line.lineContent.color;
                }
            }            
            sonTextData.isShadow = referenceStyleSonNode.value.isShadow;
            sonTextData.backgroundFullType = referenceStyleSonNode.value.backgroundFullType;
            sonTextData.borderWidth = referenceStyleSonNode.value.borderWidth;
            sonTextData.borderDot = referenceStyleSonNode.value.borderDot;
            textContent.textColor = referenceStyleSonNode.value.textContent.textColor;
            textContent.textShadow = referenceStyleSonNode.value.textContent.textShadow;
            textContent.bgColor = referenceStyleSonNode.value.textContent.bgColor;
            textContent.textFontSize = referenceStyleSonNode.value.textContent.textFontSize;
            textContent.textBold = referenceStyleSonNode.value.textContent.textBold;
            sonTextData.mindElementShape = referenceStyleSonNode.value.mindElementShape;
            sonTextData.layout = MindLayoutSelect.getLayout(parentNode.value.layout, parentNode.value.layout, referenceStyleSonNode.value.layout);

            if (this.globalLayout == NodeLayoutType.LAYOUT_BUBBLE ||
                this.globalLayout == NodeLayoutType.LAYOUT_CIRCLE) {
                if (Colors.isClear(sonTextData.backgroundColor) &&
                    Colors.isClear(sonTextData.borderColor)) {
                    let line = this.getNodeLine(sonTextData.parentNodeId);
                    if (!line.isEmpty() && line.lineContent != null) {
                        sonTextData.borderColor = line.lineContent.color;
                    }
                }
            }
        }

        sonTextData.styleColor = parentNode.value.styleColor;
        sonTextData.styleFillColor = parentNode.value.styleFillColor;
        sonTextData.checkSetTextColor(this.mindBGColor)
        sonTextData.checkShapeCard(this.mindBGColor)
        if ((sonTextData.mindElementShape == MindElementShapeType.Underline ||
            sonTextData.mindElementShape == MindElementShapeType.Two_Underline) && 
            !sonTextData.isBackgroundAndBorder()) {
            sonTextData.mindElementShape = MindElementShapeType.Corner
        }
        MindElementCalculation.set(sonTextData).caluleTextForData().calcule();
        let node = new LineMindTypeNode(sonTextData);
        this.mainMindElementDataDict.put(sonTextData.id, node);
        return node;
    }

    getReferenceStyleForSonNode(parentNodeId, level) {
        let parentNode = this.getNodeById(parentNodeId);
        if (parentNode.isEmpty()) {
            return (new LineMindTypeNode).emptyMindNode();
        }
        if (parentNode.children.length > 0) {
            return parentNode.children[0]
        }
        if (parentNode.value.type == MindElementType.SON_SUBJECT) {
            return parentNode;
        }
        let keys = this.mainMindElementDataDict.keys();
        let length = keys.length
        for (let index = 0; index < length; index++) {
            let node = this.mainMindElementDataDict.get(keys[index]);
            if (node.value.type == MindElementType.SON_SUBJECT) {
                return node;
            }
        }
        return (new LineMindTypeNode).emptyMindNode();
    }

    onTextSizeChanged(text, width, height, id) {
        let node = this.getNodeById(id);

        if (node.isEmpty()) {
            return (new LineMindTypeNode).emptyMindNode();
        }
        if (node.value.type == MindElementType.CONTENT_GENERALIZATION) {
            let generalizationContent = node.value.generalizationContent;
            let widhtDifferenceValueAbs = Math.abs(generalizationContent.width - width);
            let heigthDifferenceValueAbs = Math.abs(generalizationContent.height - height);
            generalizationContent.width = width;
            generalizationContent.height = height;
            generalizationContent.text = text;
            MindElementCalculation.set(node.value).caluleTextForData().calcule(false);
            if (widhtDifferenceValueAbs < 3 && heigthDifferenceValueAbs < 3) {
                return (new LineMindTypeNode).emptyMindNode();
            }
        } else {
            let textContent = node.value.textContent;
            let widhtDifferenceValueAbs = Math.abs(textContent.width - width);
            let heigthDifferenceValueAbs = Math.abs(textContent.height - height);
            textContent.width = width;
            textContent.height = height;
            textContent.text = text;
            MindElementCalculation.set(node.value).caluleTextForData().calcule(false);
            if (widhtDifferenceValueAbs < 3 && heigthDifferenceValueAbs < 3) {
                return (new LineMindTypeNode).emptyMindNode();
            }
        }
        return node;
    }
    getOutlinePreviousNode() {
        if (this.mindDisplayType == MindDisplayType.Outline && this.elementLayout != null && this.elementLayout) { //instanceof OutlineLayout
        } else {
        }
    }

    setOutlinePreviousNode(node) {
        if (this.mindDisplayType == MindDisplayType.Outline && this.elementLayout) { //instanceof OutlineLayout
            this.elementLayout.setPreviousNode(node);
        }
    }

    setMindDisplayType(type) {
        if (this.mindDisplayType == type || this.rootTreeNode == null) {
            return;
        }
        this.mindDisplayType = type;
        if (this.mindDisplayType == MindDisplayType.Outline) {
            this.rootTreeNode.resetLevel(this.rootTreeNode.value.type == MindElementType.MAIN_SUBJECT ? 0 : 1);
            new OutlineMindElementCalculation(this.rootTreeNode.value).caluleText().calcule();
            for (let i = 0; i < this.mainMindElementDataDict.values().length; i++) {
                const node = this.mainMindElementDataDict.values()[i];
                new OutlineMindElementCalculation(node.value).caluleText().calcule();
            }

            for (let i = 0; i < this.generalizationMindElementDataDict.values().length; i++) {
                const node = this.generalizationMindElementDataDict.values()[i];
                if (node.value.generalizationContent == null || node.value.generalizationContent.targetIds.length == 0) {
                    continue;
                }
                let targetNode = this.getNodeById(node.value.generalizationContent.targetIds[0]);
                if (!targetNode.isEmpty()) {
                    node.value.level = targetNode.value.level;
                }
                new OutlineMindElementCalculation(node.value).caluleText().calcule();
            }

        } else if (this.mindDisplayType == MindDisplayType.MindMap) {
            MindElementCalculation.set(this.rootTreeNode.value).caluleTextForData().calcule();
            this.mainMindElementDataDict.values().forEach(node => {
                MindElementCalculation.set(node.value).caluleTextForData();
            });
            this.generalizationMindElementDataDict.values().forEach(node => {
                MindElementCalculation.set(node.value).caluleTextForData();
            });
        }
        this.setElementLayout(this.globalLayout);
    }

    getRect() {
        return this.getRectByMargin(0, 0);
    }

    getRectByMargin(minLeft, minTop, spaceH = -1, spaceV = -1, freeNodeCheckPoint = false) {
        if (this.rootTreeNode == null) {
            return new CGRect();
        }
        let calculSaveImageRect = new CalculSaveImageRect(this.mainMindElementDataDict, this.generalizationMindElementDataDict,
            this.encircleMindElementDataDict, this.rootTreeNode.value, this.lineMindElementDataDict, this.explainMindElementDataDict,
            minLeft, minTop);
        if (freeNodeCheckPoint) {
            calculSaveImageRect = new CalculSaveImageRect(this.mainMindElementDataDict, this.generalizationMindElementDataDict,
                new HashMap(), this.rootTreeNode.value, this.lineMindElementDataDict, this.explainMindElementDataDict,
                minLeft, minTop);
        }
        if (this.globalLayout == NodeLayoutType.LAYOUT_FORM || this.globalLayout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
            calculSaveImageRect = new CalculSaveImageRect(this.mainMindElementDataDict, this.generalizationMindElementDataDict,
                new HashMap(), this.rootTreeNode.value, this.lineMindElementDataDict, this.explainMindElementDataDict);
        } else if (this.globalLayout == NodeLayoutType.LAYOUT_RADIATION_MAP) {
            calculSaveImageRect = new CalculSaveImageRect(this.mainMindElementDataDict, this.generalizationMindElementDataDict,
                new HashMap(), this.rootTreeNode.value, this.lineMindElementDataDict, this.explainMindElementDataDict);
        }        
        if (spaceH != -1 && spaceV != -1) {
            return calculSaveImageRect.get(spaceH, spaceV);
        } else {
            return calculSaveImageRect.get(MindSettingManager.getMindExportImageSpace(), MindSettingManager.getMindExportImageSpace());
        }
    }

    getRectWithoutEncircle(spaceH = MindSettingManager.getMindExportImageSpace(), spaceV = MindSettingManager.getMindExportImageSpace()) {
        if (this.rootTreeNode == null) {
            return new CGRect();
        }
        let calculSaveImageRect = new CalculSaveImageRect(this.mainMindElementDataDict, this.generalizationMindElementDataDict,
            new HashMap(), this.rootTreeNode.value, this.lineMindElementDataDict, this.explainMindElementDataDict);
        if (this.globalLayout == NodeLayoutType.LAYOUT_RADIATION_MAP) {
            calculSaveImageRect = new CalculSaveImageRect(this.mainMindElementDataDict, this.generalizationMindElementDataDict, new HashMap(),
                this.rootTreeNode.value, this.lineMindElementDataDict, this.explainMindElementDataDict);
        }
        return calculSaveImageRect.get(spaceH, spaceV);
    }

    setMindBGColor(value) {
        this.mainMindElementDataDict.keys().forEach(key => {
            let node = this.mainMindElementDataDict.get(key);
            if (node.value.type == MindElementType.MAIN_SUBJECT ||
                    node.value.type == MindElementType.SUBJECT ||
                    node.value.type == MindElementType.SON_SUBJECT ||
                    node.value.type == MindElementType.CONTENT_GENERALIZATION) {
                if (node.value.backgroundColor == this.mindBGColor) {
                    node.value.backgroundColor = value
                }
                node.value.checkSetTextColor(value)
                if (node.value.borderColor == this.mindBGColor) {
                    node.value.borderColor = value
                }
                if (node.value.isCardContent()) {
                    node.value.checkShapeCard(value)
                };
            }
        })
        this.explainMindElementDataDict.values().forEach(data => {
            if (data.backgroundColor == this.mindBGColor) {
                data.backgroundColor = value
            }
            data.checkSetTextColor(value)
            if (data.borderColor == this.mindBGColor) {
                data.borderColor = value;
            }
        })
        this.mindBGColor = value;
    }

    removeImage(id) {
        let node = this.getNodeById(id);
        if (node.isEmpty()) {
            return;
        }
        if (node.value.imageContent != null) {
            node.value.imageContent = null;
            MindElementCalculation.set(node.value).calcule();
        }
    }

    setlineThicken(b) {
        if (this.rootTreeNode == null || this.rootTreeNode.isEmpty()) {
            return;
        }
        if (this.rootTreeNode.children.isEmpty()) {
            return;
        }
        for (let index = 0; index < this.rootTreeNode.children.length; index++) {
            let node = this.rootTreeNode.children[index];

            let line = this.getNodeLine(node.value.id);
            if (!line.isEmpty()) {
                line.lineContent.lineThicken = b;
            }
        }
    }

    setlineDot(b, id) {
        let node = this.getNodeById(id);
        // console.log('node->',node);

        if (this.rootTreeNode == null ||
            this.rootTreeNode.isEmpty() ||
            node.isEmpty()) {
            return;
        }
        if (this.rootTreeNode.children.isEmpty()) {
            return;
        }
        // console.log('rootTreeNode->',this.rootTreeNode,this.rootTreeNode.value.id,id)

        if (this.rootTreeNode.value.id == id) {
            let keys = this.lineMindElementDataDict.keys();
            let keysLength = keys.length;
            for (let index = 0; index < keysLength; index++) {
                let key = keys[index];
                let data = this.lineMindElementDataDict.get(key);
                if (data.lineContent != null &&
                    (data.type == MindElementType.LINE ||
                        data.type == MindElementType.LAYOUT_FISH_RIGHT_LINE)) {
                    data.lineContent.dottedLine = b;
                }
            }
        } else {
            let line = this.getNodeLine(node.value.id);
            if (!line.isEmpty()) {
                line.lineContent.dottedLine = b;
            }
        }
        if (this.isUnderline(node.value)) {
            node.value.borderDot = b;
        }
        let nodeChildrenLength = node.children.length;
        for (let index = 0; index < nodeChildrenLength; index++) {
            let child = node.children[index]
            this.setlineDot(b, child.value.id);
        }
    }

    setlineShadow(b, id) {
        let node = this.getNodeById(id);

        if (this.rootTreeNode == null ||
            this.rootTreeNode.isEmpty() ||
            node.isEmpty()) {
            return;
        }
        if (this.rootTreeNode.children.isEmpty()) {
            return;
        }

        if (this.rootTreeNode.value.id == id) {
            let keys = this.lineMindElementDataDict.keys();
            let keysLength = keys.length;
            for (let index = 0; index < keysLength; index++) {
                let key = keys[index];
                let data = this.lineMindElementDataDict.get(key);
                if (data.lineContent != null &&
                    (data.type == MindElementType.LINE ||
                        data.type == MindElementType.LAYOUT_FISH_RIGHT_LINE)) {
                    data.isShadow = b;
                }
            }
        } else {
            let line = this.getNodeLine(node.value.id);
            if (!line.isEmpty()) {
                line.isShadow = b;
            }
        }
        if (this.isUnderline(node.value)) {
            node.value.isShadow = b;
        }
        let nodeChildrenLength = node.children.length;
        for (let index = 0; index < nodeChildrenLength; index++) {
            let child = node.children[index]
            this.setlineShadow(b, child.value.id);
        }
    }

    isLineThicken() {
        if (this.rootTreeNode == null || this.rootTreeNode.isEmpty() || this.rootTreeNode.children.length == 0) {
            return false;
        }

        let line = this.getNodeLine(this.rootTreeNode.children[0].value.id);
        if (line.isEmpty() || line.lineContent == null) {
            return false;
        }
        return line.lineContent.lineThicken;
    }

    getGolbalLineColor() {
        if (this.rootTreeNode == null || this.rootTreeNode.isEmpty() || this.rootTreeNode.children.length == 0) {
            return Colors.red;
        }
        let line = this.getNodeLine(this.rootTreeNode.children[0].value.id);
        if (line.isEmpty() || line.lineContent == null) {
            return Colors.red;
        }
        return line.lineContent.color;
    }

    getGolbalLineWidth() {
        if (this.rootTreeNode == null || this.rootTreeNode.isEmpty() || this.rootTreeNode.children.length == 0) {
            return 2.0;
        }
        let line = this.getNodeLine(this.rootTreeNode.children[0].value.id);
        if (line.isEmpty() || line.lineContent == null) {
            return 2.0;
        }
        return line.lineContent.lineWidth;
    }

    getNodeLine(nodeId, isFish = false) {
        if ((this.globalLayout == NodeLayoutType.LAYOUT_FORM || this.globalLayout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) &&
            this.rootTreeNode != null && nodeId == this.rootTreeNode.value.id) {
            let keys = this.lineMindElementDataDict.keys();
            for (let index = 0; index < keys.length; index++) {
                let data = this.lineMindElementDataDict.get(keys[index]);
                if (data.type == MindElementType.FORM_LINE) {
                    return data;
                }
            }
        }
        let node = this.getNodeById(nodeId);
        if (node.isEmpty()) {
            return new MindElementData().emptyMindNode();
        }

        let keys = this.lineMindElementDataDict.keys();
        for (let index = 0; index < keys.length; index++) {
            let data = this.lineMindElementDataDict.get(keys[index]);
            if (data.type == MindElementType.LINE &&
                (data.parentNodeId == node.value.parentNodeId ||
                    (data.parentNodeId == IdGenerator.INVALID_ID &&
                        data.lineContent.targetId == this.rootTreeNode.value.id)) &&
                data.lineContent.targetId == node.value.id) {
                return data;
            } else if (data.type == MindElementType.SON_LINE && data.parentNodeId == node.value.parentNodeId &&
                data.lineContent.targetId == node.value.id) {
                return data;
            }
        }

        if (this.textElementLineMindElementDataDict.containsKey(nodeId)) {
            return this.textElementLineMindElementDataDict.get(nodeId);
        } else {
            let line = this.getGeneralizationNodeLine(nodeId);
            if (!line.isEmpty()) {
                return line;
            }
        }
        return new MindElementData().emptyMindNode();
    }

    changeNodeAllLineWidth(nodeId, value) {
        if (IdGenerator.INVALID_ID == nodeId) {
            let keys = this.lineMindElementDataDict.keys();
            keys.forEach(key => {
                let line = this.lineMindElementDataDict.get(key);
                if (line != null && line.lineContent != null) {
                    let targetNode = this.getNodeById(line.lineContent.targetId);
                    if (!targetNode.isEmpty() &&
                        this.isUnderline(targetNode.value) &&
                        targetNode.value.borderWidth == line.lineContent.lineWidth) {
                        targetNode.value.borderWidth = value
                    }
                    line.lineContent.lineWidth = value;
                }
            });
            return;
        }

        let node = this.getNodeById(nodeId);
        if (node.isEmpty()) {
            return;
        }
        if (!node.isEmpty() && node.value.isHandwritingContent()) {
            node.value.handwritingContent.lineWidth = value;
        }
        let lineMindElementDataDictKeys = this.lineMindElementDataDict.keys();
        lineMindElementDataDictKeys.forEach(key => {
            let line = this.lineMindElementDataDict.get(key);
            if (line != null && line.lineContent != null) {
                let targetNode = this.getNodeById(line.lineContent.targetId);
                if (!targetNode.isEmpty() &&
                    this.isUnderline(targetNode.value) &&
                    targetNode.value.borderWidth == line.lineContent.lineWidth) {
                    targetNode.value.borderWidth = value
                }
                line.lineContent.lineWidth = value;
            }
        });
    }

    changeNodeLineWidth(id, value) {
        let node = this.getNodeById(id);
        if (node.isEmpty()) {
            return;
        }
        if (node.value.isHandwritingContent()) {
            node.value.handwritingContent.lineWidth = value;
        }
        let line = this.getNodeLine(id);
        if (!line.isEmpty() && line.lineContent.targetId == id) {
            let targetNode = this.getNodeById(line.lineContent.targetId);
            if (!targetNode.isEmpty() &&
                this.isUnderline(targetNode.value) &&
                targetNode.value.borderWidth == line.lineContent.lineWidth) {
                targetNode.value.borderWidth = value
            }
            line.lineContent.lineWidth = value
            targetNode.children.forEach(child => {
                this.changeNodeLineWidth(child.value.id, value);
            });
        }
        let formLine = this.getFormLineData(node)
        if (!formLine.isEmpty() && formLine.lineContent != null) {
            formLine.lineContent.lineWidth = value
        }
    }

    setLineColors(colors) {
        if (colors == null) {
            return;
        }
        this.lineColors = colors
    }

    changeNodeAllLineColor(nodeId, color) {
        if (this.rootTreeNode == null) {
            return;
        }

        if (IdGenerator.INVALID_ID == nodeId || this.rootTreeNode.value.id == nodeId) {
            this.lineColors = []
            if (this.rootTreeNode.value.layout == NodeLayoutType.LAYOUT_CIRCLE &&
                nodeId == this.rootTreeNode.value.id) {
                let line = this.getNodeLine(nodeId);
                if (!line.isEmpty()) {
                    line.lineContent.color = color;
                    return;
                }
            } else if ((this.rootTreeNode.value.layout == NodeLayoutType.LAYOUT_FISH_RIGHT ||
                        this.rootTreeNode.value.layout == NodeLayoutType.LAYOUT_FISH_LEFT) &&
                nodeId == this.rootTreeNode.value.id) {
                let line = this.getFishLineData(this.rootTreeNode);
                if (!line.isEmpty()) {
                    line.lineContent.color = color;
                    return;
                }
            } else {
                this.changeNodeLineColor(this.rootTreeNode.value.id, color)
            }
            // this.lineMindElementDataDict.keys().forEach(key => {
            //     let line = this.lineMindElementDataDict.get(key);
            //     line.lineContent.color = color;
            // });
            return;
        }
        let node = this.getNodeById(nodeId);
        if (node.isEmpty()) {
            return;
        }
        this.changeNodeLineColor(node.value.id, color)
        // this.lineMindElementDataDict.keys().forEach(key => {
        //     let line = this.lineMindElementDataDict.get(key);
        //     line.lineContent.color = color;
        // });
    }

    changeNodeLineColor(id, color) {
        let node = this.getNodeById(id);
        if (node.isEmpty()) {
            return;
        }
        if (node.value.isHandwritingContent()) {
            node.value.handwritingContent.strokeColor = color;
        }
        this.lineMindElementDataDict.keys().forEach(key => {
            let line = this.lineMindElementDataDict.get(key);
            if (line.lineContent.targetId == id) {
                line.lineContent.color = color;
                line.lineContent.strokeColor = color;
            }
        });
        let line = this.getNodeLine(id);
        if (!line.isEmpty() && line.lineContent.targetId == id) {
            line.lineContent.color = color;
            line.lineContent.strokeColor = color;
        }
        if (this.isUnderline(node.value)) {
            node.value.borderColor = color;
        }
        for (let index = 0; index < node.children.length; index++) {
            this.changeNodeLineColor(node.children[index].value.id, color);
        }
    }

    isRootNode(id) {
        if (this.rootTreeNode == null || this.rootTreeNode.isEmpty()) {
            return false;
        }
        return this.rootTreeNode.value.id == id;
    }

    checkAddNodeForForm(point) {
        if (this.globalLayout != NodeLayoutType.LAYOUT_FORM && this.globalLayout != NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
            return (new LineMindTypeNode).emptyMindNode();
        }
        let isAddNode = false;
        try {
            this.lineMindElementDataDict.keys().forEach(key => {
                let line = this.lineMindElementDataDict.get(key);
                if (line.type == MindElementType.FORM_LINE &&
                    line.x < (point.x) && line.x + line.width > (point.x) &&
                    line.y < (point.y) && line.y + line.height > (point.y)) {
                    isAddNode = true;
                }
            });
        } catch (error) {

        }
        if (!isAddNode) {
            return (new LineMindTypeNode).emptyMindNode();
        }
        let level = this.getLevelByPointForForm(point);
        if (level < 0) {
            return (new LineMindTypeNode).emptyMindNode();
        }
        let node = this.findNodeByPointForForm(point);
        if (node.isEmpty() || node.value.level >= level) {
            return (new LineMindTypeNode).emptyMindNode();
        }

        for (let index = node.value.level; index < level; index++) {
            node = this.addChildMindElement(node.value.id);
            if (node.value.textContent != null) {
                node.value.textContent.text = "";
            }
        }
        return node;
    }

    getLevelByPointForForm(point) {
        let level = -1;
        if (this.globalLayout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
            this.mainMindElementDataDict.keys().forEach(key => {
                let data = this.mainMindElementDataDict.get(key).value;
                if (!data.isEmpty() && data.level > 0 &&
                    data.y < (point.y) && data.y + data.height > (point.y)) {
                    level = data.level;
                }
            });
        } else {
            this.mainMindElementDataDict.keys().forEach(key => {
                let data = this.mainMindElementDataDict.get(key).value;
                if (!data.isEmpty() && data.level > 0 &&
                    data.x < (point.x) && data.x + data.width > (point.x)) {
                    level = data.level;
                }
            });
        }
        
        return level;
    }

    getFishLineData(node) {
        let arr = this.lineMindElementDataDict.keys();
        let len = arr.length
        for (let index = 0; index < len; index++) {
            let key = arr[index];
            let data = this.lineMindElementDataDict.get(key);
            if (data.type == MindElementType.LAYOUT_FISH_RIGHT_LINE &&
                data.parentNodeId == node.value.parentNodeId &&
                data.lineContent.targetId == node.value.id) {
                return data;
            }
        }
        return new MindElementData().emptyMindNode();
    }

    getCustomLineData(node) {
        let arr = this.lineMindElementDataDict.keys();
        let len = arr.length
        for (let index = 0; index < len; index++) {
            let key = arr[index];
            let data = this.lineMindElementDataDict.get(key);
            if (data.type == MindElementType.LAYOUT_CUSTOM_LINE &&
                data.parentNodeId == node.value.parentNodeId &&
                data.lineContent.targetId == node.value.id) {
                return data;
            }
        }
        return new MindElementData().emptyMindNode();
    }

    findNodeByPointForForm(point) {
        let keys = this.mainMindElementDataDict.keys();
        let length = keys.length
        if (this.globalLayout == NodeLayoutType.LAYOUT_FORM_HORIZONTAL) {
            for (let index = 0; index < length; index++) {
                let node = this.mainMindElementDataDict.get(keys[index]);
                if (!node.isEmpty() &&
                    node.children.length == 0 &&
                    node.value.x < (point.x) && node.value.x + node.value.width > (point.x)) {
                    return node;
                }
            }
        } else {
            for (let index = 0; index < length; index++) {
                let node = this.mainMindElementDataDict.get(keys[index]);
                if (!node.isEmpty() &&
                    node.children.length == 0 &&
                    node.value.y < (point.y) && node.value.y + node.value.height > (point.y)) {
                    return node;
                }
            }
        }
        return (new LineMindTypeNode).emptyMindNode();
    }
    
    createFreeNode(parentNodeId, level, data) {
        let textData = new MindElementData();
        textData.id = IdGenerator.shared.getId();
        textData.type = MindElementType.SUBJECT;
        textData.parentNodeId = parentNodeId;
        textData.level = level;
        let textContent = new TextContent(new UiUtil().getString(StringsLanguage.Mind_Edit_Free_Topic));
        textContent.x = 0;
        textContent.y = 0;
        textContent.width = 110;
        textContent.height = 41;
        textData.textContent = textContent;
        if (this.globalLayout == NodeLayoutType.LAYOUT_BUBBLE) {
            textData.mindElementShape = MindElementShapeType.Circular;
        } else {
            textData.mindElementShape = this.mindMapStyleColor.getFreeSubjectNodeShapeType();
        }
        textData.backgroundColor = this.mindMapStyleColor.getFreeSubjectViewFillColor();
        textData.borderColor = this.mindMapStyleColor.getFreeSubjectBorderColor();
        textData.borderWidth = this.mindMapStyleColor.getFreeSubjectBorderWidth();
        textContent.textColor = this.mindMapStyleColor.getFreeSubjectTextColor();
        textContent.textFontSize = this.mindMapStyleColor.getFreeSubjectTextFontSize();
        textData.styleColor = this.mindMapStyleColor.getLineColor();
        textData.styleFillColor = this.mindMapStyleColor.getSubjectViewFillColor();
        textData.bubbleRadius = new UiUtil().dip2px(30);
        textData.layout = this.globalLayout;

        if (data != null && data.isStatisticsContent()) {
            textData.statisticsContent = data.statisticsContent.copy();
            if (!new Strings().isEmpty(data.statisticsContent.title)) {
                textContent.text = data.statisticsContent.title;
            }
        }
        MindElementCalculation.set(textData).caluleTextForData().calcule();
        textData.checkShapeCard(this.mindBGColor)
        textData.checkSetTextColor(this.mindBGColor)        
        let node = new LineMindTypeNode(textData);
        this.mainMindElementDataDict.put(textData.id, node);
        return node;
    }

    addExplainContent(targetId) {
        let parentNode = this.getNodeById(targetId);
        if (parentNode.isEmpty()) {
            return new MindElementData().emptyMindNode();
        }

        let explainData = this.getNodeExplain(parentNode);
        if (!explainData.isEmpty()) {
            return new MindElementData().emptyMindNode();
        }
        let targetIds = new Array();
        targetIds.push(targetId);
        explainData = this.createExplainData(targetId);
        if (!explainData.isEmpty()) {
            this.explainMindElementDataDict.put(explainData.id, explainData);
        }
        return explainData;
    }

    addGeneralizationContent(targetId) {
        let parentNode = this.getNodeById(targetId);
        if (parentNode.isEmpty() || parentNode.value.type == MindElementType.CONTENT_GENERALIZATION) {
            return (new LineMindTypeNode).emptyMindNode();
        }
        if (parentNode.value.type == MindElementType.BAOWEI_VIEW) {
            return this.addGeneralizationContent(parentNode.value.parentNodeId);
        }
        let generalization = this.elementLayout.getNodeGeneralization(parentNode);
        if (!generalization.isEmpty()) {
            return (new LineMindTypeNode).emptyMindNode();
        }
        let targetIds = new Array();
        targetIds.push(targetId);
        let generalizationNode = this.createGeneralizationNode(targetIds, parentNode.value.level);
        if (!generalizationNode.isEmpty()) {
            this.generalizationMindElementDataDict.put(generalizationNode.value.id, generalizationNode);
        }
        return generalizationNode;
    }

    stickGeneralizationContentNode(node, targetIds, line) {
        if (node.isEmpty() || node.value.generalizationContent == null ||
            this.rootTreeNode == null || this.rootTreeNode.isEmpty() || targetIds.isEmpty()) {
            return;
        }
        let generalizationMindElementDataDictValues = this.generalizationMindElementDataDict.values();
        let generalizationMindElementDataDictValuesContent = generalizationMindElementDataDictValues.length
        for (var index = 0; index < generalizationMindElementDataDictValuesContent; index++) {
            // for (LineMindTypeNode cell : generalizationMindElementDataDict.values()) {
            let cell = generalizationMindElementDataDictValues[index];
            if (cell.isEmpty() || cell.value.generalizationContent == null) {
                continue;
            }

            if (cell.value.generalizationContent.targetIds.length == targetIds.length && targetIds.length > 0) {
                let equal = true;
                for (var i = 0; i < cell.value.generalizationContent.targetIds.length; i++) {
                    if (!(cell.value.generalizationContent.targetIds[i] == targetIds[i])) {
                        equal = false;
                        break;
                    }
                }
                if (equal) {
                    return;
                }
            }
        }

        let newNode = node.copy(true);
        this.setNodeToDictStructure(newNode);
        newNode.value.generalizationContent.targetIds = targetIds;
        this.generalizationMindElementDataDict.put(newNode.value.id, newNode);
        if (!line.isEmpty() && line.lineContent != null) {
            let newLine = line.copy();
            newLine.id = IdGenerator.shared.getId();
            newLine.parentNodeId = newNode.value.id;
            newLine.lineContent.targetId = newNode.value.id;
            this.generalizationLineMindElementDataDict.put(newLine.id, newLine);
        }
    }

    stickTextElementLineElement(line, parentId, targetId) {
        if (line.isEmpty() || line.lineContent == null) {
            return;
        }
        let newLine = line.copy();
        if (newLine.isEmpty() || newLine.lineContent == null) {
            return;
        }
        newLine.id = IdGenerator.shared.getId();
        newLine.parentNodeId = parentId;
        newLine.lineContent.targetId = targetId;
        this.lineMindElementDataDict.put(newLine.id, newLine);
        this.textElementLineMindElementDataDict.put(newLine.lineContent.targetId, newLine);
    }

    stickEncircleNode(line, parentId, targetIds) {
        if (line == null || line == undefined || line.lineContent == null ||
            this.rootTreeNode == null ||
            this.rootTreeNode.isEmpty()) {
            return;
        }
        let encircleMindElementDataDictValues = this.encircleMindElementDataDict.values();
        let encircleMindElementDataDictValuesContent = encircleMindElementDataDictValues.length
        for (var index = 0; index < encircleMindElementDataDictValuesContent; index++) {
            // for (MindElementData cell : encircleMindElementDataDict.values()) {
            let cell = encircleMindElementDataDictValues[index];
            if (cell.isEmpty() || cell.lineContent == null) {
                continue;
            }

            if (cell.lineContent.targetIds.length == targetIds.slength && targetIds.length > 0) {
                let equal = true;
                for (var i = 0; i < cell.lineContent.targetIds.length; i++) {
                    if (!cell.lineContent.targetIds[index].equals(targetIds[index])) {
                        equal = false;
                        break;
                    }
                }
                if (equal) {
                    return;
                }
            }
        }
        let newLine = line.copy();
        newLine.id = IdGenerator.shared.getId();
        newLine.parentNodeId = parentId;
        newLine.lineContent.targetIds = targetIds;
        this.encircleMindElementDataDict.put(newLine.id, newLine);
    }

    stickExplainMindNode(data, parentId) {
        if (data.isEmpty() || data.lineContent == null ||
            this.rootTreeNode == null ||
            this.rootTreeNode.isEmpty()) {
            return;
        }
        let explainMindElementDataDictValues = this.explainMindElementDataDict.values();
        let explainMindElementDataDictValuesCount = explainMindElementDataDictValues.length;
        for (var index = 0; index < explainMindElementDataDictValuesCount; index++) {
            let cell = explainMindElementDataDictValues[index];
            if (cell.isEmpty() || cell.lineContent == null) {
                continue;
            }

            if (cell.parentNodeId == parentId) {
                return;
            }
        }

        let newData = data.copy();
        newData.id = IdGenerator.shared.getId();
        newData.parentNodeId = parentId;
        newData.lineContent.targetId = parentId;
        this.explainMindElementDataDict.put(newData.id, newData);
    }

    createGeneralizationNode(targetIds, level) {
        let generalizationData = new MindElementData();
        let parentNode = this.getNodeById(targetIds[0]);
        if (parentNode.isEmpty()) {
            return (new LineMindTypeNode).emptyMindNode();
        }
        generalizationData.parentNodeId = IdGenerator.INVALID_ID;
        generalizationData.level = 0;
        generalizationData.id = IdGenerator.shared.getId();
        generalizationData.type = MindElementType.CONTENT_GENERALIZATION;
        generalizationData.width = 52;
        generalizationData.height = 23;
        generalizationData.layout = this.globalLayout == NodeLayoutType.LAYOUT_TRIANGLE ? NodeLayoutType.LAYOUT_RIGHT : this.globalLayout;

        let generalizationContent = new GeneralizationContent((new UiUtil).getString(StringsLanguage.Mind_Edit_Summary));
        generalizationContent.x = 0;
        generalizationContent.y = 0;
        generalizationContent.width = 65;
        generalizationContent.height = 38;
        generalizationContent.targetIds = targetIds;
        generalizationData.generalizationContent = generalizationContent;

        generalizationData.backgroundColor = parentNode.value.backgroundColor;
        generalizationData.borderColor = parentNode.value.borderColor;
        generalizationData.borderWidth = this.lineColorMode.subjectBorderWidth;
        generalizationData.styleColor = parentNode.value.styleColor;
        generalizationData.styleFillColor = parentNode.value.styleFillColor;

        generalizationContent.textColor = parentNode.value.textContent.textColor;
        generalizationContent.textFontSize = this.lineColorMode.sonSubjectTextFontSize;

        MindElementCalculation.set(generalizationData).caluleTextForData().calcule();

        return new LineMindTypeNode(generalizationData);
    }

    createExplainData(targetId) {
        let explainData = new MindElementData();
        let parentNode = this.getNodeById(targetId);
        if (parentNode.isEmpty()) {
            return new MindElementData().emptyMindNode();
        }
        explainData.parentNodeId = targetId;
        explainData.level = 0;
        explainData.id = IdGenerator.shared.getId();
        explainData.type = MindElementType.EXPLAIN;
        explainData.width = 52;
        explainData.height = 23;
        explainData.mindElementShape = MindElementShapeType.Semicircle;

        let textContent = new TextContent(new UiUtil().getString(StringsLanguage.Mind_Float_Explain));
        textContent.x = 0;
        textContent.y = 0;
        textContent.width = 65;
        textContent.height = 38;
        textContent.textFontSize = Config.NodeFloatExplainFontSize;
        if (parentNode.value.type == MindElementType.BAOWEI_VIEW) {
            if (parentNode.value.lineContent != null) {
                if (parentNode.value.lineContent.textContent != null) {
                    textContent.textColor = parentNode.value.lineContent.textContent.textColor;
                    explainData.backgroundColor = parentNode.value.lineContent.strokeColor;
                    explainData.borderColor = parentNode.value.lineContent.strokeColor;
                    explainData.borderWidth = this.lineColorMode.subjectBorderWidth;
                    explainData.styleColor = parentNode.value.styleColor;
                    explainData.styleFillColor = parentNode.value.styleFillColor;
                } else {
                    explainData.backgroundColor = parentNode.value.lineContent.strokeColor;
                    explainData.borderColor = parentNode.value.lineContent.strokeColor;

                    if (Colors.isClear(explainData.backgroundColor)) {
                        if (Colors.isDarkColor(mindBGColor)) {
                            textContent.textColor = 0xffffff;
                        } else {
                            textContent.textColor = 0x111111;
                        }
                    } else {
                        if (Colors.isDarkColor(explainData.backgroundColor)) {
                            textContent.textColor = 0xffffff;
                        } else {
                            textContent.textColor = 0x111111;
                        }
                    }
                    explainData.borderWidth = this.lineColorMode.subjectBorderWidth;
                    explainData.styleColor = parentNode.value.styleColor;
                    explainData.styleFillColor = parentNode.value.styleFillColor;
                }

            }
            explainData.textContent = textContent;
        } else {
            if (parentNode.value.textContent != null) {
                textContent.textColor = parentNode.value.textContent.textColor;
            } else if (parentNode.value.generalizationContent != null) {
                textContent.textColor = parentNode.value.generalizationContent.textColor;
            }
            explainData.textContent = textContent;

            explainData.backgroundColor = parentNode.value.backgroundColor;
            explainData.borderColor = parentNode.value.borderColor;
            explainData.borderWidth = this.lineColorMode.subjectBorderWidth;
            explainData.styleColor = parentNode.value.styleColor;
            explainData.styleFillColor = parentNode.value.styleFillColor;
        }
        explainData.alignmentType = MindElementAlignmentType.MIDDLE
        if (Colors.isClear(explainData.backgroundColor) &&
            Colors.isClear(explainData.borderColor)) {
            let line = this.getNodeLine(targetId);
            if (!line.isEmpty() && line.lineContent != null) {
                explainData.borderColor = line.lineContent.color;
            }
        }
        explainData.lineContent = new LineElementContent();
        explainData.lineContent.setLineElementContent(new CGPoint(), new CGPoint(), 0x333333, targetId, new CGPoint());
        explainData.lineContent.height = Config.NodeFloatExplainSpace;
        explainData.lineContent.width = Config.NodeFloatExplainWidth;
        MindElementCalculation.set(explainData).caluleTextForData().calcule();

        return explainData;
    }

    removeElementNode(id) {
        let node = this.getNodeById(id);
        if (node.isEmpty()) {
            return;
        }
        if (node.value.id != IdGenerator.INVALID_ID &&
            (node.value.type == MindElementType.MAIN_SUBJECT ||
                node.value.type == MindElementType.SUBJECT ||
                node.value.type == MindElementType.SON_SUBJECT)) {

            if (node.value.parentNodeId != IdGenerator.INVALID_ID) {
                let parentNode = this.getNodeById(node.value.parentNodeId);
                if (!parentNode.isEmpty() && parentNode.children.length > 0) {
                    for (let index = 0; index < parentNode.children.length; index++) {
                        if (parentNode.children[index].value.id == node.value.id) {
                            parentNode.children.remove(index);
                            break;
                        }
                    }
                }
            } else {
                this.rootTreeNode = null;
            }
            this.removeElementNodeByNode(node);
        } else if (node.value.type == MindElementType.CONTENT_GENERALIZATION) {
            this.removeGeneralization(node);
        } else if (node.value.type == MindElementType.EXPLAIN) {
            this.removeExplainMind(node);
        } else if (node.value.type == MindElementType.BAOWEI_VIEW) {
            this.removeEncircleMindElementData(node.value);
        }
    }

    removeElementNodeByNode(node) {
        let data = node.value;
        if (this.textElementLineMindElementDataDict.keys() != null &&
            this.textElementLineMindElementDataDict.containsKey(data.id)) {
            let lineData = this.textElementLineMindElementDataDict.get(data.id);
            if (this.delegate != null) {
                this.delegate.removeViewByMindData(lineData);
            }
            this.lineMindElementDataDict.remove(lineData.id);
            this.textElementLineMindElementDataDict.remove(data.id);
        }
        this.mainMindElementDataDict.remove(data.id);
        if (this.delegate != null) {
            this.delegate.removeViewByMindData(data);
        }
        this.removeNodeGeneralization(node);
        this.removeNodeExplainMind(node);
        let encircleKeys = this.encircleMindElementDataDict.keys();
        for (let index = 0; index < encircleKeys.length; index++) {
            let encircleData = this.encircleMindElementDataDict.get(encircleKeys[index]);            
            if (encircleData.lineContent == null || 
                (this.mindType == MindType.FLOW_CHART && (encircleData.customWidth > 0 || encircleData.customHeight > 0))) {
                continue;
            }
            var list = []
            if (!encircleData.lineContent.targetIds.isEmpty()) {
                for (let targetIdsIndex = 0; targetIdsIndex < encircleData.lineContent.targetIds.length; targetIdsIndex++) {
                    let id = encircleData.lineContent.targetIds[targetIdsIndex]
                    if (id != data.id) {
                        list.push(id);
                        let targetNode = this.getNodeById(id);
                        if (!targetNode.isEmpty()) {
                            encircleData.lineContent.targetId = id;
                            encircleData.parentNodeId = id;
                        }
                    }
                }
                encircleData.lineContent.targetIds = list;
                if (list.isEmpty()) {
                    this.removeEncircleMindElementData(encircleData);
                    break;
                }
            } else if (encircleData.lineContent.targetId == data.id ||
                encircleData.parentNodeId == data.id) {
                this.removeEncircleMindElementData(encircleData);
                break;
            }
        }

        node.children.forEach(child => {
            this.removeElementNodeByNode(child);
        });
    }

    getNodeGeneralizationByNode(node) {
        if (node.isEmpty()) {
            return new LineMindTypeNode();
        }
        if (node.children == undefined) {
            return this.getNodeGeneralizationByData(node);
        } else {
            return this.getNodeGeneralizationByData(node.value);
        }
    }

    getNodeGeneralizationByData(data) {
        if (data.isEmpty()) {
            return new LineMindTypeNode();
        }
        for (let i = 0; i < this.generalizationMindElementDataDict.keys().length; i++) {
            const key = this.generalizationMindElementDataDict.keys()[i];
            let item = this.generalizationMindElementDataDict.get(key);
            if (item.value.generalizationContent.targetIds.indexOf(data.id) != -1) {
                return item;
            }
        }
        return new LineMindTypeNode();
    }

    getNodeGeneralization(data) { //移除节点对应摘要
        if (data.isEmpty()) {
            return new LineMindTypeNode();
        }
        let keys = this.generalizationMindElementDataDict.keys();
        let length = keys.length;
        for (let index = 0; index < length; index++) {
            let key = keys[index];
            let item = this.generalizationMindElementDataDict.get(key);
            if (item.value.generalizationContent.targetIds.indexOf(data.id) > -1) {
                return item;
            }
        }
        return new LineMindTypeNode();
    }


    removeNodeGeneralization(node) { //移除节点对应摘要
        if (node.value.id == IdGenerator.INVALID_ID) {
            return;
        }
        let removeNodes = new Array();
        let keys = this.generalizationMindElementDataDict.keys();
        let length = keys.length;
        for (let index = 0; index < length; index++) {
            let item = this.generalizationMindElementDataDict.get(keys[index]);
            if (item.value.generalizationContent.targetIds.indexOf(node.value.id) > -1) {
                removeNodes.push(item)
            }
        }

        let removeLength = removeNodes.length;
        for (let index = 0; index < removeLength; index++) {
            const element = removeNodes[index];
            this.removeGeneralization(element);
        }
    }

    removeNodeExplainMind(node) { //移除节点对应浮动解释
        if (node.value.id == IdGenerator.INVALID_ID) {
            return;
        }

        let keys = this.explainMindElementDataDict.keys();
        let list = new Array();
        let keysLength = keys.length;
        for (var index = 0; index < keysLength; index++) {
            let key = keys[index]
            // for (int key : keys) {
            let item = this.explainMindElementDataDict.get(key);
            if (item != null && item.parentNodeId == node.value.id) {
                list.push(new LineMindTypeNode(item));
            }
        }
        let listLength = list.length;
        for (var j = 0; j < listLength; j++) {
            let cell = list[j];
            this.removeExplainMind(cell);
        }
    }

    removeGeneralization(node) { //移除摘要节点
        if (node.value.type != MindElementType.CONTENT_GENERALIZATION) {
            return;
        }
        let children = []
        for (let index = 0; index < node.children.length; index++) {
            children.push(node.children[index])
        }
        children.forEach(cell => {
            this.removeElementNode(cell.value.id);
        });
        this.removeNodeExplainMind(node);
        this.generalizationMindElementDataDict.remove(node.value.id);
        if (this.mainMindElementDataDict.containsKey(node.value.id)) {
            this.mainMindElementDataDict.remove(node.value.id);
        }
        if (this.delegate != null) {
            this.delegate.removeViewByMindData(node.value);
        }
        this.generalizationLineMindElementDataDict.keys().forEach(key => {
            let line = this.generalizationLineMindElementDataDict.get(key);
            if (line.parentNodeId == node.value.id) {
                this.generalizationLineMindElementDataDict.remove(line.id);
                if (this.delegate != null) {
                    this.delegate.removeViewByMindData(line);
                }
            }
        });
    }

    removeExplainMind(node) { //移除浮动解释
        if (node.value.type != MindElementType.EXPLAIN) {
            return;
        }
        this.explainMindElementDataDict.remove(node.value.id);
        if (this.delegate != null) {
            this.delegate.removeViewByMindData(node.value);
        }
    }

    setEncircleNodes(nodeId) {
        let node = this.getNodeById(nodeId);
        if (node.isEmpty() || node.value.type == MindElementType.NODE_CONNECT_LINE ||
            node.value.type == MindElementType.CONTENT_GENERALIZATION) {
            return new LineMindTypeNode();
        }
        let mind = new MindElementData();
        mind.id = IdGenerator.shared.getId();
        mind.type = MindElementType.BAOWEI_VIEW;
        mind.parentNodeId = node.value.id;

        mind.backgroundColor = Colors.clear;
        mind.borderColor = Colors.clear;
        mind.borderWidth = 0;
        mind.styleColor = node.value.styleColor;

        let lineContent = new LineElementContent(new Point(), new Point(), Colors.clear, node.value.id);
        lineContent.encircleNodesPoint = [];
        lineContent.encircleShapeType = EncircleShapeType.LAYOUT_RECTANGLE_2;
        lineContent.dottedLine = true;
        let lineData = this.getNodeLine(nodeId);
        if (!lineData.isEmpty()) {
            lineContent.strokeColor = lineData.lineContent.color;
        } else if (!Colors.isClear(node.value.borderColor) && node.value.borderWidth > 0) {
            lineContent.strokeColor = node.value.borderColor;
        } else if (!Colors.isClear(node.value.backgroundColor)) {
            lineContent.strokeColor = node.value.backgroundColor;
        } else {
            lineContent.strokeColor = node.value.styleColor;
        }
        lineContent.lineWidth = 3
        if (Colors.isDarkColor(this.mindBGColor)) {
            lineContent.color = 0x222222
        } else {
            lineContent.color = 0xFFFFC5
        }
        let targetIds = [];
        targetIds.push(node.value.id);
        lineContent.targetIds = targetIds;
        mind.lineContent = lineContent;

        if (this.mindType == MindType.FLOW_CHART) {
            let title = new UiUtil().getString(StringsLanguage.Mind_Edit_Title)
            let textContent = new TextContent(title);
            if (Colors.isClear(lineContent.strokeColor)) {
                textContent.textColor = 0x020202;
            } else if (Colors.isDarkColor(lineContent.strokeColor)) {
                textContent.textColor = 0xffffff;
            } else {
                textContent.textColor = 0x020202;
            }

            textContent.textFontSize = 14;
            textContent.text = title;
            let size = MindElementCalculation.set().caluleText(title, textContent.textFontSize, textContent.textBold);
            textContent.width = size.getWidth() + new UiUtil().dip2px(10);
            textContent.height = size.getHeight() + new UiUtil().dip2px(8);
            lineContent.textContent = textContent;
            lineContent.encircleTitleType = EncircleTitleLayoutType.TOP_INSIDE_FULL;
            lineContent.dottedLine = false;
        }
        this.encircleMindElementDataDict.put(mind.id, mind);

        return mind
    }

    removeEncircleMindElementData(data) {
        this.encircleMindElementDataDict.remove(data.id);
        if (this.delegate != null) {
            this.delegate.removeViewByMindData(data);
        }
    }

    getUpperLevelNode(id) {
        let node = this.getNodeById(id);
        if (node.isEmpty()) {
            return (new LineMindTypeNode).emptyMindNode();
        }
        let parentNode = this.getNodeById(node.value.parentNodeId);
        if (parentNode.isEmpty()) {
            return (new LineMindTypeNode).emptyMindNode();
        }
        let index = parentNode.getChildIndex(node);
        if (index < 0) {
            return (new LineMindTypeNode).emptyMindNode();
        }
        if (index == 0) {
            return parentNode;
        } else {
            let targetNode = parentNode.children[index - 1];
            return this.getBottomNode(targetNode);
        }
    }

    getBottomNode(node) {
        if (node.children.length == 0) {
            return node;
        }
        return this.getBottomNode(node.children[node.children.length - 1]);
    }

    getGeneralizationNodeLine(nodeId) {
        let keys = this.generalizationLineMindElementDataDict.keys();
        let length = keys.length;
        for (let index = 0; index < length; index++) {
            let line = this.generalizationLineMindElementDataDict.get(keys[index]);
            if (line.parentNodeId == nodeId) {
                return line;
            }
        }
        return new MindElementData().emptyMindNode();
    }

    getSubtitle() {
        let subtitle = "";
        if (this.rootTreeNode == null) {
            return subtitle;
        }
        this.rootTreeNode.children.forEach(node => {
            if (subtitle.length > 0) {
                subtitle = subtitle + ", ";
            }
            if (node.value.textContent != null) {
                subtitle = subtitle + node.value.textContent.getContentText();
            }
        });
        return subtitle;
    }

    getTitle() {
        if (this.rootTreeNode == null || this.rootTreeNode.isEmpty()) {
            return "";
        }
        return this.rootTreeNode.value.textContent.text;
    }

    clearSelected() {
    }
    copy() {
        let unit = new LineMindTypeNodeUnit(this.mindType, this.mindMapStyleColor.currentSelectedIndex, this.isFreeNode);
        if (this.rootTreeNode == null) {
            return unit;
        }
        unit.elementLayout = this.elementLayout;
        unit.globalLayout = this.globalLayout;
        unit.globalLineLayout = this.globalLineLayout;
        unit.lineColorMode = this.lineColorMode;
        unit.lineColors = this.lineColors;
        unit.fullColors = this.fullColors;
        unit.mindBGColor = this.mindBGColor;
        unit.mindMapFramework = this.mindMapFramework;
        unit.delegate = this.delegate;
        unit.setElementLayout(this.globalLayout, true);
        unit.rootTreeNode = this.rootTreeNode.copy();
        this.lineMindElementDataDict.keys().forEach(key => {
            unit.lineMindElementDataDict.put(key, this.lineMindElementDataDict.get(key).copy());
        });
        if (unit.rootTreeNode != null) {
            unit.setMainMindElementDataDictByRootTreeNode(unit.rootTreeNode);
        }

        unit.setTextElementLineMindElementDataDict();
        this.generalizationLineMindElementDataDict.keys().forEach(key => {
            unit.generalizationLineMindElementDataDict.put(key, this.generalizationLineMindElementDataDict.get(key).copy());
        });
        this.generalizationMindElementDataDict.keys().forEach(key => {
            let node = this.generalizationMindElementDataDict.get(key).copy()
            unit.generalizationMindElementDataDict.put(key, node);
            unit.setMainMindElementDataDictByRootTreeNode(node);
        });
        this.encircleMindElementDataDict.keys().forEach(key => {
            unit.encircleMindElementDataDict.put(key, this.encircleMindElementDataDict.get(key).copy());
        });

        this.explainMindElementDataDict.keys().forEach(key => {
            unit.explainMindElementDataDict.put(key, this.explainMindElementDataDict.get(key).copy());
        });
        return unit;
    }
    stick(unit) {
        this.elementLayout = unit.elementLayout;
        this.globalLayout = unit.globalLayout;
        this.globalLineLayout = unit.globalLineLayout;
        this.lineColorMode = unit.lineColorMode;
        this.lineColors = unit.lineColors;
        this.fullColors = unit.fullColors;
        this.mindBGColor = unit.mindBGColor;
        this.mindMapFramework = unit.mindMapFramework;
        if (unit.rootTreeNode != null) {
            this.rootTreeNode = unit.rootTreeNode;
            this.lineMindElementDataDict = unit.lineMindElementDataDict;
            this.setMainMindElementDataDictByRootTreeNode(this.rootTreeNode);
            this.setTextElementLineMindElementDataDict();
        }
        this.generalizationLineMindElementDataDict = unit.generalizationLineMindElementDataDict;
        this.generalizationMindElementDataDict = unit.generalizationMindElementDataDict;
        this.encircleMindElementDataDict = unit.encircleMindElementDataDict;
        this.explainMindElementDataDict = unit.explainMindElementDataDict;
    }
    setMainMindElementDataDictByRootTreeNode(node) {
        this.mainMindElementDataDict.put(node.value.id, node);
        node.children.forEach(cell => {
            this.setMainMindElementDataDictByRootTreeNode(cell);
        });
    }

    setTextElementLineMindElementDataDict() {
        this.lineMindElementDataDict.keys().forEach(key => {
            let data = this.lineMindElementDataDict.get(key);
            if (data != null && !data.isEmpty() && data.lineContent != null) {
                this.textElementLineMindElementDataDict.put(data.lineContent.targetId, data);
            }
        });
    }

    changeSubjectTextSize(value) {
        let arr = this.mainMindElementDataDict.keys();
        let len = arr.length;
        for (let index = 0; index < len; index++) {
            let key = arr[index]
            let node = this.mainMindElementDataDict.get(key);
            if (node.value.type == MindElementType.SUBJECT) {
                if (node.value.textContent != null) {
                    node.value.textContent.textFontSize = value;
                    MindElementCalculation.set(node.value).caluleTextForData();
                } else if (node.value.generalizationContent != null) {
                    node.value.generalizationContent.textFontSize = value;
                    MindElementCalculation.set(node.value).caluleTextForData();
                }
            }
        }

        let generalArr = this.generalizationMindElementDataDict.keys();
        let generalLen = generalArr.length;
        for (let index = 0; index < generalLen; index++) {
            let key = generalArr[index]
            let node = this.generalizationMindElementDataDict.get(key);
            if (node.value.type == MindElementType.SUBJECT) {
                if (node.value.textContent != null) {
                    node.value.textContent.textFontSize = value;
                    MindElementCalculation.set(node.value).caluleTextForData();
                } else if (node.value.generalizationContent != null) {
                    node.value.generalizationContent.textFontSize = value;
                    MindElementCalculation.set(node.value).caluleTextForData();
                }
            }
        }

    }

    changeSonSubjectTextSize(value) {
        let arr = this.mainMindElementDataDict.keys();
        let len = arr.length;

        for (let index = 0; index < len; index++) {
            let key = arr[index]
            let node = this.mainMindElementDataDict.get(key);
            if (node.value.type == MindElementType.SON_SUBJECT) {
                if (node.value.textContent != null) {
                    node.value.textContent.textFontSize = value;
                    MindElementCalculation.set(node.value).caluleTextForData();
                } else if (node.value.generalizationContent != null) {
                    node.value.generalizationContent.textFontSize = value;
                    MindElementCalculation.set(node.value).caluleTextForData();
                }
            }
        }



        let generalizationArr = this.generalizationMindElementDataDict.keys();
        let generalizationArrLen = generalizationArr.length;
        for (let index = 0; index < generalizationArrLen; index++) {
            let key = generalizationArr[index]
            let node = this.heightgeneralizationMindElementDataDict.get(key);
            if (node.value.type == MindElementType.SON_SUBJECT) {
                if (node.value.textContent != null) {
                    node.value.textContent.textFontSize = value;
                    MindElementCalculation.set(node.value).caluleTextForData();
                } else if (node.value.generalizationContent != null) {
                    node.value.generalizationContent.textFontSize = value;
                    MindElementCalculation.set(node.value).caluleTextForData();
                }
            }
        }
    }



    hiddenNodeChilds(id) {
        let node = this.getNodeById(id);
        if (node.isEmpty()) {
            return;
        }
        node.value.hiddenNumber = node.children.length;
        node.children.forEach((child) => {
            this.setNodeHidden(child);
        })
    }

    setNodeHidden(node) {
        node.value.isHidden = true;
        let generalizationArr = this.generalizationMindElementDataDict.keys();
        for (let index = 0; index < generalizationArr.length; index++) {
            let key = generalizationArr[index];
            let generalizationNode = this.generalizationMindElementDataDict.get(key);
            let data = generalizationNode.value;
            let generalizationContent = data.generalizationContent;
            if (generalizationContent != null) {
                if (generalizationContent.targetIds.indexOf(node.value.id) > -1) {
                    this.setNodeHidden(generalizationNode)
                    let getGeneralizationNodeLineNode = this.getGeneralizationNodeLine(data.id);
                    if (!getGeneralizationNodeLineNode.isEmpty()) {
                        getGeneralizationNodeLineNode.isHidden = true;
                    }
                }
            }
        }

        let encircleMindArr = this.encircleMindElementDataDict.keys()
        for (let index = 0; index < encircleMindArr.length; index++) {
            let key = encircleMindArr[index]
            let mind = this.encircleMindElementDataDict.get(key);

            if (node.value.id == mind.parentNodeId) {
                mind.isHidden = true;
            }
        }
        if (this.textElementLineMindElementDataDict.containsKey(node.value.id)) {
            this.textElementLineMindElementDataDict.get(node.value.id).isHidden = true;
        }
        node.children.forEach((child) => {
            this.setNodeHidden(child);
        })

    }

    showNodeChilds(id, openDepth = 0) {
        let node = this.getNodeById(id);
        if (node.isEmpty()) {
            return;
        }
        node.children.forEach((child) => {
            this.setNodeShow(child, node.value.level, openDepth);
        })
    }

    setNodeShow(node, baseLevel, openDepth) {
        node.value.isHidden = false;
        if (openDepth > 0 && openDepth < 6) {
            if ((node.value.level - baseLevel) < openDepth) {
                node.value.hiddenNumber = 0;
            } else {
                this.hiddenNodeChilds(node.value.id);
                return;
            }
        } else if (openDepth >= 6) {
            node.value.hiddenNumber = 0;
        }
        if (node.value.hiddenNumber < 0 || openDepth >= 6) {
            //node.value.hiddenNumber = 0;
        }
        let generalizationArr = this.generalizationMindElementDataDict.keys();

        for (let index = 0; index < generalizationArr.length; index++) {
            let key = generalizationArr[index];
            let generalizationNode = this.generalizationMindElementDataDict.get(key);
            let data = generalizationNode.value;
            let generalizationContent = data.generalizationContent;
            if (generalizationContent != null) {
                if (generalizationContent.targetIds.indexOf(node.value.id) > -1) {
                    this.setNodeShow(generalizationNode, 0, 0)
                    let getGeneralizationNodeLineNode = this.getGeneralizationNodeLine(data.id);
                    if (!getGeneralizationNodeLineNode.isEmpty()) {
                        getGeneralizationNodeLineNode.isHidden = false;
                    }
                }
            }
        }

        let encircleMindElementArr = this.encircleMindElementDataDict.keys();
        for (let index = 0; index < encircleMindElementArr.length; index++) {
            let key = encircleMindElementArr[index]
            if (node.value.id == this.encircleMindElementDataDict.get(key).parentNodeId) {
                this.encircleMindElementDataDict.get(key).isHidden = false;
            }
        }
        if (this.textElementLineMindElementDataDict.containsKey(node.value.id)) {
            this.textElementLineMindElementDataDict.get(node.value.id).isHidden = false;
        }

        if ((openDepth <= 0 || openDepth >= 6 || (node.value.level - baseLevel) < openDepth) && node.value.hiddenNumber <= 0) {
            node.children.forEach((child) => {
                this.setNodeShow(child, baseLevel, openDepth);
            })
        } else {
            this.hiddenNodeChilds(node.value.id);
        }

        

    }

    resizeNode(rect, id) {

        let node = this.getNodeById(id);
        if (node.isEmpty()) {
            return;
        }
        if (node.value.type == MindElementType.BAOWEI_VIEW) {
            let targetId = node.value.lineContent.targetIds.isEmpty() ? node.value.lineContent.targetId : node.value.lineContent.targetIds[0];
            let targetNode = this.getNodeById(targetId);

            let list = this.getSelectedNode(rect, targetNode);
            if (list.isEmpty()) {
                this.removeElementNode(id);
            } else {
                let targetIds = new Array();
                for (let index = 0; index < list.length; index++) {
                    let cell = list[index];
                    targetIds.push(cell.value.id);
                }
                node.value.lineContent.targetIds = targetIds;
                node.value.lineContent.targetId = list[0].value.id;
                node.value.parentNodeId = node.value.lineContent.targetId;
            }


        } else if (node.value.type == MindElementType.CONTENT_GENERALIZATION &&
            node.value.generalizationContent != null &&
            !node.value.generalizationContent.targetIds.isEmpty()) {

            let targetId = node.value.generalizationContent.targetIds[0];
            let targetNode = this.getNodeById(targetId);
            let generalizationTargetRect = this.getSelectedGeneralizationContentRect(id);
            let list = this.getSelectedNode(rect, targetNode);
            if (list.isEmpty()) {
                this.removeElementNode(id);
            } else {
                let targetIds = new Array();
                for (let index = 0; index < list.length; index++) {
                    let cell = list[index];
                    targetIds.push(cell.value.id);
                }
                node.value.generalizationContent.targetIds = targetIds;
            }
        }
    }

    getSelectedNode(rect, targetNode) {
        let list = new Array();
        if (targetNode.isEmpty()) {
            return list;
        } else {
            let parentNode = this.getNodeById(targetNode.value.parentNodeId);
            if (!parentNode.isEmpty()) {
                for (let index = 0; index < parentNode.children.length; index++) {
                    let child = parentNode.children[index]
                    var childRect = new CGRect(child.value.x, child.value.y, child.value.width, child.value.height)
                    if (parentNode.value.layout == NodeLayoutType.LAYOUT_BOTTOM ||
                        parentNode.value.layout == NodeLayoutType.LAYOUT_TOP) {
                        let top = childRect.y
                        let bottom = childRect.y + childRect.height()
                        if (childRect.y < rect.y) {
                            top = rect.y
                        }
                        if (bottom > rect.y + rect.height()) {
                            bottom = rect.y + rect.height()
                        }
                        if (top >= bottom) {
                            continue
                        }
                        childRect = new CGRect(child.value.x, top, child.value.width, bottom - top)
                    } else {
                        let left = childRect.x
                        let right = childRect.x + childRect.width()
                        if (childRect.x < rect.x) {
                            left = rect.x
                        }
                        if (right > rect.x + rect.width()) {
                            right = rect.x + rect.width()
                        }
                        if (left >= right) {
                            continue
                        }
                        childRect = new CGRect(left, child.value.y, right - left, child.value.height)
                    }
                    let crossArea = Util.getCrossArea(rect, childRect);

                    if (crossArea > childRect.width() * childRect.height() / 2) {
                        list.push(child);
                    }
                }
            }
        }
        return list;
    }

    getSelectedGeneralizationContentRect(id) {
        let rect = new CGRect(0, 0, 0, 0);
        let node = this.getNodeById(id);
        if (node.isEmpty() || node.value.type != MindElementType.CONTENT_GENERALIZATION || node.value.generalizationContent == null) {
            return rect;
        }
        let targetIds = node.value.generalizationContent.targetIds;
        if (targetIds.isEmpty()) {
            return rect;
        }
        let rectList = new Array();
        for (let index = 0; index < targetIds.length; index++) {
            let targetId = targetIds[index];
            let targetNode = this.getNodeById(targetId);
            if (targetNode.isEmpty()) {
                continue;
            }
            let cellRect = new NodesRectCalculation().calcule(targetNode);
            rectList.push(cellRect);
        }
        if (rectList.isEmpty()) {
            return rect;
        }
        return new RectsMergeCalculation().calcule(rectList);
    }

    setLineWidthForBracketsLayout(id) {
        let node = this.getNodeById(id);
        if (node.children.isEmpty() || (node.value.layout != NodeLayoutType.LAYOUT_BRACKETS_LEFT &&
            node.value.layout != NodeLayoutType.LAYOUT_BRACKETS_RIGHT)) {
            return;
        }
        let firstLine = this.getNodeLine(node.children[0].value.id);
        if (firstLine.isEmpty() || firstLine.lineContent == null) {
            return;
        }
        let lineWidth = firstLine.lineContent.lineWidth
        let childCount = node.children.length
        for (let index = 0; index < childCount; index++) {
            let line = this.getNodeLine(node.children[index].value.id);
            if (line.isEmpty() || line.lineContent == null || line.lineContent.lineWidth > 0) {
                continue;
            }
            line.lineContent.lineWidth = lineWidth;
        }
    }

    changNodeCuatomLineColor(id, color) {
        let keys = this.lineMindElementDataDict.keys();
        for (let index = 0; index < keys.length; index++) {
            let data = this.lineMindElementDataDict.get(keys[index]);
            if (data.type == MindElementType.LAYOUT_CUSTOM_LINE && 
                    data.lineContent.targetId == id) {
                data.lineContent.color = color
                data.lineContent.strokeColor = color
            }
        }
    }

    isClearBackgorund(data) {
        return Colors.isClear(data.backgroundColor);
    }

    isBackgorundSameMindMapBG(data) {
        return data.backgroundColor == this.mindBGColor;
    }

    isBorderSameMindMapBG(data) {
        return data.borderColor == this.mindBGColor;
    }

    isClearBorder(data) {
        return Colors.isClear(data.borderColor);
    }

    setDataColorForRainbowrLine(data, lineColor) {
        if (data.isEmpty()) {
            return;
        }
        let isClearBackgorund = this.isClearBackgorund(data);
        let isClearBorder = this.isClearBorder(data);
        let isBackgorundSameMindMapBG = this.isBackgorundSameMindMapBG(data);
        let isBorderSameMindMapBG = this.isBorderSameMindMapBG(data);
        if (!isClearBackgorund && !isBackgorundSameMindMapBG) {
            data.backgroundColor = lineColor;
            data.checkSetTextColor(this.mindBGColor);
        }
        if (!isClearBorder && !isBorderSameMindMapBG) {
            data.borderColor = lineColor;
            if (data.isCardContent()) {
                data.checkSetTextColor(this.mindBGColor);
            }
        }

        let encirclList = this.getEncircleData(data)

        if (encirclList.length > 0) {
            for (let j = 0; j < encirclList.length; j++) {
                let cell = encirclList[j]
                if (!cell.isEmpty() && cell.lineContent != null) {
                    cell.lineContent.strokeColor = lineColor
                    if (cell.lineContent.textContent != null) {
                        cell.lineContent.checkStrokeColorSetTextColor()
                    }
                    if (!Colors.isClear(cell.lineContent.color)) {
                        if (Colors.isDarkColor(this.mindBGColor)) {
                            cell.lineContent.color = Colors.getColorIntValue(Colors.getNumberToRgbDark(this.mindBGColor, -0.5))
                        } else {
                            cell.lineContent.color = Colors.getColorIntValue(Colors.getNumberToRgbDark(this.mindBGColor, 0.5))
                        }
                        cell.backgroundColorAlpha = 0.19
                    }
                }
            }
        }
    }

    onSelectedRainbowr(lineColors, nodeBackgorundColor = false, selfLineColorIndex = 1,
                        generalizationColor = -1, generalizationLineColor = -1, generalizationSonColor = -1,
                        sonColor = -1) {
        if (this.rootTreeNode == null || this.rootTreeNode.isEmpty()) {
            return
        }
        let isFreeRoot = this.rootTreeNode.value.id != IdGenerator.ROOT_ID
        if (generalizationColor != -1 && generalizationLineColor != -1) {
            let generalizationMindElementValues = this.generalizationMindElementDataDict.values()
            let generalizationMindElementDataDictValuesCount = generalizationMindElementValues.length;
            for (var i = 0; i < generalizationMindElementDataDictValuesCount; i++) {
                let node = generalizationMindElementValues[i];
                if (node.value.generalizationContent == null || node.value.generalizationContent.targetIds.length == 0) {
                    continue
                }
                if (this.isUnderline(node.value)) {
                    node.value.borderColor = generalizationColor
                    if (!Colors.isClear(node.value.backgroundColor) && node.value.backgroundColor != this.mindBGColor) {
                        node.value.backgroundColor = generalizationColor
                    }
                } else {
                    node.value.backgroundColor = generalizationColor
                    node.value.backgroundColorAlpha = 1.0
                    node.value.borderColor = generalizationColor
                }
                node.value.checkSetTextColor(this.mindBGColor)
                if (node.value.isCardContent()) {
                    node.value.checkShapeCard(this.mindBGColor)
                }
                let line = this.getGeneralizationNodeLine(node.value.id)
                if (!line.isEmpty()) {
                    line.lineContent.color = generalizationLineColor
                }
                for (j = 0; j < node.children.length; j++) {
                    this.setNodeLineColor(node.children[j], generalizationLineColor, generalizationSonColor)
                }
            }
        }

        if (this.rootTreeNode == null) {
            return;
        }
        this.lineColors = new Array();
        let lineColorsLength = lineColors.length;
        if (lineColorsLength > 0) {
            if (selfLineColorIndex > 0) {
                for (let index = 1; index < lineColorsLength; index++) {
                    this.lineColors.push(lineColors[index]);
                }
            } else {
                for (let index = 0; index < lineColorsLength; index++) {
                    this.lineColors.push(lineColors[index]);
                }
            }
        }
        let rootTreeNodeColor = this.rootTreeNode.value.backgroundColor;
        if (isFreeRoot) {
            this.rootTreeNode.value.backgroundColor = lineColors[0]
            this.rootTreeNode.value.borderColor = lineColors[0]
            this.rootTreeNode.value.checkSetTextColor(this.mindBGColor)
            if (this.rootTreeNode.value.isCardContent()) {
                this.rootTreeNode.value.checkShapeCard(this.mindBGColor)
            }
            let encirclList = this.getEncircleData(this.rootTreeNode.value)

            if (encirclList.length > 0) {
                for (let j = 0; j < encirclList.length; j++) {
                    let cell = encirclList[j]
                    if (!cell.isEmpty() && cell.lineContent != null) {
                        cell.lineContent.strokeColor = lineColors[0]
                        if (cell.lineContent.textContent != null) {
                            cell.lineContent.checkStrokeColorSetTextColor()
                        }
                        if (!Colors.isClear(cell.lineContent.color)) {
                            if (Colors.isDarkColor(this.mindBGColor)) {
                                cell.lineContent.color = Colors.getColorIntValue(Colors.getNumberToRgbDark(this.mindBGColor, -0.5))
                            } else {
                                cell.lineContent.color = Colors.getColorIntValue(Colors.getNumberToRgbDark(this.mindBGColor, 0.5))
                            }
                            cell.backgroundColorAlpha = 0.19
                        }
                    }
                }
            }
        } else {
            this.setDataColorForRainbowrLine(this.rootTreeNode.value, lineColors[0]);
        }
        if (this.rootTreeNode.children.isEmpty()) {
            return;
        }
        if (!Colors.isClear(rootTreeNodeColor) && rootTreeNodeColor != this.mindBGColor && this.rootTreeNode.value.textContent != null) {
            if (this.rootTreeNode.value.layout == NodeLayoutType.LAYOUT_RADIATE) {
                let keys = this.lineMindElementDataDict.keys();
                for (let index = 0; index < keys.length; index++) {
                    let data = this.lineMindElementDataDict.get(keys[index]);
                    if ((data.parentNodeId == this.rootTreeNode.value.parentNodeId || data.parentNodeId == IdGenerator.INVALID_ID) &&
                            data.lineContent.targetId == this.rootTreeNode.value.id) {
                        data.lineContent.color = lineColors[0]
                        data.lineContent.strokeColor = lineColors[0]
                    }
                }
            }
        }

        let rootTreeNodeChildrenLength = this.rootTreeNode.children.length;
        for (let index = 0; index < rootTreeNodeChildrenLength; index++) {
            let point = index % this.lineColors.length;
            let node = this.rootTreeNode.children[index];
            let line = this.textElementLineMindElementDataDict.get(node.value.id);
            if (line == null || line.isEmpty() || line.lineContent == null) {
                if (node.value.layout == NodeLayoutType.LAYOUT_TRIANGLE) {
                    node.value.backgroundColor = this.lineColors[point]
                    node.value.borderColor = this.lineColors[point]
                    this.setNodeLineColor(node, this.lineColors[point]);
                }
                continue;
            }
            
            if (nodeBackgorundColor || node.value.backgroundColor == line.lineContent.color) {
                node.value.backgroundColor = this.lineColors[point];
                if (line.lineContent.color == node.value.borderColor ||
                        node.value.mindElementShape == MindElementShapeType.Underline || node.value.mindElementShape == MindElementShapeType.Two_Underline) {
                    if (node.value.isCardContent()) {
                        node.value.borderColor = Colors.getColorIntValue(Colors.getNumberToRgbDark(this.lineColors[point], 0.5));
                    } else {
                        node.value.borderColor = this.lineColors[point];
                    }
                }
                node.value.checkSetTextColor(this.mindBGColor);
            } else {
                if (line.lineContent.color == node.value.borderColor ||
                        node.value.mindElementShape == MindElementShapeType.Underline || node.value.mindElementShape == MindElementShapeType.Two_Underline) {
                    node.value.borderColor = this.lineColors[point];
                    if (node.value.isCardContent()) {
                        node.value.checkSetTextColor(this.mindBGColor)
                    }
                }
            }

            let encirclList = this.getEncircleData(node.value)
            if (encirclList.length > 0) {
                for (let j = 0; j < encirclList.length; j++) {
                    let cell = encirclList[j]
                    if (!cell.isEmpty() && cell.lineContent != null) {
                        cell.lineContent.strokeColor = this.lineColors[point]
                        if (cell.lineContent.textContent != null) {
                            cell.lineContent.checkStrokeColorSetTextColor()
                        }
                        if (!Colors.isClear(cell.lineContent.color)) {
                            if (Colors.isDarkColor(this.mindBGColor)) {
                                cell.lineContent.color = Colors.getColorIntValue(Colors.getNumberToRgbDark(this.mindBGColor, -0.5))
                            } else {
                                cell.lineContent.color = Colors.getColorIntValue(Colors.getNumberToRgbDark(this.mindBGColor, 0.5))
                            }
                            cell.backgroundColorAlpha = 0.19
                        }
                    }
                }
            }

            line.lineContent.color = this.lineColors[point];
            line.lineContent.strokeColor = this.lineColors[point];;
            this.setNodeLineColor(node, this.lineColors[point]);
        }

        if (sonColor > -1) {
            let keys = this.mainMindElementDataDict.keys();
            let length = keys.length
            for (let index = 0; index < length; index++) {
                let node = this.mainMindElementDataDict.get(keys[index]);
                if (node.value.type != MindElementType.SON_SUBJECT) {
                    continue;
                }
                if (this.isUnderline(node.value)) {
                    let line = this.textElementLineMindElementDataDict.get(node.value.id)
                    if (line != null && !line.isEmpty()) {
                        node.value.borderColor = line.lineContent.color
                    } else {
                        node.value.borderColor = sonColor
                    }
                    if (!Colors.isClear(node.value.backgroundColor) && node.value.backgroundColor != this.mindBGColor) {
                        node.value.backgroundColor = sonColor
                    }
                } else {
                    node.value.backgroundColor = sonColor
                    node.value.borderColor = sonColor
                }
                if (isFreeRoot) {
                    node.value.backgroundColorAlpha = 1.0
                }
                node.value.checkSetTextColor(this.mindBGColor)
                if (node.value.isCardContent()) {
                    node.value.checkShapeCard(this.mindBGColor)
                }
            }
        }
    }

    setNodeLineColor(node, lineColor, nodeColor = -1) {
        let line = this.textElementLineMindElementDataDict.get(node.value.id);
        if (line != null && !line.isEmpty()) {
            if (node.value.borderColor == line.lineContent.color || 
                this.isUnderline(node.value) ||
                node.value.isCardContent()) {
                node.value.borderColor = lineColor;
                if (node.value.isCardContent()) {
                    node.value.checkSetTextColor(this.mindBGColor)
                }
            }
            if (nodeColor != -1) {
                if (this.isUnderline(node.value)) {
                    node.value.borderColor = line.lineContent.color
                    if (!Colors.isClear(node.value.backgroundColor) && node.value.backgroundColor != this.mindBGColor) {
                        node.value.backgroundColor = nodeColor
                        if (!this.rootTreeNode.isChildNode(node)) {
                            node.value.backgroundColorAlpha = 1.0
                        }
                    }
                } else {
                    node.value.backgroundColor = nodeColor
                    if (!this.rootTreeNode.isChildNode(node)) {
                        node.value.backgroundColorAlpha = 1.0
                    }
                    node.value.borderColor = nodeColor
                }
                node.value.checkSetTextColor(this.mindBGColor)
                if (node.value.isCardContent()) {
                    node.value.checkSetTextColor(this.mindBGColor);
                }
            } else if (node.value.backgroundColor == line.lineContent.color) {
                node.value.backgroundColor = lineColor;
                if (node.value.type == MindElementType.SON_SUBJECT && ! Colors.isClear(lineColor)) {
                    if (Colors.isDarkColor(this.mindBGColor)) {
                        if (Colors.isDarkColor(lineColor)) {
                            node.value.backgroundColorAlpha = 0.3;
                        } else {
                            node.value.backgroundColorAlpha = 0.2;
                        }
                    } else {
                        if (Colors.isDarkColor(lineColor)) {
                            node.value.backgroundColorAlpha = 0.2;
                        } else {
                            node.value.backgroundColorAlpha = 0.35;
                        }
                    }
                }
                node.value.checkSetTextColor(this.mindBGColor);
            }
            line.lineContent.color = lineColor;
            line.lineContent.strokeColor = lineColor;

            let encirclList = this.getEncircleData(node.value)
            if (encirclList.length > 0) {
                for (let j = 0; j < encirclList.length; j++) {
                    let cell = encirclList[j]
                    if (!cell.isEmpty() && cell.lineContent != null) {
                        cell.lineContent.strokeColor = lineColor
                        if (cell.lineContent.textContent != null) {
                            cell.lineContent.checkStrokeColorSetTextColor()
                        }
                        if (!Colors.isClear(cell.lineContent.color)) {
                            if (Colors.isDarkColor(this.mindBGColor)) {
                                cell.lineContent.color = Colors.getColorIntValue(Colors.getNumberToRgbDark(this.mindBGColor, -0.5))
                            } else {
                                cell.lineContent.color = Colors.getColorIntValue(Colors.getNumberToRgbDark(this.mindBGColor, 0.5))
                            }
                            cell.backgroundColorAlpha = 0.19
                        }
                    }
                }
            }
        }
        let nodeChildrenLength = node.children.length;
        for (let index = 0; index < nodeChildrenLength; index++) {
            let cell = node.children[index];
            this.setNodeLineColor(cell, lineColor, nodeColor);
        }
    }

    setFramework(id, settingData) {
        this.mindMapFramework = new MindMapFramework(id);
        if (this.rootTreeNode == null || this.rootTreeNode.isEmpty()) {
            return;
        }
        if (settingData != null && this.rootTreeNode.value.type == MindElementType.MAIN_SUBJECT) {
            settingData.lineThicken = this.mindMapFramework.getLineThicken();
        }

        let rootLayout = this.mindMapFramework.getMindMapRootLayout();
        let subjectLayouts = this.mindMapFramework.getMindMapSubjectLayout();
        let rootLineLayout = this.mindMapFramework.getMindMapRootLineLayout();
        let subjectLineLayout = this.mindMapFramework.getMindMapSubjectLineLayout();
        this.rootTreeNode.value.mindElementShape = this.mindMapFramework.getRootNodeShapeType();
        this.rootTreeNode.value.borderWidth = this.mindMapFramework.getRootBorderWidths();
        this.rootTreeNode.value.textContent.textFontSize = this.mindMapFramework.getRootNodeTextSize();
        this.rootTreeNode.value.checkShapeCard(this.mindBGColor);

        if (this.mindMapFramework.isRootNodeShowFullColor()) {
            let borderColor = this.rootTreeNode.value.borderColor;
            if (Colors.isClear(this.rootTreeNode.value.backgroundColor) || this.rootTreeNode.value.backgroundColor == this.mindBGColor) {
                if (!Colors.isClear(borderColor)) {
                    this.rootTreeNode.value.backgroundColor = borderColor;
                } else {
                    if (Colors.isClear(this.mindBGColor)) {
                        if (Colors.isDarkColor(this.rootTreeNode.value.textContent.textColor)) {
                            this.rootTreeNode.value.backgroundColor = Colors.white;
                            this.rootTreeNode.value.borderColor = Colors.white;
                        } else {
                            this.rootTreeNode.value.backgroundColor = Colors.black;
                            this.rootTreeNode.value.borderColor = Colors.black;
                        }
                    } else {
                        if (this.lineColors.length > 0) {
                            this.rootTreeNode.value.backgroundColor = this.lineColors[0];
                            this.rootTreeNode.value.borderColor = this.lineColors[0];
                        } else {
                            if (Colors.isDarkColor(this.mindBGColor)) {
                                this.rootTreeNode.value.backgroundColor = Colors.white;
                                this.rootTreeNode.value.borderColor = Colors.white;
                            } else {
                                this.rootTreeNode.value.backgroundColor = Colors.black;
                                this.rootTreeNode.value.borderColor = Colors.black;
                            }
                        }
                    }
                }
            } else {
                this.rootTreeNode.value.borderColor = this.rootTreeNode.value.backgroundColor;
            }
        } else {
            let borderColor = this.rootTreeNode.value.borderColor;
            let backgroundColor = this.rootTreeNode.value.backgroundColor;
            if (Colors.isClear(this.mindBGColor)) {
                if (Colors.isDarkColor(this.rootTreeNode.value.textContent.textColor)) {
                    this.rootTreeNode.value.backgroundColor = Colors.white;
                } else {
                    this.rootTreeNode.value.backgroundColor = Colors.black;
                }
            } else {
                this.rootTreeNode.value.backgroundColor = this.mindBGColor;
            }

            if (this.mindMapFramework.isRootNodeShowBorderColor()) {
                if (Colors.isClear(borderColor) || borderColor == this.mindBGColor) {
                    if (Colors.isClear(backgroundColor) || backgroundColor == this.mindBGColor) {
                        if (Colors.isClear(this.mindBGColor)) {
                            this.rootTreeNode.value.borderColor = this.rootTreeNode.value.textContent.textColor;
                        } else if (Colors.isDarkColor(this.mindBGColor)) {
                            this.rootTreeNode.value.borderColor = Colors.white;
                        } else {
                            this.rootTreeNode.value.borderColor = Colors.black;
                        }
                    } else {
                        this.rootTreeNode.value.borderColor = backgroundColor;
                    }
                }
                if (this.rootTreeNode.value.borderWidth == 0) {
                    this.rootTreeNode.value.borderWidth = new UiUtil().dip2px(2);
                }
            } else {
                this.rootTreeNode.value.borderWidth = 0;
            }
        }
        this.rootTreeNode.value.checkSetTextColor(this.mindBGColor);
        MindElementCalculation.set(this.rootTreeNode.value).caluleTextForData();

        this.setElementLayout(rootLayout);
        this.globalLineLayout = rootLineLayout;
        for (let index = 0, rootTreeNodeClildLen = this.rootTreeNode.children.length; index < rootTreeNodeClildLen; index++) {
            let child = this.rootTreeNode.children[index];
            let layout = subjectLayouts[(index % subjectLayouts.length)];
            if (rootLayout == NodeLayoutType.LAYOUT_TREE_LEFT_RIGHT) {
                if (layout == NodeLayoutType.LAYOUT_LEFT || layout == NodeLayoutType.LAYOUT_RIGHT) {
                    if (index < Math.ceil(rootTreeNodeClildLen/2)) {
                        layout = NodeLayoutType.LAYOUT_LEFT
                    } else {
                        layout = NodeLayoutType.LAYOUT_RIGHT
                    }
                } else if (layout == NodeLayoutType.LAYOUT_TREE_LEFT || layout == NodeLayoutType.LAYOUT_TREE_RIGHT) {
                    if (index < Math.ceil(rootTreeNodeClildLen/2)) {
                        layout = NodeLayoutType.LAYOUT_TREE_LEFT
                    } else {
                        layout = NodeLayoutType.LAYOUT_TREE_RIGHT
                    }
                }
            } else if (rootLayout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT_RIGHT) {
                if (layout == NodeLayoutType.LAYOUT_LEFT || layout == NodeLayoutType.LAYOUT_RIGHT) {
                    if (index < Math.ceil(rootTreeNodeClildLen/2)) {
                        layout = NodeLayoutType.LAYOUT_LEFT
                    } else {
                        layout = NodeLayoutType.LAYOUT_RIGHT
                    }
                } else if (layout == NodeLayoutType.LAYOUT_TOP_TREE_LEFT || layout == NodeLayoutType.LAYOUT_TOP_TREE_RIGHT) {
                    if (index < Math.ceil(rootTreeNodeClildLen/2)) {
                        layout = NodeLayoutType.LAYOUT_TOP_TREE_LEFT
                    } else {
                        layout = NodeLayoutType.LAYOUT_TOP_TREE_RIGHT
                    }
                }
            }
            
            let childLine = this.getNodeLine(child.value.id);
            
            if (this.mindMapFramework.getSubjectNodeShapeType() == MindElementShapeType.Underline ||
                    this.mindMapFramework.getSubjectNodeShapeType() == MindElementShapeType.Two_Underline) {
                child.value.backgroundColor = Colors.clear;
                child.value.checkSetTextColor(this.mindBGColor);
                child.value.mindElementShape = this.mindMapFramework.getSubjectNodeShapeType();
                child.value.borderWidth = this.mindMapFramework.getSubjectBorderWidths();
            } else {
                child.value.mindElementShape = this.mindMapFramework.getSubjectNodeShapeType();
                child.value.borderWidth = this.mindMapFramework.getSubjectBorderWidths();
            }

            child.value.checkShapeCard(this.mindBGColor)
            this.setNodeLayout(child.value.id, layout);
            let nodeList = new NodesRectCalculation().getNodes(child);

            if (!childLine.isEmpty() && childLine.lineContent != null) {
                childLine.lineContent.lineLayout = rootLineLayout;
                childLine.lineContent.lineWidth = this.mindMapFramework.getLineWidth();
                if (this.isUnderline(child.value) || 
                    (this.mindMapFramework.isSubjectNodeShowBorderColor() && Colors.isClear(child.value.borderColor))) {
                    child.value.borderColor = childLine.lineContent.color;
                }
            }
            if (this.mindMapFramework.isSubjectNodeShowFullColor()) {
                let borderColor = child.value.borderColor;
                if (!Colors.isClear(borderColor)) {
                    child.value.backgroundColor = borderColor;
                } else {
                    if (Colors.isClear(this.mindBGColor)) {
                        if (Colors.isDarkColor(child.value.textContent.textColor)) {
                            child.value.backgroundColor = Colors.white;
                            child.value.borderColor = Colors.white;
                        } else {
                            child.value.backgroundColor = Colors.black;
                            child.value.borderColor = Colors.black;
                        }
                    } else {
                        child.value.backgroundColor = this.mindBGColor;
                        child.value.borderColor = this.mindBGColor;
                    }
                }
            } else {
                if (Colors.isClear(this.mindBGColor)) {
                    if (Colors.isDarkColor(child.value.textContent.textColor)) {
                        child.value.backgroundColor = Colors.white;
                    } else {
                        child.value.backgroundColor = Colors.black;
                    }
                } else {
                    child.value.backgroundColor = this.mindBGColor;
                }
            }
            child.value.checkSetTextColor(this.mindBGColor);

            for (let index = 0, nodeListLen = nodeList.length; index < nodeListLen; index++) {
                let item = nodeList[index];
                if (item.id == child.value.id) {
                    continue;
                }
                let lineData = this.getNodeLine(item.id);
                item.mindElementShape = this.mindMapFramework.getSonSubjectNodeShapeType();
                item.borderWidth = this.mindMapFramework.getSonSubjectBorderWidths();
                item.backgroundColorAlpha = this.mindMapFramework.getSonSubjectNodeFullColorAlpha();
                if (this.mindMapFramework.isSonSubjectNodeFullColor()) {
                    if ((Colors.isClear(item.borderColor) || item.borderWidth == 0) && lineData.lineContent != null) {
                        item.backgroundColor = lineData.lineContent.color;
                    } else {
                        item.backgroundColor = item.borderColor;
                    }
                } else if (item.backgroundColor != this.mindBGColor) {
                    item.backgroundColor = this.mindBGColor;
                }

                if (this.mindMapFramework.isSonSubjectNodeFullColor() && item.backgroundColorAlpha < 1.0) {
                    item.borderWidth = 0;
                }
                item.checkSetTextColor(this.mindBGColor);
                item.checkShapeCard(this.mindBGColor);
                
                if (!lineData.isEmpty() && lineData.lineContent != null) {
                    lineData.lineContent.lineLayout = subjectLineLayout;
                    lineData.lineContent.lineWidth = this.mindMapFramework.getLineWidth();
                    if (this.isUnderline(item)) {
                        item.borderColor = lineData.lineContent.color;
                    }
                }
                if (rootLayout == NodeLayoutType.LAYOUT_HORIZONTAL_RIGHT) {
                    this.setNodeLayout(item.id, NodeLayoutType.LAYOUT_RIGHT);
                } else {
                    this.setNodeLayout(item.id, layout);
                }
            }
        }
    }

    removeUselessElement() {
        let removeList = []
        let removeNodeList = new Array();
        let valuse = this.lineMindElementDataDict.values()
        let length = valuse.length
        for (let index = 0; index < length; index++) {
            let line = valuse[index];
            if (line == null || line.isEmpty() || line.lineContent == null) {
                continue
            }
            if (this.rootTreeNode == null || this.rootTreeNode.isEmpty()) {
                removeList.push(line);
                continue;
            }
            let node = this.getNodeById(line.lineContent.targetId);
            if (!node.isEmpty() && line.lineContent.targetId == this.rootTreeNode.value.id) {
                continue;
            }
            let parentNode = this.getNodeById(line.parentNodeId);
            if (node.isEmpty() || parentNode.isEmpty()) {
                removeList.push(line);
            }
        }
        let explainMindElementDataDictKeys = this.explainMindElementDataDict.keys();
        let explainMindElementDataDictKeysCount = explainMindElementDataDictKeys.length;
        for (var index = 0; index < explainMindElementDataDictKeysCount; index++) {
            let key = explainMindElementDataDictKeys[index];
            let data = this.explainMindElementDataDict.get(key);
            let node = this.getNodeById(data.parentNodeId);
            if (this.rootTreeNode == null || this.rootTreeNode.isEmpty()) {
                removeList.push(data);
                continue;
            }
            if (node.isEmpty()) {
                removeList.push(data);
            }
        }

        let generalizationMindElementDataDictValuesCount = this.generalizationMindElementDataDict.values().length;
        for (var i = 0; i < generalizationMindElementDataDictValuesCount; i++) {
            let cell = this.generalizationMindElementDataDict.values()[i];
            if (cell.isEmpty() || cell.value.generalizationContent == null) {
                continue;
            }
            if (this.rootTreeNode == null || this.rootTreeNode.isEmpty()) {
                removeNodeList.push(cell);
                continue;
            }
            if (cell.value.generalizationContent.targetIds.length > 0) {
                var existTarget = false;
                for (var index = 0, j = cell.value.generalizationContent.targetIds.length; index < j; index++) {
                    let node = this.getNodeById(cell.value.generalizationContent.targetIds[index]);
                    if (!node.isEmpty()) {
                        existTarget = true;
                        break;
                    }
                }
                if (!existTarget) {
                    removeNodeList.push(cell);
                }
            }
        }
        if(this.rootTreeNode == null || this.rootTreeNode.isEmpty()) {
            this.generalizationLineMindElementDataDict.values().forEach(line=>{
                removeList.push(line);
            })
        }

        for (let index = 0; index < removeList.length; index++) {
            let cell = removeList[index]
            if (cell.type == MindElementType.LINE ||
                cell.type == MindElementType.SON_LINE ||
                cell.type == MindElementType.LAYOUT_FISH_RIGHT_LINE ||
                cell.type == MindElementType.LAYOUT_CUSTOM_LINE ||
                cell.type == MindElementType.EXPLAIN)
                this.removeLine(cell);
        }
        let removeGeneralizationList = new Array();
        let generaDictValues = this.generalizationMindElementDataDict.values();
        let generaDictValuesCount = generaDictValues.length;

        for(var i = 0; i < generaDictValuesCount; i++) {
            let item = generaDictValues[i];
            if (item.value.generalizationContent == null ||
                item.value.generalizationContent.targetIds == null) {
                removeGeneralizationList.push(item);
                continue;
            }
            if (this.rootTreeNode == null || this.rootTreeNode.isEmpty()) {
                removeGeneralizationList.push(item);
                continue;
            }
            let isRemove = true;
            let targetIdsLength = item.value.generalizationContent.targetIds.length
            for(var index = 0; index < targetIdsLength; index++){
                let node = this.getNodeById(item.value.generalizationContent.targetIds[index]);
                if (!node.isEmpty()) {
                    isRemove = false;
                    break;
                }
            }
            if (isRemove) {
                removeGeneralizationList.push(item);
            }
        }
        removeGeneralizationList.forEach(cell => {
            this.removeGeneralization(cell);
        })

        let removeGeneralizationLineList = new Array();
        this.generalizationLineMindElementDataDict.values().forEach(line=>{
            if (this.rootTreeNode == null || this.rootTreeNode.isEmpty()) {
                removeGeneralizationLineList.push(line);
            } else {
                let node = this.getNodeById(line.parentNodeId);
                if (node.isEmpty()) {
                    removeGeneralizationLineList.push(line);
                }
            }
        })
        removeGeneralizationLineList.forEach(line => {
            this.generalizationLineMindElementDataDict.remove(line.id);
            if (this.delegate != null) {
                this.delegate.removeViewByMindData(line);
            }
        })
    }

    removeLine(line) {
        if (line == null || line.isEmpty()) {
            return;
        }
        if (this.delegate != null) {
            this.delegate.removeViewByMindData(line);
        }
        this.lineMindElementDataDict.remove(line.id)
        let keys = this.textElementLineMindElementDataDict.keys()
        for (let index = 0; index < keys.length; index++) {
            let id = keys[index]
            if (this.textElementLineMindElementDataDict.get(id).id == line.id) {
                this.textElementLineMindElementDataDict.remove(id);
                break
            }
        }
    }

    getEncircleData(data) {
        var list = []
        for (let i = 0; i < this.encircleMindElementDataDict.keys().length; i++) {
            const key = this.encircleMindElementDataDict.keys()[i];
            let mind = this.encircleMindElementDataDict.get(key);
            if (mind.parentNodeId == data.id) {
                list.push(mind);
                continue;
            }
            for (let i = 0; i < mind.lineContent.targetIds.length; i++) {
                const id = mind.lineContent.targetIds[i];
                if (id == data.id) {
                    list.push(mind);
                    break;
                }
            }
        }
        return list;
    }

    isGeneralizationNodeById(id) {
        return this.isGeneralizationNode(this.getNodeById(id))
    }

    isGeneralizationNode(node) {
        if (node == null || node.isEmpty()) {
            return;
        }
        let values = this.generalizationMindElementDataDict.values()
        for (let i = 0; i < values.length; i++) {
            const generalizationNode = values[i];
            if (generalizationNode.value.isHidden) {
                continue;
            }
            if (generalizationNode.containsData(node.value.id)) {
                return true;
            }
        }
        return false;
    }

    changeLayoutToCircle(node) {
        if (!node.isEmpty()) {
            node.value.mindElementOriginalShape = node.value.mindElementShape;
            node.value.mindElementShape = MindElementShapeType.Circular;
        }
        if (node.value.level > 2) {
            this.hiddenNodeChilds(node.value.id);
        }
        let nodeChildrenLength = node.children.length;
        for (let index = 0; index < nodeChildrenLength; index++) {
            let item = node.children[index];
            this.changeLayoutToCircle(item);
        }
    }

    getNodeExplainById(id) { //获取节点node的解释
        if (id == IdGenerator.INVALID_ID) {
            return new MindElementData().emptyMindNode();
        }
        let explainMindElementDataDictKeys = this.explainMindElementDataDict.keys();
        let count = explainMindElementDataDictKeys.length;
        for (var index = 0; index < count; index++) {
            let key = explainMindElementDataDictKeys[index];
            let item = this.explainMindElementDataDict.get(key);
            if (item.parentNodeId == id) {
                return item;
            }
        }
        return new MindElementData().emptyMindNode();
    }

    getNodeExplain(node) { //获取节点node的解释
        return this.getNodeExplainById(node.value.id);
    }

    /**
    * qgx 2021//12/01  翻译
    * */
    getNodeLineColor(node) {
        if (this.rootTreeNode == null) {
            return Colors.black70;
        }
        let line = this.getNodeLine(node.value.id);
        if (!line.isEmpty() && line.lineContent != null) {
            return line.lineContent.color;
        }
        let parentNode = this.getNodeById(node.value.parentNodeId);
        if (!parentNode.isEmpty() && this.isRootNode(node.value.parentNodeId) && !this.lineColors.isEmpty()) {
            let order = 0;
            let childCount = parentNode.children.length;
            for (var i = 0; i < childCount; i++) {
                if (parentNode.children[i].value.id == node.value.id) {
                    order = i;
                    break;
                }
            }


            let colorPoint = order % this.lineColors.length;
            return this.lineColors[colorPoint];
        } else {
            this.elementLayout.setDatas(this.rootTreeNode,
                this.lineMindElementDataDict,
                this.mainMindElementDataDict,
                this.textElementLineMindElementDataDict,
                this.generalizationLineMindElementDataDict,
                this.generalizationMindElementDataDict,
                this.explainMindElementDataDict,
                this.mindMapStyleColor, this.mindBGColor,
                new SettingData());


            let referenceLine = this.elementLayout.getReferenceLine(node.value.id);

            if (!referenceLine.isEmpty()) {
                return referenceLine.lineContent.color;
            } else {
                return node.value.styleColor;
            }
        }
    }

    getFormLineData(node) {
        return this.getFormLineDataById(node.value.id);
    }

    getFormLineDataById(id) {
        let keys = this.lineMindElementDataDict.keys();
        let length = keys.length;
        for (let index = 0; index < length; index++) {
            let data = this.lineMindElementDataDict.get(keys[index]);
            if (data.type == MindElementType.FORM_LINE && 
                data.lineContent != null && 
                data.lineContent.targetId == id) {
                return data;
            }
        }
        return new MindElementData().emptyMindNode();
    }

    styleToPeer(node) {
        if (node.isEmpty() || node.value.type != MindElementType.SUBJECT && node.value.type != MindElementType.SON_SUBJECT) {
            return;
        }
        let parentNode = this.getNodeById(node.value.parentNodeId);
        if (parentNode.isEmpty()) {
            return;
        }
        let line = this.getNodeLine(node.value.id)
        let isBorderAndLineSameColor = false;
        let isBorderAndLineSameWidth = false;
        if (!line.isEmpty() && line.lineContent != null) {
            isBorderAndLineSameColor = line.lineContent.color == node.value.borderColor
            isBorderAndLineSameWidth = line.lineContent.lineWidth == node.value.borderWidth
        } 

        for (let index = 0; index < parentNode.children.length; index++) {
            const cell = parentNode.children[index];
            if (cell.value.id == node.value.id) {
                continue;
            }
            cell.stickStyle(node);
            if (isBorderAndLineSameColor || isBorderAndLineSameWidth) {
                let cellLine = this.getNodeLine(cell.value.id)
                if (!cellLine.isEmpty() && cellLine.lineContent != null) {
                    if (isBorderAndLineSameColor) {
                        cell.value.borderColor = cellLine.lineContent.color   
                        if (cell.value.isCardContent()) {
                            cell.value.checkSetTextColor(this.mindBGColor)
                        }                     
                    }
                    if (isBorderAndLineSameWidth) {
                        cell.value.borderWidth = cellLine.lineContent.lineWidth                        
                    }
                }
            }
            MindElementCalculation.set(cell.value).caluleTextForData();
        }
    }

    styleToAllSon(node) {
        if (node.isEmpty() || node.value.type != MindElementType.SON_SUBJECT) {
            return;
        }

        let target = this.getNodeById(node.value.id);
        if (target.isEmpty()) {
            return;
        }
        let line = this.getNodeLine(node.value.id)
        let isBorderAndLineSameColor = false;
        let isBorderAndLineSameWidth = false;
        if (!line.isEmpty() && line.lineContent != null) {
            isBorderAndLineSameColor = line.lineContent.color == node.value.borderColor
            isBorderAndLineSameWidth = line.lineContent.lineWidth == node.value.borderWidth
        } 
        let keys = this.mainMindElementDataDict.keys();
        let length = keys.length

        for (let index = 0; index < length; index++) {
            let cell = this.mainMindElementDataDict.get(keys[index]);
            if (cell.value.id == node.value.id || cell.value.type != MindElementType.SON_SUBJECT) {
                continue;
            }
            cell.stickStyle(node);
            if (isBorderAndLineSameColor || isBorderAndLineSameWidth) {
                let cellLine = this.getNodeLine(cell.value.id)
                if (!cellLine.isEmpty() && cellLine.lineContent != null) {
                    if (isBorderAndLineSameColor) {
                        cell.value.borderColor = cellLine.lineContent.color    
                        if (cell.value.isCardContent()) {
                            cell.value.checkSetTextColor(this.mindBGColor)
                        }                    
                    }
                    if (isBorderAndLineSameWidth) {
                        cell.value.borderWidth = cellLine.lineContent.lineWidth                        
                    }
                }
            }
            MindElementCalculation.set(cell.value).caluleTextForData();
        }
    }
}

export default LineMindTypeNodeUnit