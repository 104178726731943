

import CreatMindItmeData from "../views/uidata/CreatMindItmeData";
import MindType from "../viewmodel/datatype/MindType";
import MindDisplayType from "../viewmodel/datatype/MindDisplayType";
import LineLayout from "../viewmodel/datatype/LineLayout";
import NodeLayoutType from "../viewmodel/datatype/NodeLayoutType";
import Strings from "../common/lang/strings";

class CreateMapDatas {
    constructor() {
        this.creatMindTypeLeftRight = [];
        this.creatMindTypeUnilateral = [];
        this.creatMindTypeBottom = [];
        this.creatMindTypeBubble = [];
        this.creatMindTypeTimeVertical = [];
        this.initDatas();
    }

    initDatas() {
        this.creatMindTypeLeftRight.push(
            new CreatMindItmeData("left_right_layout_empty.jpg", Strings.My_Mind_Type_Left_Right,
              NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE, MindType.LINE_MAP, 1.645, 2,
              LineLayout.CURVE_LINE_2, MindDisplayType.MindMap, true)
          );
        this.creatMindTypeLeftRight.push(new CreatMindItmeData("mind_skeleton_0.png", Strings.My_Mind_Type_Left_Right, NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE, MindType.LINE_MAP, 1.645, 0));
        this.creatMindTypeLeftRight.push(new CreatMindItmeData("mind_skeleton_1.png", Strings.My_Mind_Type_Left_Right, NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE, MindType.LINE_MAP, 1.645, 1));
        this.creatMindTypeLeftRight.push(new CreatMindItmeData("mind_skeleton_2.png", Strings.My_Mind_Type_Left_Right, NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE, MindType.LINE_MAP, 1.645, 2));
        this.creatMindTypeLeftRight.push(new CreatMindItmeData("mind_skeleton_3.png", Strings.My_Mind_Type_Left_Right, NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE, MindType.LINE_MAP, 1.645, 3));
        this.creatMindTypeLeftRight.push(new CreatMindItmeData("mind_skeleton_4.png", Strings.My_Mind_Type_Left_Right, NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE, MindType.LINE_MAP, 1.645, 4));
        this.creatMindTypeLeftRight.push(new CreatMindItmeData("mind_skeleton_5.png", Strings.My_Mind_Type_Left_Right, NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE, MindType.LINE_MAP, 1.645, 5));
        this.creatMindTypeLeftRight.push(new CreatMindItmeData("mind_skeleton_6.png", Strings.My_Mind_Type_Left_Right, NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE, MindType.LINE_MAP, 1.645, 6));

        this.creatMindTypeUnilateral.push(new CreatMindItmeData("mind_skeleton_7.png", Strings.My_Mind_Type_Unilateral, NodeLayoutType.LAYOUT_RIGHT, MindType.LINE_MAP, 1.645, 7));
        this.creatMindTypeUnilateral.push(
            new CreatMindItmeData("mind_skeleton_flowchart_1.png", Strings.My_Mind_Type_Left_Right,
              NodeLayoutType.LAYOUT_RIGHT_LEFT_CLOCKWISE, MindType.FLOW_CHART, 1.645, 2,
              LineLayout.CURVE_LINE_2, MindDisplayType.MindMap, false)
          );
        this.creatMindTypeUnilateral.push(new CreatMindItmeData("mind_skeleton_8.png", Strings.My_Mind_Type_Unilateral, NodeLayoutType.LAYOUT_RIGHT, MindType.LINE_MAP, 1.645, 8));
        this.creatMindTypeUnilateral.push(new CreatMindItmeData("mind_skeleton_100.png", Strings.My_Mind_Type_Unilateral, NodeLayoutType.LAYOUT_BRIDGE, MindType.TIME_MAP,
                                                                1.645, 5, LineLayout.STRAIGHT_LINE_2, MindDisplayType.MindMap, false, ""));
        this.creatMindTypeUnilateral.push(new CreatMindItmeData("mind_skeleton_10.png", Strings.My_Mind_Type_Unilateral, NodeLayoutType.LAYOUT_RIGHT, MindType.LINE_MAP, 1.645, 10));
        this.creatMindTypeUnilateral.push(new CreatMindItmeData("mind_skeleton_11.png", Strings.My_Mind_Type_Unilateral, NodeLayoutType.LAYOUT_RIGHT, MindType.LINE_MAP, 1.645, 11));
        this.creatMindTypeUnilateral.push(new CreatMindItmeData("mind_skeleton_12.png", Strings.My_Mind_Type_Unilateral, NodeLayoutType.LAYOUT_BRACKETS_RIGHT, MindType.LINE_MAP, 1.645, 12));
        this.creatMindTypeUnilateral.push(new CreatMindItmeData("mind_skeleton_13.png", Strings.My_Mind_Type_Unilateral, NodeLayoutType.LAYOUT_BRACKETS_RIGHT, MindType.LINE_MAP, 1.645, 13));
        this.creatMindTypeUnilateral.push(new CreatMindItmeData("mind_skeleton_14.png", Strings.My_Mind_Type_Unilateral, NodeLayoutType.LAYOUT_BRACKETS_RIGHT, MindType.LINE_MAP, 1.645, 14));
        this.creatMindTypeUnilateral.push(new CreatMindItmeData("mind_skeleton_20.png", Strings.My_Mind_Type_Unilateral, NodeLayoutType.LAYOUT_FISH_RIGHT, MindType.LINE_MAP, 1.645, 19));
        this.creatMindTypeUnilateral.push(new CreatMindItmeData("mind_skeleton_19.png", Strings.My_Mind_Type_Unilateral, NodeLayoutType.LAYOUT_FISH_LEFT, MindType.LINE_MAP, 1.645, 20));
        this.creatMindTypeUnilateral.push(new CreatMindItmeData("mind_skeleton_21.png", Strings.My_Mind_Type_Unilateral, NodeLayoutType.LAYOUT_HORIZONTAL_RIGHT, MindType.LINE_MAP, 1.645, 21));
        this.creatMindTypeUnilateral.push(new CreatMindItmeData("mind_skeleton_22.png", Strings.My_Mind_Type_Unilateral, NodeLayoutType.LAYOUT_HORIZONTAL_RIGHT, MindType.LINE_MAP, 1.645, 22));
        this.creatMindTypeUnilateral.push(new CreatMindItmeData("mind_skeleton_23.png", Strings.My_Mind_Type_Unilateral, NodeLayoutType.LAYOUT_TREE_RIGHT, MindType.LINE_MAP, 1.645, 23));
        this.creatMindTypeUnilateral.push(new CreatMindItmeData("mind_skeleton_24.png", Strings.My_Mind_Type_Unilateral, NodeLayoutType.LAYOUT_TREE_RIGHT, MindType.LINE_MAP, 1.645, 24));
        this.creatMindTypeUnilateral.push(new CreatMindItmeData("mind_skeleton_25.png", Strings.My_Mind_Type_Unilateral, NodeLayoutType.LAYOUT_TREE_LEFT_RIGHT, MindType.LINE_MAP, 1.645, 25));
        this.creatMindTypeUnilateral.push(new CreatMindItmeData("mind_skeleton_26.png", Strings.My_Mind_Type_Unilateral, NodeLayoutType.LAYOUT_TOP_TREE_LEFT_RIGHT, MindType.LINE_MAP, 1.645, 26));
        this.creatMindTypeUnilateral.push(new CreatMindItmeData("mind_skeleton_27.png", Strings.My_Mind_Type_Unilateral, NodeLayoutType.LAYOUT_VERTICAL_RIGHT, MindType.LINE_MAP, 1.645, 27));
        this.creatMindTypeUnilateral.push(new CreatMindItmeData("mind_skeleton_28.png", Strings.My_Mind_Type_Unilateral, NodeLayoutType.LAYOUT_CIRCLE, MindType.LINE_MAP, 1.645, 28));
        this.creatMindTypeUnilateral.push(new CreatMindItmeData("mind_skeleton_29.png", Strings.My_Mind_Type_Unilateral, NodeLayoutType.LAYOUT_TRIANGLE, MindType.LINE_MAP, 1.645, 29));
        this.creatMindTypeUnilateral.push(new CreatMindItmeData("mind_skeleton_30.png", Strings.My_Mind_Type_Unilateral, NodeLayoutType.LAYOUT_FORM, MindType.LINE_MAP, 1.645, 30));
        this.creatMindTypeUnilateral.push(new CreatMindItmeData("mind_skeleton_32.png", Strings.My_Mind_Type_Unilateral, NodeLayoutType.LAYOUT_FORM_HORIZONTAL, MindType.LINE_MAP, 1.645, 32));
        
        this.creatMindTypeBottom.push(new CreatMindItmeData("mind_skeleton_15.png", Strings.My_Mind_Type_Bottom, NodeLayoutType.LAYOUT_BOTTOM, MindType.LINE_MAP, 1.645, 15));
        this.creatMindTypeBottom.push(new CreatMindItmeData("mind_skeleton_16.png", Strings.My_Mind_Type_Bottom, NodeLayoutType.LAYOUT_BOTTOM, MindType.LINE_MAP, 1.645, 16));
        this.creatMindTypeBottom.push(new CreatMindItmeData("mind_skeleton_17.png", Strings.My_Mind_Type_Bottom, NodeLayoutType.LAYOUT_BOTTOM, MindType.LINE_MAP, 1.645, 17));
        this.creatMindTypeBottom.push(new CreatMindItmeData("mind_skeleton_18.png", Strings.My_Mind_Type_Bottom, NodeLayoutType.LAYOUT_BOTTOM, MindType.LINE_MAP, 1.645, 18));

        this.creatMindTypeBubble.push(new CreatMindItmeData("mind_skeleton_102.png", Strings.My_Mind_Type_Bubble, NodeLayoutType.LAYOUT_BUBBLE, MindType.BUBBLE_MAP, 1.645, 4));
        this.creatMindTypeBubble.push(new CreatMindItmeData("mind_skeleton_28.png", Strings.My_Mind_Type_Bubble, NodeLayoutType.LAYOUT_CIRCLE, MindType.LINE_MAP, 1.645, 28));
        this.creatMindTypeBubble.push(new CreatMindItmeData("mind_skeleton_103.png", Strings.My_Mind_Type_Bubble, NodeLayoutType.LAYOUT_BUBBLE, MindType.DOUBLE_BUBBLE_MAP, 1.645, 0));
        
        this.creatMindTypeTimeVertical.push(new CreatMindItmeData("mind_skeleton_time_0.png", Strings.My_Mind_Type_Time_Vertical, NodeLayoutType.LAYOUT_VERTICAL, MindType.TIME_MAP, 1.645, 0));
        this.creatMindTypeTimeVertical.push(new CreatMindItmeData("mind_skeleton_time_1.png", Strings.My_Mind_Type_Time_Vertical, NodeLayoutType.LAYOUT_HORIZONTAL, MindType.TIME_MAP, 1.645, 1));
        this.creatMindTypeTimeVertical.push(new CreatMindItmeData("mind_skeleton_time_3.png", Strings.My_Mind_Type_Time_Vertical, NodeLayoutType.LAYOUT_HORIZONTAL_S, MindType.TIME_MAP, 1.645, 3));
        this.creatMindTypeTimeVertical.push(new CreatMindItmeData("mind_skeleton_time_4.png", Strings.My_Mind_Type_Time_Vertical, NodeLayoutType.LAYOUT_HORIZONTAL, MindType.TIME_MAP, 1.645, 4));
        this.creatMindTypeTimeVertical.push(new CreatMindItmeData("mind_skeleton_time_6.png", Strings.My_Mind_Type_Time_Vertical, NodeLayoutType.LAYOUT_VERTICAL, MindType.TIME_MAP, 1.645, 6));
        this.creatMindTypeTimeVertical.push(new CreatMindItmeData("mind_skeleton_time_7.png", Strings.My_Mind_Type_Time_Vertical, NodeLayoutType.LAYOUT_VERTICAL, MindType.TIME_MAP, 1.645, 7));
        this.creatMindTypeTimeVertical.push(new CreatMindItmeData("mind_skeleton_time_8.png", Strings.My_Mind_Type_Time_Vertical, NodeLayoutType.LAYOUT_VERTICAL, MindType.TIME_MAP, 1.645, 8));
        this.creatMindTypeTimeVertical.push(new CreatMindItmeData("mind_skeleton_time_10.png", Strings.My_Mind_Type_Time_Vertical, NodeLayoutType.LAYOUT_VERTICAL, MindType.TIME_MAP, 1.645, 10));
        this.creatMindTypeTimeVertical.push(new CreatMindItmeData("mind_skeleton_time_11.png", Strings.My_Mind_Type_Time_Vertical, NodeLayoutType.LAYOUT_VERTICAL, MindType.TIME_MAP, 1.645, 11));
    }
}
export default CreateMapDatas
