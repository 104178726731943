<template>
  <main id="switch-map-menu">
    <div
      v-show="showSwitchMapMenu"
      :style="{
        left: pointLeft + 'px',
        top: pointTop + 'px',
        opacity: opacity,
      }"
      ref="switchMap"
      class="switch-map"
    >
      <div v-if="spinning" class="loading-box">
        <a-spin
          class="loading-child"
          tip="Loading..."
          :spinning="spinning"
          :delay="500"
        >
        </a-spin>
      </div>
      <main v-else>
        <section
          class="folder-container"
          v-if="JSON.stringify(folder) !== '{}'"
        >
          <div
            class="up-one-level"
            @click.stop="
              upperLevelFolder.pop();
              getFirstPageSwitchMapData(folderParentId);
            "
          >
            <label class="upper-level-box">
              <img
                ref="upperLevelImg"
                class="return-img"
                src="../../../assets/img/mindNewEditMenuIocn/return.svg"
                alt=""
              />
            </label>
            <span>{{
              $getStringObj.getString($Strings.Group_Mind_Up_One_Level)
            }}</span>
          </div>
          <div class="line"></div>
          <div class="folder-name" :title="folder.name">
            {{ folder.name }}
          </div>
        </section>
        <main v-if="SwitchMapData.length > 0">
          <div
            v-for="(item, index) in SwitchMapData"
            :key="index"
            class="menulist-each-big-box"
          >
            <section
              class="menulist-each"
              v-if="!arvhiveMap && item.isDir"
              @click.stop="getFirstPageSwitchMapData(item.id)"
            >
              <span
                class="fileiconfont my-mind-icon"
                :class="folderColor[item.colorCode]"
                >&#xe761;</span
              >
              <div class="my-mind-list-title">
                <span class="my-mind-main-heading" :title="item.name">
                  {{ item.name }}
                </span>
              </div>
            </section>
            <section
              class="menulist-each"
              v-else-if="item.type == 0 && gmap"
              @click.stop="
                upperLevelFolder.push(item);
                getFirstPageSwitchMapData(item.id);
              "
            >
              <img
                class="mind-each-file"
                v-if="item.type == 0"
                src="../../../assets/img/groupImg/file-icon.png"
                alt=""
              />
              <div class="my-mind-list-title">
                <span class="my-mind-main-heading" :title="item.name">
                  {{ item.name }}
                </span>
              </div>
            </section>
            <section
              class="menulist-each"
              v-else-if="arvhiveMap && item.isDir"
              @click.stop="
                upperLevelFolder.push(item);
                getFirstPageSwitchMapData(item.id);
              "
            >
              <img
                class="mind-each-file"
                v-if="arvhiveMap"
                src="../../../assets/img/arvhive/bookcase.svg"
                alt=""
              />
              <div class="my-mind-list-title">
                <span class="my-mind-main-heading" :title="item.name">
                  {{ item.name }}
                </span>
              </div>
            </section>
            <section class="menulist-each" v-else @click="checkMenuItem(item)">
              <img
                src="../../../assets/img/user/plate_plan_4.jpg"
                v-real-img="checkImgSrc(item.cover)"
                alt=""
              />
              <div class="my-mind-list-title">
                <span class="my-mind-main-heading" :title="item.name">
                  {{ item.name }}
                </span>
                <span class="my-mind-main-subheading" :title="item.subtitle">
                  {{ item.subtitle }}
                </span>
              </div>
            </section>
            <div class="line"></div>
          </div>
        </main>
        <main class="no-dom" v-else>
          <img
            class="no-dom-img"
            src="../../../assets/img/mindNewEditMenuIocn/NoDocuments.svg"
            alt=""
          />
          <span>{{
            $getStringObj.getString($Strings.Group_Mind_No_Documents)
          }}</span>
        </main>
      </main>
    </div>
  </main>
</template>

<script>
import { mapMutations } from "vuex";
import {
  postMindmapListEntities,
  postMindmapQueryArchives,
} from "../../../common/netWork/networkcache";
import { postGroupListGfiles } from "../../../common/netWork/group_api";
import HttpServerConfig from "../../../common/HttpServerConfig";
import EditMindmapVM from "../../../viewmodel/mindelementdata/vm/EditMindmapVM";

export default {
  inject: ["reload"],
  components: {},
  props: [
    "pointObj",
    "menuPoint",
    "showSwitchMapMenu",
    "parentId",
    "gmap",
    "groupId",
    "mindMapingType",
  ],
  data() {
    return {
      spinning: false, //是否展示加载动画
      pointLeft: 0, //大盒子相对于整个页面的位置
      pointTop: 0,
      // hiddenLeft: 0, //透明盒子相对与父盒子的位置
      // hiddenTop: 0,
      // hiddenWidth: 0,
      // hiddenHeight: 0,
      opacity: 0,
      showAnimal: false,
      topSpace: 15, //菜单距离顶部的距离
      SwitchMapData: [], //切换导图列表
      SwitchMapTempData: [], //临时数据
      SwitchMapDataFlag: true, //是否可以请求切换导图列表
      Requested: false, //显示隐藏切换导图弹框请求一次切换导图列表
      page: 1,
      folder: {},
      upperLevelFolder: [],
      folderParentId: "",
      currentParentId: "",
      arvhiveMap: false,
      lastIndexBookcase: 0, //书架书夹上一批数据的最后一个index
      loadMoreDataTime: null,
      loadMoreDataFlag: false, //是否可以请求书架书夹列表数据
      pageSize: 45, //归档列表一页显示数据
    };
  },
  mounted() {
    if (this.$route.name == "arvhiveMap") {
      this.arvhiveMap = true;
    }
    this.folderColor = {
      0: "file-color-blue",
      1: "file-color-yellow",
      2: "file-color-red",
      3: "file-color-green",
      4: "file-color-gray",
    };
    this.loadMoreData();
  },
  updated() {
    let menudom = this.$refs.switchMap.getBoundingClientRect();
    // console.log('menudom.width',menudom.width);
    if (this.menuPoint.val1 == "bottom" && this.menuPoint.val2 == "left") {
      this.pointTop = this.pointObj.y + this.pointObj.height + this.topSpace;
      this.pointLeft = this.pointObj.x - menudom.width + this.pointObj.width;
      // this.hiddenLeft = menudom.width - this.pointObj.width;
      // this.hiddenTop = -this.pointObj.height;
      // this.hiddenWidth = this.pointObj.width;
      // this.hiddenHeight = this.pointObj.height;
      // console.log('进入了准确位置1')
    } else if (this.menuPoint.val1 == "top" && this.menuPoint.val2 == "left") {
      this.pointTop = this.pointObj.y - menudom.height;
      this.pointLeft = this.pointObj.x + this.pointObj.width - menudom.width;
      // this.hiddenLeft = menudom.width - this.pointObj.width;
      // this.hiddenTop = menudom.height;
      // this.hiddenWidth = this.pointObj.width;
      // this.hiddenHeight = this.pointObj.height;
    } else if (
      this.menuPoint.val1 == "left" &&
      this.menuPoint.val2 == "bottom"
    ) {
      this.pointTop = this.pointObj.y;
      this.pointLeft = this.pointObj.x - menudom.width;
      // this.hiddenLeft = menudom.width ;
      // this.hiddenTop = 0;
      // this.hiddenWidth = this.pointObj.width;
      // this.hiddenHeight = this.pointObj.height;
    } else if (
      this.menuPoint.val1 == "bottom" &&
      this.menuPoint.val2 == "center"
    ) {
      this.pointTop = this.pointObj.y + this.pointObj.height + this.topSpace;
      this.pointLeft =
        this.pointObj.x + this.pointObj.width / 2 - menudom.width / 2;
    } else if (
      this.menuPoint.val1 == "bottom" &&
      this.menuPoint.val2 == "right"
    ) {
      this.pointTop = this.pointObj.y + this.pointObj.height + this.topSpace;
      this.pointLeft = this.pointObj.x; // + this.pointObj.width/2 - menudom.width/2;
    }
  },
  methods: {
    ...mapMutations(["showDaGangModel"]),
    checkImgSrc(src) {
      return HttpServerConfig.getMediaSrc(src);
    },
    checkMenuItem(item) {
      EditMindmapVM.delegate.exitEditStatusSaveMap(); // 退出编辑状态，保存导图

      if (this.mindMapingType != null && this.mindMapingType.value) {
        //归档切换导图判断是否是编辑状态
        this.$router.replace({
          query: {
            id: this.gmap ? item.gmapId : item.mindMapId,
            editState: "arvhiveMapEdit",
          },
        });
      } else {
        this.$router.replace({
          query: {
            id: this.gmap ? item.gmapId : item.mindMapId,
          },
        });
      }
      this.$emit("outsideCloseSwitchMap");
      let id = this.$route.query.id;
      if (this.gmap) {
        this.SwitchMapData = this.SwitchMapTempData.filter(
          (item) => item.gmapId !== id
        );
      } else if (this.arvhiveMap) {
        this.SwitchMapData = this.SwitchMapTempData.filter(
          (item) => item.mindMapId !== id
        );
      } else {
        this.Requested = false; //切换导图之后重新请求切换导图列表,共享组、归档不能编辑，不需要重新请求
      }
    },
    //请求导图数据
    getFirstPageSwitchMapData(parentId) {
      this.currentParentId = parentId;
      this.spinning = true;
      if (this.gmap) {
        postGroupListGfiles(
          {
            groupId: this.groupId,
            parentId,
            page: 1,
            tab: 0,
          },
          (res) => {
            if (!!res.gfiles) {
              let id = this.$route.query.id;
              this.SwitchMapTempData = res.gfiles;
              this.SwitchMapData = res.gfiles.filter(
                (item) => item.gmapId !== id
              );
              // console.log(this.SwitchMapData.length)
            } else {
              this.SwitchMapData = [];
            }
            if (this.upperLevelFolder.length > 0) {
              this.folder =
                this.upperLevelFolder[this.upperLevelFolder.length - 1];
            } else {
              this.folder = {};
            }

            this.folderParentId = res.upperId;
            this.spinning = false;
          }
        );
      } else if (this.arvhiveMap) {
        postMindmapQueryArchives(
          {
            parentId,
            lastIndex: 0,
            dirOnly: false,
          },
          (res) => {
            if (!!res.archives) {
              let id = this.$route.query.id;
              this.SwitchMapTempData = res.archives;
              this.SwitchMapData = res.archives.filter(
                (item) => item.mindMapId !== id
              );
              // this.SwitchMapData.forEach(element => {
              //   element.colorCode = 4
              // });
              if (this.SwitchMapData.length >= this.pageSize) {
                //数据数量等于一页显示数量加载更多
                this.loadMoreDataFlag = true;
                this.lastIndexBookcase =
                  this.SwitchMapData[this.SwitchMapData.length - 1].index;
              }
              // console.log(this.SwitchMapData)
            }
            if (this.upperLevelFolder.length > 0) {
              this.folder =
                this.upperLevelFolder[this.upperLevelFolder.length - 1];
              this.folderParentId = this.folder.parentId;
            } else {
              this.folder = {};
            }
            this.spinning = false;
          }
        );
      } else {
        postMindmapListEntities(
          {
            parentId,
            page: 1,
          },
          (res) => {
            if (!!res.entity) {
              let id = this.$route.query.id;
              this.SwitchMapData = res.entity.filter(
                (item) => item.mindMapId !== id
              );
              // console.log(this.SwitchMapData.length)
            }
            if (!!res.parents && res.parents.length > 0) {
              this.folder = res.parents[res.parents.length - 1];
            } else {
              this.folder = {};
            }
            this.folderParentId = res.parentId;
            this.spinning = false;
          }
        );
      }

      this.SwitchMapDataFlag = true;
    },
    getSwitchMapData() {
      if (this.SwitchMapDataFlag) {
        if (this.gmap) {
          postGroupListGfiles(
            {
              groupId: this.groupId,
              parentId: this.currentParentId,
              page: this.page,
              tab: 0,
            },
            (res) => {
              if (!!res.gfiles && res.entity.length > 0) {
                this.SwitchMapData = [...this.SwitchMapData, ...res.gfiles];
                this.SwitchMapDataFlag = true;
              }
            }
          );
        } else if (this.arvhiveMap) {
          // console.log((this.page-1)*15);
          postMindmapQueryArchives(
            {
              parentId: this.currentParentId,
              lastIndex: this.lastIndexBookcase,
              dirOnly: false,
            },
            (res) => {
              if (!!res.archives && res.archives.length > 0) {
                this.SwitchMapData = [...this.SwitchMapData, ...res.archives];
                this.SwitchMapDataFlag = true;
                this.lastIndexBookcase =
                  this.SwitchMapData[this.SwitchMapData.length - 1].index;
              } else {
                this.SwitchMapDataFlag = false;
              }
            }
          );
        } else {
          postMindmapListEntities(
            {
              parentId: this.currentParentId,
              page: this.page,
            },
            (res) => {
              if (!!res.entity && res.entity.length > 0) {
                this.SwitchMapData = [...this.SwitchMapData, ...res.entity];
                this.SwitchMapDataFlag = true;
              }
            }
          );
        }
      }
      this.SwitchMapDataFlag = false;
    },
    loadMoreData() {
      let box = document.querySelector(".switch-map");
      if (this.arvhiveMap) {
        if (box) {
          const onMouseWheel = (e) => {
            clearTimeout(this.loadMoreDataTime);
            this.loadMoreDataTime = setTimeout(() => {
              let ev = e || window.event;
              let down = true; // 定义一个标志，当滚轮向下滚时，执行一些操作
              down = ev.wheelDelta ? ev.wheelDelta < 0 : ev.detail > 0;
              if (down) {
                if (this.loadMoreDataFlag) {
                  this.getSwitchMapData();
                }
              }
            }, 100);
          };
          box.removeEventListener("mousewheel", onMouseWheel, true);
          box.addEventListener("mousewheel", onMouseWheel, true);
        }
      } else {
        box.addEventListener(
          "mousewheel",
          (e) => {
            if (this.SwitchMapDataFlag) {
              setTimeout(() => {
                this.page++;
                this.getSwitchMapData();
              }, 100);
            }
          },
          true
        );
      }
    },
    setDardarkMode() {
      // 设置深色模式
      this.$nextTick(() => {
        let addGroupDom = this.$refs.switchMap;
        let imgDom = this.$refs.upperLevelImg;
        let dardarkMode = this.$store.state.mapEditIsDardarkMode;
        let daGangModels = this.$store.state.showDaGangModels; //大纲
        if (addGroupDom) {
          if (!dardarkMode.darkMode && !daGangModels) {
            addGroupDom.style.color = "#fff";//dardarkMode.fontColor;
            addGroupDom.style.background = "#3E3E3E"//dardarkMode.bgColor;

            addGroupDom.style.boxShadow = "none";
            if (imgDom) {
              imgDom.style.marginLeft = "16px";
              imgDom.style.filter = "drop-shadow(rgb(255, 255, 255) -16px 0px)";
            }
          } else {
            addGroupDom.style.color = "#333";
            addGroupDom.style.background = "#fff";
            addGroupDom.style.boxShadow = "0 0 5px #999";
            if (imgDom) {
              imgDom.style.marginLeft = "0px";
              imgDom.style.filter = "none";
            }
          }
        }
      });
    },
  },
  watch: {
    showSwitchMapMenu(newVal) {
      if (newVal && !this.Requested) {
        this.folder = {};
        this.getFirstPageSwitchMapData(this.parentId);
        this.Requested = true;
      }
    },
    pointObj(val) {
      this.opacity = 1;
    },
    "$store.state.mapEditIsDardarkMode": {
      //导图编辑页面是否为深色模式
      handler(newVal, oldVal) {
        this.setDardarkMode();
      },
      deep: true,
    },
    folder: {
      handler(newVal, oldVal) {
        //添加图标设为深色模式
        this.setDardarkMode();
      },
      deep: true,
    },
    "$store.state.showDaGangModels"(newshowDaGangModels) {
      //大纲
      this.setDardarkMode();
    },
  },
};
</script>

<style lang="less" scoped>
@font-face {
  font-family: "fileiconfont";
  src: url("../../../assets/font-icon/font_tclfjh9pcm/iconfont.eot");
  src: url("../../../assets/font-icon/font_tclfjh9pcm/iconfont.eot?#iefix")
      format("embedded-opentype"),
    url("../../../assets/font-icon/font_tclfjh9pcm/iconfont.woff2")
      format("woff2"),
    url("../../../assets/font-icon/font_tclfjh9pcm/iconfont.woff")
      format("woff"),
    url("../../../assets/font-icon/font_tclfjh9pcm/iconfont.ttf")
      format("truetype"),
    url("../../../assets/font-icon/font_tclfjh9pcm/iconfont.svg#iconfont")
      format("svg");
}

.fileiconfont {
  font-family: "fileiconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.switch-map {
  position: fixed;
  left: 200px;
  top: 200px;
  z-index: 3000;
  width: 240px;
  min-height: 216px;
  max-height: 500px;
  overflow: hidden auto;
  border-radius: 8px;
  background-color: #fff;
  color: #333333;
  border: 1px solid var(--exportMenuDashedColor);
  box-shadow: 0 0 5px #999;
  cursor: auto;

  .folder-container {
    padding: 16px 16px 0;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .up-one-level {
      font-size: 14px;
      font-family: Noto Sans SC;
      font-weight: 400;
      display: flex;
      align-items: center;
      cursor: pointer;
      .upper-level-box {
        width: 16px;
        overflow: hidden;
        display: inline-block;
        cursor: pointer;
        .return-img {
          width: 16px;
        }
      }
    }
    .line {
      width: 1px;
      height: 10px;
      background: #999999;
      opacity: 1;
      border-radius: 50px;
    }
    .folder-name {
      width: 80px;
      height: 22px;
      font-size: 14px;
      font-family: Noto Sans SC;
      font-weight: 400;
      color: #999999;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .menulist-each-big-box {
    padding: 8px 11px 0;
    // margin-bottom: 10px;

    .menulist-each {
      padding: 5px 10px;
      width: 218px;
      height: 66px;
      white-space: nowrap;
      display: flex;
      align-items: center;
      cursor: pointer;

      img {
        width: 56px;
        border: 1px solid #f8f8f8;
        border-radius: 8px;
      }
      .mind-each-file {
        border: 0;
      }

      .my-mind-list-title {
        width: 120px;
        margin-left: 16px;
        display: flex;
        flex-flow: column;
        justify-content: space-between;

        .my-mind-main-heading {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-family: Noto Sans SC;
          font-weight: 400;
          min-width: 50px;
        }

        .my-mind-main-subheading {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 12px;
          color: #999;
          box-sizing: border-box;
        }
      }

      .my-mind-icon {
        display: block;
        width: 56px;
        height: 40px;
        font-size: 40px;
        line-height: 40px;
        text-align: center;
      }

      /* 文件夹颜色开始 */
      .file-color-green {
        color: #0bbb4e;
      }

      .file-color-blue {
        color: #6bb7da;
      }

      .file-color-red {
        color: #ff4d14;
      }

      .file-color-yellow {
        color: #ebcb3c;
      }

      .file-color-gray {
        color: #707070;
      }

      /* 文件夹颜色结束 */
      &:hover {
        background: rgba(0, 0, 0, 0.1);
      }
    }

    &:last-child {
      padding-bottom: 8px;
    }

    .line {
      width: 218px;
      height: 1px;
      background: var(--exportMenuDashedColor);
      opacity: 1;
      margin-top: 8px;
      transform: scale(1, 0.5);
    }
  }

  .no-dom {
    width: 100%;
    height: 216px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    span {
      font-size: 14px;
      color: #999999;
      margin-top: 22px;
    }
  }
}

.switch-map::-webkit-scrollbar {
  width: 0px;
}

.switch-map::-webkit-scrollbar-track {
  background: rgb(239, 239, 239);
  border-radius: 2px;
}

.switch-map::-webkit-scrollbar-thumb {
  background: #bfbfbf;
  border-radius: 10px;
}

.switch-map::-webkit-scrollbar-thumb:hover {
  background: #b4b4b4;
  cursor: pointer;
}

/* 加载动画开始 */
.loading-box {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
}
.loading-child {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.ant-spin-dot-item {
  background-color: #fd492b;
}
.ant-spin {
  color: #fd492b;
}
.ant-spin .ant-spin-dot-item {
  background-color: #fd492b !important;
}
/* 加载动画结束 */
</style>
